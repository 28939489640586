/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguard.text.proto" (package "brandguard.text", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TextKernelSetting } from "./brandguard.kernel_pb";
import { KernelMetadata } from "./brandguard.kernel_pb";
import { FloatRange } from "./meta.search_pb";
import { DateRange } from "./meta.search_pb";
import { Prediction } from "./brandguard.approval_pb";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextEvent
 */
export interface TextEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: brandguard.approval.Prediction approved = 4;
     */
    approved: Prediction;
    /**
     * @generated from protobuf field: brandguard.approval.Prediction observation = 5;
     */
    observation: Prediction;
    /**
     * @generated from protobuf field: string queue = 6;
     */
    queue: string;
    /**
     * @generated from protobuf field: double confidence = 1000;
     */
    confidence: number;
    /**
     * @generated from protobuf field: double onbrand = 1004;
     */
    onbrand: number;
    /**
     * @generated from protobuf field: double grammar = 1005;
     */
    grammar: number;
    /**
     * @generated from protobuf field: double profanity = 1006;
     */
    profanity: number;
    /**
     * @generated from protobuf field: double racism = 1007;
     */
    racism: number;
    /**
     * @generated from protobuf field: double text_tone_and_voice = 1008 [json_name = "text_tone_and_voice"];
     */
    text_tone_and_voice: number;
    /**
     * @generated from protobuf field: double text_admiration = 1009 [json_name = "text_admiration"];
     */
    text_admiration: number;
    /**
     * @generated from protobuf field: double text_amusement = 1010 [json_name = "text_amusement"];
     */
    text_amusement: number;
    /**
     * @generated from protobuf field: double text_anger = 1011 [json_name = "text_anger"];
     */
    text_anger: number;
    /**
     * @generated from protobuf field: double text_annoyance = 1012 [json_name = "text_annoyance"];
     */
    text_annoyance: number;
    /**
     * @generated from protobuf field: double text_approval = 1013 [json_name = "text_approval"];
     */
    text_approval: number;
    /**
     * @generated from protobuf field: double text_caring = 1014 [json_name = "text_caring"];
     */
    text_caring: number;
    /**
     * @generated from protobuf field: double text_confusion = 1015 [json_name = "text_confusion"];
     */
    text_confusion: number;
    /**
     * @generated from protobuf field: double text_curiosity = 1016 [json_name = "text_curiosity"];
     */
    text_curiosity: number;
    /**
     * @generated from protobuf field: double text_desire = 1017 [json_name = "text_desire"];
     */
    text_desire: number;
    /**
     * @generated from protobuf field: double text_disappointment = 1018 [json_name = "text_disappointment"];
     */
    text_disappointment: number;
    /**
     * @generated from protobuf field: double text_disapproval = 1019 [json_name = "text_disapproval"];
     */
    text_disapproval: number;
    /**
     * @generated from protobuf field: double text_disgust = 1020 [json_name = "text_disgust"];
     */
    text_disgust: number;
    /**
     * @generated from protobuf field: double text_embarrassment = 1021 [json_name = "text_embarrassment"];
     */
    text_embarrassment: number;
    /**
     * @generated from protobuf field: double text_excitement = 1022 [json_name = "text_excitement"];
     */
    text_excitement: number;
    /**
     * @generated from protobuf field: double text_fear = 1023 [json_name = "text_fear"];
     */
    text_fear: number;
    /**
     * @generated from protobuf field: double text_gratitude = 1024 [json_name = "text_gratitude"];
     */
    text_gratitude: number;
    /**
     * @generated from protobuf field: double text_grief = 1025 [json_name = "text_grief"];
     */
    text_grief: number;
    /**
     * @generated from protobuf field: double text_joy = 1026 [json_name = "text_joy"];
     */
    text_joy: number;
    /**
     * @generated from protobuf field: double text_love = 1027 [json_name = "text_love"];
     */
    text_love: number;
    /**
     * @generated from protobuf field: double text_nervousness = 1028 [json_name = "text_nervousness"];
     */
    text_nervousness: number;
    /**
     * @generated from protobuf field: double text_neutral = 1029 [json_name = "text_neutral"];
     */
    text_neutral: number;
    /**
     * @generated from protobuf field: double text_optimism = 1030 [json_name = "text_optimism"];
     */
    text_optimism: number;
    /**
     * @generated from protobuf field: double text_pride = 1031 [json_name = "text_pride"];
     */
    text_pride: number;
    /**
     * @generated from protobuf field: double text_realization = 1032 [json_name = "text_realization"];
     */
    text_realization: number;
    /**
     * @generated from protobuf field: double text_relief = 1033 [json_name = "text_relief"];
     */
    text_relief: number;
    /**
     * @generated from protobuf field: double text_remorse = 1034 [json_name = "text_remorse"];
     */
    text_remorse: number;
    /**
     * @generated from protobuf field: double text_sadness = 1035 [json_name = "text_sadness"];
     */
    text_sadness: number;
    /**
     * @generated from protobuf field: double text_surprise = 1036 [json_name = "text_surprise"];
     */
    text_surprise: number;
    /**
     * @generated from protobuf field: string updated_at = 1037 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: double text_reference_rules = 1038 [json_name = "text_reference_rules"];
     */
    text_reference_rules: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextMedia
 */
export interface TextMedia {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string uploaded_by = 4 [json_name = "uploaded_by"];
     */
    uploaded_by: string;
    /**
     * @generated from protobuf field: string md5 = 5;
     */
    md5: string;
    /**
     * @generated from protobuf field: string mimetype = 6;
     */
    mimetype: string;
    /**
     * @generated from protobuf field: string mimetype_md5 = 7 [json_name = "mimetype_md5"];
     */
    mimetype_md5: string;
    /**
     * @generated from protobuf field: string created_at = 8 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 9 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string tombstoned_at = 10 [json_name = "tombstoned_at"];
     */
    tombstoned_at: string;
    /**
     * @generated from protobuf field: string scored_at = 11 [json_name = "scored_at"];
     */
    scored_at: string;
    /**
     * @generated from protobuf field: string reviewed_by = 12 [json_name = "reviewed_by"];
     */
    reviewed_by: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextUploadRequest
 */
export interface TextUploadRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextUploadResponse
 */
export interface TextUploadResponse {
    /**
     * @generated from protobuf field: brandguard.text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextSearchRequest
 */
export interface TextSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string queues = 2;
     */
    queues: string[]; // only applies to uploaded content
    /**
     * @generated from protobuf field: string deprecated_uploaded_by = 3 [json_name = "uploaded_by"];
     */
    deprecated_uploaded_by: string;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: meta.DateRange created = 5;
     */
    created?: DateRange;
    /**
     * @generated from protobuf field: uint64 limit = 6;
     */
    limit: bigint;
    /**
     * @generated from protobuf field: repeated brandguard.approval.Prediction observation = 7;
     */
    observation: Prediction[];
    /**
     * @generated from protobuf field: repeated brandguard.approval.Prediction approved = 8;
     */
    approved: Prediction[];
    /**
     * @generated from protobuf field: meta.FloatRange confidence = 9;
     */
    confidence?: FloatRange;
    /**
     * @generated from protobuf field: repeated string uploaded_by = 10 [json_name = "uploaders"];
     */
    uploaded_by: string[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextSearchResponseItem
 */
export interface TextSearchResponseItem {
    /**
     * @generated from protobuf field: brandguard.text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextSearchResponse
 */
export interface TextSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.text.TextSearchResponseItem items = 1;
     */
    items: TextSearchResponseItem[];
    /**
     * @generated from protobuf field: brandguard.text.TextSearchRequest next = 2;
     */
    next?: TextSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextFindRequest
 */
export interface TextFindRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextFindResponse
 */
export interface TextFindResponse {
    /**
     * @generated from protobuf field: brandguard.text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextPatchRequest
 */
export interface TextPatchRequest {
    /**
     * @generated from protobuf field: brandguard.text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextPatchResponse
 */
export interface TextPatchResponse {
    /**
     * @generated from protobuf field: brandguard.text.TextMedia media = 1;
     */
    media?: TextMedia;
    /**
     * @generated from protobuf field: brandguard.text.TextEvent event = 2;
     */
    event?: TextEvent;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextTrainRequest
 */
export interface TextTrainRequest {
    /**
     * @generated from protobuf field: int64 ago = 1;
     */
    ago: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextTrainResponse
 */
export interface TextTrainResponse {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextKernelSearchRequest
 */
export interface TextKernelSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: meta.DateRange created = 5;
     */
    created?: DateRange;
    /**
     * @generated from protobuf field: uint64 limit = 6;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextKernelSearchResponse
 */
export interface TextKernelSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.kernel.KernelMetadata items = 1;
     */
    items: KernelMetadata[];
    /**
     * @generated from protobuf field: brandguard.text.TextKernelSearchRequest next = 2;
     */
    next?: TextKernelSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextKernelFindRequest
 */
export interface TextKernelFindRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextKernelFindResponse
 */
export interface TextKernelFindResponse {
    /**
     * @generated from protobuf field: brandguard.kernel.KernelMetadata kernel = 1;
     */
    kernel?: KernelMetadata;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextKernelSettingsCreateRequest
 */
export interface TextKernelSettingsCreateRequest {
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelSetting settings = 1;
     */
    settings?: TextKernelSetting;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextKernelSettingsCreateResponse
 */
export interface TextKernelSettingsCreateResponse {
    /**
     * @generated from protobuf field: brandguard.kernel.KernelMetadata kernel = 1;
     */
    kernel?: KernelMetadata;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextKernelSettingsRequest
 */
export interface TextKernelSettingsRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextKernelSettingsResponse
 */
export interface TextKernelSettingsResponse {
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelSetting settings = 1;
     */
    settings?: TextKernelSetting;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextRescoreRequest
 */
export interface TextRescoreRequest {
    /**
     * @generated from protobuf field: int64 delay = 1;
     */
    delay: bigint;
    /**
     * @generated from protobuf field: meta.DateRange window = 2;
     */
    window?: DateRange;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextGenerationRequest
 */
export interface TextGenerationRequest {
    /**
     * @generated from protobuf field: string prompt = 1;
     */
    prompt: string;
    /**
     * @generated from protobuf field: int32 count = 2;
     */
    count: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextGenerationResponse
 */
export interface TextGenerationResponse {
    /**
     * @generated from protobuf field: repeated brandguard.text.TextSearchResponseItem items = 1;
     */
    items: TextSearchResponseItem[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.text.TextRescoreResponse
 */
export interface TextRescoreResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class TextEvent$Type extends MessageType<TextEvent> {
    constructor() {
        super("brandguard.text.TextEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "approved", kind: "enum", T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 5, name: "observation", kind: "enum", T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 6, name: "queue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1000, name: "confidence", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1004, name: "onbrand", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1005, name: "grammar", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1006, name: "profanity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1007, name: "racism", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1008, name: "text_tone_and_voice", kind: "scalar", localName: "text_tone_and_voice", jsonName: "text_tone_and_voice", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1009, name: "text_admiration", kind: "scalar", localName: "text_admiration", jsonName: "text_admiration", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1010, name: "text_amusement", kind: "scalar", localName: "text_amusement", jsonName: "text_amusement", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1011, name: "text_anger", kind: "scalar", localName: "text_anger", jsonName: "text_anger", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1012, name: "text_annoyance", kind: "scalar", localName: "text_annoyance", jsonName: "text_annoyance", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1013, name: "text_approval", kind: "scalar", localName: "text_approval", jsonName: "text_approval", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1014, name: "text_caring", kind: "scalar", localName: "text_caring", jsonName: "text_caring", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1015, name: "text_confusion", kind: "scalar", localName: "text_confusion", jsonName: "text_confusion", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1016, name: "text_curiosity", kind: "scalar", localName: "text_curiosity", jsonName: "text_curiosity", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1017, name: "text_desire", kind: "scalar", localName: "text_desire", jsonName: "text_desire", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1018, name: "text_disappointment", kind: "scalar", localName: "text_disappointment", jsonName: "text_disappointment", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1019, name: "text_disapproval", kind: "scalar", localName: "text_disapproval", jsonName: "text_disapproval", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1020, name: "text_disgust", kind: "scalar", localName: "text_disgust", jsonName: "text_disgust", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1021, name: "text_embarrassment", kind: "scalar", localName: "text_embarrassment", jsonName: "text_embarrassment", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1022, name: "text_excitement", kind: "scalar", localName: "text_excitement", jsonName: "text_excitement", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1023, name: "text_fear", kind: "scalar", localName: "text_fear", jsonName: "text_fear", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1024, name: "text_gratitude", kind: "scalar", localName: "text_gratitude", jsonName: "text_gratitude", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1025, name: "text_grief", kind: "scalar", localName: "text_grief", jsonName: "text_grief", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1026, name: "text_joy", kind: "scalar", localName: "text_joy", jsonName: "text_joy", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1027, name: "text_love", kind: "scalar", localName: "text_love", jsonName: "text_love", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1028, name: "text_nervousness", kind: "scalar", localName: "text_nervousness", jsonName: "text_nervousness", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1029, name: "text_neutral", kind: "scalar", localName: "text_neutral", jsonName: "text_neutral", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1030, name: "text_optimism", kind: "scalar", localName: "text_optimism", jsonName: "text_optimism", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1031, name: "text_pride", kind: "scalar", localName: "text_pride", jsonName: "text_pride", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1032, name: "text_realization", kind: "scalar", localName: "text_realization", jsonName: "text_realization", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1033, name: "text_relief", kind: "scalar", localName: "text_relief", jsonName: "text_relief", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1034, name: "text_remorse", kind: "scalar", localName: "text_remorse", jsonName: "text_remorse", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1035, name: "text_sadness", kind: "scalar", localName: "text_sadness", jsonName: "text_sadness", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1036, name: "text_surprise", kind: "scalar", localName: "text_surprise", jsonName: "text_surprise", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 1037, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 1038, name: "text_reference_rules", kind: "scalar", localName: "text_reference_rules", jsonName: "text_reference_rules", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<TextEvent>): TextEvent {
        const message = { id: "", account_id: "", brand_id: "", approved: 0, observation: 0, queue: "", confidence: 0, onbrand: 0, grammar: 0, profanity: 0, racism: 0, text_tone_and_voice: 0, text_admiration: 0, text_amusement: 0, text_anger: 0, text_annoyance: 0, text_approval: 0, text_caring: 0, text_confusion: 0, text_curiosity: 0, text_desire: 0, text_disappointment: 0, text_disapproval: 0, text_disgust: 0, text_embarrassment: 0, text_excitement: 0, text_fear: 0, text_gratitude: 0, text_grief: 0, text_joy: 0, text_love: 0, text_nervousness: 0, text_neutral: 0, text_optimism: 0, text_pride: 0, text_realization: 0, text_relief: 0, text_remorse: 0, text_sadness: 0, text_surprise: 0, updated_at: "", text_reference_rules: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextEvent): TextEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* brandguard.approval.Prediction approved */ 4:
                    message.approved = reader.int32();
                    break;
                case /* brandguard.approval.Prediction observation */ 5:
                    message.observation = reader.int32();
                    break;
                case /* string queue */ 6:
                    message.queue = reader.string();
                    break;
                case /* double confidence */ 1000:
                    message.confidence = reader.double();
                    break;
                case /* double onbrand */ 1004:
                    message.onbrand = reader.double();
                    break;
                case /* double grammar */ 1005:
                    message.grammar = reader.double();
                    break;
                case /* double profanity */ 1006:
                    message.profanity = reader.double();
                    break;
                case /* double racism */ 1007:
                    message.racism = reader.double();
                    break;
                case /* double text_tone_and_voice = 1008 [json_name = "text_tone_and_voice"];*/ 1008:
                    message.text_tone_and_voice = reader.double();
                    break;
                case /* double text_admiration = 1009 [json_name = "text_admiration"];*/ 1009:
                    message.text_admiration = reader.double();
                    break;
                case /* double text_amusement = 1010 [json_name = "text_amusement"];*/ 1010:
                    message.text_amusement = reader.double();
                    break;
                case /* double text_anger = 1011 [json_name = "text_anger"];*/ 1011:
                    message.text_anger = reader.double();
                    break;
                case /* double text_annoyance = 1012 [json_name = "text_annoyance"];*/ 1012:
                    message.text_annoyance = reader.double();
                    break;
                case /* double text_approval = 1013 [json_name = "text_approval"];*/ 1013:
                    message.text_approval = reader.double();
                    break;
                case /* double text_caring = 1014 [json_name = "text_caring"];*/ 1014:
                    message.text_caring = reader.double();
                    break;
                case /* double text_confusion = 1015 [json_name = "text_confusion"];*/ 1015:
                    message.text_confusion = reader.double();
                    break;
                case /* double text_curiosity = 1016 [json_name = "text_curiosity"];*/ 1016:
                    message.text_curiosity = reader.double();
                    break;
                case /* double text_desire = 1017 [json_name = "text_desire"];*/ 1017:
                    message.text_desire = reader.double();
                    break;
                case /* double text_disappointment = 1018 [json_name = "text_disappointment"];*/ 1018:
                    message.text_disappointment = reader.double();
                    break;
                case /* double text_disapproval = 1019 [json_name = "text_disapproval"];*/ 1019:
                    message.text_disapproval = reader.double();
                    break;
                case /* double text_disgust = 1020 [json_name = "text_disgust"];*/ 1020:
                    message.text_disgust = reader.double();
                    break;
                case /* double text_embarrassment = 1021 [json_name = "text_embarrassment"];*/ 1021:
                    message.text_embarrassment = reader.double();
                    break;
                case /* double text_excitement = 1022 [json_name = "text_excitement"];*/ 1022:
                    message.text_excitement = reader.double();
                    break;
                case /* double text_fear = 1023 [json_name = "text_fear"];*/ 1023:
                    message.text_fear = reader.double();
                    break;
                case /* double text_gratitude = 1024 [json_name = "text_gratitude"];*/ 1024:
                    message.text_gratitude = reader.double();
                    break;
                case /* double text_grief = 1025 [json_name = "text_grief"];*/ 1025:
                    message.text_grief = reader.double();
                    break;
                case /* double text_joy = 1026 [json_name = "text_joy"];*/ 1026:
                    message.text_joy = reader.double();
                    break;
                case /* double text_love = 1027 [json_name = "text_love"];*/ 1027:
                    message.text_love = reader.double();
                    break;
                case /* double text_nervousness = 1028 [json_name = "text_nervousness"];*/ 1028:
                    message.text_nervousness = reader.double();
                    break;
                case /* double text_neutral = 1029 [json_name = "text_neutral"];*/ 1029:
                    message.text_neutral = reader.double();
                    break;
                case /* double text_optimism = 1030 [json_name = "text_optimism"];*/ 1030:
                    message.text_optimism = reader.double();
                    break;
                case /* double text_pride = 1031 [json_name = "text_pride"];*/ 1031:
                    message.text_pride = reader.double();
                    break;
                case /* double text_realization = 1032 [json_name = "text_realization"];*/ 1032:
                    message.text_realization = reader.double();
                    break;
                case /* double text_relief = 1033 [json_name = "text_relief"];*/ 1033:
                    message.text_relief = reader.double();
                    break;
                case /* double text_remorse = 1034 [json_name = "text_remorse"];*/ 1034:
                    message.text_remorse = reader.double();
                    break;
                case /* double text_sadness = 1035 [json_name = "text_sadness"];*/ 1035:
                    message.text_sadness = reader.double();
                    break;
                case /* double text_surprise = 1036 [json_name = "text_surprise"];*/ 1036:
                    message.text_surprise = reader.double();
                    break;
                case /* string updated_at = 1037 [json_name = "updated_at"];*/ 1037:
                    message.updated_at = reader.string();
                    break;
                case /* double text_reference_rules = 1038 [json_name = "text_reference_rules"];*/ 1038:
                    message.text_reference_rules = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* brandguard.approval.Prediction approved = 4; */
        if (message.approved !== 0)
            writer.tag(4, WireType.Varint).int32(message.approved);
        /* brandguard.approval.Prediction observation = 5; */
        if (message.observation !== 0)
            writer.tag(5, WireType.Varint).int32(message.observation);
        /* string queue = 6; */
        if (message.queue !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.queue);
        /* double confidence = 1000; */
        if (message.confidence !== 0)
            writer.tag(1000, WireType.Bit64).double(message.confidence);
        /* double onbrand = 1004; */
        if (message.onbrand !== 0)
            writer.tag(1004, WireType.Bit64).double(message.onbrand);
        /* double grammar = 1005; */
        if (message.grammar !== 0)
            writer.tag(1005, WireType.Bit64).double(message.grammar);
        /* double profanity = 1006; */
        if (message.profanity !== 0)
            writer.tag(1006, WireType.Bit64).double(message.profanity);
        /* double racism = 1007; */
        if (message.racism !== 0)
            writer.tag(1007, WireType.Bit64).double(message.racism);
        /* double text_tone_and_voice = 1008 [json_name = "text_tone_and_voice"]; */
        if (message.text_tone_and_voice !== 0)
            writer.tag(1008, WireType.Bit64).double(message.text_tone_and_voice);
        /* double text_admiration = 1009 [json_name = "text_admiration"]; */
        if (message.text_admiration !== 0)
            writer.tag(1009, WireType.Bit64).double(message.text_admiration);
        /* double text_amusement = 1010 [json_name = "text_amusement"]; */
        if (message.text_amusement !== 0)
            writer.tag(1010, WireType.Bit64).double(message.text_amusement);
        /* double text_anger = 1011 [json_name = "text_anger"]; */
        if (message.text_anger !== 0)
            writer.tag(1011, WireType.Bit64).double(message.text_anger);
        /* double text_annoyance = 1012 [json_name = "text_annoyance"]; */
        if (message.text_annoyance !== 0)
            writer.tag(1012, WireType.Bit64).double(message.text_annoyance);
        /* double text_approval = 1013 [json_name = "text_approval"]; */
        if (message.text_approval !== 0)
            writer.tag(1013, WireType.Bit64).double(message.text_approval);
        /* double text_caring = 1014 [json_name = "text_caring"]; */
        if (message.text_caring !== 0)
            writer.tag(1014, WireType.Bit64).double(message.text_caring);
        /* double text_confusion = 1015 [json_name = "text_confusion"]; */
        if (message.text_confusion !== 0)
            writer.tag(1015, WireType.Bit64).double(message.text_confusion);
        /* double text_curiosity = 1016 [json_name = "text_curiosity"]; */
        if (message.text_curiosity !== 0)
            writer.tag(1016, WireType.Bit64).double(message.text_curiosity);
        /* double text_desire = 1017 [json_name = "text_desire"]; */
        if (message.text_desire !== 0)
            writer.tag(1017, WireType.Bit64).double(message.text_desire);
        /* double text_disappointment = 1018 [json_name = "text_disappointment"]; */
        if (message.text_disappointment !== 0)
            writer.tag(1018, WireType.Bit64).double(message.text_disappointment);
        /* double text_disapproval = 1019 [json_name = "text_disapproval"]; */
        if (message.text_disapproval !== 0)
            writer.tag(1019, WireType.Bit64).double(message.text_disapproval);
        /* double text_disgust = 1020 [json_name = "text_disgust"]; */
        if (message.text_disgust !== 0)
            writer.tag(1020, WireType.Bit64).double(message.text_disgust);
        /* double text_embarrassment = 1021 [json_name = "text_embarrassment"]; */
        if (message.text_embarrassment !== 0)
            writer.tag(1021, WireType.Bit64).double(message.text_embarrassment);
        /* double text_excitement = 1022 [json_name = "text_excitement"]; */
        if (message.text_excitement !== 0)
            writer.tag(1022, WireType.Bit64).double(message.text_excitement);
        /* double text_fear = 1023 [json_name = "text_fear"]; */
        if (message.text_fear !== 0)
            writer.tag(1023, WireType.Bit64).double(message.text_fear);
        /* double text_gratitude = 1024 [json_name = "text_gratitude"]; */
        if (message.text_gratitude !== 0)
            writer.tag(1024, WireType.Bit64).double(message.text_gratitude);
        /* double text_grief = 1025 [json_name = "text_grief"]; */
        if (message.text_grief !== 0)
            writer.tag(1025, WireType.Bit64).double(message.text_grief);
        /* double text_joy = 1026 [json_name = "text_joy"]; */
        if (message.text_joy !== 0)
            writer.tag(1026, WireType.Bit64).double(message.text_joy);
        /* double text_love = 1027 [json_name = "text_love"]; */
        if (message.text_love !== 0)
            writer.tag(1027, WireType.Bit64).double(message.text_love);
        /* double text_nervousness = 1028 [json_name = "text_nervousness"]; */
        if (message.text_nervousness !== 0)
            writer.tag(1028, WireType.Bit64).double(message.text_nervousness);
        /* double text_neutral = 1029 [json_name = "text_neutral"]; */
        if (message.text_neutral !== 0)
            writer.tag(1029, WireType.Bit64).double(message.text_neutral);
        /* double text_optimism = 1030 [json_name = "text_optimism"]; */
        if (message.text_optimism !== 0)
            writer.tag(1030, WireType.Bit64).double(message.text_optimism);
        /* double text_pride = 1031 [json_name = "text_pride"]; */
        if (message.text_pride !== 0)
            writer.tag(1031, WireType.Bit64).double(message.text_pride);
        /* double text_realization = 1032 [json_name = "text_realization"]; */
        if (message.text_realization !== 0)
            writer.tag(1032, WireType.Bit64).double(message.text_realization);
        /* double text_relief = 1033 [json_name = "text_relief"]; */
        if (message.text_relief !== 0)
            writer.tag(1033, WireType.Bit64).double(message.text_relief);
        /* double text_remorse = 1034 [json_name = "text_remorse"]; */
        if (message.text_remorse !== 0)
            writer.tag(1034, WireType.Bit64).double(message.text_remorse);
        /* double text_sadness = 1035 [json_name = "text_sadness"]; */
        if (message.text_sadness !== 0)
            writer.tag(1035, WireType.Bit64).double(message.text_sadness);
        /* double text_surprise = 1036 [json_name = "text_surprise"]; */
        if (message.text_surprise !== 0)
            writer.tag(1036, WireType.Bit64).double(message.text_surprise);
        /* string updated_at = 1037 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(1037, WireType.LengthDelimited).string(message.updated_at);
        /* double text_reference_rules = 1038 [json_name = "text_reference_rules"]; */
        if (message.text_reference_rules !== 0)
            writer.tag(1038, WireType.Bit64).double(message.text_reference_rules);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextEvent
 */
export const TextEvent = new TextEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextMedia$Type extends MessageType<TextMedia> {
    constructor() {
        super("brandguard.text.TextMedia", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "uploaded_by", kind: "scalar", localName: "uploaded_by", jsonName: "uploaded_by", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "mimetype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "mimetype_md5", kind: "scalar", localName: "mimetype_md5", jsonName: "mimetype_md5", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "tombstoned_at", kind: "scalar", localName: "tombstoned_at", jsonName: "tombstoned_at", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "scored_at", kind: "scalar", localName: "scored_at", jsonName: "scored_at", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "reviewed_by", kind: "scalar", localName: "reviewed_by", jsonName: "reviewed_by", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TextMedia>): TextMedia {
        const message = { id: "", account_id: "", brand_id: "", uploaded_by: "", md5: "", mimetype: "", mimetype_md5: "", created_at: "", updated_at: "", tombstoned_at: "", scored_at: "", reviewed_by: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextMedia>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextMedia): TextMedia {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string uploaded_by = 4 [json_name = "uploaded_by"];*/ 4:
                    message.uploaded_by = reader.string();
                    break;
                case /* string md5 */ 5:
                    message.md5 = reader.string();
                    break;
                case /* string mimetype */ 6:
                    message.mimetype = reader.string();
                    break;
                case /* string mimetype_md5 = 7 [json_name = "mimetype_md5"];*/ 7:
                    message.mimetype_md5 = reader.string();
                    break;
                case /* string created_at = 8 [json_name = "created_at"];*/ 8:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 9 [json_name = "updated_at"];*/ 9:
                    message.updated_at = reader.string();
                    break;
                case /* string tombstoned_at = 10 [json_name = "tombstoned_at"];*/ 10:
                    message.tombstoned_at = reader.string();
                    break;
                case /* string scored_at = 11 [json_name = "scored_at"];*/ 11:
                    message.scored_at = reader.string();
                    break;
                case /* string reviewed_by = 12 [json_name = "reviewed_by"];*/ 12:
                    message.reviewed_by = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextMedia, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string uploaded_by = 4 [json_name = "uploaded_by"]; */
        if (message.uploaded_by !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.uploaded_by);
        /* string md5 = 5; */
        if (message.md5 !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.md5);
        /* string mimetype = 6; */
        if (message.mimetype !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mimetype);
        /* string mimetype_md5 = 7 [json_name = "mimetype_md5"]; */
        if (message.mimetype_md5 !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mimetype_md5);
        /* string created_at = 8 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 9 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.updated_at);
        /* string tombstoned_at = 10 [json_name = "tombstoned_at"]; */
        if (message.tombstoned_at !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.tombstoned_at);
        /* string scored_at = 11 [json_name = "scored_at"]; */
        if (message.scored_at !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.scored_at);
        /* string reviewed_by = 12 [json_name = "reviewed_by"]; */
        if (message.reviewed_by !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.reviewed_by);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextMedia
 */
export const TextMedia = new TextMedia$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextUploadRequest$Type extends MessageType<TextUploadRequest> {
    constructor() {
        super("brandguard.text.TextUploadRequest", []);
    }
    create(value?: PartialMessage<TextUploadRequest>): TextUploadRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextUploadRequest): TextUploadRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TextUploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextUploadRequest
 */
export const TextUploadRequest = new TextUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextUploadResponse$Type extends MessageType<TextUploadResponse> {
    constructor() {
        super("brandguard.text.TextUploadResponse", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextUploadResponse>): TextUploadResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextUploadResponse): TextUploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextUploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextUploadResponse
 */
export const TextUploadResponse = new TextUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextSearchRequest$Type extends MessageType<TextSearchRequest> {
    constructor() {
        super("brandguard.text.TextSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "queues", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deprecated_uploaded_by", kind: "scalar", localName: "deprecated_uploaded_by", jsonName: "uploaded_by", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created", kind: "message", T: () => DateRange },
            { no: 6, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "observation", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 8, name: "approved", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["brandguard.approval.Prediction", Prediction] },
            { no: 9, name: "confidence", kind: "message", T: () => FloatRange },
            { no: 10, name: "uploaded_by", kind: "scalar", localName: "uploaded_by", jsonName: "uploaders", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TextSearchRequest>): TextSearchRequest {
        const message = { offset: 0n, queues: [], deprecated_uploaded_by: "", brand_id: "", limit: 0n, observation: [], approved: [], uploaded_by: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextSearchRequest): TextSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string queues */ 2:
                    message.queues.push(reader.string());
                    break;
                case /* string deprecated_uploaded_by = 3 [json_name = "uploaded_by"];*/ 3:
                    message.deprecated_uploaded_by = reader.string();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* meta.DateRange created */ 5:
                    message.created = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* uint64 limit */ 6:
                    message.limit = reader.uint64().toBigInt();
                    break;
                case /* repeated brandguard.approval.Prediction observation */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.observation.push(reader.int32());
                    else
                        message.observation.push(reader.int32());
                    break;
                case /* repeated brandguard.approval.Prediction approved */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.approved.push(reader.int32());
                    else
                        message.approved.push(reader.int32());
                    break;
                case /* meta.FloatRange confidence */ 9:
                    message.confidence = FloatRange.internalBinaryRead(reader, reader.uint32(), options, message.confidence);
                    break;
                case /* repeated string uploaded_by = 10 [json_name = "uploaders"];*/ 10:
                    message.uploaded_by.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string queues = 2; */
        for (let i = 0; i < message.queues.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.queues[i]);
        /* string deprecated_uploaded_by = 3 [json_name = "uploaded_by"]; */
        if (message.deprecated_uploaded_by !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deprecated_uploaded_by);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* meta.DateRange created = 5; */
        if (message.created)
            DateRange.internalBinaryWrite(message.created, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 limit = 6; */
        if (message.limit !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.limit);
        /* repeated brandguard.approval.Prediction observation = 7; */
        if (message.observation.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.observation.length; i++)
                writer.int32(message.observation[i]);
            writer.join();
        }
        /* repeated brandguard.approval.Prediction approved = 8; */
        if (message.approved.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.approved.length; i++)
                writer.int32(message.approved[i]);
            writer.join();
        }
        /* meta.FloatRange confidence = 9; */
        if (message.confidence)
            FloatRange.internalBinaryWrite(message.confidence, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated string uploaded_by = 10 [json_name = "uploaders"]; */
        for (let i = 0; i < message.uploaded_by.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.uploaded_by[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextSearchRequest
 */
export const TextSearchRequest = new TextSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextSearchResponseItem$Type extends MessageType<TextSearchResponseItem> {
    constructor() {
        super("brandguard.text.TextSearchResponseItem", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextSearchResponseItem>): TextSearchResponseItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextSearchResponseItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextSearchResponseItem): TextSearchResponseItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextSearchResponseItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextSearchResponseItem
 */
export const TextSearchResponseItem = new TextSearchResponseItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextSearchResponse$Type extends MessageType<TextSearchResponse> {
    constructor() {
        super("brandguard.text.TextSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TextSearchResponseItem },
            { no: 2, name: "next", kind: "message", T: () => TextSearchRequest }
        ]);
    }
    create(value?: PartialMessage<TextSearchResponse>): TextSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextSearchResponse): TextSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.text.TextSearchResponseItem items */ 1:
                    message.items.push(TextSearchResponseItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.text.TextSearchRequest next */ 2:
                    message.next = TextSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.text.TextSearchResponseItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            TextSearchResponseItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.text.TextSearchRequest next = 2; */
        if (message.next)
            TextSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextSearchResponse
 */
export const TextSearchResponse = new TextSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextFindRequest$Type extends MessageType<TextFindRequest> {
    constructor() {
        super("brandguard.text.TextFindRequest", []);
    }
    create(value?: PartialMessage<TextFindRequest>): TextFindRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextFindRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextFindRequest): TextFindRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TextFindRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextFindRequest
 */
export const TextFindRequest = new TextFindRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextFindResponse$Type extends MessageType<TextFindResponse> {
    constructor() {
        super("brandguard.text.TextFindResponse", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextFindResponse>): TextFindResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextFindResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextFindResponse): TextFindResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextFindResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextFindResponse
 */
export const TextFindResponse = new TextFindResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextPatchRequest$Type extends MessageType<TextPatchRequest> {
    constructor() {
        super("brandguard.text.TextPatchRequest", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextPatchRequest>): TextPatchRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextPatchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextPatchRequest): TextPatchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextPatchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextPatchRequest
 */
export const TextPatchRequest = new TextPatchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextPatchResponse$Type extends MessageType<TextPatchResponse> {
    constructor() {
        super("brandguard.text.TextPatchResponse", [
            { no: 1, name: "media", kind: "message", T: () => TextMedia },
            { no: 2, name: "event", kind: "message", T: () => TextEvent }
        ]);
    }
    create(value?: PartialMessage<TextPatchResponse>): TextPatchResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextPatchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextPatchResponse): TextPatchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.text.TextMedia media */ 1:
                    message.media = TextMedia.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* brandguard.text.TextEvent event */ 2:
                    message.event = TextEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextPatchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.text.TextMedia media = 1; */
        if (message.media)
            TextMedia.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.text.TextEvent event = 2; */
        if (message.event)
            TextEvent.internalBinaryWrite(message.event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextPatchResponse
 */
export const TextPatchResponse = new TextPatchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextTrainRequest$Type extends MessageType<TextTrainRequest> {
    constructor() {
        super("brandguard.text.TextTrainRequest", [
            { no: 1, name: "ago", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<TextTrainRequest>): TextTrainRequest {
        const message = { ago: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextTrainRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextTrainRequest): TextTrainRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ago */ 1:
                    message.ago = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextTrainRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ago = 1; */
        if (message.ago !== 0n)
            writer.tag(1, WireType.Varint).int64(message.ago);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextTrainRequest
 */
export const TextTrainRequest = new TextTrainRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextTrainResponse$Type extends MessageType<TextTrainResponse> {
    constructor() {
        super("brandguard.text.TextTrainResponse", []);
    }
    create(value?: PartialMessage<TextTrainResponse>): TextTrainResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextTrainResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextTrainResponse): TextTrainResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TextTrainResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextTrainResponse
 */
export const TextTrainResponse = new TextTrainResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelSearchRequest$Type extends MessageType<TextKernelSearchRequest> {
    constructor() {
        super("brandguard.text.TextKernelSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created", kind: "message", T: () => DateRange },
            { no: 6, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<TextKernelSearchRequest>): TextKernelSearchRequest {
        const message = { offset: 0n, brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelSearchRequest): TextKernelSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* meta.DateRange created */ 5:
                    message.created = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* uint64 limit */ 6:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* meta.DateRange created = 5; */
        if (message.created)
            DateRange.internalBinaryWrite(message.created, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 limit = 6; */
        if (message.limit !== 0n)
            writer.tag(6, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextKernelSearchRequest
 */
export const TextKernelSearchRequest = new TextKernelSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelSearchResponse$Type extends MessageType<TextKernelSearchResponse> {
    constructor() {
        super("brandguard.text.TextKernelSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KernelMetadata },
            { no: 2, name: "next", kind: "message", T: () => TextKernelSearchRequest }
        ]);
    }
    create(value?: PartialMessage<TextKernelSearchResponse>): TextKernelSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelSearchResponse): TextKernelSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.kernel.KernelMetadata items */ 1:
                    message.items.push(KernelMetadata.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.text.TextKernelSearchRequest next */ 2:
                    message.next = TextKernelSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.kernel.KernelMetadata items = 1; */
        for (let i = 0; i < message.items.length; i++)
            KernelMetadata.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.text.TextKernelSearchRequest next = 2; */
        if (message.next)
            TextKernelSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextKernelSearchResponse
 */
export const TextKernelSearchResponse = new TextKernelSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelFindRequest$Type extends MessageType<TextKernelFindRequest> {
    constructor() {
        super("brandguard.text.TextKernelFindRequest", []);
    }
    create(value?: PartialMessage<TextKernelFindRequest>): TextKernelFindRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelFindRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelFindRequest): TextKernelFindRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TextKernelFindRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextKernelFindRequest
 */
export const TextKernelFindRequest = new TextKernelFindRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelFindResponse$Type extends MessageType<TextKernelFindResponse> {
    constructor() {
        super("brandguard.text.TextKernelFindResponse", [
            { no: 1, name: "kernel", kind: "message", T: () => KernelMetadata }
        ]);
    }
    create(value?: PartialMessage<TextKernelFindResponse>): TextKernelFindResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelFindResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelFindResponse): TextKernelFindResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.kernel.KernelMetadata kernel */ 1:
                    message.kernel = KernelMetadata.internalBinaryRead(reader, reader.uint32(), options, message.kernel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelFindResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.kernel.KernelMetadata kernel = 1; */
        if (message.kernel)
            KernelMetadata.internalBinaryWrite(message.kernel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextKernelFindResponse
 */
export const TextKernelFindResponse = new TextKernelFindResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelSettingsCreateRequest$Type extends MessageType<TextKernelSettingsCreateRequest> {
    constructor() {
        super("brandguard.text.TextKernelSettingsCreateRequest", [
            { no: 1, name: "settings", kind: "message", T: () => TextKernelSetting }
        ]);
    }
    create(value?: PartialMessage<TextKernelSettingsCreateRequest>): TextKernelSettingsCreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelSettingsCreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelSettingsCreateRequest): TextKernelSettingsCreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.kernel.TextKernelSetting settings */ 1:
                    message.settings = TextKernelSetting.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelSettingsCreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.kernel.TextKernelSetting settings = 1; */
        if (message.settings)
            TextKernelSetting.internalBinaryWrite(message.settings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextKernelSettingsCreateRequest
 */
export const TextKernelSettingsCreateRequest = new TextKernelSettingsCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelSettingsCreateResponse$Type extends MessageType<TextKernelSettingsCreateResponse> {
    constructor() {
        super("brandguard.text.TextKernelSettingsCreateResponse", [
            { no: 1, name: "kernel", kind: "message", T: () => KernelMetadata }
        ]);
    }
    create(value?: PartialMessage<TextKernelSettingsCreateResponse>): TextKernelSettingsCreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelSettingsCreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelSettingsCreateResponse): TextKernelSettingsCreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.kernel.KernelMetadata kernel */ 1:
                    message.kernel = KernelMetadata.internalBinaryRead(reader, reader.uint32(), options, message.kernel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelSettingsCreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.kernel.KernelMetadata kernel = 1; */
        if (message.kernel)
            KernelMetadata.internalBinaryWrite(message.kernel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextKernelSettingsCreateResponse
 */
export const TextKernelSettingsCreateResponse = new TextKernelSettingsCreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelSettingsRequest$Type extends MessageType<TextKernelSettingsRequest> {
    constructor() {
        super("brandguard.text.TextKernelSettingsRequest", []);
    }
    create(value?: PartialMessage<TextKernelSettingsRequest>): TextKernelSettingsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelSettingsRequest): TextKernelSettingsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TextKernelSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextKernelSettingsRequest
 */
export const TextKernelSettingsRequest = new TextKernelSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelSettingsResponse$Type extends MessageType<TextKernelSettingsResponse> {
    constructor() {
        super("brandguard.text.TextKernelSettingsResponse", [
            { no: 1, name: "settings", kind: "message", T: () => TextKernelSetting }
        ]);
    }
    create(value?: PartialMessage<TextKernelSettingsResponse>): TextKernelSettingsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelSettingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelSettingsResponse): TextKernelSettingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguard.kernel.TextKernelSetting settings */ 1:
                    message.settings = TextKernelSetting.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelSettingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguard.kernel.TextKernelSetting settings = 1; */
        if (message.settings)
            TextKernelSetting.internalBinaryWrite(message.settings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextKernelSettingsResponse
 */
export const TextKernelSettingsResponse = new TextKernelSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextRescoreRequest$Type extends MessageType<TextRescoreRequest> {
    constructor() {
        super("brandguard.text.TextRescoreRequest", [
            { no: 1, name: "delay", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "window", kind: "message", T: () => DateRange }
        ]);
    }
    create(value?: PartialMessage<TextRescoreRequest>): TextRescoreRequest {
        const message = { delay: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextRescoreRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextRescoreRequest): TextRescoreRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 delay */ 1:
                    message.delay = reader.int64().toBigInt();
                    break;
                case /* meta.DateRange window */ 2:
                    message.window = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.window);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextRescoreRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 delay = 1; */
        if (message.delay !== 0n)
            writer.tag(1, WireType.Varint).int64(message.delay);
        /* meta.DateRange window = 2; */
        if (message.window)
            DateRange.internalBinaryWrite(message.window, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextRescoreRequest
 */
export const TextRescoreRequest = new TextRescoreRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextGenerationRequest$Type extends MessageType<TextGenerationRequest> {
    constructor() {
        super("brandguard.text.TextGenerationRequest", [
            { no: 1, name: "prompt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TextGenerationRequest>): TextGenerationRequest {
        const message = { prompt: "", count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextGenerationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextGenerationRequest): TextGenerationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string prompt */ 1:
                    message.prompt = reader.string();
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextGenerationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string prompt = 1; */
        if (message.prompt !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.prompt);
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextGenerationRequest
 */
export const TextGenerationRequest = new TextGenerationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextGenerationResponse$Type extends MessageType<TextGenerationResponse> {
    constructor() {
        super("brandguard.text.TextGenerationResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TextSearchResponseItem }
        ]);
    }
    create(value?: PartialMessage<TextGenerationResponse>): TextGenerationResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextGenerationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextGenerationResponse): TextGenerationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.text.TextSearchResponseItem items */ 1:
                    message.items.push(TextSearchResponseItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextGenerationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.text.TextSearchResponseItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            TextSearchResponseItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextGenerationResponse
 */
export const TextGenerationResponse = new TextGenerationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextRescoreResponse$Type extends MessageType<TextRescoreResponse> {
    constructor() {
        super("brandguard.text.TextRescoreResponse", []);
    }
    create(value?: PartialMessage<TextRescoreResponse>): TextRescoreResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextRescoreResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextRescoreResponse): TextRescoreResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TextRescoreResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.text.TextRescoreResponse
 */
export const TextRescoreResponse = new TextRescoreResponse$Type();

import React from "react"
import classnames from "classnames"
import * as containers from "layouts/containers"
import { css } from "@emotion/css"

interface props extends containers.FlexProps, containers.DefaultStyling {
	cause?: JSX.Element
}

const container = css`
	&.error > * {
		grid-area: 1 / 1;
	}
`

export function Overlay(props: React.PropsWithChildren<props>): JSX.Element {
	const { cause, children, styled, className, ...rest } = props

	if (cause) {
		return (
			<containers.grid className={classnames("error", container, className)} grid="auto/auto" {...rest}>
				{props.children}
				<containers.flex styled={styled} className="cause" width="100%" height="100%" m="auto">
					{cause}
				</containers.flex>
			</containers.grid>
		)
	}

	return <>{children}</>
}

import React from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as login from "./login"
import * as pkce from "./login/login.pkce"

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	const location = useLocation()
	const decoded = httpx.qs.parse(location.search, {
		parseBooleans: true,
		parseNumbers: true,
	}) as unknown as sessions.api.LoginOptions

	const loginopts = sessions.api.loginopts.zero({ ...decoded, ...location.state })

	return (
		<Routes>
			<Route path="/login" element={<login.Container {...loginopts} />} />
			<Route path="/logout" element={<login.Logout />} />
			<Route path="/authorize" element={<login.Authorize />} />
			<Route
				path="/authorize.html"
				element={
					<pkce.Display
						flexDirection="column"
						minHeight="100vh"
						margin="0"
						flex="1"
						background={layouts.theme.backgrounds.lightblue}
					>
						<layouts.Flex mt="25px">
							<layouts.buttons.primary
								width="180px"
								height="38px"
								color={layouts.theme.colors.blue.bright}
								onClick={() => window.close()}
							>
								Done
							</layouts.buttons.primary>
						</layouts.Flex>
					</pkce.Display>
				}
			/>
			<Route path="*" element={props.children} />
		</Routes>
	)
}

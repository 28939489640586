import React from "react"
import * as timex from "timex"
import styled from "@emotion/styled"
import * as containers from "layouts/containers"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

interface DateProps {
	selected: Date
	onChange(d: Date): void
}

export function Date(props: DateProps): JSX.Element {
	const { selected, onChange } = props

	return (
		<React.Fragment>
			<DatePicker selected={selected} onChange={onChange} />
		</React.Fragment>
	)
}

interface DateRangeProps {
	startDate?: Date
	endDate?: Date
	minStartDate?: Date
	maxStartDate?: Date
	minEndDate?: Date
	maxEndDate?: Date
	placeholderText?: string
	changeStart(d: Date): void
	changeEnd(d: Date): void
}

const Container = styled(containers.flex)`
	.react-datepicker-wrapper {
		flex: 1;

		.react-datepicker__input-container {
			height: 100%;

			input {
				border-radius: 0.5em;
				height: 100%;
			}
		}
	}
`
export function DateRange(props: DateRangeProps & containers.FlexProps): JSX.Element {
	const { startDate, endDate, minStartDate, maxStartDate, minEndDate, maxEndDate, changeStart, changeEnd, ...rest } =
		props

	return (
		<Container {...rest}>
			<DatePicker
				selected={startDate}
				onChange={(date: Date) => changeStart(date)}
				selectsStart
				startDate={startDate}
				minDate={minStartDate}
				maxDate={maxStartDate}
				endDate={endDate}
			/>
			<containers.span mx="5px" />
			<DatePicker
				selected={endDate}
				onChange={(date: Date) => changeEnd(date)}
				selectsEnd
				startDate={startDate}
				minDate={minEndDate}
				maxDate={maxEndDate}
				endDate={endDate}
			/>
		</Container>
	)
}

export function DateRangeOneDatepicker(props: DateRangeProps & containers.FlexProps): JSX.Element {
	const { startDate, endDate, minStartDate, maxEndDate, changeStart, changeEnd, placeholderText, ...rest } = props
	const infiso = timex.infinity().toMillis()
	const neginfiso = timex.negInfinity().toMillis()

	const onChange = (dates: [Date | undefined, Date | undefined]) => {
		const [start, end] = dates
		if (start) changeStart(start)
		if (end) {
			changeEnd(timex.from.date(end).endOf("day").toJSDate())
		}
	}

	return (
		<Container {...rest}>
			<DatePicker
				selected={startDate && startDate.getTime() !== neginfiso ? startDate : undefined}
				onChange={(dates) =>
					onChange(dates.map((date) => (date === null ? undefined : date)) as [Date | undefined, Date | undefined])
				}
				startDate={startDate && startDate.getTime() !== neginfiso ? startDate : undefined}
				endDate={endDate && endDate.getTime() !== infiso ? endDate : undefined}
				minDate={minStartDate}
				maxDate={maxEndDate}
				selectsRange
				placeholderText={placeholderText}
				{...rest}
			/>
		</Container>
	)
}

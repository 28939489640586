import * as brandguide from "brandguide"

export namespace logos {
	export const has_additional_logos = (data: brandguide.api.GuideLogos) =>
		[data.secondary?.present, data.logomark?.present, data.wordmark?.present, data.alternative?.present].includes(true)
}

export namespace typography {
	export const has_secondary_font = (data: brandguide.api.GuideTypography) =>
		!!data.fonts.find((f) => f.id === "secondary")

	export const has_alternative_fonts = (data: brandguide.api.GuideTypography) =>
		data.fonts.filter((f) => f.id !== "primary" && f.id !== "secondary").length > 0
}

export namespace imagery {
	export const has_lifestyle = (data: brandguide.api.GuideImagery) => !!data.lifestyle?.enabled
	export const has_product = (data: brandguide.api.GuideImagery) => !!data.product?.enabled
	export const has_illustration = (data: brandguide.api.GuideImagery) => !!data.illustration?.enabled
}

export namespace tov {
	export const has_writing_style = (data: brandguide.api.GuideToneOfVoice) => (data.writing_style || []).length > 0
}

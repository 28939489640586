import React, { useEffect, useState } from "react"
import { useLocation, Route, Routes, Navigate } from "react-router-dom"
import * as authzc from "authzcached"
import * as layouts from "layouts"
import * as icons from "icons"
import * as errors from "errors"
import * as httpx from "httpx"
import * as typography from "typography"
import * as api from "./api"
import * as timex from "timex"
import * as navigation from "navigation"

function Authorize(): JSX.Element {
	const [cause, setCause] = useState(undefined as errors.Cause)
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const location = useLocation()
	const req = httpx.qs.parse(location.search)

	return (
		<layouts.Flex
			flexDirection="column"
			minHeight="100vh"
			margin="0"
			flex="1"
			background={layouts.theme.backgrounds.lightblue}
			data-testid="authorize-container"
		>
			<layouts.Flex className="header" px="50px" alignItems="center" justifyContent="flex-start" height="60px">
				<icons.MainLogo width="202px" />
			</layouts.Flex>
			<layouts.Flex justifyContent="center" alignItems="center" flex="1">
				<errors.overlay cause={cause}>
					<layouts.Flex
						p="40px"
						borderRadius="11px"
						flexDirection="column"
						background={layouts.theme.colors.white}
						boxShadow={layouts.theme.extension.boxshadow}
						alignItems="center"
					>
						<typography.h1
							px="40px"
							color={layouts.theme.colors.grey.dark50}
							fontSize="30px"
							lineHeight="44px"
							fontWeight="700"
							letterSpacing="0.4px"
						>
							BrandGuard needs access
						</typography.h1>
						<typography.h5
							pt="5px"
							color={layouts.theme.colors.grey.dark50alpha80}
							fontSize="13px"
							fontWeight="500"
							letterSpacing="normal"
						>
							To use the plugin, please allow access to your BrandGuard account.
						</typography.h5>
						<layouts.Flex py="50px">
							<layouts.buttons.primary
								background={layouts.theme.colors.blue.bright}
								width="166px"
								height="47px"
								onClick={() => {
									api
										.authorization(req, authzc.bearer(authzaccount))
										.then((redirect) => {
											window.location.href = redirect
										})
										.catch((cause) => {
											setCause(<errors.UnknownCause cause={cause} />)
										})
								}}
							>
								Allow access
							</layouts.buttons.primary>
						</layouts.Flex>
						<typography.h5
							textAlign="center"
							pt="10px"
							color={layouts.theme.colors.grey.dark50alpha80}
							fontSize="13px"
							fontWeight="500"
							lineHeight="18px"
							letterSpacing="normal"
						>
							By continuing, you consent to BrandGuard using your information
							<br />
							according to our{" "}
							<layouts.links.external
								target="_blank"
								href="https://www.brandguard.ai/terms-of-use"
								color={layouts.theme.colors.blue.bright}
							>
								Terms of Service
							</layouts.links.external>{" "}
							and{" "}
							<layouts.links.external
								target="_blank"
								href="https://www.brandguard.ai/privacy-policy"
								color={layouts.theme.colors.blue.bright}
							>
								Privacy Policy
							</layouts.links.external>
							.
						</typography.h5>
					</layouts.Flex>
				</errors.overlay>
			</layouts.Flex>
			<layouts.Flex className="footer" px="50px" alignItems="center" justifyContent="flex-start" height="60px">
				<typography.h4 fontSize="12px" color={layouts.theme.colors.grey.dark50alpha80}>
					{`Copyright © ${timex.local().year} BrandGuard. All rights reserved.`}
				</typography.h4>
			</layouts.Flex>
		</layouts.Flex>
	)
}

export default function Routing(): JSX.Element {
	const navctx = navigation.context.useCached()
	useEffect(() => {
		navctx.update({ ...navctx, hidden: true })
	}, [])
	return (
		<Routes>
			<Route path="/authorize" element={<Authorize />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	)
}

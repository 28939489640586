import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import styled from "@emotion/styled"

const Card = styled(layouts.Flex)``
Card.defaultProps = {
	flex: "1",
	height: "100%",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "20px",
	background: colors.blue.blue,
	border: `1px solid ${colors.grey.dark50alpha30}`,
	gridGap: "10px",
	position: "relative",
}

const CardText = styled(typography.h5)``
CardText.defaultProps = {
	p: "5%",
	whiteSpace: "unset",
	fontSize: "2vh",
	fontWeight: "700",
	lineHeight: "normal",
}

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const data = brandguide.api.zeros.guide.guideToneOfVoiceZero(guide.guide?.tone_of_voice)
	const writing_styles = data.writing_style || []

	if (writing_styles.length === 0) return <></>

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Tone of Voice</result_components.NavTitle>
				<result_components.NavDescription>
					Our brand voice is {data.tone_of_voice[0]?.description || ""}
					<br />
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.writing_style} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer gridGap="2%">
					<layouts.Flex flex="1" flexDirection="column">
						<layouts.Flex flexDirection="column">
							<typography.h4 color={colors.grey.dark50} lineHeight="50px">
								Writing Samples
							</typography.h4>

							<typography.h5 color={colors.grey.dark50} fontWeight="400" lineHeight="3vh">
								Here are some examples of our writing.
							</typography.h5>
						</layouts.Flex>
						<layouts.Flex flex="1" flexDirection="column">
							{writing_styles.map((ws) => (
								<layouts.Flex key={ws.id} p="1vh" borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`}>
									<typography.h4
										fontSize="1.5vh"
										fontWeight="400"
										whiteSpace="unset"
										lineHeight="2vh"
										color={layouts.theme.colors.grey.dark50alpha80}
									>
										{ws.text}
									</typography.h4>
								</layouts.Flex>
							))}
						</layouts.Flex>
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

import React, { useState, useEffect, useMemo } from "react"
import classnames from "classnames"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as brands from "brands"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as authz from "authz"
import * as api from "brandguard/api"
import * as cardstatuspanel from "brandguard/display/training/display/status"
import * as predictions from "brandguard/predictions"
import * as authzc from "authzcached"
import * as nav from "brandguard/components/navigation"
import * as caching from "brandguard/display/training/cache"
import * as bglayouts from "brandguard/components/layouts"
import * as bgapprovals from "brandguard/display/approval"
import * as bgtexts from "brandguard/display/brandguard/text"
import * as icons from "icons"
import { UploadSimple } from "brandguard/components"
import * as mediaapi from "media/api"
import { uploads } from "brandguard/display/training"
import { CancellablePromise } from "real-cancellable-promise"
import { Details } from "."

export default function Display(props: React.PropsWithChildren<unknown>): JSX.Element {
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const cachekey = `textuploads.${brand.id}`

	const [loading, setLoading] = useState(true)
	const [selectedItem, setSelectedItem] = useState(undefined as api.TextSearchResponseItem | undefined)
	const [checkedItems, setCheckedItems] = useState([] as api.TextSearchResponseItem[])
	const [onBrandUploadFocused, setOnbrandUploadFocused] = useState(false)
	const [offBrandUploadFocused, setOffbrandUploadFocused] = useState(false)
	const [filterFocused, setFilterFocused] = useState(false)

	const _searchReq = api.text.uploads.searchZero({
		offset: 0n,
		limit: 20n,
		brand_id: brand.id,
	})

	const [searchReq, setSearchReq] = useState(_searchReq)
	const [cursorKey, setCursorKey] = useState(uuid.v4())

	const [texts, setTexts] = useState({
		items: [],
		next: searchReq,
	} as api.TextSearchResponse)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		if (!loading) setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.text.training.search(searchReq, searchReq.brand_id, bearertoken))
			.then(setTexts)
			.catch(httpx.errors.cancellation(console.warn))
			.catch(console.error)
			.finally(() => setLoading(false))
		return p.cancel
	}, [searchReq])

	const onTextUpdate = (item: api.TextSearchResponseItem): void => {
		setTexts((prevState) => ({
			...prevState,
			items: prevState.items.map((i) => (i.media?.id === item.media?.id ? item : i)),
		}))
	}

	const setUnion = (upd: api.TextSearchResponseItem): void => {
		setTexts((prevState) => ({
			...prevState,
			items: [upd, ...prevState.items.filter((o) => o.media?.id !== upd.media?.id)],
		}))
	}

	const _allselected = texts.items.length > 0 && checkedItems.length === texts.items.length

	const handleSelectAll = (): void => {
		if (_allselected) {
			setCheckedItems([])
			return
		}
		setCheckedItems(texts.items)
	}

	const appchecked = searchReq.observation.length === 1 && searchReq.observation[0] === api.Prediction.APPROVED
	const rejchecked = searchReq.observation.length === 1 && searchReq.observation[0] === api.Prediction.REJECTED
	const fcnt = bgapprovals.CalculateFilterSCount(_searchReq, searchReq)

	const MemoOnBrandUpload = useMemo(() => {
		return function MemodUpload(props: inputs.uploads.api.Props) {
			return (
				<bgtexts.uploads.default
					background={layouts.theme.colors.blue.blue}
					observation={api.Prediction.APPROVED}
					{...props}
				/>
			)
		}
	}, [])

	const MemoOffBrandUpload = useMemo(() => {
		return function MemodUpload(props: inputs.uploads.api.Props) {
			return (
				<bgtexts.uploads.default
					background={layouts.theme.colors.red.cinnabar}
					observation={api.Prediction.REJECTED}
					{...props}
				/>
			)
		}
	}, [])

	const SelectedDisplay = () => {
		if (!selectedItem) return <></>
		return (
			<layouts.containers.absolute zIndex="1">
				<Details
					item={selectedItem}
					canAction={permission.brandguard_upload}
					deleteAction={api.text.training.patch}
					onDelete={(results) => {
						setSelectedItem(undefined)
						setTexts((prevState) => ({
							...prevState,
							items: prevState.items.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
						}))
					}}
					onClose={() => setSelectedItem(undefined)}
					status={
						<cardstatuspanel.default
							prediction={selectedItem.event?.observation}
							borderRadius="10px"
							minHeight="35px"
						/>
					}
				/>
			</layouts.containers.absolute>
		)
	}

	return (
		<layouts.containers.flex
			flexDirection="column-reverse"
			overflow="hidden"
			data-testid="text-training-upload-display"
		>
			<layouts.pagination.Cursor
				key={cursorKey}
				my="auto"
				justifyContent="center"
				current={searchReq.offset}
				advance={Number(texts.next!.offset) === -1 ? undefined : texts.next?.offset}
				onChange={(next) => {
					setSearchReq({
						...searchReq,
						offset: next,
					})
				}}
			/>
			<layouts.loading.screen
				className="brandguard-working-area"
				loading={loading}
				flex="1"
				overflow="hidden"
				icon={<></>}
				flexDirection="row"
			>
				<layouts.containers.flex
					className="center-panel"
					flex="1"
					flexDirection="column"
					position="relative"
					alignItems="center"
					justifyContent="center"
				>
					<SelectedDisplay />
					<layouts.containers.flex flexDirection="column" flex="1" minHeight="100px" width="100%">
						<bglayouts.OverlaysContainer overflow="auto" background={layouts.theme.backgrounds.whitealpha80}>
							<layouts.containers.ResponsiveGrid gap={20} p="25px">
								{texts.items.map((item) => {
									return (
										<bgapprovals.texts.CardDisplay
											key={item.media?.id}
											item={item}
											active={selectedItem && item?.media?.id === selectedItem.media?.id}
											onCardClick={() => {
												setSelectedItem(item)
											}}
											onChange={onTextUpdate}
											onChecked={(checked) =>
												setCheckedItems((prevState) =>
													checked ? [...prevState, item] : prevState.filter((i) => i.media?.id !== item.media?.id),
												)
											}
											panel={
												<cardstatuspanel.default
													prediction={item.event?.observation}
													borderRadius="0 0 10px 10px"
													height="35px"
												/>
											}
											find={api.text.uploads.find}
											checked={checkedItems.some((i) => i.media?.id === item.media?.id)}
										/>
									)
								})}
							</layouts.containers.ResponsiveGrid>
							<layouts.loading.pending loading={loading || texts.next?.offset === searchReq.offset}>
								{texts.items.length === 0 && <bgapprovals.layouts.ZeroState />}
							</layouts.loading.pending>
						</bglayouts.OverlaysContainer>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.loading.screen>
			<layouts.containers.flex flexDirection="row" className={classnames(bgapprovals.layouts.styledactions)}>
				<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative" flex="1" mr="10px">
					<layouts.buttons.outline
						width="220px"
						height="40px"
						borderRadius="1px"
						onClick={() => {
							if (filterFocused) setFilterFocused(false)
							if (offBrandUploadFocused) setOffbrandUploadFocused(false)
							setOnbrandUploadFocused(!onBrandUploadFocused)
						}}
					>
						{onBrandUploadFocused ? (
							<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
						) : (
							<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
						)}
						<layouts.Span px="10px">Upload On-Brand Assets</layouts.Span>
					</layouts.buttons.outline>
					<layouts.containers.flex
						display={onBrandUploadFocused ? "flex" : "none"}
						position="absolute"
						top="100%"
						pt="5px"
					>
						<UploadSimple
							className={classnames(nav.layouts.slideDownAnimation)}
							title="Upload On-Brand Assets"
							isProcessing={(t: api.TextSearchResponseItem) => predictions.processing(t.event?.approved)}
							onChange={(text) => {
								caching.uploads.text.remove(cachekey, [{ description: "", ...text }])
								setUnion(text)
							}}
							resume={() => {
								return caching.uploads.text.getasync(cachekey).then((texts) => {
									return texts
										.filter((txt) => txt.event?.observation === api.Prediction.APPROVED)
										.map(
											(t) =>
												({
													id: uuid.v4(),
													display: t.description,
													background: layouts.theme.colors.blue.blue,
													data: t,
												} as inputs.uploads.api.uploaded<api.TextSearchResponseItem>),
										)
								})
							}}
							refresh={(data) => {
								return api.text.uploads
									.find(data.media?.id!, brand.id, bearertoken)
									.catch(
										httpx.errors.notFound((cause) => {
											console.debug("unable to load text training display", cause)
											return api.text.training.find(data.media?.id!, brand.id, bearertoken)
										}),
									)
									.then((resp) => {
										if (resp.event?.approved === api.Prediction.ERROR) {
											caching.uploads.text.remove(cachekey, [{ description: "", ...data }])
											return CancellablePromise.reject(resp)
										} else {
											return CancellablePromise.resolve(resp)
										}
									})
							}}
							upload={(stats, content) => {
								const delay = uploads.next(cachekey)
								const data = new FormData()
								data.append("observation", api.Prediction.APPROVED.toString())
								data.append("delay", delay.toMillis().toFixed(0))
								return api.text.training.upload(data, content, brand.id, authzc.bearer(metaauthz)).then((resp) => {
									caching.uploads.text.add(cachekey, [{ description: content.name, ...resp }])
									return resp
								})
							}}
							DropWell={MemoOnBrandUpload}
							// ManuallyInsert={MemoOnBrandManual}
							onClose={() => setOnbrandUploadFocused(false)}
						/>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative" flex="1" mr="10px">
					<layouts.buttons.outlinered
						width="230px"
						height="40px"
						borderRadius="1px"
						onClick={() => {
							if (filterFocused) setFilterFocused(false)
							if (onBrandUploadFocused) setOnbrandUploadFocused(false)
							setOffbrandUploadFocused(!offBrandUploadFocused)
						}}
					>
						{offBrandUploadFocused ? (
							<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.red.cinnabar} />
						) : (
							<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.red.cinnabar} />
						)}
						<layouts.Span px="10px">Upload Off-Brand Assets</layouts.Span>
					</layouts.buttons.outlinered>
					<layouts.containers.flex
						display={offBrandUploadFocused ? "flex" : "none"}
						position="absolute"
						top="100%"
						pt="5px"
					>
						<UploadSimple
							className={classnames(nav.layouts.slideDownAnimation)}
							title="Upload Off-Brand Assets"
							isProcessing={(t: api.TextSearchResponseItem) => predictions.processing(t.event?.approved)}
							onChange={(text) => {
								caching.uploads.text.remove(cachekey, [{ description: "", ...text }])
								setUnion(text)
							}}
							resume={() => {
								return caching.uploads.text.getasync(cachekey).then((texts) => {
									return texts
										.filter((txt) => txt.event?.observation === api.Prediction.REJECTED)
										.map(
											(t) =>
												({
													id: uuid.v4(),
													display: t.description,
													background: layouts.theme.colors.red.cinnabar,
													data: t,
												} as inputs.uploads.api.uploaded<api.TextSearchResponseItem>),
										)
								})
							}}
							refresh={(data) => {
								return api.text.uploads
									.find(data.media?.id!, brand.id, bearertoken)
									.catch(
										httpx.errors.notFound((cause) => {
											console.debug("unable to find training off-brand text", cause)
											return api.text.training.find(data.media?.id!, brand.id, bearertoken)
										}),
									)
									.then((resp) => {
										if (resp.event?.approved === api.Prediction.ERROR) {
											caching.uploads.text.remove(cachekey, [{ description: "", ...data }])
											return CancellablePromise.reject(resp)
										} else {
											return CancellablePromise.resolve(resp)
										}
									})
							}}
							upload={(stats, content) => {
								const data = new FormData()
								data.append("observation", api.Prediction.REJECTED.toString())
								return api.text.training.upload(data, content, brand.id, authzc.bearer(metaauthz)).then((resp) => {
									caching.uploads.text.add(cachekey, [{ description: content.name, ...resp }])
									return resp
								})
							}}
							DropWell={MemoOffBrandUpload}
							// ManuallyInsert={MemoOffBrandManual}
							onClose={() => setOffbrandUploadFocused(false)}
						/>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative">
					<bgapprovals.layouts.FilterButton
						width="145px"
						height="40px"
						borderRadius="4px"
						onClick={() => {
							if (onBrandUploadFocused) setOnbrandUploadFocused(false)
							if (offBrandUploadFocused) setOffbrandUploadFocused(false)
							setFilterFocused(!filterFocused)
						}}
						className={fcnt > 0 ? "active" : ""}
					>
						<layouts.containers.flex flexDirection="row" justifyContent="center">
							<layouts.containers.box>
								<icons.bgapproval.filter />
							</layouts.containers.box>
							<layouts.containers.box px="10px">Filter{fcnt > 0 ? `/${fcnt}` : ""}</layouts.containers.box>
							<layouts.containers.box>
								{filterFocused ? <icons.bgapproval.arrowDown /> : <icons.bgapproval.arrowDown />}
							</layouts.containers.box>
						</layouts.containers.flex>
					</bgapprovals.layouts.FilterButton>
					<layouts.containers.flex display={filterFocused ? "flex" : "none"} position="absolute" top="100%" pt="5px">
						<bgapprovals.FilterDisplay
							onFilterClear={() => {
								setSearchReq(_searchReq)
								setCursorKey(uuid.v4())
							}}
							_searchReq={_searchReq}
							onFilterChange={(r) => {
								setSearchReq({ ...searchReq, ...r, ...{ offset: 0n } })
								setCursorKey(uuid.v4())
							}}
						>
							<bgapprovals.Checkbox
								description="On-Brand Assets"
								checked={appchecked}
								onChange={() => {
									setSearchReq((prevState) => ({
										...prevState,
										observation: appchecked ? _searchReq.observation : [api.Prediction.APPROVED],
										offset: 0n,
									}))
									setCursorKey(uuid.v4())
								}}
							/>
							<bgapprovals.Checkbox
								description="Off-Brand Assets"
								checked={rejchecked}
								onChange={() => {
									setSearchReq((prevState) => ({
										...prevState,
										observation: rejchecked ? _searchReq.observation : [api.Prediction.REJECTED],
										offset: 0n,
									}))
									setCursorKey(uuid.v4())
								}}
							/>
						</bgapprovals.FilterDisplay>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex className="actions" justifyContent="flex-end" flex="10">
					<bgapprovals.layouts.SelectAll
						disabled={texts.items.length === 0}
						cursor="pointer"
						className="select-all-action"
						onClick={handleSelectAll}
					>
						{_allselected ? "Unselect All" : "Select All"}
					</bgapprovals.layouts.SelectAll>
					<authz.Protected
						enabled={permission.brandguard_upload && checkedItems.length > 0}
						rejected={
							<layouts.containers.flex className="download-action">
								<>
									<layouts.containers.flex className="download-action">
										<icons.brandguard.download />
									</layouts.containers.flex>
									<layouts.containers.flex className="remove-action">
										<icons.brandguard.remove />
									</layouts.containers.flex>
								</>
							</layouts.containers.flex>
						}
					>
						<layouts.containers.flex
							className="download-action"
							cursor="pointer"
							onClick={() => {
								const promises: Promise<api.TextPatchRequest | string>[] = []
								checkedItems.forEach((i) => {
									promises.push(
										mediaapi.url(i.media?.md5!, bearertoken).then((result) => {
											return fetch(result.url).then((resp) => resp.text())
										}),
									)
								})
								Promise.all(promises).then((content) => {
									const downloadLink = document.createElement("a")
									downloadLink.href = URL.createObjectURL(new Blob([content.join("\n")], { type: "text/csv" }))
									downloadLink.download = "brandguard-assets.csv"
									downloadLink.click()
									downloadLink.remove()
								})
							}}
						>
							<icons.brandguard.download />
						</layouts.containers.flex>
						<layouts.containers.flex
							className="remove-action"
							cursor="pointer"
							onClick={() => {
								const promises: Promise<api.TextPatchRequest>[] = []
								checkedItems.forEach((i) => {
									i.media!.tombstoned_at = new Date().toISOString()
									promises.push(api.text.training.patch(i.media!.brand_id, i.media!.id, i, authzc.bearer(metaauthz)))
								})
								Promise.all(promises)
									.then((results) => {
										setCheckedItems((prevState) =>
											prevState.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
										)
										setTexts((prevState) => ({
											...prevState,
											items: prevState.items.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
										}))
									})
									.catch((cause) => console.error("unable to display text training items", cause))
									.finally(() => {
										setSearchReq((prevState) => ({ ...prevState, offset: 0n }))
										setCursorKey(uuid.v4())
									})
							}}
						>
							<icons.brandguard.remove />
						</layouts.containers.flex>
					</authz.Protected>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as icons from "icons"
import * as inputs from "inputs"
import { FileRejection } from "react-dropzone"
import { css } from "@emotion/css"
import * as colorsx from "colorsx"

const transparentBG = css`
	background-size: 20px 20px;
	background-image: linear-gradient(45deg, #cccccc 25%, transparent 25%, transparent 75%, #cccccc 75%, #cccccc),
		linear-gradient(45deg, #cccccc 25%, transparent 25%, transparent 75%, #cccccc 75%, #cccccc);
	background-position: 0 0, 10px 10px;
`

export const borderBox = css`
	box-sizing: border-box;
`

export const steps = ["Logo(s)", "Clearspace", "Proportions", "Placement", "Misuse", "Usage"]

export const usages = {
	HIGH_CONTRAST: "hight_contrast",
	POOR_CONTRAST: "poor_contrast",
	CORRECT_CLEARSPASE: "correct_clearspase",
	INCORRECT_CLEARSPASE: "incorrect_clearspase",
}

export const actionButtonsProps = {
	background: layouts.theme.colors.grey.dark50alpha60,
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "50%",
	height: "25px",
	width: "25px",
}

export const customInputRange = css`
	input[type="range"] {
		display: block;
		width: 100%;
		margin: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
	}

	input[type="range"]::-webkit-slider-runnable-track {
		position: relative;
		height: 2px;
		border: 1px solid #b2b2b2;
		border-radius: 5px;
		background-color: #e2e2e2;
	}

	input[type="range"]::-webkit-slider-thumb {
		position: relative;
		top: -13px;
		width: 5px;
		height: 26px;
		border-radius: 10px;
		background: ${layouts.theme.colors.blue.blue};
		-webkit-appearance: none;
		cursor: pointer;
	}

	input[type="range"]::-moz-range-track {
		position: relative;
		height: 2px;
		border: 1px solid #b2b2b2;
		border-radius: 5px;
		background-color: #e2e2e2;
	}

	input[type="range"]::-moz-range-thumb {
		position: relative;
		top: -13px;
		width: 5px;
		height: 26px;
		border-radius: 10px;
		background: ${layouts.theme.colors.blue.blue};
		-moz-appearance: none;
		cursor: pointer;
	}

	input[type="range"]::-moz-focus-outer {
		border: 0;
	}
`

export const ContrastBGColorClassName = (svg: string | undefined) => {
	if (!svg) return layouts.theme.colors.white
	const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
	const colors = icons.svg.getColorsFromSvg(svgElement)
	const top_color = icons.svg.getTopColors(colors)
	const hex = colorsx.convertColorToHex(top_color[0])
	return colorsx.isNearlyWhite(hex) ? transparentBG : ""
}

interface logoItemProps extends layouts.containers.ContainerProps {
	item: brandguide.api.LogoObject
	title: string
	onChange(c: brandguide.api.LogoObject): void
}

export function LogoItem(props: logoItemProps): JSX.Element {
	const { item, title, onChange, ...rest } = props

	const [logo, setLogo] = useState(item)

	useEffect(() => {
		onChange(logo)
	}, [logo])

	if (!item.present)
		return (
			<layouts.Flex {...rest}>
				<Dropwell
					onChange={(f) => toBase64(f[0]).then((r) => setLogo({ ...logo, present: true, svg: r }))}
					title={title}
				/>
			</layouts.Flex>
		)

	return (
		<layouts.Flex border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`} {...rest}>
			<layouts.Flex justifyContent="end">
				<layouts.Flex p="5px" onClick={() => setLogo({ ...logo, present: false, svg: "" })}>
					<icons.Close width="10px" height="10px" />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex
				flex="3"
				justifyContent="center"
				alignItems="center"
				minHeight="180px"
				className={ContrastBGColorClassName(logo.svg)}
			>
				<img src={logo.svg} alt={title} style={{ maxHeight: "160px", maxWidth: "160px" }} />
			</layouts.Flex>
			<layouts.Flex flexDirection="column" flex="1" justifyContent="flex-end" alignItems="center" position="relative">
				<typography.h4 fontSize="12px" color={layouts.theme.colors.grey.dark50}>
					{title}
				</typography.h4>
			</layouts.Flex>
		</layouts.Flex>
	)
}

LogoItem.defaultProps = {
	flexDirection: "column",
	width: "170px",
	height: "240px",
}

const acceptedTypes = {
	"image/svg+xml": [".svg"],
}

const styledIcon = css`
	svg {
		width: 25px;
		height: 25px;
		path {
			stroke-width: 5px;
		}
	}
`

function toBase64(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = (error) => reject(error)
	})
}

function isValidSvg(file: File): Promise<boolean> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = (event) => {
			const svgContent = event.target?.result as string
			const parser = new DOMParser()
			const svgDoc = parser.parseFromString(svgContent, "image/svg+xml")

			const imageElements = svgDoc.getElementsByTagName("image")

			for (let i = 0; i < imageElements.length; i++) {
				const href = imageElements[i].getAttribute("xlink:href")
				if (href) {
					resolve(false)
					return
				}
			}

			resolve(true)
		}
		reader.onerror = (error) => reject(error)
		reader.readAsText(file)
	})
}

const MAX_SIZE_IN_MB = 1

function isSVGFileSizeValid(file: File, maxSizeInMB: number = MAX_SIZE_IN_MB): boolean {
	const maxSizeInBytes = maxSizeInMB * 1024 * 1024
	return file.size <= maxSizeInBytes
}

interface props extends layouts.containers.ContainerProps {
	onChange(item: File[]): void
	title: string
}

export default function Dropwell(props: React.PropsWithChildren<props>): JSX.Element {
	const { title, onChange, ...rest } = props
	const [loading, setLoading] = useState(false)

	const onDropHandle = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		if (acceptedFiles.length === 0) return
		setLoading(true)
		const file = acceptedFiles[0]
		Promise.resolve(isValidSvg(file))
			.then((isValid) => {
				if (!isValid) {
					alert("The file contains embedded images. Please upload an SVG file without embedded PNG or JPEG images.")
					return
				}
				if (!isSVGFileSizeValid(file)) {
					alert("The file is too large. Please upload an SVG file smaller than 1 MB.")
					return
				}
				onChange([file])
			})
			.finally(() => setLoading(false))
	}

	return (
		<layouts.loading.pending loading={loading}>
			<inputs.Dropwell
				className="svg.dropwell"
				accept={acceptedTypes}
				onDrop={(accepted, rejected, evt) => onDropHandle(accepted, rejected)}
				display="flex"
				flex="1"
				{...rest}
			>
				<layouts.dnd.Container p="unset" borderRadius="unset">
					<icons.FileUpload className={styledIcon} width="50px" height="50px" mt="67px" />
					<typography.h6
						fontSize="10px"
						fontWeight="400"
						whiteSpace="unset"
						p="20px"
						textAlign="center"
						color={layouts.theme.colors.grey.dark3}
					>
						Drag & drop your image here or choose file
					</typography.h6>
					<layouts.Flex
						flexDirection="column"
						flex="1"
						justifyContent="flex-end"
						alignItems="center"
						position="relative"
					>
						<typography.h4 fontSize="12px" color={layouts.theme.colors.grey.dark50}>
							{title}
						</typography.h4>
					</layouts.Flex>
				</layouts.dnd.Container>
			</inputs.Dropwell>
		</layouts.loading.pending>
	)
}

export const LogoTitle = (o: brandguide.api.LogoObject): string => {
	if (o.id === "primary") return "Main Logo"
	if (o.id === "secondary") return "Secondary Logo"
	if (o.id === "logomark") return "Logomark"
	if (o.id === "wordmark") return "Wordmark"
	if (o.id === "alternative") return "Alternative"
	return ""
}

const alignPosition = (i: brandguide.api.Placements | undefined): string => {
	if (!i) return "center"
	if (i === brandguide.api.Placements.TOP) return "start"
	if (i === brandguide.api.Placements.BOTTOM) return "end"
	return "center"
}

const alignName = (i: brandguide.api.Placements): string => {
	if (i === brandguide.api.Placements.TOP) return "Top"
	if (i === brandguide.api.Placements.BOTTOM) return "Bottom"
	if (i === brandguide.api.Placements.MIDDLE) return "Middle"
	return "All"
}

interface placementProps extends layouts.containers.ContainerProps {
	items: brandguide.api.Placements[]
	logo: string
	posX: string
	alignAll: boolean
	title: string
	onChange(i: brandguide.api.Placements[]): void
	readonly: boolean
}

export function PlacementItem(props: placementProps): JSX.Element {
	const { items, logo, posX, alignAll, title, readonly, onChange, ...rest } = props

	const typographyProps = {
		fontSize: "10px",
		fontWeight: "400",
		color: layouts.theme.colors.grey.dark50alpha80,
	}

	const lastElement = items[items.length - 1]

	const checkboxes = () => {
		if (alignAll) return [brandguide.api.Placements.ALL]
		return [
			brandguide.api.Placements.TOP,
			brandguide.api.Placements.MIDDLE,
			brandguide.api.Placements.BOTTOM,
			brandguide.api.Placements.ALL,
		]
	}

	function StatusIcon(): JSX.Element {
		const iconProps = {
			fill: layouts.theme.colors.grey.dark50,
			fillOpacity: "0.8",
			width: "15px",
			height: "15px",
		}
		return <icons.brandguide.marks.Accept {...iconProps} />
	}

	return (
		<layouts.Flex {...rest}>
			<layouts.containers.absolute right="0" p="5px">
				<StatusIcon />
			</layouts.containers.absolute>
			<layouts.Flex
				flex="1"
				minHeight="115px"
				border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
				justifyContent={posX}
				alignItems={alignPosition(lastElement)}
				className={ContrastBGColorClassName(logo)}
			>
				<layouts.Flex p="10px" position="relative">
					<img src={logo} alt={"Logo"} style={{ height: "50px", width: "50px" }} />
					{lastElement === brandguide.api.Placements.ALL && (
						<>
							<layouts.containers.absolute left="25px" bottom="-23px">
								<icons.brandguide.arrows.PlacementArrowDown />
							</layouts.containers.absolute>
							<layouts.containers.absolute left="25px" top="-23px">
								<icons.brandguide.arrows.PlacementArrowUp />
							</layouts.containers.absolute>
							{alignAll && (
								<>
									<layouts.containers.absolute top="25px" left="-23px">
										<icons.brandguide.arrows.PlacementArrowLeft />
									</layouts.containers.absolute>
									<layouts.containers.absolute top="25px" right="-23px">
										<icons.brandguide.arrows.PlacementArrowRight />
									</layouts.containers.absolute>
								</>
							)}
						</>
					)}
				</layouts.Flex>
			</layouts.Flex>
			{!readonly && (
				<layouts.Flex flexDirection="column" border="2px solid #ECF0FC">
					<typography.h5 my="12px" pl="10px" {...typographyProps}>
						Vertical Alignment
					</typography.h5>
					<layouts.containers.grid
						px="20px"
						gap="15px"
						gridTemplateColumns="repeat(2, minmax(25px, 1fr))"
						gridTemplateRows="repeat(auto-fit, minmax(15px, 1fr))"
						style={{ alignItems: "start" }}
						minHeight="80px"
					>
						{checkboxes().map((p) => (
							<label key={p} style={{ marginBottom: "10px" }}>
								<input
									type="checkbox"
									value={p}
									checked={items.includes(p)}
									style={{ width: "15px", height: "15px" }}
									onChange={(e) => {
										let upd = items
										const val = Number(e.target.value)
										if (val === brandguide.api.Placements.ALL) {
											if (items.length === 1 && items[0] === brandguide.api.Placements.ALL) upd = []
											else upd = [brandguide.api.Placements.ALL]
											onChange(upd)
											return
										}
										upd = upd.filter((i) => i !== brandguide.api.Placements.ALL)
										const isExist = items.find((i) => i === val)
										if (isExist) upd = items.filter((i) => i !== val)
										else upd.push(val)
										if (upd.length === 3) {
											upd = [brandguide.api.Placements.ALL]
										}
										onChange(upd)
									}}
								/>
								<layouts.Span display="inline-block" verticalAlign="text-top" px="5px">
									<typography.h5 {...typographyProps}>{alignName(p)}</typography.h5>
								</layouts.Span>
							</label>
						))}
					</layouts.containers.grid>
					<layouts.Flex justifyContent="center" mt="27px" mb="10px">
						<typography.h6 fontSize="12px" fontWeight="400" color={layouts.theme.colors.grey.dark50}>
							{title}
						</typography.h6>
					</layouts.Flex>
				</layouts.Flex>
			)}
		</layouts.Flex>
	)
}

PlacementItem.defaultProps = {
	flexDirection: "column",
	readonly: false,
	alignAll: false,
	position: "relative",
}

interface misuseProps extends layouts.containers.ContainerProps {
	item: brandguide.api.LogoMisuse
	onDelete(i: brandguide.api.LogoMisuse): void
	readonly: boolean
}

export function MisUseItem(props: misuseProps): JSX.Element {
	const { item, onDelete, readonly, ...rest } = props

	function StatusIcon(): JSX.Element {
		const iconProps = {
			fill: layouts.theme.colors.grey.dark50,
			fillOpacity: "0.8",
			width: "15px",
			height: "15px",
		}
		return <icons.brandguide.marks.Reject {...iconProps} />
	}

	return (
		<layouts.Flex {...rest}>
			<layouts.containers.absolute right="0" p="5px">
				<StatusIcon />
			</layouts.containers.absolute>
			<layouts.Flex
				flex="1"
				minHeight="100px"
				border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
				justifyContent="center"
				alignItems="center"
				className={ContrastBGColorClassName(item.svg)}
			>
				<layouts.Flex p="10px">
					<img src={item.svg} alt={"Logo"} style={{ height: "50px", width: "50px" }} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex>
				<typography.h6 fontSize="10px" fontWeight="400">
					{item.title}
				</typography.h6>
				{!readonly && (
					<layouts.Flex
						flex="1"
						justifyContent="flex-end"
						cursor="pointer"
						onClick={() => onDelete(item)}
						alignItems="center"
						pr="5px"
					>
						<icons.brandguide.Trash width="10px" height="10px" fill={layouts.theme.colors.grey.dark50alpha80} />
					</layouts.Flex>
				)}
			</layouts.Flex>
		</layouts.Flex>
	)
}

MisUseItem.defaultProps = {
	flexDirection: "column",
	position: "relative",
	readonly: false,
}

interface usageProps extends layouts.containers.ContainerProps {
	item: brandguide.api.LogoUsage
	svg: string
	onDelete(i: brandguide.api.LogoUsage): void
	readonly: boolean
	correct: boolean
}

export function UsageItem(props: usageProps): JSX.Element {
	const { item, svg, onDelete, readonly, correct, ...rest } = props

	function StatusIcon(): JSX.Element {
		const iconProps = {
			fill: colorsx.getTextColorFromBG(item.color?.hex_code || layouts.theme.colors.white),
			fillOpacity: "0.8",
			width: "15px",
			height: "15px",
		}
		if (correct) return <icons.brandguide.marks.Accept {...iconProps} />
		return <icons.brandguide.marks.Reject {...iconProps} />
	}

	return (
		<layouts.Flex {...rest}>
			<layouts.containers.absolute right="0" p="5px">
				<StatusIcon />
			</layouts.containers.absolute>
			<layouts.Flex
				flex="1"
				minHeight="100px"
				border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
				justifyContent="center"
				alignItems="center"
				background={item.color?.hex_code || layouts.theme.colors.white}
			>
				<layouts.Flex
					p={item.id !== usages.INCORRECT_CLEARSPASE ? "10px" : "unset"}
					className={item.color?.hex_code ? "" : ContrastBGColorClassName(svg)}
				>
					<img src={svg} alt={"Logo"} style={{ height: "50px", width: "50px" }} />
				</layouts.Flex>

				{[usages.CORRECT_CLEARSPASE, usages.INCORRECT_CLEARSPASE].includes(item.id) && (
					<layouts.Flex flex="1">
						<typography.h6 whiteSpace="unset" color="#111E54" fontSize="10px" fontWeight="400" lineHeight="normal">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
						</typography.h6>
					</layouts.Flex>
				)}
			</layouts.Flex>
			<layouts.Flex>
				<typography.h6 fontSize="10px" fontWeight="400">
					{item.title}
				</typography.h6>
				{!readonly && (
					<layouts.Flex
						flex="1"
						justifyContent="flex-end"
						cursor="pointer"
						onClick={() => onDelete(item)}
						alignItems="center"
						pr="5px"
					>
						<icons.brandguide.Trash width="10px" height="10px" fill={layouts.theme.colors.grey.dark50alpha80} />
					</layouts.Flex>
				)}
			</layouts.Flex>
		</layouts.Flex>
	)
}

UsageItem.defaultProps = {
	flexDirection: "column",
	position: "relative",
	readonly: false,
	correct: false,
}

import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as selector from "brands/selector"
import { Link } from "react-router-dom"
import * as brands from "brands"
import * as routing from "./routing"

export function MetricsContainer(props: React.PropsWithChildren<selector.props>): JSX.Element {
	const { urlgen, children } = props
	return (
		<layouts.backgrounds.Grey className="brandguard-metrics" flex="0 1 100%">
			<layouts.containers.flex justifyContent="center" flex="1" data-testid="metrics-container">
				<layouts.containers.flex p="50px" pt="25px" flexDirection="column" width="100%" overflowY="hidden">
					<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="20px">
						<layouts.containers.flex flex="2">
							<typography.h3 color={layouts.theme.colors.grey.medium}>Metrics</typography.h3>
						</layouts.containers.flex>
						<layouts.containers.flex flex="2" justifyContent="end" height="48px">
							<selector.BrandSelector width="30%" urlgen={urlgen} />
						</layouts.containers.flex>
					</layouts.containers.flex>
					{children}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

export function MetricsApprovalsActiveLink(): JSX.Element {
	const brand = brands.caching.useCached()
	return (
		<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="10px">
			<layouts.containers.flex flexDirection="row" flex="1" borderBottom={layouts.theme.borders.grey.dark50alphamedium}>
				<layouts.containers.flex
					p="5px"
					flexDirection="row"
					borderBottom={`2px solid ${layouts.theme.colors.blue.blue}`}
				>
					<layouts.containers.flex fontSize="14px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
						Approvals
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<Link to={routing.metrics_training(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Training
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<Link to={routing.metrics_brandgpt(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Brandgpt
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function MetricsTrainingActiveLink(): JSX.Element {
	const brand = brands.caching.useCached()
	return (
		<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="10px">
			<layouts.containers.flex flexDirection="row" flex="1" borderBottom={layouts.theme.borders.grey.dark50alphamedium}>
				<Link to={routing.metrics_approvals(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Approvals
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<layouts.containers.flex
					p="5px"
					flexDirection="row"
					borderBottom={`2px solid ${layouts.theme.colors.blue.blue}`}
				>
					<layouts.containers.flex fontSize="14px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
						Training
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<Link to={routing.metrics_brandgpt(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Brandgpt
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function MetricsBrandgptActiveLink(): JSX.Element {
	const brand = brands.caching.useCached()
	return (
		<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="10px">
			<layouts.containers.flex flexDirection="row" flex="1" borderBottom={layouts.theme.borders.grey.dark50alphamedium}>
				<Link to={routing.metrics_approvals(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Approvals
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<Link to={routing.metrics_training(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Training
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<layouts.containers.flex
					p="5px"
					flexDirection="row"
					borderBottom={`2px solid ${layouts.theme.colors.blue.blue}`}
				>
					<layouts.containers.flex fontSize="14px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
						Brandgpt
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

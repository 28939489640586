import React from "react"
import classnames from "classnames"
import { css } from "@emotion/css"
import * as containers from "../containers"
import * as theme from "../theme"
import * as loadings from "../loading"

interface props {
	readonly?: boolean
	cause?: unknown
	loading?: boolean
}

const readonlycss = css`
	pointer-events: none;
`

const errored = css`
	background: ${theme.backgrounds.danger};
	border-radius: 0.25em;
`

export default function Container(props: React.PropsWithChildren<props & containers.FlexProps>): JSX.Element {
	const { cause, loading, children, className, opacity, readonly, ...rest } = props

	return (
		<containers.flex
			className={classnames("form", className, readonly ? readonlycss : undefined, cause ? errored : undefined)}
			flexDirection="column"
			{...rest}
		>
			<loadings.screen loading={!!loading} opacity={opacity} flex="1">
				{children}
			</loadings.screen>
		</containers.flex>
	)
}

import * as caching from "caching"
import * as timex from "timex"
import * as envx from "envx"

export const cache = new caching.Cache({
	namespace: "brandguard.uploads",
	ttl: timex.duration.iso("PT1H").toMillis(),
})

export function next(key: string): timex.Duration {
	const maxdelay = envx.duration(timex.duration.hours(0), "REACT_APP_BRANDGUARD_TRAINING_UPLOAD_MAX_DELAY")
	const k = `${key}.${timex.utc().startOf("day").toUnixInteger()}`
	const x = cache.set(k, cache.maybeSync(k, () => 0) + 1)
	if (x > 100) return timex.duration.milliseconds(timex.backoff.sigmoid(x, 0.2) * maxdelay.toMillis())
	if (x > 20) return timex.duration.milliseconds(timex.backoff.sigmoid(x) * maxdelay.toMillis())
	return timex.duration.milliseconds(timex.backoff.sigmoid(x, 0.0005) * maxdelay.toMillis())
}

import React from "react"
import { css } from "@emotion/css"
import classnames from "classnames"
import * as containers from "layouts/containers"

export default function Sliding(props: React.PropsWithChildren<containers.FlexProps>): JSX.Element {
	const { className, ...rest } = props

	const animation = css`
		animation: indeterminateAnimation 1s infinite linear;
		transform-origin: 0% 50%;

		@keyframes indeterminateAnimation {
			0% {
				transform: translateX(0) scaleX(0);
			}
			40% {
				transform: translateX(0) scaleX(0.4);
			}
			100% {
				transform: translateX(100%) scaleX(0.5);
			}
		}
	`

	return <containers.flex className={classnames(className, animation)} {...rest}></containers.flex>
}

Sliding.defaultProps = {
	flex: "1",
	height: "2px",
}

import * as layouts from "layouts"
import styled from "@emotion/styled"

export const StyledDatePicker = layouts.css`
	.react-datepicker-wrapper {
		padding-left: 10px;
		.react-datepicker__input-container {
			height: 100%;

			input {
				outline: none;
				border: none;
				height: 100%;
			}
		}
	}
`

export const Card = styled(layouts.containers.flex)`
	background: ${layouts.theme.colors.white};

	&:hover {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}
`

export const Header = styled(layouts.containers.flex)`
	color: ${layouts.theme.colors.black};
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	width: 100%;
	text-transform: uppercase;
`

export const ConstrainChart = layouts.css`
	canvas {
		width: 100% !important;
		height: 100% !important;
		max-height: 100% !important;
		max-width: 100% !important;
	}
`

export const hover = layouts.css`
	&:hover {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}

	canvas {
		width: 100% !important;
		height: 100% !important;
		max-height: 100% !important;
		max-width: 100% !important;
	}
`

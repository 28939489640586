import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as profiles from "profiles"
import * as um from "meta/usermanagement"
import * as timex from "timex"
import * as authzc from "authzcached"
import * as httpx from "httpx"
import * as errors from "errors"

export default function Profiles(props: { req: profiles.api.proto.SearchRequest }): JSX.Element {
	const { req } = props
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [searchReq, setSearchReq] = useState(req)
	const [pset, setProfiles] = useState<profiles.api.proto.SearchResponse>({
		items: [],
		next: searchReq,
	})
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)

	useEffect(() => {
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => profiles.api.search(searchReq, authzc.bearer(metaauthz)))
			.then(setProfiles)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				console.warn(cause)
				setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
			})
		return p.cancel
	}, [searchReq])

	if (cause) return <errors.overlay styled cause={cause} flex="1" />
	if (pset.items.length === 0) return <></>

	return (
		<layouts.containers.flex
			flexDirection="column"
			flex="2"
			background={layouts.theme.colors.grey.bg}
			my="20px"
			overflowY="auto"
			data-testid="usermanagement-profiles"
		>
			<layouts.containers.flex
				flexDirection="row"
				fontWeight="700"
				fontSize="16px"
				lineHeight="30px"
				justifyContent="center"
				borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
				mx="5px"
			>
				<layouts.containers.flex flex="3" p="10px">
					Users
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" p="10px" justifyContent="center">
					<layouts.Flex>
						Created
						<um.sorting.default
							sort_by={profiles.api.proto.Sorting.CREATED_AT}
							req={searchReq}
							onChange={() => setSearchReq(searchReq)}
						/>
					</layouts.Flex>
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" p="10px" justifyContent="center">
					<layouts.Flex>
						Updated
						<um.sorting.default
							sort_by={profiles.api.proto.Sorting.UPDATED_AT}
							req={searchReq}
							onChange={() => setSearchReq(searchReq)}
						/>
					</layouts.Flex>
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" p="10px"></layouts.containers.flex>
			</layouts.containers.flex>
			{pset.items.map((profile, i) => {
				return (
					<um.layouts.AccordionProfileContainer key={profile.id} profile={profile}>
						<layouts.containers.flex
							flexDirection="column"
							px="50px"
							py="10px"
							alignItems="flex-start"
							fontSize="14px"
							lineHeight="20px"
							color={layouts.theme.colors.grey.dark50}
						>
							<um.layouts.EditProfile
								key={profile.id}
								current={profile}
								onChange={async (upd) => {
									return profiles.api
										.update(
											{
												profile: upd,
											},
											authzc.bearer(metaauthz),
										)
										.then((r) => {
											const updated = r.profile || upd
											setProfiles((prevState) => ({
												...prevState,
												items: pset.items.map((p) => (p.id === upd.id ? updated : p)),
											}))
											return updated
										})
								}}
								onRemove={() => {
									profiles.api
										.update(
											{
												profile: {
													...profile,
													disabled_at: timex.local().toISO(),
													disabled_manually_at: timex.local().toISO(),
												},
											},
											authzc.bearer(metaauthz),
										)
										.then((r) => {
											setProfiles((prevState) => ({
												...prevState,
												items: pset.items.filter((p) => p.id !== r.profile?.id),
											}))
										})
								}}
							/>
						</layouts.containers.flex>
					</um.layouts.AccordionProfileContainer>
				)
			})}
			<layouts.pagination.Cursor
				mx="auto"
				mt="auto"
				py="5px"
				pr="10px"
				current={searchReq.offset}
				advance={Number(pset.next!.offset) === -1 ? undefined : pset.next?.offset}
				onChange={(next) => {
					setSearchReq({
						...searchReq,
						offset: next,
					})
				}}
			/>
		</layouts.containers.flex>
	)
}

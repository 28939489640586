import React, { useState } from "react"
import * as icons from "icons"
import * as containers from "./containers"

export interface props {
	body: string
}

export default function Container(props: React.PropsWithChildren<props & containers.FlexProps>): JSX.Element {
	const { body, ...rest } = props
	const [isCopied, setIsCopied] = useState(false)

	const handleCopyClick = () => {
		const textblob = new Blob([body], { type: "text/plain" })
		// eslint-disable-next-line no-undef
		const data = [new ClipboardItem({ [textblob.type]: textblob })]
		navigator.clipboard.write(data).then(() => {
			setIsCopied(true)
			setTimeout(() => {
				setIsCopied(false)
			}, 2000)
		})
	}

	return (
		<containers.flex title="Copy to clipboard" onClick={handleCopyClick} {...rest}>
			{!isCopied ? <icons.Clipboard mt="2px" /> : <containers.span>Copied!</containers.span>}
		</containers.flex>
	)
}

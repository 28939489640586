import React from "react"
import * as containers from "../containers"

export default function Group(props: React.PropsWithChildren<containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props

	return (
		<containers.flex flex="1" mb="5px" {...rest}>
			{children}
		</containers.flex>
	)
}

import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as bgimages from "brandguard/display/brandguard/images"
import * as authz from "authz"
import * as mediaapi from "media/api"
import { Image } from "media"
import { css } from "@emotion/css"
import * as icons from "icons"
import * as debugx from "x/debugx"
import { useState } from "react"
import * as httpx from "httpx"

const styledContainer = css`
	img {
		max-height: 70vh;
		min-height: 70vh;
	}
	.actions {
		min-height: unset;
		margin-top: 0;
		.selected-assets-description {
			display: none;
		}
		.actions-container {
			margin: 10px;
		}
	}
`

interface selectedProps extends layouts.containers.FlexProps {
	item: api.ImageSearchResponseItem
	canAction: boolean
	onDelete?(items: api.ImageSearchResponseItem[]): void
	deleteAction(
		bid: string,
		id: string,
		req: api.ImagePatchRequest,
		...options: httpx.option[]
	): Promise<api.ImagePatchResponse>
	onClose(): void
	status: JSX.Element
}

export default function DetailsDisplay(props: selectedProps): JSX.Element {
	const { item, canAction, onDelete, deleteAction, onClose, status, ...rest } = props

	const [focused, setFocused] = useState(false)

	return (
		<layouts.Flex {...rest}>
			<layouts.containers.flex
				flex="1"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				background={layouts.theme.backgrounds.bluealpha5}
				position="relative"
				className={styledContainer}
			>
				<layouts.containers.absolute p="10px" top="0" left="0" zIndex="2">
					{status}
				</layouts.containers.absolute>
				<layouts.containers.absolute p="10px" top="0" right="0" zIndex="2">
					<layouts.Flex
						borderRadius="50%"
						alignItems="center"
						justifyContent="center"
						background={layouts.theme.colors.white}
						p="5px"
						onClick={onClose}
					>
						<icons.Close
							width="25px"
							height="25px"
							stroke={layouts.theme.colors.grey.medium}
							fill={layouts.theme.colors.grey.medium}
						/>
					</layouts.Flex>
				</layouts.containers.absolute>
				<Image media={mediaapi.zero({ content_digest: item.media?.md5 })} height="100%" />
				{focused && (
					<layouts.containers.absolute>
						<layouts.Flex flex="1" p="20px" m="5px" background={layouts.theme.colors.white} borderRadius="5px">
							<bgimages.Debug item={item} />
						</layouts.Flex>
					</layouts.containers.absolute>
				)}
				<layouts.containers.absolute className="actions" right="0" bottom="0" p="10px">
					<authz.Protected enabled={canAction}>
						<bgimages.MultiActions
							uploadItems={[item]}
							deleteAction={canAction ? deleteAction : undefined}
							onDelete={onDelete}
						>
							{debugx.alpha.enabled() && (
								<layouts.Flex alignItems="center" justifyContent="center" ml="8px" onClick={() => setFocused(!focused)}>
									<icons.Code
										width="30px"
										height="30px"
										fill={focused ? layouts.theme.colors.blue.blue : layouts.theme.colors.white}
									/>
								</layouts.Flex>
							)}
						</bgimages.MultiActions>
					</authz.Protected>
				</layouts.containers.absolute>
			</layouts.containers.flex>
		</layouts.Flex>
	)
}

DetailsDisplay.defaultProps = {
	background: layouts.theme.colors.white,
	p: "5px",
	border: layouts.theme.borders.grey.dark50alpha,
	boxShadow: layouts.theme.boxshadow.black.wide,
}

import * as caching from "caching"
import * as timex from "timex"
import * as httpx from "httpx"
import * as api from "brandguard/api"
import { CancellablePromise } from "real-cancellable-promise"

export const cache = new caching.Cache({
	namespace: "novacloud.colorExplanationMask.urls",
	ttl: timex.duration.iso("PT48H").toMillis(),
})

export function cachable(evt: string, ...options: httpx.option[]): CancellablePromise<string> {
	const retry = httpx.autoretry()
	return retry.wrap(() => api.explanations.background_color.url(evt, ...options)).then((r) => r.url)
}

// get or load a media url by id.
export function current(aid: string, eid: string, ...options: httpx.option[]): CancellablePromise<string> {
	return cache.maybe(`${aid}.${eid}`, () => cachable(eid, ...options))
}

export default cache

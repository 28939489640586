import { SectionTitle } from "brandguide/results/components"
import { default as TOV } from "./tov"
import { default as WritingSamples } from "./writing_samples"

export { default as TOV } from "./tov"
export { default as WritingSamples } from "./writing_samples"

export function Section(): JSX.Element {
	return (
		<>
			<SectionTitle title="Tone of Voice" className="section-print" />
			<TOV className="section-print" />
			<WritingSamples className="section-print" />
		</>
	)
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as typographies from "brandguide/build/typography"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideTypography | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideTypographyZero(_guide.guide?.typography))

	const fonts = data.fonts || []

	useEffect(() => {
		const secondary = fonts.find((f) => f.id === "secondary")
		if (secondary) return
		setData({ ...data, fonts: [...fonts, ...[brandguide.api.zeros.typography.fontZero({ id: "secondary" })]] })
	}, [])

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Typography" Help={<typographies.helps.Modal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please upload all the fonts used for your brand.">
							<typographies.Carousel my="25px" className={typographies.layouts.styledCarousel} autoscrollright>
								{fonts.map((f) => (
									<typographies.layouts.UploadItem
										key={f.id}
										font={f}
										onChange={(upd) => setData({ ...data, fonts: fonts.map((i) => (i.id === upd.id ? upd : i)) })}
									/>
								))}
								<typographies.layouts.AddUploadsItem
									onChange={() => {
										setData({
											...data,
											fonts: [
												...fonts,
												...[brandguide.api.zeros.typography.fontZero({ id: `alternative_${fonts.length - 1}` })],
											],
										})
									}}
								/>
							</typographies.Carousel>

							<typographies.Explanation
								val={data.descriptions?.uploads || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.typography.descZero({ ...data.descriptions, uploads: text }),
									})
								}}
							/>
							<brandguide.build.layouts.NextStepsNav
								onNext={() =>
									onChange(typographies.Steps.USAGE, { ...data, fonts: fonts.filter((i) => i.font !== "") })
								}
								disabled={!brandguide.validations.typography.valid.uploads(data)}
							/>
							<typographies.Progress activeStep={typographies.Steps.UPLOAD} steps={typographies.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<typographies.Guidelines activPage="typography" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import * as layouts from "layouts"
import * as brandguide from "brandguide"

interface props extends layouts.containers.FlexProps {
	data: brandguide.api.GuideLogos
}

export default function Placements(props: props): JSX.Element {
	const { data } = props
	const focused = data.primary!

	const allPlacement = (data.placement?.all || []).length > 0
	if (allPlacement)
		return (
			<brandguide.build.logos.layouts.PlacementItem
				minWidth="250px"
				items={data.placement?.all || []}
				posX="center"
				readonly
				alignAll
				logo={focused.svg}
				title="All"
				onChange={() => undefined}
			/>
		)
	return (
		<layouts.containers.ResponsiveGrid gap={20} breakpointCols={3}>
			<brandguide.build.logos.layouts.PlacementItem
				items={data.placement?.left || []}
				readonly
				posX="start"
				logo={focused.svg}
				title="Left Aligned"
				onChange={() => undefined}
				minWidth="250px"
			/>
			<brandguide.build.logos.layouts.PlacementItem
				items={data.placement?.center || []}
				readonly
				posX="center"
				logo={focused.svg}
				title="Center Aligned"
				onChange={() => undefined}
				minWidth="250px"
			/>
			<brandguide.build.logos.layouts.PlacementItem
				items={data.placement?.right || []}
				readonly
				posX="end"
				logo={focused.svg}
				title="Right Aligned"
				onChange={() => undefined}
				minWidth="250px"
			/>
		</layouts.containers.ResponsiveGrid>
	)
}

import React from "react"
import styled from "@emotion/styled"
import * as system from "styled-system"
import * as theme from "./theme"
import Masonry from "react-masonry-css"
import { css } from "@emotion/css"
import { Flex } from "layouts"
import classnames from "classnames"

export interface DefaultStyling {
	styled?: boolean
}

export interface CursorProps {
	cursor?: string
}

export interface ContainerProps
	extends system.PositionProps,
		system.LayoutProps,
		system.TypographyProps,
		system.SpaceProps,
		system.BorderProps,
		system.FlexProps,
		system.OpacityProps,
		system.BoxShadowProps,
		system.ZIndexProps,
		system.BackgroundProps,
		system.BorderRadiusProps,
		CursorProps {
	className?: string
	aspectRatio?: string
}

export const grid = styled.div<
	ContainerProps & system.GridProps & system.GridGapProps & system.JustifyContentProps & { gap?: string; grid?: string }
>`
	display: grid;
	grid: ${(props) => (props.grid ? props.grid : "auto / auto auto auto auto auto;")};
	gap: ${(props) => (props.gap ? props.gap : "unset")};
	${system.grid}
	${system.position}
	${system.justifyContent}
	${system.layout}
	${system.space}
	${system.gridGap}
	${system.width}
	${system.height}
	${system.overflowX}
	${system.overflowY}
	${system.textAlign}
	${system.borderRadius}
	${system.flex}
`

export const box = styled.div<DefaultStyling & ContainerProps>`
	${(props) =>
		props.styled
			? `
		background: ${theme.colors.white};
		border-radius: 5px;
		box-shadow: ${theme.boxshadow.default};
	`
			: ""}
	cursor: ${(props) => (props.cursor || props.onClick ? "pointer" : "unset")};
	${system.zIndex}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
	${system.background}
	${system.opacity}
	${system.flex}
	${system.boxShadow}
	${system.border}
	${system.borderRadius}
`

export const absolute = styled.div<ContainerProps & DefaultStyling>`
	position: absolute;
	${(props) =>
		props.styled &&
		`
		background: ${theme.colors.white};
	`}
	${system.zIndex}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
	${system.background}
	${system.borderRadius}
	${system.opacity}
	${system.boxShadow}
	${system.border}
	${system.color}
	${system.justifyContent}
`

export interface FlexProps
	extends ContainerProps,
		DefaultStyling,
		system.GridGapProps,
		system.FlexProps,
		system.FlexDirectionProps,
		system.FlexboxProps,
		system.BackgroundProps,
		system.JustifyContentProps {}
export const flex = styled.div<FlexProps>`
	display: flex;
	${(props) =>
		props.styled &&
		`
		background: ${theme.colors.white};
		box-shadow: ${theme.boxshadow.default};
		&:hover {
			${props.onClick ? `background-color:${theme.colors.grey.light10}` : ""};
		}
	`}
	cursor: ${(props) => (props.cursor ? props.cursor : props.onClick ? "pointer" : "unset")};
	${system.zIndex}
	${system.position}
	${system.layout}
	${system.typography}
	${system.background}
	${system.space}
	${system.borderRadius}
	${system.gridGap}
	${system.opacity}
	${system.boxShadow}
	${system.flexDirection}
	${system.flex}
	${system.flexbox}
	${system.justifyContent}
	${system.border}
	${system.color}
`

export interface SpanProps extends ContainerProps, CursorProps, system.AlignContentProps {}
export const span = styled.span<SpanProps>`
	aspect-ratio: ${(props) => (props.aspectRatio ? props.aspectRatio : "unset")};
	cursor: ${(props) => (props.cursor || props.onClick ? "pointer" : "unset")};
	&:focus {
		outline: none;
		border: ${theme.borders.hinting.darkest};
	}

	&:hover {
		background-color: ${(props) => (props.onClick ? theme.colors.grey.light10 : "unset")};
		color: ${(props) => (props.onClick ? theme.colors.grey.lightest : "unset")};
		border-radius: 0.25em;
	}
	${system.zIndex}
	${system.alignContent}
	${system.position}
	${system.layout}
	${system.typography}
	${system.background}
	${system.space}
	${system.borderRadius}
	${system.opacity}
	${system.boxShadow}
	${system.flex}
	${system.color}
`

const styledResponsive = (gap: number) => css`
	.responsive-grid {
		display: flex;
		margin-left: -${gap}px;
		width: auto;
	}
	.responsive-grid_column {
		padding-left: ${gap}px;
		background-clip: padding-box;
	}

	.responsive-grid_column > div {
		margin-bottom: ${gap}px;
	}
`
interface responsiveProps extends FlexProps {
	breakpointCols: number | { [key: number]: number }
	gap: number
}

export function ResponsiveGrid(props: React.PropsWithChildren<responsiveProps>): JSX.Element {
	const { children, breakpointCols, className, gap, ...rest } = props
	return (
		<Flex className={classnames(styledResponsive(gap), className)} {...rest}>
			<Masonry breakpointCols={breakpointCols} className="responsive-grid" columnClassName="responsive-grid_column">
				{children}
			</Masonry>
		</Flex>
	)
}

ResponsiveGrid.defaultProps = {
	breakpointCols: 4,
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as typographies from "brandguide/build/typography"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideTypography | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideTypographyZero(_guide.guide?.typography))

	useEffect(() => {
		if (data.items.length !== 0) return
		setData({ ...data, items: brandguide.build.generators.initial.typography.usage(data.fonts) })
	}, [])

	const items = data.items || []

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Typography Usage" Help={<typographies.helps.Modal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="If you have specific fonts, sizes, and weights please add them in here.">
							<layouts.overlays.Container flexDirection="column" overflow="auto" height="42vh" my="10px">
								{items.map((i) => {
									return (
										<typographies.layouts.Edit
											key={i.id}
											item={i}
											fonts={data.fonts}
											onChange={(upd) => setData({ ...data, items: items.map((i) => (i.id === upd.id ? upd : i)) })}
											onDelete={(upd) => setData({ ...data, items: items.filter((i) => i.id !== upd.id) })}
										/>
									)
								})}
								<typographies.layouts.AddItem
									onChange={(upd) => {
										setData({ ...data, items: [...items, ...[upd]] })
									}}
								/>
							</layouts.overlays.Container>

							<typographies.Explanation
								val={data.descriptions?.usage || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.typography.descZero({ ...data.descriptions, usage: text }),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(typographies.Steps.UPLOAD, undefined)}
								onNext={() => onChange(typographies.Steps.RESULT, data)}
								disabled={!brandguide.validations.typography.valid.usage(data)}
								isLastStep
							/>
							<typographies.Progress activeStep={typographies.Steps.USAGE} steps={typographies.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<typographies.Guidelines activPage="typography" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

// min asserts at least a minimum number of charaters.
export function min(
	n: number | bigint,
	invalid: JSX.Element = <>too small</>,
): (i: number | bigint) => JSX.Element | undefined {
	return (i: number | bigint) => (i >= n ? undefined : invalid)
}

// max asserts at no more than a maximum number of charaters.
export function max(
	n: number | bigint,
	invalid: JSX.Element = <>too large</>,
): (i: number | bigint) => JSX.Element | undefined {
	return (i: number | bigint) => (i <= n ? undefined : invalid)
}

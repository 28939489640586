import { Route, Routes, Navigate, useNavigate } from "react-router-dom"
import * as brands from "brands"
import * as layouts from "layouts"
import * as routing from "./routing"
import * as brandguide from "brandguide"
import * as cache from "./cache"
import * as build from "brandguide/build"
import { section } from "brandguide/validations"
import * as authzc from "authzcached"
import { useEffect } from "react"

export const nextSection = (pid: brandguide.validations.pages | undefined, bid: string): string => {
	switch (pid) {
		case "colors":
			return routing.build.colors(bid)
		case "logos":
			return routing.build.logos(bid)
		case "typography":
			return routing.build.typography(bid)
		case "imagery":
			return routing.build.imagery(bid)
		case "tone_of_voice":
			return routing.build.tone_of_voice(bid)
		case "archetype":
			return routing.build.archetype(bid)
		case "application":
			return routing.build.application(bid)
		case "compliance":
			return routing.build.compliance(bid)
	}
	return routing.root(bid)
}

const isCompleted = (page: section, guide: brandguide.api.Guide): boolean => {
	switch (page?.id as brandguide.validations.pages) {
		case "colors":
			return brandguide.validations.colors.valid.completed(brandguide.api.zeros.guide.guideColorsZero(guide.colors))
		case "logos":
			return brandguide.validations.logos.valid.completed(brandguide.api.zeros.guide.guideLogosZero(guide.logos))
		case "typography":
			return brandguide.validations.typography.valid.completed(
				brandguide.api.zeros.guide.guideTypographyZero(guide.typography),
			)
		case "imagery":
			return brandguide.validations.imagery.valid.completed(brandguide.api.zeros.guide.guideImageryZero(guide.imagery))
		case "tone_of_voice":
			return brandguide.validations.tone_of_voice.valid.completed(
				brandguide.api.zeros.guide.guideToneOfVoiceZero(guide.tone_of_voice),
			)
		case "archetype":
			return brandguide.validations.archetype.valid.completed(
				brandguide.api.zeros.guide.guideArchetypeZero(guide.archetype),
			)
		case "application":
			return brandguide.validations.application.valid.completed(
				brandguide.api.zeros.guide.guideApplicationZero(guide.application),
			)
		case "compliance":
			return brandguide.validations.compliance.valid.completed(
				brandguide.api.zeros.guide.guideComplianceZero(guide.compliance),
			)
	}
}

export const continueSection = (guide: brandguide.api.Brandguide, permission: boolean) => {
	const pages = brandguide.validations.guide.sections(guide.guide!)
	for (let i = 0; i < pages.length; i++) {
		const section = pages[i]

		const accessWithPermissions = permission && section?.enabled && !isCompleted(section, guide.guide!)
		const accessWhithoutPermissions = !permission && section?.enabled && isCompleted(section, guide.guide!)

		if (accessWithPermissions || accessWhithoutPermissions) {
			return nextSection(section.id as brandguide.validations.pages, guide.brand_id)
		}
	}
	if (!permission) return

	const lastPage = [...pages].reverse().find((p) => p?.enabled)
	return nextSection(lastPage?.id as brandguide.validations.pages, guide.brand_id)
}

export function Redirect() {
	const cache = brandguide.cache.useCached()
	const _guide = cache.guide
	const brand_id = brands.caching.useCached().id

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	const navigate = useNavigate()

	useEffect(() => {
		if (brandguide.validations.guide.started(_guide)) {
			const linkToContinue = continueSection(_guide, permission.brandguide_edit)
			if (linkToContinue) return navigate(linkToContinue)
		}
		navigate(routing.start(brand_id), { replace: true })
	}, [])

	return null
}

export default function Routing(): JSX.Element {
	const brand = brands.caching.useCached()
	return (
		<layouts.backgrounds.Grey flex="0 1 100%" height="100%" overflowY="hidden">
			<cache.Storage>
				<Routes>
					<Route path="/redirect" element={<Redirect />} />
					<Route
						path="/start"
						element={
							<brandguide.Container urlgen={routing.redirect}>
								<brandguide.Start />
							</brandguide.Container>
						}
					/>
					<Route
						path="/build/*"
						element={
							<brandguide.Container urlgen={routing.redirect}>
								<build.Routes />
							</brandguide.Container>
						}
					/>
					<Route path="*" element={<Navigate to={routing.redirect(brand.id)} replace />} />
				</Routes>
			</cache.Storage>
		</layouts.backgrounds.Grey>
	)
}

import React, { useState, useEffect } from "react"
import * as timex from "timex"
import * as layouts from "layouts"
import * as httpx from "httpx"
import { CancellablePromise } from "real-cancellable-promise"
import classnames from "classnames"
import { uploaded } from "./api"
import * as styles from "./progress.styles"
import * as errors from "errors"
import * as typography from "typography"

export default function Processing<T>(
	props: React.PropsWithChildren<{
		className: string
		item: uploaded<T>
		refresh(data: T): CancellablePromise<T>
		onChange(item: uploaded<T>): void
		onError(item: uploaded<T>): void
	}>,
): JSX.Element {
	const { className, item, onChange, onError, refresh } = props

	const [cause, setCause] = useState(undefined as errors.Cause)

	const _refresh = () => {
		const retry = httpx.autoretry()
		retry
			.wrap(() => refresh(item.data))
			.then((result) => {
				onChange({ ...item, data: result })
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				console.warn(cause)
				setCause(
					<layouts.Flex className={classnames("display-processing", className, styles.Container)} mb="5px">
						<typography.danger fontSize="10px">Failed: {item.display}</typography.danger>
					</layouts.Flex>,
				)
			})
	}

	useEffect(_refresh, []) // immediately refresh on mount
	useEffect(() => {
		const tid = setTimeout(_refresh, timex.backoff.noise(timex.duration.seconds(2)).toMillis())
		return () => {
			clearTimeout(tid)
		}
	}, [item])

	useEffect(() => {
		if (!cause) return
		const tid = setTimeout(() => onError(item), timex.duration.seconds(3).toMillis())
		return () => {
			clearTimeout(tid)
		}
	}, [cause])

	if (cause) return cause

	return (
		<layouts.Flex className={classnames("display-processing", className, styles.Container)} mb="5px">
			<layouts.Span className={styles.Description} flex="1">
				Processing: {item.display}
			</layouts.Span>
			<layouts.Span className={styles.Description} flex="1">
				<layouts.bars.progress.Sliding background={item.background} />
			</layouts.Span>
		</layouts.Flex>
	)
}

import React from "react"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import styled from "@emotion/styled"

type searchResponseItem = api.ImageSearchResponseItem | api.TextSearchResponseItem

interface props {
	item: searchResponseItem
	previtemstate: searchResponseItem | undefined
	agree(item: searchResponseItem): Promise<searchResponseItem>
	disagree(item: searchResponseItem): Promise<searchResponseItem>
	undo(current: searchResponseItem, previously: searchResponseItem): Promise<searchResponseItem>
}

export const ActionButton = styled(layouts.buttons.outline)`
	border: 1px solid ${layouts.theme.colors.white};
	color: ${layouts.theme.colors.white};
	font-size: 12px;
	font-weight: 400;
	background-color: unset;
	padding: 5px;
	margin-right: 10px;
	&:hover {
		background-color: ${layouts.theme.backgrounds.dark50alpha20};
		color: unset;
	}
`

function Container(props: React.PropsWithChildren): JSX.Element {
	const { children } = props
	return (
		<layouts.containers.flex
			flex="1"
			minHeight="35px"
			maxHeight="35px"
			fontSize="12pt"
			fontWeight="400px"
			color={layouts.theme.colors.white}
		>
			{children}
		</layouts.containers.flex>
	)
}

export default function Display(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return <Container {...rest}>{children}</Container>
}

export function Uploads(props: props & layouts.containers.FlexProps): JSX.Element {
	const { item, previtemstate, agree, disagree, undo, ...rest } = props
	const _approve = <ActionButton onClick={() => agree(item)}>YES</ActionButton>
	const _reject = <ActionButton onClick={(evt) => disagree(item)}>NO</ActionButton>
	const _undo = previtemstate ? <ActionButton onClick={(evt) => undo(item, previtemstate)}>UNDO</ActionButton> : <></>

	switch (item.event?.observation) {
		case api.Prediction.APPROVED_BY_SYSTEM:
			return (
				<layouts.containers.flex background={layouts.theme.colors.blue.blue} {...rest}>
					<layouts.containers.flex px="10px">Approved by BrandGuard</layouts.containers.flex>
				</layouts.containers.flex>
			)
		case api.Prediction.APPROVED_BY_REVIEW:
			return (
				<layouts.containers.flex background={layouts.theme.colors.blue.blue} {...rest}>
					<layouts.containers.flex px="10px">Approved by Reviewer</layouts.containers.flex>
				</layouts.containers.flex>
			)
		case api.Prediction.APPROVED:
			return (
				<layouts.containers.flex background={layouts.theme.colors.blue.blue} {...rest}>
					<layouts.containers.flex px="10px">Approved</layouts.containers.flex>
					{_undo}
				</layouts.containers.flex>
			)
		case api.Prediction.REJECTED:
			return (
				<layouts.containers.flex background={layouts.theme.colors.red.cinnabar} {...rest}>
					<layouts.containers.flex px="10px">Rejected</layouts.containers.flex>
					{_undo}
				</layouts.containers.flex>
			)
		case api.Prediction.INREVIEW:
			return (
				<layouts.containers.flex background={layouts.theme.colors.grey.dark50} {...rest}>
					<layouts.containers.flex px="10px">Waiting for Reviewer</layouts.containers.flex>
					{_undo}
				</layouts.containers.flex>
			)
		case api.Prediction.REJECTED_BY_REVIEW:
			return (
				<layouts.containers.flex background={layouts.theme.colors.red.cinnabar} {...rest}>
					<layouts.containers.flex px="10px">Rejected by Reviewer</layouts.containers.flex>
					{_undo}
				</layouts.containers.flex>
			)
		case api.Prediction.DISCARDED_BY_UPLOADER:
			return (
				<layouts.containers.flex background={layouts.theme.colors.red.cinnabar} {...rest}>
					<layouts.containers.flex px="10px">Rejected by Creator</layouts.containers.flex>
					{_undo}
				</layouts.containers.flex>
			)
		case api.Prediction.ERROR:
			return (
				<layouts.containers.flex background={layouts.theme.colors.red.cinnabar} {...rest}>
					<layouts.containers.flex px="10px">Error</layouts.containers.flex>
				</layouts.containers.flex>
			)
		default:
			return (
				<layouts.containers.flex background={layouts.theme.colors.grey.dark20alpha} {...rest}>
					<layouts.containers.flex px="10px">Request review?</layouts.containers.flex>
					{_approve}
					{_reject}
				</layouts.containers.flex>
			)
	}
}

Uploads.defaultProps = {
	flex: "1",
	justifyContent: "flex-start",
	alignItems: "center",
}

export function Review(props: props & layouts.containers.FlexProps): JSX.Element {
	const { item, previtemstate, agree, disagree, undo, ...rest } = props

	const _approve = <ActionButton onClick={() => agree(item)}>YES</ActionButton>
	const _reject = <ActionButton onClick={(evt) => disagree(item)}>NO</ActionButton>
	const _undo = previtemstate ? <ActionButton onClick={(evt) => undo(item, previtemstate)}>UNDO</ActionButton> : <></>

	switch (item.event?.observation) {
		case api.Prediction.APPROVED:
			return (
				<layouts.containers.flex background={layouts.theme.colors.blue.blue} {...rest}>
					<layouts.containers.flex px="10px">Sent to Approved Assets</layouts.containers.flex>
					{_undo}
				</layouts.containers.flex>
			)
		case api.Prediction.REJECTED:
			return (
				<layouts.containers.flex background={layouts.theme.colors.red.cinnabar} {...rest}>
					<layouts.containers.flex px="10px">Sent to Rejected Assets</layouts.containers.flex>
					{_undo}
				</layouts.containers.flex>
			)
		case api.Prediction.ERROR:
			return (
				<layouts.containers.flex background={layouts.theme.colors.red.cinnabar} {...rest}>
					<layouts.containers.flex px="10px">Error</layouts.containers.flex>
				</layouts.containers.flex>
			)
		default:
			return (
				<layouts.containers.flex background={layouts.theme.colors.grey.dark50} {...rest}>
					<layouts.containers.flex px="10px">Approve Asset?</layouts.containers.flex>
					{_approve}
					{_reject}
				</layouts.containers.flex>
			)
	}
}

Review.defaultProps = {
	flex: "1",
	justifyContent: "flex-start",
	alignItems: "center",
}

export function ApprovalLog(
	props: Omit<props, "previtemstate" | "agree" | "disagree" | "undo"> & layouts.containers.FlexProps,
): JSX.Element {
	const { item, ...rest } = props

	if (item.event?.observation === api.Prediction.APPROVED)
		return (
			<layouts.containers.flex background={layouts.theme.colors.blue.blue} {...rest}>
				<layouts.containers.flex px="10px">Approved</layouts.containers.flex>
			</layouts.containers.flex>
		)
	if (item.event?.observation === api.Prediction.REJECTED)
		return (
			<layouts.containers.flex background={layouts.theme.colors.red.cinnabar} {...rest}>
				<layouts.containers.flex px="10px">Rejected</layouts.containers.flex>
			</layouts.containers.flex>
		)

	return <></>
}

ApprovalLog.defaultProps = {
	flex: "1",
	justifyContent: "flex-start",
	alignItems: "center",
}

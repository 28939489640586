import React, { useEffect, useState } from "react"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"
import * as brands from "brands"
import * as notiflayouts from "./layouts"
import * as icons from "icons"
import * as brandguard from "brandguard"
import * as timex from "timex"
import * as navigation from "navigation"
import * as profiles_api from "profiles/api"

import * as api from "./api"
import { Link } from "react-router-dom"

export default function Display(): JSX.Element {
	const brand = brands.caching.useCached()
	const nav = navigation.context.useCached()

	const profilesReq = { limit: 100n, enabled: profiles_api.StatusFilters.All } as profiles_api.proto.SearchRequest
	const [profiles, setProfiles] = useState({ next: profilesReq, items: [] } as profiles_api.proto.SearchResponse)

	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const [nreq, setNotifRequest] = useState(api.zeros.search({ limit: 20n }))
	const [notifications, setNotifications] = useState({
		next: nreq,
		items: [],
	} as api.SearchResponse)
	const bearertoken = sessions.useToken()

	const [checkedItems, setCheckedItems] = useState([] as api.Notification[])

	useEffect(() => {
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => api.search(brand.id, nreq, bearertoken))
			.then(setNotifications)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline>
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve notifications</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
		return pending.cancel
	}, [nreq])

	useEffect(() => {
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => profiles_api.search(profilesReq, bearertoken))
			.then(setProfiles)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
			})
		return p.cancel
	}, [])

	const _allselected = notifications.items.length > 0 && checkedItems.length === notifications.items.length
	const handleSelectAll = (): void => {
		if (_allselected) {
			setCheckedItems([])
			return
		}
		setCheckedItems(notifications.items)
	}

	const handleMarkRead = (ns: api.Notification[]) => {
		ns.forEach((n) => {
			const unread = timex.utc() <= timex.from.iso(n.viewed_at)
			if (!unread) return
			const retry = httpx.autoretry()
			retry.wrap(() =>
				api
					.set_as_read(brand.id, n.id, bearertoken)
					.then((r) => {
						setNotifications({
							...notifications,
							items: notifications.items.map((i) => (i.id === r.notification?.id ? r.notification : i)),
						})
						nav.update({ ...nav, unread_notification_cnt: nav.unread_notification_cnt - 1 })
						if (checkedItems.find((i) => i.id === r.notification?.id))
							setCheckedItems(checkedItems.map((i) => (i.id === r.notification?.id ? r.notification : i)))
					})
					.catch(httpx.errors.cancellation(console.warn))
					.catch((e) => console.error(e)),
			)
		})
	}

	const handleDelete = (ns: api.Notification[]) => {
		ns.forEach((n) => {
			const unread = timex.utc() <= timex.from.iso(n.viewed_at)
			const retry = httpx.autoretry()
			retry.wrap(() =>
				api
					.destroy(brand.id, n.id, bearertoken)
					.then((r) => {
						setNotifications({
							...notifications,
							items: notifications.items.filter((i) => i.id !== r.notification?.id),
						})
						if (unread) nav.update({ ...nav, unread_notification_cnt: nav.unread_notification_cnt - 1 })
						if (checkedItems.find((i) => i.id === r.notification?.id))
							setCheckedItems(checkedItems.filter((i) => i.id !== r.notification?.id))
					})
					.catch(httpx.errors.cancellation(console.warn))
					.catch((e) => console.error(e)),
			)
		})
	}

	const hasUnreadInChecked =
		checkedItems.length > 0 && checkedItems.map((i) => timex.utc() <= timex.from.iso(i.viewed_at)).includes(true)

	if (notifications.items.length === 0)
		return (
			<layouts.Flex flex="1" alignItems="center" justifyContent="center" background={layouts.theme.colors.white}>
				You have no notifications
			</layouts.Flex>
		)

	return (
		<layouts.overlays.Container flex="1">
			<layouts.Flex className="notifications-display" flex="1" flexDirection="column">
				{cause}
				<layouts.loading.screen
					className="grid-area"
					loading={loading}
					flex="1"
					overflow="hidden"
					icon={<></>}
					flexDirection="column"
				>
					<layouts.containers.flex className="actions" justifyContent="flex-end" my="20px" gridGap="10px">
						<notiflayouts.ActionButton
							disabled={notifications.items.length === 0}
							cursor="pointer"
							className="select-all-action"
							onClick={handleSelectAll}
						>
							{_allselected ? "Unselect All" : "Select All"}
						</notiflayouts.ActionButton>
						{hasUnreadInChecked && (
							<notiflayouts.ActionButton
								width="150px"
								cursor="pointer"
								className="select-all-action"
								onClick={() => handleMarkRead(checkedItems)}
							>
								Mark selected as read
							</notiflayouts.ActionButton>
						)}
						{checkedItems.length > 0 && (
							<notiflayouts.ActionButton
								width="135px"
								cursor="pointer"
								className="select-all-action"
								onClick={() => handleDelete(checkedItems)}
							>
								Delete Selected
							</notiflayouts.ActionButton>
						)}
					</layouts.containers.flex>
					<layouts.containers.flex className="center-panel" flex="1" flexDirection="column" minHeight="100px">
						{notifications.items.map((n, i) => {
							const checked = checkedItems.find((i) => i.id === n.id)
							const unread = timex.utc() <= timex.from.iso(n.viewed_at)
							return (
								<notiflayouts.styledFormContainer key={i} alignItems="center">
									<CheckMark unread={unread} />
									<layouts.Flex mx="5px">
										<icons.brandguard.checkbox
											bottom="10px"
											right="10px"
											onClick={(e) => {
												setCheckedItems(checked ? checkedItems.filter((i) => i.id !== n.id) : [...checkedItems, ...[n]])
												e.stopPropagation()
											}}
											checked={!!checked}
										/>
									</layouts.Flex>
									<layouts.Flex fontWeight="700" mx="5px">
										<notiflayouts.styledFormLabel>{NotificationTitle(n)}</notiflayouts.styledFormLabel>
									</layouts.Flex>
									<notiflayouts.styledFormLabel>
										{notificationDescription(n, profiles.items || [])}{" "}
										<NotificationLink n={n} onLink={() => handleMarkRead([n])} />
									</notiflayouts.styledFormLabel>
									<layouts.Flex gridGap="10px" ml="auto">
										{unread && (
											<layouts.Flex alignItems="center" onClick={() => handleMarkRead([n])}>
												<icons.Eye width="14px" height="14px" />
											</layouts.Flex>
										)}
										<layouts.Flex alignItems="center" onClick={() => handleDelete([n])}>
											<icons.Trash width="14px" height="14px" />
										</layouts.Flex>
									</layouts.Flex>
								</notiflayouts.styledFormContainer>
							)
						})}
					</layouts.containers.flex>
				</layouts.loading.screen>
				<layouts.pagination.Cursor
					justifyContent="center"
					current={nreq.offset}
					advance={Number(notifications.next!.offset) === -1 ? undefined : notifications.next?.offset}
					onChange={(next) => {
						setNotifRequest({
							...nreq,
							offset: next,
						})
					}}
				/>
			</layouts.Flex>
		</layouts.overlays.Container>
	)
}

function CheckMark(props: { unread: boolean }): JSX.Element {
	const { unread } = props
	if (unread)
		return (
			<layouts.Flex
				borderRadius="50%"
				background={layouts.theme.colors.red.cinnabar}
				minWidth="10px"
				minHeight="10px"
				alignItems="center"
				justifyContent="center"
				mx="5px"
			/>
		)
	return <layouts.Flex width="20px" />
}

const notificationDescription = (n: api.Notification, profiles: profiles_api.proto.Profile[]): string => {
	const display = profiles.find((p) => p.id === n.initiator_id)?.display || n.initiator_id
	switch (n.event) {
		case api.Event.IMG_NEED_REVIEW || api.Event.TEXT_NEED_REVIEW:
			return `${display} requested an asset review`
		case api.Event.IMG_REVIEWED || api.Event.TEXT_REVIEWED:
			return `${display} reviewed the asset`
		default:
			return ""
	}
}

const NotificationTitle = (n: api.Notification): string => {
	switch (n.event) {
		case api.Event.IMG_NEED_REVIEW:
			return "Image Review Request"
		case api.Event.TEXT_NEED_REVIEW:
			return "Text Review Request"
		case api.Event.IMG_REVIEWED:
			return "Image Asset Reviewed"
		case api.Event.TEXT_REVIEWED:
			return "Text Asset Reviewed"
		default:
			return ""
	}
}

function NotificationLink(props: { n: api.Notification; onLink(): void }): JSX.Element {
	const { n, onLink } = props
	const bid = n.brand_id
	let link = "#"
	switch (n.event) {
		case api.Event.IMG_NEED_REVIEW:
			link = brandguard.routing.image.approval.review(bid)
			break
		case api.Event.IMG_REVIEWED:
			link =
				n.event_prediction === api.Prediction.APPROVED
					? brandguard.routing.image.approval.approved(bid)
					: brandguard.routing.image.approval.rejected(bid)
			break
		case api.Event.TEXT_NEED_REVIEW:
			link = brandguard.routing.text.approval.review(bid)
			break
		case api.Event.TEXT_REVIEWED:
			link =
				n.event_prediction === api.Prediction.APPROVED
					? brandguard.routing.text.approval.approved(bid)
					: brandguard.routing.text.approval.rejected(bid)
			break
		default:
			break
	}

	return (
		<Link to={link} onClick={onLink}>
			{n.event_id}
		</Link>
	)
}

import React from "react"
import styled from "@emotion/styled"
import * as system from "styled-system"

interface props
	extends system.SizeProps,
		system.FlexProps,
		system.MarginProps,
		system.MaxWidthProps,
		system.MaxHeightProps {
	objectFit: string
}

const Img = styled.img<props>`
	object-fit: ${(props) => props.objectFit};
	${system.margin}
	${system.size}
	${system.flex}
	${system.maxWidth}
	${system.maxHeight}
`

export function Base(props: React.ImgHTMLAttributes<HTMLImageElement> & props): JSX.Element {
	const { src, alt, ...rest } = props

	return <Img src={src} alt={alt} {...rest}></Img>
}

Base.defaultProps = {
	objectFit: "contain",
}

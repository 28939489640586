import { SVG, SVGProps } from "./svg"
import * as theme from "layouts/theme"

interface props extends SVGProps {
	width?: string
	height?: string
	stroke?: string
}

export const check = (props: props) => {
	let { ...rest } = props
	return (
		<SVG width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20 2L6.57591 16L2 11.2278"
				stroke={theme.colors.white}
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
				{...rest}
			/>
		</SVG>
	)
}

export const x = (props: props) => {
	let { ...rest } = props
	return (
		<SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16 2L9 9M9 9L2 16M9 9L16 16M9 9L2 2"
				stroke={theme.colors.white}
				strokeWidth="3"
				strokeLinecap="round"
				{...rest}
			/>
		</SVG>
	)
}

import * as httputils from "httpx"
import * as oauth2 from "authz/oauth2"

export const scopes = {
	adwords: "https://www.googleapis.com/auth/adwords",
}

export interface Credential {
	id: string
	domain: string
	description: string
	created_at: string
	updated_at: string
	disabled_at: string
}

export interface SearchRequest {
	query: string
}

export interface SearchResponse {
	items: Credential[]
}

export function search(req: SearchRequest, ...options: httputils.option[]): Promise<SearchResponse> {
	return httputils.get(`${httputils.urlstorage.host()}/authz/gsuite/`, req, ...options)
}

export interface FindResponse {
	credential: Credential
}

export function find(id: string, ...options: httputils.option[]): Promise<FindResponse> {
	return httputils.get(`${httputils.urlstorage.host()}/authz/gsuite/${id}`, {}, ...options)
}

export function authorize_url(
	req: oauth2.AuthorizeRequest,
	...options: httputils.option[]
): Promise<oauth2.AuthorizeResponse> {
	return httputils.get(`${httputils.urlstorage.host()}/authz/gsuite/authz`, req, ...options)
}

export function exchange(req: oauth2.ExchangeRequest, ...options: httputils.option[]): Promise<unknown> {
	return httputils.post(`${httputils.urlstorage.host()}/authz/gsuite/authz`, req, ...options)
}

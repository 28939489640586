import { SVG, SVGProps } from "./svg"
export function Icon(props: SVGProps): JSX.Element {
	const { className, ...rest } = props
	return (
		<SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 46 46"
			{...rest}
		>
			<g transform="translate(-668.000000, -219.000000)"></g>
			<g transform="translate(15.000000, 15.000000)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#4285F4"
					d="M21.6,9.4c0-1-0.1-2-0.3-2.9H8V12h7.7c-0.3,1.8-1.3,3.3-2.8,4.3v3.6h4.6C20.1,17.4,21.6,13.7,21.6,9.4L21.6,9.4z"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#34A853"
					d="M8,23.3 c3.8,0,7.1-1.3,9.4-3.4l-4.6-3.6c-1.3,0.9-2.9,1.4-4.8,1.4c-3.7,0-6.8-2.5-8-5.9h-4.7v3.7C-2.4,20.1,2.4,23.3,8,23.3L8,23.3z"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FBBC05"
					d="M0,11.8 c-0.3-0.9-0.4-1.8-0.4-2.7S-0.2,7.2,0,6.4V2.7h-4.7c-1,1.9-1.5,4.1-1.5,6.4s0.5,4.5,1.5,6.4L0,11.8L0,11.8z"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#EA4335"
					d="M8,0.5 c2.1,0,4,0.7,5.4,2.1l4.1-4.1C15-3.7,11.8-5.1,8-5.1C2.4-5.1-2.4-2-4.7,2.7L0,6.4C1.2,3,4.3,0.5,8,0.5L8,0.5z"
				/>
				<path fill="none" d="M-6.2-5.1h28.4 v28.4H-6.2V-5.1z" />
			</g>
		</SVG>
	)
}

export default Icon

import React from "react"
import * as layouts from "layouts"
import * as icons from "icons"
import styled from "@emotion/styled"
import { api } from "brandguard"
import * as typography from "typography"
import * as bgscores from "brandguard/display/scores"
import { css } from "@emotion/css"
import { State } from "accounts/meta.account.score.settings"
export * as uploads from "./uploads"

export const styledMainScore = css`
	span {
		font-size: 16px;
		font-weight: 800;
	}
`

export const styledScore = css`
	span {
		font-size: 12px;
		font-weight: 800;
		line-height: 14px;
		letter-spacing: 0px;
		text-align: center;
		color: ${layouts.theme.colors.grey.dark50};
		&:hover {
			color: ${layouts.theme.colors.grey.dark50};
		}
	}
`

const NoSubScoresContainer = styled(layouts.containers.flex)`
	flex-direction: column;
	padding-left: 15px;
	padding-right: 54px;
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 10pt;
	font-weight: 400;
	line-height: 25pt;
	color: ${layouts.theme.colors.grey.dark50};
	border: ${`1px solid ${layouts.theme.colors.grey.light05}`};
	border-radius: 8px;
	&.extension {
		color: ${layouts.theme.extension.colors.white.alpha85};
		border: ${`1px solid ${layouts.theme.extension.colors.blue.border}`};
		background: transparent;
		margin: 0 12px 16px;
	}
`

export const ScoreAccordionDescription = styled(layouts.containers.flex)`
	flex-direction: column;
	font-size: 10pt;
	font-weight: 400;
	line-height: 25pt;
	color: ${layouts.theme.colors.grey.dark50};
	flex: 1;
	&.extension {
		color: ${layouts.theme.extension.colors.white.alpha85};
	}
`
ScoreAccordionDescription.defaultProps = {
	padding: "0 0 0 15px",
}

const SubScoreContainer = styled(layouts.containers.flex)`
	background: ${layouts.theme.colors.white};
	padding-left: 20px;
	min-width: 210px;
	max-width: 220px;
	min-height: 100px;
	&.extension {
		padding-left: 10px;
		background: transparent;
	}
`

const SubTitle = styled(typography.h3)`
	font-size: 10pt;
	font-weight: 400;
	line-height: 14pt;
	margin-right: 54px;
	color: ${layouts.theme.colors.grey.dark50};
	&.extension {
		margin-right: 10px;
		color: ${layouts.theme.extension.colors.white.alpha85};
	}
`
SubTitle.defaultProps = {
	whiteSpace: "unset",
}

export const SubSubScoreContainer = styled(layouts.containers.flex)`
	padding-left: 15px;
	flex-direction: column;
	font-size: 10pt;
	font-weight: 400;
	line-height: 25pt;
	color: ${layouts.theme.colors.grey.dark50};
	margin-bottom: 3px;
	&.extension {
		margin-right: 20px;
		padding-left: 10px;
		color: ${layouts.theme.extension.colors.white.alpha85};
	}
`
SubSubScoreContainer.defaultProps = {
	mr: "54px",
	className: "sub-score-container",
}

const BGScoreContainer = styled(layouts.containers.box)`
	min-height: 47px;
	width: 100%;
	position: relative;
	&.extension {
		display: none;
	}
`
BGScoreContainer.defaultProps = {
	marginBottom: "20px",
}

const BGScoreSimpleContainer = styled(layouts.containers.flex)`
	flex-direction: row;
	border-radius: 0px 0px 5px 5px;
	padding: 0.3em;
	&.extension {
		padding: 0;
	}
	&.extension.recent-score {
		position: absolute;
		height: 19px;
		top: "0";
		right: "0";
		padding: 0.1em;
	}
`

const BGScoreSimpleLogoContainer = styled(layouts.containers.flex)`
	padding-left: 3px;
	margin-top: -3px;
	&.extension:not(.recent-score) {
		display: none;
	}
`

const BGScoreSimpleLogoIcon = styled(icons.Logo)`
	width: 0.5vw;
	&.extension.recent-score {
		width: 10px;
	}
`

BGScoreSimpleContainer.defaultProps = {
	background: layouts.theme.colors.white,
}

const BGScoreSimpleDisplayScoreContainer = styled(layouts.containers.flex)`
	color: ${layouts.theme.colors.white};
	align-items: center;
	padding-left: 5px;
	&.extension {
		padding-left: unset;
		&.recent-score {
			padding-left: 3px;
		}
	}
`

const BGScoreSimpleDisplayScore = styled(bgscores.Percentage)`
	font-size: 0.7vw;
	font-weight: 600;
	&.extension {
		font-size: 20px;
		&.recent-score {
			font-size: 13px;
		}
	}
`

export interface subScoreStruct {
	title: string
	score: number
	inverse: boolean
	indev: boolean
	available: number
	additions: JSX.Element | undefined
}

export interface scoreStruct {
	title: string
	indev: boolean
	subtitle?: string
	subscores: subScoreStruct[]
}

const minProgressWeight = 5

export function sum(array: number[]): number {
	return array.reduce((a, b) => a + b, 0)
}

function calculatePercentage(prediction: api.Prediction, mainScore: number, otherScores: subScoreStruct[]) {
	const filtered = otherScores.filter((i) => i.score !== -1)
	let othscore = 0
	filtered.forEach((i) => {
		const score = i.inverse ? 1 - i.score : i.score
		othscore = othscore + score
	})
	const average = filtered.length > 0 ? othscore / filtered.length : 0
	const result = mainScore * 0.8 + average * 0.2
	if (prediction === api.Prediction.REJECTED && result > 0.5) {
		return result * 0.5
	}
	if (prediction === api.Prediction.APPROVED && result < 0.5) {
		return 0.5 * (result + 1)
	}
	return result
}

export const isNonNegotiable = (score: number, indev: boolean, inverse: boolean, state: State): boolean => {
	if (indev || score === -1 || state !== State.NON_NEGOTIABLE) return false
	const percent = inverse ? Math.round((1 - score) * 100) : Math.round(score * 100)
	return percent < 100
}

export function BGScore(
	props: {
		mainScore: number
		otherScores: subScoreStruct[]
		prediction: api.Prediction
	} & layouts.containers.ContainerProps,
): JSX.Element {
	const { mainScore, otherScores, prediction, className } = props
	const cgradient = calculatePercentage(prediction, mainScore, otherScores)

	return (
		<BGScoreContainer className={className}>
			<typography.h2
				color={layouts.theme.colors.grey.dark50}
				fontSize="18px"
				fontWeight="700"
				lineHeight="30px"
				pl="10px"
			>
				BrandGuard Score
			</typography.h2>
			<typography.h2
				color={layouts.theme.colors.grey.dark50}
				fontSize="18px"
				fontWeight="700"
				lineHeight="30px"
				pr="10px"
			>
				<bgscores.Percentage score={cgradient} indev={false} />
			</typography.h2>
		</BGScoreContainer>
	)
}

const calculateBGScoreImage = (event: api.ImageEvent) => {
	const mainScore = event.brand_voice === -1 ? 0 : event.brand_voice
	const otherScores = [
		{ score: event.image_quality_general, inverse: false },
		{ score: event.image_violence, inverse: true },
		{ score: event.sexually_explicit, inverse: true },
	] as subScoreStruct[]

	return calculatePercentage(event.approved, mainScore, otherScores)
}

export function BGScoreImageSimple(props: { event: api.ImageEvent } & layouts.containers.ContainerProps): JSX.Element {
	const { event, className } = props
	const rejected = event.approved === api.Prediction.REJECTED

	return (
		<BGScoreSimpleContainer
			background={rejected ? layouts.theme.colors.red.cinnabar : layouts.theme.colors.blue.blue}
			className={className}
		>
			<BGScoreSimpleLogoContainer className={className}>
				<BGScoreSimpleLogoIcon fill={layouts.theme.colors.white} className={className} />
			</BGScoreSimpleLogoContainer>
			<BGScoreSimpleDisplayScoreContainer className={className}>
				<BGScoreSimpleDisplayScore score={calculateBGScoreImage(event)} indev={false} className={className} />
			</BGScoreSimpleDisplayScoreContainer>
		</BGScoreSimpleContainer>
	)
}

export function BGScoreImageSimpleWithoutPrediction(
	props: { event: api.ImageEvent } & layouts.containers.ContainerProps,
): JSX.Element {
	const { event, className, ...rest } = props
	return (
		<BGScoreSimpleContainer className={className} {...rest}>
			<BGScoreSimpleDisplayScoreContainer className={className}>
				<bgscores.Percentage score={calculateBGScoreImage(event)} indev={false} className={className} />
			</BGScoreSimpleDisplayScoreContainer>
		</BGScoreSimpleContainer>
	)
}

const calculateBGScoreText = (event: api.TextEvent) => {
	const mainScore = event.onbrand === -1 ? 0 : event.onbrand
	const otherScores = [
		{ score: event.grammar, inverse: false },
		{ score: event.profanity, inverse: true },
		{ score: event.racism, inverse: true },
	] as subScoreStruct[]

	return calculatePercentage(event.approved, mainScore, otherScores)
}

export function BGScoreTextSimple(props: { event: api.TextEvent } & layouts.containers.ContainerProps): JSX.Element {
	const { event, className } = props
	const rejected = event.approved === api.Prediction.REJECTED

	return (
		<BGScoreSimpleContainer
			background={rejected ? layouts.theme.colors.red.cinnabar : layouts.theme.colors.blue.blue}
			className={className}
		>
			<BGScoreSimpleLogoContainer className={className}>
				<BGScoreSimpleLogoIcon fill={layouts.theme.colors.white} className={className} />
			</BGScoreSimpleLogoContainer>
			<BGScoreSimpleDisplayScoreContainer className={className}>
				<BGScoreSimpleDisplayScore score={calculateBGScoreText(event)} indev={false} className={className} />
			</BGScoreSimpleDisplayScoreContainer>
		</BGScoreSimpleContainer>
	)
}

export function BGScoreTextSimpleWithoutPrediction(
	props: { event: api.TextEvent } & layouts.containers.ContainerProps,
): JSX.Element {
	const { event, className, ...rest } = props

	return (
		<BGScoreSimpleContainer className={className} {...rest}>
			<BGScoreSimpleDisplayScoreContainer className={className}>
				<bgscores.Percentage score={calculateBGScoreText(event)} indev={false} className={className} />
			</BGScoreSimpleDisplayScoreContainer>
		</BGScoreSimpleContainer>
	)
}

export function ScoreItem(
	props: { i: scoreStruct; initOpen: boolean } & layouts.containers.ContainerProps,
): JSX.Element {
	const { i, initOpen, className } = props
	const theme = layouts.useTheme()

	const calcScoreAverage = (): number => {
		const arr = i.subscores.filter((a) => !(a.score === -1 || a.indev)).map((a) => (a.inverse ? 1 - a.score : a.score))
		const sum = arr.reduce((a, sum) => sum + a, 0)
		return arr.length === 0 ? -1 : sum / arr.length
	}

	const average = calcScoreAverage()
	let weight = Math.round(average * 100)
	if (i.indev || average === -1) {
		weight = 0
	} else {
		if (weight < minProgressWeight) weight = minProgressWeight
	}

	const sorted = i.subscores.sort((a, b) => {
		if (a.score === -1 || a.indev) return 1
		if (b.score === -1 || b.indev) return -1
		const _a = a.inverse ? Math.round((1 - a.score) * 100) : Math.round(a.score * 100)
		const _b = b.inverse ? Math.round((1 - b.score) * 100) : Math.round(b.score * 100)
		return _a - _b
	})

	const subscores = (
		<SubScoreContainer className={`${className} brandguard-subscore`}>
			<layouts.containers.box className="subscore-box">
				<SubTitle pt="10px" pb="5px" px="5px" textAlign="center" className={className}>
					{i.subtitle}
				</SubTitle>
				{sorted.map((subscore, index) => {
					if (subscore.available === State.DISABLED) return <React.Fragment key={index} />

					let weight = Math.round(subscore.score * 100)
					if (subscore.indev || subscore.score === -1) {
						weight = 0
					} else {
						if (subscore.inverse) weight = 100 - weight
						if (weight < minProgressWeight) weight = minProgressWeight
					}
					if (weight < minProgressWeight) weight = minProgressWeight
					if (subscore.indev || subscore.score === -1) weight = 0

					const _isNonNegotiable = isNonNegotiable(subscore.score, subscore.indev, subscore.inverse, subscore.available)

					const SubScore = (
						<>
							<layouts.containers.flex flexDirection="row" flex="1">
								<layouts.containers.flex
									flex="3"
									justifyContent="flex-start"
									alignItems="center"
									color={
										_isNonNegotiable
											? theme.color(layouts.theme.colors.red.cinnabar, layouts.theme.colors.red.bright)
											: "unset"
									}
								>
									{subscore.title}
								</layouts.containers.flex>
								<layouts.containers.flex flex="1" justifyContent="flex-end" alignItems="center">
									<bgscores.Percentage
										score={subscore.score}
										indev={subscore.indev}
										inverse={subscore.inverse}
										fontWeight="500"
										nonnegotiable={_isNonNegotiable}
										className={className}
									/>
								</layouts.containers.flex>
							</layouts.containers.flex>
							<bgscores.PercentageBar weight={weight} />
						</>
					)

					if (subscore.additions)
						return React.cloneElement(subscore.additions, {
							children: <SubSubScoreContainer className={className}>{SubScore}</SubSubScoreContainer>,
							className: className,
						})

					return (
						<SubSubScoreContainer key={index} className={className}>
							{SubScore}
						</SubSubScoreContainer>
					)
				})}
			</layouts.containers.box>
		</SubScoreContainer>
	)

	if (sum(i.subscores.map((i) => i.available)) === 0) return <></>

	return (
		<bgscores.SectionAccordion initOpen={initOpen} className={className}>
			<layouts.containers.box tabIndex={0}>
				<bgscores.SectionDescription>
					<ScoreAccordionDescription className={className}>
						<layouts.containers.flex flexDirection="row" flex="1">
							<layouts.containers.flex flex="3" justifyContent="flex-start" alignItems="center">
								{i.title}
								{i.subscores.map((i) => isNonNegotiable(i.score, i.indev, i.inverse, i.available)).includes(true) && (
									<layouts.Flex mx="5px">
										<icons.Notice
											fill={theme.color(layouts.theme.colors.red.cinnabar, layouts.theme.colors.red.bright)}
										/>
									</layouts.Flex>
								)}
							</layouts.containers.flex>
							<layouts.containers.flex flex="1" justifyContent="flex-end" alignItems="center">
								<bgscores.Percentage score={average} indev={i.indev} fontWeight="500" className={className} />
							</layouts.containers.flex>
						</layouts.containers.flex>
						<bgscores.PercentageBar weight={weight} />
					</ScoreAccordionDescription>
				</bgscores.SectionDescription>
				<layouts.accordions.Content overflowX="hidden">{subscores}</layouts.accordions.Content>
			</layouts.containers.box>
		</bgscores.SectionAccordion>
	)
}

ScoreItem.defaultProps = {
	initOpen: false,
}

export function NoSubscoresItem(
	props: React.PropsWithChildren<
		{ score: number; indev: boolean; inverse: boolean; state: State } & layouts.containers.ContainerProps
	>,
): JSX.Element {
	const { score, indev, inverse, state, children, className } = props
	const theme = layouts.useTheme()
	let weight = Math.round(score * 100)
	if (indev || score === -1) {
		weight = 0
	} else {
		if (weight < minProgressWeight) weight = minProgressWeight
	}

	const _isNonNegotiable = isNonNegotiable(score, indev, inverse, state)

	return (
		<NoSubScoresContainer className={className}>
			<layouts.containers.flex flexDirection="row" flex="1">
				<layouts.containers.flex flex="3" justifyContent="flex-start" alignItems="center">
					{children}
					{_isNonNegotiable && (
						<layouts.Flex mx="5px">
							<icons.Notice fill={theme.color(layouts.theme.colors.red.cinnabar, layouts.theme.colors.red.bright)} />
						</layouts.Flex>
					)}
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" justifyContent="flex-end" alignItems="center">
					<bgscores.Percentage
						score={score}
						indev={indev}
						fontWeight="500"
						className={className}
						nonnegotiable={_isNonNegotiable}
					/>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<bgscores.PercentageBar weight={weight} />
		</NoSubScoresContainer>
	)
}

NoSubscoresItem.defaultProps = {
	inverse: false,
}

export function NonNegotiableMessage(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const theme = layouts.useTheme()
	return (
		<layouts.Flex
			background={theme.color(layouts.theme.colors.red.cinnabar, layouts.theme.colors.red.bright)}
			{...rest}
		>
			<layouts.Flex ml="10px" mr="5px">
				<icons.Notice fill={layouts.theme.colors.white} />
			</layouts.Flex>
			<layouts.Flex mr="5px">
				<typography.h6 color={layouts.theme.colors.white} fontSize="13px" fontWeight="700">
					Warning!
				</typography.h6>
			</layouts.Flex>
			<typography.h6
				color={layouts.theme.colors.white}
				fontSize="13px"
				fontWeight="400"
				whiteSpace="unset"
				lineHeight="13px"
			>
				Non-negotiable rule(s) broken.
			</typography.h6>
		</layouts.Flex>
	)
}

import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as apps from "brandguide/build/application"
import { Link } from "react-router-dom"
import { results } from "brandguide/build/layouts"
import * as navigation from "brandguide/build/navigation"
import * as authz from "authz"
import * as authzc from "authzcached"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideApplication | undefined): void
	onLoad(): void
}

export function SectionResult(props: props): JSX.Element {
	const { onChange, onLoad, ...rest } = props
	const guide = brandguide.cache.useGuide()
	const data = brandguide.api.zeros.guide.guideApplicationZero(guide.guide?.application)
	const [loadedImages, setLoadedImages] = useState(data.items.length)

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	useEffect(() => {
		if (loadedImages !== 0) return
		onLoad()
	}, [loadedImages])

	return (
		<layouts.Flex flex="1" {...rest}>
			<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
				<results.Title>Application</results.Title>
				<results.ResultDesc>
					Here you will find examples of pieces of content that we have published. Please use these as examples to
					further understand our brand guidelines and how to use them in real world scenarios.
				</results.ResultDesc>

				<results.SectionContainer>
					<results.Title>Examples</results.Title>
					<results.Explanation desc={data.descriptions?.upload} />

					<layouts.containers.grid
						gap="30px"
						gridTemplateColumns="repeat(auto-fill, minmax(250px, 250px))"
						gridTemplateRows="repeat(auto-fill, minmax(250px, 250px))"
						width="100%"
					>
						{(data.items || []).map((i) => (
							<layouts.Flex key={i.id} width="250px" height="250px" alignItems="center" justifyContent="center">
								<brandguide.build.layouts.B64ImageForPDF
									contentdigest={i.thumbnail_contentdigest}
									styles={{ maxWidth: "250px", maxHeight: "250px" }}
									onLoad={() => setLoadedImages((prev) => prev - 1)}
								/>
							</layouts.Flex>
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<authz.Protected enabled={permission.brandguide_edit}>
					<layouts.Flex justifyContent="center" mt="100px" mb="20px" alignItems="center">
						<NextStageLink guide={guide} className="no-print" />
						<navigation.Section
							title="Application"
							steps={apps.layouts.steps}
							onChange={(s) => onChange(s, undefined)}
						/>
					</layouts.Flex>
				</authz.Protected>
			</layouts.Flex>
			<colors.Guidelines activPage="application" className="no-print" />
		</layouts.Flex>
	)
}

function Display(props: props): JSX.Element {
	const { onChange, onLoad, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<layouts.Flex justifyContent="right" mr="47px">
						<brandguide.build.SavePDF />
					</layouts.Flex>
					<SectionResult onChange={onChange} onLoad={onLoad} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	onLoad: () => undefined,
}

interface nextProps extends layouts.containers.FlexProps {
	guide: brandguide.api.Brandguide
}

function NextStageLink(props: nextProps): JSX.Element {
	const { ...rest } = props
	// const brand = brands.caching.useCached()

	const nextLink = (): string | undefined => {
		return undefined
	}

	if (!nextLink()) return <></>

	return (
		<layouts.Flex {...rest}>
			<Link to={nextLink()!}>
				<layouts.buttons.unstyled
					width="130px"
					height="34px"
					background={layouts.theme.colors.grey.dark50}
					borderRadius="5px"
					color={layouts.theme.colors.white}
					onClick={() => undefined}
				>
					Next Section
				</layouts.buttons.unstyled>
			</Link>
		</layouts.Flex>
	)
}

NextStageLink.defaultProps = {
	flex: "1",
	justifyContent: "end",
}

export default Display

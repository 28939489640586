import * as brands from "brands"
import * as layouts from "layouts"
import * as typography from "typography"
import * as components from "."
import { colors } from "layouts/theme"
import { PropsWithChildren } from "react"

export const NavTextProps = {
	color: colors.grey.dark50,
	letterSpacing: "normal",
	whiteSpace: "unset",
}

export function NavTitle(props: PropsWithChildren<unknown>): JSX.Element {
	const { children } = props
	return (
		<typography.h4 lineHeight="24px" {...NavTextProps}>
			{children}
		</typography.h4>
	)
}

export function NavDescription(props: PropsWithChildren<unknown>): JSX.Element {
	const { children } = props
	return (
		<typography.h5 fontSize="10px" fontWeight="400" lineHeight="14px" {...NavTextProps}>
			{children}
		</typography.h5>
	)
}

export default function Display(props: PropsWithChildren<layouts.containers.ContainerProps>): JSX.Element {
	const { children, ...rest } = props
	const brand = brands.caching.useCached()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flexDirection="column">
				<layouts.Flex flexDirection="column" flex="1" p="10px">
					<typography.h3 fontSize="18px" lineHeight="24px" {...NavTextProps}>
						{brand.description} Brand Guidelines
					</typography.h3>
					<typography.h5 fontSize="12px" {...NavTextProps}>
						by BrandGuide Builder
					</typography.h5>
				</layouts.Flex>
				<layouts.Flex flexDirection="column" flex="1" px="20px" justifyContent="end">
					{children}
				</layouts.Flex>
				<layouts.Flex flexDirection="column" flex="1" p="10px" justifyContent="end">
					<typography.h5 fontSize="12px" {...NavTextProps}>
						{components.FormattedDate()}
					</typography.h5>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
	background: colors.grey.bg,
	minWidth: "25wv",
}

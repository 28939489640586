import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as timex from "timex"
import { css } from "@emotion/css"
import { Brand, api as brandsApi, routing as brandsRouting } from "brands"
import * as errors from "errors"
import * as httpx from "httpx"
import * as typography from "typography"
import * as icons from "icons"
import styled from "@emotion/styled"

export const styledFormContainer = styled(layouts.forms.Group)``

styledFormContainer.defaultProps = {
	alignItems: "center",
	padding: "10px",
	background: layouts.theme.colors.white,
	borderRadius: "5px",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha20}`,
}

export const styledFormLabel = styled.span``

styledFormLabel.defaultProps = {
	color: layouts.theme.colors.grey.dark10,
}

export const styledAccordion = css`
	border: 1px solid ${layouts.theme.colors.grey.dark50alpha20};
	padding: 10px !important;
	border-radius: 5px;
	border-bottom: 1px solid ${layouts.theme.colors.grey.dark50alpha20} !important;
	margin-bottom: 5px;
`

export const styledToggle = css`
	.toggle-icon {
		outline: 1px solid ${layouts.theme.colors.blue.blue};
	}
`

export const IconHoverStyles = css`
	&:hover {
		svg {
			path {
				stroke: ${layouts.theme.colors.white};
				fill: ${layouts.theme.colors.blue.blue};
			}
		}
	}
`

export const styledInput = css`
	input {
		padding-left: 5px;
		padding-right: 5px;
		border-radius: 5px;
		:hover,
		:focus {
			outline: none;
			box-shadow: none;
		}
	}
`

export const styledTextInput = css`
	input {
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 5px;
		background: ${layouts.theme.backgrounds.lightblue};
		min-height: 30px;
		:hover,
		:focus {
			outline: none;
			box-shadow: none;
		}
	}
`

export const TableHead = styled.th`
	vertical-align: middle;
`
export const TableRow = styled.tr`
	cursor: default;
	&:hover {
		color: ${layouts.theme.colors.grey.lightest};
		background: ${layouts.theme.colors.blue.blue};
	}
`
export const TableData = styled.td`
	vertical-align: middle;
`

export const TextArea = styled(inputs.TextArea)`
	border-radius: 10px;
	outline: none;
	height: 100px;
	padding: 10px;
	font-family: Roboto;
	background: ${layouts.theme.backgrounds.lightblue};
	border: none;
`

interface props extends layouts.containers.FlexProps {
	enqueue: (ago: timex.DateTime) => Promise<void>
}

export function RescoreEnqueue(props: props): JSX.Element {
	const { enqueue, ...rest } = props
	const [disabled, setDisabled] = useState(false)
	const [ago, setAgo] = useState(timex.duration.minutes(-5))

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxWidth="100px">
				<inputs.Numeric
					placeholder="minutes ago"
					defaultValue={ago.hours.toString()}
					min={1}
					max={60}
					onChange={(evt) => setAgo(timex.duration.minutes(-1 * evt.currentTarget.valueAsNumber))}
					className={styledInput}
				/>
			</layouts.Flex>
			<layouts.Flex flex="1">
				<layouts.buttons.primary
					ml="10px"
					borderRadius="0.25em"
					width="10ch"
					disabled={disabled}
					onClick={() => {
						setDisabled(true)
						enqueue(timex.utc().plus(ago))
							.then(() => setDisabled(false))
							.catch((cause) => {
								console.error("unable to admin rescore", cause)
								setDisabled(false)
							})
					}}
				>
					Rescore
				</layouts.buttons.primary>
			</layouts.Flex>
		</layouts.Flex>
	)
}

export function AdminTrainingEnqueue(props: props): JSX.Element {
	const { enqueue, ...rest } = props
	const [disabled, setDisabled] = useState(false)
	const [ago, setAgo] = useState(timex.duration.hours(100))

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxWidth="100px">
				<inputs.Numeric
					placeholder="hours"
					defaultValue={ago.hours.toString()}
					min={0}
					max={999}
					onChange={(evt) => setAgo(timex.duration.hours(evt.currentTarget.valueAsNumber))}
					className={styledInput}
				/>
			</layouts.Flex>
			<layouts.Flex flex="1">
				<layouts.buttons.primary
					ml="10px"
					borderRadius="0.25em"
					width="10ch"
					disabled={disabled}
					onClick={() => {
						setDisabled(true)
						enqueue(timex.utc().plus(ago))
							.then(() => setDisabled(false))
							.catch((cause) => {
								console.error("unable to enqueue admin training", cause)
								setDisabled(false)
							})
					}}
				>
					Train
				</layouts.buttons.primary>
			</layouts.Flex>
		</layouts.Flex>
	)
}

export function SuggestionsZeroState(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<typography.h3 fontWeight="700" fontSize="24px" lineHeight="32px" color={layouts.theme.colors.grey.dark50alpha80}>
				Enhance training data with suggested assets.
			</typography.h3>
			<icons.bgapproval.zeroIcon pt="50px" />
		</layouts.Flex>
	)
}

interface cloneProps extends layouts.containers.FlexProps {
	brand: Brand
	setLoading: (value: React.SetStateAction<boolean>) => void
	setCause: (value: React.SetStateAction<JSX.Element | undefined>) => void
	httpOptions: httpx.option
}

export function CloneBrandSettings(props: cloneProps): JSX.Element {
	const { brand, setLoading, setCause, httpOptions } = props
	const navigate = useNavigate()
	return (
		<layouts.buttons.outline
			mr="12px"
			px="10px"
			borderRadius="5px"
			onClick={() => {
				setLoading(true)
				const retry = httpx.autoretry()
				retry
					.wrap(() => brandsApi.clone({ brand }, httpOptions))
					.then((r) => {
						setLoading(false)
						navigate(brandsRouting.index())
					})
					.catch((cause) => {
						console.warn(cause)
						setCause(
							<errors.Display cause={cause} onClick={() => setCause(undefined)}>
								failed to copy settings
							</errors.Display>,
						)
						setLoading(false)
					})
			}}
		>
			Copy Settings to New Brand
		</layouts.buttons.outline>
	)
}

import * as httpx from "httpx"
import { CancellablePromise } from "real-cancellable-promise"
import {
	TextReferenceRuleViolation,
	TextReferenceRuleViolationSearchRequest,
	TextReferenceRuleViolationSearchResponse,
} from "brandguard/brandguard_pb"

export function zero(d: Partial<TextReferenceRuleViolation>): TextReferenceRuleViolation {
	return {
		id: "",
		account_id: "",
		brand_id: "",
		event_id: "",
		description: "",
		rule: "",
		explanation: "",
		created_at: "",
		...d,
	}
}

export namespace searches {
	export function request(
		d: Partial<TextReferenceRuleViolationSearchRequest> = {},
	): TextReferenceRuleViolationSearchRequest {
		return {
			offset: 0n,
			brand_id: "",
			limit: 100n,
			event_ids: [],
			...d,
		}
	}

	export function response(
		d: Partial<TextReferenceRuleViolationSearchResponse> = {},
	): TextReferenceRuleViolationSearchResponse {
		return {
			next: request(),
			items: [],
			...d,
		}
	}
}

export function search(
	req: TextReferenceRuleViolationSearchRequest,
	...options: httpx.option[]
): CancellablePromise<TextReferenceRuleViolationSearchResponse> {
	return httpx
		.get<TextReferenceRuleViolationSearchResponse>(
			`${httpx.urlstorage.host()}/bg/violations/referencerules`,
			req,
			...options,
		)
		.then((r) => ({
			...r,
			items: (r.items || []).map(zero),
		}))
}

import React from "react"
import styled from "@emotion/styled"
import { Link as RouterLink } from "react-router-dom"
import * as layouts from "layouts"

export const StyledNavList = styled.ul`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
`

export function NavList(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<layouts.containers.flex>
			<StyledNavList>{props.children}</StyledNavList>
		</layouts.containers.flex>
	)
}

export const Button = styled(layouts.buttons.unstyled)`
	font-weight: 500;
	font-size: 12px;
	color: ${layouts.theme.colors.grey.dark50};
	background: ${layouts.theme.colors.white};
	cursor: pointer;
	border-radius: 10px;
	padding-left: 10px;
	padding-right: 10px;
	width: 187px;
	&.active,
	&:hover {
		background: ${layouts.theme.colors.grey.dark50};
		color: ${layouts.theme.colors.white};
	}
`
Button.defaultProps = {
	height: "50px",
	width: "187px",
}

export function Link(props: React.PropsWithChildren<{ url: string }>): JSX.Element {
	return <RouterLink to={props.url}>{props.children}</RouterLink>
}

export function NavItem(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.containers.flex flex="1" position="relative" justifyContent="flex-start" p="10px" {...rest}>
			{children}
		</layouts.containers.flex>
	)
}

import { names } from "./names"

export function hexToRgb(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : undefined
}

export function rgbToCmyk(rgb: { r: number; g: number; b: number } | undefined) {
	if (!rgb) return [0, 0, 0, 0]

	let computedC = 0
	let computedM = 0
	let computedY = 0
	let computedK = 0

	let r = rgb.r
	let g = rgb.g
	let b = rgb.b

	if (r === 0 && g === 0 && b === 0) {
		computedK = 1
		return [0, 0, 0, 1]
	}

	computedC = 1 - r / 255
	computedM = 1 - g / 255
	computedY = 1 - b / 255

	let minCMY = Math.min(computedC, Math.min(computedM, computedY))

	computedC = (computedC - minCMY) / (1 - minCMY)
	computedM = (computedM - minCMY) / (1 - minCMY)
	computedY = (computedY - minCMY) / (1 - minCMY)
	computedK = minCMY

	return [
		parseFloat(computedC.toFixed(2)),
		parseFloat(computedM.toFixed(2)),
		parseFloat(computedY.toFixed(2)),
		parseFloat(computedK.toFixed(2)),
	]
}

function colorDistance(color1: string, color2: string) {
	const rgb1 = hexToRgb(color1)
	const rgb2 = hexToRgb(color2)

	if (!rgb1 || !rgb2) {
		return Infinity
	}

	return Math.sqrt(Math.pow(rgb1.r - rgb2.r, 2) + Math.pow(rgb1.g - rgb2.g, 2) + Math.pow(rgb1.b - rgb2.b, 2))
}

export function getClosestColorName(inputColor: string): string {
	let closestColorName = ""
	let smallestDistance = Infinity

	for (const [colorCode, colorName] of names) {
		const distance = colorDistance(inputColor, `#${colorCode}`)
		if (distance < smallestDistance) {
			smallestDistance = distance
			closestColorName = colorName
		}
	}

	return closestColorName
}

export type TextColorFromBG = "black" | "white"

export function getTextColorFromBG(backgroundHexColor: string): TextColorFromBG {
	const rgb = hexToRgb(backgroundHexColor)

	if (!rgb) {
		return "black"
	}

	const brightness = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)

	return brightness > 125 ? "black" : "white"
}

function getContrast(hex1: string, hex2: string): number {
	const rgb1 = hexToRgb(hex1)
	const rgb2 = hexToRgb(hex2)

	const brightness1 = (rgb1!.r * 299 + rgb1!.g * 587 + rgb1!.b * 114) / 1000
	const brightness2 = (rgb2!.r * 299 + rgb2!.g * 587 + rgb2!.b * 114) / 1000

	return Math.abs(brightness1 - brightness2)
}

type ColorPair = [string, string]

export function getTopContrastPairs(colors: string[], cnt: number = 5): ColorPair[] {
	const pairs: { colors: ColorPair; contrast: number }[] = []

	for (let i = 0; i < colors.length; i++) {
		for (let j = i + 1; j < colors.length; j++) {
			const contrast = getContrast(colors[i], colors[j])
			pairs.push({ colors: [colors[i], colors[j]], contrast })
		}
	}

	pairs.sort((a, b) => b.contrast - a.contrast)

	return pairs.slice(0, cnt).map((pair) => pair.colors)
}

export function getLowContrastPairs(colors: string[], cnt: number = 5): ColorPair[] {
	const pairs: { colors: ColorPair; contrast: number }[] = []

	for (let i = 0; i < colors.length; i++) {
		for (let j = i + 1; j < colors.length; j++) {
			const contrast = getContrast(colors[i], colors[j])
			pairs.push({ colors: [colors[i], colors[j]], contrast })
		}
	}

	pairs.sort((a, b) => a.contrast - b.contrast)

	return pairs.slice(0, cnt).map((pair) => pair.colors)
}

export const randomColor = () => {
	const random = names[Math.floor(Math.random() * names.length)]
	return `#${random[0]}`
}

const namedColors: Record<string, string> = {
	white: "#ffffff",
	silver: "#c0c0c0",
	gray: "#808080",
	black: "#000000",
	red: "#ff0000",
	maroon: "#800000",
	yellow: "#ffff00",
	olive: "#808000",
	lime: "#00ff00",
	green: "#008000",
}

export const rgbToHex = (rgb: string): string => {
	const match = rgb.match(/(\d+),\s*(\d+),\s*(\d+)/)
	if (!match) {
		return rgb
	}
	const hex = (channel: number): string => ("0" + channel.toString(16)).slice(-2)
	return "#" + hex(Number(match[1])) + hex(Number(match[2])) + hex(Number(match[3]))
}

export const hslToHex = (hsl: string): string => {
	const match = hsl.match(/(\d+),\s*(\d+)%,\s*(\d+)%/)
	if (!match) {
		return hsl
	}
	let [h, s, l] = match.slice(1, 4).map(Number)
	s /= 100
	l /= 100
	const hue2rgb = (p: number, q: number, t: number): number => {
		if (t < 0) t += 1
		if (t > 1) t -= 1
		if (t < 1 / 6) return p + (q - p) * 6 * t
		if (t < 1 / 2) return q
		if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
		return p
	}
	let r, g, b
	if (s === 0) {
		r = g = b = l
	} else {
		const q = l < 0.5 ? l * (1 + s) : l + s - l * s
		const p = 2 * l - q
		r = hue2rgb(p, q, h + 1 / 3)
		g = hue2rgb(p, q, h)
		b = hue2rgb(p, q, h - 1 / 3)
	}
	const hex = (channel: number): string => ("0" + Math.round(channel * 255).toString(16)).slice(-2)
	return "#" + hex(r) + hex(g) + hex(b)
}

export const nameToHex = (name: string): string => {
	return namedColors[name.toLowerCase()] || name
}

export const convertColorToHex = (color: string): string => {
	if (!color) {
		console.error(color, "- not a color")
		return "#000000"
	}
	if (color.startsWith("rgb(")) {
		return rgbToHex(color)
	} else if (color.startsWith("hsl(")) {
		return hslToHex(color)
	} else if (!color.startsWith("#")) {
		return nameToHex(color)
	}
	return color
}

export function isNearlyWhite(hexColor: string) {
	if (hexColor.charAt(0) === "#") {
		hexColor = hexColor.substring(1)
	}

	if (hexColor.length === 3) {
		hexColor = hexColor
			.split("")
			.map((c) => c + c)
			.join("")
	}

	if (!/^([0-9a-f]{6})$/i.test(hexColor)) {
		return false
	}

	const r = parseInt(hexColor.substring(0, 2), 16)
	const g = parseInt(hexColor.substring(2, 4), 16)
	const b = parseInt(hexColor.substring(4, 6), 16)

	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

	return luminance > 0.9
}

import React, { useState } from "react"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as uuid from "uuid"
import { OAuth2Client } from "@badgateway/oauth2-client"
import * as api from "./api"

interface props extends layouts.containers.FlexProps {
	redirect: string
	cred?: api.credentials.Secret
}

export default function TokenTest(props: props): JSX.Element {
	const { redirect: defaultRedirect, cred, ...rest } = props

	const [clientID, setClientID] = useState(cred?.id || "5dd5aba1-1142-4e2a-880a-30f66aeba25d")
	const [clientSecret, setClientSecret] = useState(cred?.secret || "0LJl933Hu_ztaLVekBVtWbhXL0M0voSNyVFl5EnPzs4=")
	const [redirect, setRedirect] = useState(defaultRedirect)

	return (
		<layouts.containers.flex className="oauth2.token.display" flexDirection="column" {...rest}>
			<layouts.forms.Group flex="1" gridGap="10px">
				<layouts.forms.Label width="15ch">Client ID</layouts.forms.Label>
				<inputs.Text width="60ch" defaultValue={clientID} onChange={(evt) => setClientID(evt.target.value)} />
			</layouts.forms.Group>
			<layouts.forms.Group flex="1" gridGap="10px">
				<layouts.forms.Label width="15ch">Client Secret</layouts.forms.Label>
				<inputs.Text width="60ch" defaultValue={clientSecret} onChange={(evt) => setClientSecret(evt.target.value)} />
			</layouts.forms.Group>
			<layouts.forms.Group flex="1" gridGap="10px">
				<layouts.forms.Label width="15ch">Redirect</layouts.forms.Label>
				<inputs.Text width="60ch" defaultValue={redirect} onChange={(evt) => setRedirect(evt.target.value)} />
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.buttons.primary
					onClick={() => {
						const client = new OAuth2Client({
							// The base URI of your OAuth2 server
							server: httpx.urlstorage.host(),
							// OAuth2 client id
							clientId: clientID,
							clientSecret: clientSecret,
							authorizationEndpoint: "/oauth2/authorize",
							tokenEndpoint: "/oauth2/token",
						})

						client.authorizationCode
							.getAuthorizeUri({
								// URL in the app that the user should get redirected to after authenticating
								redirectUri: redirect,
								// Optional string that can be sent along to the auth server. This value will
								// be sent along with the redirect back to the app verbatim.
								state: uuid.v4(),
								scope: ["admin"],
							})
							.then((url) => {
								window.location.href = url
							})
					}}
				>
					Authorize
				</layouts.buttons.primary>
			</layouts.forms.Group>
		</layouts.containers.flex>
	)
}

TokenTest.defaultProps = {
	redirect: `${window.location.origin}/demos/oauth2/authorization`,
}

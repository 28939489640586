import React, { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as icons from "icons"
import * as inputs from "inputs"

import { css } from "@emotion/css"

type pages = "Start" | "Lifestyle" | "Product" | "Illustration" | "Misuse" | "Fundamentals"

export const steps = ["Start", "Lifestyle", "Product", "Illustration", "Misuse", "Fundamentals"] as pages[]

export const actualSteps = (imagery: brandguide.api.GuideImagery): pages[] => {
	const actual = [] as pages[]
	steps.forEach((s) => {
		if ((s === "Lifestyle" && !imagery.lifestyle?.enabled) || false) return
		if ((s === "Product" && !imagery.product?.enabled) || false) return
		if ((s === "Illustration" && !imagery.illustration?.enabled) || false) return
		actual.push(s)
	})
	return actual
}

export const styledGap5 = css`
	.content {
		gap: 5px;
	}
`

export const styledGap60 = css`
	.content {
		gap: 60px;
	}
`

export const slyledDropWell = css`
	.styled-drop-area {
		padding: 10px;
		min-height: 150px;
	}
	.upload-icon {
		width: 50px;
		height: 50px;
		svg {
			width: 25px;
			height: 25px;
		}
	}
`

interface imageProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ImageryObject
	onDelete(): void
}

export function Image(props: imageProps): JSX.Element {
	const { item, onDelete, height, width, ...rest } = props
	const parentRest = { height, width }
	const [focused, setFocused] = useState(false)

	return (
		<layouts.Flex
			onMouseEnter={() => setFocused(true)}
			onMouseLeave={() => setFocused(false)}
			{...rest}
			{...parentRest}
		>
			<brandguide.build.layouts.Image
				contentdigest={item.thumbnail_contentdigest}
				styles={{ maxHeight: "100%", maxWidth: "100%" }}
			/>
			{focused && (
				<layouts.Flex position="absolute" {...parentRest}>
					<layouts.Flex flex="1" flexDirection="column" alignItems="end" p="5px">
						<layouts.Flex onClick={onDelete} mb="6px">
							<icons.Close
								width="20px"
								height="20px"
								stroke={layouts.theme.colors.white}
								fill={layouts.theme.colors.grey.dark50}
							/>
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
			)}
		</layouts.Flex>
	)
}

Image.defaultProps = {
	height: "150px",
	width: "150px",
	alignItems: "center",
	justifyContent: "center",
	position: "relative",
}

const styledTextArea = css`
	font-family: Roboto;
	padding: 10px;
	border: 1px solid ${layouts.theme.colors.grey.dark50alpha20};
	&:focus {
		outline: none;
	}
	font-family: Roboto;
	height: 70px !important;
	border: none;
	max-width: 170px;
`

const textOverflow = css`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
`

interface itemProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ImageryObject
	onChange(item: brandguide.api.ImageryObject): void
}

export function MisuseItem(props: itemProps): JSX.Element {
	const { item, onChange, ...rest } = props

	const [mItem, setMItem] = useState(item)
	const [focused, setFocused] = useState(false)

	if (mItem.thumbnail_contentdigest === "")
		return (
			<layouts.Flex {...rest}>
				<brandguide.build.dropwells.images.Dropwell
					className={slyledDropWell}
					onChange={(f) => {
						const newItem = {
							...mItem,
							thumbnail_contentdigest: f[0].thumbnail_contentdigest,
							contentdigest: f[0].contentdigest,
						}
						setMItem(newItem)
						onChange(newItem)
						setFocused(true)
					}}
				/>
				<layouts.Flex flexDirection="column" border="2px solid #ECF0FC" flex="1">
					<layouts.Flex flexDirection="column" justifyContent="center" alignItems="center" mt="10px">
						<layouts.Flex px="10px">
							<typography.h6
								whiteSpace="unset"
								fontSize="11px"
								fontWeight="400"
								color={layouts.theme.colors.grey.dark50alpha40}
								lineHeight="12px"
							>
								Please add why this type of photo is NOT to be used for your brand.
							</typography.h6>
						</layouts.Flex>
						<layouts.Flex alignItems="end" justifyContent="end" gridGap="3px" m="5px" marginLeft="auto">
							<layouts.Flex>
								<icons.brandguide.actions.Accept width="20px" height="20px" fill="#B2C5F4" />
							</layouts.Flex>
							<layouts.Flex>
								<icons.brandguide.actions.Reject width="20px" height="20px" fill="#B2C5F4" />
							</layouts.Flex>
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		)
	return (
		<layouts.Flex
			border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
			flexDirection="column"
			minWidth="170px"
			maxWidth="170px"
		>
			<layouts.Flex
				flex="1"
				justifyContent="center"
				alignItems="center"
				minHeight="170px"
				border={`1px solid ${layouts.theme.colors.grey.dark50alpha10}`}
			>
				<brandguide.build.layouts.Image
					contentdigest={mItem.thumbnail_contentdigest}
					styles={{ maxHeight: "170px", maxWidth: "170px" }}
				/>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" flex="1" justifyContent="start" alignItems="start">
				<layouts.Flex flexDirection="column" justifyContent="start" alignItems="start">
					<layouts.Flex>
						{focused ? (
							<inputs.TextArea
								autoFocus={true}
								className={styledTextArea}
								value={mItem.description}
								placeholder="Please add why this type of photo is NOT to be used for your brand."
								onChange={(e) => setMItem({ ...mItem, description: e.target.value })}
							/>
						) : (
							<layouts.Flex p="10px" onClick={() => setFocused(true)} className={textOverflow}>
								<typography.h6
									whiteSpace="unset"
									fontSize="11px"
									fontWeight="400"
									color={layouts.theme.colors.grey.dark50}
									lineHeight="12px"
								>
									{mItem.description || "Please add why this type of photo is NOT to be used for your brand."}
								</typography.h6>
							</layouts.Flex>
						)}
					</layouts.Flex>
					{focused && (
						<layouts.Flex alignItems="end" justifyContent="end" gridGap="3px" m="5px" marginLeft="auto">
							<layouts.Flex
								onClick={() => {
									onChange(mItem)
									setFocused(false)
								}}
							>
								<icons.brandguide.actions.Accept
									width="20px"
									height="20px"
									fill={mItem.description !== "" ? layouts.theme.colors.blue.blue : "#B2C5F4"}
								/>
							</layouts.Flex>
							<layouts.Flex
								onClick={() => {
									setFocused(false)
									setMItem({ ...mItem, description: item.description })
								}}
							>
								<icons.brandguide.actions.Reject width="20px" height="20px" fill={layouts.theme.colors.blue.blue} />
							</layouts.Flex>
						</layouts.Flex>
					)}
				</layouts.Flex>
			</layouts.Flex>
			{!focused && (
				<layouts.Flex m="5px" marginLeft="auto">
					<layouts.Flex
						onClick={() => {
							const upd = brandguide.api.zeros.imagery.objectZero({ id: item.id })
							setMItem(upd)
							onChange(upd)
						}}
					>
						<icons.brandguide.actions.Reject width="20px" height="20px" fill={layouts.theme.colors.blue.blue} />
					</layouts.Flex>
				</layouts.Flex>
			)}
		</layouts.Flex>
	)
}

MisuseItem.defaultProps = {
	flexDirection: "column",
	width: "170px",
}

const textBaseProps = {
	fontWeight: "400",
	letterSpacing: "unset",
	lineHeight: "normal",
	whiteSpace: "unset",
}

interface addItemProps extends layouts.containers.ContainerProps {
	onChange(): void
}

export function AddMisuseItem(props: addItemProps): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flexDirection="column" flex="1" justifyContent="flex-end" alignItems="center">
				<layouts.Flex onClick={onChange}>
					<typography.h4 mb="10px" fontSize="20px" color={layouts.theme.colors.grey.dark50} {...textBaseProps}>
						+
					</typography.h4>
				</layouts.Flex>
				<typography.h5
					mb="10px"
					fontSize="12px"
					color={layouts.theme.colors.grey.dark50}
					{...textBaseProps}
					lineHeight="20px"
				>
					Add New
				</typography.h5>
			</layouts.Flex>
		</layouts.Flex>
	)
}

AddMisuseItem.defaultProps = {
	flexDirection: "column",
	width: "170px",
	height: "250px",
	border: "2px solid #ECF0FC",
}

interface fundamentalSectionProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ImageryFundamentalObject
	onChange(item: brandguide.api.ImageryFundamentalObject): void
}

export function FundamentalSectionItem(props: fundamentalSectionProps): JSX.Element {
	const { item, onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex alignItems="center" justifyContent="center" mb="10px">
				<typography.h5 fontSize="16px" fontWeight="600" lineHeight="normal">
					{item.name}
				</typography.h5>
			</layouts.Flex>
			<layouts.containers.grid gridTemplateRows="repeat(5, auto)" gridAutoFlow="column" gap="5px">
				{(item.items || []).map((fsi) => (
					<FundamentalItem
						key={fsi.name}
						item={fsi}
						onChange={(upd) => {
							onChange({ ...item, items: item.items.map((i) => (i.name === upd.name ? upd : i)) })
						}}
						onDelete={() => onChange({ ...item, items: item.items.filter((i) => i.name !== fsi.name) })}
					/>
				))}
			</layouts.containers.grid>
			<AddFundamentalItem
				items={item.items}
				onChange={(upd) => {
					onChange({ ...item, items: [...item.items, ...[upd]] })
				}}
			/>
		</layouts.Flex>
	)
}

FundamentalSectionItem.defaultProps = {
	flexDirection: "column",
}

interface fundamentalItemProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ImageryFundamentalItem
	onChange(item: brandguide.api.ImageryFundamentalItem): void
	onDelete(): void
}

export function FundamentalItem(props: fundamentalItemProps): JSX.Element {
	const { item, onChange, onDelete, ...rest } = props

	return (
		<layouts.Flex background={item.checked ? layouts.theme.colors.blue.blue : layouts.theme.colors.white} {...rest}>
			<layouts.Flex flex="1" alignItems="center">
				<layouts.Flex
					flex="9"
					justifyContent="center"
					alignItems="center"
					onClick={() => {
						if (!item.is_default) return
						onChange({ ...item, checked: !item.checked })
					}}
				>
					<typography.h5
						whiteSpace="unset"
						fontSize="14px"
						fontWeight="400"
						lineHeight="normal"
						color={item.checked ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50alpha50}
					>
						{item.name}
					</typography.h5>
				</layouts.Flex>
				{!item.is_default && (
					<layouts.Flex flex="1" onClick={onDelete} m="2px">
						<icons.Close
							width="10px"
							height="10px"
							fill={item.checked ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50alpha50}
						/>
					</layouts.Flex>
				)}
			</layouts.Flex>
		</layouts.Flex>
	)
}

FundamentalItem.defaultProps = {
	width: "151px",
	height: "37px",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "5px",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha30}`,
	boxShadow: `0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`,
	gap: "10px",
	mt: "5px",
}

const styledInput = css`
	input {
		padding-left: 2px;
		padding-right: 2px;
		max-width: 90px;
		:hover,
		:focus {
			outline: none;
			box-shadow: none;
		}
	}
`

interface addFundamentalItemProps extends layouts.containers.ContainerProps {
	items: brandguide.api.ImageryFundamentalItem[]
	onChange(item: brandguide.api.ImageryFundamentalItem): void
}

export function AddFundamentalItem(props: addFundamentalItemProps): JSX.Element {
	const { items, onChange, ...rest } = props
	const zero = brandguide.api.zeros.imagery.fundamentalItemZero({ checked: true })
	const [item, setItem] = useState(zero)
	const [focused, setFocused] = useState(false)

	const disabled =
		item.name.length === 0 || items.map((i) => i.name.toLocaleLowerCase()).includes(item.name.toLocaleLowerCase())

	return (
		<layouts.Flex border={`1px ${focused ? "solid" : "dashed"} ${layouts.theme.colors.grey.dark50alpha30}`} {...rest}>
			{focused ? (
				<layouts.Flex>
					<layouts.Flex className={styledInput}>
						<inputs.Text
							type="text"
							defaultValue={item.name}
							placeholder="add text here"
							onChange={(e) => setItem({ ...item, name: e.target.value })}
						/>
					</layouts.Flex>
					<layouts.Flex
						onClick={() => {
							if (disabled) return
							onChange(item)
							// reset form
							setItem(zero)
							setFocused(false)
						}}
					>
						<icons.brandguide.actions.Accept
							fill={disabled ? layouts.theme.colors.grey.dark50 : layouts.theme.colors.blue.blue}
						/>
					</layouts.Flex>
					<layouts.Flex onClick={() => setFocused(false)}>
						<icons.brandguide.actions.Reject fill={layouts.theme.colors.blue.blue} />
					</layouts.Flex>
				</layouts.Flex>
			) : (
				<layouts.Flex onClick={() => setFocused(true)}>
					<typography.h5
						whiteSpace="unset"
						fontSize="14px"
						fontWeight="400"
						lineHeight="normal"
						color={layouts.theme.colors.grey.dark50alpha50}
						px="5px"
					>
						+ Add New
					</typography.h5>
				</layouts.Flex>
			)}
		</layouts.Flex>
	)
}

AddFundamentalItem.defaultProps = {
	width: "151px",
	height: "37px",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "5px",
	boxShadow: `0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`,
	gap: "10px",
	mt: "10px",
}

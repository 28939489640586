import React from "react"
import classnames from "classnames"
import * as errors from "errors"
import * as containers from "../containers"
import * as overlay from "./overlay"
import * as layouts from "layouts"

export function pending(props: React.PropsWithChildren<overlay.Props & layouts.containers.FlexProps>): JSX.Element {
	const { className, loading, children, icon, ...rest } = props

	if (loading) {
		return (
			<containers.flex
				flex="1"
				width="100%"
				height="100%"
				{...rest}
				className={classnames("loading pending", className)}
			>
				{icon}
			</containers.flex>
		)
	}

	return <errors.Boundary>{children}</errors.Boundary>
}

pending.defaultProps = {
	...overlay.defaults,
}

import { useState, useEffect } from "react"
import * as brandguide from "brandguide"
import * as res_components from "brandguide/results/components"

interface props {
	itemsOnPage: number
	onLoad(): void
}

export default function Display(props: props): JSX.Element {
	const { itemsOnPage, onLoad } = props

	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = brandguide.api.zeros.guide.guideImageryZero(guide.guide?.imagery)

	const sectionDescription =
		"This section is dedicated to showcasing the illustrations that represent our brand's unique visual style. Illustrations are a powerful way to communicate complex ideas in a simple, engaging, and visually appealing manner. They play a crucial role in our brand's visual identity, helping to convey our brand's personality, values, and messages."
	const items = res_components.chunkImageItems(data.illustration?.items || [], itemsOnPage)
	const [loadedImages, setLoadedImages] = useState(items.length)

	useEffect(() => {
		if (loadedImages !== 0) return
		onLoad()
	}, [loadedImages])

	if (!brandguide.validations.imagery.valid.illustration(data)) return <></>

	return (
		<>
			{items.map((i, index) => {
				const firstPage = index === 0
				const pageTitle = firstPage ? "Illustration Style" : undefined
				const sectionDesc = firstPage ? sectionDescription : undefined
				const explanation = firstPage ? data.descriptions?.illustration : undefined
				return (
					<res_components.ImagesPage
						key={index}
						items={i}
						sectionTitle="Illustrations"
						sectionDescription={sectionDesc}
						pageTitle={pageTitle}
						explanation={explanation}
						onLoad={() => setLoadedImages((prev) => prev - 1)}
						className="section-print"
					/>
				)
			})}
		</>
	)
}

Display.defaultProps = {
	itemsOnPage: 9,
}

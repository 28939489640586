import * as theme from "layouts/theme"
import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { fill, ...rest } = props
	return (
		<svg.SVG xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" {...rest}>
			<path
				d="M8.0526 1.5825C7.83635 1.17375 7.16385 1.17375 6.9476 1.5825L1.3226 12.2075C1.27196 12.3027 1.24686 12.4095 1.24973 12.5173C1.25261 12.6251 1.28337 12.7303 1.339 12.8227C1.39464 12.9151 1.47327 12.9915 1.56722 13.0445C1.66117 13.0975 1.76724 13.1252 1.8751 13.125H13.1251C13.2329 13.1252 13.3389 13.0975 13.4328 13.0446C13.5267 12.9916 13.6052 12.9153 13.6608 12.8229C13.7164 12.7306 13.7471 12.6254 13.7499 12.5177C13.7528 12.4099 13.7276 12.3033 13.677 12.2081L8.0526 1.5825ZM8.1251 11.25H6.8751V10H8.1251V11.25ZM6.8751 8.75V5.625H8.1251L8.12572 8.75H6.8751Z"
				fill={fill}
			/>
		</svg.SVG>
	)
}

Icon.defaultProps = {
	fill: theme.colors.red.cinnabar,
}

export default Icon

import * as brandguide from "brandguide"

const checker = (arr: boolean[]) => arr.every(Boolean)

export type pages =
	| "colors"
	| "logos"
	| "typography"
	| "imagery"
	| "tone_of_voice"
	| "archetype"
	| "application"
	| "compliance"

export type state = "active" | "pending" | "completed"

export type section =
	| brandguide.api.GuideColors
	| brandguide.api.GuideLogos
	| brandguide.api.GuideTypography
	| brandguide.api.GuideImagery
	| brandguide.api.GuideToneOfVoice
	| brandguide.api.GuideArchetype
	| brandguide.api.GuideApplication
	| brandguide.api.GuideCompliance

export namespace colors {
	export namespace valid {
		export const primary = (data: brandguide.api.GuideColors): boolean =>
			data.primary_colors.filter((c) => !c.metadata?.default).length > 0

		export const secondary = (data: brandguide.api.GuideColors): boolean => true //step can be skipped, it is always valid

		export const correct = (data: brandguide.api.GuideColors): boolean => data.correct_color_contrasts.length > 0

		export const incorrect = (data: brandguide.api.GuideColors): boolean => data.incorrect_color_contrasts.length > 0

		export const completed = (data: brandguide.api.GuideColors): boolean =>
			checker([primary(data), secondary(data), correct(data), incorrect(data)])
	}
}

export namespace logos {
	export namespace valid {
		export const uploads = (data: brandguide.api.GuideLogos): boolean => data.primary?.present || false

		export const clearspace = (data: brandguide.api.GuideLogos): boolean => true //step can be skipped, it is always valid

		export const proportions = (data: brandguide.api.GuideLogos): boolean => true //step can be skipped, it is always valid

		export const placement = (data: brandguide.api.GuideLogos): boolean => true //step can be skipped, it is always valid

		export const misuse = (data: brandguide.api.GuideLogos): boolean => (data.misuse || []).length > 0

		export const usage = (data: brandguide.api.GuideLogos): boolean => {
			const use = data.usage.filter((u) => !u.use) || []
			const dontuse = data.usage.filter((u) => u.use) || []
			if (use?.length > 0 && dontuse?.length > 0) return true
			return false
		}

		export const completed = (data: brandguide.api.GuideLogos): boolean =>
			checker([uploads(data), clearspace(data), proportions(data), placement(data), misuse(data), usage(data)])
	}
}

export namespace typography {
	export namespace valid {
		export const uploads = (data: brandguide.api.GuideTypography): boolean => {
			const primary = (data.fonts || []).find((i) => i.id === "primary")
			return !!primary && primary.font !== ""
		}

		export const usage = (data: brandguide.api.GuideTypography): boolean => (data.items || []).length > 0

		export const completed = (data: brandguide.api.GuideTypography): boolean => checker([uploads(data), usage(data)])
	}
}

export namespace imagery {
	export namespace enabled {
		export const lifestyle = (data: brandguide.api.GuideImagery): boolean => !!data.lifestyle?.enabled

		export const product = (data: brandguide.api.GuideImagery): boolean => !!data.product?.enabled

		export const illustration = (data: brandguide.api.GuideImagery): boolean => !!data.illustration?.enabled

		export const sections = (data: brandguide.api.GuideImagery): boolean =>
			[imagery.enabled.lifestyle(data), imagery.enabled.product(data), imagery.enabled.illustration(data)].includes(
				true,
			)
	}
	export namespace valid {
		export const start = (data: brandguide.api.GuideImagery): boolean => imagery.enabled.sections(data)

		export const lifestyle = (data: brandguide.api.GuideImagery): boolean =>
			!!data.lifestyle?.enabled && (data.lifestyle?.items || []).length > 0

		export const product = (data: brandguide.api.GuideImagery): boolean =>
			!!data.product?.enabled && (data.product?.items || []).length > 0

		export const illustration = (data: brandguide.api.GuideImagery): boolean =>
			!!data.illustration?.enabled && (data.illustration?.items || []).length > 0

		export const misuse = (data: brandguide.api.GuideImagery): boolean => true //step can be skipped, it is always valid

		export const fundamentals = (data: brandguide.api.GuideImagery): boolean => {
			if ((data.fundamentals || []).length === 0) return false
			const to_check = [] as boolean[]
			;(data.fundamentals || []).forEach((object) =>
				(object.items || []).forEach((item) => to_check.push(item.checked || false)),
			)
			return to_check.includes(true)
		}

		export const completed = (data: brandguide.api.GuideImagery): boolean => {
			if (!imagery.enabled.sections(data)) return false
			const to_check = [] as boolean[]
			if (imagery.enabled.lifestyle(data)) to_check.push(imagery.valid.lifestyle(data))
			if (imagery.enabled.product(data)) to_check.push(imagery.valid.product(data))
			if (imagery.enabled.illustration(data)) to_check.push(imagery.valid.illustration(data))
			to_check.push(imagery.valid.misuse(data))
			to_check.push(imagery.valid.fundamentals(data))
			return checker(to_check)
		}
	}
}

export namespace tone_of_voice {
	export namespace valid {
		export const tone_of_voice = (data: brandguide.api.GuideToneOfVoice): boolean =>
			(data.tone_of_voice || []).filter((i) => i.selected).length > 0
		export const adjectives = (data: brandguide.api.GuideToneOfVoice): boolean =>
			data.positive_adjectives.length >= 2 && data.negative_adjectives.length >= 2
		export const writing_style = (data: brandguide.api.GuideToneOfVoice): boolean => true //step can be skipped, it is always valid
		export const completed = (data: brandguide.api.GuideToneOfVoice): boolean =>
			checker([tone_of_voice(data), adjectives(data), writing_style(data)])
	}
}

export namespace archetype {
	export namespace valid {
		export const selection = (data: brandguide.api.GuideArchetype): boolean => {
			if ((data.adjectives || []).length !== 2) return false
			const items = [] as string[]
			data.adjectives.forEach((a) => a.items.forEach((i) => items.push(i)))
			return items.length === 6
		}
		export const breakdown = (data: brandguide.api.GuideArchetype): boolean => true
		export const completed = (data: brandguide.api.GuideArchetype): boolean =>
			checker([selection(data), breakdown(data)])
	}
}

export namespace application {
	export namespace valid {
		export const upload = (data: brandguide.api.GuideApplication): boolean => (data.items || []).length > 0

		export const completed = (data: brandguide.api.GuideApplication): boolean => checker([upload(data)])
	}
}

export namespace compliance {
	export namespace valid {
		export const selection = (data: brandguide.api.GuideCompliance): boolean =>
			data.items.some((item) => item.names.length > 0)
		export const completed = (data: brandguide.api.GuideCompliance): boolean => checker([selection(data)])
	}
}

export namespace guide {
	export const sections = (guide: brandguide.api.Guide) => [
		guide.colors,
		guide.logos,
		guide.typography,
		guide.imagery,
		guide.tone_of_voice,
		guide.archetype,
		guide.compliance,
		guide.application,
	]

	export const sections_enabled = (guide: brandguide.api.Brandguide) =>
		(sections(guide.guide!) || []).map((s) => s?.enabled)

	export const sections_active = (guide: brandguide.api.Brandguide) =>
		(sections(guide.guide!) || []).filter((s) => s?.enabled)

	export const started = (guide: brandguide.api.Brandguide): boolean => sections_enabled(guide).includes(true)

	export const pages_state = (guide: brandguide.api.Brandguide) => {
		return {
			colorsState: brandguide.api.zeros.guide.guideColorsZero(guide.guide?.colors),
			logosState: brandguide.api.zeros.guide.guideLogosZero(guide.guide?.logos),
			typographyState: brandguide.api.zeros.guide.guideTypographyZero(guide.guide?.typography),
			imageryState: brandguide.api.zeros.guide.guideImageryZero(guide.guide?.imagery),
			toneAndVoiceState: brandguide.api.zeros.guide.guideToneOfVoiceZero(guide.guide?.tone_of_voice),
			archetypeState: brandguide.api.zeros.guide.guideArchetypeZero(guide.guide?.archetype),
			applicationState: brandguide.api.zeros.guide.guideApplicationZero(guide.guide?.application),
			complianceState: brandguide.api.zeros.guide.guideComplianceZero(guide.guide?.compliance),
		}
	}

	export const pending_pages = (guide: brandguide.api.Brandguide): boolean[] => {
		const pending = []
		if (guide.guide?.colors?.enabled) pending.push(colors.valid.completed(pages_state(guide).colorsState))
		if (guide.guide?.logos?.enabled) pending.push(logos.valid.completed(pages_state(guide).logosState))
		if (guide.guide?.typography?.enabled) pending.push(typography.valid.completed(pages_state(guide).typographyState))
		if (guide.guide?.imagery?.enabled) pending.push(imagery.valid.completed(pages_state(guide).imageryState))
		if (guide.guide?.tone_of_voice?.enabled)
			pending.push(tone_of_voice.valid.completed(pages_state(guide).toneAndVoiceState))
		if (guide.guide?.archetype?.enabled) pending.push(archetype.valid.completed(pages_state(guide).archetypeState))
		if (guide.guide?.application?.enabled)
			pending.push(application.valid.completed(pages_state(guide).applicationState))
		if (guide.guide?.compliance?.enabled) pending.push(compliance.valid.completed(pages_state(guide).complianceState))
		return pending
	}

	export const colors_state = (activPage: pages, guide: brandguide.api.Brandguide): state => {
		if (activPage === "colors" && !colors.valid.completed(pages_state(guide).colorsState)) return "active"
		if (colors.valid.completed(pages_state(guide).colorsState)) return "completed"
		return "pending"
	}

	export const logos_state = (activPage: pages, guide: brandguide.api.Brandguide): state => {
		if (activPage === "logos" && !logos.valid.completed(pages_state(guide).logosState)) return "active"
		if (logos.valid.completed(pages_state(guide).logosState)) return "completed"
		return "pending"
	}

	export const typography_state = (activPage: pages, guide: brandguide.api.Brandguide): state => {
		if (activPage === "typography" && !typography.valid.completed(pages_state(guide).typographyState)) return "active"
		if (typography.valid.completed(pages_state(guide).typographyState)) return "completed"
		return "pending"
	}

	export const imagery_state = (activPage: pages, guide: brandguide.api.Brandguide): state => {
		if (activPage === "imagery" && !imagery.valid.completed(pages_state(guide).imageryState)) return "active"
		if (imagery.valid.completed(pages_state(guide).imageryState)) return "completed"
		return "pending"
	}

	export const tone_and_voice_state = (activPage: pages, guide: brandguide.api.Brandguide): state => {
		if (activPage === "tone_of_voice" && !tone_of_voice.valid.completed(pages_state(guide).toneAndVoiceState))
			return "active"
		if (tone_of_voice.valid.completed(pages_state(guide).toneAndVoiceState)) return "completed"
		return "pending"
	}

	export const archetype_state = (activPage: pages, guide: brandguide.api.Brandguide): state => {
		if (activPage === "archetype" && !archetype.valid.completed(pages_state(guide).archetypeState)) return "active"
		if (archetype.valid.completed(pages_state(guide).archetypeState)) return "completed"
		return "pending"
	}

	export const application_state = (activPage: pages, guide: brandguide.api.Brandguide): state => {
		if (activPage === "application" && !application.valid.completed(pages_state(guide).applicationState))
			return "active"
		if (application.valid.completed(pages_state(guide).applicationState)) return "completed"
		return "pending"
	}

	export const compliance_state = (activPage: pages, guide: brandguide.api.Brandguide): state => {
		if (activPage === "compliance" && !compliance.valid.completed(pages_state(guide).complianceState)) return "active"
		if (compliance.valid.completed(pages_state(guide).complianceState)) return "completed"
		return "pending"
	}
}

import { SVG, SVGProps } from "./svg"
import * as theme from "layouts/theme"

const Icon = (props: SVGProps) => {
	const { fill, className, ...rest } = props

	return (
		<SVG viewBox="0 0 25 35" className={className} xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M12.78,10.62h-3.72V.38H.82V12.73h.02v.05H6.9v3.92H.96l-.13,.93c-.01,.09-.3,2.26-.25,4.22,.06,2.06,.65,3.88,.68,3.96h0c1.43,5.1,6.16,8.73,11.52,8.73,6.59,0,11.96-5.37,11.96-11.96s-5.37-11.96-11.96-11.96Zm-9.8,0V2.54h3.92V10.62H2.98Zm9.8,21.76c-4.58,0-8.61-3.24-9.58-7.72h-.02c-.16-.6-.4-1.7-.44-2.88-.03-1.03,.05-2.16,.12-2.93H6.9v3.92h0c.11,3.15,2.7,5.68,5.88,5.68s5.88-2.64,5.88-5.88-2.64-5.88-5.88-5.88h-3.72v-3.92h3.72c5.4,0,9.8,4.4,9.8,9.8s-4.4,9.8-9.8,9.8Zm-3.72-13.52h3.72c2.05,0,3.72,1.67,3.72,3.72s-1.67,3.72-3.72,3.72-3.72-1.67-3.72-3.72v-3.72Z"
				fill={fill}
			/>
		</SVG>
	)
}

Icon.defaultProps = {
	fill: theme.colors.blue.dark20,
}

export const MainLogo = (props: SVGProps) => {
	const { fill, ...rest } = props

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="271.3" height="52.8" viewBox="0 0 271.3 52.8" {...rest}>
			<g>
				<path
					fill={fill}
					d="M48.9,44.1V15.4h5.5v28.7h-5.5Zm4.1,0v-4.5h7.5c1.3,0,2.4-.4,3.1-1.2s1.1-1.8,1.1-2.9c0-.8-.2-1.5-.5-2.1-.3-.6-.8-1.1-1.4-1.5-.6-.4-1.4-.6-2.3-.6h-7.5v-4.5h6.9c1.1,0,2.1-.3,2.8-.9s1-1.4,1-2.6-.3-2-1-2.6c-.8-.4-1.7-.7-2.9-.7h-6.8v-4.5h7.5c1.9,0,3.5,.3,4.8,1,1.3,.7,2.3,1.6,2.9,2.7,.7,1.1,1,2.4,1,3.7,0,1.6-.5,3-1.5,4.2s-2.4,2.1-4.3,2.6l.2-1.8c2.1,.6,3.8,1.6,4.9,3,1.2,1.4,1.7,3,1.7,5,0,1.5-.4,2.9-1.1,4.1-.7,1.2-1.8,2.2-3.3,3-1.4,.7-3.2,1.1-5.2,1.1,0,0-7.6,0-7.6,0Z"
				/>
				<path
					fill={fill}
					d="M73.1,44.1V24.4h5.3v19.7h-5.3Zm5.3-10.9l-2.1-1.4c.2-2.4,1-4.3,2.1-5.7,1.2-1.4,2.9-2.1,5.1-2.1,1,0,1.8,.2,2.6,.5,.8,.3,1.5,.8,2.1,1.5l-3.3,3.8c-.3-.3-.7-.6-1.1-.7-.4-.2-.9-.2-1.4-.2-1.1,0-2.1,.4-2.8,1.1-.8,.7-1.2,1.8-1.2,3.2Z"
				/>
				<path
					fill={fill}
					d="M96.7,44.6c-1.8,0-3.4-.4-4.9-1.3-1.4-.9-2.6-2.1-3.4-3.7-.8-1.6-1.2-3.3-1.2-5.2,0-2,.4-3.7,1.2-5.3,.8-1.6,1.9-2.8,3.4-3.7,1.4-.9,3.1-1.3,4.9-1.3,1.4,0,2.7,.3,3.8,.9,1.1,.6,2,1.4,2.7,2.4s1,2.2,1.1,3.5v7.1c-.1,1.3-.4,2.5-1.1,3.5s-1.6,1.8-2.7,2.4c-1.1,.4-2.3,.7-3.8,.7Zm1-5c1.5,0,2.7-.5,3.6-1.5s1.4-2.3,1.4-3.9c0-1-.2-2-.6-2.8s-1-1.4-1.8-1.9c-.7-.4-1.6-.7-2.6-.7s-1.8,.2-2.6,.7c-.7,.4-1.3,1.1-1.8,1.9-.4,.8-.6,1.7-.6,2.8s.2,2,.6,2.8,1,1.4,1.8,1.9,1.6,.7,2.6,.7Zm4.8,4.5v-5.3l.9-4.8-.9-4.7v-4.9h5.3v19.7h-5.3Z"
				/>
				<path
					fill={fill}
					d="M111.5,44.1V24.4h5.3v19.7h-5.3Zm13.2,0v-11.3c0-1.2-.4-2.1-1.1-2.9-.7-.7-1.7-1.1-2.9-1.1-.8,0-1.4,.2-2,.5s-1.1,.8-1.4,1.4-.5,1.3-.5,2.1l-2.1-1.1c0-1.6,.3-2.9,1-4.1s1.6-2.1,2.8-2.7c1.2-.7,2.5-1,4-1,1.4,0,2.7,.4,3.9,1.1,1.1,.7,2,1.7,2.7,2.8,.7,1.2,1,2.4,1,3.7v12.5h-5.4s0,.1,0,.1Z"
				/>
				<path
					fill={fill}
					d="M141.7,44.6c-1.8,0-3.5-.4-4.9-1.3-1.4-.9-2.6-2.1-3.4-3.7-.8-1.6-1.2-3.3-1.2-5.2,0-2,.4-3.7,1.2-5.3,.8-1.6,2-2.8,3.4-3.7,1.4-.9,3.1-1.3,4.9-1.3,1.4,0,2.7,.3,3.8,.9s2,1.4,2.7,2.4,1.1,2.2,1.1,3.5v7c-.1,1.3-.4,2.4-1.1,3.5-.7,1-1.6,1.9-2.7,2.4-1.1,.5-2.4,.8-3.8,.8Zm.9-5c1,0,1.9-.2,2.6-.7,.7-.4,1.3-1.1,1.8-1.9,.4-.8,.6-1.7,.6-2.8,0-1-.2-2-.6-2.8-.4-.8-1-1.4-1.8-1.9-.7-.4-1.6-.7-2.6-.7s-1.9,.2-2.6,.7c-.7,.5-1.3,1.1-1.8,1.9-.4,.8-.6,1.7-.6,2.7,0,1.1,.2,2,.6,2.8,.4,.8,1,1.4,1.8,1.9,.8,.6,1.7,.8,2.6,.8Zm10.1,4.5h-5.3v-5.3l.9-4.8-.9-4.7V14.6h5.3v29.5Z"
				/>
				<path
					fill={fill}
					d="M170.2,44.6c-2,0-4-.4-5.7-1.1-1.8-.7-3.3-1.8-4.7-3.2-1.3-1.4-2.4-2.9-3.1-4.7-.7-1.8-1.1-3.7-1.1-5.8s.4-4,1.1-5.8c.8-1.8,1.8-3.3,3.2-4.7,1.4-1.3,3-2.4,4.8-3.1,1.8-.7,3.8-1.1,5.9-1.1,2.4,0,4.5,.4,6.5,1.3s3.6,2.1,4.9,3.7l-3.8,3.8c-.9-1.2-2-2.1-3.3-2.7s-2.8-.9-4.4-.9c-1.8,0-3.4,.4-4.8,1.2-1.4,.8-2.5,1.9-3.3,3.3s-1.2,3.1-1.2,5,.4,3.6,1.2,5c.8,1.5,1.8,2.6,3.2,3.4,1.3,.8,2.9,1.2,4.6,1.2,1.8,0,3.3-.3,4.6-1s2.3-1.6,2.9-2.9c.7-1.3,1-2.9,1-4.8l3.6,2.5-12.5-.1v-4.8h14.6v.7c0,3.4-.6,6.3-1.8,8.6s-2.9,4-5,5.2-4.6,1.8-7.4,1.8Z"
				/>
				<path
					fill={fill}
					d="M195.6,44.6c-1.7,0-3.3-.4-4.6-1.1-1.3-.7-2.4-1.8-3.2-3.1s-1.1-2.8-1.1-4.5v-11.5h5.4v11.3c0,.8,.1,1.5,.4,2.1,.3,.6,.7,1,1.2,1.3s1.2,.4,1.9,.4c1.1,0,2-.3,2.6-1s.9-1.6,.9-2.9v-11.2h5.3v11.4c0,1.7-.4,3.3-1.1,4.6-.7,1.3-1.8,2.3-3.1,3-1.4,.8-2.9,1.2-4.6,1.2h0Z"
				/>
				<path
					fill={fill}
					d="M215.9,44.6c-1.8,0-3.4-.4-4.9-1.3-1.4-.9-2.6-2.1-3.4-3.7-.8-1.6-1.2-3.3-1.2-5.2,0-2,.4-3.7,1.2-5.3,.8-1.6,1.9-2.8,3.4-3.7,1.4-.9,3.1-1.3,4.9-1.3,1.4,0,2.7,.3,3.8,.9s2,1.4,2.7,2.4,1,2.2,1.1,3.5v7.1c-.1,1.3-.4,2.5-1.1,3.5-.7,1-1.6,1.8-2.7,2.4-1.1,.4-2.4,.7-3.8,.7Zm1-5c1.5,0,2.7-.5,3.6-1.5,.9-1,1.4-2.3,1.4-3.9,0-1-.2-2-.6-2.8-.4-.8-1-1.4-1.8-1.9-.7-.4-1.6-.7-2.6-.7s-1.8,.2-2.6,.7c-.7,.4-1.3,1.1-1.8,1.9-.4,.8-.6,1.7-.6,2.8s.2,2,.6,2.8c.4,.8,1,1.4,1.8,1.9s1.6,.7,2.6,.7h0Zm4.7,4.5v-5.3l.9-4.8-.9-4.7v-4.9h5.3v19.7h-5.3Z"
				/>
				<path
					fill={fill}
					d="M230.7,44.1V24.4h5.3v19.7h-5.3Zm5.3-10.9l-2.1-1.4c.2-2.4,1-4.3,2.1-5.7,1.2-1.4,2.9-2.1,5.1-2.1,1,0,1.8,.2,2.6,.5s1.5,.8,2.1,1.5l-3.3,3.8c-.3-.3-.7-.6-1.1-.7-.4-.2-.9-.2-1.4-.2-1.1,0-2.1,.4-2.8,1.1-.8,.7-1.2,1.8-1.2,3.2h0Z"
				/>
				<path
					fill={fill}
					d="M254.4,44.6c-1.8,0-3.5-.4-4.9-1.3-1.4-.9-2.6-2.1-3.4-3.7-.8-1.6-1.2-3.3-1.2-5.2,0-2,.4-3.7,1.2-5.3,.8-1.6,2-2.8,3.4-3.7,1.4-.9,3.1-1.3,4.9-1.3,1.4,0,2.7,.3,3.8,.9,1.1,.6,2,1.4,2.7,2.4s1.1,2.2,1.1,3.5v7c-.1,1.3-.4,2.4-1.1,3.5-.7,1-1.6,1.9-2.7,2.4s-2.4,.8-3.8,.8h0Zm.9-5c1,0,1.9-.2,2.6-.7,.7-.4,1.3-1.1,1.8-1.9,.4-.8,.6-1.7,.6-2.8,0-1-.2-2-.6-2.8s-1-1.4-1.8-1.9c-.7-.4-1.6-.7-2.6-.7s-1.9,.2-2.6,.7c-.7,.5-1.3,1.1-1.8,1.9-.4,.8-.6,1.7-.6,2.7,0,1.1,.2,2,.6,2.8,.4,.8,1,1.4,1.8,1.9,.8,.6,1.7,.8,2.6,.8Zm10.1,4.5h-5.3v-5.3l.9-4.8-.9-4.7V14.6h5.3v29.5Z"
				/>
			</g>
			<path
				fill={fill}
				d="M20.7,18.7h-4.5V6.2H6.2v15h0v.1h7.4v4.8H6.3l-.2,1.1c0,.1-.4,2.8-.3,5.1,.1,2.5,.8,4.7,.8,4.8h0c1.7,6.2,7.5,10.6,14,10.6,8,0,14.6-6.5,14.6-14.6,.1-7.9-6.5-14.4-14.5-14.4Zm-12,0V8.8h4.8v9.8h-4.8v.1Zm12,26.5c-5.6,0-10.5-4-11.7-9.4h0c-.2-.7-.5-2.1-.5-3.5,0-1.3,.1-2.6,.2-3.6h4.9v4.8h0c.1,3.8,3.3,6.9,7.2,6.9s7.2-3.2,7.2-7.2-3.2-7.2-7.2-7.2h-4.5v-4.8h4.5c6.6,0,11.9,5.4,11.9,11.9-.1,6.7-5.4,12.1-12,12.1Zm-4.6-16.5h4.5c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5-4.5-2-4.5-4.5v-4.5Z"
			/>
		</svg>
	)
}

MainLogo.defaultProps = {
	fill: theme.colors.blue.dark10,
}

export default Icon

import React from "react"
import { Route, Routes, Navigate, useNavigate, useParams } from "react-router-dom"
import { CancellablePromise } from "real-cancellable-promise"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as authzc from "authzcached"
import * as brandguard from "brandguard"
import * as training from "brandguard/display/training"
import * as api from "./api"
import * as caching from "./cache"
import * as routing from "./routing"
import * as edit from "./edit"
import { default as Grid } from "./grid"
import * as brandgpt from "brandgpt"
import * as brandguide from "brandguide"
import { css } from "@emotion/css"
import * as metrics from "metrics"
import * as meta from "meta/authz"
import * as notifications from "brandguard/notifications"

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<layouts.containers.flex className="brands" flex="1" overflowY="hidden">
			<layouts.containers.flex className="body-container" flex="1 1">
				<layouts.modals.Root className={styledModal}>
					<Routes>
						<Route path="/:bid/*" element={<BrandRoutes />} />
						<Route path="/unknown" element={<BrandUnknown />} />
						<Route path="/" element={<BrandsDisplay />} />
						<Route path="*" element={<Navigate to="/unknown" replace />} />
					</Routes>
				</layouts.modals.Root>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

const HomePath = (permissions: meta.Token, bid: string) =>
	permissions.brandguide_view || permissions.brandguide_edit ? routing.brandguide(bid) : routing.brandguard(bid)

function BrandRoutes(): JSX.Element {
	const params = useParams() as { bid: string }
	return (
		<caching.Node bid={params.bid}>
			<notifications.counter.Node bid={params.bid} />
			<Routes>
				<Route path="/brandguide/*" element={<brandguide.Routes />} />
				<Route path="/brandguard/*" element={<brandguard.Routes />} />
				<Route path="/training/*" element={<training.Routes />} />
				<Route path="/brandgpt/*" element={<brandgpt.Routes />} />
				<Route path="/metrics/*" element={<metrics.Routes />} />
				<Route path="*" element={<brandguide.Routes />} />
				<Route path="/notifications/*" element={<notifications.Routes />} />
			</Routes>
		</caching.Node>
	)
}

const Nobrands = () => {
	const account = sessions.useAccount()
	const accountauthz = authzc.useCache((cached) => cached.meta)
	const permissions = accountauthz.current
	const navigate = useNavigate()
	return (
		<edit.New
			boxShadow="none"
			overflow="auto"
			defaults={api.zero({ account_id: account.id })}
			onChange={(b) => {
				const retry = httpx.autoretry()
				retry
					.wrap(() => api.create({ brand: b }, authzc.bearer(accountauthz)))
					.then((r) => navigate(HomePath(permissions, r.brand!.id)))
			}}
		/>
	)
}

function BrandsDisplay(): JSX.Element {
	return (
		<caching.Node>
			<layouts.backgrounds.Grey className="background-grey" flex="0 1 100%" background={layouts.theme.colors.grey.bg}>
				<layouts.modals.Root>
					<Grid nobrands={<Nobrands />} />
				</layouts.modals.Root>
			</layouts.backgrounds.Grey>
		</caching.Node>
	)
}

function BrandUnknown(): JSX.Element {
	const navigate = useNavigate()
	const accountauthz = authzc.useCache((cached) => cached.meta)
	const permissions = accountauthz.current
	return (
		<caching.Node>
			<layouts.backgrounds.Grey className="background-grey" flex="0 1 100%" background={layouts.theme.colors.grey.bg}>
				<layouts.modals.Root>
					<Grid
						nobrands={<Nobrands />}
						search={(req: api.SearchRequest, ...options: httpx.option[]): CancellablePromise<api.SearchResponse> => {
							return api.search(req, ...options).then((r) => {
								if (r.items.length !== 1) {
									return r
								}

								const brand = r.items.at(0)!
								// enqueue navigation away from the unknown page after the current task completes.
								setImmediate(() => navigate(HomePath(permissions, brand!.id)))
								return { ...r, items: [] }
							})
						}}
					/>
				</layouts.modals.Root>
			</layouts.backgrounds.Grey>
		</caching.Node>
	)
}

const styledModal = css`
	.modal {
		background: unset;
	}
	.inner {
		flex: 1;
		margin-top: 215px;
		align-items: center;
		justify-content: center;
	}
`

import React from "react"
import styled from "@emotion/styled"
import * as system from "styled-system"

const Image = styled.div<system.PositionProps>`
	position: absolute;
	cursor: pointer;
	width: 24px;
	height: 24px;
	top: ${(props) => props.top};
	right: ${(props) => props.right};
	left: ${(props) => props.left};
`
export function left(props: React.PropsWithChildren<system.PositionProps>): JSX.Element {
	const { top, children } = { ...props }

	return (
		<Image left="15px" top={top}>
			{children}
		</Image>
	)
}

left.defaultProps = {
	top: "15px",
}

export function right(props: React.PropsWithChildren<system.PositionProps>): JSX.Element {
	const { top, children } = { ...props }

	return (
		<Image right="15px" top={top}>
			{children}
		</Image>
	)
}

right.defaultProps = {
	top: "15px",
}

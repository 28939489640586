import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as tone_of_voices from "brandguide/build/tone_of_voice"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideToneOfVoice | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideToneOfVoiceZero(_guide.guide?.tone_of_voice))

	useEffect(() => {
		if (data.positive_adjectives.length + data.negative_adjectives.length > 0) return
		const defaultData = brandguide.build.generators.initial.tone_of_voice.adjectives(data)
		setData(defaultData)
	}, [])

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="We Are, We Are Not" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please type in any adjectives to describe what your tone of voice is and is not.">
							<tone_of_voices.Carousel my="25px" className={tone_of_voices.layouts.styledGap60}>
								<tone_of_voices.layouts.AdjectiveSectionItem
									title="We ARE"
									items={data.positive_adjectives}
									onChange={(upd) => setData({ ...data, positive_adjectives: upd })}
								/>
								<layouts.Flex mt="20px" borderRight={`1px solid ${layouts.theme.colors.grey.dark50alpha30}`} />
								<tone_of_voices.layouts.AdjectiveSectionItem
									title="We ARE NOT"
									items={data.negative_adjectives}
									onChange={(upd) => setData({ ...data, negative_adjectives: upd })}
								/>
							</tone_of_voices.Carousel>

							<tone_of_voices.Explanation
								val={data.descriptions?.adjectives || ""}
								placeholder="Add further explanation here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.tone_of_voice.descZero({
											...data.descriptions,
											adjectives: text,
										}),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(tone_of_voices.Steps.TONE_AND_VOICE, undefined)}
								onNext={() => onChange(tone_of_voices.Steps.WRITING_STYLE, data)}
								disabled={!brandguide.validations.tone_of_voice.valid.adjectives(data)}
								validation_message="You must have at least 2 adjectives in each section"
							/>
							<tone_of_voices.Progress
								activeStep={tone_of_voices.Steps.ADJECTIVES}
								steps={tone_of_voices.layouts.steps}
							/>
						</brandguide.build.layouts.SectionStepContainer>
						<tone_of_voices.Guidelines activPage="tone_of_voice" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as layouts from "layouts"
import * as icons from "icons"
import * as sessions from "sessions"

export function Logout(): JSX.Element {
	const current = sessions.useSession()
	const navigate = useNavigate()

	useEffect(() => {
		current.replace(sessions.zero(sessions.options.logout))
		navigate("/")
	}, [])

	return (
		<layouts.containers.flex className="logout" flexDirection="column" flex="1" width="100vw">
			<icons.loading.Ring1 />
		</layouts.containers.flex>
	)
}

import React from "react"
import * as layouts from "layouts"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as brands from "brands"
import * as compl from "brandguide/build/compliance"
import { Link } from "react-router-dom"
import { results } from "brandguide/build/layouts"
import * as navigation from "brandguide/build/navigation"
import * as authz from "authz"
import * as authzc from "authzcached"
import * as typography from "typography"

function formatTitles(titles: string[]): string {
	if (titles.length === 0) return ""

	let titlesString = ""
	let lastTitle = undefined
	switch (true) {
		case titles.length === 1:
			titlesString = titles[0]
			break
		case titles.length === 2:
			titlesString = titles.join(" and ")
			break
		default:
			lastTitle = titles.pop()
			titlesString = titles.join(", ")
			titlesString += " and " + lastTitle
			break
	}

	return titlesString
}

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideCompliance | undefined): void
}

export function SectionResult(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const guide = brandguide.cache.useGuide()
	const data = brandguide.api.zeros.guide.guideComplianceZero(guide.guide?.compliance)

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	const selectedItems = (data.items || []).filter((i) => i.names.length > 0)

	const title = `We need to follow the ${formatTitles(
		selectedItems.map((i) => i.title),
	)} compliance requirements in all of our written and visual communications.`

	return (
		<layouts.Flex flex="1" {...rest}>
			<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
				<results.Title>Compliance</results.Title>
				<results.ResultDesc>{title}</results.ResultDesc>

				<layouts.Flex my="20px">
					<results.Explanation desc={data.descriptions?.selection} />
				</layouts.Flex>

				<results.SectionContainer>
					<results.Title>Documentation</results.Title>
					<results.ResultDesc>
						Please refer the the following link(s) for the compliance documentation:
					</results.ResultDesc>
					<ul style={{ listStyleType: "initial", marginLeft: "20px" }}>
						{selectedItems.map((item, i) => (
							<React.Fragment key={i}>
								<li>
									<layouts.Flex my="20px" gridGap="20px">
										<layouts.Flex width="10vw">{item.title}:</layouts.Flex>
										<layouts.Flex>
											<ul>
												{item.names.map((n, index) => (
													<React.Fragment key={`${i}-${index}`}>
														<li style={{ marginBottom: "10px" }}>
															<layouts.Flex flexDirection="column" gridGap="5px">
																<layouts.Flex alignItems="center" maxWidth="40vw">
																	<typography.h4
																		fontSize="16px"
																		color={layouts.theme.colors.grey.dark50}
																		lineHeight="unset"
																		className={compl.layouts.truncatedText}
																	>
																		{n.name}
																	</typography.h4>
																</layouts.Flex>
																<layouts.Flex maxWidth="40vw">
																	<layouts.links.external
																		href={n.url}
																		target="_blank"
																		color={layouts.theme.colors.blue.blue}
																		style={{ textDecoration: "underline", fontSize: "12px", whiteSpace: "nowrap" }}
																		className={compl.layouts.truncatedText}
																	>
																		{n.url}
																	</layouts.links.external>
																</layouts.Flex>
															</layouts.Flex>
														</li>
													</React.Fragment>
												))}
											</ul>
										</layouts.Flex>
									</layouts.Flex>
								</li>
							</React.Fragment>
						))}
					</ul>

					<layouts.containers.grid
						gap="30px"
						gridTemplateColumns="repeat(auto-fill, minmax(250px, 250px))"
						gridTemplateRows="repeat(auto-fill, minmax(250px, 250px))"
						width="100%"
					></layouts.containers.grid>
				</results.SectionContainer>

				<authz.Protected enabled={permission.brandguide_edit}>
					<layouts.Flex justifyContent="center" mt="100px" mb="20px" alignItems="center">
						<NextStageLink guide={guide} className="no-print" />
						<navigation.Section
							title="Compliance"
							steps={compl.layouts.steps}
							onChange={(s) => onChange(s, undefined)}
						/>
					</layouts.Flex>
				</authz.Protected>
			</layouts.Flex>
			<colors.Guidelines activPage="compliance" className="no-print" />
		</layouts.Flex>
	)
}

function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<layouts.Flex justifyContent="right" mr="47px">
						<brandguide.build.SavePDF />
					</layouts.Flex>
					<SectionResult onChange={onChange} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	onLoad: () => undefined,
}

interface nextProps extends layouts.containers.FlexProps {
	guide: brandguide.api.Brandguide
}

function NextStageLink(props: nextProps): JSX.Element {
	const { guide, ...rest } = props
	const brand = brands.caching.useCached()

	const nextLink = (): string | undefined => {
		if (guide.guide?.application?.enabled) return brandguide.routing.build.application(brand.id)
		return undefined
	}

	if (!nextLink()) return <></>

	return (
		<layouts.Flex {...rest}>
			<Link to={nextLink()!}>
				<layouts.buttons.unstyled
					width="130px"
					height="34px"
					background={layouts.theme.colors.grey.dark50}
					borderRadius="5px"
					color={layouts.theme.colors.white}
					onClick={() => undefined}
				>
					Next Section
				</layouts.buttons.unstyled>
			</Link>
		</layouts.Flex>
	)
}

NextStageLink.defaultProps = {
	flex: "1",
	justifyContent: "end",
}

export default Display

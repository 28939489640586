/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandgpt.proto" (package "brandgpt", syntax proto3)
// tslint:disable
// @ts-nocheck
/**
 * @generated from protobuf enum brandgpt.Direction
 */
export enum Direction {
    /**
     * @generated from protobuf enum value: USER = 0;
     */
    USER = 0,
    /**
     * @generated from protobuf enum value: SYSTEM = 1;
     */
    SYSTEM = 1,
    /**
     * @generated from protobuf enum value: ASSISTANT = 2;
     */
    ASSISTANT = 2,
    /**
     * @generated from protobuf enum value: ERROR = 999;
     */
    ERROR = 999
}

import React, { useEffect, useState } from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as errors from "errors"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as api from "brandguard/api"
import * as bgscores from "brandguard/display/scores"
import { ScoreAccordionDescription, SubSubScoreContainer } from "brandguard/components/layouts/scores"
import * as icons from "icons"

interface props extends layouts.containers.FlexProps {
	event: api.Media
}

export default function Display(props: React.PropsWithChildren<props>): JSX.Element {
	const { className, event, children, ...rest } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)
	const [req, setReq] = useState(
		api.explanations.nudity.searches.request({
			event_ids: [event.id],
		}),
	)
	const [nudity, setNudity] = useState({ next: req, items: [] } as api.NudityExplanationSearchResponse)
	const [loading, setloading] = useState(false)

	useEffect(() => {
		setloading(true)
		const retry = httpx.autoretry()
		const pending = retry
			.wrap(() => api.explanations.nudity.search(req, authzc.bearer(metaauthz)))
			.then(setNudity)
			.catch(httpx.errors.cancellation(console.debug))
			.catch((cause) => {
				setCause(<errors.UnknownCause logging={console.error} cause={cause} onClick={() => setCause(undefined)} />)
			})
			.finally(() => setloading(false))

		return pending.cancel
	}, [req])

	useEffect(() => {
		setReq(
			api.violations.searches.request({
				event_ids: [event.id],
			}),
		)
	}, [event.id])

	if (loading)
		return (
			<SubSubScoreContainer className={className}>
				<layouts.containers.flex flexDirection="row" flex="1">
					<layouts.containers.flex flex="3" justifyContent="flex-start" alignItems="center" color={"unset"}>
						Nudity
					</layouts.containers.flex>
					<layouts.containers.flex flex="1" justifyContent="flex-end" alignItems="center">
						<icons.loading.Ring3 width="14px" height="14px" />
					</layouts.containers.flex>
				</layouts.containers.flex>
				<bgscores.PercentageBar weight={0} />
			</SubSubScoreContainer>
		)
	if (cause) return <layouts.Flex p="10px">{cause}</layouts.Flex>
	if (nudity.items.length === 0) return <>{children}</>

	return (
		<bgscores.SectionAccordion
			borderWidth="0"
			borderRadius="0"
			borderColor="unset"
			borderStyle="unset"
			borderBottom="none"
			my="0"
			className={classnames(className, bgscores.layouts.styledAccordion)}
			{...rest}
		>
			<layouts.containers.box tabIndex={0}>
				<bgscores.SectionDescription>
					<ScoreAccordionDescription padding="0" className={className}>
						{children}
					</ScoreAccordionDescription>
				</bgscores.SectionDescription>
				<layouts.accordions.Content>
					<layouts.Flex
						flexDirection="column"
						flex="1"
						pr="54px"
						className={classnames("nudity_explanation.list", className)}
					>
						<errors.overlay styled cause={cause} flex="1">
							{nudity.items.map((v) => (
								<layouts.Flex flexDirection="column" key={v.id} my="10px" pl="35px" lineHeight="14px">
									<layouts.Box fontWeight="700">{v.description}</layouts.Box>
								</layouts.Flex>
							))}
						</errors.overlay>
					</layouts.Flex>
				</layouts.accordions.Content>
			</layouts.containers.box>
		</bgscores.SectionAccordion>
	)
}

import React from "react"
import * as layouts from "layouts"
import classnames from "classnames"
import * as api from "brandguard/api"
import { Image, Text } from "media"
import * as mediaapi from "media/api"
import { styledscroll } from "brandguard/components/layouts"
import * as bgscores from "brandguard/display/scores"
import * as icons from "icons"

export function ImageDisplay(
	props: React.PropsWithChildren<{ item: api.ImageSearchResponseItem; onClose(): void } & layouts.containers.FlexProps>,
): JSX.Element {
	const { item, onClose, children, ...rest } = props
	return (
		<layouts.containers.flex flexDirection="row" {...rest}>
			<layouts.containers.flex
				flex="1"
				flexDirection="row"
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
				height="75vh"
				width="60vw"
				overflow="hidden"
			>
				<layouts.containers.flex
					flex="6"
					borderRight={layouts.theme.borders.grey.dark50alpha}
					alignItems="center"
					justifyContent="center"
					background={layouts.theme.backgrounds.bluealpha5}
				>
					<Image media={mediaapi.zero({ content_digest: item.media?.md5 })} height="100%" />
				</layouts.containers.flex>
				<layouts.containers.flex
					flex="5"
					p="10px"
					flexDirection="column"
					className={classnames(bgscores.layouts.styledscores, styledscroll)}
					overflowY="auto"
				>
					<layouts.containers.flex justifyContent="flex-end" alignItems="flex-start">
						<layouts.containers.flex onClick={onClose}>
							<icons.Close
								width="15px"
								height="15px"
								fill={layouts.theme.colors.grey.dark50}
								fillOpacity="0.6"
								p="5px"
							/>
						</layouts.containers.flex>
					</layouts.containers.flex>
					{children}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function TextDisplay(
	props: React.PropsWithChildren<{ item: api.TextSearchResponseItem; onClose(): void } & layouts.containers.FlexProps>,
): JSX.Element {
	const { item, onClose, children, ...rest } = props
	return (
		<layouts.containers.flex flexDirection="row" {...rest}>
			<layouts.containers.flex
				flex="1"
				flexDirection="row"
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
				height="75vh"
				width="60vw"
				overflow="hidden"
			>
				<layouts.containers.flex
					flex="6"
					borderRight={layouts.theme.borders.grey.dark50alpha}
					alignItems="center"
					justifyContent="center"
					background={layouts.theme.backgrounds.bluealpha5}
				>
					<Text
						media={mediaapi.zero({ content_digest: item.media?.md5 })}
						textAlign="left"
						fontWeight="400"
						fontSize="16px"
						lineHeight="30px"
						p="20px"
						my="auto"
					/>
				</layouts.containers.flex>
				<layouts.containers.flex
					flex="5"
					p="10px"
					flexDirection="column"
					className={classnames(bgscores.layouts.styledscores, styledscroll)}
					overflowY="auto"
				>
					<layouts.containers.flex justifyContent="flex-end" alignItems="flex-start">
						<layouts.containers.flex onClick={onClose}>
							<icons.Close
								width="15px"
								height="15px"
								fill={layouts.theme.colors.grey.dark50}
								fillOpacity="0.6"
								p="5px"
							/>
						</layouts.containers.flex>
					</layouts.containers.flex>
					{children}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

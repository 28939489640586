import * as brands from "brands"
import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as icons from "icons"
import * as colorsx from "colorsx"
import * as components from "./components"

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const brand = brands.caching.useCached()
	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = guide.guide!

	const hasLogo = !!data.logos?.enabled && brandguide.validations.logos.valid.uploads(data.logos)

	const isWhiteLogo = () => {
		if (!hasLogo) return false
		return CheckWhiteLogo(data.logos?.primary?.svg!)
	}

	const textColor = isWhiteLogo() ? colors.white : colors.grey.dark50
	const bgcolor = isWhiteLogo() ? colors.black.dark : colors.white

	return (
		<layouts.Flex {...rest} background={bgcolor}>
			<layouts.Flex maxHeight="30px">
				<typography.h6 color={textColor} fontSize="12px" fontWeight="700">
					BrandGuide Builder
				</typography.h6>
			</layouts.Flex>
			<layouts.Flex flex="1" flexDirection="column" alignItems="center" justifyContent="center">
				{hasLogo ? (
					<layouts.Flex height="50vh" width="50vw" alignItems="center" justifyContent="center">
						<img
							src={data.logos?.primary?.svg}
							alt="Main Logo"
							style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
						/>
					</layouts.Flex>
				) : (
					<typography.h1 letterSpacing="normal" color={textColor}>
						{brand.description}
					</typography.h1>
				)}
				<layouts.Flex mt="25px">
					<typography.h3 color={textColor} fontSize="22px" fontWeight="500" letterSpacing="normal">
						{brand.description} Brand Guidelines
					</typography.h3>
				</layouts.Flex>
				<layouts.Flex>
					<typography.h4 color={textColor} fontSize="14px" fontWeight="400">
						Last Updated: {components.FormattedDate()}
					</typography.h4>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	flexDirection: "column",
	height: "100vh",
	width: "100vw",
}

const CheckWhiteLogo = (svg: string) => {
	const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
	const colors = icons.svg.getColorsFromSvg(svgElement)
	const top_color = icons.svg.getTopColors(colors)
	const hex = colorsx.convertColorToHex(top_color[0])
	return colorsx.isNearlyWhite(hex)
}

import { useEffect, useState } from "react"
import * as api from "./api"
import * as authzc from "authzcached"
import * as navigation from "navigation"
import * as uuid from "uuid"

interface props {
	bid?: string
}

export function Node(props: props): JSX.Element {
	const { bid } = props

	const nav = navigation.context.useCached()

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [cnt, setCnt] = useState(0)

	useEffect(() => {
		if (!bid) return
		if (!uuid.validate(bid)) return

		let cancelled = false
		const fetchData = () => {
			api
				.unread_cnt(bid, authzc.bearer(metaauthz))
				.then((r) => cancelled || setCnt(r.total))
				.catch((e) => console.error(e))
		}
		fetchData()
		const tid = setInterval(() => {
			fetchData()
		}, 60000) // every 1 minute

		return () => {
			clearInterval(tid)
			cancelled = true
		}
	}, [])

	useEffect(() => {
		nav.update({ ...nav, unread_notification_cnt: cnt })
	}, [cnt])

	return <></>
}

import { SectionTitle } from "brandguide/results/components"
import { default as MainLogos } from "./main"
import { default as AdditionalLogos } from "./addidional"
import { default as Geomentry } from "./geometry"
import { default as Placements } from "./placements"

export { default as MainLogos } from "./main"
export { default as AdditionalLogos } from "./addidional"
export { default as Geomentry } from "./geometry"
export { default as Placements } from "./placements"

export function Section(): JSX.Element {
	return (
		<>
			<SectionTitle title="Logos" className="section-print" />
			<MainLogos className="section-print" />
			<AdditionalLogos className="section-print" />
			<Geomentry className="section-print" />
			<Placements className="section-print" />
		</>
	)
}

import * as system from "styled-system"
import styled from "@emotion/styled"
import * as b64 from "js-base64"

export interface SVGProps extends system.PositionProps, system.SpaceProps {
	fill?: string
	fillOpacity?: string
	stroke?: string
	strokeOpacity?: string
	className?: string
	transform?: string
	display?: string | number | undefined
	height?: string | number | undefined
	width?: string | number | undefined
	verticalAlign?: string | undefined
}

export const SVG = styled.svg<SVGProps>`
	${system.display}
	${system.layout}
	${system.space}
	${system.position}
`

export const prefix = "data:image/svg+xml;base64,"

export const encodeFromB64ToSVGElement = (svg: string): SVGSVGElement => {
	const svgString = () => {
		if (svg.startsWith(prefix)) {
			svg = svg.slice(prefix.length)
		}
		return b64.atob(svg)
	}
	const parser = new DOMParser()
	const svgDoc = parser.parseFromString(svgString(), "image/svg+xml")
	return svgDoc.documentElement as unknown as SVGSVGElement
}

export const getColorsFromSvg = (svg: SVGSVGElement): Record<string, number> => {
	const colors: Record<string, number> = {}
	const styleElement = svg.querySelector("defs style")
	if (styleElement) {
		const styleRules = (styleElement.textContent || "").split("}")
		styleRules.forEach((rule) => {
			const [, properties] = rule.split("{")
			if (properties) {
				const props = properties.split(";")
				props.forEach((prop) => {
					const [key, value] = prop.split(":")
					if (key.trim() === "fill" || key.trim() === "stroke") {
						const color = value.trim()
						if (color && color !== "none") {
							colors[color] = (colors[color] || 0) + 1
						}
					}
				})
			}
		})
	}
	svg.querySelectorAll("*").forEach((element) => {
		const fill = element.getAttribute("fill")
		const stroke = element.getAttribute("stroke")
		const stop_color = element.getAttribute("stop-color")
		;[fill, stroke, stop_color].forEach((color) => {
			if (color && color !== "none") {
				colors[color] = (colors[color] || 0) + 1
			}
		})
	})
	if (Object.keys(colors).length === 0) return { "#000000": 1 }
	return colors
}

export const getTopColors = (colors: Record<string, number>, count: number = 1): string[] => {
	return Object.entries(colors)
		.sort(([, count1], [, count2]) => count2 - count1)
		.slice(0, count)
		.map(([color]) => color)
}

export function svgToPngB64String(svgEl: SVGSVGElement, width: number, clearspace: number = 0): Promise<string> {
	return new Promise((resolve, reject) => {
		const serializer = new XMLSerializer()
		const svgStr = serializer.serializeToString(svgEl)

		const canvas = document.createElement("canvas")
		const context = canvas.getContext("2d")
		const img = new Image()

		img.onload = function () {
			// Scaling SVG while maintaining proportions
			const aspectRatio = svgEl.viewBox.baseVal.width / svgEl.viewBox.baseVal.height
			const clearspacePixels = (clearspace / 100) * width
			canvas.width = width + 2 * clearspacePixels
			canvas.height = width / aspectRatio + 2 * clearspacePixels

			if (context) {
				context.drawImage(img, clearspacePixels, clearspacePixels, width, width / aspectRatio)
				resolve(canvas.toDataURL("image/png"))
			} else {
				reject("Failed to get canvas context")
			}
		}

		img.onerror = function (err) {
			reject(err)
		}

		img.src = "data:image/svg+xml;base64," + b64.btoa(svgStr)
	})
}

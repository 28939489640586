import * as authzcache from "authzcache"
import * as sessions from "sessions"
import * as httpx from "httpx"
import * as _tokens from "authz/tokens"
import * as metaproto from "./meta.authz"
import * as profiles from "profiles"
import { CancellablePromise } from "real-cancellable-promise"

export { default as Modify } from "./authz.modify"

export function authz(
	req: metaproto.AuthzRequest,
	...options: httpx.option[]
): CancellablePromise<metaproto.AuthzResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/meta/authz`, req, ...options)
}

export function grant(req: metaproto.GrantRequest, ...options: httpx.option[]): Promise<metaproto.GrantResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/meta/authz/${req.profile_id}`, req, ...options)
}

export function grant_self(req: metaproto.GrantRequest, ...options: httpx.option[]): Promise<metaproto.GrantResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/meta/authz/me/${req.profile_id}`, req, ...options)
}

export function profile(req: metaproto.ProfileRequest, ...options: httpx.option[]): Promise<metaproto.ProfileResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/meta/authz/${req.profile_id}`, req, ...options)
}

export interface Token extends _tokens.Token {
	usermanagement: boolean
	brandguard_upload: boolean
	brandguard_review: boolean
	brandguard_train: boolean
	adpool_management: boolean
	brandgpt_use: boolean
	brandguide_view: boolean
	brandguide_edit: boolean
}

export namespace tokens {
	export function zero(ctx: Partial<Token> = {}): Token {
		return _tokens.zero({
			usermanagement: false,
			brandguard_upload: false,
			brandguard_review: false,
			brandguard_train: false,
			adpool_management: false,
			brandgpt_use: false,
			brandguide_view: false,
			brandguide_edit: false,
			...ctx,
		})
	}

	export function denied(p: profiles.Profile, bearer: string): Token {
		return zero({
			profile_id: p.id,
			account_id: p.account_id,
			bearer: bearer,
		})
	}

	export function authorization(t: Token): metaproto.Authorization {
		return {
			usermanagement: t.usermanagement,
			brandguard_upload: t.brandguard_upload,
			brandguard_review: t.brandguard_review,
			brandguard_train: t.brandguard_train,
			adpool_management: t.adpool_management,
			brandgpt_use: t.brandgpt_use,
			brandguide_view: t.brandguide_view,
			brandguide_edit: t.brandguide_edit,
		}
	}

	export function authorizationFromGrant(g: metaproto.GrantResponse): metaproto.Authorization {
		return (
			g.authorization || {
				usermanagement: false,
				brandguard_upload: false,
				brandguard_review: false,
				brandguard_train: false,
				adpool_management: false,
				brandgpt_use: false,
				brandguide_view: false,
				brandguide_edit: false,
			}
		)
	}
}

export function useAuthz(session: sessions.Session): _tokens.Cached<Token> {
	const { profile, credentials, account } = session
	return authzcache.cached(tokens.denied(profile, credentials), (cached): CancellablePromise<Token> => {
		const retry = httpx.autoretry()
		return retry
			.wrap(() => authz({}, sessions.bearertoken(account.id)))
			.then((r) => {
				return tokens.zero({
					...cached.current,
					..._tokens.clone(r.token),
				})
			})
	})
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brands from "brands"
import * as icons from "icons"
import * as sessions from "sessions"
import { FileRejection } from "react-dropzone"
import * as httpx from "httpx"
import * as mediaapi from "media/api"
import * as inputs from "inputs"

export namespace images {
	const acceptedTypes = {
		"image/png": [".png"],
		"image/jpeg": [".jpeg", ".jpg"],
		"image/gif": [".gif"],
		"image/webp": [".webp"],
	}

	const ImgSize = 250

	interface uploadItem {
		contentdigest: string
		thumbnail_contentdigest: string
	}

	interface props extends layouts.containers.ContainerProps {
		onChange(items: uploadItem[]): void
	}

	export function Dropwell(props: React.PropsWithChildren<props>): JSX.Element {
		const { onChange, ...rest } = props
		const bearertoken = sessions.useToken()
		const mediaPath = brands.routing.media(brands.caching.useCached().id)

		const [upload, setUpload] = useState(false)
		const [compress, setCompress] = useState(false)

		const [uploaded, setUploaded] = useState([] as string[])
		const [compressed, setCompressed] = useState([] as string[])

		useEffect(() => {
			if ([uploaded.length, compressed.length].includes(0)) return
			const upd = compressed.map((c, i) => {
				return { thumbnail_contentdigest: c, contentdigest: uploaded[i] } as uploadItem
			})
			onChange(upd)
		}, [uploaded, compressed])

		const onDropHandle = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
			if (acceptedFiles.length === 0) return
			const uploadPromises = acceptedFiles.map((f) => {
				return httpx.autoretry().wrap(() => mediaapi.uploadMedia(f, mediaPath, bearertoken))
			})
			const compressPromises = acceptedFiles.map((f) => resizeImageAndReturnFile(f))

			setUpload(true)
			Promise.all(uploadPromises)
				.then((results) => {
					const result = results.map((r) => r.media?.content_digest || "")
					setUploaded(result)
				})
				.finally(() => setUpload(false))

			setCompress(true)
			Promise.all(compressPromises).then((results) => {
				const compressedUploadPromoses = results.map((f) => {
					return httpx.autoretry().wrap(() => mediaapi.uploadMedia(f, mediaPath, bearertoken))
				})
				Promise.all(compressedUploadPromoses)
					.then((resp) => {
						const result = resp.map((r) => r.media?.content_digest || "")
						setCompressed(result)
					})
					.finally(() => setCompress(false))
			})
		}

		return (
			<layouts.loading.pending loading={[upload, compress].includes(true)}>
				<inputs.Dropwell
					className="images.dropwell"
					multiple
					accept={acceptedTypes}
					onDrop={(accepted, rejected, evt) => onDropHandle(accepted, rejected)}
					display="flex"
					flex="1"
					{...rest}
				>
					<layouts.dnd.Container borderRadius="unset" flex="1">
						<icons.FileUpload width="75px" height="75px" mt="16px" className="upload-icon" />
						<typography.h6
							fontSize="12px"
							fontWeight="400"
							whiteSpace="unset"
							textAlign="center"
							color={layouts.theme.colors.grey.dark3}
							lineHeight="16px"
							mb="13px"
							mt="7px"
						>
							Drag & drop your image here or choose file
						</typography.h6>
					</layouts.dnd.Container>
				</inputs.Dropwell>
			</layouts.loading.pending>
		)
	}

	function resizeImageAndReturnFile(file: File): Promise<File> {
		return new Promise((resolve, reject) => {
			const img = document.createElement("img")
			img.src = URL.createObjectURL(file)
			img.onload = () => {
				const canvas = document.createElement("canvas")
				const ctx = canvas.getContext("2d")
				let { width, height } = img

				if (width > height) {
					height = Math.round((height * ImgSize) / width)
					width = ImgSize
				} else {
					width = Math.round((width * ImgSize) / height)
					height = ImgSize
				}

				canvas.width = width
				canvas.height = height

				ctx?.drawImage(img, 0, 0, width, height)

				canvas.toBlob((blob) => {
					if (blob) {
						const newFile = new File([blob], file.name, { type: file.type })
						resolve(newFile)
					} else {
						reject("image compression error")
					}
				}, file.type)
			}
			img.onerror = reject
		})
	}
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as tone_of_voices from "brandguide/build/tone_of_voice"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideToneOfVoice | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideToneOfVoiceZero(_guide.guide?.tone_of_voice))

	const items = data.tone_of_voice || []

	useEffect(() => {
		const defaultData = brandguide.build.generators.initial.tone_of_voice.tone_of_voice()
		if (data.tone_of_voice.length === 0) {
			setData({ ...data, tone_of_voice: defaultData })
			return
		}
		const selected = data.tone_of_voice.find((i) => i.selected)
		if (!selected) return
		setData({ ...data, tone_of_voice: defaultData.map((d) => (d.title === selected?.title ? selected : d)) })
	}, [])

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Tone of Voice" Help={<tone_of_voices.helps.Modal />} />
					<layouts.Flex flex="1">
						<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
							<typography.h5
								mt="32px"
								fontSize="16px"
								fontWeight="400"
								letterSpacing="unset"
								color={layouts.theme.colors.grey.dark50}
								whiteSpace="unset"
							>
								Please select your brand&apos;s tone using a simple error message example. Examples taken from{" "}
								<layouts.links.external
									href="https://www.nngroup.com/articles/tone-of-voice-dimensions/"
									target="_blank"
									color={layouts.theme.colors.grey.dark50alpha80}
									style={{ textDecoration: "underline" }}
								>
									Nielson Norman Group
								</layouts.links.external>
								.
							</typography.h5>

							<tone_of_voices.Carousel my="25px" className={tone_of_voices.layouts.styledCarousel} autoscrollright>
								{items.map((i) => (
									<tone_of_voices.layouts.TovItem
										key={i.id}
										item={i}
										onChange={(upd) =>
											setData({
												...data,
												tone_of_voice: items.map((i) => (i.id === upd.id ? upd : { ...i, selected: false })),
											})
										}
									/>
								))}
							</tone_of_voices.Carousel>

							<tone_of_voices.Explanation
								val={data.descriptions?.tone_of_voice || ""}
								placeholder="Add further explanation here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.tone_of_voice.descZero({
											...data.descriptions,
											tone_of_voice: text,
										}),
									})
								}}
							/>
							<brandguide.build.layouts.NextStepsNav
								onNext={() =>
									onChange(tone_of_voices.Steps.ADJECTIVES, {
										...data,
										tone_of_voice: items.filter((i) => i.selected),
									})
								}
								disabled={!brandguide.validations.tone_of_voice.valid.tone_of_voice(data)}
							/>
							<tone_of_voices.Progress
								activeStep={tone_of_voices.Steps.TONE_AND_VOICE}
								steps={tone_of_voices.layouts.steps}
							/>
						</layouts.Flex>
						<tone_of_voices.Guidelines activPage="tone_of_voice" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import React, { useState, useRef } from "react"
import * as layouts from "layouts"
import * as logos from "."
import * as brandguide from "brandguide"
import * as md5x from "md5x"

export const Steps = {
	UPLOAD: 0,
	CLEARSPASE: 1,
	PROPORTIONS: 2,
	PLACEMENT: 3,
	MISUSE: 4,
	USAGE: 5,
	RESULT: 6,
}

export function Container(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()

	const _logos = brandguide.api.zeros.guide.guideLogosZero(cache.guide.guide?.logos)
	const containerRef = useRef<HTMLDivElement>(null)

	const curstep = () => {
		switch (false) {
			case brandguide.validations.logos.valid.uploads(_logos):
				return Steps.UPLOAD
			case brandguide.validations.logos.valid.clearspace(_logos):
				return Steps.CLEARSPASE
			case brandguide.validations.logos.valid.proportions(_logos):
				return Steps.PROPORTIONS
			case brandguide.validations.logos.valid.placement(_logos):
				return Steps.PLACEMENT
			case brandguide.validations.logos.valid.misuse(_logos):
				return Steps.MISUSE
			case brandguide.validations.logos.valid.usage(_logos):
				return Steps.USAGE
			default:
				return Steps.RESULT
		}
	}

	const [step, setStep] = useState(curstep)
	const [loading, setLoading] = useState(false)

	const handleOnStep = (step: number, data: brandguide.api.GuideLogos | undefined) => {
		if (!data) {
			setStep(step)
			return
		}

		const guide = { ...cache.guide.guide, logos: data }
		const md5 = md5x.string(JSON.stringify(guide))

		if (md5 === cache.md5) {
			setStep(step)
			return
		}

		setLoading(true)
		cache.store({ ...cache, guide: { ...cache.guide, guide: guide } }).finally(() => {
			setStep(step)
			setLoading(false)
		})
	}

	return (
		<layouts.Flex overflowY="auto" ref={containerRef} {...rest}>
			<layouts.loading.screen flex="1" loading={loading} position={loading ? "relative" : "unset"}>
				{step === Steps.UPLOAD && <logos.Uploads onChange={handleOnStep} />}
				{step === Steps.CLEARSPASE && <logos.Clearspace containerRef={containerRef} onChange={handleOnStep} />}
				{step === Steps.PROPORTIONS && <logos.Proportions onChange={handleOnStep} />}
				{step === Steps.PLACEMENT && <logos.Placements onChange={handleOnStep} />}
				{step === Steps.MISUSE && <logos.Misuse onChange={handleOnStep} />}
				{step === Steps.USAGE && <logos.Usage onChange={handleOnStep} />}
				{step === Steps.RESULT && <logos.Result onChange={handleOnStep} />}
			</layouts.loading.screen>
		</layouts.Flex>
	)
}

Container.defaultProps = {
	flexDirection: "column",
	flex: "1",
	mb: "25px",
}

export default Container

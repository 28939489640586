import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as uuid from "uuid"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideColors | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideColorsZero(_guide.guide?.colors))

	const items = data.secondary_colors || ([] as brandguide.api.ColorObject[])

	useEffect(() => {
		if (items.length > 0) return
		setData({
			...data,
			secondary_colors: [
				brandguide.build.generators.initial.colors.black(),
				brandguide.build.generators.initial.colors.white(),
			],
		})
	}, [])

	const nonDefaultColors = items.filter((el) => !el.metadata?.default)
	const defaultColors = items.filter((el) => el.metadata?.default)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Secondary Color Palette" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Create your secondary color palette.">
							<colors.Carousel my="25px" autoscrollright>
								{nonDefaultColors.map((c, i) => (
									<colors.layouts.ColorItem
										key={c.metadata?.id}
										item={c}
										onDelete={(id) => {
											setData({
												...data,
												secondary_colors: items.filter((o) => o.metadata?.id !== id),
											})
										}}
										onChange={(u) => {
											setData({
												...data,
												secondary_colors: items.map((a) => (u.metadata!.id === a.metadata?.id ? u : a)),
											})
										}}
									/>
								))}
								{defaultColors.length > 1 && (
									<layouts.Flex
										flexDirection="column"
										width="140px"
										height="240px"
										border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
									>
										{defaultColors.map((c, i) => (
											<colors.layouts.ColorItem
												key={c.metadata?.id}
												item={c}
												height="120px"
												border="none"
												disabled
												onDelete={(id) => {
													setData({ ...data, secondary_colors: items.filter((o) => o.metadata?.id !== id) })
												}}
												onChange={(u) => undefined} //we can not change default colors
											/>
										))}
									</layouts.Flex>
								)}
								{defaultColors.length < 2 &&
									defaultColors.map((c, i) => (
										<colors.layouts.ColorItem
											key={c.metadata?.id}
											item={c}
											disabled
											onDelete={(id) => {
												setData({ ...data, secondary_colors: items.filter((o) => o.metadata?.id !== id) })
											}}
											onChange={(u) => undefined} //we can not change default colors
										/>
									))}
								<colors.layouts.AddColorItem
									onChange={() =>
										setData({
											...data,
											secondary_colors: [
												...items,
												...[
													{
														color: brandguide.api.zeros.colors.colorZero({
															hex_code: layouts.theme.colors.white,
															name: "white",
														}),
														metadata: brandguide.api.zeros.colors.metadataZero({ id: uuid.v4() }),
													},
												],
											],
										})
									}
								/>
							</colors.Carousel>
							<colors.Explanation
								val={data.descriptions?.secondary_colors || ""}
								placeholder="Add further explanation of color usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.colors.descZero({
											...data.descriptions,
											secondary_colors: text,
										}),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(colors.Steps.PRIMARY, undefined)}
								onNext={() => onChange(colors.Steps.CORRECT, data)}
								disabled={!brandguide.validations.colors.valid.secondary(data)}
							/>
							<brandguide.build.layouts.SkipStep
								my="30px"
								onSkip={() => onChange(colors.Steps.CORRECT, data)}
								onNeverHave={() => onChange(colors.Steps.CORRECT, data)}
							/>
							<colors.Progress activeStep={colors.Steps.SECONDARY} steps={colors.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<colors.Guidelines activPage="colors" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import React, { useContext, useEffect, useState } from "react"
import * as httpx from "httpx"
import * as accounts from "accounts"
import * as api from "./api"
import * as sessions from "sessions"

export const Context = React.createContext(zero())
export const Provider = Context.Provider

export interface Replacer {
	(s: cached): void
}

export default interface cached {
	settings: api.AccountSettings
	replace: Replacer
}

export function zero(): cached {
	return {
		settings: api.settings.zero(),
		replace(s: cached) {
			// noop default.
		},
	}
}

export function useCached(): cached {
	return useContext(Context)
}

export function useSettings(): api.AccountSettings {
	const ctx = useContext(Context)
	return ctx.settings
}

export function Storage(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [cached, setCached] = useState(zero())
	const session = sessions.useSession()

	useEffect(() => {
		const retry = httpx.autoretry()
		const pending = retry
			.wrap(() => accounts.api.settings.get({}, sessions.bearertoken(session.account.id)))
			.then((r) => setCached({ ...cached, settings: api.settings.zero(r.account_settings), replace: setCached }))
			.catch(httpx.errors.cancellation(console.warn))
			.catch((e) => console.error(e))
		return pending.cancel
	}, [session.account.id])

	return <Provider value={cached}>{props.children}</Provider>
}

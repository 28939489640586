import React from "react"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as styles from "./styles"
import Icon from "./icon"

export default function StatusOverlay(
	props: {
		prediction: api.Prediction
		color(p: api.Prediction): string
	} & layouts.containers.FlexProps,
): JSX.Element {
	const { prediction, color, ...rest } = props

	return (
		<styles.StatusText background={color(prediction)} {...rest}>
			<Icon prediction={prediction} />
		</styles.StatusText>
	)
}

StatusOverlay.defaultProps = {
	prediction: api.Prediction.NONE,
	color: predictions.color,
	height: "13px",
	width: "13px",
	borderBottomLeftRadius: "3px",
	top: "0",
	right: "0",
	position: "absolute",
}

import React from "react"
import * as icons from "icons"
import { SVGProps } from "icons"
import * as api from "brandguard/api"
import { css } from "@emotion/css"
import classnames from "classnames"

const rotateAnim = css`
	animation-name: rotate;
	animation-duration: 3s;
	animation-iteration-count: infinite;

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

export default function Icon(props: SVGProps & { prediction: api.Prediction }): JSX.Element {
	const { prediction, ...rest } = props
	if (
		[api.Prediction.REJECTED, api.Prediction.REJECTED_BY_REVIEW, api.Prediction.DISCARDED_BY_UPLOADER].includes(
			prediction,
		)
	) {
		return <icons.prediction.rejected {...rest} />
	}

	if (
		[api.Prediction.APPROVED, api.Prediction.APPROVED_BY_REVIEW, api.Prediction.APPROVED_BY_SYSTEM].includes(prediction)
	) {
		return <icons.prediction.approved {...rest} />
	}

	if (prediction === api.Prediction.INREVIEW) {
		return <icons.prediction.inreview {...rest} />
	}

	return <icons.prediction.processing className={classnames(rotateAnim)} {...rest} />
}

Icon.defaultProps = {
	prediction: api.Prediction.NONE,
}

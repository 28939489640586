export { default as SectionTitle } from "./section_title"
export { default as FormattedDate } from "./bgb_date"
export { default as LeftSideNav, NavTitle, NavDescription } from "./left_side_nav"
export { MainContainer, ContentContainer } from "./layouts"
export { default as Explanation } from "./explanation"
export { default as ImagesPage } from "./images_page"

export * as validations from "./validations"

export const pluralize = (count: number, noun: string, suffix = "s") => `${count} ${noun}${count !== 1 ? suffix : ""}`

export function chunkImageItems<T>(array: T[], chunkSize: number = 9): T[][] {
	let index = 0
	const tempArray = []

	for (index = 0; index < array.length; index += chunkSize) {
		let chunk = array.slice(index, index + chunkSize)
		tempArray.push(chunk)
	}

	return tempArray
}

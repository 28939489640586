import styled from "@emotion/styled"
import * as layouts from "layouts"

export const BlockDenied = styled(layouts.containers.flex)`
	font-weight: 400;
	font-size: 12pt;
	line-height: 18px;
	text-align: center;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: ${layouts.theme.colors.danger};
	background: ${layouts.theme.colors.white};
	width: 100%;
`

export const UploadDenied = styled(BlockDenied)`
	background: ${layouts.theme.backgrounds.bluealpha};
	border: 1px dashed ${layouts.theme.colors.blue.blue};
`

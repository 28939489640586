import * as layouts from "layouts"
import styled from "@emotion/styled"

export const styledFormContainer = styled(layouts.forms.Group)``

styledFormContainer.defaultProps = {
	alignItems: "center",
	padding: "10px",
	background: layouts.theme.colors.white,
	borderRadius: "5px",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha20}`,
	flex: "unset",
}

export const styledFormLabel = styled.span``

styledFormLabel.defaultProps = {
	color: layouts.theme.colors.grey.dark10,
}

export const ActionButton = styled(layouts.buttons.outline)`
	color: ${layouts.theme.colors.grey.dark50}CC;
	border: none;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0px;
	&:hover {
		background-color: ${layouts.theme.colors.blue.blue};
		color: ${layouts.theme.colors.white};
	}
`

ActionButton.defaultProps = {
	width: "100px",
	height: "35px",
	background: layouts.theme.colors.white,
}

import * as layouts from "layouts"
import * as components from "."
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as icons from "icons"

interface props extends layouts.containers.FlexProps {
	item: brandguide.api.LogoObject
}

export default function ProportionItem(props: props): JSX.Element {
	const { item, ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<img src={item?.svg} alt={item?.id} style={{ maxWidth: `${item?.min_size}pt` }} />
			<layouts.Flex flexDirection="column" {...components.centeredProps}>
				<icons.brandguide.arrows.ArrowLeftRight width={`${item?.min_size}pt`} />
				<typography.h6
					fontSize="12px"
					fontWeight="500"
					color={layouts.theme.colors.grey.dark50alpha80}
					textAlign="center"
				>
					{item!.min_size}px
				</typography.h6>
				<typography.h6
					fontSize="14px"
					fontWeight="600"
					color={layouts.theme.colors.grey.dark50alpha80}
					textAlign="center"
				>
					Min Size
				</typography.h6>
			</layouts.Flex>
		</layouts.Flex>
	)
}

ProportionItem.defaultProps = {
	flex: "1",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	maxHeight: "100%",
}

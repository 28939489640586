import { useState, useEffect } from "react"
import * as brandguide from "brandguide"
import * as res_components from "brandguide/results/components"

interface props {
	itemsOnPage: number
	onLoad(): void
}

export default function Display(props: props): JSX.Element {
	const { itemsOnPage, onLoad } = props

	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = brandguide.api.zeros.guide.guideApplicationZero(guide.guide?.application)

	const sectionDescription =
		"Here you will find examples of pieces of content that we have published. Please use these as examples to further understand our brand guidelines and how to use them in real world scenarios."
	const items = res_components.chunkImageItems(data.items || [], itemsOnPage)
	const [loadedImages, setLoadedImages] = useState(items.length)

	useEffect(() => {
		if (loadedImages !== 0) return
		onLoad()
	}, [loadedImages])

	return (
		<>
			{items.map((i, index) => {
				const firstPage = index === 0
				const pageTitle = firstPage ? "Application" : undefined
				const sectionDesc = firstPage ? sectionDescription : undefined
				const explanation = firstPage ? data.descriptions?.upload : undefined
				return (
					<res_components.ImagesPage
						key={index}
						items={i}
						sectionTitle="Application"
						sectionDescription={sectionDesc}
						pageTitle={pageTitle}
						explanation={explanation}
						onLoad={() => setLoadedImages((prev) => prev - 1)}
						className="section-print"
					/>
				)
			})}
		</>
	)
}

Display.defaultProps = {
	itemsOnPage: 9,
}

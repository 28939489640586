import * as caching from "caching"
import * as timex from "timex"
import * as api from "brandguard/api"
import { CancellablePromise } from "real-cancellable-promise"

export const cache = new caching.Cache({
	namespace: "novacloud.training",
	ttl: timex.duration.iso("PT1H").toMillis(),
})

export namespace uploads {
	export namespace text {
		interface cached extends api.TextSearchResponseItem {
			description: string
		}

		export function get(key: string): cached[] {
			return cache.get(key) || []
		}

		export function getasync(key: string): CancellablePromise<cached[]> {
			return new CancellablePromise(new Promise((resolve) => resolve(get(key))), () => {})
		}

		export function add(key: string, items: cached[]): cached[] {
			return cache.set(key, [...get(key), ...items])
		}

		export function remove(key: string, items: cached[]): cached[] {
			if (items.length === 0) return get(key)
			return cache.set(
				key,
				get(key).filter((i) => !items.find((e) => e.media?.id === i.media?.id)),
			)
		}
	}

	export namespace img {
		export function get(key: string): api.ImageSearchResponseItem[] {
			return cache.get(key) || []
		}

		export function getasync(key: string): CancellablePromise<api.ImageSearchResponseItem[]> {
			return new CancellablePromise(new Promise((resolve) => resolve(get(key))), () => {})
		}

		export function add(key: string, items: api.ImageSearchResponseItem[]): api.ImageSearchResponseItem[] {
			return cache.set(key, [...get(key), ...items])
		}

		export function remove(key: string, items: api.ImageSearchResponseItem[]): api.ImageSearchResponseItem[] {
			return cache.set(
				key,
				get(key).filter((i) => !items.find((e) => e.media?.id === i.media?.id)),
			)
		}
	}
}

export default cache

import * as httpx from "httpx"
import * as events from "./events"
import { CancellablePromise } from "real-cancellable-promise"
import {
	ImageSearchRequest,
	ImageSearchResponse,
	ImageFindResponse,
	ImagePatchRequest,
	ImagePatchResponse,
} from "brandguard/brandguard.image_pb"

export namespace requests {
	export function search(d: Partial<ImageSearchRequest> = {}): ImageSearchRequest {
		return {
			offset: 0n,
			queues: [],
			uploaded_by: [],
			deprecated_uploaded_by: "",
			brand_id: "",
			limit: 10n,
			observation: [],
			approved: [],
			...d,
		}
	}
}

export function search(
	bid: string,
	req: ImageSearchRequest,
	...options: httpx.option[]
): CancellablePromise<ImageSearchResponse> {
	return httpx
		.get<ImageSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/approvallog`, req, ...options)
		.then((resp) => ({
			...resp,
			items: (resp.items || []).map((item) => ({ media: item.media, event: events.zero(item.event) })),
		}))
}

export function find(id: string, bid: string, ...options: httpx.option[]): Promise<ImageFindResponse> {
	return httpx
		.get<ImageFindResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/approvallog/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function patch(
	id: string,
	bid: string,
	req: ImagePatchRequest,
	...options: httpx.option[]
): Promise<ImagePatchResponse> {
	return httpx
		.patch<ImagePatchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/approvallog/${id}`, req, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function destroy(bid: string, id: string, ...options: httpx.option[]): Promise<ImagePatchResponse> {
	return httpx
		.destroy<ImagePatchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/approvallog/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

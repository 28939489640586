import React, { useState, useEffect } from "react"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as api from "./api"
import * as brands from "brands"
import * as layouts from "layouts"
import * as errors from "errors"
import * as icons from "icons"
import * as uuid from "uuid"
import classnames from "classnames"
import * as inputs from "inputs"
import * as brandgpt from "brandgpt"
import * as profiles from "profiles"
import * as styleguideApi from "styleguide/api"
import DOMPurify from "dompurify"

function convertMarkdownToSafeHtml(input: string) {
	let parsedText = input
	// checking for headers (#Header)
	const titleRegex = /(#+)(\s)(.*)/g
	parsedText = parsedText.replace(titleRegex, (match, hashes, titleContent) => {
		const level = hashes.length // heading level depends on the number of characters #
		return `<h${level}>${titleContent.trim()}</h${level}>`
	})
	// checking for bold (**text** or __text__)
	const boldRegex = /(\*\*|__)(.*?)\1/g
	parsedText = parsedText.replace(boldRegex, "<b>$2</b>")
	// checking for italic (*text* or _text_)
	const italicRegex = /(\*|_)(.*?)\1/g
	parsedText = parsedText.replace(italicRegex, "<i>$2</i>")
	// Sanitize HTML with DOMPurify
	return DOMPurify.sanitize(parsedText)
}

interface props {
	channel: api.Channel | undefined
	styleguide: styleguideApi.Styleguide | undefined
	selectStyleguidePage(p: number): void
}

export default function Display(props: props): JSX.Element {
	const { channel, styleguide, selectStyleguidePage } = props
	const brand = brands.caching.useCached()
	const bearertoken = sessions.useToken()
	const token = sessions.useGetToken()
	const [loading, setLoading] = useState(false)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [message, setMessage] = useState(undefined as string | undefined)
	const [socket, setSocket] = useState<WebSocket | undefined>(undefined)

	const [mreq, setMreq] = useState({
		channel_id: channel ? channel.id : uuid.NIL,
		offset: 0n,
		limit: 100n,
	} as api.MessagesSearchRequest)

	const [messages, setMessages] = useState({
		next: mreq,
		items: [],
	} as api.MessagesSearchResponse)

	const sg = styleguideApi.zero(styleguide)
	const welcomeMessage =
		sg.welcome_message.length > 0
			? sg.welcome_message
			: `Welcome to the ${brand.description} Icon Design System PDF! Discover how this iconic brand has been elevated to new heights and explore the opportunities it presents.
				Example questions:
				What specific changes have been made to the ${brand.description} icon design system?
				How does the new system provide greater flexibility for the brand?
				Can you provide examples of how the system has been implemented in real-world marketing campaigns?`

	useEffect(() => {
		if (!channel) return
		setMreq((prevState) => ({ ...prevState, channel_id: channel.id }))
	}, [channel])

	useEffect(() => {
		if (mreq.channel_id === uuid.NIL) return

		setLoading(true)
		const pending = api.messages
			.search(brand.id, mreq, bearertoken)
			.then((messages) => {
				setMessages(messages)
				setLoading(false)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline>
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve messages</errors.Textual>
					</errors.Inline>,
				)
				setLoading(false)
			})
		return pending.cancel
	}, [mreq])

	function openWs(): WebSocket {
		const ws = api.channels.ws(brand.id, channel!.id, token)
		ws.onopen = () => console.log("connection open")
		ws.onclose = (c) => {
			console.log("connection close", c)
			if (c.code !== 1000) {
				setSocket(openWs())
			}
		}

		ws.onmessage = (e) => {
			const message = api.messageZero(JSON.parse(e.data))
			setMessages((prevState) => ({
				...prevState,
				items: !prevState.items.some((element) => element.id === message?.id)
					? [...[message!], ...prevState.items]
					: prevState.items.map((element) => (element.id === message?.id ? message : element)),
			}))
		}
		return ws
	}

	useEffect(() => {
		if (!channel?.id) return
		const ws = openWs()
		setSocket(ws)
		return () => {
			ws.close(1000)
		}
	}, [channel])

	function sendMessage(): void {
		if (!message || !socket) return
		socket!.send(
			JSON.stringify(api.messageZero({ channel_id: channel?.id, body: message, direction: api.Direction.USER })),
		)
		setMessage(undefined)
	}

	if (!channel)
		return (
			<layouts.loading.pending loading={loading}>
				<layouts.containers.flex flexDirection="column" flex="1">
					<layouts.containers.flex flexDirection="row" p="20px">
						<layouts.containers.flex>
							<icons.brandgpt.boticon pr="10px" />
						</layouts.containers.flex>
						<layouts.containers.flex flex="1" mt="15px">
							<layouts.containers.flex background={layouts.theme.colors.white} borderRadius="0 20px 20px 20px" flex="1">
								<layouts.containers.span p="20px" fontSize="16px" fontWeight="400" lineHeight="26px">
									<brandgpt.layouts.TypeWriter fullText="Please, create or select channel." />
								</layouts.containers.span>
							</layouts.containers.flex>
						</layouts.containers.flex>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.loading.pending>
		)

	const _zero = (
		<layouts.containers.flex flexDirection="row" p="20px">
			<layouts.containers.flex>
				<icons.brandgpt.boticon pr="10px" />
			</layouts.containers.flex>
			<layouts.containers.flex flex="1" mt="15px">
				<layouts.containers.flex background={layouts.theme.colors.white} borderRadius="0 20px 20px 20px">
					<layouts.containers.span p="20px" fontSize="14px" fontWeight="400" lineHeight="26px">
						<brandgpt.layouts.TypeWriter fullText={welcomeMessage} speed={25} />
					</layouts.containers.span>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)

	function DisplayMessage(props: { message: api.Message; selectStyleguidePage(p: number): void }): JSX.Element {
		const { message } = props
		const page_numbers = message.page_number.filter((p) => p > 0)

		if (message.direction === api.Direction.USER) return <UserMessage message={message} />
		return (
			<layouts.containers.flex flexDirection="row" px="20px" py="10px">
				<layouts.containers.flex>
					<icons.brandgpt.boticon pr="10px" />
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" mt="15px">
					<layouts.containers.flex background={layouts.theme.colors.white} borderRadius="0 20px 20px 20px">
						<layouts.containers.span p="20px" fontSize="14px" fontWeight="400" lineHeight="26px">
							<brandgpt.layouts.Par dangerouslySetInnerHTML={{ __html: convertMarkdownToSafeHtml(message.body) }} />
							<brandgpt.layouts.Par>
								<layouts.containers.span fontWeight="500">
									{page_numbers.length > 0 && "[ Sources: "}
									{page_numbers.map((p, i) => (
										<brandgpt.layouts.Page
											key={i}
											onClick={() => {
												selectStyleguidePage(p)
											}}
										>
											{p}
										</brandgpt.layouts.Page>
									))}
									{page_numbers.length > 0 && "]"}
								</layouts.containers.span>
								<brandgpt.layouts.Par></brandgpt.layouts.Par>
							</brandgpt.layouts.Par>
						</layouts.containers.span>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		)
	}

	return (
		<layouts.loading.pending loading={loading}>
			<layouts.containers.flex className="messages-area" flex="1" overflow="hidden" flexDirection="column">
				{cause}
				<layouts.containers.flex
					flexDirection="column-reverse"
					flex="1"
					overflowY="auto"
					className={classnames(brandgpt.layouts.styledscroll)}
				>
					{messages.items.length === 0 && _zero}
					{messages.items.map((m, i) => (
						<DisplayMessage key={m.id} message={m} selectStyleguidePage={selectStyleguidePage} />
					))}
				</layouts.containers.flex>
				<layouts.containers.flex p="10px" alignItems="flex-end">
					<inputs.Text
						className={classnames(brandgpt.layouts.messageInput)}
						p="0"
						pl="10px"
						pr="35px"
						borderRadius="5px"
						height="37px"
						defaultValue={message}
						onChange={(evt) => setMessage(evt.currentTarget.value)}
						onKeyDown={(evt) => {
							if (evt.key !== "Enter") return
							sendMessage()
							evt.currentTarget.value = ""
						}}
						autoFocus
						icon={
							<layouts.containers.absolute right="0" p="10px">
								<icons.brandgpt.inputarrow cursor="pointer" onClick={sendMessage} />
							</layouts.containers.absolute>
						}
					/>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.loading.pending>
	)
}

export function UserMessage(props: { message: api.Message }): JSX.Element {
	const { message } = props
	const bearertoken = sessions.useToken()
	const [creator, setCreator] = useState(profiles.missing)

	useEffect(() => {
		profiles.caching
			.current(message.profile_id, bearertoken)
			.then(setCreator)
			.catch(
				httpx.errors.notFound(() => {
					// ignore 404s
				}),
			)
	}, [])

	return (
		<layouts.containers.flex flexDirection="row" px="20px" py="10px">
			<layouts.containers.flex flex="1" mt="15px" justifyContent="flex-end">
				<layouts.containers.flex background={layouts.theme.colors.white} borderRadius="20px 0 20px 20px">
					<layouts.containers.span p="20px" fontSize="14px" fontWeight="400" lineHeight="26px">
						<brandgpt.layouts.Par>{message.body}</brandgpt.layouts.Par>
					</layouts.containers.span>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<profiles.Avatar profile={creator} />
		</layouts.containers.flex>
	)
}

export function PromoStyleguideMessage(): JSX.Element {
	return (
		<layouts.containers.flex flexDirection="column" flex="1">
			<layouts.containers.flex flexDirection="row" p="20px">
				<layouts.containers.flex>
					<icons.brandgpt.boticon pr="10px" />
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" mt="15px">
					<layouts.containers.flex background={layouts.theme.colors.white} borderRadius="0 20px 20px 20px">
						<layouts.containers.span p="20px" fontSize="14px" fontWeight="400" lineHeight="26px">
							<brandgpt.layouts.TypeWriter fullText="Welcome to the BrandGPT. Please upload your styleguide in PDF Format." />
						</layouts.containers.span>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flex="1" p="10px" alignItems="flex-end">
				<inputs.Text
					className={classnames(brandgpt.layouts.messageInput)}
					padding="0px 10px"
					borderRadius="5px"
					height="37px"
					disabled
					icon={
						<layouts.containers.absolute right="0" p="10px">
							<icons.brandgpt.inputarrow disabled />
						</layouts.containers.absolute>
					}
				/>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function ForbiddenMessage(): JSX.Element {
	return (
		<layouts.containers.flex flexDirection="column" flex="1">
			<layouts.containers.flex flexDirection="row" p="20px">
				<layouts.containers.flex>
					<icons.brandgpt.boticon pr="10px" />
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" mt="15px">
					<layouts.containers.flex background={layouts.theme.colors.white} borderRadius="0 20px 20px 20px">
						<layouts.containers.span p="20px" fontSize="14px" fontWeight="400" lineHeight="26px">
							<brandgpt.layouts.TypeWriter fullText="Oops! Looks like you do not have access to upload your brand guidelines. Please contact your admin to give you access." />
						</layouts.containers.span>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flex="1" p="10px" alignItems="flex-end">
				<inputs.Text
					className={classnames(brandgpt.layouts.messageInput)}
					padding="0px 10px"
					borderRadius="5px"
					height="37px"
					disabled
					icon={
						<layouts.containers.absolute right="0" p="10px">
							<icons.brandgpt.inputarrow disabled />
						</layouts.containers.absolute>
					}
				/>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

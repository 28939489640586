import * as brandguide from "brandguide"
import * as uuid from "uuid"
import * as colorsx from "colorsx"
import { usages } from "brandguide/build/logos/layouts"
import * as icons from "icons"
import * as b64 from "js-base64"

export namespace initial {
	export namespace colors {
		export const black = () =>
			brandguide.api.zeros.colors.colorObjectZero({
				color: brandguide.api.zeros.colors.colorZero({ hex_code: "#000000", name: "black" }),
				metadata: brandguide.api.zeros.colors.metadataZero({ id: uuid.v4(), default: true }),
			})

		export const white = () =>
			brandguide.api.zeros.colors.colorObjectZero({
				color: brandguide.api.zeros.colors.colorZero({ hex_code: "#FFFFFF", name: "white" }),
				metadata: brandguide.api.zeros.colors.metadataZero({ id: uuid.v4(), default: true }),
			})

		export const correct = (
			primary_colors: brandguide.api.ColorObject[],
			secondary_colors: brandguide.api.ColorObject[],
		) => {
			const colors = [...primary_colors, ...secondary_colors]
			const uniq = [...new Set(colors.map((c) => c.color!.hex_code))]
			const pairs = colorsx.getTopContrastPairs(uniq)
			return pairs.map((p) => {
				const bgname = colors.find((c) => c.color?.hex_code === p[0])?.color?.name || colorsx.getClosestColorName(p[0])
				const textname =
					colors.find((c) => c.color?.hex_code === p[1])?.color?.name || colorsx.getClosestColorName(p[1])
				return brandguide.api.zeros.colors.colorContrastObjectZero({
					background: brandguide.api.zeros.colors.colorZero({ hex_code: p[0], name: bgname }),
					text: brandguide.api.zeros.colors.colorZero({ hex_code: p[1], name: textname }),
					metadata: brandguide.api.zeros.colors.metadataZero({ id: uuid.v4(), default: true }),
				})
			})
		}

		export const incorrect = (
			primary_colors: brandguide.api.ColorObject[],
			secondary_colors: brandguide.api.ColorObject[],
		) => {
			const colors = [...primary_colors, ...secondary_colors]
			const uniq = [...new Set(colors.map((c) => c.color!.hex_code))]
			const pairs = colorsx.getLowContrastPairs(uniq)
			return pairs.map((p) => {
				const bgname = colors.find((c) => c.color?.hex_code === p[0])?.color?.name || colorsx.getClosestColorName(p[0])
				const textname =
					colors.find((c) => c.color?.hex_code === p[1])?.color?.name || colorsx.getClosestColorName(p[1])
				return brandguide.api.zeros.colors.colorContrastObjectZero({
					background: brandguide.api.zeros.colors.colorZero({
						hex_code: p[0],
						name: bgname,
					}),
					text: brandguide.api.zeros.colors.colorZero({ hex_code: p[1], name: textname }),
					metadata: brandguide.api.zeros.colors.metadataZero({ id: uuid.v4(), default: true }),
				})
			})
		}
	}

	export namespace logos {
		function getContrastColor(hex: string): string {
			const rainbowColors = ["#ff0000", "#ff7f00", "#ffff00", "#00ff00", "#0000ff", "#4b0082", "#8b00ff"]
			let maxContrast = 0
			let contrastColor = ""
			rainbowColors.forEach((color) => {
				const contrast = getContrast(hex, color)
				if (contrast > maxContrast) {
					maxContrast = contrast
					contrastColor = color
				}
			})
			return contrastColor
		}

		function getLuminance(hex: string): number {
			const rgb = hex
				.slice(1)
				.match(/.{2}/g)!
				.map((c) => parseInt(c, 16))
			return rgb
				.map((c) => {
					const cc = c / 255
					return cc <= 0.03928 ? cc / 12.92 : ((cc + 0.055) / 1.055) ** 2.4
				})
				.reduce((a, b) => a + b * (b === Math.max(...rgb) ? 0.15 : 0.05), 0)
		}

		function getContrast(hex1: string, hex2: string): number {
			const lum1 = getLuminance(hex1)
			const lum2 = getLuminance(hex2)
			return lum1 > lum2 ? (lum1 + 0.05) / (lum2 + 0.05) : (lum2 + 0.05) / (lum1 + 0.05)
		}

		const getLeastContrastColor = (hex: string): string => {
			if (hex.length === 9) {
				const alphaDecimal = parseInt(hex.slice(7, 9), 16)
				const newAlphaDecimal = Math.floor(alphaDecimal * 0.5)
				const newAlphaHex = newAlphaDecimal.toString(16).padStart(2, "0")
				return hex.slice(0, 7) + newAlphaHex
			}
			return hex + "CC"
		}

		interface constracts {
			top: string
			least: string
		}

		const getContrasts = (svg: string): constracts => {
			const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
			const colors = icons.svg.getColorsFromSvg(svgElement)
			const top_color = icons.svg.getTopColors(colors)
			const hex = colorsx.convertColorToHex(top_color[0])

			return { top: getContrastColor(hex), least: getLeastContrastColor(hex) }
		}
		export const usage = (svg: string): brandguide.api.LogoUsage[] => {
			return [
				{
					id: usages.HIGH_CONTRAST,
					title: "Do use high contrast color combinations",
					use: true,
					color: { hex_code: getContrasts(svg).top, name: "" },
				},
				{
					id: usages.POOR_CONTRAST,
					title: "Do not use poor color combinations",
					use: false,
					color: { hex_code: getContrasts(svg).least, name: "" },
				},
				{
					id: usages.CORRECT_CLEARSPASE,
					title: "Do use correct clearspace with text & page edge",
					use: true,
				},
				{
					id: usages.INCORRECT_CLEARSPASE,
					title: "Do not place too close to text or page edge",
					use: false,
				},
			]
		}

		const decoder = (svgElement: SVGSVGElement): string => {
			const serializer = new XMLSerializer()
			const rotatedSvgString = serializer.serializeToString(svgElement)
			return icons.svg.prefix + b64.btoa(rotatedSvgString)
		}

		const DoNotRotate = (svg: string): brandguide.api.LogoMisuse => {
			const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
			const bbox = svgElement.getBBox()
			const cx = bbox.x + bbox.width / 2
			const cy = bbox.y + bbox.height / 2
			svgElement.setAttribute("transform", `rotate(180, ${cx}, ${cy})`)
			const result = decoder(svgElement)
			return { title: "Do not rotate", svg: result }
		}

		const DoNotAlterColors = (svg: string): brandguide.api.LogoMisuse => {
			const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
			if (svgElement.hasAttribute("style")) {
				svgElement.removeAttribute("style")
			}
			const defsElement = svgElement.querySelector("defs")
			if (defsElement) {
				svgElement.removeChild(defsElement)
			}
			const paths = svgElement.querySelectorAll("path")
			paths.forEach((path) => {
				const color = "#" + Math.floor(Math.random() * 16777215).toString(16)
				path.setAttribute("fill", color)
			})
			const result = decoder(svgElement)
			return { title: "Do not alter colors", svg: result }
		}

		const DoNotAddEffects = (svg: string): brandguide.api.LogoMisuse => {
			const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
			const shadowFilter = document.createElementNS("http://www.w3.org/2000/svg", "filter")
			shadowFilter.setAttribute("id", "shadow")
			shadowFilter.innerHTML = "<feDropShadow dx='1' dy='1' stdDeviation='0.5' />"
			svgElement.appendChild(shadowFilter)

			const paths = svgElement.querySelectorAll("path")
			paths.forEach((path) => {
				path.setAttribute("filter", "url(#shadow)")
			})
			const result = decoder(svgElement)
			return { title: "Do not add effects", svg: result }
		}

		const DoNotDistort = (svg: string): brandguide.api.LogoMisuse => {
			const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
			const innerElements = svgElement.querySelectorAll("*")
			innerElements.forEach((element) => {
				element.setAttribute("transform", "skewX(10)")
				element.setAttribute("transform", "skewY(-10)")
			})
			const result = decoder(svgElement)
			return { title: "Do not distort", svg: result }
		}

		const DoNotOutline = (svg: string): brandguide.api.LogoMisuse => {
			const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
			const paths = svgElement.getElementsByTagName("path")
			for (let i = 0; i < paths.length; i++) {
				paths[i].setAttribute("stroke", "black")
				paths[i].setAttribute("stroke-width", "1")
			}
			const result = decoder(svgElement)
			return { title: "Do not outline", svg: result }
		}

		const DoNotChangeComposition = (svg: string): brandguide.api.LogoMisuse => {
			const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
			const paths = svgElement.getElementsByTagName("path")
			const half = Math.ceil(paths.length / 2)
			for (let i = 0; i < half; i++) {
				const bbox = paths[i].getBBox()
				const cx = bbox.x + bbox.width / 2
				const cy = bbox.y + bbox.height / 2
				paths[i].setAttribute("transform", `rotate(90, ${cx}, ${cy})`)
			}
			const result = decoder(svgElement)
			return { title: "Do not change composition", svg: result }
		}

		export const misuse = (svg: string): brandguide.api.LogoMisuse[] => {
			return [
				DoNotRotate(svg),
				DoNotAlterColors(svg),
				DoNotAddEffects(svg),
				DoNotDistort(svg),
				DoNotOutline(svg),
				DoNotChangeComposition(svg),
			]
		}
	}

	export namespace typography {
		export const usage = (fonts: brandguide.api.FontObject[]): brandguide.api.TypographyObject[] => {
			const fontIds = fonts.map((f) => f.id)
			return [
				brandguide.api.zeros.typography.itemZero({
					id: uuid.v4(),
					font_id: "primary",
					title: "H1",
					size: 32,
				}),
				brandguide.api.zeros.typography.itemZero({
					id: uuid.v4(),
					font_id: fontIds[Math.floor(Math.random() * fontIds.length)],
					title: "H2",
					size: 24,
					bold: true,
				}),
				brandguide.api.zeros.typography.itemZero({
					id: uuid.v4(),
					font_id: fontIds[Math.floor(Math.random() * fontIds.length)],
					title: "H3",
					size: 16,
				}),
				brandguide.api.zeros.typography.itemZero({
					id: uuid.v4(),
					font_id: fontIds[Math.floor(Math.random() * fontIds.length)],
					title: "Body",
					size: 14,
				}),
			]
		}
	}
	export namespace imagery {
		export const misuse = (): brandguide.api.ImageryObject[] => {
			return [
				brandguide.api.zeros.imagery.objectZero({ id: uuid.v4() }),
				brandguide.api.zeros.imagery.objectZero({ id: uuid.v4() }),
				brandguide.api.zeros.imagery.objectZero({ id: uuid.v4() }),
			]
		}

		export const fundamentals = (): brandguide.api.ImageryFundamentalObject[] => {
			return [
				brandguide.api.zeros.imagery.fundamentalObjectZero({
					id: "color_correction",
					name: "Color Correction",
					items: [
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Bold & Bright", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Muted", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Monochromatic", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Black & White", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Warm & Rich", is_default: true }),
					],
				}),
				brandguide.api.zeros.imagery.fundamentalObjectZero({
					id: "tone",
					name: "Tone",
					items: [
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Authentic", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Friendly", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Serious", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Informative", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Controversial", is_default: true }),
					],
				}),
				brandguide.api.zeros.imagery.fundamentalObjectZero({
					id: "subject",
					name: "Subject",
					items: [
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "One Person", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Group of People", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "No People", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Animals Only", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Animals & People", is_default: true }),
					],
				}),
				brandguide.api.zeros.imagery.fundamentalObjectZero({
					id: "nudity",
					name: "Nudity",
					items: [
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Fully Clothed", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Swimwear", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Sportswear", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "Lingerie", is_default: true }),
						brandguide.api.zeros.imagery.fundamentalItemZero({ name: "X-Rated", is_default: true }),
					],
				}),
			]
		}
	}

	export namespace tone_of_voice {
		const examples = {
			ONE: "Example One",
			TWO: "Example Two",
			THREE: "Example Three",
			FOUR: "Example Four",
		}
		export const tone_of_voice = (): brandguide.api.ToneOfVoiceObject[] => {
			return [
				{
					id: uuid.v4(),
					title: examples.ONE,
					text: "We apologize, but we are experiencing a problem.",
					description: "Formal, serious, respectful, & matter-of-fact.",
					selected: false,
				},
				{
					id: uuid.v4(),
					title: examples.TWO,
					text: "We're sorry, but we're experiencing a problem on our end.",
					description: "Serious, respectful, & matter-of-fact, but a bit more casual.",
					selected: false,
				},
				{
					id: uuid.v4(),
					title: examples.THREE,
					text: "Oops! We're sorry, but we're experiencing a problem on our end.",
					description: "More casual & enthusiastic.",
					selected: false,
				},
				{
					id: uuid.v4(),
					title: examples.FOUR,
					text: "What did you do! You broke it! (Just kidding. We're experiencing a problem on our end.)",
					description: "Much more casual, funny, irreverent, & enthusiastic.",
					selected: false,
				},
			]
		}
		interface adjectives {
			positive_adjectives: brandguide.api.ToneOfVoiceAdjective[]
			negative_adjectives: brandguide.api.ToneOfVoiceAdjective[]
		}
		function defaultAdjectives(selected: string): adjectives {
			switch (selected) {
				case examples.ONE:
					return {
						positive_adjectives: [
							{ id: uuid.v4(), name: "Formal" },
							{ id: uuid.v4(), name: "Serious" },
							{ id: uuid.v4(), name: "Respectful" },
							{ id: uuid.v4(), name: "Matter-of-fact" },
						],
						negative_adjectives: [
							{ id: uuid.v4(), name: "Informal" },
							{ id: uuid.v4(), name: "Casual" },
							{ id: uuid.v4(), name: "Irreverent" },
							{ id: uuid.v4(), name: "Overly Enthusiastic" },
						],
					}
				case examples.TWO:
					return {
						positive_adjectives: [
							{ id: uuid.v4(), name: "Serious" },
							{ id: uuid.v4(), name: "Respectful" },
							{ id: uuid.v4(), name: "Matter-of-fact" },
							{ id: uuid.v4(), name: "A bit more casual" },
						],
						negative_adjectives: [
							{ id: uuid.v4(), name: "Irreverent" },
							{ id: uuid.v4(), name: "Overly Enthusiastic" },
							{ id: uuid.v4(), name: "Formal" },
						],
					}
				case examples.THREE:
					return {
						positive_adjectives: [
							{ id: uuid.v4(), name: "Casual" },
							{ id: uuid.v4(), name: "Enthusiastic" },
						],
						negative_adjectives: [
							{ id: uuid.v4(), name: "Formal" },
							{ id: uuid.v4(), name: "Serious" },
							{ id: uuid.v4(), name: "Matter-of-fact" },
							{ id: uuid.v4(), name: "Irreverent" },
						],
					}
				case examples.FOUR:
					return {
						positive_adjectives: [
							{ id: uuid.v4(), name: "More Casual" },
							{ id: uuid.v4(), name: "Funny" },
							{ id: uuid.v4(), name: "Irreverent" },
							{ id: uuid.v4(), name: "Enthusiastic" },
						],
						negative_adjectives: [
							{ id: uuid.v4(), name: "Formal" },
							{ id: uuid.v4(), name: "Serious" },
							{ id: uuid.v4(), name: "Matter-of-fact" },
						],
					}
				default:
					return { positive_adjectives: [], negative_adjectives: [] }
			}
		}

		export const adjectives = (data: brandguide.api.GuideToneOfVoice): brandguide.api.GuideToneOfVoice => {
			return { ...data, ...defaultAdjectives(data.tone_of_voice[0].title) }
		}
	}

	export namespace compliance {
		export const selecting = (): brandguide.api.ComplianceItem[] => {
			return [
				{
					id: uuid.v4(),
					title: "Prescription Drugs",
					info: "Prescription drug advertising is regulated by the US Food and Drug Administration (FDA) in the USA.",
					names: [] as brandguide.api.ComplianceName[],
				},
				{
					id: uuid.v4(),
					title: "OTC (Over the Counter)",
					info: "Over-the-counter (non-prescription) drugs advertising is overseen by the Federal Trade Commission (FTC) in the USA.",
					names: [] as brandguide.api.ComplianceName[],
				},
				{
					id: uuid.v4(),
					title: "Finance",
					info: "Financial advertising is regulated by a government entity, such as the Financial Industry Regulatory Authority (FINRA) and Consumer Financial Protection Bureau (CFPB).",
					names: [] as brandguide.api.ComplianceName[],
				},
				{
					id: uuid.v4(),
					title: "Marketing to Children",
					info: "Self-regulated and National Advertising Review Council's Children's Advertising Review Unit (“CARU”) program is designed to promote responsible children's advertising. The Children's Online Privacy Protection Act of 1998 (COPPA) was established under the authority of the FTC to set guidelines with regards to consent and personal information of minors.",
					names: [] as brandguide.api.ComplianceName[],
				},
				{
					id: uuid.v4(),
					title: "Food & Bev - Alcohol",
					info: "Alcohol beverage advertising is regulated by the Federal Alcohol Administration Act (FAA Act)  giving authority to the Alcohol and Tobacco Tax and Trade Bureau (TTB).",
					names: [] as brandguide.api.ComplianceName[],
				},
				{
					id: uuid.v4(),
					title: "Food & Bev - FDA",
					info: "Write up here on the specific segment that explains the rules in a succinct manner. The space here is all we can give them but if we need to link out for further explanation we can do so with a link here.",
					names: [] as brandguide.api.ComplianceName[],
				},
				{
					id: uuid.v4(),
					title: "Gaming",
					info: "Gaming advertising is regulated by the gaming authority and statutes in its jurisdiction; however, general rules pertaining to gambling advertising apply across state lines as well.",
					names: [] as brandguide.api.ComplianceName[],
				},
				{
					id: uuid.v4(),
					title: "Tobacco",
					info: "Tobacco advertising is regulated by the Food and Drug Administration (FDA).",
					names: [] as brandguide.api.ComplianceName[],
				},
			]
		}
	}
}

export namespace helps {
	export namespace colors {
		export const tiles = [
			{
				title: "Red",
				text: "Love, warmth, courage passion, spontaneous, anger, & even danger",
				color: "#FFF",
				background: "#D60034",
			},
			{
				title: "Orange",
				text: "Kindness, joy, innovation, energy, & fun",
				color: "#FFF",
				background: "#E38300",
			},
			{
				title: "Yellow",
				text: "Optimism, hospitality, tranquility, & creativity",
				color: "#353E4E",
				background: "#FAD700",
			},
			{
				title: "Green",
				text: "Love, warmth, courage passion, spontaneous, anger, and even danger",
				color: "#FFF",
				background: "#009E33",
			},
			{
				title: "Blue",
				text: "Strength, confidence, Intelligence, cold/cool, calm, serenity, & rest",
				color: "#FFF",
				background: "#0098B8",
			},
			{
				title: "Purple",
				text: "Mystery, eternity, eccentricity, luxury, fashion, & sophistication",
				color: "#FFF",
				background: "#740682",
			},
			{
				title: "Pink",
				text: "Illusion, reverie, tender, delicacy, sweet, courtesy, & charm",
				color: "#FFF",
				background: "#D2007C",
			},
			{
				title: "Brown",
				text: "Cozy, warm, stable, comfortable, rustic, ordinary, & bitter",
				color: "#FFF",
				background: "#7E3C16",
			},
			{
				title: "Black",
				text: "Elegance, authority, sobriety, respect, & strength",
				color: "#FFF",
				background: "#0D0D0D",
			},
			{
				title: "Gray",
				text: "Security, maturity, & reliability",
				color: "#FFF",
				background: "#797A75",
			},
		]
	}
}

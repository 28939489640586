import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as icons from "icons"
import * as debugx from "x/debugx"
import * as sessions from "sessions"
import * as api from "./api"
import * as cache from "./cache"
import noImage from "images/no-image.svg"

interface imageProps {
	media: api.Media
	onClick?(media: api.Media, url: string): void
	onDestroyClick?(e: React.MouseEvent<HTMLDivElement, MouseEvent>, media_id: string): void
	noImage: JSX.Element
}

const StyledDescription = styled(layouts.containers.flex)`
	height: 12px;
	font-weight: 700;
	font-size: 8px;
	line-height: 12px;
	color: ${layouts.theme.colors.grey.dark5};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const DestroyContainer = styled(layouts.containers.flex)`
	transform: rotate(180deg);
	margin-top: -7px;
`

const DismissIcon = styled(icons.checkboxes.dismiss)`
	fill: ${(props) => props.fill};
	&:hover {
		fill: ${layouts.theme.colors.blue.blue};
	}
	&:hover path {
		stroke: white;
	}
`

export function Image(props: React.PropsWithChildren<imageProps> & layouts.containers.FlexProps): JSX.Element {
	const { media, onClick, onDestroyClick, noImage, children, ...rest } = props
	const [response, setResponse] = useState({} as api.RedirectResponse)
	const [loading, setLoading] = useState(false)
	const account = sessions.useAccount()
	const bearertoken = sessions.useToken()
	useEffect(() => {
		setLoading(true)
		cache
			.current(account.id, media.content_digest, bearertoken)
			.then((url) => setResponse({ url: url }))
			.catch((cause) => console.error("unable to load image", cause))
			.finally(() => setLoading(false))
	}, [media.content_digest])

	const i = response.url ? (
		<layouts.images.Base
			width="100%"
			height="auto"
			maxWidth="100%"
			maxHeight="100%"
			m="auto"
			alt={media.description}
			src={response.url}
		/>
	) : (
		noImage || <layouts.containers.flex className="zero" width="100%" height="auto" />
	)

	const destroyImage =
		debugx.alpha.enabled() && onDestroyClick ? (
			<DestroyContainer className="destroy-image">
				<layouts.containers.box onClick={(e) => onDestroyClick(e, media.id)}>
					<DismissIcon fill={layouts.theme.colors.grey.dark10} />
				</layouts.containers.box>
			</DestroyContainer>
		) : (
			<></>
		)

	return (
		<layouts.containers.flex
			flexDirection="column"
			overflow="hidden"
			flex="1"
			onClick={onClick ? () => onClick(media, response.url) : undefined}
			{...rest}
		>
			<layouts.loading.screen loading={loading} flex="1" icon={<></>} height="100%">
				{i}
				{media.description ? (
					<layouts.containers.flex justifyContent="space-between">
						<StyledDescription className="description" mx="auto">
							{media.description}
						</StyledDescription>
						{destroyImage}
					</layouts.containers.flex>
				) : (
					<layouts.containers.flex justifyContent="flex-end">{destroyImage}</layouts.containers.flex>
				)}
			</layouts.loading.screen>
			{children}
		</layouts.containers.flex>
	)
}

Image.defaultProps = {
	noImage: <layouts.images.Base width="100%" height="auto" alt="" src={noImage} />,
}

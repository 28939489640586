import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as colorx from "colorsx"
import { Link } from "react-router-dom"
import * as brands from "brands"
import { results } from "brandguide/build/layouts"
import * as navigation from "brandguide/build/navigation"
import * as authz from "authz"
import * as authzc from "authzcached"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideColors | undefined): void
}

export function SectionResult(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const cache = brandguide.cache.useCached()
	const data = brandguide.api.zeros.guide.guideColorsZero(cache.guide?.guide?.colors)

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	return (
		<layouts.Flex flex="1" {...rest}>
			<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
				<layouts.Flex alignItems="center">
					<results.Title>Color Palette</results.Title>
				</layouts.Flex>
				<results.ResultDesc>
					Color is a valuable asset for the brand identity. It helps us gain recognition through consistent use of
					color.
				</results.ResultDesc>

				<results.SectionContainer>
					<results.Title>Primary Color Palette</results.Title>
					<results.Explanation desc={data.descriptions?.primary_colors} />

					<layouts.containers.grid
						width="100%"
						my="20px"
						gridGap="50px"
						gridTemplateColumns="repeat(auto-fill, 150px)"
						gridAutoRows="min-content"
						style={{ alignContent: "start", alignItems: "start" }}
					>
						{data.primary_colors.map((item, index) => (
							<Card key={index} item={item} />
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Secondary Color Palette</results.Title>
					<results.Explanation desc={data.descriptions?.secondary_colors} />
					<layouts.containers.grid
						width="100%"
						my="20px"
						gridGap="50px"
						gridTemplateColumns="repeat(auto-fill, 150px)"
						gridAutoRows="min-content"
						style={{ alignContent: "start", alignItems: "start" }}
					>
						{data.secondary_colors.map((item, index) => (
							<Card key={index} item={item} />
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Correct Color Usage</results.Title>
					<results.Explanation desc={data.descriptions?.correct_color_contrasts} />

					<layouts.containers.grid
						width="100%"
						my="20px"
						gridGap="50px"
						gridTemplateColumns="repeat(auto-fill, 250px)"
						gridAutoRows="min-content"
						style={{ alignContent: "start", alignItems: "start" }}
					>
						{data.correct_color_contrasts.map((item, index) => (
							<colors.layouts.ColorWithTextItem
								key={index}
								item={item}
								onChange={() => undefined}
								onDelete={() => undefined}
								readonly
								correct
							/>
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Incorrect Color Usage</results.Title>
					<results.Explanation desc={data.descriptions?.incorrect_color_contrasts} />

					<layouts.containers.grid
						width="100%"
						my="20px"
						gridGap="50px"
						gridTemplateColumns="repeat(auto-fill, 250px)"
						gridAutoRows="min-content"
						style={{ alignContent: "start", alignItems: "start" }}
					>
						{data.incorrect_color_contrasts.map((item, index) => (
							<colors.layouts.ColorWithTextItem
								key={index}
								item={item}
								onChange={() => undefined}
								onDelete={() => undefined}
								readonly
							/>
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<authz.Protected enabled={permission.brandguide_edit}>
					<layouts.Flex justifyContent="center" mt="100px" mb="20px" alignItems="center">
						<NextStageLink guide={cache.guide} className="no-print" />
						<navigation.Section title="Colors" steps={colors.layouts.steps} onChange={(s) => onChange(s, undefined)} />
					</layouts.Flex>
				</authz.Protected>
			</layouts.Flex>
			<colors.Guidelines activPage="colors" className="no-print" />
		</layouts.Flex>
	)
}

function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<layouts.Flex justifyContent="right" mr="47px">
						<brandguide.build.SavePDF />
					</layouts.Flex>
					<SectionResult onChange={onChange} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface cardProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ColorObject
}

function Card(props: cardProps): JSX.Element {
	const { item, ...rest } = props

	const ops = ["E6", "BF", "CC", "99", "66", "33", "00"]
	const rgb = colorx.hexToRgb(item.color!.hex_code)
	const cmyk = colorx.rgbToCmyk(rgb)

	function HEX(): JSX.Element {
		return (
			<layouts.Flex flexDirection="column" mt="10px">
				<typography.h5 fontSize="18px" fontWeight="500" {...textBaseProps}>
					HEX
				</typography.h5>
				<typography.h5 mt="5px" fontSize="18px" fontWeight="400" {...textBaseProps}>
					{item.color!.hex_code}
				</typography.h5>
			</layouts.Flex>
		)
	}

	function RGB(): JSX.Element {
		return (
			<layouts.Flex flexDirection="column" mt="10px">
				<typography.h5 fontSize="18px" fontWeight="500" {...textBaseProps}>
					RGB
				</typography.h5>
				<typography.h5 mt="5px" fontSize="18px" fontWeight="400" {...textBaseProps}>
					{`${rgb?.r} / ${rgb?.g} / ${rgb?.b}`}
				</typography.h5>
			</layouts.Flex>
		)
	}

	function CMYK(): JSX.Element {
		return (
			<layouts.Flex flexDirection="column" mt="10px">
				<typography.h5 fontSize="18px" fontWeight="500" {...textBaseProps}>
					CMYK
				</typography.h5>
				<typography.h5 mt="5px" fontSize="18px" fontWeight="400" {...textBaseProps}>
					{`${cmyk[0]} / ${cmyk[1]} / ${cmyk[2]} / ${cmyk[3]}`}
				</typography.h5>
			</layouts.Flex>
		)
	}

	return (
		<layouts.Flex {...rest}>
			<layouts.Box
				width="135px"
				height="135px"
				background={item.color!.hex_code}
				border={`1px solid ${layouts.theme.colors.grey.dark50alpha05}`}
			/>
			<layouts.Flex my="5px">
				{ops.map((o, index) => (
					<layouts.Box key={index} width="20px" height="20px" my="5px" background={`${item.color!.hex_code}${o}`} />
				))}
			</layouts.Flex>
			<typography.h5 mt="5px" fontSize="18px" fontWeight="500" {...textBaseProps}>
				{item.color?.name}
			</typography.h5>
			<HEX />
			<RGB />
			<CMYK />
		</layouts.Flex>
	)
}

Card.defaultProps = {
	flexDirection: "column",
	width: "150px",
}

const textBaseProps = {
	letterSpacing: "unset",
	lineHeight: "normal",
	color: layouts.theme.colors.grey.dark50,
	whiteSpace: "unset",
}

interface nextProps extends layouts.containers.FlexProps {
	guide: brandguide.api.Brandguide
}

function NextStageLink(props: nextProps): JSX.Element {
	const { guide, ...rest } = props
	const brand = brands.caching.useCached()

	const nextLink = (): string | undefined => {
		if (guide.guide?.logos?.enabled) return brandguide.routing.build.logos(brand.id)
		if (guide.guide?.typography?.enabled) return brandguide.routing.build.typography(brand.id)
		if (guide.guide?.imagery?.enabled) return brandguide.routing.build.imagery(brand.id)
		if (guide.guide?.tone_of_voice?.enabled) return brandguide.routing.build.tone_of_voice(brand.id)
		if (guide.guide?.archetype?.enabled) return brandguide.routing.build.archetype(brand.id)
		if (guide.guide?.compliance?.enabled) return brandguide.routing.build.compliance(brand.id)
		if (guide.guide?.application?.enabled) return brandguide.routing.build.application(brand.id)
		return undefined
	}

	if (!nextLink()) return <></>

	return (
		<layouts.Flex {...rest}>
			<Link to={nextLink()!}>
				<layouts.buttons.unstyled
					width="130px"
					height="34px"
					background={layouts.theme.colors.grey.dark50}
					borderRadius="5px"
					color={layouts.theme.colors.white}
					onClick={() => undefined}
				>
					Next Section
				</layouts.buttons.unstyled>
			</Link>
		</layouts.Flex>
	)
}

NextStageLink.defaultProps = {
	flex: "1",
	justifyContent: "end",
}

export default Display

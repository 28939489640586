// eslint-disable-next-line no-use-before-define
import ReactDOM from "react-dom"
import "./styles/reset.css"
import "./styles/fonts.css"
import App from "./app"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(<App />, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import * as luxon from "luxon"

export { DateTime, Duration } from "luxon"

export function infinity(): luxon.DateTime {
	return luxon.DateTime.utc(9999, 12, 31, 23, 59, 59, 999)
}

export function negInfinity(): luxon.DateTime {
	return luxon.DateTime.utc(0, 1, 1, 1, 1, 1, 0)
}

export function local(): luxon.DateTime {
	return luxon.DateTime.local()
}

export function utc(): luxon.DateTime {
	return luxon.DateTime.now().toUTC()
}

export function ago(d: luxon.Duration): luxon.DateTime {
	return local().minus(d).startOf("day")
}

export namespace duration {
	export function iso(s: string): luxon.Duration {
		return luxon.Duration.fromISO(s)
	}

	export function milliseconds(n: number): luxon.Duration {
		return luxon.Duration.fromMillis(n)
	}

	export function seconds(n: number): luxon.Duration {
		return luxon.Duration.fromDurationLike({ seconds: n })
	}

	export function minutes(n: number): luxon.Duration {
		return luxon.Duration.fromDurationLike({ minutes: n })
	}

	export function hours(n: number): luxon.Duration {
		return luxon.Duration.fromDurationLike({ hours: n })
	}

	export function days(n: number): luxon.Duration {
		return luxon.Duration.fromDurationLike({ days: n })
	}
}

export namespace backoff {
	export function noise(d: luxon.Duration, delta: luxon.Duration = luxon.Duration.fromMillis(200)): luxon.Duration {
		return luxon.Duration.fromMillis(d.toMillis() + delta.toMillis() * Math.random())
	}

	export function sigmoid(n: number, multi: number = 0.001): number {
		return 1 - Math.exp(-1 * multi * n)
	}
}

export namespace from {
	export function date(d: Date): luxon.DateTime {
		return luxon.DateTime.fromJSDate(d)
	}

	export function iso(s: string): luxon.DateTime {
		return luxon.DateTime.fromISO(s)
	}
}

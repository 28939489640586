export function root(bid: string): string {
	return `/brands/${bid}`
}

export function metrics_approvals(bid: string): string {
	return `${root(bid)}/metrics/approvals`
}

export function metrics_training(bid: string): string {
	return `${root(bid)}/metrics/training`
}

export function metrics_brandgpt(bid: string): string {
	return `${root(bid)}/metrics/brandgpt`
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import { css } from "@emotion/css"
import styled from "@emotion/styled"
import * as brands from "brands"
import * as icons from "icons"
import * as api from "brandguide/api"
import * as media from "media"
import * as sessions from "sessions"
import * as authzc from "authzcached"

export function toTitleCase(str: string): string {
	str = str.replace(/_/g, " ")
	return str.replace(/\w\S*/g, function (txt: string): string {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
}

export const disabledButton = css`
	button {
		cursor: pointer;
	}
	button:disabled,
	button[disabled] {
		background-color: ${layouts.theme.colors.grey.dark50alpha20};
		color: ${layouts.theme.colors.grey.dark50alpha80};
	}
`

interface skipProps extends layouts.containers.ContainerProps {
	onSkip(): void
	onNeverHave(): void
}

export function SkipStep(props: skipProps): JSX.Element {
	const { onSkip, onNeverHave, ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" justifyContent="flex-end" pr="30px">
				<layouts.Flex onClick={onSkip}>
					<typography.h4 fontSize="16px" fontWeight="400" color={layouts.theme.colors.grey.dark50}>
						Skip for now
					</typography.h4>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flex="1" pl="30px">
				<layouts.Flex onClick={onNeverHave}>
					<typography.h4 fontSize="16px" fontWeight="400" color={layouts.theme.colors.grey.dark50}>
						Will never have
					</typography.h4>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

export namespace results {
	export const SectionContainer = styled(layouts.Flex)``

	SectionContainer.defaultProps = {
		flexDirection: "column",
		alignItems: "start",
		mt: "32px",
		className: "page-break",
	}

	export const Title = styled(typography.h3)`
		font-size: 24px;
		font-weight: 400;
		color: ${layouts.theme.colors.grey.dark50};
	`

	Title.defaultProps = {
		whiteSpace: "unset",
		letterSpacing: "unset",
	}

	export const ResultDesc = styled(typography.h5)``
	ResultDesc.defaultProps = {
		mt: "32px",
		fontSize: "14px",
		fontWeight: "400",
		letterSpacing: "unset",
		lineHeight: "20px",
		color: layouts.theme.colors.grey.dark50,
		whiteSpace: "unset",
	}

	export function Explanation(props: { desc: string | undefined }): JSX.Element {
		const { desc } = props
		if (!desc) return <></>
		return (
			<typography.h5
				my="5px"
				fontSize="14px"
				fontWeight="400"
				letterSpacing="unset"
				lineHeight="20px"
				color={layouts.theme.colors.grey.dark50}
				whiteSpace="pre-wrap"
			>
				{desc}
			</typography.h5>
		)
	}
}

export type imageItem = api.ImageryObject | api.ApplicationObject

export const dualViewItemsCnt = 8

export const itemsToView = (ImageComponent: (item: imageItem) => JSX.Element, items: imageItem[]) => {
	if (items.length >= dualViewItemsCnt) {
		return items
			.map((item, index) => {
				if (index % 2 === 0) {
					return (
						<layouts.Flex key={index} flexDirection="column" alignItems="center" justifyContent="center">
							{ImageComponent(item)}
							{items[index + 1] !== undefined && ImageComponent(items[index + 1])}
						</layouts.Flex>
					)
				}
				return null
			})
			.filter(Boolean)
	} else {
		return items.map((item) => ImageComponent(item))
	}
}

export interface stepTitleProps extends layouts.containers.ContainerProps {
	title: string
	Help?: JSX.Element
}

export function SectionStepTitle(props: stepTitleProps): JSX.Element {
	const { title, Help, ...rest } = props
	const brand = brands.caching.useCached()
	const mtoggle = layouts.modals.useToggle()

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex alignItems="center">
				<typography.h3
					fontSize="24px"
					fontWeight="400"
					letterSpacing="unset"
					color={layouts.theme.colors.grey.dark50}
					whiteSpace="unset"
				>
					{brand.description} | {title}
				</typography.h3>
				{Help && (
					<layouts.Flex ml="10px" onClick={() => mtoggle(Help)}>
						<icons.brandguide.Help />
					</layouts.Flex>
				)}
			</layouts.Flex>
			<layouts.buttons.unstyled
				borderRadius="5px"
				border={`1px solid ${layouts.theme.colors.grey.dark50alpha30}`}
				height="34px"
				width="80px"
				background={layouts.theme.colors.white}
				color={layouts.theme.colors.grey.dark50alpha50}
			>
				Save
			</layouts.buttons.unstyled>
		</layouts.Flex>
	)
}

SectionStepTitle.defaultProps = {
	justifyContent: "space-between",
	px: "47px",
	mb: "32px",
}

export interface stepContainerProps extends layouts.containers.ContainerProps {
	desc: string
}

export function SectionStepContainer(props: React.PropsWithChildren<stepContainerProps>): JSX.Element {
	const { desc, children, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<typography.h5
				mt="32px"
				fontSize="16px"
				fontWeight="400"
				letterSpacing="unset"
				color={layouts.theme.colors.grey.dark50}
				whiteSpace="unset"
			>
				{desc}
			</typography.h5>
			{children}
		</layouts.Flex>
	)
}

SectionStepContainer.defaultProps = {
	flexDirection: "column",
	flex: "8",
	className: "left-panel",
	px: "47px",
}

interface nextNavProps extends layouts.containers.ContainerProps {
	onNext(): void
	disabled: boolean
}

export function NextStepsNav(props: nextNavProps): JSX.Element {
	const { onNext, disabled, ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<layouts.buttons.unstyled
				width="80px"
				height="34px"
				disabled={disabled}
				background={layouts.theme.colors.grey.dark50}
				borderRadius="5px"
				color={layouts.theme.colors.white}
				onClick={onNext}
			>
				Next
			</layouts.buttons.unstyled>
		</layouts.Flex>
	)
}

NextStepsNav.defaultProps = {
	justifyContent: "center",
	my: "20px",
	alignItems: "center",
	flex: "1",
	className: disabledButton,
}

interface twoWayNavProps extends layouts.containers.ContainerProps {
	onNext(): void
	onBack(): void
	disabled: boolean
	isLastStep: boolean
	validation_message: string
}

export function TwoWayStepsNav(props: twoWayNavProps): JSX.Element {
	const { onNext, onBack, disabled, isLastStep, validation_message, ...rest } = props
	const action = isLastStep ? "View" : "Next"
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1">
				<layouts.Flex onClick={onBack} alignItems="center">
					<icons.brandguide.arrows.ArrowBack />
					<typography.h4 pl="10px" fontSize="16px">
						Back
					</typography.h4>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flex="1" className={disabledButton}>
				<layouts.tooltips.default text={disabled ? validation_message : action}>
					<layouts.buttons.unstyled
						disabled={disabled}
						width="80px"
						height="34px"
						background={layouts.theme.colors.grey.dark50}
						borderRadius="5px"
						color={layouts.theme.colors.white}
						onClick={onNext}
					>
						{action}
					</layouts.buttons.unstyled>
				</layouts.tooltips.default>
			</layouts.Flex>
		</layouts.Flex>
	)
}

TwoWayStepsNav.defaultProps = {
	justifyContent: "center",
	my: "20px",
	alignItems: "center",
	flex: "1",
	disabled: false,
	isLastStep: false,
	validation_message: "",
}

interface ImageProps {
	contentdigest: string
	styles?: React.CSSProperties
}

export function Image(props: ImageProps): JSX.Element {
	const { contentdigest, styles } = props
	const [response, setResponse] = useState({} as media.api.RedirectResponse)
	const [loading, setLoading] = useState(false)
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const bearertoken = sessions.useToken()

	useEffect(() => {
		setLoading(true)
		media.cache
			.current(authzaccount.current.id, contentdigest, bearertoken)
			.then((url) => setResponse({ url: url }))
			.catch((cause) => console.warn("unable to load image", cause))
			.finally(() => setLoading(false))
	}, [contentdigest])

	return (
		<layouts.loading.pending loading={loading}>
			<img src={response.url} alt="img" style={styles} />
		</layouts.loading.pending>
	)
}

export function B64ImageForPDF(props: ImageProps & { onLoad(): void }): JSX.Element {
	const { contentdigest, onLoad, styles } = props
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const bearertoken = sessions.useToken()

	const [base64URL, setBase64URL] = useState(undefined as string | undefined)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		media.cache
			.current(authzaccount.current.id, contentdigest, bearertoken)
			.then((url) => {
				fetch(url)
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader()
						reader.onloadend = () => {
							if (reader.result) {
								setBase64URL(reader.result as string)
							}
						}
						reader.readAsDataURL(blob)
					})
					.finally(() => setLoading(false))
			})
			.catch((cause) => console.warn("unable to load image", cause))
	}, [contentdigest])

	return (
		<layouts.loading.pending loading={loading}>
			<img
				src={base64URL}
				alt="img"
				style={styles}
				onLoad={onLoad}
				onError={(e) => {
					onLoad()
					console.warn(contentdigest, "Image load error.")
				}}
			/>
		</layouts.loading.pending>
	)
}

import React, { useState, useEffect } from "react"
import classnames from "classnames"
import { CancellablePromise } from "real-cancellable-promise"
import * as icons from "icons"
import * as layouts from "layouts"
import * as errors from "errors"
import * as httpx from "httpx"
import { statistics, uploaded, pending } from "./api"
import * as styles from "./progress.styles"

export default function Pending<T>(
	props: React.PropsWithChildren<{
		className: string
		pending: pending
		stats: statistics
		onChange(item: uploaded<T>): void
		upload(statistics: statistics, content: File): CancellablePromise<T> // upload content
	}>,
): JSX.Element {
	const { stats, className, pending, onChange, upload } = props
	const [cause, setCause] = useState(undefined as errors.Cause)

	const _upload = () => {
		const retry = httpx.autoretry()
		retry
			.wrap(() => upload(stats, pending.file))
			.then((img) =>
				onChange({ id: pending.id, display: pending.file.name, background: pending.background, data: img }),
			)
			.catch((cause) => {
				setCause(
					<layouts.Flex className={`display-pending ${className}`} mb="5px">
						<layouts.Flex flexDirection="row" flex="1">
							<layouts.Flex justifyContent="flex-start" alignItems="center" flex="3">
								<layouts.Span className={styles.Description} flex="1" color={layouts.theme.colors.red.light}>
									Upload Failed: {pending.file.name}
								</layouts.Span>
							</layouts.Flex>
							<errors.Display
								cause={cause}
								onClick={() => {
									setCause(undefined)
									_upload()
								}}
							>
								<layouts.Flex justifyContent="flex-end" alignItems="center" flex="1" pr="10px">
									<icons.brandguard.retry fill={layouts.theme.colors.red.light} />
								</layouts.Flex>
							</errors.Display>
						</layouts.Flex>
					</layouts.Flex>,
				)
			})
	}

	useEffect(_upload, [])

	if (cause) return cause

	return (
		<layouts.Flex
			className={classnames("display-pending", styles.Container)}
			mb="5px"
			{...styles.ContainerDefaultProps}
		>
			<layouts.Span className={styles.Description} flex="1">
				Uploading: {pending.file.name}
			</layouts.Span>
			<layouts.Span className={styles.Description} flex="1">
				<layouts.bars.progress.Sliding background={pending.background} />
			</layouts.Span>
		</layouts.Flex>
	)
}

import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"

export function Modal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Tone of Voice Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>
							creating a brand voice is essential for maintaining consistency, building personality, differentiating
							from competitors, engaging with the audience, storytelling, and fostering trust.
						</b>{" "}
						By defining and implementing a clear brand voice, a brand can strengthen its identity and relationships with
						consumers, ultimately driving business success.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Consistency:</b> A consistent brand voice helps unify all communication
						efforts across various channels and touchpoints, including marketing materials, social media, customer
						service interactions, and advertising campaigns. Consistency in tone, language, and style reinforces brand
						recognition and builds trust with consumers.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Personality:</b> A distinct brand voice gives the brand a personality and
						character, making it more relatable and memorable to consumers. By infusing the brand with a unique tone and
						style, it can stand out in a crowded marketplace and create stronger emotional connections with its
						audience.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Audience Engagement:</b> A brand voice that resonates with its target
						audience encourages engagement and interaction. When consumers feel a connection with the brand&apos;s
						voice, they are more likely to engage with its content, share it with others, and develop brand loyalty over
						time.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Brand Storytelling:</b> A cohesive brand voice facilitates effective
						storytelling by providing a consistent narrative thread that ties together the brand&apos;s messaging and
						content. It allows the brand to tell its story in a compelling and memorable way, fostering deeper emotional
						connections with consumers.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Brand Trust:</b> A consistent and authentic brand voice builds trust with
						consumers by demonstrating transparency, reliability, and authenticity. When consumers consistently
						encounter a brand that speaks with a clear and trustworthy voice, they are more likely to perceive the brand
						positively and develop loyalty.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Modal.defaultProps = {
	width: "815px",
	height: "590px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

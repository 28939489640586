import * as layouts from "layouts"
import * as routing from "brandguard/routing"
import * as bgscores from "brandguard/display/scores"
import * as scoring from "./scoring"
import * as images from "."

export default function Scoring(): JSX.Element {
	return (
		<bgscores.Container urlgen={routing.image.score}>
			<layouts.containers.flex flexDirection="column" height="100%" data-testid="brandguard">
				<scoring.Navigation />
				<images.overlays.Storage>
					<scoring.Display />
				</images.overlays.Storage>
			</layouts.containers.flex>
		</bgscores.Container>
	)
}

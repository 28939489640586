import * as httpx from "httpx"
import * as uuid from "uuid"
import { DateTime } from "luxon"
import * as proto from "./meta.sso.domain"
import { CancellablePromise } from "real-cancellable-promise"

export * as proto from "./meta.sso.domain"

export function ssoAccessLink(name: string): string {
	const uri = new URL(httpx.urlstorage.sso())
	uri.host = `${name}.${uri.host}`
	return uri.toString()
}

export function zero(d: Partial<proto.Domainapi> = {}): proto.Domainapi {
	let ts = DateTime.now()
	return {
		id: uuid.NIL,
		account_id: uuid.NIL,
		description: "",
		authorized_domains: [],
		created_at: ts.toISO(),
		updated_at: ts.toISO(),
		tombstoned_at: ts.toISO(),
		...d,
	}
}

export namespace searches {
	export function zero(d: Partial<proto.SearchRequest> = {}): proto.SearchRequest {
		return {
			query: "",
			offset: 0n,
			limit: 0n,
			created: undefined,
			enabled: 0,
			...d,
		}
	}

	export function pzero(d: Partial<proto.SearchRequest> = {}): proto.SearchRequest {
		return {
			query: "",
			offset: 0n,
			limit: 0n,
			created: undefined,
			enabled: 0,
			...d,
		}
	}
}

export function search(req: proto.SearchRequest, ...options: httpx.option[]): CancellablePromise<proto.SearchResponse> {
	return httpx
		.get<proto.SearchResponse>(`${httpx.urlstorage.host()}/sso/domains`, req, ...options)
		.then((r) => ({ ...r, items: (r.items || []).map(zero) }))
}

export function create(req: proto.CreateRequest, ...options: httpx.option[]): CancellablePromise<proto.CreateResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/sso/domains`, req, ...options)
}

export function update(req: proto.UpdateRequest, ...options: httpx.option[]): CancellablePromise<proto.UpdateResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/sso/domains/${req.domain?.id}`, req, ...options)
}

export function destroy(id: string, ...options: httpx.option[]): CancellablePromise<proto.Domainapi> {
	return httpx.destroy(`${httpx.urlstorage.host()}/sso/domains/${id}`, {}, ...options)
}

import React, { useContext } from "react"
import * as profiles from "profiles"
import * as httpx from "httpx"
import * as caching from "caching"
import * as timex from "timex"
import { CancellablePromise } from "real-cancellable-promise"

export const Context = React.createContext<profiles.Profile[]>([])

export const Provider = Context.Provider

export const cache = new caching.Cache({ namespace: "novacloud.profiles", ttl: timex.duration.iso("PT1H").toMillis() })

function cachable(id: string, ...options: httpx.option[]): CancellablePromise<profiles.Profile> {
	return profiles.api.find(id, ...options)
}

export function current(id: string, ...options: httpx.option[]): CancellablePromise<profiles.Profile> {
	return cache.maybe(id, () => cachable(id, ...options))
}

export function useCached(): profiles.Profile[] {
	return useContext(Context)
}

export default cache

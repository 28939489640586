export * as strings from "./assertions.strings"
export * as arrays from "./assertions.arrays"
export * as primitives from "./assertions.primitives"
export * as numeric from "./assertions.numeric"

export function every(...checks: (() => JSX.Element | undefined)[]): boolean {
	return checks.reduce((result, v) => result && v() === undefined, true)
}

// checks if any of the checks are invalid
export function failed(...checks: (JSX.Element | undefined)[]): boolean {
	return checks.reduce((result, v) => result || v !== undefined, false)
}

export function assert<T>(...assertions: ((v: T) => JSX.Element | undefined)[]): (i: T) => JSX.Element[] {
	return (i: T): JSX.Element[] => {
		return assertions.reduce((result, v) => {
			const err = v(i)
			return err === undefined ? result : result.concat(err)
		}, [] as JSX.Element[])
	}
}

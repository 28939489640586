import { useState } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as typography from "typography"
import { Account } from "./account"
import { AccountSettings, State } from "accounts/api"
import { css } from "@emotion/css"
import * as icons from "icons"

const styledInputs = css`
	input {
		margin: 0;
	}
`

interface props extends layouts.containers.ContainerProps {
	onChange(a: Account, s: AccountSettings): void
	current: Account
	cur_settings: AccountSettings
}
function validate(account: Account): boolean {
	return inputs.assertions.failed(inputs.assertions.strings.min(1)(account.description))
}

export function Edit(props: props): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const { current, onChange, cur_settings, ...rest } = props
	const [account, setAccount] = useState(current)
	const [settings, setSettings] = useState(cur_settings)
	const disabled = validate(account)

	return (
		<layouts.containers.flex styled flexDirection="column" flex="1" background={layouts.theme.colors.grey.bg} {...rest}>
			<layouts.Flex flexDirection="column" maxWidth="fit-content" px="10px">
				<typography.h3
					lineHeight="30px"
					fontWeight="500"
					color={layouts.theme.colors.blue.blue}
					textAlign="left"
					letterSpacing="unset"
				>
					Edit Account
				</typography.h3>
				<layouts.Flex borderBottom={`2.5px solid ${layouts.theme.colors.blue.blue}`} mt="5px" />
			</layouts.Flex>
			<layouts.Flex my="50px">
				<Form
					current={account}
					cur_settings={settings}
					onChange={(a, s) => {
						setAccount(a)
						setSettings(s)
					}}
					flex="1"
				/>
			</layouts.Flex>

			<layouts.containers.flex justifyContent="flex-end" gridGap="10px">
				<layouts.buttons.outline width="120px" height="35px" borderRadius="37px" onClick={() => mtoggle(undefined)}>
					Cancel
				</layouts.buttons.outline>
				<layouts.buttons.primary
					width="120px"
					height="35px"
					borderRadius="37px"
					disabled={disabled}
					onClick={() => onChange(account, settings)}
				>
					Save
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Edit.defaultProps = {
	width: "965px",
	px: "25px",
	py: "25px",
}

const subContFlexProps = {
	flex: "1",
	mt: "20px",
}

export default function Form(props: props): JSX.Element {
	const { current, onChange, cur_settings, ...rest } = props
	return (
		<layouts.forms.Container flexDirection="row" {...rest}>
			<layouts.forms.Group className="brand.details" flex="1" gridGap="50px">
				<layouts.Flex flexDirection="column" flex="1">
					<typography.h3
						px="10px"
						color={layouts.theme.colors.grey.dark50}
						letterSpacing="unset"
						fontWeight="700"
						textAlign="left"
						lineHeight="30px"
					>
						Description
					</typography.h3>
					<layouts.Flex height="50px" mt="10px">
						<inputs.Text
							padding="0px 20px"
							borderRadius="30px"
							placeholder="e.g. Example Industries, Inc."
							defaultValue={current.description}
							onChange={(evt) => onChange({ ...current, description: evt.currentTarget.value }, cur_settings)}
							autoFocus
						/>
					</layouts.Flex>
				</layouts.Flex>
				<layouts.Flex flexDirection="column" flex="1">
					<typography.h3
						px="10px"
						color={layouts.theme.colors.grey.dark50}
						letterSpacing="unset"
						fontWeight="700"
						textAlign="left"
						lineHeight="30px"
					>
						Domain
					</typography.h3>
					<layouts.Flex height="50px" mt="10px">
						<inputs.Text
							padding="0px 20px"
							borderRadius="30px"
							defaultValue={current.domain}
							placeholder="e.g. https://www.example.com"
							onChange={(evt) => onChange({ ...current, domain: evt.currentTarget.value }, cur_settings)}
						/>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.forms.Group>
			<layouts.forms.Group flex="1" my="40px" flexDirection="column">
				<layouts.Flex flexDirection="column" flex="1">
					<typography.h3
						px="10px"
						color={layouts.theme.colors.grey.dark50}
						letterSpacing="unset"
						fontWeight="700"
						textAlign="left"
						lineHeight="30px"
					>
						Scoring and Display Settings
					</typography.h3>
				</layouts.Flex>
				<layouts.Flex
					flex="1"
					flexDirection="column"
					fontSize="14px"
					color={layouts.theme.colors.grey.dark50}
					className={styledInputs}
				>
					<AggregateScore current={cur_settings} onChange={(upd) => onChange(current, upd)} {...subContFlexProps} />
					<IdentifyModelsChecked
						current={cur_settings}
						onChange={(upd) => onChange(current, upd)}
						{...subContFlexProps}
					/>
					<StyleModels current={cur_settings} onChange={(upd) => onChange(current, upd)} {...subContFlexProps} />
					<BrandSafetyModels current={cur_settings} onChange={(upd) => onChange(current, upd)} {...subContFlexProps} />
					<LegalAndComplianceModels
						current={cur_settings}
						onChange={(upd) => onChange(current, upd)}
						{...subContFlexProps}
					/>
				</layouts.Flex>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}

function sum(array: number[]): number {
	return array.reduce((a, b) => a + b, 0)
}

interface settingsProps extends layouts.containers.FlexProps {
	onChange(s: AccountSettings): void
	current: AccountSettings
}

function AggregateScore(props: settingsProps): JSX.Element {
	const { current, onChange, ...rest } = props
	const checked = sum([current.confidence]) > 0
	return (
		<layouts.forms.Container {...rest}>
			<inputs.Checkbox
				alignItems="flex-start"
				checked={checked}
				onChange={() => {
					onChange({ ...current, confidence: checked ? State.DISABLED : State.ENABLED })
				}}
			>
				<layouts.forms.Label my="unset" ml="10px">
					BrandGuard Score
				</layouts.forms.Label>
			</inputs.Checkbox>
		</layouts.forms.Container>
	)
}

function IdentifyModelsChecked(props: settingsProps): JSX.Element {
	const { current, onChange, ...rest } = props

	const checked =
		sum([
			current.brand_voice, //Image Consistency
			current.onbrand, //Copy Consistency
			current.image_obscure_rules, //Custom Rules
			current.text_tone_and_voice, //Tone of Voice
			current.brand_uniqueness, //Image Competitor Similarity
			current.copy_consist, //Copy Competitor Similarity
			current.text_reference_rules, //Reference Checks
		]) > 0

	return (
		<layouts.forms.Container {...rest}>
			<inputs.Checkbox
				alignItems="flex-start"
				checked={checked}
				onChange={() => {
					const upd = checked ? State.DISABLED : State.ENABLED
					onChange({
						...current,
						brand_voice: upd,
						onbrand: upd,
						image_obscure_rules: upd,
						text_tone_and_voice: upd,
						brand_uniqueness: upd,
						copy_consist: upd,
						text_reference_rules: upd,
					})
				}}
			>
				<layouts.forms.Label my="unset" ml="10px">
					Brand Principles
				</layouts.forms.Label>
			</inputs.Checkbox>
			<layouts.Flex flex="1" mx="30px" mt="10px" flexDirection="column">
				<layouts.Flex flex="1">
					<SubScoreSettingsSelector
						flex="1"
						title="Image Consistency"
						state={current.brand_voice}
						onChange={(s) => onChange({ ...current, brand_voice: s })}
					/>
					<SubScoreSettingsSelector
						flex="1"
						title="Tone of Voice"
						state={current.text_tone_and_voice}
						onChange={(s) => onChange({ ...current, text_tone_and_voice: s })}
					/>
					<SubScoreSettingsSelector
						flex="1"
						title="Copy Consistency"
						state={current.onbrand}
						onChange={(s) => onChange({ ...current, onbrand: s })}
					/>
					<SubScoreSettingsSelector
						flex="1"
						title="Reference Checks"
						state={current.text_reference_rules}
						onChange={(s) => onChange({ ...current, text_reference_rules: s })}
					/>
				</layouts.Flex>
				<layouts.Flex flex="1" mt="5px">
					<SubScoreSettingsSelector
						flex="1"
						title="Look & Feel"
						state={current.image_obscure_rules}
						onChange={(s) => onChange({ ...current, image_obscure_rules: s })}
					/>
					<SubScoreSettingsSelector
						flex="1"
						title="Image Competitor Similarity"
						state={current.brand_uniqueness}
						onChange={(s) => onChange({ ...current, brand_uniqueness: s })}
					/>
					<SubScoreSettingsSelector
						flex="2"
						title="Copy Competitor Similarity"
						state={current.copy_consist}
						onChange={(s) => onChange({ ...current, copy_consist: s })}
					/>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.forms.Container>
	)
}

function StyleModels(props: settingsProps): JSX.Element {
	const { current, onChange, ...rest } = props

	const checked =
		sum([
			current.image_logo_detection, //Correct Logo(s)
			current.image_logo_distortion, //Logo Use Check
			current.image_logo_colors, //Logo Color
			current.image_logo_clearspace, //Logo Clearspace
			current.image_font_detection, //Image Fonts
			current.image_background_colors, //Brand Color(s)
		]) > 0

	return (
		<layouts.forms.Container {...rest}>
			<inputs.Checkbox
				alignItems="flex-start"
				checked={checked}
				onChange={() => {
					const upd = checked ? State.DISABLED : State.ENABLED
					onChange({
						...current,
						image_logo_detection: upd,
						image_logo_distortion: upd,
						image_logo_colors: upd,
						image_logo_clearspace: upd,
						image_font_detection: upd,
						image_background_colors: upd,
					})
				}}
			>
				<layouts.forms.Label my="unset" ml="10px">
					Precision Elements
				</layouts.forms.Label>
			</inputs.Checkbox>
			<layouts.Flex flex="1" mx="30px" mt="10px" flexDirection="column">
				<layouts.Flex flex="1">
					<SubScoreSettingsSelector
						flex="1"
						title="Correct Logo(s)"
						state={current.image_logo_detection}
						onChange={(s) => onChange({ ...current, image_logo_detection: s })}
					/>
					<SubScoreSettingsSelector
						flex="1"
						title="Undistorted Logo"
						state={current.image_logo_distortion}
						onChange={(s) => onChange({ ...current, image_logo_distortion: s })}
					/>
					<SubScoreSettingsSelector
						flex="2"
						title="Logo Color"
						state={current.image_logo_colors}
						onChange={(s) => onChange({ ...current, image_logo_colors: s })}
					/>
				</layouts.Flex>
				<layouts.Flex flex="1" mt="5px">
					<SubScoreSettingsSelector
						flex="1"
						title="Logo Clearspace"
						state={current.image_logo_clearspace}
						onChange={(s) => onChange({ ...current, image_logo_clearspace: s })}
					/>
					<SubScoreSettingsSelector
						flex="1"
						title="Correct Font(s)"
						state={current.image_font_detection}
						onChange={(s) => onChange({ ...current, image_font_detection: s })}
					/>
					<SubScoreSettingsSelector
						flex="2"
						title="Brand Color(s)"
						state={current.image_background_colors}
						onChange={(s) => onChange({ ...current, image_background_colors: s })}
					/>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.forms.Container>
	)
}

function BrandSafetyModels(props: settingsProps): JSX.Element {
	const { current, onChange, ...rest } = props

	const checked =
		sum([
			current.image_violence, //Violence Check
			current.sexually_explicit, //Nudity Check
			current.image_quality_general, //Image Quality
			current.grammar, //Grammar Check
			current.racism, //Racism Check
			current.profanity, //Profanity Check
		]) > 0

	return (
		<layouts.forms.Container {...rest}>
			<inputs.Checkbox
				alignItems="flex-start"
				checked={checked}
				onChange={() => {
					const upd = checked ? State.DISABLED : State.ENABLED
					onChange({
						...current,
						image_violence: upd,
						sexually_explicit: upd,
						image_quality_general: upd,
						grammar: upd,
						racism: upd,
						profanity: upd,
					})
				}}
			>
				<layouts.forms.Label my="unset" ml="10px">
					Brand Safety
				</layouts.forms.Label>
			</inputs.Checkbox>
			<layouts.Flex flex="1" mx="30px" mt="10px" flexDirection="column">
				<layouts.Flex flex="1" mt="5px">
					<SubScoreSettingsSelector
						flex="1"
						title="Violence Check"
						state={current.image_violence}
						onChange={(s) => onChange({ ...current, image_violence: s })}
					/>
					<SubScoreSettingsSelector
						flex="3"
						title="Nudity Check"
						state={current.sexually_explicit}
						onChange={(s) => onChange({ ...current, sexually_explicit: s })}
					/>
				</layouts.Flex>
				<layouts.Flex flex="1" mt="5px">
					<SubScoreSettingsSelector
						flex="1"
						title="Grammar Check"
						state={current.grammar}
						onChange={(s) => onChange({ ...current, grammar: s })}
					/>
					<SubScoreSettingsSelector
						flex="1"
						title="Racism Check"
						state={current.racism}
						onChange={(s) => onChange({ ...current, racism: s })}
					/>
					<SubScoreSettingsSelector
						flex="2"
						title="Profanity Check"
						state={current.profanity}
						onChange={(s) => onChange({ ...current, profanity: s })}
					/>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.forms.Container>
	)
}

function LegalAndComplianceModels(props: settingsProps): JSX.Element {
	const { current, onChange, ...rest } = props
	const checked = sum([current.image_compliance, current.copy_compliance]) > 0
	return (
		<layouts.forms.Container {...rest}>
			<inputs.Checkbox
				alignItems="flex-start"
				checked={checked}
				onChange={() => {
					const upd = checked ? State.DISABLED : State.ENABLED
					onChange({ ...current, image_compliance: upd, copy_compliance: upd })
				}}
			>
				<layouts.forms.Label my="unset" ml="10px">
					Compliance
				</layouts.forms.Label>
			</inputs.Checkbox>
			<layouts.Flex flex="1" mx="30px" mt="10px">
				<SubScoreSettingsSelector
					flex="1"
					title="Image Compliance"
					state={current.image_compliance}
					onChange={(s) => onChange({ ...current, image_compliance: s })}
				/>
				<SubScoreSettingsSelector
					flex="3"
					title="Copy Compliance"
					state={current.copy_compliance}
					onChange={(s) => onChange({ ...current, copy_compliance: s })}
				/>
			</layouts.Flex>
		</layouts.forms.Container>
	)
}

interface subScoreProps extends layouts.containers.ContainerProps {
	title: string
	state: State
	onChange(s: State): void
}

function SubScoreSettingsSelector(props: subScoreProps): JSX.Element {
	const { title, state, onChange, ...rest } = props
	return (
		<layouts.forms.Container {...rest}>
			<layouts.Flex>
				<layouts.Flex
					pr="10px"
					cursor="pointer"
					onClick={() => onChange(state === State.NON_NEGOTIABLE ? State.ENABLED : State.NON_NEGOTIABLE)}
				>
					<icons.Notice
						fill={
							state === State.NON_NEGOTIABLE
								? layouts.theme.colors.red.cinnabar
								: layouts.theme.colors.grey.dark50alpha20
						}
					/>
				</layouts.Flex>
				<inputs.Checkbox
					alignItems="flex-start"
					checked={state !== State.DISABLED}
					onChange={() => onChange(state === State.DISABLED ? State.ENABLED : State.DISABLED)}
				>
					<layouts.forms.Label my="unset" ml="10px">
						{title}
					</layouts.forms.Label>
				</inputs.Checkbox>
			</layouts.Flex>
		</layouts.forms.Container>
	)
}

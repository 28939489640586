/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable,generate_dependencies
// @generated from protobuf file "meta.sso.domain.proto" (package "sso.domain", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DateRange } from "./meta.search";
/**
 * easyjson:json
 *
 * @generated from protobuf message sso.domain.Domainapi
 */
export interface Domainapi {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string authorized_domains = 4 [json_name = "authorized_domains"];
     */
    authorized_domains: string[];
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 6 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string tombstoned_at = 7 [json_name = "tombstoned_at"];
     */
    tombstoned_at: string;
}
/**
 * @generated from protobuf message sso.domain.Sort
 */
export interface Sort {
    /**
     * @generated from protobuf field: sso.domain.Sorting by = 1;
     */
    by: Sorting;
    /**
     * @generated from protobuf field: sso.domain.Direction direction = 2;
     */
    direction: Direction;
}
/**
 * @generated from protobuf message sso.domain.SearchRequest
 */
export interface SearchRequest {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: int64 limit = 2;
     */
    limit: bigint;
    /**
     * @generated from protobuf field: int64 offset = 3;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: meta.DateRange created = 4;
     */
    created?: DateRange;
    /**
     * @generated from protobuf field: sso.domain.Sort sorted = 5;
     */
    sorted?: Sort;
    /**
     * @generated from protobuf field: int32 enabled = 6;
     */
    enabled: number;
}
/**
 * @generated from protobuf message sso.domain.SearchResponse
 */
export interface SearchResponse {
    /**
     * @generated from protobuf field: repeated sso.domain.Domainapi items = 1;
     */
    items: Domainapi[];
    /**
     * @generated from protobuf field: sso.domain.SearchRequest next = 2;
     */
    next?: SearchRequest;
}
/**
 * @generated from protobuf message sso.domain.CreateRequest
 */
export interface CreateRequest {
    /**
     * @generated from protobuf field: sso.domain.Domainapi domain = 1;
     */
    domain?: Domainapi;
}
/**
 * @generated from protobuf message sso.domain.CreateResponse
 */
export interface CreateResponse {
    /**
     * @generated from protobuf field: sso.domain.Domainapi domain = 1;
     */
    domain?: Domainapi;
}
/**
 * @generated from protobuf message sso.domain.UpdateRequest
 */
export interface UpdateRequest {
    /**
     * @generated from protobuf field: sso.domain.Domainapi domain = 1;
     */
    domain?: Domainapi;
}
/**
 * @generated from protobuf message sso.domain.UpdateResponse
 */
export interface UpdateResponse {
    /**
     * @generated from protobuf field: sso.domain.Domainapi domain = 1;
     */
    domain?: Domainapi;
}
/**
 * @generated from protobuf message sso.domain.DeleteResponse
 */
export interface DeleteResponse {
    /**
     * @generated from protobuf field: sso.domain.Domainapi domain = 1;
     */
    domain?: Domainapi;
}
/**
 * @generated from protobuf enum sso.domain.Sorting
 */
export enum Sorting {
    /**
     * @generated from protobuf enum value: CREATED_AT = 0;
     */
    CREATED_AT = 0,
    /**
     * @generated from protobuf enum value: UPDATED_AT = 1;
     */
    UPDATED_AT = 1
}
/**
 * @generated from protobuf enum sso.domain.Direction
 */
export enum Direction {
    /**
     * @generated from protobuf enum value: DESC = 0;
     */
    DESC = 0,
    /**
     * @generated from protobuf enum value: ASC = 1;
     */
    ASC = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Domainapi$Type extends MessageType<Domainapi> {
    constructor() {
        super("sso.domain.Domainapi", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "authorized_domains", kind: "scalar", localName: "authorized_domains", jsonName: "authorized_domains", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "tombstoned_at", kind: "scalar", localName: "tombstoned_at", jsonName: "tombstoned_at", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Domainapi>): Domainapi {
        const message = { id: "", account_id: "", description: "", authorized_domains: [], created_at: "", updated_at: "", tombstoned_at: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Domainapi>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Domainapi): Domainapi {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* repeated string authorized_domains = 4 [json_name = "authorized_domains"];*/ 4:
                    message.authorized_domains.push(reader.string());
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 6 [json_name = "updated_at"];*/ 6:
                    message.updated_at = reader.string();
                    break;
                case /* string tombstoned_at = 7 [json_name = "tombstoned_at"];*/ 7:
                    message.tombstoned_at = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Domainapi, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* repeated string authorized_domains = 4 [json_name = "authorized_domains"]; */
        for (let i = 0; i < message.authorized_domains.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.authorized_domains[i]);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 6 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.updated_at);
        /* string tombstoned_at = 7 [json_name = "tombstoned_at"]; */
        if (message.tombstoned_at !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.tombstoned_at);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.Domainapi
 */
export const Domainapi = new Domainapi$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sort$Type extends MessageType<Sort> {
    constructor() {
        super("sso.domain.Sort", [
            { no: 1, name: "by", kind: "enum", T: () => ["sso.domain.Sorting", Sorting] },
            { no: 2, name: "direction", kind: "enum", T: () => ["sso.domain.Direction", Direction] }
        ]);
    }
    create(value?: PartialMessage<Sort>): Sort {
        const message = { by: 0, direction: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Sort>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Sort): Sort {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sso.domain.Sorting by */ 1:
                    message.by = reader.int32();
                    break;
                case /* sso.domain.Direction direction */ 2:
                    message.direction = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Sort, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sso.domain.Sorting by = 1; */
        if (message.by !== 0)
            writer.tag(1, WireType.Varint).int32(message.by);
        /* sso.domain.Direction direction = 2; */
        if (message.direction !== 0)
            writer.tag(2, WireType.Varint).int32(message.direction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.Sort
 */
export const Sort = new Sort$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRequest$Type extends MessageType<SearchRequest> {
    constructor() {
        super("sso.domain.SearchRequest", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "created", kind: "message", T: () => DateRange },
            { no: 5, name: "sorted", kind: "message", T: () => Sort },
            { no: 6, name: "enabled", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRequest>): SearchRequest {
        const message = { query: "", limit: 0n, offset: 0n, enabled: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRequest): SearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* int64 limit */ 2:
                    message.limit = reader.int64().toBigInt();
                    break;
                case /* int64 offset */ 3:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* meta.DateRange created */ 4:
                    message.created = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* sso.domain.Sort sorted */ 5:
                    message.sorted = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sorted);
                    break;
                case /* int32 enabled */ 6:
                    message.enabled = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* int64 limit = 2; */
        if (message.limit !== 0n)
            writer.tag(2, WireType.Varint).int64(message.limit);
        /* int64 offset = 3; */
        if (message.offset !== 0n)
            writer.tag(3, WireType.Varint).int64(message.offset);
        /* meta.DateRange created = 4; */
        if (message.created)
            DateRange.internalBinaryWrite(message.created, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* sso.domain.Sort sorted = 5; */
        if (message.sorted)
            Sort.internalBinaryWrite(message.sorted, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 enabled = 6; */
        if (message.enabled !== 0)
            writer.tag(6, WireType.Varint).int32(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.SearchRequest
 */
export const SearchRequest = new SearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchResponse$Type extends MessageType<SearchResponse> {
    constructor() {
        super("sso.domain.SearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Domainapi },
            { no: 2, name: "next", kind: "message", T: () => SearchRequest }
        ]);
    }
    create(value?: PartialMessage<SearchResponse>): SearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchResponse): SearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated sso.domain.Domainapi items */ 1:
                    message.items.push(Domainapi.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* sso.domain.SearchRequest next */ 2:
                    message.next = SearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated sso.domain.Domainapi items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Domainapi.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* sso.domain.SearchRequest next = 2; */
        if (message.next)
            SearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.SearchResponse
 */
export const SearchResponse = new SearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
    constructor() {
        super("sso.domain.CreateRequest", [
            { no: 1, name: "domain", kind: "message", T: () => Domainapi }
        ]);
    }
    create(value?: PartialMessage<CreateRequest>): CreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRequest): CreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sso.domain.Domainapi domain */ 1:
                    message.domain = Domainapi.internalBinaryRead(reader, reader.uint32(), options, message.domain);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sso.domain.Domainapi domain = 1; */
        if (message.domain)
            Domainapi.internalBinaryWrite(message.domain, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateResponse$Type extends MessageType<CreateResponse> {
    constructor() {
        super("sso.domain.CreateResponse", [
            { no: 1, name: "domain", kind: "message", T: () => Domainapi }
        ]);
    }
    create(value?: PartialMessage<CreateResponse>): CreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateResponse): CreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sso.domain.Domainapi domain */ 1:
                    message.domain = Domainapi.internalBinaryRead(reader, reader.uint32(), options, message.domain);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sso.domain.Domainapi domain = 1; */
        if (message.domain)
            Domainapi.internalBinaryWrite(message.domain, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.CreateResponse
 */
export const CreateResponse = new CreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
    constructor() {
        super("sso.domain.UpdateRequest", [
            { no: 1, name: "domain", kind: "message", T: () => Domainapi }
        ]);
    }
    create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRequest): UpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sso.domain.Domainapi domain */ 1:
                    message.domain = Domainapi.internalBinaryRead(reader, reader.uint32(), options, message.domain);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sso.domain.Domainapi domain = 1; */
        if (message.domain)
            Domainapi.internalBinaryWrite(message.domain, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateResponse$Type extends MessageType<UpdateResponse> {
    constructor() {
        super("sso.domain.UpdateResponse", [
            { no: 1, name: "domain", kind: "message", T: () => Domainapi }
        ]);
    }
    create(value?: PartialMessage<UpdateResponse>): UpdateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateResponse): UpdateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sso.domain.Domainapi domain */ 1:
                    message.domain = Domainapi.internalBinaryRead(reader, reader.uint32(), options, message.domain);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sso.domain.Domainapi domain = 1; */
        if (message.domain)
            Domainapi.internalBinaryWrite(message.domain, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.UpdateResponse
 */
export const UpdateResponse = new UpdateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteResponse$Type extends MessageType<DeleteResponse> {
    constructor() {
        super("sso.domain.DeleteResponse", [
            { no: 1, name: "domain", kind: "message", T: () => Domainapi }
        ]);
    }
    create(value?: PartialMessage<DeleteResponse>): DeleteResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteResponse): DeleteResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sso.domain.Domainapi domain */ 1:
                    message.domain = Domainapi.internalBinaryRead(reader, reader.uint32(), options, message.domain);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sso.domain.Domainapi domain = 1; */
        if (message.domain)
            Domainapi.internalBinaryWrite(message.domain, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message sso.domain.DeleteResponse
 */
export const DeleteResponse = new DeleteResponse$Type();

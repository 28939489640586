import * as svg from "./svg"
import * as theme from "layouts/theme"

const Icon = (props: svg.SVGProps) => {
	const { fill, ...rest } = props
	return (
		<svg.SVG width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M8.80786 16.4547C9.51338 16.4547 10.0853 15.8818 10.0853 15.175C10.0853 14.4683 9.51338 13.8954 8.80786 13.8954C8.10234 13.8954 7.5304 14.4683 7.5304 15.175C7.5304 15.8818 8.10234 16.4547 8.80786 16.4547Z"
				fill={fill}
			/>
			<path
				d="M12.4742 16.4547C13.1798 16.4547 13.7517 15.8818 13.7517 15.175C13.7517 14.4683 13.1798 13.8954 12.4742 13.8954C11.7687 13.8954 11.1968 14.4683 11.1968 15.175C11.1968 15.8818 11.7687 16.4547 12.4742 16.4547Z"
				fill={fill}
			/>
			<path
				d="M19.8227 12.2008H19.7004V8.1273C19.7004 7.37107 19.0835 6.75313 18.3286 6.75313H13.7377V6.63059C13.7377 6.03891 13.2537 5.55401 12.663 5.55401H11.1234V3.79822C11.728 3.59516 12.1649 3.02448 12.1649 2.34878C12.1649 1.50502 11.4816 0.820557 10.6393 0.820557C9.79698 0.820557 9.11369 1.50502 9.11369 2.34878C9.11369 3.02273 9.55058 3.59516 10.1552 3.79822V5.55401H8.61564C8.02496 5.55401 7.54089 6.03891 7.54089 6.63059V6.75313H2.95355C2.19861 6.75313 1.58172 7.37107 1.58172 8.1273V12.2008H1.45939C0.868717 12.2008 0.384644 12.6857 0.384644 13.2774V17.3299C0.384644 17.9216 0.868717 18.4065 1.45939 18.4065H1.58172V22.9299C1.58172 23.6861 2.19861 24.304 2.95355 24.304H18.3286C19.0835 24.304 19.7004 23.6861 19.7004 22.9299V18.4065H19.8227C20.4134 18.4065 20.8975 17.9216 20.8975 17.3299V13.2774C20.8975 12.6857 20.4134 12.2008 19.8227 12.2008ZM16.1965 19.3903C16.1965 19.7964 15.8942 20.1325 15.5063 20.192V22.5325L12.4935 20.2043H5.89819C5.45081 20.2043 5.08557 19.8384 5.08557 19.3903V10.9614C5.08557 10.5133 5.45081 10.1474 5.89819 10.1474H15.3822C15.8296 10.1474 16.1948 10.5133 16.1948 10.9614V19.3903H16.1965Z"
				fill={fill}
			/>
		</svg.SVG>
	)
}

Icon.defaultProps = {
	fill: theme.colors.grey.dark10,
}

export default Icon

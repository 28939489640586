import * as containers from "layouts/containers"
import * as theme from "layouts/theme"

interface barProps extends containers.FlexProps {
	val: number
	progressColor: string
	innerColor: string
	tickMark: JSX.Element
}

export default function Percentage(props: barProps): JSX.Element {
	const { val, progressColor, innerColor, tickMark, ...rest } = props

	return (
		<containers.flex className="progress-bar-container" background={innerColor} {...rest}>
			<containers.flex
				width={`${val}%`}
				background={progressColor}
				position="relative"
				borderRadius={props.borderRadius || "unset"}
			>
				{val > 0 && tickMark}
			</containers.flex>
		</containers.flex>
	)
}

Percentage.defaultProps = {
	innerColor: theme.colors.grey.dark50alpha20,
	height: "2px",
	tickMark: <></>,
}

import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"

export function Modal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Imagery Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>
							Having both photography and illustrations for a brand allows for versatility, creativity, differentiation,
							and effective visual communication.
						</b>{" "}
						By leveraging the strengths of each medium, a brand can create a cohesive and compelling visual identity
						that resonates with its audience and sets it apart in the marketplace.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Visual Communication:</b> Photography and illustrations offer different
						ways to visually communicate the brand&apos;s message, values, and identity. Photography can capture
						real-life scenes and products, providing authenticity and relatability, while illustrations allow for
						creativity, storytelling, and abstraction, offering unique visual interpretations.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Versatility:</b> By incorporating both photography and illustrations into
						its branding, a brand can adapt to different communication needs and platforms. Photography is well-suited
						for showcasing products, people, and real-life situations, while illustrations can be used for editorial
						content, social media posts, and branding elements such as logos and icons.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Brand Personality:</b> The choice between photography and illustrations can
						convey different aspects of the brand&apos;s personality. Photography may evoke a sense of authenticity,
						warmth, or lifestyle, while illustrations can express creativity, playfulness, or sophistication. By
						leveraging both mediums strategically, a brand can create a multi-dimensional and memorable identity.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Differentiation:</b> Incorporating illustrations alongside photography can
						help a brand stand out in a crowded marketplace. Unique and custom illustrations can add a distinct visual
						style to the brand, setting it apart from competitors and making it more memorable to consumers.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Accessibility:</b> Illustrations can be particularly useful for
						communicating complex ideas or abstract concepts in a visually engaging and accessible way. They can
						simplify information, clarify concepts, and appeal to diverse audiences, enhancing the brand&apos;s ability
						to connect with customers.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Modal.defaultProps = {
	width: "815px",
	height: "625px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

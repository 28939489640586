import React from "react"
import classnames from "classnames"
import * as errors from "errors"
import * as theme from "../theme"
import * as containers from "../containers"
import * as overlay from "./overlay"

export function screen(props: React.PropsWithChildren<overlay.Props & containers.FlexProps>): JSX.Element {
	const { className, loading, display, children, opacity, icon, ...rest } = props

	return (
		<errors.Boundary>
			<containers.flex
				className="loading screen container"
				position="relative"
				zIndex="0"
				flexDirection="column"
				{...rest}
			>
				{children}
				<containers.absolute
					width="100%"
					height="100%"
					top="0px"
					display={loading ? display : "none"}
					background={theme.colors.white}
					opacity={loading ? opacity : undefined}
					className={classnames("loading screen opacity", className)}
				/>
				<containers.absolute
					width="100%"
					height="100%"
					top="0px"
					display={loading ? display : "none"}
					className={classnames("loading screen icon", className)}
				>
					<containers.flex height="inherit" width="100%" m="auto">
						{icon}
					</containers.flex>
				</containers.absolute>
			</containers.flex>
		</errors.Boundary>
	)
}

screen.defaultProps = {
	...overlay.defaults,
}

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	LineElement,
	PointElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from "chart.js"

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, Filler)

export { Bar, Line } from "react-chartjs-2"
export type { CartesianScaleOptions, CartesianTickOptions, GridLineOptions } from "chart.js"

export type CartesianPosition = "left" | "top" | "right" | "bottom" | "center" | { [scale: string]: number }

import styled from "@emotion/styled"
import { css } from "@emotion/css"
import * as layouts from "layouts"
import * as colorsx from "colorsx"
import * as icons from "icons"

export const transparentBG = css`
	background-size: 20px 20px;
	background-image: linear-gradient(45deg, #cccccc 25%, transparent 25%, transparent 75%, #cccccc 75%, #cccccc),
		linear-gradient(45deg, #cccccc 25%, transparent 25%, transparent 75%, #cccccc 75%, #cccccc);
	background-position: 0 0, 10px 10px;
`

export const ContrastBGColorClassName = (svg: string | undefined) => {
	if (!svg) return layouts.theme.colors.white
	const svgElement = icons.svg.encodeFromB64ToSVGElement(svg)
	const colors = icons.svg.getColorsFromSvg(svgElement)
	const top_color = icons.svg.getTopColors(colors)
	const hex = colorsx.convertColorToHex(top_color[0])
	return colorsx.isNearlyWhite(hex) ? transparentBG : ""
}

export const centeredProps = {
	alignItems: "center",
	justifyContent: "center",
}

export const LogoImage = styled.img<{ maxWidth?: string; maxHeight?: string }>`
	max-width: ${(props) => props.maxWidth};
	max-height: ${(props) => props.maxHeight};
	object-fit: contain;
`

LogoImage.defaultProps = {
	maxWidth: "75%",
	maxHeight: "75%",
}

export const fullSizerProps = {
	height: "100%",
	width: "100%",
}

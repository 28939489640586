import * as layouts from "layouts"
import styled from "@emotion/styled"

const Accordion = styled(layouts.accordions.Container)`
	min-height: 25px;
	padding: 0;
	&.extension {
		border-color: ${layouts.theme.extension.colors.blue.border};
		background: transparent;
		margin: 0 12px 16px;
		svg {
			path {
				stroke: ${layouts.theme.colors.blue.bluedarkness};
			}
		}
	}
`

Accordion.defaultProps = {
	borderWidth: "1px 1px 2px",
	borderStyle: "solid",
	borderColor: layouts.theme.colors.grey.light05,
	borderRadius: "8px",
	my: "5px",
}

export default Accordion

import React from "react"
import * as svg from "./svg"
import * as theme from "layouts/theme"
import * as containers from "layouts/containers"

interface clicableIconProps extends svg.SVGProps, containers.CursorProps {
	onClick?: React.MouseEventHandler<SVGSVGElement>
}

export const collapse = (props: clicableIconProps) => {
	let { stroke, strokeOpacity, ...rest } = props
	return (
		<svg.SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M6.74995 15.75L6.74995 2.24995M16.2 13.5L16.2 4.49995C16.2 3.00878 14.9911 1.79995 13.5 1.79995L4.49995 1.79995C3.00878 1.79995 1.79995 3.00878 1.79995 4.49995L1.79995 13.5C1.79995 14.9911 3.00878 16.2 4.49995 16.2L13.5 16.2C14.9911 16.2 16.2 14.9911 16.2 13.5Z"
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeWidth="1.5"
			/>
		</svg.SVG>
	)
}

collapse.defaultProps = {
	stroke: theme.colors.grey.dark50,
	strokeOpacity: "0.8",
}

export const trash = (props: clicableIconProps) => {
	let { ...rest } = props
	return (
		<svg.SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M1.8 4.04998L16.2 4.04998M12.6 16.65H5.4C4.40589 16.65 3.6 15.8441 3.6 14.85V4.94998C3.6 4.45292 4.00295 4.04998 4.5 4.04998H13.5C13.9971 4.04998 14.4 4.45292 14.4 4.94998V14.85C14.4 15.8441 13.5941 16.65 12.6 16.65ZM7.2 4.04998H10.8C11.2971 4.04998 11.7 3.64703 11.7 3.14998V2.24998C11.7 1.75292 11.2971 1.34998 10.8 1.34998H7.2C6.70295 1.34998 6.3 1.75292 6.3 2.24998V3.14998C6.3 3.64703 6.70295 4.04998 7.2 4.04998Z"
				stroke={theme.colors.grey.dark50}
				strokeOpacity="0.8"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg.SVG>
	)
}

export const edit = (props: clicableIconProps) => {
	let { ...rest } = props
	return (
		<svg.SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M10.35 14.6637H14.85M3.15002 14.6637L6.42451 14.0039C6.59835 13.9689 6.75796 13.8833 6.88331 13.7579L14.2136 6.42358C14.565 6.07194 14.5648 5.50195 14.213 5.1506L12.6602 3.59955C12.3086 3.24835 11.7389 3.24859 11.3876 3.60008L4.0566 10.9351C3.93149 11.0603 3.84606 11.2196 3.811 11.3931L3.15002 14.6637Z"
				stroke={theme.colors.grey.dark50}
				strokeOpacity="0.8"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg.SVG>
	)
}

export const share = (props: clicableIconProps) => {
	let { ...rest } = props
	return (
		<svg.SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M15.7501 8.59422L8.55007 3.82495L8.55007 6.52495C2.24993 7.87495 2.24993 14.1749 2.24993 14.1749C2.24993 14.1749 4.94993 10.575 8.55007 11.025L8.55007 13.8149L15.7501 8.59422Z"
				stroke={theme.colors.grey.dark50}
				strokeOpacity="0.8"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
		</svg.SVG>
	)
}

export const newchat = (props: svg.SVGProps) => {
	let { ...rest } = props
	return (
		<svg.SVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z"
				fill="#3E6EE4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H13V7Z"
				fill="#3E6EE4"
			/>
		</svg.SVG>
	)
}
export const chat = (props: svg.SVGProps) => {
	let { ...rest } = props
	return (
		<svg.SVG width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M6.83338 11.0903V11M11.7705 11.0903V11M16.7077 11.0903V11M21.6448 11C21.6448 12.3801 21.3453 13.6921 20.806 14.878L21.6467 20.5991L16.6037 19.3734C15.175 20.1545 13.5263 20.6 11.7705 20.6C6.31711 20.6 1.89624 16.302 1.89624 11C1.89624 5.69809 6.31711 1.40002 11.7705 1.40002C17.2239 1.40002 21.6448 5.69809 21.6448 11Z"
				stroke={theme.colors.white}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg.SVG>
	)
}
export const boticon = (props: svg.SVGProps) => {
	let { ...rest } = props
	return (
		<svg.SVG width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<circle cx="25" cy="25" r="25" fill="#0C122B" />
			<path
				d="M23.8079 28.4547C24.5134 28.4547 25.0853 27.8818 25.0853 27.175C25.0853 26.4683 24.5134 25.8954 23.8079 25.8954C23.1023 25.8954 22.5304 26.4683 22.5304 27.175C22.5304 27.8818 23.1023 28.4547 23.8079 28.4547Z"
				fill="white"
			/>
			<path
				d="M27.4742 28.4547C28.1798 28.4547 28.7517 27.8818 28.7517 27.175C28.7517 26.4683 28.1798 25.8954 27.4742 25.8954C26.7687 25.8954 26.1968 26.4683 26.1968 27.175C26.1968 27.8818 26.7687 28.4547 27.4742 28.4547Z"
				fill="white"
			/>
			<path
				d="M34.8227 24.2008H34.7004V20.1273C34.7004 19.3711 34.0835 18.7531 33.3286 18.7531H28.7377V18.6306C28.7377 18.0389 28.2537 17.554 27.663 17.554H26.1234V15.7982C26.728 15.5952 27.1649 15.0245 27.1649 14.3488C27.1649 13.505 26.4816 12.8206 25.6393 12.8206C24.797 12.8206 24.1137 13.505 24.1137 14.3488C24.1137 15.0227 24.5506 15.5952 25.1552 15.7982V17.554H23.6156C23.025 17.554 22.5409 18.0389 22.5409 18.6306V18.7531H17.9536C17.1986 18.7531 16.5817 19.3711 16.5817 20.1273V24.2008H16.4594C15.8687 24.2008 15.3846 24.6857 15.3846 25.2774V29.3299C15.3846 29.9216 15.8687 30.4065 16.4594 30.4065H16.5817V34.9299C16.5817 35.6861 17.1986 36.304 17.9536 36.304H33.3286C34.0835 36.304 34.7004 35.6861 34.7004 34.9299V30.4065H34.8227C35.4134 30.4065 35.8975 29.9216 35.8975 29.3299V25.2774C35.8975 24.6857 35.4134 24.2008 34.8227 24.2008ZM31.1965 31.3903C31.1965 31.7964 30.8942 32.1325 30.5063 32.192V34.5325L27.4935 32.2043H20.8982C20.4508 32.2043 20.0856 31.8384 20.0856 31.3903V22.9614C20.0856 22.5133 20.4508 22.1474 20.8982 22.1474H30.3822C30.8296 22.1474 31.1948 22.5133 31.1948 22.9614V31.3903H31.1965Z"
				fill="white"
			/>
		</svg.SVG>
	)
}
export const inputarrow = (props: clicableIconProps & { disabled?: boolean }) => {
	let { disabled, ...rest } = props
	return (
		<svg.SVG width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M1.62873 7.16098C0.790222 7.45737 0.685335 8.59935 1.4549 9.04483L7.0817 12.2957C7.22303 12.3775 7.38528 12.416 7.54828 12.4064C7.71129 12.3967 7.86787 12.3394 7.99858 12.2415L14.6548 7.24937C14.8469 7.10441 15.0909 7.34836 14.9465 7.54105L9.95432 14.1961C9.85645 14.3268 9.79913 14.4834 9.78949 14.6464C9.77986 14.8094 9.81832 14.9716 9.90011 15.113L13.1528 20.7427C13.5977 21.5117 14.7397 21.4068 15.0366 20.5689L21.6652 1.8618C21.958 1.03626 21.1614 0.239585 20.3352 0.531855L1.62873 7.16098Z"
				fill={theme.colors.blue.blue}
				fillOpacity={disabled ? "0.2" : "1"}
			/>
		</svg.SVG>
	)
}
export const attention = (props: svg.SVGProps) => {
	let { ...rest } = props
	return (
		<svg.SVG width="123" height="123" viewBox="0 0 123 123" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<g clipPath="url(#clip0_66_7710)">
				<path
					d="M61.5 20.5C53.391 20.5 45.4641 22.9046 38.7216 27.4097C31.9792 31.9149 26.7242 38.3182 23.621 45.81C20.5178 53.3018 19.7058 61.5455 21.2878 69.4987C22.8698 77.4519 26.7747 84.7574 32.5086 90.4914C38.2426 96.2253 45.5481 100.13 53.5013 101.712C61.4545 103.294 69.6983 102.482 77.19 99.3791C84.6818 96.2759 91.0851 91.0208 95.5903 84.2784C100.095 77.536 102.5 69.609 102.5 61.5C102.5 50.6261 98.1804 40.1976 90.4914 32.5086C82.8024 24.8196 72.3739 20.5 61.5 20.5ZM56.4092 41C56.4092 39.6408 56.9491 38.3372 57.9103 37.3761C58.8714 36.415 60.175 35.875 61.5342 35.875C62.8934 35.875 64.197 36.415 65.1581 37.3761C66.1192 38.3372 66.6592 39.6408 66.6592 41V64.5408C66.6592 65.2139 66.5266 65.8803 66.2691 66.5021C66.0115 67.1239 65.634 67.6889 65.1581 68.1648C64.6822 68.6407 64.1172 69.0182 63.4954 69.2757C62.8736 69.5333 62.2072 69.6658 61.5342 69.6658C60.8612 69.6658 60.1947 69.5333 59.5729 69.2757C58.9511 69.0182 58.3862 68.6407 57.9103 68.1648C57.4344 67.6889 57.0569 67.1239 56.7993 66.5021C56.5417 65.8803 56.4092 65.2139 56.4092 64.5408V41ZM61.5 87.125C60.3377 87.125 59.2015 86.7803 58.2351 86.1346C57.2687 85.4889 56.5155 84.5711 56.0707 83.4972C55.6259 82.4234 55.5095 81.2418 55.7363 80.1019C55.963 78.9619 56.5227 77.9148 57.3446 77.0929C58.1665 76.271 59.2136 75.7113 60.3535 75.4846C61.4935 75.2578 62.6751 75.3742 63.7489 75.819C64.8227 76.2638 65.7406 77.017 66.3863 77.9834C67.032 78.9498 67.3767 80.086 67.3767 81.2483C67.3767 82.8069 66.7575 84.3017 65.6554 85.4038C64.5534 86.5059 63.0586 87.125 61.5 87.125Z"
					fill="#C5D3F7"
				/>
			</g>
			<defs>
				<clipPath id="clip0_66_7710">
					<rect width="123" height="123" fill={theme.colors.white} />
				</clipPath>
			</defs>
		</svg.SVG>
	)
}

export const arrowleftright = (props: svg.SVGProps) => {
	let { ...rest } = props
	return (
		<svg.SVG width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path d="M-2.62268e-07 6L4.5 0.803848L4.5 11.1962L-2.62268e-07 6Z" fill={theme.colors.grey.dark50alpha20} />
			<path
				transform="translate(5, 0)"
				d="M5 6L0.499999 11.1962L0.499999 0.803848L5 6Z"
				fill={theme.colors.grey.dark50alpha20}
			/>
		</svg.SVG>
	)
}

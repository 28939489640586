import React, { useState, useRef } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as caching from "brandguide/cache"
import * as icons from "icons"
import * as brandguide from "brandguide"
import * as logos from "brandguide/build/logos"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideLogos | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()
	const imgRef = useRef<HTMLImageElement>(null)

	const [data, setData] = useState(brandguide.api.zeros.guide.guideLogosZero(_guide.guide?.logos))

	const items = [data.primary, data.secondary, data.logomark, data.wordmark, data.alternative].filter((l) => l?.present)

	const [focused, setFocused] = useState(items[0]!)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Proportions" Help={<logos.helps.ProportionModal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Use the slider to select the acceptable minimum size for your digital logo(s).">
							<layouts.Flex flex="2">
								<layouts.Flex flexDirection="column" flex="1" alignItems="flex-start" justifyContent="center">
									{items.map((logo) => (
										<label key={logo?.id} style={{ marginBottom: "10px" }}>
											<input
												type="checkbox"
												name="logos"
												value={logo?.id}
												checked={logo?.id === focused.id}
												style={{ width: "15px", height: "15px" }}
												onChange={(e) => setFocused(items.find((i) => i?.id === e.target.value)!)}
											/>
											<layouts.Span display="inline-block" verticalAlign="text-top" px="10px">
												<typography.h5
													fontSize="15px"
													fontWeight="400"
													color={
														logo?.id === focused.id
															? layouts.theme.colors.blue.blue
															: layouts.theme.colors.grey.dark50alpha80
													}
												>
													{logos.layouts.LogoTitle(logo!)}
												</typography.h5>
											</layouts.Span>
										</label>
									))}
								</layouts.Flex>
								<layouts.Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
									<layouts.Flex flex="1" alignItems="center" className="image-container">
										<layouts.Flex
											position="relative"
											flexDirection="column"
											className={logos.layouts.ContrastBGColorClassName(focused.svg)}
										>
											<img
												src={focused.svg}
												alt={logos.layouts.LogoTitle(focused)}
												style={{ width: `${focused.min_size}px`, maxWidth: "150px" }}
												ref={imgRef}
											/>
										</layouts.Flex>
									</layouts.Flex>

									<layouts.Flex zIndex="1" mb="10px">
										<layouts.Flex
											position="relative"
											flexDirection="column"
											minWidth="250px"
											className={logos.layouts.customInputRange}
										>
											<input
												type="range"
												min={24}
												max={100}
												step={1}
												value={focused.min_size}
												onChange={(e) => setFocused({ ...focused, min_size: Number(e.target.value) })}
											/>
											<layouts.Flex justifyContent="center" mt="10px">
												<output style={{ top: "20px", width: "50px", height: "24px" }}>
													<typography.h6
														fontSize="12px"
														fontWeight="500"
														color={layouts.theme.colors.grey.dark50alpha80}
														textAlign="center"
													>
														{focused.min_size}px
													</typography.h6>
												</output>
											</layouts.Flex>
										</layouts.Flex>
									</layouts.Flex>
								</layouts.Flex>

								<layouts.Flex alignItems="center" justifyContent="center">
									<layouts.Flex flexDirection="column" mx="10px" gridGap="20px">
										<layouts.tooltips.default text="Apply">
											<layouts.Flex
												{...logos.layouts.actionButtonsProps}
												background={
													focused.min_size !== items.find((i) => i?.id === focused?.id)?.min_size
														? layouts.theme.colors.grey.dark50alpha60
														: layouts.theme.colors.grey.dark50alpha05
												}
												onClick={() => {
													if (focused.min_size === items.find((i) => i?.id === focused?.id)?.min_size) return
													setData(proportionUpdate(data, focused))
												}}
											>
												<icons.brandguide.Done />
											</layouts.Flex>
										</layouts.tooltips.default>
										<layouts.tooltips.default text="Apply All">
											<layouts.Flex
												{...logos.layouts.actionButtonsProps}
												background={
													items.filter((i) => i?.min_size !== focused.min_size).length > 0
														? layouts.theme.colors.grey.dark50alpha60
														: layouts.theme.colors.grey.dark50alpha05
												}
												onClick={() => {
													if (items.filter((i) => i?.min_size !== focused.min_size).length === 0) return
													setData(proportionUpdateAll(data, focused.min_size))
												}}
											>
												<icons.brandguide.DoneAll />
											</layouts.Flex>
										</layouts.tooltips.default>
										<layouts.tooltips.default text="Start Over">
											<layouts.Flex
												{...logos.layouts.actionButtonsProps}
												background={
													items.filter((i) => i?.min_size === 0).length === 0
														? layouts.theme.colors.grey.dark50alpha60
														: layouts.theme.colors.grey.dark50alpha05
												}
												onClick={() => {
													if (items.filter((i) => i?.min_size !== 0).length === 0) return
													setData(proportionUpdateAll(data, 0))
												}}
											>
												<icons.Close fill={layouts.theme.colors.white} height="16px" width="16px" />
											</layouts.Flex>
										</layouts.tooltips.default>
									</layouts.Flex>
								</layouts.Flex>
							</layouts.Flex>

							<logos.Explanation
								val={data.descriptions?.proportions || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.logos.descZero({ ...data.descriptions, proportions: text }),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(logos.Steps.CLEARSPASE, undefined)}
								onNext={() => onChange(logos.Steps.PLACEMENT, data)}
								disabled={!brandguide.validations.logos.valid.proportions(data)}
							/>
							<brandguide.build.layouts.SkipStep
								my="30px"
								onSkip={() => onChange(logos.Steps.PLACEMENT, data)}
								onNeverHave={() => onChange(logos.Steps.PLACEMENT, data)}
							/>
							<logos.Progress activeStep={logos.Steps.PROPORTIONS} steps={logos.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<logos.Guidelines activPage="logos" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

const proportionUpdateAll = (data: brandguide.api.GuideLogos, upd: number): brandguide.api.GuideLogos => {
	const logos = brandguide.api.zeros.guide.guideLogosZero(data)
	logos.primary!.min_size = upd
	logos.secondary!.min_size = upd
	logos.logomark!.min_size = upd
	logos.wordmark!.min_size = upd
	logos.alternative!.min_size = upd
	return logos
}

const proportionUpdate = (
	data: brandguide.api.GuideLogos,
	upd: brandguide.api.LogoObject,
): brandguide.api.GuideLogos => {
	const logos = brandguide.api.zeros.guide.guideLogosZero(data)
	if (upd.id === "primary") logos.primary = upd
	if (upd.id === "secondary") logos.secondary = upd
	if (upd.id === "logomark") logos.logomark = upd
	if (upd.id === "wordmark") logos.wordmark = upd
	if (upd.id === "alternative") logos.alternative = upd
	return logos
}

export default Display

import React from "react"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as bglayouts from "brandguard/components/layouts"
import classnames from "classnames"
import * as debugx from "x/debugx"
import * as scores from "brandguard/components/layouts/scores"
import { useSettings } from "accounts/settings"
import { State } from "accounts/api"
import {
	LookAndFeel,
	BackgroundColor,
	Violence,
	Nudity,
	ImageConsistency,
	LogoDistortion,
	LogoClearspace,
	LogoColor,
	FontDetection,
} from "brandguard/display/scores"

interface props extends layouts.containers.ContainerProps {
	item: api.ImageSearchResponseItem
	prediction(evt: api.ImageSearchResponseItem): api.Prediction
}

export default function Scoring(props: props): JSX.Element {
	const { item, prediction, className, mx, ...rest } = props
	const parentRest = { mx }
	const settings = useSettings()

	const onBrand = {
		title: "Brand Principles",
		indev: false,
		subtitle: "Detailed view of the Brand Principles",
		subscores: [
			{
				title: "Look & Feel",
				score: item.event?.image_obscure_rule_conformance,
				inverse: false,
				indev: false,
				available: settings.image_obscure_rules,
				additions: <LookAndFeel event={item.media!} />,
			},
			{
				title: "Image Consistency",
				score: item.event?.brand_voice,
				inverse: false,
				indev: false,
				available: settings.brand_voice,
				additions: <ImageConsistency event={item.media!} />,
			},
			{
				title: "Image Competitor Similarity",
				inverse: false,
				score: 0,
				indev: true,
				available: settings.brand_uniqueness,
			},
		] as scores.subScoreStruct[],
	} as scores.scoreStruct

	const onStyle = {
		title: "Precision Elements",
		indev: false,
		subtitle: "Detailed view of the Precision Elements",
		subscores: [
			{
				title: "Correct Logo(s)",
				score: item.event?.image_logo_detection,
				inverse: false,
				indev: false,
				available: settings.image_logo_detection && Number(debugx.alpha.enabled()),
			},
			{
				title: "Undistorted Logo",
				score: item.event?.image_logo_distortion,
				inverse: false,
				indev: false,
				available: settings.image_logo_distortion,
				additions: <LogoDistortion event={item.media!} />,
			},
			{
				title: "Logo Color",
				score: item.event?.image_logo_colors,
				inverse: false,
				indev: false,
				available: settings.image_logo_colors,
				additions: <LogoColor event={item.media!} />,
			},
			{
				title: "Logo Clearspace",
				score: item.event?.image_logo_clearspace,
				inverse: false,
				indev: false,
				available: settings.image_logo_clearspace,
				additions: <LogoClearspace event={item.media!} />,
			},
			{
				title: "Correct Font(s)",
				score: item.event?.image_font_detection,
				inverse: false,
				indev: false,
				available: settings.image_font_detection,
				additions: <FontDetection event={item.media!} />,
			},
			{
				title: "Brand Color(s)",
				score: item.event?.image_background_colors,
				inverse: false,
				indev: false,
				available: settings.image_background_colors,
				additions: <BackgroundColor event={item.media!} />,
			},
		] as scores.subScoreStruct[],
	} as scores.scoreStruct

	const safety = {
		title: "Brand Safety",
		indev: false,
		subtitle: "Detailed view of the Brand Safety score",
		subscores: [
			{
				title: "Violence Check",
				score: item.event?.image_violence,
				inverse: true,
				indev: false,
				available: settings.image_violence,
				additions: <Violence event={item.media!} />,
			},
			{
				title: "Nudity Check",
				score: item.event?.sexually_explicit,
				inverse: true,
				indev: false,
				available: settings.sexually_explicit,
				additions: <Nudity event={item.media!} />,
			},
		] as scores.subScoreStruct[],
	} as scores.scoreStruct

	function NonNegotiableAlert(props: layouts.containers.FlexProps): JSX.Element {
		const { ...rest } = props

		const _onbrand = onBrand.subscores.map((i) => scores.isNonNegotiable(i.score, i.indev, i.inverse, i.available))
		const _onstyle = onStyle.subscores.map((i) => scores.isNonNegotiable(i.score, i.indev, i.inverse, i.available))
		const _safety = safety.subscores.map((i) => scores.isNonNegotiable(i.score, i.indev, i.inverse, i.available))
		const _compliance = [scores.isNonNegotiable(0, true, false, settings.image_compliance)]
		if (!_onbrand.concat(_onstyle, _safety, _compliance).includes(true)) return <></>
		return <scores.NonNegotiableMessage {...rest} />
	}

	//If there is only one category enabled => expand that category by default
	const initOpen = (): boolean => {
		const _onbrand = scores.sum(onBrand.subscores.map((i) => i.available)) === 0 ? 0 : 1
		const _onstyle = scores.sum(onStyle.subscores.map((i) => i.available)) === 0 ? 0 : 1
		const _safety = scores.sum(safety.subscores.map((i) => i.available)) === 0 ? 0 : 1
		return _onbrand + _onstyle + _safety === 1
	}

	return (
		<bglayouts.fileDetails.InformationGroup
			className={classnames(className, "file-details-information-group")}
			{...rest}
		>
			{settings.confidence !== State.DISABLED && (
				<scores.BGScore
					prediction={prediction(item)}
					mainScore={item.event!.brand_voice === -1 ? 0 : item.event!.brand_voice}
					className={classnames(className, "brandguard-score")}
					otherScores={
						[
							{ score: item.event?.image_quality_general, inverse: false },
							{ score: item.event?.image_violence, inverse: true },
							{ score: item.event?.sexually_explicit, inverse: true },
						] as scores.subScoreStruct[]
					}
				/>
			)}
			<NonNegotiableAlert height="30px" borderRadius="5px" alignItems="center" mb="15px" {...parentRest} />
			<scores.ScoreItem i={onBrand} className={className} initOpen={initOpen()} />
			<scores.ScoreItem i={onStyle} className={className} initOpen={initOpen()} />
			<scores.ScoreItem i={safety} className={className} initOpen={initOpen()} />
			{settings.image_compliance !== State.DISABLED && (
				<scores.NoSubscoresItem className={className} score={0} indev state={settings.image_compliance}>
					Compliance
				</scores.NoSubscoresItem>
			)}
		</bglayouts.fileDetails.InformationGroup>
	)
}

Scoring.defaultProps = {
	prediction: (i: api.ImageSearchResponseItem) => i.event?.approved || api.Prediction.NONE,
}

import * as layouts from "layouts"
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as colorx from "colorsx"

interface props extends layouts.containers.ContainerProps {
	item: brandguide.api.ColorObject
}

export default function Display(props: props): JSX.Element {
	const { item, ...rest } = props

	const textColor = colorx.getTextColorFromBG(item.color?.hex_code || layouts.theme.colors.black.dark)

	const textBaseProps = {
		letterSpacing: "unset",
		lineHeight: "normal",
		color: textColor,
		whiteSpace: "unset",
		fontSize: "calc(0.3vw + 0.3vh + .5vmin)",
	}

	const rgb = colorx.hexToRgb(item.color!.hex_code)
	const cmyk = colorx.rgbToCmyk(rgb)

	function HEX(): JSX.Element {
		return (
			<layouts.Flex flexDirection="column" mt="10px">
				<typography.h5 fontWeight="600" {...textBaseProps}>
					HEX
				</typography.h5>
				<typography.h5 mt="3px" fontWeight="400" {...textBaseProps}>
					{item.color!.hex_code}
				</typography.h5>
			</layouts.Flex>
		)
	}

	function RGB(): JSX.Element {
		return (
			<layouts.Flex flexDirection="column" mt="10px">
				<typography.h5 fontWeight="600" {...textBaseProps}>
					RGB
				</typography.h5>
				<typography.h5 mt="3px" fontWeight="400" {...textBaseProps}>
					{`${rgb?.r} / ${rgb?.g} / ${rgb?.b}`}
				</typography.h5>
			</layouts.Flex>
		)
	}

	function CMYK(): JSX.Element {
		return (
			<layouts.Flex flexDirection="column" mt="10px">
				<typography.h5 fontWeight="600" {...textBaseProps}>
					CMYK
				</typography.h5>
				<typography.h5 mt="3px" fontWeight="400" {...textBaseProps}>
					{`${cmyk[0]} / ${cmyk[1]} / ${cmyk[2]} / ${cmyk[3]}`}
				</typography.h5>
			</layouts.Flex>
		)
	}

	return (
		<layouts.Flex {...rest}>
			<typography.h5 mt="3px" fontWeight="600" {...textBaseProps}>
				{item.color?.name}
			</typography.h5>
			<HEX />
			<RGB />
			<CMYK />
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flexDirection: "column",
	padding: "10px",
}

import React from "react"
import classnames from "classnames"
import * as inputs from "inputs"
import * as api from "brandguard/api"
import * as dropwells from "./dropwell"
import * as bglayouts from "brandguard/components/layouts"

export default function Upload(
	props: React.PropsWithChildren<{
		statistics: inputs.uploads.api.statistics
		concurrent: number
		background: string
		observation: api.Prediction
		onChange: (item: File[], background?: string) => void
	}>,
): JSX.Element {
	const { children, background, observation, onChange } = props

	return (
		<>
			<dropwells.default
				className={classnames(
					"image",
					"upload",
					bglayouts.uploads.navdropwellsimple,
					observation === api.Prediction.REJECTED
						? `${bglayouts.uploads.navdropwellred} ${bglayouts.uploads.navareared}`
						: "",
				)}
				mb="20px"
				onChange={(files) => onChange(files, background)}
			/>
			{children}
		</>
	)
}

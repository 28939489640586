import * as uuid from "uuid"
import * as httpx from "httpx"
import * as timex from "timex"
import * as proto from "./meta.oauth2"
import { CancellablePromise } from "real-cancellable-promise"
export type {
	Credential,
	Token,
	SearchRequest,
	SearchResponse,
	DisableResponse,
	TokenDisableResponse,
} from "./meta.oauth2"

export namespace credentials {
	export interface Secret extends proto.Credential {
		secret: string
	}

	export function zero(d: Partial<proto.Credential> = {}): proto.Credential {
		const ts = timex.local().toISO()
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			created_at: ts,
			updated_at: ts,
			disabled_at: timex.infinity().toISO(),
			description: "",
			email: "",
			origins: [],
			redirects: [],
			scopes: [],
			...d,
		}
	}

	export namespace searches {
		export function request(d: Partial<proto.SearchRequest> = {}): proto.SearchRequest {
			return {
				query: "",
				offset: uuid.NIL,
				limit: 20n,
				created: undefined,
				...d,
			}
		}

		export function response(d: Partial<proto.SearchResponse> = {}): proto.SearchResponse {
			return {
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: proto.SearchRequest,
		...options: httpx.option[]
	): CancellablePromise<proto.SearchResponse> {
		return httpx
			.get<proto.SearchResponse>(`${httpx.urlstorage.host()}/meta/oauth2/credentials/`, req, ...options)
			.then((resp) => {
				return { ...resp, items: resp.items || [] }
			})
	}

	export function create(
		req: proto.CreateRequest,
		...options: httpx.option[]
	): CancellablePromise<proto.CreateResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/meta/oauth2/credentials`, req, ...options)
	}

	export function refreshToken(id: string, ...options: httpx.option[]): CancellablePromise<proto.CreateTokenResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/meta/oauth2/credentials/${id}/refresh_token`, {}, ...options)
	}

	export function accessToken(...options: httpx.option[]): CancellablePromise<proto.CreateAccessTokenResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/meta/oauth2/credentials/access_token`, {}, ...options)
	}

	export function disable(id: string, ...options: httpx.option[]): CancellablePromise<proto.DisableResponse> {
		return httpx.destroy(`${httpx.urlstorage.host()}/meta/oauth2/credentials/${id}`, {}, ...options)
	}
}

export namespace tokens {
	export interface Secret extends proto.Token {
		refresh_token: string
	}

	export function zero(d: Partial<proto.Token> = {}): proto.Token {
		const ts = timex.local().toISO()
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			authorized_by: uuid.NIL,
			created_at: ts,
			expires_at: timex.infinity().toISO(),
			description: "",
			scopes: [],
			...d,
		}
	}

	export namespace searches {
		export function request(d: Partial<proto.TokenSearchRequest> = {}): proto.TokenSearchRequest {
			return {
				query: "",
				offset: uuid.NIL,
				limit: 20n,
				created: undefined,
				...d,
			}
		}

		export function response(d: Partial<proto.TokenSearchResponse> = {}): proto.TokenSearchResponse {
			return {
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: proto.TokenSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<proto.TokenSearchResponse> {
		return httpx
			.get<proto.TokenSearchResponse>(`${httpx.urlstorage.host()}/meta/oauth2/tokens/`, req, ...options)
			.then((resp) => {
				return { ...resp, items: resp.items || [] }
			})
	}

	export function disable(id: string, ...options: httpx.option[]): CancellablePromise<proto.TokenDisableResponse> {
		return httpx.destroy(`${httpx.urlstorage.host()}/meta/oauth2/tokens/${id}/`, {}, ...options)
	}
}

// handle authorization requests.
export function authorization(req: httpx.qs.StringifiableRecord, ...options: httpx.option[]): Promise<string> {
	return httpx
		.get<{ redirect: string }>(`${httpx.urlstorage.host()}/oauth2/authorize`, req, httpx.accept.json, ...options)
		.then((r) => {
			return r.redirect
		})
}

import React from "react"
import * as layouts from "layouts"
import * as icons from "icons"
import * as api from "./api"

interface props {
	sort_by: number
	req: api.proto.SearchRequest
	onChange(req: api.proto.SearchRequest): void
}

export default function Sort(props: React.PropsWithChildren<props & layouts.containers.ContainerProps>): JSX.Element {
	const { sort_by, req, onChange, ...rest } = props

	const checked = (d: api.proto.Direction): boolean => {
		const usort_by = req.sorted?.by || api.proto.Sorting.CREATED_AT
		const direction = req.sorted?.direction || api.proto.Direction.DESC
		return usort_by === sort_by && direction === d
	}

	return (
		<layouts.Flex alignItems="center" px="10px" {...rest}>
			<layouts.Flex flexDirection="column">
				<layouts.Flex
					cursor="pointer"
					onClick={() => onChange({ ...req, sorted: { by: sort_by, direction: api.proto.Direction.ASC } })}
				>
					<icons.arrows.SortUp
						transform="matrix(1,0,0,-1,0,0)"
						fillOpacity={checked(api.proto.Direction.ASC) ? "1" : "0.5"}
					/>
				</layouts.Flex>
				<layouts.Flex
					cursor="pointer"
					onClick={() => onChange({ ...req, sorted: { by: sort_by, direction: api.proto.Direction.DESC } })}
				>
					<icons.arrows.SortUp fillOpacity={checked(api.proto.Direction.DESC) ? "1" : "0.5"} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

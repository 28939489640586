import * as caching from "caching"

export namespace pkce {
	export namespace state {
		const key = "pkce.state"

		export interface data {
			state: string
			codeVerifier: string
		}

		export function set(d: data): Promise<data> {
			return caching.object.set(key, d)
		}

		export function get(): Promise<data> {
			return caching.object.get(key)
		}
	}
}

export namespace magiclink {
	export namespace state {
		const key = "magiclink.state"

		export function set(d: string): Promise<string> {
			return caching.set(key, d)
		}

		export function consume(): Promise<string> {
			return caching.get(key).then((s) => {
				return caching.removeItem(key).then(() => {
					return s
				})
			})
		}
	}
}

export namespace ephemerallink {
	export namespace state {
		const key = "ephemerallink.state"

		export function cas(d: string): Promise<string> {
			return caching.cas(key, d)
		}
	}
}

import { Route, Routes } from "react-router-dom"
import { Display, Container } from "brandgpt"
import * as routing from "./routing"
import * as layouts from "layouts"
import * as authz from "authz"
import * as authzc from "authzcached"
import * as nav from "brandguard/components/navigation"

export default function Routing(): JSX.Element {
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current

	return (
		<Routes>
			<Route
				path="*"
				element={
					<authz.Protected
						enabled={permission.brandgpt_use}
						rejected={
							<authz.layouts.BlockDenied height="100%" data-testid="brandgpt-block-denied-container">
								You do not have permissions to use BrandGPT. Please contact an admin.
							</authz.layouts.BlockDenied>
						}
					>
						<Container urlgen={routing.root}>
							<layouts.containers.flex flexDirection="column" data-testid="brandgpt-container">
								<nav.title title="Chat With Your Brand" />
							</layouts.containers.flex>
							<Display />
						</Container>
					</authz.Protected>
				}
			/>
		</Routes>
	)
}

import React, { useState, useRef, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as caching from "brandguide/cache"
import * as icons from "icons"
import * as brandguide from "brandguide"
import * as logos from "brandguide/build/logos"
import useResizeObserver from "@react-hook/resize-observer"

interface clearspaceGridProps {
	imgWidth: number
	imgHeight: number
	pathbbox: DOMRect | undefined
	focused: brandguide.api.LogoObject
}

export function ClearspaceGrid(props: clearspaceGridProps): JSX.Element {
	const { imgWidth, imgHeight, pathbbox, focused } = props
	if (imgWidth + imgHeight === 0) return <></>
	const paddingX = (focused.clearspace / 100) * (pathbbox?.width || 0)
	const paddingY = (focused.clearspace / 100) * (pathbbox?.height || 0)

	const border = `1px dashed ${layouts.theme.colors.grey.dark50alpha40}`

	return (
		<>
			<layouts.containers.absolute
				className="top"
				width={`${imgWidth * 3}px`}
				height={`${paddingY}px`}
				borderBottom={border}
				left={`-${imgWidth}px`}
				top={`-${paddingY}px`}
				borderTop={border}
			/>
			<layouts.containers.absolute
				className="left"
				width={`${paddingX}px`}
				height={`${imgHeight * 3}px`}
				borderLeft={border}
				top={`-${imgHeight}px`}
				left={`-${paddingX}px`}
				borderRight={border}
			/>
			<layouts.containers.absolute
				className="bottom"
				width={`${imgWidth * 3}px`}
				height={`${paddingY}px`}
				borderBottom={border}
				left={`-${imgWidth}px`}
				bottom={`-${paddingY}px`}
				borderTop={border}
			/>
			<layouts.containers.absolute
				className="right"
				width={`${paddingX}px`}
				height={`${imgHeight * 3}px`}
				borderLeft={border}
				top={`-${imgHeight}px`}
				right={`-${paddingX}px`}
				borderRight={border}
			/>
		</>
	)
}

export function XIndicator(props: clearspaceGridProps): JSX.Element {
	const { imgWidth, pathbbox, focused } = props
	const indicatorBorder = `1px dashed ${layouts.theme.colors.grey.dark50}`
	const height = (focused.clearspace / 100) * (pathbbox?.height || 0) + "px"
	return (
		<layouts.containers.absolute
			className="x-indicator"
			top={pathbbox?.y + "px"}
			ml="50%"
			style={{ transform: "translateX(-100%)" }}
		>
			<layouts.Flex color={layouts.theme.colors.grey.dark50alpha80}>
				<layouts.Flex
					className={logos.layouts.borderBox}
					width={`${imgWidth}px`}
					height={height}
					borderLeft={indicatorBorder}
					borderTop={indicatorBorder}
					borderBottom={indicatorBorder}
					position="relative"
				>
					<layouts.containers.absolute
						flex="1"
						width={`${imgWidth}px`}
						top="50%"
						left="-100%"
						style={{ transform: "translateY(-50%)" }}
					>
						<layouts.Flex flex="1" alignItems="end" borderBottom={indicatorBorder} justifyContent="center">
							<typography.h6 fontSize="12px" fontWeight="500" lineHeight="14px">
								X indicator
							</typography.h6>
						</layouts.Flex>
						<layouts.Flex flex="1" justifyContent="center">
							<typography.h6 fontSize="12px" fontWeight="500" lineHeight="14px">
								{focused.clearspace}%
							</typography.h6>
						</layouts.Flex>
					</layouts.containers.absolute>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.containers.absolute>
	)
}

interface SvgPathObject {
	element: SVGPathElement
	index: number
}

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideLogos | undefined): void
	containerRef: React.RefObject<HTMLDivElement>
}

export function Display(props: props): JSX.Element {
	const { onChange, containerRef, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideLogosZero(_guide.guide?.logos))
	const items = [data.primary, data.secondary, data.logomark, data.wordmark, data.alternative].filter((l) => l?.present)
	const [focused, setFocused] = useState(items[0]!)
	const [image, setImage] = useState({ width: 0, height: 0, y: 0, x: 0 })
	const [width, setWidth] = useState(0)
	const [scrollPosition, setScrollPosition] = useState(0)

	const horContainer = useRef<HTMLDivElement>(null)
	const ref = useRef<HTMLDivElement>(null)

	const [activePath, setActivePath] = useState<SvgPathObject | undefined>(undefined)
	const [pathbbox, setPathbbox] = useState<DOMRect | undefined>(undefined)

	const svgMaxWidth = 150
	const svgMaxHeight = 150

	useEffect(() => {
		if (!ref.current) return
		ref.current.innerHTML = ""
		ref.current.style.top = "0"

		const onPathClick = (path: SVGPathElement, index: number) => {
			if (focused.clearspace === 0) setFocused({ ...focused, clearspace: 100 })
			path.style.stroke = layouts.theme.colors.blue.blue
			setActivePath({ element: path, index })
		}

		const decodedSvg = icons.svg.encodeFromB64ToSVGElement(focused.svg)
		decodedSvg.style.height = `${svgMaxHeight}px`
		decodedSvg.style.width = `${svgMaxWidth}px`

		const paths = decodedSvg.querySelectorAll("path")

		ref.current.appendChild(decodedSvg)

		let minX = Infinity
		let minY = Infinity
		let maxX = -Infinity
		let maxY = -Infinity

		paths.forEach((path, index) => {
			path.style.cursor = "pointer"
			path.addEventListener("click", () => {
				paths.forEach((p) => (p.style.stroke = ""))
				onPathClick(path, index)
			})

			const rect = path.getBoundingClientRect()
			minX = Math.min(minX, rect.left)
			minY = Math.min(minY, rect.top)
			maxX = Math.max(maxX, rect.right)
			maxY = Math.max(maxY, rect.bottom)
		})

		const width = maxX - minX
		const height = maxY - minY

		const topRate = (svgMaxHeight - height) / 2
		ref.current.style.top = `-${topRate}px`

		const bbox = decodedSvg.getBBox()
		setImage({ width: width, height: height, y: bbox.y, x: bbox.x })

		if (focused.clearspace !== 0 && focused.clearspace_path_index >= 0) {
			const el = paths[focused.clearspace_path_index]
			setActivePath({ element: el, index: focused.clearspace_path_index })
		} else setActivePath(undefined)

		return () => {
			paths.forEach((path, index) =>
				path.removeEventListener("click", () => {
					paths.forEach((p) => (p.style.stroke = ""))
					onPathClick(path, index)
				}),
			)
		}
	}, [focused.id])

	useEffect(() => {
		if (!activePath) {
			setPathbbox(undefined)
			if (!ref.current) return
			const paths = ref.current.querySelectorAll("path")
			paths.forEach((p) => (p.style.stroke = ""))
			return
		}
		const rect = activePath.element.getBoundingClientRect()
		setPathbbox(rect)
	}, [activePath, width, scrollPosition])

	useResizeObserver(horContainer, (entry) => {
		setWidth(entry.contentRect.width)
	})

	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) setScrollPosition(containerRef.current.scrollTop)
		}
		const container = containerRef.current
		if (container) container.addEventListener("scroll", handleScroll)

		return () => {
			if (container) container.removeEventListener("scroll", handleScroll)
		}
	}, [containerRef])

	return (
		<layouts.Flex {...rest} ref={horContainer}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Clearspace" Help={<logos.helps.ClearspaceModal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Select the logo then letter/shape to be used for this measurement.">
							<layouts.Flex flex="1">
								<layouts.Flex flexDirection="column" flex="1" alignItems="flex-start" justifyContent="center">
									{items.map((logo) => (
										<label key={logo?.id} style={{ marginBottom: "10px" }}>
											<input
												type="checkbox"
												name="logos"
												value={logo?.id}
												checked={logo?.id === focused.id}
												style={{ width: "15px", height: "15px", zIndex: "1" }}
												onChange={(e) => setFocused(items.find((i) => i?.id === e.target.value)!)}
											/>
											<layouts.Span display="inline-block" verticalAlign="text-top" px="10px">
												<typography.h5
													fontSize="15px"
													fontWeight="400"
													color={
														logo?.id === focused.id
															? layouts.theme.colors.blue.blue
															: layouts.theme.colors.grey.dark50alpha80
													}
												>
													{logos.layouts.LogoTitle(logo!)}
												</typography.h5>
											</layouts.Span>
										</label>
									))}
								</layouts.Flex>
								{activePath && (
									<XIndicator imgHeight={image.height} imgWidth={image.width} pathbbox={pathbbox} focused={focused} />
								)}
								<layouts.Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
									<layouts.Flex alignItems="center" className="image-container" minHeight="380px">
										<layouts.Flex position="relative" flexDirection="column" alignItems="center">
											<layouts.Flex key={focused.id} position="relative" height={image.height} width={image.width}>
												{activePath && (
													<ClearspaceGrid
														key={focused.id}
														imgHeight={image.height}
														imgWidth={image.width}
														pathbbox={pathbbox}
														focused={focused}
													/>
												)}
											</layouts.Flex>

											<layouts.containers.absolute
												ref={ref}
												className={logos.layouts.ContrastBGColorClassName(focused.svg)}
											/>
											<layouts.containers.absolute zIndex="1" className="actions" bottom="-50%" minWidth="200px">
												{activePath ? (
													<layouts.Flex>
														{[100, 50, 25].map((i) => (
															<label key={i} style={{ marginTop: "10px" }}>
																<input
																	type="checkbox"
																	name="clearspace"
																	value={i}
																	checked={i === focused.clearspace}
																	onChange={(e) =>
																		setFocused({
																			...focused,
																			clearspace: Number(e.target.value),
																			clearspace_path_index: activePath.index,
																		})
																	}
																/>
																<layouts.Span display="inline-block" verticalAlign="text-top" px="10px">
																	<typography.h5
																		fontSize="13px"
																		fontWeight="400"
																		color={layouts.theme.colors.grey.dark50alpha80}
																	>
																		{i}%
																	</typography.h5>
																</layouts.Span>
															</label>
														))}
													</layouts.Flex>
												) : (
													<layouts.Flex>
														<typography.h5
															fontSize="13px"
															fontWeight="400"
															color={layouts.theme.colors.grey.dark50alpha80}
														>
															Hover over the letter or shape you want for the X height
														</typography.h5>
													</layouts.Flex>
												)}
											</layouts.containers.absolute>
											{activePath && (
												<layouts.containers.absolute right="-100%">
													<layouts.Flex flexDirection="column" mx="10px" gridGap="20px">
														<layouts.tooltips.default text="Apply">
															<layouts.Flex
																{...logos.layouts.actionButtonsProps}
																background={
																	focused.clearspace !== items.find((i) => i?.id === focused?.id)?.clearspace
																		? layouts.theme.colors.grey.dark50alpha60
																		: layouts.theme.colors.grey.dark50alpha05
																}
																onClick={() => {
																	if (focused.clearspace === items.find((i) => i?.id === focused?.id)?.clearspace)
																		return
																	setData(clearspaceUpdate(data, focused))
																}}
															>
																<icons.brandguide.Done />
															</layouts.Flex>
														</layouts.tooltips.default>
														<layouts.tooltips.default text="Apply All">
															<layouts.Flex
																{...logos.layouts.actionButtonsProps}
																background={
																	items.filter((i) => i?.clearspace !== focused.clearspace).length > 0
																		? layouts.theme.colors.grey.dark50alpha60
																		: layouts.theme.colors.grey.dark50alpha05
																}
																onClick={() => {
																	if (items.filter((i) => i?.clearspace !== focused.clearspace).length === 0) return
																	setData(clearspaceUpdateAll(data, focused.clearspace))
																}}
															>
																<icons.brandguide.DoneAll />
															</layouts.Flex>
														</layouts.tooltips.default>
														<layouts.tooltips.default text="Skip">
															<layouts.Flex
																{...logos.layouts.actionButtonsProps}
																onClick={() => setActivePath(undefined)}
															>
																<icons.brandguide.EyeHide />
															</layouts.Flex>
														</layouts.tooltips.default>
														<layouts.tooltips.default text="Start Over">
															<layouts.Flex
																{...logos.layouts.actionButtonsProps}
																background={
																	items.filter((i) => i?.clearspace === 0).length === 0
																		? layouts.theme.colors.grey.dark50alpha60
																		: layouts.theme.colors.grey.dark50alpha05
																}
																onClick={() => {
																	if (items.filter((i) => i?.clearspace !== 0).length === 0) return
																	setData(clearspaceUpdateAll(data, 0))
																}}
															>
																<icons.Close fill={layouts.theme.colors.white} height="16px" width="16px" />
															</layouts.Flex>
														</layouts.tooltips.default>
													</layouts.Flex>
												</layouts.containers.absolute>
											)}
										</layouts.Flex>
									</layouts.Flex>
								</layouts.Flex>
							</layouts.Flex>

							<logos.Explanation
								val={data.descriptions?.clearspace || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.logos.descZero({ ...data.descriptions, clearspace: text }),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(logos.Steps.UPLOAD, undefined)}
								onNext={() => onChange(logos.Steps.PROPORTIONS, data)}
								disabled={!brandguide.validations.logos.valid.clearspace(data)}
								flex="unset"
							/>
							<brandguide.build.layouts.SkipStep
								my="30px"
								onSkip={() => onChange(logos.Steps.PROPORTIONS, data)}
								onNeverHave={() => onChange(logos.Steps.PROPORTIONS, data)}
							/>
							<logos.Progress activeStep={logos.Steps.CLEARSPASE} steps={logos.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<logos.Guidelines activPage="logos" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

const clearspaceUpdateAll = (data: brandguide.api.GuideLogos, upd: number): brandguide.api.GuideLogos => {
	const logos = brandguide.api.zeros.guide.guideLogosZero(data)
	logos.primary!.clearspace = upd
	logos.secondary!.clearspace = upd
	logos.logomark!.clearspace = upd
	logos.wordmark!.clearspace = upd
	logos.alternative!.clearspace = upd
	return logos
}

const clearspaceUpdate = (
	data: brandguide.api.GuideLogos,
	upd: brandguide.api.LogoObject,
): brandguide.api.GuideLogos => {
	const logos = brandguide.api.zeros.guide.guideLogosZero(data)
	if (upd.id === "primary") logos.primary = upd
	if (upd.id === "secondary") logos.secondary = upd
	if (upd.id === "logomark") logos.logomark = upd
	if (upd.id === "wordmark") logos.wordmark = upd
	if (upd.id === "alternative") logos.alternative = upd
	return logos
}

export default Display

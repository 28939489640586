import React from "react"

export function enter(
	fn: (e: React.KeyboardEvent<HTMLInputElement>) => void,
): (e: React.KeyboardEvent<HTMLInputElement>) => void {
	return (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key !== "Enter") return
		fn(e)
	}
}

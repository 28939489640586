import React, { useState, useEffect } from "react"
import * as timex from "timex"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "brandguard/api"
import * as uuidx from "x/uuidx"
import * as bglayouts from "brandguard/components/layouts"
import * as bgapprovals from "brandguard/display/approval"
import * as authz from "authz"
import * as authzc from "authzcached"
import classnames from "classnames"
import * as debugx from "x/debugx"
import * as icons from "icons"
import JSZip from "jszip"
import * as media from "media"
import * as training from "brandguard/display/training"
import * as simports from "./suggestions.imports"
import { Details } from "."
import * as inputs from "inputs"
import * as caching from "brandguard/display/training/cache"
import { CancellablePromise } from "real-cancellable-promise"

export default function Suggestions(props: React.PropsWithChildren<unknown>): JSX.Element {
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current
	const [loading, setLoading] = useState(true)
	const [selectedItem, setSelectedItem] = useState(undefined as api.ImageSearchResponseItem | undefined)
	const [checkedItems, setCheckedItems] = useState([] as api.ImageSearchResponseItem[])
	const [undoItems, setUndoItems] = useState<api.ImageSearchResponseItem[]>([])
	const [filterFocused, setFilterFocused] = useState(false)
	const mtoggle = layouts.modals.useToggle()
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const cachekey = `suggestionsimguploads.${brand.id}`

	const _searchReq = api.images.uploads.searches.zero({
		offset: 0n,
		limit: 20n,
		brand_id: brand.id,
		queues: [uuidx.Max],
		observation: [api.Prediction.NONE],
		approved: [api.Prediction.REJECTED],
	})

	const [searchReq, setSearchReq] = useState(_searchReq)
	const [cursorKey, setCursorKey] = useState(uuid.v4())

	const [images, setImages] = useState({
		items: [],
		next: searchReq,
	} as api.ImageSearchResponse)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.images.reviewable.search(searchReq.brand_id, searchReq, bearertoken))
			.then(setImages)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => console.error("unable to display suggested training image", cause))
			.finally(() => {
				setLoading(false)
			})
		return p.cancel
	}, [searchReq])

	const onImageUpdate = (item: api.ImageSearchResponseItem): void => {
		const remove = (items: api.ImageSearchResponseItem[]) => items.filter((i) => i.media?.id !== item.media?.id)
		const replace = (items: api.ImageSearchResponseItem[]) =>
			items.map((i) => (i.media?.id === item.media?.id ? item : i))
		const removed = timex.from.iso(item.media?.tombstoned_at || timex.infinity().toISO()) < timex.local()
		const op = removed ? remove : replace
		setImages((prevState) => ({ ...prevState, items: op(prevState.items) }))
	}

	const approve = (
		item: api.ImageSearchResponseItem & { event: api.ImageEvent },
	): Promise<api.ImageSearchResponseItem> => {
		const retry = httpx.autoretry()
		return retry
			.wrap(() =>
				api.images.training.transfer(
					item.event.id,
					item.event.brand_id,
					{ ...item, event: { ...item.event, observation: api.Prediction.APPROVED } },
					authzc.bearer(authzaccount),
				),
			)
			.then((resp) => {
				setUndoItems([...undoItems, ...[item]])
				setImages((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === resp.media?.id ? resp : i)),
				}))
				if (selectedItem && selectedItem.media?.id === resp.media?.id) setSelectedItem(resp)
				api.images.uploads.destroy(item.event.brand_id, item.event.id, authzc.bearer(authzaccount))
				return resp
			})
	}

	const reject = async (
		item: api.ImageSearchResponseItem & { event: api.ImageEvent },
	): Promise<api.ImageSearchResponseItem> => {
		const retry = httpx.autoretry()
		return retry
			.wrap(() =>
				api.images.training.transfer(
					item.event.id,
					item.event.brand_id,
					{ ...item, event: { ...item.event, observation: api.Prediction.REJECTED } },
					authzc.bearer(authzaccount),
				),
			)
			.then((resp) => {
				setUndoItems([...undoItems, ...[item]])
				setImages((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === resp.media?.id ? resp : i)),
				}))
				if (selectedItem && selectedItem.media?.id === resp.media?.id) setSelectedItem(resp)
				api.images.uploads.destroy(item.event.brand_id, item.event.id, authzc.bearer(authzaccount))
				return resp
			})
	}

	const undo = (
		current: api.ImageSearchResponseItem & { event: api.ImageEvent },
		previously: api.ImageSearchResponseItem & { event: api.ImageEvent },
	): Promise<api.ImagePatchResponse> => {
		const retry = httpx.autoretry()
		return retry
			.wrap(() =>
				api.images.uploads.patch(
					previously.event.id,
					previously.event.brand_id,
					previously,
					authzc.bearer(authzaccount),
				),
			)
			.then((resp_i) => {
				setUndoItems(undoItems.filter((i) => i.media?.id !== previously.media?.id))
				setImages((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === previously.media?.id ? previously : i)),
				}))
				if (selectedItem && selectedItem.media?.id === previously.media?.id) setSelectedItem(previously)
				return resp_i
			})
			.then((upload) => {
				return api.images.training
					.find(current.event.id, current.event.brand_id, bearertoken)
					.then((result) => {
						return api.images.training
							.destroy(result.event!.brand_id, result.event!.id, authzc.bearer(authzaccount))
							.then(() => {
								return upload
							})
					})
					.catch(
						httpx.errors.notFound((cause) => {
							console.debug("unable to find training images", cause)
							// ignore 404s
							return upload
						}),
					)
			})
	}

	const setUnion = (upd: api.ImageSearchResponseItem): void => {
		setImages((prevState) => ({
			...prevState,
			items: [upd, ...prevState.items.filter((o) => o.media?.id !== upd.media?.id)],
		}))
	}

	const _allselected = images.items.length > 0 && checkedItems.length === images.items.length

	const handleSelectAll = (): void => {
		if (_allselected) {
			setCheckedItems([])
			return
		}
		setCheckedItems(images.items)
	}

	const fcnt = bgapprovals.CalculateFilterSCount(_searchReq, searchReq)

	const SelectedDisplay = () => {
		if (!selectedItem) return <></>
		return (
			<layouts.containers.absolute zIndex="1">
				<Details
					item={selectedItem}
					canAction={permission.brandguard_upload}
					deleteAction={api.images.uploads.patch}
					onDelete={(results) => {
						setSelectedItem(undefined)
						setImages((prevState) => ({
							...prevState,
							items: prevState.items.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
						}))
					}}
					onClose={() => setSelectedItem(undefined)}
					status={
						<training.display.status.Container>
							<training.display.status.Suggestion
								item={selectedItem}
								previtemstate={undoItems.find((u) => u.media?.id === selectedItem.media?.id)}
								agree={approve}
								disagree={reject}
								undo={undo}
								borderRadius="10px"
							/>
						</training.display.status.Container>
					}
				/>
			</layouts.containers.absolute>
		)
	}

	return (
		<layouts.containers.flex flexDirection="column-reverse" overflow="hidden" data-testid="img-training-review-display">
			<layouts.pagination.Cursor
				key={cursorKey}
				my="auto"
				justifyContent="center"
				current={searchReq.offset}
				advance={Number(images.next!.offset) === -1 ? undefined : images.next?.offset}
				onChange={(next) => {
					setSearchReq({
						...searchReq,
						offset: next,
					})
				}}
			/>
			<layouts.loading.screen
				className="brandguard-working-area"
				loading={loading}
				flex="1"
				overflow="hidden"
				icon={<></>}
				flexDirection="row"
			>
				<layouts.containers.flex
					className="center-panel"
					flex="1"
					flexDirection="column"
					position="relative"
					alignItems="center"
					justifyContent="center"
				>
					<SelectedDisplay />
					<layouts.containers.flex flexDirection="column" flex="1" minHeight="100px" width="100%">
						<bglayouts.OverlaysContainer overflow="auto" background={layouts.theme.backgrounds.whitealpha80}>
							<layouts.containers.ResponsiveGrid gap={20} p="25px">
								{images.items.map((item) => {
									return (
										<bgapprovals.images.CardDisplay
											key={item.media?.id + undoItems.length.toString()}
											item={item}
											active={selectedItem && item?.media?.id === selectedItem.media?.id}
											onCardClick={() => {
												setSelectedItem(item)
											}}
											onChange={onImageUpdate}
											onChecked={(checked) =>
												setCheckedItems((prevState) =>
													checked ? [...prevState, item] : prevState.filter((i) => i.media?.id !== item.media?.id),
												)
											}
											panel={
												<training.display.status.Container>
													<training.display.status.Suggestion
														item={item}
														previtemstate={undoItems.find((u) => u.media?.id === item.media?.id)}
														agree={approve}
														disagree={reject}
														undo={undo}
														borderRadius="0 0 10px 10px"
													/>
												</training.display.status.Container>
											}
											find={api.images.uploads.find}
											checked={checkedItems.some((i) => i.media?.id === item.media?.id)}
										/>
									)
								})}
							</layouts.containers.ResponsiveGrid>
							<layouts.loading.pending loading={loading || images.next?.offset === searchReq.offset}>
								{images.items.length === 0 && (
									<training.layouts.SuggestionsZeroState
										flexDirection="column"
										flex="1"
										pt="20px"
										alignItems="center"
									/>
								)}
							</layouts.loading.pending>
						</bglayouts.OverlaysContainer>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.loading.screen>
			<layouts.containers.flex flexDirection="row" className={classnames(bgapprovals.layouts.styledactions)}>
				<layouts.Flex alignItems="baseline" mr="5px">
					<layouts.buttons.outline
						height="40px"
						borderRadius="0.25em"
						className={training.layouts.IconHoverStyles}
						onClick={() =>
							mtoggle(
								<simports.FileModal
									onChange={(img) => {
										caching.uploads.img.remove(cachekey, [img])
										setUnion(img)
									}}
									upload={(stats, content) => {
										const delay = training.uploads.next(cachekey)
										const data = new FormData()
										data.append("observation", api.Prediction.NONE.toString())
										data.append("delay", delay.toMillis().toFixed(0))
										return api.images.reviewable
											.upload(data, content, brand.id, authzc.bearer(authzaccount))
											.then((resp) => {
												caching.uploads.img.add(cachekey, [resp])
												return resp
											})
									}}
									resume={() => {
										return caching.uploads.img.getasync(cachekey).then((imgs) => {
											return imgs
												.filter((img) => img.event?.observation === api.Prediction.NONE)
												.map(
													(i) =>
														({
															id: uuid.v4(),
															display: i.media?.description,
															background: layouts.theme.colors.blue.blue,
															data: i,
														} as inputs.uploads.api.uploaded<api.ImageSearchResponseItem>),
												)
										})
									}}
									refresh={(data) => {
										return api.images.uploads
											.find(data.media?.id!, brand.id, bearertoken)
											.catch(
												httpx.errors.notFound((cause) => {
													console.debug("unable to find suggestion file upload images", cause)
													return api.images.training.find(data.media?.id!, brand.id, bearertoken)
												}),
											)
											.then((resp) => {
												if (resp.event?.approved === api.Prediction.ERROR) {
													caching.uploads.img.remove(cachekey, [data])
													return CancellablePromise.reject(resp)
												} else {
													return CancellablePromise.resolve(resp)
												}
											})
									}}
								/>,
							)
						}
					>
						<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
						<layouts.Span px="10px">Import from File</layouts.Span>
					</layouts.buttons.outline>
				</layouts.Flex>
				<authz.Protected enabled={debugx.alpha.enabled()}>
					<layouts.Flex alignItems="baseline" mr="5px">
						<layouts.buttons.outline
							height="40px"
							borderRadius="0.25em"
							className={training.layouts.IconHoverStyles}
							onClick={() =>
								mtoggle(
									<simports.URLModal
										enqueue={(url) => {
											const req = { brand_id: brand.id, url: url } as api.ImportFromURLRequest
											return api.images.reviewable.urlimport(brand.id, req, authzc.bearer(authzaccount))
										}}
									/>,
								)
							}
						>
							<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
							<layouts.Span px="10px">Import from URL</layouts.Span>
						</layouts.buttons.outline>
					</layouts.Flex>
					<layouts.Flex alignItems="baseline" mr="5px">
						<layouts.buttons.outline
							height="40px"
							borderRadius="0.25em"
							className={training.layouts.IconHoverStyles}
							onClick={() =>
								mtoggle(
									<simports.StockModal
										enqueue={(num) => {
											const req = { brand_id: brand.id, number: num } as api.StockImportRequest
											return api.images.reviewable.stockimport(brand.id, req, authzc.bearer(authzaccount))
										}}
									/>,
								)
							}
						>
							<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
							<layouts.Span px="10px">Import Stock Images</layouts.Span>
						</layouts.buttons.outline>
					</layouts.Flex>
					<layouts.Flex alignItems="baseline" mr="5px">
						<layouts.buttons.outline
							height="40px"
							borderRadius="0.25em"
							onClick={() =>
								mtoggle(
									<simports.GoogleImagesModal
										enqueue={(w, n) => {
											const req = { brand_id: brand.id, keywords: w, number: n } as api.GoogleImagesRequest
											return api.images.reviewable.googleimagesimport(brand.id, req, authzc.bearer(authzaccount))
										}}
									/>,
								)
							}
						>
							<layouts.Flex alignItems="center">
								<icons.Google height="20px" width="20px" />
								<layouts.Span px="10px">Import Google Images</layouts.Span>
							</layouts.Flex>
						</layouts.buttons.outline>
					</layouts.Flex>
				</authz.Protected>
				<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative">
					<bgapprovals.layouts.FilterButton
						width="145px"
						height="40px"
						onClick={() => {
							setFilterFocused(!filterFocused)
						}}
						className={fcnt > 0 ? "active" : ""}
					>
						<layouts.containers.flex flexDirection="row" justifyContent="center">
							<layouts.containers.box>
								<icons.bgapproval.filter />
							</layouts.containers.box>
							<layouts.containers.box px="10px">Filter{fcnt > 0 ? `/${fcnt}` : ""}</layouts.containers.box>
							<layouts.containers.box>
								{filterFocused ? <icons.bgapproval.arrowDown /> : <icons.bgapproval.arrowDown />}
							</layouts.containers.box>
						</layouts.containers.flex>
					</bgapprovals.layouts.FilterButton>
					<layouts.containers.flex display={filterFocused ? "flex" : "none"} position="absolute" top="100%" pt="5px">
						<bgapprovals.FilterDisplay
							onFilterClear={() => {
								setSearchReq(_searchReq)
								setCursorKey(uuid.v4())
							}}
							_searchReq={_searchReq}
							onFilterChange={(r) => {
								setSearchReq({ ...searchReq, ...r, ...{ offset: 0n } })
								setCursorKey(uuid.v4())
							}}
						/>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex className="actions" justifyContent="flex-end" flex="10">
					<bgapprovals.layouts.SelectAll
						disabled={images.items.length === 0}
						cursor="pointer"
						className="select-all-action"
						onClick={handleSelectAll}
					>
						{_allselected ? "Unselect All" : "Select All"}
					</bgapprovals.layouts.SelectAll>
					<authz.Protected
						enabled={permission.brandguard_upload && checkedItems.length > 0}
						rejected={
							<>
								<layouts.containers.flex className="download-action">
									<icons.brandguard.download />
								</layouts.containers.flex>
								<layouts.containers.flex className="remove-action">
									<icons.brandguard.remove />
								</layouts.containers.flex>
							</>
						}
					>
						<layouts.containers.flex
							className="download-action"
							cursor="pointer"
							onClick={() => {
								const zip = new JSZip()
								const promises: Promise<api.ImagePatchRequest | JSZip>[] = []
								checkedItems.forEach((i) => {
									promises.push(
										media.cache.current(authzaccount.current.id, i.media!.md5, bearertoken).then((url) => {
											return fetch(url)
												.then((response) => {
													return response.arrayBuffer()
												})
												.then((imageBuffer) => {
													const imageName = media.downloads.filename(i.media!.md5, i.media!.mimetype)
													return zip.file(imageName, imageBuffer)
												})
										}),
									)
								})
								Promise.all(promises).then(() => {
									zip.generateAsync({ type: "blob" }).then((content) => {
										const downloadLink = document.createElement("a")
										downloadLink.href = URL.createObjectURL(content)
										downloadLink.download = "brandguard-assets.zip"
										downloadLink.click()
									})
								})
							}}
						>
							<icons.brandguard.download />
						</layouts.containers.flex>
						<layouts.containers.flex
							className="remove-action"
							cursor="pointer"
							onClick={() => {
								const promises: Promise<api.ImagePatchRequest>[] = []
								checkedItems.forEach((i) => {
									promises.push(api.images.uploads.destroy(i.media!.brand_id, i.media!.id, authzc.bearer(authzaccount)))
								})
								Promise.all(promises)
									.then((results) => {
										setCheckedItems((prevState) =>
											prevState.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
										)
										setImages((prevState) => ({
											...prevState,
											items: prevState.items.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
										}))
									})
									.catch((cause) => console.error("unable to set brandguard training images", cause))
									.finally(() => {
										setSearchReq((prevState) => ({ ...prevState, offset: 0n }))
										setCursorKey(uuid.v4())
									})
							}}
						>
							<icons.brandguard.remove />
						</layouts.containers.flex>
					</authz.Protected>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function root(bid: string): string {
	return `/brands/${bid}/brandguard`
}

export namespace image {
	export function upload(bid: string): string {
		return `${root(bid)}/image/upload`
	}

	export function accepted(bid: string): string {
		return `${root(bid)}/image/accepted`
	}

	export function rejected(bid: string): string {
		return `${root(bid)}/image/rejected`
	}

	export function review(bid: string): string {
		return `${root(bid)}/image/review`
	}

	export function score(bid: string): string {
		return `${root(bid)}/image/score`
	}

	export namespace approval {
		export function upload(bid: string): string {
			return `${root(bid)}/approval/image`
		}

		export function approved(bid: string): string {
			return `${root(bid)}/approval/image/approved`
		}

		export function rejected(bid: string): string {
			return `${root(bid)}/approval/image/rejected`
		}

		export function review(bid: string): string {
			return `${root(bid)}/approval/image/review`
		}
	}
}

export namespace text {
	export function upload(bid: string): string {
		return `${root(bid)}/text/upload`
	}

	export function accepted(bid: string): string {
		return `${root(bid)}/text/accepted`
	}

	export function rejected(bid: string): string {
		return `${root(bid)}/text/rejected`
	}

	export function review(bid: string): string {
		return `${root(bid)}/text/review`
	}

	export function score(bid: string): string {
		return `${root(bid)}/text/score`
	}

	export namespace approval {
		export function upload(bid: string): string {
			return `${root(bid)}/approval/text`
		}

		export function approved(bid: string): string {
			return `${root(bid)}/approval/text/approved`
		}

		export function rejected(bid: string): string {
			return `${root(bid)}/approval/text/rejected`
		}

		export function review(bid: string): string {
			return `${root(bid)}/approval/text/review`
		}
	}
}

import React, { useState, useMemo } from "react"
import { CancellablePromise } from "real-cancellable-promise"
import * as layouts from "layouts"
import * as brands from "brands"
import * as api from "brandguard/api"
import * as authzc from "authzcached"
import * as inputs from "inputs"
import JSON from "jsonx"
import * as errors from "errors"
import * as sessions from "sessions"
import classnames from "classnames"
import * as authz from "authz"
import * as bgtexts from "brandguard/display/brandguard/text"
import * as bglayouts from "brandguard/components/layouts"
import { UploadSimple } from "brandguard/components"
import * as predictions from "brandguard/predictions"
import * as uuid from "uuid"
import * as nav from "brandguard/components/navigation"
import * as caching from "brandguard/display/training/cache"
import * as httpx from "httpx"
import { uploads } from "brandguard/display/training"
import * as bgscorelayouts from "brandguard/display/scores/layouts"

interface props extends layouts.containers.FlexProps {
	onChange(text: api.TextSearchResponseItem): void
}

export function TextFromFileModal(props: React.PropsWithChildren<props>): JSX.Element {
	const { onChange, ...rest } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const cachekey = `suggestionstextuploads.${brand.id}`
	const mtoggle = layouts.modals.useToggle()

	const MemoUpload = useMemo(() => {
		return function MemodUpload(props: inputs.uploads.api.Props) {
			return (
				<authz.Protected
					enabled={permission.brandguard_upload}
					rejected={
						<layouts.containers.flex flex="1" className={classnames(bglayouts.uploads.navdenied)}>
							<authz.layouts.UploadDenied className="styled-area">
								You do not have permissions to upload new assets for scoring. Please contact an admin.
							</authz.layouts.UploadDenied>
						</layouts.containers.flex>
					}
				>
					<bgtexts.uploads.default
						background={layouts.theme.colors.blue.blue}
						observation={api.Prediction.NONE}
						{...props}
					/>
				</authz.Protected>
			)
		}
	}, [])

	return (
		<UploadSimple
			className={classnames(nav.layouts.slideDownAnimation)}
			isProcessing={(i: api.TextSearchResponseItem) => predictions.processing(i.event?.approved)}
			onChange={(text) => {
				caching.uploads.text.remove(cachekey, [{ description: "", ...text }])
				onChange(text)
			}}
			resume={() => {
				return caching.uploads.text.getasync(cachekey).then((texts) => {
					return texts
						.filter((img) => img.event?.observation === api.Prediction.NONE)
						.map(
							(t) =>
								({
									id: uuid.v4(),
									display: t.description,
									background: layouts.theme.colors.blue.blue,
									data: t,
								} as inputs.uploads.api.uploaded<api.TextSearchResponseItem>),
						)
				})
			}}
			refresh={(data) => {
				return api.text.uploads
					.find(data.media?.id!, brand.id, bearertoken)
					.catch(
						httpx.errors.notFound((cause) => {
							console.debug("unable to load text training display", cause)
							return api.text.training.find(data.media?.id!, brand.id, bearertoken)
						}),
					)
					.then((resp) => {
						if (resp.event?.approved === api.Prediction.ERROR) {
							caching.uploads.text.remove(cachekey, [{ description: "", ...data }])
							return CancellablePromise.reject(resp)
						} else {
							return CancellablePromise.resolve(resp)
						}
					})
			}}
			upload={(stats, content) => {
				const delay = uploads.next(cachekey)
				const data = new FormData()
				data.append("observation", api.Prediction.NONE.toString())
				data.append("delay", delay.toMillis().toFixed(0))
				return api.text.reviewable.upload(data, content, brand.id, authzc.bearer(metaauthz)).then((resp) => {
					caching.uploads.text.add(cachekey, [{ description: content.name, ...resp }])
					return resp
				})
			}}
			DropWell={MemoUpload}
			onClose={() => mtoggle(undefined)}
			{...rest}
		/>
	)
}

export function UserPrompt(
	props: { enqueue: (keywords: string, num: number) => Promise<void> } & layouts.containers.FlexProps,
): JSX.Element {
	const [prompt, setPrompt] = useState("")
	const [num, setNum] = useState(0)
	const assertions = [
		inputs.assertions.strings.min(3)(prompt),
		inputs.assertions.strings.max(32768)(prompt),
		inputs.assertions.numeric.max(100)(num),
		inputs.assertions.numeric.min(1)(num),
	]
	const disabled = inputs.assertions.failed(...assertions)

	return (
		<layouts.forms.Container flexDirection="row" flex="1">
			<layouts.forms.Group flex="1" flexDirection="column">
				<layouts.Flex flexDirection="row" mb="10px" className={classnames(bgscorelayouts.zerotextinsert)}>
					<bglayouts.uploads.TextArea
						placeholder="Enter prompt"
						value={prompt}
						onChange={(evt) => {
							setPrompt(evt.target.value)
						}}
					/>
				</layouts.Flex>
				<layouts.Flex flexDirection="row" mb="10px" width="100px">
					<inputs.Numeric
						padding="0px 5px"
						placeholder="count of generating assets"
						onChange={(evt) => setNum(parseInt(evt.currentTarget.value))}
					/>
				</layouts.Flex>
				<layouts.Flex flexDirection="row" justifyContent="flex-end" width="100%" py="5px">
					<layouts.buttons.primary
						borderRadius="0.25em"
						disabled={disabled}
						onClick={() => {
							props.enqueue(prompt, num).catch((cause) => {
								console.error("unable to display generating assets", cause)
							})
						}}
					>
						Send
					</layouts.buttons.primary>
				</layouts.Flex>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}

export default function Generating(props: React.PropsWithChildren<inputs.uploads.api.Props>): JSX.Element {
	const { onChange, children } = props
	const brand = brands.caching.useCached()
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(false)

	return (
		<>
			{cause && <layouts.containers.flex justifyContent="center">{cause}</layouts.containers.flex>}
			{children}
			<layouts.loading.pending loading={loading} pb="60px">
				<UserPrompt
					enqueue={(k: string, n: number) => {
						setLoading(true)
						return api.text.reviewable
							.textgeneration(brand.id, { prompt: k, count: n } as api.TextGenerationRequest, authzc.bearer(metaauthz))
							.catch((c: unknown) => {
								setCause(
									<errors.Inline flex="1" p="5px" mb="5px">
										<errors.Textual cause={c} onClick={() => setCause(undefined)}>
											<layouts.Span color={layouts.theme.colors.white} fontSize="12px">
												Unable to display generating texts from prompt
											</layouts.Span>
										</errors.Textual>
									</errors.Inline>,
								)
								return Promise.reject(c)
							})
							.then((r) => {
								r.items.forEach((i) => {
									const content = JSON.stringify(i, undefined, 2)
									const file = new File([new Blob([content])], "generating", { type: "text/json" })
									onChange([file])
								})
								setLoading(false)
							})
					}}
				/>
			</layouts.loading.pending>
		</>
	)
}

export function TextGenerationModal(props: React.PropsWithChildren<props>): JSX.Element {
	const { onChange, ...rest } = props
	const brand = brands.caching.useCached()
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const bearertoken = sessions.useToken()
	const mtoggle = layouts.modals.useToggle()
	const cachekey = `suggestionstextgenerating.${brand.id}`

	const MemoGenerating = useMemo(() => {
		return function MemodGenerating(props: inputs.uploads.api.Props) {
			return (
				<authz.Protected
					enabled={permission.brandguard_upload}
					rejected={
						<layouts.containers.flex flex="1" className={classnames(bglayouts.uploads.navdenied)}>
							<authz.layouts.UploadDenied className="styled-area">
								You do not have permissions to upload new assets for scoring. Please contact an admin.
							</authz.layouts.UploadDenied>
						</layouts.containers.flex>
					}
				>
					<Generating {...props} />
				</authz.Protected>
			)
		}
	}, [])

	const readFileAsJson = (file: File): CancellablePromise<api.TextSearchResponseItem> => {
		const promise = new Promise<api.TextSearchResponseItem>((resolve, reject) => {
			const reader = new FileReader()

			reader.onload = (event: ProgressEvent<FileReader>) => {
				const fileContent = event.target?.result as string
				try {
					const jsonData = JSON.parse(fileContent)
					resolve(jsonData)
				} catch (error) {
					reject(error)
				}
			}

			reader.onerror = (event: ProgressEvent<FileReader>) => {
				reject(event.target?.error)
			}

			reader.readAsText(file)
		})
		return new CancellablePromise<api.TextSearchResponseItem>(Promise.resolve(promise), () => {})
	}

	return (
		<UploadSimple
			title="Generate Assets"
			className={classnames(nav.layouts.slideDownAnimation)}
			isProcessing={(i: api.TextSearchResponseItem) => predictions.processing(i.event?.approved)}
			onChange={(text) => {
				caching.uploads.text.remove(cachekey, [{ description: "", ...text }])
				onChange(text)
			}}
			resume={() => {
				return caching.uploads.text.getasync(cachekey).then((texts) => {
					return texts.map(
						(t) =>
							({
								id: uuid.v4(),
								display: t.description,
								background: layouts.theme.colors.blue.blue,
								data: t,
							} as inputs.uploads.api.uploaded<api.TextSearchResponseItem>),
					)
				})
			}}
			refresh={(data) => {
				return api.text.uploads
					.find(data.media?.id!, brand.id, bearertoken)
					.catch(
						httpx.errors.notFound((cause) => {
							console.debug("unable to load text training display", cause)
							return api.text.training.find(data.media?.id!, brand.id, bearertoken)
						}),
					)
					.then((resp) => {
						if (resp.event?.approved === api.Prediction.ERROR) {
							caching.uploads.text.remove(cachekey, [{ description: "", ...data }])
							return CancellablePromise.reject(resp)
						} else {
							return CancellablePromise.resolve(resp)
						}
					})
			}}
			upload={(stats, content) => {
				return readFileAsJson(content).then((resp) => {
					caching.uploads.text.add(cachekey, [{ description: content.name, ...resp }])
					return resp
				})
			}}
			DropWell={MemoGenerating}
			onClose={() => mtoggle(undefined)}
			{...rest}
		/>
	)
}

import * as layouts from "layouts"
import { css } from "@emotion/css"
import * as icons from "icons"
import * as typography from "typography"

const styled = css`
	.progress-container::before {
		content: "";
		background-color: #cfcfcf;
		position: absolute;
		top: 25%;
		left: 0;
		transform: translateY(-50%);
		height: 3px;
		width: 100%;
	}

	.progress {
		transition: 0.4s ease;
	}
`

interface props extends layouts.containers.ContainerProps {
	steps: string[]
	activeStep: number
}

function Progress(props: props): JSX.Element {
	const { steps, activeStep, ...rest } = props
	return (
		<layouts.Flex className={styled} {...rest}>
			<layouts.Flex flex="1" className="progress-container" justifyContent="space-between" position="relative">
				<layouts.containers.absolute
					background={layouts.theme.colors.grey.dark20}
					top="20%"
					left="0"
					height="5px"
					width={`${(activeStep / (steps.length - 1)) * 100 + "%"}`}
					className="progress"
				></layouts.containers.absolute>
				{steps.map((step, index) => (
					<layouts.Flex key={index} flexDirection="column" alignItems="center">
						<layouts.Flex
							borderRadius="50%"
							height="15px"
							width="15px"
							alignItems="center"
							justifyContent="center"
							border="3px solid #CFCFCF"
							zIndex={1}
							background={index < activeStep ? layouts.theme.colors.grey.dark20 : layouts.theme.colors.white}
							borderColor={index <= activeStep ? layouts.theme.colors.grey.dark20 : ""}
						>
							{index < activeStep && <icons.brandguide.ProgressCheck />}
						</layouts.Flex>
						<typography.h6
							fontSize="12px"
							fontWeight="400"
							color={index <= activeStep ? layouts.theme.colors.grey.dark50 : "#CFCFCF"}
						>
							{step}
						</typography.h6>
					</layouts.Flex>
				))}
			</layouts.Flex>
		</layouts.Flex>
	)
}

Progress.defaultProps = {
	justifyContent: "center",
	alignItems: "flex-end",
}

export default Progress

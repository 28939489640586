import React, { useContext } from "react"
import * as uuid from "uuid"

export interface ctx {
	bid: string
	collapsed: boolean
	hidden: boolean
	unread_notification_cnt: number
	collapse(upd: boolean): void
	update(upd: Partial<ctx>): void
}

export function zero(partial: Partial<ctx> = {}): ctx {
	return {
		bid: uuid.NIL,
		collapsed: false,
		hidden: false,
		unread_notification_cnt: 0,
		collapse: (upd: boolean) => {},
		update: (upd: Partial<ctx>) => {},
		...partial,
	}
}

export function useCached() {
	return useContext(Context)
}

export const Context = React.createContext(zero())
export const Provider = Context.Provider

import React, { useState } from "react"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import * as md5x from "md5x"
import * as api from "brandguard/api"
import * as training from "brandguard/display/training"

interface props extends layouts.containers.FlexProps {
	settings: api.TextKernelReferenceRulesEnabled
	onChange(upd: api.TextKernelReferenceRulesEnabled): void
}

export default function ReferenceRules(props: React.PropsWithChildren<props>): JSX.Element {
	const widthProps = { width: "12ch" }
	const { settings, onChange, ...rest } = props
	const [description, setDescription] = useState("")
	const [explanation, setExplanation] = useState("")
	const [rule, setRule] = useState("")
	const [reset, setReset] = useState(uuid.v4())
	const disabled = inputs.assertions.failed(
		inputs.assertions.strings.min(1)(description),
		inputs.assertions.strings.max(512)(description),
		inputs.assertions.strings.max(512)(rule),
		inputs.assertions.strings.reference_rules()(rule),
		inputs.assertions.strings.min(1)(explanation),
		inputs.assertions.strings.max(1024)(explanation),
	)

	const resetForm = () => {
		setDescription("")
		setRule("")
		setExplanation("")
		setReset(uuid.v4())
	}

	const rules = (settings.rules || []).map((r) => (
		<layouts.Flex key={r.id} py="5px" flexDirection="column">
			<layouts.Flex>
				<layouts.Span {...widthProps}>Description: </layouts.Span>
				<layouts.Span>{r.description}</layouts.Span>
				<layouts.Span
					ml="auto"
					onClick={(evt) => {
						evt.stopPropagation()
						onChange({ ...settings, rules: settings.rules.filter((o) => o.id !== r.id) })
					}}
				>
					<icons.Trash width="14px" height="14px" />
				</layouts.Span>
			</layouts.Flex>
			<layouts.Flex>
				<layouts.Span {...widthProps}>Text to avoid: </layouts.Span>
				<layouts.Span>{r.rule}</layouts.Span>
			</layouts.Flex>
			<layouts.Flex>
				<layouts.Span {...widthProps}>Explanation: </layouts.Span>
				<layouts.Span maxWidth="80%">{r.explanation}</layouts.Span>
			</layouts.Flex>
		</layouts.Flex>
	))

	return (
		<layouts.Flex flexDirection="column" flex="1" {...rest}>
			<layouts.forms.Container key={reset} p="10px">
				<layouts.forms.Group>
					<layouts.forms.Label {...widthProps}>Description</layouts.forms.Label>
					<inputs.Text
						autoFocus
						placeholder="short description of the rule"
						onChange={(evt) => setDescription(evt.target.value)}
						className={training.layouts.styledTextInput}
					/>
				</layouts.forms.Group>
				<layouts.forms.Group>
					<layouts.forms.Label {...widthProps}>Text to avoid</layouts.forms.Label>
					<inputs.Text
						placeholder='put words/phrases in quotes and separate each one by commas: "DoNotUse", "Unacceptable", ...'
						onChange={(evt) => setRule(evt.target.value)}
						className={training.layouts.styledTextInput}
					/>
				</layouts.forms.Group>
				<layouts.forms.Group>
					<layouts.forms.Label {...widthProps}>Explanation</layouts.forms.Label>
					<inputs.Text
						placeholder="explanation of the rule"
						onChange={(evt) => setExplanation(evt.target.value)}
						className={training.layouts.styledTextInput}
					/>
				</layouts.forms.Group>
				<layouts.Flex flex="1" flexDirection="row" justifyContent="space-between" mt="10px">
					<layouts.buttons.outline onClick={() => resetForm()} width="10ch" borderRadius="5px">
						Cancel
					</layouts.buttons.outline>
					<layouts.buttons.primary
						width="10ch"
						borderRadius="5px"
						disabled={disabled}
						onClick={() => {
							onChange({
								...settings,
								rules: (settings.rules || []).concat({ id: md5x.string(rule), description, rule, explanation }),
							})
							resetForm()
						}}
					>
						Add
					</layouts.buttons.primary>
				</layouts.Flex>
			</layouts.forms.Container>
			<layouts.Flex flexDirection="column">{rules}</layouts.Flex>
		</layouts.Flex>
	)
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as icons from "icons"
import * as inputs from "inputs"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as debugx from "x/debugx"
import { createCode, clearLoginAttemptInfo } from "supertokens-web-js/recipe/passwordless"
import * as routing from "./routing"
import * as typography from "typography"
import { css } from "@emotion/css"

const btncontainer = { mx: "auto", border: "unset", width: "231px", my: "8px" }
const btnstyle = {
	mx: "auto",
	width: "inherit",
	height: "inherit",
	boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
	cursor: "inherit",
	color: layouts.theme.colors.grey.dark10,
}

function EndpointButton(
	props: React.PropsWithChildren<{ endpoint: sessions.api.Endpoint; target: string; icon: JSX.Element }>,
): JSX.Element {
	const { children, endpoint, target, icon } = props
	const theme = layouts.useTheme()

	return (
		<layouts.links.external href={endpoint.url} target={target} width="inherit" height="inherit">
			<layouts.buttons.unstyled
				{...btnstyle}
				background={theme.color(layouts.theme.colors.white, layouts.theme.colors.white)}
			>
				<layouts.containers.span verticalAlign="middle" display="inline-block" ml="16px">
					{icon}
				</layouts.containers.span>
				<layouts.containers.span
					verticalAlign="middle"
					mr="auto"
					display="inline-flex"
					ml="16px"
					minWidth="140px"
					alignContent="flex-start"
				>
					{children}
				</layouts.containers.span>
			</layouts.buttons.unstyled>
		</layouts.links.external>
	)
}

function Endpoint(props: { endpoint: sessions.api.Endpoint; target: string }): JSX.Element {
	const { endpoint, ...rest } = props
	switch (endpoint.display) {
		case "GSuite":
			return (
				<layouts.Flex {...btncontainer} height="57px">
					<EndpointButton endpoint={endpoint} icon={<icons.Google width="32px" height="32px" />} {...rest}>
						<layouts.containers.span>Sign in with Google</layouts.containers.span>
					</EndpointButton>
				</layouts.Flex>
			)
		case "Facebook":
			if (!debugx.alpha.enabled()) return <></>
			return (
				<layouts.Flex {...btncontainer} height="57px">
					<EndpointButton endpoint={endpoint} icon={<icons.Facebook width="26px" height="26px" {...rest} />} {...rest}>
						<layouts.containers.span>Sign in with Facebook</layouts.containers.span>
					</EndpointButton>
				</layouts.Flex>
			)
		case "Microsoft":
			return (
				<layouts.Flex {...btncontainer} height="57px">
					<EndpointButton endpoint={endpoint} icon={<icons.Microsoft width="26px" height="26px" {...rest} />} {...rest}>
						<layouts.containers.span>Sign in with Microsoft</layouts.containers.span>
					</EndpointButton>
				</layouts.Flex>
			)
		default:
			return <></>
	}
}

function MagicLink(props: { options: string }): JSX.Element {
	const [email, setEmail] = useState("")
	const theme = layouts.useTheme()

	return (
		<layouts.Flex flexDirection="column" {...btncontainer}>
			<layouts.Flex alignItems="center" pt="10px" pb="20px">
				<layouts.Flex flex="1" borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`} />
				<typography.h6 fontWeight="500" fontSize="13px" px="10px" color={layouts.theme.colors.grey.dark50alpha80}>
					or
				</typography.h6>
				<layouts.Flex flex="1" borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`} />
			</layouts.Flex>
			<inputs.Text
				padding="0px 5px"
				mb="10px"
				placeholder="Email"
				defaultValue={email}
				borderRadius="4px"
				onChange={(evt) => setEmail(evt.target.value)}
				className={styledInput}
			/>
			<layouts.buttons.primary
				cursor="pointer"
				borderRadius="6px"
				height="40px"
				background={theme.color(layouts.theme.colors.blue.bright, layouts.theme.colors.blue.bright)}
				fontSize="13px"
				fontWeight="700"
				onClick={() => {
					clearLoginAttemptInfo().then(() => {
						createCode({ email }).then((code) => {
							if (code.status !== "OK") return Promise.reject(code)
							window.location.href = `${httpx.urlstorage.sso()}${routing.authorize()}?loginopts=${props.options}`
						})
					})
				}}
			>
				Send login link
			</layouts.buttons.primary>
			<layouts.Flex justifyContent="center">
				<typography.h6 fontSize="13px" fontWeight="500" py="5px" color={layouts.theme.colors.blue.bright}>
					Use your organization&apos;s email
				</typography.h6>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface props {
	tabbed: boolean
	endpoints: sessions.api.Endpoint[]
	options: string
}

const styledInput = css`
	input {
		height: 37px;
		border: 1px solid ${layouts.theme.colors.grey.dark50alpha20};
		background: ${layouts.theme.colors.grey.dark50alpha10};
		&:focus,
		&:hover {
			outline: 2px solid;
			outline-color: ${layouts.theme.colors.blue.bright};
		}
	}
`

export default function Login(props: props & layouts.containers.FlexProps): JSX.Element {
	const { tabbed, endpoints, options, ...rest } = props

	const target = tabbed ? "_blank" : "_self"
	const eles = endpoints.map((endpoint, idx) => {
		return <Endpoint key={idx} endpoint={endpoint} target={target} />
	})

	return (
		<layouts.Flex className="endpoints" flex="1" flexDirection="column" {...rest}>
			{eles}
			<MagicLink options={options} />
		</layouts.Flex>
	)
}

Login.defaultProps = {}

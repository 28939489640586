import * as svg from "./svg"
import * as theme from "layouts/theme"

export const Clear = (props: svg.SVGProps) => {
	const { fill, fillOpacity, ...rest } = props
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<mask id="mask0_406_4356" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
				<path
					d="M7.5 15C11.6423 15 15 11.6423 15 7.5C15 3.35775 11.6423 0 7.5 0C3.35775 0 0 3.35775 0 7.5C0 11.6423 3.35775 15 7.5 15Z"
					fill="white"
				/>
				<path
					d="M9.62117 5.37866L5.37842 9.62141M5.37842 5.37866L9.62117 9.62141"
					stroke="black"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</mask>
			<g mask="url(#mask0_406_4356)">
				<path d="M-2.11792 -2.11768H23.2938V23.2941H-2.11792V-2.11768Z" fill={fill} fillOpacity={fillOpacity} />
			</g>
		</svg>
	)
}

Clear.defaultProps = {
	fill: theme.colors.grey.dark50,
	fillOpacity: "0.4",
}

export const Magnifier = (props: svg.SVGProps) => {
	const { fill, ...rest } = props
	return (
		<svg.SVG width="18px" height="18px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5006 11.0006H11.7106L11.4306 10.7306C12.6306 9.33063 13.2506 7.42063 12.9106 5.39063C12.4406 2.61063 10.1206 0.390626 7.32063 0.0506256C3.09063 -0.469374 -0.469374 3.09063 0.0506256 7.32063C0.390626 10.1206 2.61063 12.4406 5.39063 12.9106C7.42063 13.2506 9.33063 12.6306 10.7306 11.4306L11.0006 11.7106V12.5006L15.2506 16.7506C15.6606 17.1606 16.3306 17.1606 16.7406 16.7506C17.1506 16.3406 17.1506 15.6706 16.7406 15.2606L12.5006 11.0006ZM6.50063 11.0006C4.01063 11.0006 2.00063 8.99063 2.00063 6.50063C2.00063 4.01063 4.01063 2.00063 6.50063 2.00063C8.99063 2.00063 11.0006 4.01063 11.0006 6.50063C11.0006 8.99063 8.99063 11.0006 6.50063 11.0006Z"
				fill={fill}
			/>
		</svg.SVG>
	)
}

Magnifier.defaultProps = {
	fill: "#C3CAD9",
}

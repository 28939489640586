import * as theme from "layouts/theme"
import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { fill, stroke, ...rest } = props
	return (
		<svg.SVG
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill={fill || "none"}
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M4.5 6.25C4.5 5.78587 4.68437 5.34075 5.01256 5.01256C5.34075 4.68437 5.78587 4.5 6.25 4.5H13.25C13.7141 4.5 14.1592 4.68437 14.4874 5.01256C14.8156 5.34075 15 5.78587 15 6.25V13.25C15 13.7141 14.8156 14.1592 14.4874 14.4874C14.1592 14.8156 13.7141 15 13.25 15H6.25C5.78587 15 5.34075 14.8156 5.01256 14.4874C4.68437 14.1592 4.5 13.7141 4.5 13.25V6.25Z"
				stroke={stroke || theme.colors.grey.dark50}
				strokeOpacity="0.8"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.5 4.5V2.75C11.5 2.28587 11.3156 1.84075 10.9874 1.51256C10.6592 1.18437 10.2141 1 9.75 1H2.75C2.28587 1 1.84075 1.18437 1.51256 1.51256C1.18437 1.84075 1 2.28587 1 2.75V9.75C1 10.2141 1.18437 10.6592 1.51256 10.9874C1.84075 11.3156 2.28587 11.5 2.75 11.5H4.5"
				stroke={stroke || theme.colors.grey.dark50}
				strokeOpacity="0.8"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg.SVG>
	)
}

export default Icon

import * as svg from "./svg"
import * as theme from "layouts/theme"

const Icon = (props: svg.SVGProps) => {
	let { stroke, strokeOpacity, fill, ...rest } = props
	return (
		<svg.SVG width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M10.35 14.6637H14.85M3.15002 14.6637L6.42451 14.0039C6.59835 13.9689 6.75796 13.8833 6.88331 13.7579L14.2136 6.42358C14.565 6.07194 14.5648 5.50195 14.213 5.1506L12.6602 3.59955C12.3086 3.24835 11.7389 3.24859 11.3876 3.60008L4.0566 10.9351C3.93149 11.0603 3.84606 11.2196 3.811 11.3931L3.15002 14.6637Z"
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg.SVG>
	)
}

Icon.defaultProps = {
	fill: "none",
	stroke: theme.colors.grey.dark50,
	strokeOpacity: "0.8",
}

export default Icon

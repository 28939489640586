import React from "react"
import styled from "@emotion/styled"
import { Global, css as react_css } from "@emotion/react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as icons from "icons"

const MainContainer = styled(layouts.containers.flex)`
	justify-content: center;
	flex-direction: column;
	flex: 1;
	min-height: 166px;
	padding: 47px 0 37px;
	background: ${layouts.theme.colors.blue.dark20};
	color: ${layouts.theme.extension.colors.blue.text};
	font-size: 12px;
	&.figma-plugin {
		padding-top: 0;
	}
`

const ExtensionHeader = styled(layouts.containers.flex)`
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 47px;
	justify-content: center;
	align-items: center;
	background: ${layouts.theme.extension.colors.blue.header};
	&.figma-plugin {
		display: none;
	}
`

const ExtensionBody = styled(layouts.containers.flex)`
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex: 1;
`

const globalStyles = react_css`
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		background: ${layouts.theme.extension.colors.blue.background_dark};
	}
	::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background: ${layouts.theme.extension.colors.blue.header};
	}
`

export function ExtensionContainer(
	props: React.PropsWithChildren<unknown> & layouts.containers.ContainerProps,
): JSX.Element {
	const { className } = props
	return (
		<MainContainer className={classnames("bg-extension", className)}>
			<Global styles={globalStyles} />
			<ExtensionHeader className={classnames("bg-extension-header", className)}>
				<icons.ExtensionLogo />
			</ExtensionHeader>
			<ExtensionBody className="bg-extension-body">{props.children}</ExtensionBody>
		</MainContainer>
	)
}

ExtensionContainer.defaultProps = {
	className: "",
}

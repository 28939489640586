import React from "react"
import styled from "@emotion/styled"
import * as system from "styled-system"

export type Cause = JSX.Element | undefined

export interface DefaultStyling {
	styled?: boolean
}

export interface CursorProps {
	cursor?: string
}

interface ContainerProps
	extends system.PositionProps,
		system.LayoutProps,
		system.TypographyProps,
		system.SpaceProps,
		system.BorderProps,
		system.FlexProps,
		system.OpacityProps,
		system.BoxShadowProps,
		system.ZIndexProps,
		system.BackgroundProps,
		CursorProps {
	className?: string
	aspectRatio?: string
}

export interface Props extends ContainerProps, DefaultStyling {
	className?: string
	cause?: unknown
	logging?(cause: unknown): void
	onClick?: React.MouseEventHandler<HTMLDivElement>
}

export interface FlexProps
	extends ContainerProps,
		DefaultStyling,
		system.GridGapProps,
		system.FlexProps,
		system.FlexDirectionProps,
		system.FlexboxProps,
		system.BackgroundProps,
		system.JustifyContentProps {}

export const Flex = styled.div<FlexProps>`
	display: flex;
	cursor: ${(props) => (props.cursor || props.onClick ? "pointer" : "unset")};
	${system.zIndex}
	${system.position}
	${system.layout}
	${system.typography}
	${system.background}
	${system.space}
	${system.borderRadius}
	${system.gridGap}
	${system.opacity}
	${system.boxShadow}
	${system.flexDirection}
	${system.flex}
	${system.flexbox}
	${system.justifyContent}
	${system.border}
	${system.color}
`

import { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as edit from "accounts/edit"
import * as api from "accounts/api"
import { useCached as useCachedSettings } from "accounts/settings"
import { settings as settings_api, UpdateAccountSettingsRequest } from "accounts/api"
import { CancellablePromise } from "real-cancellable-promise"

interface props extends layouts.containers.FlexProps {
	onSuccess(): void
}

export default function Display(props: props): JSX.Element {
	const { onSuccess, ...rest } = props
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const session = sessions.useSession()
	const [account, setAccount] = useState(session.account)
	const as_cached = useCachedSettings()
	const [settings, setSettings] = useState(as_cached.settings)

	useEffect(() => {
		if (session.account === account) return
		session.replace({ ...{ ...session, account: account }, recording: true })
	}, [account])

	useEffect(() => {
		if (as_cached.settings === settings) return
		as_cached.replace({ ...{ ...as_cached, settings: settings } })
	}, [settings])

	return (
		<layouts.containers.flex {...rest}>
			<edit.Edit
				borderRadius="5px"
				px="50px"
				py="30px"
				overflow="auto"
				current={account}
				cur_settings={settings}
				onChange={(upd, settings) => {
					metaauthz.token().then((token) => {
						CancellablePromise.all([
							api.update({ account: upd }, httpx.options.bearer(token.bearer)),
							settings_api.update(
								{ account_settings: settings } as UpdateAccountSettingsRequest,
								httpx.options.bearer(token.bearer),
							),
						])
							.then((res) => {
								const [a, s] = res
								setAccount(a.account!)
								setSettings(settings_api.zero(s.account_settings))
							})
							.catch((cause) => console.error("unable to update account", cause))
							.finally(() => onSuccess())
					})
				}}
			></edit.Edit>
		</layouts.containers.flex>
	)
}

import React, { useState, useEffect, useRef, useContext } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as icons from "icons"
import * as inputs from "inputs"
import { FileRejection } from "react-dropzone"
import { css } from "@emotion/css"
import FontName from "fontnamex"
import * as b64 from "js-base64"
import * as uuid from "uuid"
import * as cache from "./cache"

export const styledCarousel = css`
	.content {
		gap: 10px;
	}
`

export const steps = ["Typography", "Usage"]

export const typographyTitle = (o: brandguide.api.FontObject): string => {
	if (o.id === "primary") return "Primary Font"
	if (o.id === "secondary") return "Secondary Font"
	return "Alternative Font"
}

const textLinksProps = {
	fontSize: "11px",
	fontWeight: "400",
	color: layouts.theme.colors.grey.dark50,
	lineHeight: "15px",
}

interface typographyItemProps extends layouts.containers.ContainerProps {
	font: brandguide.api.FontObject
	onChange(c: brandguide.api.FontObject): void
}

export function UploadItem(props: typographyItemProps): JSX.Element {
	const { font, onChange, ...rest } = props
	const previewRef = useRef<HTMLDivElement>(null)
	const [fontItem, setFontItem] = useState(font)
	const [loadedFont, setLoadedFont] = useState<FontFace | undefined>(undefined)

	const [googleFocused, setGoogleFocused] = useState(false)

	const onFontUpload = (file: File) => {
		const reader = new FileReader()
		file.arrayBuffer().then((b) => {
			const barray = new Uint8Array(b)
			const base64 = b64.fromUint8Array(barray)
			const upd = {
				...fontItem,
				file_name: file.name,
				font: base64,
				font_family: file.name,
				font_type: file.type,
			} as brandguide.api.FontObject
			reader.onloadend = function (e) {
				try {
					const fontMeta = FontName.parse(e.target!.result)[0]
					const font_family = fontMeta["fontFamily"] || file.name
					upd.font_family = font_family
					setFontItem(upd)
				} catch (e) {
					setFontItem(upd)
				}
			}
			reader.readAsArrayBuffer(file)
		})
	}

	useEffect(() => {
		onChange(fontItem)
	}, [fontItem])

	useEffect(() => {
		if (fontItem.font === "") return
		if (!previewRef.current) return
		const barray = b64.toUint8Array(fontItem.font)
		const blob = new Blob([barray])
		const file = new File([blob], fontItem.file_name)
		const reader = new FileReader()
		const newFontFamilyName = `${fontItem.font_family}_${fontItem.id}`
		reader.onloadend = function (e) {
			const font = new FontFace(newFontFamilyName, e!.target!.result!)
			font
				.load()
				.then(function (loadedFont) {
					setLoadedFont(loadedFont)
					document.fonts.add(loadedFont)
					previewRef.current!.style.fontFamily = `${newFontFamilyName}, sans-serif`
				})
				.catch(function (error) {
					console.log("Failed to load font: " + error)
				})
		}

		reader.readAsArrayBuffer(file)
	}, [fontItem])

	if (googleFocused)
		return (
			<FontSearch
				onSelect={(f) => {
					setGoogleFocused(false)
					setFontItem(f)
				}}
				fontItem={fontItem}
				onCancel={() => setGoogleFocused(false)}
				border="2px solid #ECF0FC"
				{...rest}
			/>
		)

	if (fontItem.font === "")
		return (
			<layouts.Flex {...rest}>
				<Dropwell
					onChange={(f) => {
						onFontUpload(f[0])
					}}
				/>
				<layouts.Flex flexDirection="column" border="2px solid #ECF0FC">
					<layouts.Flex flexDirection="column" justifyContent="center" alignItems="center" mt="19px">
						<layouts.Flex justifyContent="start" alignItems="center" mb="10px" onClick={() => setGoogleFocused(true)}>
							<layouts.Flex>
								<icons.brandguide.arrows.SmallRight pr="10px" />
							</layouts.Flex>
							<layouts.Flex>
								<typography.h6 {...textLinksProps}>Search Google Fonts</typography.h6>
							</layouts.Flex>
						</layouts.Flex>
						<layouts.Flex justifyContent="center" mt="27px" mb="10px">
							<typography.h6 fontSize="12px" fontWeight="400" color={layouts.theme.colors.grey.dark50}>
								{typographyTitle(fontItem)}
							</typography.h6>
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		)

	return (
		<layouts.Flex border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`} {...rest}>
			<layouts.Flex justifyContent="end">
				<layouts.Flex
					p="5px"
					onClick={() => {
						if (loadedFont) {
							if (previewRef.current) previewRef.current.style.fontFamily = "inherit"
							document.fonts.delete(loadedFont)
							setLoadedFont(undefined)
						}
						setFontItem(brandguide.api.zeros.typography.fontZero({ id: font.id }))
					}}
				>
					<icons.Close width="10px" height="10px" />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flex="3" justifyContent="center" alignItems="center" minHeight="180px">
				<layouts.Flex ref={previewRef} flexDirection="column" justifyContent="center" alignItems="center">
					<typography.h2 fontSize="64px" fontWeight="700" lineHeight="70px">
						A
					</typography.h2>
					<typography.h6 fontSize="12px" fontWeight="400" whiteSpace="unset" textAlign="center">
						{fontItem.font_family}
					</typography.h6>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex
				flexDirection="column"
				flex="1"
				justifyContent="flex-end"
				alignItems="center"
				position="relative"
				mb="10px"
			>
				<typography.h4 fontSize="12px" color={layouts.theme.colors.grey.dark50}>
					{typographyTitle(fontItem)}
				</typography.h4>
			</layouts.Flex>
		</layouts.Flex>
	)
}

UploadItem.defaultProps = {
	flexDirection: "column",
	width: "170px",
}

interface searchProps extends layouts.containers.ContainerProps {
	fontItem: brandguide.api.FontObject
	onSelect(font: brandguide.api.FontObject): void
	onCancel(): void
}

const styledSearch = css`
	input {
		max-width: 130px;
		max-height: 25px;
		outline: 0;
		border-width: 0 0 1px;
		border-color: ${layouts.theme.colors.grey.dark50alpha80};
		&:hover,
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
`

function FontSearch(props: searchProps): JSX.Element {
	const { fontItem, onSelect, onCancel, ...rest } = props

	const fonts = cache.useCached()
	const [search, setSearch] = useState("")

	const handleSelect = (googlefont: brandguide.api.GoogleFont) => {
		const url = googlefont.files["regular"] || googlefont.files[googlefont.variants[0]]
		if (!url) {
			setSearch("")
			return
		}
		fetch(url)
			.then((response) => response.arrayBuffer())
			.then((buffer) => {
				const barray = new Uint8Array(buffer)
				const base64 = b64.fromUint8Array(barray)
				const upd = {
					...fontItem,
					file_name: url,
					font: base64,
					font_family: googlefont.family,
					font_type: url.split(".").pop(),
				} as brandguide.api.FontObject
				onSelect(upd)
			})
	}

	const filteredFonts = fonts.filter((font) => font.family.toLowerCase().includes(search.toLowerCase())).slice(0, 5)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex className={styledSearch} my="5px" alignItems="center">
				<layouts.Flex mx="10px">
					<input
						type="text"
						placeholder="Search Google Fonts..."
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</layouts.Flex>
				<layouts.Flex onClick={onCancel}>
					<icons.Close width="15px" height="15px" fill={layouts.theme.colors.grey.dark50alpha60} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				{(search ? filteredFonts : []).map((font) => (
					<layouts.Flex
						key={font.family}
						borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
						alignItems="center"
						minHeight="30px"
					>
						<layouts.Flex flex="5" px="5px">
							<typography.h6 fontSize="12px" fontWeight="400" whiteSpace="unset" lineHeight="15px">
								{font.family}
							</typography.h6>
						</layouts.Flex>
						<layouts.Flex flex="1" px="5px" onClick={() => handleSelect(font)} justifyContent="end">
							<icons.brandguide.actions.typography.Add />
						</layouts.Flex>
					</layouts.Flex>
				))}
			</layouts.Flex>
			<layouts.Flex flexDirection="column" flex="1" justifyContent="flex-end" alignItems="center" mb="10px">
				<typography.h4 fontSize="12px" color={layouts.theme.colors.grey.dark50} lineHeight="20px">
					{typographyTitle(fontItem)}
				</typography.h4>
			</layouts.Flex>
		</layouts.Flex>
	)
}

const textBaseProps = {
	fontWeight: "400",
	letterSpacing: "unset",
	lineHeight: "normal",
	whiteSpace: "unset",
}

interface typographyAddItemProps extends layouts.containers.ContainerProps {
	onChange(): void
}

export function AddUploadsItem(props: typographyAddItemProps): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flexDirection="column" flex="1" justifyContent="flex-end" alignItems="center">
				<layouts.Flex onClick={onChange}>
					<typography.h4 mb="10px" fontSize="20px" color={layouts.theme.colors.grey.dark50} {...textBaseProps}>
						+
					</typography.h4>
				</layouts.Flex>
				<typography.h5
					mb="10px"
					fontSize="12px"
					color={layouts.theme.colors.grey.dark50}
					{...textBaseProps}
					lineHeight="20px"
				>
					Add New
				</typography.h5>
			</layouts.Flex>
		</layouts.Flex>
	)
}

AddUploadsItem.defaultProps = {
	flexDirection: "column",
	width: "170px",
	border: "2px solid #ECF0FC",
}

const acceptedFonts = {
	"application/x-font-ttf": [".ttf"],
	"application/x-font-opentype": [".otf"],
	"application/font-woff": [".woff"],
	"application/font-woff2": [".woff2"],
	"application/vnd.ms-fontobject": [".eot"],
}
const styledIcon = css`
	svg {
		width: 25px;
		height: 25px;
		path {
			stroke-width: 5px;
		}
	}
`

interface props extends layouts.containers.ContainerProps {
	onChange(item: File[]): void
}

export default function Dropwell(props: React.PropsWithChildren<props>): JSX.Element {
	const { onChange, ...rest } = props

	const onDropHandle = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		if (acceptedFiles.length === 0) return
		onChange(Array.from(acceptedFiles))
	}

	return (
		<inputs.Dropwell
			className="fonts.dropwell"
			accept={acceptedFonts}
			onDrop={(accepted, rejected, evt) => onDropHandle(accepted, rejected)}
			display="flex"
			flex="1"
			{...rest}
		>
			<layouts.dnd.Container p="unset" borderRadius="unset">
				<icons.FileUpload className={styledIcon} width="50px" height="50px" mt="16px" />
				<typography.h6
					fontSize="10px"
					fontWeight="400"
					whiteSpace="unset"
					textAlign="center"
					color={layouts.theme.colors.grey.dark3}
					lineHeight="16px"
					mb="13px"
					mt="7px"
				>
					Drag & drop your font file here or choose file
				</typography.h6>
			</layouts.dnd.Container>
		</inputs.Dropwell>
	)
}

export const combinedTitle = (item: brandguide.api.TypographyObject, font_family: string): string => {
	let title = `${font_family} ${item.size}pt`
	if (item.bold) title = `${title} Bold`
	if (item.italic) title = `${title} Italic`
	if (item.transform === brandguide.api.TextTransform.TITLECASE) title = `${title} TitleCase`
	if (item.transform === brandguide.api.TextTransform.LOWERCASE) title = `${title} LowerCase`
	if (item.transform === brandguide.api.TextTransform.UPPERCASE) title = `${title} UpperCase`
	if (item.transform === brandguide.api.TextTransform.SMALLCAPS) title = `${title} SmallCaps`
	return title
}

interface accordionProps {
	item: brandguide.api.TypographyObject
	font: brandguide.api.FontObject
	onDelete(item: brandguide.api.TypographyObject): void
}

function EditHead(
	props: React.PropsWithChildren<accordionProps & layouts.accordions.props & layouts.containers.FlexProps>,
): JSX.Element {
	const { open, toggle } = useContext(layouts.accordions.AccordionContext)
	const { item, font, onDelete, children, ...rest } = { ...props }

	const fontFamilyName = `${font.font_family}_${font.id}`

	return (
		<>
			<layouts.containers.flex
				flexDirection="row"
				fontSize="14px"
				lineHeight="20px"
				minHeight="40px"
				color={layouts.theme.colors.grey.dark50}
				{...rest}
			>
				<layouts.Flex flex="1" alignItems="center" px="10px">
					<typography.h6 fontSize="16px" fontWeight={item.bold ? "700" : "400"} fontFamily={fontFamilyName}>
						{item.title} {combinedTitle(item, font.font_family)}
					</typography.h6>
				</layouts.Flex>
				{!open && (
					<>
						<layouts.containers.flex p="10px" justifyContent="end" alignItems="center" onClick={() => toggle(!open)}>
							<icons.brandguide.Pen fill={layouts.theme.colors.grey.dark50alpha40} />
						</layouts.containers.flex>
						<layouts.containers.flex p="10px" justifyContent="end" alignItems="center" onClick={() => onDelete(item)}>
							<icons.brandguide.Trash fill={layouts.theme.colors.grey.dark50alpha40} />
						</layouts.containers.flex>
					</>
				)}
			</layouts.containers.flex>
			{children}
		</>
	)
}

interface edittProps extends layouts.containers.FlexProps {
	item: brandguide.api.TypographyObject
	fonts: brandguide.api.FontObject[]
	onChange(item: brandguide.api.TypographyObject): void
	onDelete(item: brandguide.api.TypographyObject): void
}

const textSizes = [8, 10, 12, 14, 16, 18, 20, 24, 26, 32, 40, 64]

export function Edit(props: edittProps): JSX.Element {
	const { item, fonts, onDelete, onChange, ...rest } = props
	const [asset, setAsset] = useState(item)
	const _font = fonts.find((f) => f.id === item.font_id)

	useEffect(() => {
		fonts.forEach((font) => {
			if (font.font === "") return
			const barray = b64.toUint8Array(font.font)
			const blob = new Blob([barray])
			const file = new File([blob], font.file_name)
			const reader = new FileReader()
			const newFontFamilyName = `${font.font_family}_${font.id}`
			reader.onloadend = function (e) {
				const font = new FontFace(newFontFamilyName, e!.target!.result!)
				font
					.load()
					.then(function (loadedFont) {
						document.fonts.add(loadedFont)
					})
					.catch(function (error) {
						console.log("Failed to load font: " + error)
					})
			}
			reader.readAsArrayBuffer(file)
		})
	}, [])

	const font = fonts.find((f) => f.id === asset.font_id)
	if (!font) return <>something went wrong</>
	const fontFamilyName = `${font.font_family}_${font.id}`

	const preparedTitle = (): string => {
		const text = combinedTitle(asset, font.font_family)
		if (asset.transform === brandguide.api.TextTransform.TITLECASE) return brandguide.build.layouts.toTitleCase(text)
		if (asset.transform === brandguide.api.TextTransform.UPPERCASE) return text.toUpperCase()
		if (asset.transform === brandguide.api.TextTransform.LOWERCASE) return text.toLocaleLowerCase()
		return text
	}

	return (
		<layouts.accordions.Container
			minHeight="unset"
			padding="unset"
			flexDirection="column"
			borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
			m="5px 0 5px 0"
			{...rest}
		>
			<layouts.containers.box
				tabIndex={0}
				className="accordion-containers-box"
				background={layouts.theme.colors.grey.bg}
			>
				<EditHead item={item} font={_font!} onDelete={onDelete}>
					<layouts.accordions.Content>
						<layouts.containers.flex flexDirection="column" width="100%" flex="1">
							<layouts.Flex flexDirection="column" p="5px">
								<layouts.Flex flex="1" gridGap="10px">
									<layouts.Flex>
										<select
											defaultValue={item.font_id}
											style={{
												width: "125px",
												height: "32px",
												outline: "none",
												border: "none",
												borderRadius: "3px",
												backgroundColor: layouts.theme.colors.white,
											}}
											onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
												setAsset({ ...asset, font_id: evt.currentTarget.value })
											}}
										>
											{fonts.map((f) => (
												<option key={f.id} value={f.id}>
													{f.font_family}
												</option>
											))}
										</select>
									</layouts.Flex>
									<layouts.Flex>
										<select
											defaultValue={item.size.toString()}
											style={{
												width: "67px",
												height: "32px",
												outline: "none",
												border: "none",
												borderRadius: "3px",
												backgroundColor: layouts.theme.colors.white,
											}}
											onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
												setAsset({ ...asset, size: Number(evt.currentTarget.value) })
											}}
										>
											{textSizes.map((s) => (
												<option key={s} value={s}>
													{s}
												</option>
											))}
										</select>
									</layouts.Flex>
									<layouts.Flex
										background={asset.bold ? layouts.theme.colors.grey.dark50 : layouts.theme.colors.white}
										onClick={() => setAsset({ ...asset, bold: !asset.bold })}
										{...baseButtonProps}
									>
										<typography.h6
											fontSize="14px"
											fontWeight="700"
											color={asset.bold ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50}
										>
											B
										</typography.h6>
									</layouts.Flex>
									<layouts.Flex
										background={asset.italic ? layouts.theme.colors.grey.dark50 : layouts.theme.colors.white}
										onClick={() => setAsset({ ...asset, italic: !asset.italic })}
										{...baseButtonProps}
									>
										<typography.h6
											fontSize="14px"
											fontWeight="400"
											color={asset.italic ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50}
											fontStyle="italic"
										>
											I
										</typography.h6>
									</layouts.Flex>
									<layouts.Flex minWidth="20px" />
									<layouts.tooltips.default text="Uppercase" {...tooltipProps}>
										<Action
											item={asset}
											name="AG"
											val={brandguide.api.TextTransform.UPPERCASE}
											onChange={(upd) => setAsset(upd)}
										/>
									</layouts.tooltips.default>
									<layouts.tooltips.default text="Lowercase" {...tooltipProps}>
										<Action
											item={asset}
											name="ag"
											val={brandguide.api.TextTransform.LOWERCASE}
											onChange={(upd) => setAsset(upd)}
										/>
									</layouts.tooltips.default>
									<layouts.tooltips.default text="Titlecase" {...tooltipProps}>
										<Action
											item={asset}
											name="Ag"
											val={brandguide.api.TextTransform.TITLECASE}
											onChange={(upd) => setAsset(upd)}
										/>
									</layouts.tooltips.default>
									<layouts.tooltips.default text="Titlecase" {...tooltipProps}>
										<Action
											item={asset}
											name="AG"
											val={brandguide.api.TextTransform.SMALLCAPS}
											onChange={(upd) => setAsset(upd)}
										/>
									</layouts.tooltips.default>
								</layouts.Flex>
								<layouts.Flex my="20px" mx="10px">
									<typography.h6
										fontSize={`${asset.size}pt`}
										fontWeight={asset.bold ? "700" : "400"}
										fontStyle={asset.italic ? "italic" : "normal"}
										lineHeight={`${asset.size + 2}pt`}
										whiteSpace="unset"
										style={{
											fontVariant: asset.transform === brandguide.api.TextTransform.SMALLCAPS ? "small-caps" : "unset",
										}}
										fontFamily={fontFamilyName}
									>
										{preparedTitle()}
									</typography.h6>
								</layouts.Flex>
								<EditActions onApply={() => onChange(asset)} onCancel={() => setAsset(item)} />
							</layouts.Flex>
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</EditHead>
			</layouts.containers.box>
		</layouts.accordions.Container>
	)
}

const tooltipProps = {
	top: "100%",
	bottom: "unset",
}

interface editActionsProps extends layouts.containers.ContainerProps {
	onApply(): void
	onCancel(): void
}

function EditActions(props: editActionsProps): JSX.Element {
	const { onApply, onCancel, ...rest } = props

	const { open, toggle } = useContext(layouts.accordions.AccordionContext)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				onClick={() => {
					toggle(!open)
					onApply()
				}}
			>
				<icons.brandguide.actions.Accept />
			</layouts.Flex>
			<layouts.Flex
				onClick={() => {
					toggle(!open)
					onCancel()
				}}
			>
				<icons.brandguide.actions.Reject />
			</layouts.Flex>
		</layouts.Flex>
	)
}

EditActions.defaultProps = {
	alignItems: "end",
	justifyContent: "end",
	gridGap: "8px",
}

const baseButtonProps = {
	height: "31px",
	width: "31px",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "3px",
}

interface addItemProps extends layouts.containers.ContainerProps {
	onChange(item: brandguide.api.TypographyObject): void
}

export function AddItem(props: addItemProps): JSX.Element {
	const { onChange, ...rest } = props
	const [item, setItem] = useState(
		brandguide.api.zeros.typography.itemZero({ id: uuid.v4(), font_id: "primary", size: 16 }),
	)
	const [focused, setFocused] = useState(false)

	if (focused)
		return (
			<layouts.Flex {...rest}>
				<layouts.Flex mx="10px">
					<input
						placeholder="Enter tag"
						value={item.title}
						style={{
							width: "125px",
							height: "32px",
							outline: "none",
							border: "none",
							borderRadius: "3px",
							paddingLeft: "5px",
							paddingRight: "5px",
							backgroundColor: layouts.theme.colors.white,
						}}
						onChange={(e) => setItem({ ...item, title: e.target.value })}
					/>
				</layouts.Flex>
				{item.title !== "" && (
					<layouts.Flex alignItems="end" justifyContent="end" gridGap="8px">
						<layouts.Flex
							onClick={() => {
								onChange(item)
								setFocused(false)
								setItem(brandguide.api.zeros.typography.itemZero({ id: uuid.v4(), font_id: "primary", size: 16 }))
							}}
						>
							<icons.brandguide.actions.Accept />
						</layouts.Flex>
						<layouts.Flex
							onClick={() => {
								setFocused(false)
								setItem(brandguide.api.zeros.typography.itemZero({ id: uuid.v4(), font_id: "primary", size: 16 }))
							}}
						>
							<icons.brandguide.actions.Reject />
						</layouts.Flex>
					</layouts.Flex>
				)}
			</layouts.Flex>
		)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex mx="10px" onClick={() => setFocused(true)}>
				<typography.h6 fontSize="16px" fontWeight="400" color={layouts.theme.colors.grey.dark50alpha80}>
					+ Add new
				</typography.h6>
			</layouts.Flex>
		</layouts.Flex>
	)
}

AddItem.defaultProps = {
	minHeight: "40px",
	background: layouts.theme.colors.grey.bg,
	alignItems: "center",
}

interface actionProps {
	name: string
	val: brandguide.api.TextTransform
	item: brandguide.api.TypographyObject
	onChange(item: brandguide.api.TypographyObject): void
}

function Action(props: actionProps): JSX.Element {
	const { name, val, item, onChange } = props
	return (
		<layouts.Flex
			background={item.transform === val ? layouts.theme.colors.grey.dark50 : layouts.theme.colors.white}
			onClick={() => {
				const upd = item.transform === val ? brandguide.api.TextTransform.NONE : val
				onChange({ ...item, transform: upd })
			}}
			{...baseButtonProps}
		>
			<typography.h6
				fontSize="14px"
				fontWeight="400"
				style={{ fontVariant: val === brandguide.api.TextTransform.SMALLCAPS ? "small-caps" : "unset" }}
				color={item.transform === val ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50}
			>
				{name}
			</typography.h6>
		</layouts.Flex>
	)
}

import React, { useEffect, useState } from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as errors from "errors"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as api from "brandguard/api"
import * as bgscores from "brandguard/display/scores"
import { ScoreAccordionDescription, SubSubScoreContainer } from "brandguard/components/layouts/scores"
import * as icons from "icons"
import * as bgimages from "brandguard/display/brandguard/images"
import * as uuid from "uuid"

interface props extends layouts.containers.FlexProps {
	event: api.Media
}

export default function Display(props: React.PropsWithChildren<props>): JSX.Element {
	const { className, event, children, ...rest } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)
	const [req, setReq] = useState(
		api.explanations.background_color.searches.request({
			event_ids: [event.id],
			limit: 1n,
		}),
	)
	const [bcexplanations, setBCExplanations] = useState({
		next: req,
		items: [],
	} as api.BackgroundColorExplanationSearchResponse)
	const [loading, setloading] = useState(false)

	const [focused, setFocused] = useState(undefined as JSX.Element[] | undefined)

	const overlay = bgimages.overlays.useCached()
	const [overlayKey] = useState(uuid.v4())

	useEffect(() => {
		setloading(true)
		const retry = httpx.autoretry()
		const pending = retry
			.wrap(() => api.explanations.background_color.search(req, authzc.bearer(metaauthz)))
			.then(setBCExplanations)
			.catch(httpx.errors.cancellation(console.debug))
			.catch((cause) => {
				setCause(<errors.UnknownCause logging={console.error} cause={cause} onClick={() => setCause(undefined)} />)
			})
			.finally(() => setloading(false))

		return pending.cancel
	}, [req])

	useEffect(() => {
		setReq({ ...req, event_ids: [event.id] })
	}, [event.id])

	useEffect(() => {
		if (!focused) {
			overlay.update({
				...overlay,
				items: overlay.items.filter((ov) => ov.uuid !== overlayKey),
			})
			return
		}
		const upd = focused.map((f) => {
			return { uuid: overlayKey, position: 1, element: f }
		})
		overlay.update({ ...overlay, items: [...overlay.items, ...upd] })
		return () =>
			overlay.update({
				...overlay,
				items: overlay.items.filter((ov) => ov.uuid !== overlayKey),
			})
	}, [focused])

	if (loading)
		return (
			<SubSubScoreContainer className={className}>
				<layouts.containers.flex flexDirection="row" flex="1">
					<layouts.containers.flex flex="3" justifyContent="flex-start" alignItems="center" color={"unset"}>
						Brand Colors
					</layouts.containers.flex>
					<layouts.containers.flex flex="1" justifyContent="flex-end" alignItems="center">
						<icons.loading.Ring3 width="14px" height="14px" />
					</layouts.containers.flex>
				</layouts.containers.flex>
				<bgscores.PercentageBar weight={0} />
			</SubSubScoreContainer>
		)
	if (cause) return <layouts.Flex p="10px">{cause}</layouts.Flex>
	if (bcexplanations.items.length === 0) return <>{children}</>

	return (
		<bgscores.SectionAccordion
			borderWidth="0"
			borderRadius="0"
			borderColor="unset"
			borderStyle="unset"
			borderBottom="none"
			my="0"
			className={classnames(className, bgscores.layouts.styledAccordion)}
			{...rest}
		>
			<layouts.containers.box tabIndex={0}>
				<bgscores.SectionDescription>
					<ScoreAccordionDescription padding="0" className={className}>
						{children}
					</ScoreAccordionDescription>
				</bgscores.SectionDescription>
				<layouts.accordions.Content>
					<layouts.Flex
						flexDirection="column"
						flex="1"
						pr="54px"
						className={classnames("bcexplanations.list", className)}
					>
						<errors.overlay styled cause={cause} flex="1">
							{bcexplanations.items.map((v) => (
								<layouts.Flex key={v.id} my="10px" pl="35px" lineHeight="14px" gridGap="10px" alignItems="center">
									<layouts.Flex>
										<layouts.toggles.Display
											active={!!focused}
											onClick={() => {
												const upd = () => {
													if (focused) return undefined
													return bcexplanations.items.map((i, index) => (
														<OverlayImage key={index} event_id={event.id} />
													))
												}
												setFocused(upd)
											}}
										/>
									</layouts.Flex>
									<layouts.Flex fontWeight="700">{v.description}</layouts.Flex>
								</layouts.Flex>
							))}
						</errors.overlay>
					</layouts.Flex>
				</layouts.accordions.Content>
			</layouts.containers.box>
		</bgscores.SectionAccordion>
	)
}

interface overlayImageProps {
	event_id: string
}

function OverlayImage(props: overlayImageProps): JSX.Element {
	const { event_id } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setloading] = useState(true)
	const [url, setUrl] = useState("")

	useEffect(() => {
		bgscores.cache.background_color_explanation_mask
			.current(metaauthz.current.account_id, event_id, authzc.bearer(metaauthz))
			.then(setUrl)
			.catch((cause) => console.warn(cause))
			.finally(() => setloading(false))
	}, [])

	return (
		<layouts.loading.pending
			loading={loading}
			alignItems="center"
			justifyContent="center"
			icon={<icons.loading.Ring3 />}
		>
			<layouts.images.Base
				width="100%"
				height="auto"
				maxWidth="100%"
				maxHeight="100%"
				m="auto"
				alt="overlay"
				src={url}
			/>
		</layouts.loading.pending>
	)
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"
import * as typography from "typography"
import classnames from "classnames"
import * as brandgpt from "brandgpt"

interface props extends layouts.containers.ContainerProps {
	onChange(c: api.Channel): void
}

function validate(channel: api.Channel): boolean {
	return inputs.assertions.failed(
		inputs.assertions.strings.min(1)(channel.description),
		inputs.assertions.strings.max(200)(channel.description),
	)
}

const panelDefaultProps = {
	width: "563px",
	minHeight: "165px",
	background: layouts.theme.colors.white,
	borderRadius: "10px",
	boxShadow: layouts.theme.boxshadow.black.wide,
	px: "25px",
	py: "25px",
}

export function New(props: props & { styleguide_id: string }): JSX.Element {
	const { styleguide_id } = props
	const [channel, setChannel] = useState(api.channelZero({ styleguide_id: styleguide_id }))
	const { onChange, ...rest } = props

	const disabled = validate(channel)

	return (
		<layouts.containers.flex styled flexDirection="column" flex="1" {...rest}>
			<typography.h3
				color={layouts.theme.colors.grey.dark50}
				textAlign="left"
				fontSize="16px"
				fontWeight="700"
				pt="10px"
				pb="20px"
			>
				Add New Chat
			</typography.h3>
			<Form current={channel} onChange={setChannel} />
			<layouts.containers.flex justifyContent="right" mt="25px" alignItems="flex-end" flex="1">
				<layouts.buttons.primary
					width="140px"
					height="35px"
					borderRadius="27px"
					disabled={disabled}
					onClick={() => onChange(channel)}
				>
					Create
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

New.defaultProps = panelDefaultProps

export function Edit(props: props & { current: api.Channel }): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const { current, onChange, ...rest } = props

	const [channel, setChannel] = useState(api.channelZero(current))

	const disabled = validate(channel)

	return (
		<layouts.containers.flex styled flexDirection="column" flex="1" {...rest}>
			<typography.h3
				color={layouts.theme.colors.grey.dark50}
				textAlign="left"
				fontSize="16px"
				fontWeight="700"
				pt="10px"
				pb="20px"
			>
				Edit Chat
			</typography.h3>
			<Form current={channel} onChange={setChannel} />
			<layouts.containers.flex justifyContent="space-between" mt="25px">
				<layouts.containers.flex justifyContent="right" mt="25px" alignItems="flex-end" flex="1" gridGap="10px">
					<layouts.buttons.outline width="140px" height="35px" borderRadius="27px" onClick={() => mtoggle(undefined)}>
						Cancel
					</layouts.buttons.outline>
					<layouts.buttons.primary
						width="140px"
						height="35px"
						borderRadius="27px"
						p="0px"
						m="0px"
						disabled={disabled}
						onClick={() => onChange(channel)}
					>
						Save
					</layouts.buttons.primary>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Edit.defaultProps = panelDefaultProps

export function Delete(props: props & { current: api.Channel }): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const { current, onChange, ...rest } = props

	return (
		<layouts.containers.flex styled flexDirection="column" flex="1" {...rest}>
			<typography.h3
				color={layouts.theme.colors.grey.dark50}
				textAlign="left"
				fontSize="16px"
				fontWeight="700"
				pt="10px"
				pb="20px"
			>
				Delete Chat?
			</typography.h3>
			{current.description}
			<layouts.containers.flex justifyContent="space-between" mt="25px">
				<layouts.containers.flex justifyContent="right" mt="25px" alignItems="flex-end" flex="1" gridGap="10px">
					<layouts.buttons.outline width="140px" height="35px" borderRadius="27px" onClick={() => mtoggle(undefined)}>
						Cancel
					</layouts.buttons.outline>
					<layouts.buttons.danger
						width="140px"
						height="35px"
						borderRadius="27px"
						p="0px"
						m="0px"
						onClick={() => onChange(current)}
					>
						Delete
					</layouts.buttons.danger>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Delete.defaultProps = panelDefaultProps

export default function Form(props: props & { current: api.Channel }): JSX.Element {
	const { current, onChange, ...rest } = props

	return (
		<layouts.forms.Container flexDirection="row" {...rest}>
			<layouts.forms.Group className="brand.details" flex="1" gridGap="10px">
				<inputs.Text
					className={classnames(brandgpt.layouts.styledInput)}
					padding="0px 10px"
					borderRadius="5px"
					height="37px"
					placeholder="Chat Name"
					defaultValue={current.description}
					onChange={(evt) => onChange({ ...current, description: evt.currentTarget.value })}
					autoFocus
				/>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}

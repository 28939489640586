import React from "react"
import * as displays from "./displays"
import * as api from "./api"

interface props {
	onError(props: api.Props): JSX.Element
}

/**
 * react functional components don't support handling exceptions.
 */
export class Boundary extends React.Component<React.PropsWithChildren<props>, { cause: unknown }> {
	constructor(props: props) {
		super(props)
		this.state = { cause: undefined }
	}

	static defaultProps = {
		onError: (props: api.Props) => <displays.UnknownCause className="auto" flex="1" {...props} />,
	}

	static getDerivedStateFromError(error: unknown) {
		// Update state so the next render will show the fallback UI.
		return { cause: error }
	}

	componentDidCatch(error: unknown, errorInfo: unknown) {
		// log the error to an error reporting service
		console.error(error, errorInfo)
	}

	render(): JSX.Element {
		if (this.state.cause && this.props.onError) {
			// render custom fallback UI
			return this.props.onError({ cause: this.state.cause, onClick: () => this.setState({ cause: undefined }) })
		}

		return <>{this.props.children}</>
	}
}

import React from "react"
import * as layouts from "layouts"
import classnames from "classnames"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import * as icons from "icons"

export const styledAccordion = css`
	.accordion-description {
		svg {
			transform: rotate(-90deg);
		}
	}
	.sub-score-container {
		margin-right: 0;
	}
`

export const accordiondescription = css`
	.accordion-description {
		border-bottom: none;
		.feather {
			margin-top: 5px;
			padding-right: 20px;
		}
	}
`

export const styledscores = css`
	.brandguard-score {
		display: flex;
		border-radius: 5px;
		align-items: center;
		justify-content: space-between;
		flex: 1;
		border: 1px solid ${layouts.theme.colors.grey.dark50};
		box-sizing: border-box;
	}
	.brandguard-subscore {
		max-width: unset;
		display: flex;
		flex: 1;
		.subscore-box {
			width: 100%;
		}
	}
	.actions {
		margin-top: 0;
		.selected-assets-description {
			display: none;
		}
		.download-action,
		.remove-action {
			width: 45px;
			height: 35px;
			box-shadow: ${layouts.theme.boxshadow.black.medium};
			border-radius: 10px;
			justify-content: center;
			align-items: center;
			svg {
				circle {
					display: none;
				}
				path {
					stroke: ${layouts.theme.colors.grey.dark50};
					stroke-opacity: 0.8;
				}
			}
		}
		.download-action {
			margin-right: 10px;
		}

		.remove-action {
			margin-left: 10px;
		}
	}
	.file-details-information-group {
		padding-top: 5px;
		padding-bottom: 5px;
		margin-bottom: 15px;
		&:not(:last-of-type) {
			::after {
				content: none;
			}
		}
	}
`

export const styledcardimage = css`
	.card-image {
		position: relative;
		background: unset;
		img {
			max-width: unset;
			max-height: unset;
			margin-top: unset;
			margin-bottom: auto;
		}
	}
	.card-image-checkbox {
		top: 0;
		right: 0;
		width: 1vw;
		height: 1.5vh;
	}
`

export const styledcardtext = css`
	.loading {
		margin: unset;
	}
	.card-text-content {
		position: relative;
		padding: 10px;
		font-size: 10pt;
		background: unset;
		line-height: 12pt;
		color: ${layouts.theme.colors.grey.dark50};
	}
	.card-image-checkbox {
		top: 0;
		right: 0;
		width: 1vw;
		height: 1.5vh;
	}
`

export const zerotextinsert = css`
	.review-button-container {
		justify-content: flex-end;
		padding-top: 25px;
	}
	textarea {
		border: none;
		outline: none;
		background: ${layouts.theme.backgrounds.bluealpha};
	}
`

export const ZeroText = styled.span`
	max-width: 50%;
	font-size: 18px;
	line-height: 30px;
	color: ${layouts.theme.colors.grey.medium};
	b {
		font-weight: 700;
	}
`

export function PreviewContainer(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.containers.flex
			flex="1"
			flexDirection="row"
			border={layouts.theme.borders.grey.dark50alpha}
			overflow="hidden"
			{...rest}
		>
			{children}
		</layouts.containers.flex>
	)
}

export namespace accordion {
	export const header = styled.span<layouts.containers.FlexProps>`
		font-weight: 700;
		font-size: 18px;
		line-height: 30px;
		color: ${layouts.theme.colors.grey.medium};
	`
	export const subheader = styled.span<layouts.containers.FlexProps>`
		font-weight: 400;
		font-size: 14px;
		line-height: 30px;
		color: ${layouts.theme.colors.grey.medium};
	`
	export function ZeroContainer(props: React.PropsWithChildren<unknown>): JSX.Element {
		const { children } = props
		return (
			<layouts.accordions.Container
				minHeight="unset"
				padding="10px 0"
				flexDirection="column"
				className={classnames(accordiondescription)}
				borderBottom={layouts.theme.borders.grey.dark50alpha}
				m="5px"
			>
				<layouts.containers.box className="accordion-containers-box">
					<layouts.accordions.Description borderBottom={`2px solid ${layouts.theme.colors.grey.light30}`}>
						<accordion.header pl="20px">{children}</accordion.header>
					</layouts.accordions.Description>
				</layouts.containers.box>
			</layouts.accordions.Container>
		)
	}

	export function Container(
		props: React.PropsWithChildren<{ initOpen: boolean; title: string } & layouts.containers.FlexProps>,
	): JSX.Element {
		const { children, initOpen, title, ...rest } = props
		return (
			<layouts.accordions.Container
				initOpen={initOpen}
				minHeight="unset"
				padding="10px 0 0 0"
				flexDirection="column"
				className={classnames(accordiondescription)}
				borderBottom={layouts.theme.borders.grey.dark50alpha}
				{...rest}
			>
				<layouts.containers.box tabIndex={0} className="accordion-containers-box">
					<layouts.accordions.Description borderBottom={`2px solid ${layouts.theme.colors.grey.light30}`}>
						<accordion.header pl="20px">{title}</accordion.header>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex flexDirection="column" width="100%" flex="1">
							{children}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
		)
	}
	Container.defaultProps = {
		initOpen: false,
	}
}

export function PreviewZero(): JSX.Element {
	return (
		<PreviewContainer py="10px" background={layouts.theme.colors.white} boxShadow={layouts.theme.boxshadow.black.wide}>
			<layouts.containers.flex
				flex="6"
				borderRight={layouts.theme.borders.grey.dark50alpha}
				alignItems="center"
				justifyContent="center"
			>
				<ZeroText>
					<b>To Begin:</b> Upload the asset(s) that you would like to score
				</ZeroText>
			</layouts.containers.flex>
			<layouts.containers.flex flex="5" p="25px" flexDirection="column" className={classnames(styledscores)}>
				<accordion.ZeroContainer>Asset Scores</accordion.ZeroContainer>
				<accordion.ZeroContainer>More Assets</accordion.ZeroContainer>
				<layouts.containers.flex flex="1" p="10px" flexDirection="row" justifyContent="center" className="actions">
					<layouts.containers.flex className="download-action">
						<icons.brandguard.download />
					</layouts.containers.flex>
					<layouts.containers.flex className="remove-action">
						<icons.brandguard.remove />
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</PreviewContainer>
	)
}

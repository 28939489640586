import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import { Link } from "react-router-dom"

export default function Container(props: React.PropsWithChildren<unknown>): JSX.Element {
	const { children } = props

	return (
		<layouts.backgrounds.Grey className="settings-container" flex="1">
			<layouts.containers.flex justifyContent="center" flex="1">
				<layouts.containers.flex
					px="50px"
					pt="25px"
					className="brandguard"
					flexDirection="column"
					flex="1"
					overflowY="hidden"
				>
					<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="25px">
						<layouts.containers.flex flex="2">
							<typography.h3 color={layouts.theme.colors.grey.medium} fontWeight="700">
								Settings
							</typography.h3>
						</layouts.containers.flex>
					</layouts.containers.flex>
					{children}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

interface link {
	title: string
	url: string
}

const links = [
	{ title: "Manage Users", url: "/u12t" },
	{ title: "Account", url: "/u12t/account" },
	{ title: "API Access", url: "/u12t/oauth2" },
] as link[]

export function Links(props: { activeLink: string }): JSX.Element {
	return (
		<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="5px">
			<layouts.containers.flex
				flexDirection="row"
				flex="1"
				borderBottom={layouts.theme.borders.grey.dark50alphamedium2px}
			>
				{links.map((l) => {
					if (l.url === props.activeLink)
						return (
							<layouts.containers.flex
								key={l.url}
								p="5px"
								flexDirection="row"
								borderBottom={`2px solid ${layouts.theme.colors.blue.blue}`}
								mr="30px"
								mb="-2px"
							>
								<layouts.containers.flex fontSize="18px" fontWeight="600" color={layouts.theme.colors.blue.blue}>
									{l.title}
								</layouts.containers.flex>
							</layouts.containers.flex>
						)
					return (
						<Link key={l.url} to={l.url} style={{ textDecoration: "none", color: "inherit" }}>
							<layouts.containers.flex p="5px" flexDirection="row" mr="30px">
								<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="18px">
									{l.title}
								</layouts.containers.span>
							</layouts.containers.flex>
						</Link>
					)
				})}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function root(bid: string): string {
	return `/brands/${bid}/training`
}
export namespace image {
	export function upload(bid: string): string {
		return `${root(bid)}/image/upload`
	}
	export function onbrand(bid: string): string {
		return `${root(bid)}/image/onbrand`
	}
	export function offbrand(bid: string): string {
		return `${root(bid)}/image/offbrand`
	}
	export function review(bid: string): string {
		return `${root(bid)}/image/review`
	}
	export function settings(bid: string): string {
		return `${root(bid)}/image/settings`
	}
}

export namespace text {
	export function upload(bid: string): string {
		return `${root(bid)}/text/upload`
	}
	export function onbrand(bid: string): string {
		return `${root(bid)}/text/onbrand`
	}
	export function offbrand(bid: string): string {
		return `${root(bid)}/text/offbrand`
	}
	export function review(bid: string): string {
		return `${root(bid)}/text/review`
	}
	export function settings(bid: string): string {
		return `${root(bid)}/text/settings`
	}
}

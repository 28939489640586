import * as timex from "timex"
import * as msearch from "./meta.search"
export type { DateRange } from "./meta.search"

export namespace dateranges {
	export function everything(): msearch.DateRange {
		return {
			oldest: timex.negInfinity().toISO(),
			newest: timex.infinity().toISO(),
		}
	}

	export function days(n: number): msearch.DateRange {
		return {
			oldest: timex.local().startOf("day").minus(timex.duration.days(n)).toISO(),
			newest: timex.local().endOf("day").toISO(),
		}
	}

	export function since(ts: timex.DateTime): msearch.DateRange {
		return {
			oldest: ts.toISO(),
			newest: timex.utc().toISO(),
		}
	}
}

import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"

const Btn = styled(layouts.buttons.outline)<{ focused: boolean }>`
	width: 120px;
	height: 28px;
	padding: 0;
	font-weight: 500;
	font-size: 12px;
	line-height: 1em;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	margin-right: 5px;
	background: ${(props) => (props.focused ? layouts.theme.colors.blue.blue : "default")};
	color: ${(props) => (props.focused ? layouts.theme.colors.white : layouts.theme.colors.grey.dark10)};
	border-color: ${(props) => (props.focused ? layouts.theme.colors.blue.blue : layouts.theme.colors.white)};
	box-shadow: ${layouts.theme.boxshadow.grey.lightest};

	&:hover {
		border-color: ${layouts.theme.colors.blue.blue};
		background: ${layouts.theme.colors.blue.blue};
	}

	&:disabled {
		color: ${layouts.theme.colors.grey.dark10};
		border-color: ${layouts.theme.colors.white};
		box-shadow: ${layouts.theme.boxshadow.grey.lightest};
		background: ${layouts.theme.colors.white};
	}
`

export default function Button(
	props: React.PropsWithChildren<layouts.buttons.ButtonProps & { focused: boolean }>,
): JSX.Element {
	const { children, ...rest } = props
	return <Btn {...rest}>{children}</Btn>
}

Button.defaultProps = {
	focused: false,
}

import React, { useEffect, useState } from "react"
import * as sessions from "sessions"
import * as brands from "brands"
import * as httpx from "httpx"
import * as layouts from "layouts"
import styled from "@emotion/styled"
import * as icons from "icons"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as bglayouts from "brandguard/components/layouts"
import * as mediaapi from "media/api"

interface props {
	item: api.TextSearchResponseItem
	active?: boolean
	panel: JSX.Element
	onCardClick(): void
	onChecked?(state: boolean): void
	onChange(item: api.TextSearchResponseItem): void
	find(id: string, bid: string, ...options: httpx.option[]): Promise<api.TextFindResponse>
	initialChecked: boolean
}

const Checkbox = styled(icons.brandguard.checkbox)`
	position: absolute;
	top: 7px;
	right: 7px;
`

const LoadingScreen = styled(layouts.loading.screen)`
	margin: auto;
	&.extension {
		margin: unset;
	}
`

export default function Display(
	props: React.PropsWithChildren<props & layouts.containers.ContainerProps>,
): JSX.Element {
	const { item, active, onChange, onCardClick, onChecked, find, panel, className, initialChecked, ...rest } = props
	const [loading, setLoading] = useState(true)
	const [checked, setChecked] = useState(initialChecked)
	const [response, setResponse] = useState("" as string)
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const processing = predictions.processing(item.event?.approved)

	useEffect(() => {
		if (!processing) return
		let cancelled = false
		const tid = setTimeout(() => {
			find(item.media?.id!, brand.id, bearertoken)
				.then((result) => {
					cancelled || onChange(result)
				})
				.catch((cause) => {
					console.error("unable to find brandguard text media", cause)
					cancelled || onChange({ ...item })
				})
		}, Math.round(Math.random() * 5000 + 5000))

		return () => {
			clearTimeout(tid)
			cancelled = true
		}
	}, [item])

	useEffect(() => {
		setLoading(true)
		mediaapi
			.url(item.media?.md5!, bearertoken)
			.then((result) => fetch(result.url))
			.then((resp) => resp.text())
			.then((data) => setResponse(data))
			.catch((cause) => console.error("unable to display brandguard text mediaapi", cause))
			.finally(() => setLoading(false))
	}, [item.media?.md5])

	return (
		<bglayouts.Card
			key={item.media?.id}
			className={`${className} ${active ? "active" : ""}`}
			onClick={onCardClick}
			flexDirection="column"
			{...rest}
		>
			<LoadingScreen loading={loading} className={className}>
				<bglayouts.CardTextContent opacity={processing ? 0.3 : 1} className={`${className} card-text-content`}>
					{response}
				</bglayouts.CardTextContent>
			</LoadingScreen>
			{onChecked && (
				<Checkbox
					onClick={(e) => {
						onChecked(!checked)
						setChecked(!checked)
						e.stopPropagation()
					}}
					checked={checked}
				/>
			)}
			{panel}
		</bglayouts.Card>
	)
}

Display.defaultProps = {
	initialChecked: false,
}

import React from "react"
import { Route, Routes } from "react-router-dom"
import * as layouts from "layouts"
import * as oauth2 from "./oauth2"
import * as gsuite from "./gsuite"
import * as facebook from "./facebook"

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<Routes>
			<Route
				path="/facebook/authz"
				element={
					<layouts.backgrounds.Grey flex="1">
						<oauth2.Authorized exchange={facebook.api.authorization.exchange} />
					</layouts.backgrounds.Grey>
				}
			/>
			<Route
				path="/gsuite/authz"
				element={
					<layouts.backgrounds.Grey flex="1">
						<oauth2.Authorized exchange={gsuite.api.exchange} />
					</layouts.backgrounds.Grey>
				}
			/>
		</Routes>
	)
}

import { useState, useEffect } from "react"
import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"

interface props extends layouts.containers.ContainerProps {
	sectionTitle: string
	sectionDescription?: string
	items: brandguide.api.ApplicationObject[]
	pageTitle?: string
	explanation?: string
	onLoad(): void
}

export default function Display(props: props): JSX.Element {
	const { sectionTitle, items, sectionDescription, pageTitle, explanation, onLoad, ...rest } = props

	const [loadedImages, setLoadedImages] = useState(items.length)

	useEffect(() => {
		if (loadedImages !== 0) return
		onLoad()
	}, [loadedImages])

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>{sectionTitle}</result_components.NavTitle>
				{sectionDescription && (
					<result_components.NavDescription>{sectionDescription}</result_components.NavDescription>
				)}
				<result_components.Explanation desc={explanation} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer>
					<layouts.Flex>
						<typography.h4 color={colors.grey.dark50} lineHeight="30px" fontSize="16px">
							{pageTitle}
						</typography.h4>
					</layouts.Flex>
					<layouts.containers.grid
						gap="5px"
						height="80vh"
						style={{ alignItems: "center" }}
						grid="auto auto auto / auto auto auto"
					>
						{items.map((i) => (
							<brandguide.build.layouts.B64ImageForPDF
								key={i.id}
								contentdigest={i.thumbnail_contentdigest}
								styles={{ maxWidth: "310px", maxHeight: "210px", objectFit: "contain", width: "100%", height: "auto" }}
								onLoad={() => setLoadedImages((prev) => prev - 1)}
							/>
						))}
					</layouts.containers.grid>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as logos from "brandguide/build/logos"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideLogos | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideLogosZero(_guide.guide?.logos))

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Logo(s)" Help={<logos.helps.LogoModal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer
							desc="Please upload all logo variations. We will cover acceptable logo colors in another section. SVG files
								only."
						>
							<logos.Carousel my="25px" autoscrollright>
								<logos.layouts.LogoItem
									item={data.primary!}
									title="Main logo"
									mr="5px"
									onChange={(upd) => setData({ ...data, primary: upd })}
								/>
								<logos.layouts.LogoItem
									item={data.secondary!}
									title="Secondary logo"
									mr="5px"
									onChange={(upd) => setData({ ...data, secondary: upd })}
								/>
								<logos.layouts.LogoItem
									item={data.logomark!}
									title="Logomark"
									mr="5px"
									onChange={(upd) => setData({ ...data, logomark: upd })}
								/>
								<logos.layouts.LogoItem
									item={data.wordmark!}
									title="Wordmark"
									mr="5px"
									onChange={(upd) => setData({ ...data, wordmark: upd })}
								/>
								<logos.layouts.LogoItem
									item={data.alternative!}
									title="Alternative"
									onChange={(upd) => setData({ ...data, alternative: upd })}
								/>
							</logos.Carousel>

							<logos.Explanation
								val={data.descriptions?.uploads || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.logos.descZero({ ...data.descriptions, uploads: text }),
									})
								}}
							/>
							<brandguide.build.layouts.NextStepsNav
								onNext={() => onChange(logos.Steps.CLEARSPASE, data)}
								disabled={!brandguide.validations.logos.valid.uploads(data)}
							/>
							<logos.Progress activeStep={logos.Steps.UPLOAD} steps={logos.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<logos.Guidelines activPage="logos" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

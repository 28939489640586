import React from "react"
import * as containers from "../containers"

export default function Label(props: React.PropsWithChildren<containers.SpanProps>): JSX.Element {
	const { children, ...rest } = props

	return (
		<containers.span my="auto" mr="10px" pb="3px" display="inline-block" {...rest}>
			{children}
		</containers.span>
	)
}

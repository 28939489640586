/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "styleguide.proto" (package "styleguide", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * easyjson:json
 *
 * @generated from protobuf message styleguide.Styleguide
 */
export interface Styleguide {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string uploaded_by = 4 [json_name = "uploaded_by"];
     */
    uploaded_by: string;
    /**
     * @generated from protobuf field: string md5 = 5;
     */
    md5: string;
    /**
     * @generated from protobuf field: string mimetype = 6;
     */
    mimetype: string;
    /**
     * @generated from protobuf field: string mimetype_md5 = 7 [json_name = "mimetype_md5"];
     */
    mimetype_md5: string;
    /**
     * @generated from protobuf field: string created_at = 8 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 9 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string tombstoned_at = 10 [json_name = "tombstoned_at"];
     */
    tombstoned_at: string;
    /**
     * @generated from protobuf field: string description = 11;
     */
    description: string;
    /**
     * @generated from protobuf field: styleguide.State state = 12;
     */
    state: State;
    /**
     * @generated from protobuf field: string welcome_message = 13 [json_name = "welcome_message"];
     */
    welcome_message: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message styleguide.StyleguideUploadRequest
 */
export interface StyleguideUploadRequest {
    /**
     * @generated from protobuf field: styleguide.Styleguide styleguide = 1;
     */
    styleguide?: Styleguide;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message styleguide.StyleguideUploadResponse
 */
export interface StyleguideUploadResponse {
    /**
     * @generated from protobuf field: styleguide.Styleguide styleguide = 1;
     */
    styleguide?: Styleguide;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message styleguide.StyleguideSearchRequest
 */
export interface StyleguideSearchRequest {
    /**
     * @generated from protobuf field: string uploaded_by = 1 [json_name = "uploaded_by"];
     */
    uploaded_by: string;
    /**
     * @generated from protobuf field: string brand_id = 2 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: int64 offset = 3;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message styleguide.StyleguideSearchResponse
 */
export interface StyleguideSearchResponse {
    /**
     * @generated from protobuf field: repeated styleguide.Styleguide items = 1;
     */
    items: Styleguide[];
    /**
     * @generated from protobuf field: styleguide.StyleguideSearchRequest next = 2;
     */
    next?: StyleguideSearchRequest;
}
/**
 * @generated from protobuf enum styleguide.State
 */
export enum State {
    /**
     * @generated from protobuf enum value: UPLOADED = 0;
     */
    UPLOADED = 0,
    /**
     * @generated from protobuf enum value: PARSED = 1;
     */
    PARSED = 1,
    /**
     * @generated from protobuf enum value: EMBEDDINGS_CREATED = 2;
     */
    EMBEDDINGS_CREATED = 2,
    /**
     * @generated from protobuf enum value: ERROR = 999;
     */
    ERROR = 999
}
// @generated message type with reflection information, may provide speed optimized methods
class Styleguide$Type extends MessageType<Styleguide> {
    constructor() {
        super("styleguide.Styleguide", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "uploaded_by", kind: "scalar", localName: "uploaded_by", jsonName: "uploaded_by", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "mimetype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "mimetype_md5", kind: "scalar", localName: "mimetype_md5", jsonName: "mimetype_md5", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "tombstoned_at", kind: "scalar", localName: "tombstoned_at", jsonName: "tombstoned_at", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "state", kind: "enum", T: () => ["styleguide.State", State] },
            { no: 13, name: "welcome_message", kind: "scalar", localName: "welcome_message", jsonName: "welcome_message", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Styleguide>): Styleguide {
        const message = { id: "", account_id: "", brand_id: "", uploaded_by: "", md5: "", mimetype: "", mimetype_md5: "", created_at: "", updated_at: "", tombstoned_at: "", description: "", state: 0, welcome_message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Styleguide>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Styleguide): Styleguide {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string uploaded_by = 4 [json_name = "uploaded_by"];*/ 4:
                    message.uploaded_by = reader.string();
                    break;
                case /* string md5 */ 5:
                    message.md5 = reader.string();
                    break;
                case /* string mimetype */ 6:
                    message.mimetype = reader.string();
                    break;
                case /* string mimetype_md5 = 7 [json_name = "mimetype_md5"];*/ 7:
                    message.mimetype_md5 = reader.string();
                    break;
                case /* string created_at = 8 [json_name = "created_at"];*/ 8:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 9 [json_name = "updated_at"];*/ 9:
                    message.updated_at = reader.string();
                    break;
                case /* string tombstoned_at = 10 [json_name = "tombstoned_at"];*/ 10:
                    message.tombstoned_at = reader.string();
                    break;
                case /* string description */ 11:
                    message.description = reader.string();
                    break;
                case /* styleguide.State state */ 12:
                    message.state = reader.int32();
                    break;
                case /* string welcome_message = 13 [json_name = "welcome_message"];*/ 13:
                    message.welcome_message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Styleguide, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string uploaded_by = 4 [json_name = "uploaded_by"]; */
        if (message.uploaded_by !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.uploaded_by);
        /* string md5 = 5; */
        if (message.md5 !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.md5);
        /* string mimetype = 6; */
        if (message.mimetype !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mimetype);
        /* string mimetype_md5 = 7 [json_name = "mimetype_md5"]; */
        if (message.mimetype_md5 !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mimetype_md5);
        /* string created_at = 8 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 9 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.updated_at);
        /* string tombstoned_at = 10 [json_name = "tombstoned_at"]; */
        if (message.tombstoned_at !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.tombstoned_at);
        /* string description = 11; */
        if (message.description !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.description);
        /* styleguide.State state = 12; */
        if (message.state !== 0)
            writer.tag(12, WireType.Varint).int32(message.state);
        /* string welcome_message = 13 [json_name = "welcome_message"]; */
        if (message.welcome_message !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.welcome_message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message styleguide.Styleguide
 */
export const Styleguide = new Styleguide$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StyleguideUploadRequest$Type extends MessageType<StyleguideUploadRequest> {
    constructor() {
        super("styleguide.StyleguideUploadRequest", [
            { no: 1, name: "styleguide", kind: "message", T: () => Styleguide }
        ]);
    }
    create(value?: PartialMessage<StyleguideUploadRequest>): StyleguideUploadRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StyleguideUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StyleguideUploadRequest): StyleguideUploadRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* styleguide.Styleguide styleguide */ 1:
                    message.styleguide = Styleguide.internalBinaryRead(reader, reader.uint32(), options, message.styleguide);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StyleguideUploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* styleguide.Styleguide styleguide = 1; */
        if (message.styleguide)
            Styleguide.internalBinaryWrite(message.styleguide, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message styleguide.StyleguideUploadRequest
 */
export const StyleguideUploadRequest = new StyleguideUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StyleguideUploadResponse$Type extends MessageType<StyleguideUploadResponse> {
    constructor() {
        super("styleguide.StyleguideUploadResponse", [
            { no: 1, name: "styleguide", kind: "message", T: () => Styleguide }
        ]);
    }
    create(value?: PartialMessage<StyleguideUploadResponse>): StyleguideUploadResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StyleguideUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StyleguideUploadResponse): StyleguideUploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* styleguide.Styleguide styleguide */ 1:
                    message.styleguide = Styleguide.internalBinaryRead(reader, reader.uint32(), options, message.styleguide);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StyleguideUploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* styleguide.Styleguide styleguide = 1; */
        if (message.styleguide)
            Styleguide.internalBinaryWrite(message.styleguide, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message styleguide.StyleguideUploadResponse
 */
export const StyleguideUploadResponse = new StyleguideUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StyleguideSearchRequest$Type extends MessageType<StyleguideSearchRequest> {
    constructor() {
        super("styleguide.StyleguideSearchRequest", [
            { no: 1, name: "uploaded_by", kind: "scalar", localName: "uploaded_by", jsonName: "uploaded_by", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<StyleguideSearchRequest>): StyleguideSearchRequest {
        const message = { uploaded_by: "", brand_id: "", offset: 0n, limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StyleguideSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StyleguideSearchRequest): StyleguideSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uploaded_by = 1 [json_name = "uploaded_by"];*/ 1:
                    message.uploaded_by = reader.string();
                    break;
                case /* string brand_id = 2 [json_name = "brand_id"];*/ 2:
                    message.brand_id = reader.string();
                    break;
                case /* int64 offset */ 3:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StyleguideSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uploaded_by = 1 [json_name = "uploaded_by"]; */
        if (message.uploaded_by !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uploaded_by);
        /* string brand_id = 2 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.brand_id);
        /* int64 offset = 3; */
        if (message.offset !== 0n)
            writer.tag(3, WireType.Varint).int64(message.offset);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message styleguide.StyleguideSearchRequest
 */
export const StyleguideSearchRequest = new StyleguideSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StyleguideSearchResponse$Type extends MessageType<StyleguideSearchResponse> {
    constructor() {
        super("styleguide.StyleguideSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Styleguide },
            { no: 2, name: "next", kind: "message", T: () => StyleguideSearchRequest }
        ]);
    }
    create(value?: PartialMessage<StyleguideSearchResponse>): StyleguideSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StyleguideSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StyleguideSearchResponse): StyleguideSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated styleguide.Styleguide items */ 1:
                    message.items.push(Styleguide.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* styleguide.StyleguideSearchRequest next */ 2:
                    message.next = StyleguideSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StyleguideSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated styleguide.Styleguide items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Styleguide.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* styleguide.StyleguideSearchRequest next = 2; */
        if (message.next)
            StyleguideSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message styleguide.StyleguideSearchResponse
 */
export const StyleguideSearchResponse = new StyleguideSearchResponse$Type();

import React, { useState } from "react"
import * as layouts from "layouts"
import * as typographies from "."
import * as brandguide from "brandguide"
import * as md5x from "md5x"

export const Steps = {
	UPLOAD: 0,
	USAGE: 1,
	RESULT: 2,
}

export function Container(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()

	const _logos = brandguide.api.zeros.guide.guideTypographyZero(cache.guide.guide?.typography)

	const curstep = () => {
		switch (false) {
			case brandguide.validations.typography.valid.uploads(_logos):
				return Steps.UPLOAD
			case brandguide.validations.typography.valid.usage(_logos):
				return Steps.USAGE
			default:
				return Steps.RESULT
		}
	}

	const [step, setStep] = useState(curstep)
	const [loading, setLoading] = useState(false)

	const handleOnStep = (step: number, data: brandguide.api.GuideTypography | undefined) => {
		if (!data) {
			setStep(step)
			return
		}

		const guide = { ...cache.guide.guide, typography: data }
		const md5 = md5x.string(JSON.stringify(guide))

		if (md5 === cache.md5) {
			setStep(step)
			return
		}

		setLoading(true)
		cache.store({ ...cache, guide: { ...cache.guide, guide: guide } }).finally(() => {
			setStep(step)
			setLoading(false)
		})
	}

	return (
		<layouts.Flex overflowY="auto" {...rest}>
			<layouts.loading.screen flex="1" loading={loading}>
				{step === Steps.UPLOAD && (
					<typographies.cache.Storage>
						<typographies.Uploads onChange={handleOnStep} />
					</typographies.cache.Storage>
				)}
				{step === Steps.USAGE && <typographies.Usage onChange={handleOnStep} />}
				{step === Steps.RESULT && <typographies.Result onChange={handleOnStep} />}
			</layouts.loading.screen>
		</layouts.Flex>
	)
}

Container.defaultProps = {
	flexDirection: "column",
	flex: "1",
	mb: "25px",
}

export default Container

import React from "react"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as styles from "./styles"
import Icon from "./icon"

export default function Status(
	props: {
		prediction: api.Prediction
		observation: api.Prediction
		confidence: number
		threshold: number
		color(p: api.Prediction): string
		statusText(p: api.Prediction): JSX.Element
		reasonText(p: api.Prediction, o: api.Prediction, confidence: number, threshold: number): string
	} & layouts.containers.FlexProps,
): JSX.Element {
	const { prediction, observation, confidence, threshold, color, statusText, reasonText, ...rest } = props
	const reason = reasonText(prediction, observation, confidence, threshold)

	return (
		<styles.StatusText background={color(prediction)} {...rest}>
			<Icon prediction={prediction} />
			<layouts.containers.span ml="10px">{statusText(prediction)}</layouts.containers.span>
			{reason && <layouts.containers.span ml="5px">({reason})</layouts.containers.span>}
		</styles.StatusText>
	)
}

Status.defaultProps = {
	prediction: api.Prediction.NONE,
	observation: api.Prediction.NONE,
	confidence: 0,
	threshold: 0,
	color: predictions.color,
	statusText: predictions.string,
	reasonText: predictions.reason,
	p: "2px 9px",
	height: "25px",
}

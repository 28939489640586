import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { fill, ...rest } = props
	return (
		<svg.SVG width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M3.30668 5.33695C2.65563 5.33695 2.21873 5.29412 1.9189 5.22559C1.62764 5.15705 1.52484 5.06282 1.47344 5.00286C1.41348 4.92576 1.35351 4.79726 1.31925 4.506C1.28498 4.20617 1.28498 3.83781 1.28498 3.32382H0C0 3.81211 9.57385e-09 4.26613 0.0428327 4.66019C0.0942319 5.05425 0.19703 5.46545 0.471159 5.80811C0.753855 6.15934 1.14792 6.36493 1.61051 6.4763C2.06453 6.58766 2.62136 6.6305 3.30668 6.6305V5.34552V5.33695ZM5.32838 3.31525C5.32838 3.82924 5.32838 4.1976 5.29412 4.49743C5.25985 4.78869 5.19989 4.92576 5.13992 4.99429C5.08852 5.05425 4.98572 5.14849 4.69446 5.21702C4.39463 5.29412 3.95774 5.33695 3.30668 5.33695V6.62193C3.992 6.62193 4.5574 6.5791 5.01142 6.46773C5.47401 6.35637 5.86808 6.15077 6.15077 5.79954C6.4249 5.45688 6.5277 5.04569 6.5791 4.65163C6.62193 4.25757 6.62193 3.80354 6.62193 3.31525H5.33695H5.32838ZM3.30668 1.28498C3.94917 1.28498 4.38607 1.32781 4.68589 1.39634C4.96859 1.46488 5.07995 1.55911 5.13135 1.62764C5.19132 1.70474 5.25128 1.83324 5.29412 2.1245C5.32838 2.41576 5.33695 2.79269 5.33695 3.30668H6.62193C6.62193 2.81839 6.62193 2.3558 6.57053 1.96174C6.51913 1.56768 6.40777 1.15648 6.13364 0.813821C5.85094 0.462593 5.45688 0.265563 4.99429 0.145631C4.54026 0.0428327 3.98344 0 3.30668 0V1.28498ZM1.28498 3.31525C1.28498 2.80126 1.28498 2.4329 1.32781 2.13307C1.36208 1.8418 1.43061 1.70474 1.49058 1.63621C1.54198 1.56768 1.65334 1.48201 1.93604 1.40491C2.23587 1.32781 2.66419 1.28498 3.31525 1.28498V0C2.63849 0 2.0731 0.0428327 1.62764 0.154198C1.16505 0.274129 0.770988 0.471159 0.488292 0.822387C0.214163 1.16505 0.102798 1.56768 0.0513992 1.9703C3.19128e-09 2.36436 0 2.81839 0 3.30668H1.28498V3.31525Z"
				fill={fill}
			/>
			<path
				d="M11.6848 5.32838C11.0337 5.32838 10.5968 5.28555 10.297 5.21702C10.0057 5.14849 9.90293 5.05425 9.85153 4.99429C9.79156 4.91719 9.73159 4.78869 9.69733 4.49743C9.66306 4.1976 9.66306 3.82924 9.66306 3.31525H8.37808C8.37808 3.80354 8.37808 4.25757 8.42092 4.65163C8.47231 5.04569 8.57511 5.45688 8.84924 5.79954C9.13194 6.15077 9.526 6.35637 9.98859 6.46773C10.4426 6.5791 10.9994 6.62193 11.6848 6.62193V5.33695V5.32838ZM13.7065 3.30668C13.7065 3.82067 13.7065 4.18903 13.6722 4.48886C13.6379 4.78013 13.578 4.91719 13.518 4.98572C13.4666 5.04569 13.3638 5.13992 13.0725 5.20845C12.7727 5.28555 12.3358 5.32838 11.6848 5.32838V6.61336C12.3701 6.61336 12.9355 6.57053 13.3895 6.45917C13.8521 6.3478 14.2462 6.1422 14.5289 5.79098C14.803 5.44832 14.9058 5.03712 14.9572 4.64306C15 4.249 15 3.79497 15 3.30668H13.715H13.7065ZM11.6848 1.28498C12.3273 1.28498 12.7641 1.32781 13.064 1.39634C13.3467 1.46488 13.458 1.55911 13.5094 1.62764C13.5694 1.70474 13.6294 1.83324 13.6722 2.1245C13.7065 2.41576 13.715 2.79269 13.715 3.30668H15C15 2.81839 15 2.3558 14.9486 1.96174C14.8972 1.56768 14.7859 1.15648 14.5117 0.813821C14.229 0.462593 13.835 0.265563 13.3724 0.145631C12.9183 0.0428327 12.3615 0 11.6848 0V1.28498ZM9.66306 3.30668C9.66306 2.79269 9.66306 2.42433 9.7059 2.1245C9.74016 1.83324 9.80869 1.69617 9.86866 1.62764C9.92006 1.55911 10.0314 1.47344 10.3141 1.39634C10.6139 1.31925 11.0423 1.27641 11.6933 1.27641V0C11.0166 0 10.4512 0.0428327 10.0057 0.154198C9.54313 0.274129 9.14907 0.471159 8.86638 0.822387C8.59225 1.16505 8.48088 1.56768 8.42948 1.9703C8.37808 2.36436 8.37808 2.81839 8.37808 3.30668H9.66306Z"
				fill={fill}
			/>
			<path
				d="M3.31525 13.7065C2.66419 13.7065 2.2273 13.6636 1.92747 13.5951C1.63621 13.5266 1.53341 13.4323 1.48201 13.3724C1.42204 13.2953 1.36208 13.1668 1.32781 12.8755C1.29355 12.5757 1.29355 12.2073 1.29355 11.6933H0C0 12.1816 9.57385e-09 12.6356 0.0428327 13.0297C0.0942319 13.4238 0.19703 13.835 0.471159 14.1776C0.753855 14.5288 1.14792 14.7344 1.61051 14.8458C2.06453 14.9572 2.62136 15 3.30668 15V13.715L3.31525 13.7065ZM5.33695 11.6848C5.33695 12.1987 5.33695 12.5671 5.30268 12.8669C5.26842 13.1582 5.20845 13.2953 5.14849 13.3638C5.09709 13.4238 4.99429 13.518 4.70303 13.5865C4.4032 13.6636 3.9663 13.7065 3.31525 13.7065V14.9914C4.00057 14.9914 4.56596 14.9486 5.01999 14.8372C5.48258 14.7259 5.87664 14.5203 6.15934 14.1691C6.43347 13.8264 6.53626 13.4152 6.58766 13.0211C6.6305 12.6271 6.6305 12.173 6.6305 11.6848H5.34552H5.33695ZM3.31525 9.66305C3.95774 9.66305 4.39463 9.70589 4.69446 9.77442C4.97716 9.84295 5.08852 9.93718 5.13992 10.0057C5.19989 10.0828 5.25985 10.2113 5.30268 10.5026C5.33695 10.7938 5.34552 11.1708 5.34552 11.6848H6.6305C6.6305 11.1965 6.6305 10.7339 6.5791 10.3398C6.5277 9.94575 6.41633 9.53455 6.1422 9.19189C5.85951 8.84067 5.46545 8.64364 5.00286 8.5237C4.54883 8.41234 3.992 8.36951 3.31525 8.36951V9.65449V9.66305ZM1.29355 11.6848C1.29355 11.1708 1.29355 10.8024 1.33638 10.5026C1.37065 10.2113 1.43918 10.0742 1.49914 10.0057C1.55054 9.93718 1.66191 9.85152 1.9446 9.77442C2.24443 9.69732 2.67276 9.65449 3.32382 9.65449V8.36951C2.64706 8.36951 2.08167 8.41234 1.63621 8.5237C1.17362 8.64364 0.779555 8.84067 0.496859 9.19189C0.22273 9.53455 0.111365 9.93718 0.0599657 10.3398C0.00856653 10.7339 0.00856652 11.1879 0.00856652 11.6762H1.29355V11.6848Z"
				fill={fill}
			/>
			<path
				d="M11.6933 13.7065C11.0423 13.7065 10.6054 13.6636 10.3056 13.5951C10.0143 13.5266 9.9115 13.4323 9.8601 13.3724C9.80014 13.2953 9.74017 13.1668 9.7059 12.8755C9.67164 12.5757 9.67164 12.2073 9.67164 11.6933H8.38666C8.38666 12.1816 8.38666 12.6356 8.42949 13.0297C8.48089 13.4238 8.58369 13.835 8.85782 14.1776C9.14051 14.5288 9.53457 14.7344 9.99717 14.8458C10.4512 14.9572 11.008 15 11.6933 15V13.715V13.7065ZM13.715 11.6848C13.715 12.1987 13.715 12.5671 13.6808 12.8669C13.6465 13.1582 13.5865 13.2953 13.5266 13.3638C13.4752 13.4238 13.3724 13.518 13.0811 13.5865C12.7813 13.6636 12.3444 13.7065 11.6933 13.7065V14.9914C12.3787 14.9914 12.9441 14.9486 13.3981 14.8372C13.8607 14.7259 14.2547 14.5203 14.5374 14.1691C14.8116 13.8264 14.9144 13.4152 14.9658 13.0211C15.0086 12.6271 15.0086 12.173 15.0086 11.6848H13.7236H13.715ZM11.6933 9.66305C12.3358 9.66305 12.7727 9.70589 13.0726 9.77442C13.3552 9.84295 13.4666 9.93718 13.518 10.0057C13.578 10.0828 13.6379 10.2113 13.6808 10.5026C13.715 10.7938 13.7236 11.1708 13.7236 11.6848H15.0086C15.0086 11.1965 15.0086 10.7339 14.9572 10.3398C14.9058 9.94575 14.7944 9.53455 14.5203 9.19189C14.2376 8.84067 13.8435 8.64364 13.3809 8.5237C12.9269 8.41234 12.3701 8.36951 11.6933 8.36951V9.65449V9.66305ZM9.67164 11.6848C9.67164 11.1708 9.67164 10.8024 9.71447 10.5026C9.74874 10.2113 9.81727 10.0742 9.87723 10.0057C9.92863 9.93718 10.04 9.85152 10.3227 9.77442C10.6225 9.69732 11.0508 9.65449 11.7019 9.65449V8.36951C11.0251 8.36951 10.4598 8.41234 10.0143 8.5237C9.55171 8.64364 9.15765 8.84067 8.87495 9.19189C8.60082 9.53455 8.48946 9.93718 8.43806 10.3398C8.38666 10.7339 8.38666 11.1879 8.38666 11.6762H9.67164V11.6848Z"
				fill={fill}
			/>
		</svg.SVG>
	)
}

export default Icon

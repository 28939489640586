import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as imagery from "brandguide/build/imagery"
import * as uuid from "uuid"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideImagery | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideImageryZero(_guide.guide?.imagery))

	const items = data.misuse || []

	useEffect(() => {
		if (items.length > 0) return
		setData({ ...data, misuse: brandguide.build.generators.initial.imagery.misuse() })
	}, [])

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Photography to Avoid" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please upload photography to avoid and add why it is not acceptable.">
							<imagery.Carousel my="25px" className={imagery.layouts.styledGap5} autoscrollright>
								{items.map((i) => (
									<imagery.layouts.MisuseItem
										key={i.id}
										item={i}
										onChange={(upd) => {
											setData({ ...data, misuse: items.map((i) => (i.id === upd.id ? upd : i)) })
										}}
									/>
								))}
								<imagery.layouts.AddMisuseItem
									onChange={() => {
										setData({
											...data,
											misuse: [...items, ...[brandguide.api.zeros.imagery.objectZero({ id: uuid.v4() })]],
										})
									}}
								/>
							</imagery.Carousel>

							<imagery.Explanation
								val={data.descriptions?.misuse || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.imagery.descZero({ ...data.descriptions, misuse: text }),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => {
									let back = imagery.Steps.START
									if (brandguide.validations.imagery.enabled.lifestyle(data))
										back = imagery.layouts.steps.findIndex((i) => i === "Lifestyle")
									if (brandguide.validations.imagery.enabled.product(data))
										back = imagery.layouts.steps.findIndex((i) => i === "Product")
									if (brandguide.validations.imagery.enabled.illustration(data))
										back = imagery.layouts.steps.findIndex((i) => i === "Illustration")
									onChange(back, undefined)
								}}
								onNext={() =>
									onChange(imagery.Steps.FUNDAMENTALS, {
										...data,
										misuse: items.filter((i) => i.thumbnail_contentdigest !== ""),
									})
								}
							/>
							<brandguide.build.layouts.SkipStep
								my="30px"
								onSkip={() => onChange(imagery.Steps.FUNDAMENTALS, data)}
								onNeverHave={() => onChange(imagery.Steps.FUNDAMENTALS, data)}
							/>
							<imagery.Progress
								activeStep={imagery.layouts.actualSteps(data).findIndex((i) => i === "Misuse")}
								steps={imagery.layouts.actualSteps(data)}
							/>
						</brandguide.build.layouts.SectionStepContainer>
						<imagery.Guidelines activPage="imagery" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

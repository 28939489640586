import React, { useState, useMemo } from "react"
import { useLocation } from "react-router-dom"
import * as layouts from "layouts"
import * as titlebars from "titlebars"
import * as icons from "icons"
import * as inputs from "inputs"
import * as timex from "timex"
import * as navlayouts from "./layouts"
import * as routing from "brandguard/routing"
import * as trainingrouting from "brandguard/display/training/routing"
import { Link } from "react-router-dom"
import * as brands from "brands"
import * as typography from "typography"
import * as debugx from "x/debugx"

export function SuggestedTrainingLink(props: { url: string }): JSX.Element {
	const location = useLocation()
	if (!debugx.alpha.enabled()) return <></>

	return (
		<Link to={props.url}>
			<titlebars.navigation.Button className={props.url === location.pathname ? "active" : ""}>
				Suggested Training
			</titlebars.navigation.Button>
		</Link>
	)
}

export function ReviewLink(props: { url: string }): JSX.Element {
	const location = useLocation()

	if (props.url === location.pathname) return <></>

	return (
		<navlayouts.NavLink
			url={props.url}
			title="Review Assets"
			icon={<icons.brandguard.review className="icon-with-stroke" width="15px" height="15px" mr="8px" />}
		/>
	)
}

export function ApprovedLink(props: { url: string }): JSX.Element {
	const location = useLocation()

	if (props.url === location.pathname) return <></>

	return (
		<navlayouts.NavLink
			url={props.url}
			title="Approved Assets"
			icon={<icons.brandguard.accepted className="icon-with-stroke" width="20px" height="15px" mr="8px" />}
		/>
	)
}

export function RejectedLink(props: { url: string }): JSX.Element {
	const location = useLocation()

	if (props.url === location.pathname) return <></>

	return (
		<navlayouts.NavLink
			url={props.url}
			title="Rejected Assets"
			icon={<icons.brandguard.rejected className="" width="20px" height="15px" mr="8px" />}
		/>
	)
}

export function OnBrandLink(props: { url: string }): JSX.Element {
	const location = useLocation()

	if (props.url === location.pathname) return <></>

	return (
		<navlayouts.NavLink
			url={props.url}
			title="On-Brand Assets"
			icon={<icons.brandguard.accepted className="icon-with-stroke" width="20px" height="15px" mr="8px" />}
		/>
	)
}

export function OffBrandLink(props: { url: string }): JSX.Element {
	const location = useLocation()

	if (props.url === location.pathname) return <></>

	return (
		<navlayouts.NavLink
			url={props.url}
			title="Off-Brand Assets"
			icon={<icons.brandguard.rejected className="" width="20px" height="15px" mr="8px" />}
		/>
	)
}

export const Zero = (): JSX.Element => {
	return <layouts.containers.flex mb="20px" />
}

export const ImgAssets = (): JSX.Element => {
	const brand = brands.caching.useCached()

	return (
		<Assets>
			<ReviewLink url={routing.image.review(brand.id)} />
			<ApprovedLink url={routing.image.accepted(brand.id)} />
			<RejectedLink url={routing.image.rejected(brand.id)} />
		</Assets>
	)
}

export const TextAssets = (): JSX.Element => {
	const brand = brands.caching.useCached()

	return (
		<Assets>
			<ReviewLink url={routing.text.review(brand.id)} />
			<ApprovedLink url={routing.text.accepted(brand.id)} />
			<RejectedLink url={routing.text.rejected(brand.id)} />
		</Assets>
	)
}

export const TrainingTextAssets = (): JSX.Element => {
	const brand = brands.caching.useCached()

	return (
		<Assets>
			<OnBrandLink url={trainingrouting.text.onbrand(brand.id)} />
			<OffBrandLink url={trainingrouting.text.offbrand(brand.id)} />
		</Assets>
	)
}

const Assets = (props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element => {
	const { children, ...rest } = props
	return (
		<navlayouts.Container minHeight="85px" {...rest}>
			<layouts.containers.flex className="bg-buttons" justifyContent="flex-start" alignItems="center">
				<layouts.containers.flex flexDirection="row">{children}</layouts.containers.flex>
			</layouts.containers.flex>
		</navlayouts.Container>
	)
}

export function MeterLink(
	props: React.PropsWithChildren<React.DOMAttributes<HTMLButtonElement> & layouts.containers.FlexProps>,
): JSX.Element {
	if (!debugx.alpha.enabled()) return <></>

	const { children, ...rest } = props
	return (
		<titlebars.navigation.Button {...rest}>
			Brand Accuracy Meter
			{children}
		</titlebars.navigation.Button>
	)
}

export const Meter = (): JSX.Element => {
	return <navlayouts.Container>Meter</navlayouts.Container>
}

export function RescoreLink(
	props: React.PropsWithChildren<React.DOMAttributes<HTMLButtonElement> & layouts.containers.FlexProps>,
): JSX.Element {
	if (!debugx.alpha.enabled()) return <></>

	const { children, ...rest } = props

	return (
		<titlebars.navigation.Button {...rest}>
			Rescore Assets
			{children}
		</titlebars.navigation.Button>
	)
}

export function RescoreEnqueue(props: { enqueue: (ago: timex.DateTime) => Promise<void> }): JSX.Element {
	const [disabled, setDisabled] = useState(false)
	const [ago, setAgo] = useState(timex.duration.minutes(-5))

	return (
		<>
			<inputs.Numeric
				placeholder="minutes ago"
				defaultValue={ago.hours.toString()}
				min={1}
				max={60}
				onChange={(evt) => setAgo(timex.duration.minutes(-1 * evt.currentTarget.valueAsNumber))}
			/>
			<layouts.buttons.unstyled
				cursor="pointer"
				border="1px solid #FFFFFF"
				borderRadius="1em"
				background="transparent"
				minWidth="20ch"
				color={layouts.theme.colors.white}
				disabled={disabled}
				onClick={() => {
					setDisabled(true)
					props
						.enqueue(timex.utc().plus(ago))
						.then(() => setDisabled(false))
						.catch((cause) => {
							console.error("unable to navigate to Rescore", cause)
							setDisabled(false)
						})
				}}
			>
				Rescore
			</layouts.buttons.unstyled>
		</>
	)
}

export function URLImportEnqueue(props: { enqueue: (url: string | undefined) => Promise<void> }): JSX.Element {
	const [disabled, setDisabled] = useState(false)
	const [domain, setDomain] = useState(undefined as string | undefined)
	const brand = brands.caching.useCached()

	return (
		<>
			<inputs.Text
				placeholder={brand.domain}
				defaultValue={domain}
				onChange={(evt) => setDomain(evt.currentTarget.value)}
			/>
			<layouts.buttons.unstyled
				cursor="pointer"
				border="1px solid #FFFFFF"
				borderRadius="1em"
				background="transparent"
				minWidth="20ch"
				color={layouts.theme.colors.white}
				disabled={disabled}
				onClick={() => {
					setDisabled(true)
					props
						.enqueue(domain)
						.then(() => setDisabled(false))
						.catch((cause) => {
							console.error("unable to navigate to URL imports", cause)
							setDisabled(false)
						})
				}}
			>
				Import
			</layouts.buttons.unstyled>
		</>
	)
}

export function AdminControlLink(
	props: React.PropsWithChildren<React.DOMAttributes<HTMLButtonElement> & layouts.containers.FlexProps>,
): JSX.Element {
	if (!debugx.alpha.enabled()) return <></>

	const { children, ...rest } = props

	return (
		<titlebars.navigation.Button {...rest}>
			Settings
			{children}
		</titlebars.navigation.Button>
	)
}

export function AdminTrainingEnqueue(props: { enqueue: (ago: timex.DateTime) => Promise<void> }): JSX.Element {
	const [disabled, setDisabled] = useState(false)
	const [ago, setAgo] = useState(timex.duration.hours(100))

	return (
		<>
			<inputs.Numeric
				placeholder="hours"
				defaultValue={ago.hours.toString()}
				min={0}
				max={999}
				onChange={(evt) => setAgo(timex.duration.hours(evt.currentTarget.valueAsNumber))}
			/>
			<layouts.buttons.unstyled
				cursor="pointer"
				border="1px solid #FFFFFF"
				borderRadius="1em"
				background="transparent"
				minWidth="20ch"
				color={layouts.theme.colors.white}
				disabled={disabled}
				onClick={() => {
					setDisabled(true)
					props
						.enqueue(timex.utc().plus(ago))
						.then(() => setDisabled(false))
						.catch((cause) => {
							console.error("unable to navigate to admin training enqueue", cause)
							setDisabled(false)
						})
				}}
			>
				Train
			</layouts.buttons.unstyled>
		</>
	)
}

export function AdminControls(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props

	return (
		<Assets px="20px" {...rest}>
			{children}
		</Assets>
	)
}

export function ActiveNav(
	props: React.PropsWithChildren<{
		current: JSX.Element
		redirect: string
		dropdown: JSX.Element
	}>,
): JSX.Element {
	const { current, redirect, dropdown, children } = props
	const zero = useMemo(Zero, [])
	const [Focused, setFocused] = useState(zero)

	return (
		<>
			<layouts.containers.flex flexDirection="row">
				<layouts.containers.flex
					justifyContent="space-between"
					background={layouts.theme.colors.white}
					borderRadius="10px"
					minHeight="70px"
					mb="4px"
					flex="6"
				>
					<layouts.containers.flex p="10px" flex="1" justifyContent="flex-start">
						<Link to={redirect} state={{ from: "brandguard/assets" }}>
							<titlebars.navigation.Button>Upload Assets</titlebars.navigation.Button>
						</Link>
					</layouts.containers.flex>
					<layouts.containers.flex p="10px" flex="3" justifyContent="flex-start">
						<navlayouts.ActiveTab justifyContent="center" alignItems="center">
							<layouts.containers.flex flex="3" justifyContent="center" pl="5px" pr="5px" position="relative">
								<titlebars.navigation.Button
									className="active"
									onClick={() => setFocused(Focused === dropdown ? zero : dropdown)}
								>
									Filter Assets
								</titlebars.navigation.Button>
								{Focused !== zero && <navlayouts.poligon top="49px" />}
							</layouts.containers.flex>
							<layouts.containers.flex flex="3">{current}</layouts.containers.flex>
							<layouts.containers.flex flex="1" justifyContent="flex-end" px="5px">
								<Link to={redirect}>
									<icons.brandguard.backarrow />
								</Link>
							</layouts.containers.flex>
						</navlayouts.ActiveTab>
					</layouts.containers.flex>
					{children}
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="row">
				<layouts.containers.flex flexDirection="column" flex="6">
					{Focused}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</>
	)
}

export function Title(props: React.PropsWithChildren<{ title: string; subtitle?: string }>): JSX.Element {
	const { title, subtitle } = props
	return (
		<layouts.containers.flex flexDirection="row" className="nav-bar">
			<layouts.containers.flex flexDirection="row" flex="6">
				<layouts.containers.flex flex={subtitle ? "1" : "6"}>
					<typography.h3 color={layouts.theme.colors.grey.medium} pb="10px" fontWeight="700" lineHeight="20px">
						{title}
					</typography.h3>
				</layouts.containers.flex>
				{subtitle && (
					<layouts.containers.flex flex="3" justifyContent="flex-start">
						<typography.h3
							color={layouts.theme.colors.grey.medium}
							pb="10px"
							fontSize="12px"
							fontWeight="400"
							lineHeight="20px"
						>
							{subtitle}
						</typography.h3>
					</layouts.containers.flex>
				)}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

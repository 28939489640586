export function media(bid: string): string {
	return `/brands/${bid}/media`
}

export function root(bid: string): string {
	return `/brands/${bid}`
}

export function brandguard(bid: string): string {
	return `/brands/${bid}/brandguard`
}

export function brandguide(bid: string): string {
	return `/brands/${bid}/brandguide`
}

export function brandgpt(bid: string): string {
	return `/brands/${bid}/brandgpt`
}

export function ads(bid: string): string {
	return `/brands/${bid}/ads`
}

export function index(): string {
	return "/brands"
}

export function unknown(): string {
	return "/brands/unknown"
}

import * as React from "react"
import { SVG, SVGProps } from "./svg"

export default function Icon(props: SVGProps): JSX.Element {
	// eslint-disable-next-line no-unused-vars
	const { fill, stroke, className, ...rest } = props
	return (
		<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...rest}>
			<path
				d="M1024 512C1024 229.23 794.77 0 512 0S0 229.23 0 512c0 255.55 187.23 467.37 432 505.78V660H302V512h130V399.2C432 270.88 508.44 200 625.39 200 681.41 200 740 210 740 210v126h-64.56c-63.6 0-83.44 39.47-83.44 79.96V512h142l-22.7 148H592v357.78c244.77-38.41 432-250.22 432-505.78Z"
				fill="#1877f2"
			/>
			<path
				d="M711.3 660 734 512H592v-96.04c0-40.49 19.84-79.96 83.44-79.96H740V210s-58.59-10-114.61-10C508.44 200 432 270.88 432 399.2V512H302v148h130v357.78c26.07 4.09 52.78 6.22 80 6.22s53.93-2.13 80-6.22V660h119.3Z"
				fill="#fff"
			/>
		</SVG>
	)
}

import { CancellablePromise } from "real-cancellable-promise"
import * as httpx from "httpx"
import * as uuid from "uuid"

import {
	Color,
	MetaData,
	ColorObject,
	ColorContrastObject,
	ColorDescriptions,
	GuideColors,
	LogoObject,
	LogoPlacement,
	LogoMisuse,
	LogoUsage,
	LogosDescriptions,
	GuideLogos,
	TextTransform,
	TypographyObject,
	FontObject,
	GuideTypography,
	TypographyDescriptions,
	ImageryDescriptions,
	ImageryObject,
	ImagerySection,
	ImageryFundamentalItem,
	ImageryFundamentalObject,
	GuideImagery,
	ToneOfVoiceAdjective,
	ToneOfVoiceObject,
	ToneOfVoiceDescriptions,
	GuideToneOfVoice,
	ApplicationObject,
	ApplicationDescriptions,
	GuideApplication,
	ArchetypeAdjectiveObject,
	Archetype,
	ArchetypeDescriptions,
	GuideArchetype,
	ComplianceName,
	ComplianceItem,
	ComplianceDescriptions,
	GuideCompliance,
	Guide,
	BrandguideObject as Brandguide,
	BrandguideCreateResponse,
	BrandguideFindResponse,
	BrandguideCreateRequest,
	GoogleFontsResponse,
	GoogleFont,
	BrandguideSearchRequest,
	BrandguideSearchResponse,
} from "./brandguide_pb"

export {
	Color,
	MetaData,
	ColorObject,
	ColorContrastObject,
	ColorDescriptions,
	GuideColors,
	LogoObject,
	Placements,
	LogoPlacement,
	LogoMisuse,
	LogoUsage,
	LogosDescriptions,
	GuideLogos,
	TextTransform,
	ImageryFundamentalItem,
	TypographyObject,
	FontObject,
	GuideTypography,
	TypographyDescriptions,
	ImageryDescriptions,
	ImageryObject,
	ImageryFundamentalObject,
	ImagerySection,
	GuideImagery,
	ToneOfVoiceAdjective,
	ToneOfVoiceObject,
	ToneOfVoiceDescriptions,
	GuideToneOfVoice,
	ApplicationObject,
	ApplicationDescriptions,
	GuideApplication,
	ArchetypeAdjectiveObject,
	Archetype,
	ArchetypeDescriptions,
	GuideArchetype,
	ComplianceName,
	ComplianceItem,
	ComplianceDescriptions,
	GuideCompliance,
	Guide,
	BrandguideObject as Brandguide,
	BrandguideFindResponse,
	GoogleFontsResponse,
	GoogleFont,
	BrandguideSearchRequest,
	BrandguideSearchResponse,
} from "./brandguide_pb"

export namespace zeros {
	export namespace searches {
		export function zero(r: Partial<BrandguideSearchRequest> = {}): BrandguideSearchRequest {
			return {
				brand_id: "",
				limit: 20,
				offset: 0,
				...r,
			}
		}
	}
	export namespace googleFonts {
		export function zero(f: Partial<GoogleFont> = {}): GoogleFont {
			return {
				family: "",
				variants: [],
				subsets: [],
				version: "",
				lastModified: "",
				files: {},
				category: "",
				kind: "",
				menu: "",
				...f,
			}
		}
	}

	export namespace colors {
		export function metadataZero(i: Partial<MetaData> = {}): MetaData {
			return {
				id: "",
				favorite: false,
				default: false,
				...i,
			}
		}

		export function colorZero(i: Partial<Color> = {}): Color {
			return {
				hex_code: "",
				name: "",
				...i,
			}
		}

		export function descZero(i: Partial<ColorDescriptions> = {}): ColorDescriptions {
			return {
				primary_colors: "",
				secondary_colors: "",
				correct_color_contrasts: "",
				incorrect_color_contrasts: "",
				...i,
			}
		}

		export function colorObjectZero(i: Partial<ColorObject> = {}): ColorObject {
			return {
				color: colorZero(),
				metadata: metadataZero(),
				...i,
			}
		}

		export function colorContrastObjectZero(i: Partial<ColorContrastObject> = {}): ColorContrastObject {
			return {
				background: colorZero(),
				text: colorZero(),
				metadata: metadataZero(),
				...i,
			}
		}
	}

	export namespace logos {
		export function descZero(d: Partial<LogosDescriptions> = {}): LogosDescriptions {
			return {
				uploads: "",
				clearspace: "",
				proportions: "",
				placement: "",
				misuse: "",
				usage: "",
				...d,
			}
		}

		export function logoObjectZero(lo: Partial<LogoObject> = {}): LogoObject {
			return {
				id: "",
				svg: "",
				present: false,
				clearspace: 0,
				clearspace_path_index: 0,
				min_size: 24,
				...lo,
			}
		}

		export function logoMisuseZero(lm: Partial<LogoMisuse> = {}): LogoMisuse {
			return {
				title: "",
				svg: "",
				...lm,
			}
		}

		export function logoUsageZero(lu: Partial<LogoUsage> = {}): LogoUsage {
			return {
				id: "",
				title: "",
				use: false,
				color: zeros.colors.colorZero(),
				...lu,
			}
		}

		export function logoPlacementZero(lp: Partial<LogoPlacement> = {}): LogoPlacement {
			return {
				left: [],
				center: [],
				right: [],
				all: [],
				...lp,
			}
		}
	}

	export namespace typography {
		export function fontZero(i: Partial<FontObject> = {}): FontObject {
			return {
				id: "",
				font: "",
				font_family: "",
				file_name: "",
				font_type: "",
				...i,
			}
		}

		export function itemZero(i: Partial<TypographyObject> = {}): TypographyObject {
			return {
				id: "",
				font_id: "",
				title: "",
				size: 0,
				bold: false,
				italic: false,
				transform: TextTransform.NONE,
				...i,
			}
		}

		export function descZero(i: Partial<TypographyDescriptions> = {}): TypographyDescriptions {
			return {
				uploads: "",
				usage: "",
				...i,
			}
		}
	}

	export namespace imagery {
		export function objectZero(i: Partial<ImageryObject> = {}): ImageryObject {
			return {
				id: "",
				description: "",
				contentdigest: "",
				thumbnail_contentdigest: "",
				...i,
			}
		}

		export function sectionZero(i: Partial<ImagerySection> = {}): ImagerySection {
			return {
				enabled: false,
				items: [],
				...i,
			}
		}

		export function fundamentalItemZero(i: Partial<ImageryFundamentalItem> = {}): ImageryFundamentalItem {
			return {
				name: "",
				checked: false,
				is_default: false,
				...i,
			}
		}

		export function fundamentalObjectZero(i: Partial<ImageryFundamentalObject> = {}): ImageryFundamentalObject {
			return {
				id: "",
				name: "",
				items: [],
				...i,
			}
		}

		export function descZero(i: Partial<ImageryDescriptions> = {}): ImageryDescriptions {
			return {
				lifestyle: "",
				product: "",
				illustration: "",
				misuse: "",
				fundamentals: "",
				...i,
			}
		}
	}

	export namespace tone_of_voice {
		export function objectZero(i: Partial<ToneOfVoiceObject> = {}): ToneOfVoiceObject {
			return {
				id: "",
				title: "",
				text: "",
				description: "",
				selected: false,
				...i,
			}
		}

		export function adjectiveZero(i: Partial<ToneOfVoiceAdjective> = {}): ToneOfVoiceAdjective {
			return {
				id: uuid.NIL,
				name: "",
				...i,
			}
		}

		export function descZero(i: Partial<ToneOfVoiceDescriptions> = {}): ToneOfVoiceDescriptions {
			return {
				tone_of_voice: "",
				adjectives: "",
				writing_style: "",
				...i,
			}
		}
	}

	export namespace archetype {
		export function adjectiveZero(i: Partial<ArchetypeAdjectiveObject> = {}): ArchetypeAdjectiveObject {
			return {
				archetype: Archetype.Outlaw,
				items: [],
				percent: 0,
				...i,
			}
		}

		export function descZero(i: Partial<ArchetypeDescriptions> = {}): ArchetypeDescriptions {
			return {
				selection: "",
				breakdown: "",
				...i,
			}
		}
	}

	export namespace application {
		export function objectZero(i: Partial<ApplicationObject> = {}): ApplicationObject {
			return {
				id: "",
				contentdigest: "",
				thumbnail_contentdigest: "",
				...i,
			}
		}

		export function descZero(i: Partial<ApplicationDescriptions> = {}): ApplicationDescriptions {
			return {
				upload: "",
				...i,
			}
		}
	}

	export namespace compliance {
		export function nameZero(cn: Partial<ComplianceName> = {}): ComplianceName {
			return {
				id: "",
				name: "",
				url: "",
				...cn,
			}
		}

		export function objectZero(c: Partial<ComplianceItem> = {}): ComplianceItem {
			return {
				id: "",
				title: "",
				info: "",
				names: [],
				...c,
			}
		}

		export function descZero(d: Partial<ComplianceDescriptions> = {}): ComplianceDescriptions {
			return {
				selection: "",
				...d,
			}
		}
	}

	export namespace guide {
		export function guideLogosZero(gc: Partial<GuideLogos> = {}): GuideLogos {
			return {
				id: "logos",
				primary: zeros.logos.logoObjectZero({ id: "primary" }),
				secondary: zeros.logos.logoObjectZero({ id: "secondary" }),
				logomark: zeros.logos.logoObjectZero({ id: "logomark" }),
				wordmark: zeros.logos.logoObjectZero({ id: "wordmark" }),
				alternative: zeros.logos.logoObjectZero({ id: "alternative" }),
				enabled: false,
				placement: zeros.logos.logoPlacementZero(),
				misuse: [],
				usage: [],
				descriptions: zeros.logos.descZero(),
				...gc,
			}
		}

		export function guideColorsZero(gc: Partial<GuideColors> = {}): GuideColors {
			return {
				id: "colors",
				primary_colors: [],
				secondary_colors: [],
				correct_color_contrasts: [],
				incorrect_color_contrasts: [],
				enabled: false,
				descriptions: zeros.colors.descZero(),
				...gc,
			}
		}

		export function guideTypographyZero(gc: Partial<GuideTypography> = {}): GuideTypography {
			return {
				id: "typography",
				enabled: false,
				fonts: [
					zeros.typography.fontZero({ id: "primary" }),
					zeros.typography.fontZero({ id: "secondary" }),
					zeros.typography.fontZero({ id: "alternative_1" }),
					zeros.typography.fontZero({ id: "alternative_2" }),
				],
				items: [],
				descriptions: zeros.typography.descZero(),
				...gc,
			}
		}

		export function guideImageryZero(gi: Partial<GuideImagery> = {}): GuideImagery {
			return {
				id: "imagery",
				enabled: false,
				lifestyle: zeros.imagery.sectionZero(),
				product: zeros.imagery.sectionZero(),
				illustration: zeros.imagery.sectionZero(),
				misuse: [],
				fundamentals: [],
				descriptions: zeros.imagery.descZero(),
				...gi,
			}
		}

		export function guideToneOfVoiceZero(gtv: Partial<GuideToneOfVoice> = {}): GuideToneOfVoice {
			return {
				id: "tone_of_voice",
				enabled: false,
				tone_of_voice: [],
				writing_style: [],
				positive_adjectives: [],
				negative_adjectives: [],
				descriptions: zeros.tone_of_voice.descZero(),
				...gtv,
			}
		}

		export function guideArchetypeZero(ga: Partial<GuideArchetype> = {}): GuideArchetype {
			return {
				id: "archetype",
				enabled: false,
				adjectives: [],
				descriptions: zeros.archetype.descZero(),
				...ga,
			}
		}

		export function guideApplicationZero(gtv: Partial<GuideApplication> = {}): GuideApplication {
			return {
				id: "application",
				enabled: false,
				items: [],
				descriptions: zeros.application.descZero(),
				...gtv,
			}
		}

		export function guideComplianceZero(gc: Partial<GuideCompliance> = {}): GuideCompliance {
			return {
				id: "compliance",
				enabled: false,
				items: [],
				descriptions: zeros.compliance.descZero(),
				...gc,
			}
		}

		export function zero(g: Partial<Guide> = {}): Guide {
			return {
				colors: guideColorsZero(),
				logos: guideLogosZero(),
				typography: guideTypographyZero(),
				imagery: guideImageryZero(),
				tone_of_voice: guideToneOfVoiceZero(),
				archetype: guideArchetypeZero(),
				application: guideApplicationZero(),
				compliance: guideComplianceZero(),
				...g,
			}
		}
	}

	export function zero(bg: Partial<Brandguide> = {}): Brandguide {
		return {
			brand_id: uuid.NIL,
			account_id: uuid.NIL,
			profile_id: uuid.NIL,
			version: "",
			created_at: "",
			updated_at: "",
			guide: guide.zero(),
			pdf_generated: false,
			...bg,
		}
	}
}

export function fillGuide(r: BrandguideFindResponse): Brandguide {
	const guide = zeros.zero({
		...r.brandguide_object,
		guide: {
			...r.brandguide_object?.guide,
			colors: fillColorGuide(r),
			logos: fillLogoGuide(r),
			typography: fillTypographyGuide(r),
			imagery: fillImageryGuide(r),
			tone_of_voice: fillToneOfVoiceGuide(r),
			archetype: fillArchetype(r),
			application: fillApplication(r),
			compliance: fillCompliance(r),
		},
	})
	return guide
}

export function fillColorGuide(r: BrandguideFindResponse): GuideColors {
	const colorsGuide = zeros.guide.guideColorsZero({
		...r.brandguide_object?.guide?.colors,
		primary_colors: (r.brandguide_object?.guide?.colors?.primary_colors || []).map((c) =>
			zeros.colors.colorObjectZero({
				color: zeros.colors.colorZero({ hex_code: c.color?.hex_code, name: c.color?.name }),
				metadata: zeros.colors.metadataZero({
					id: c.metadata?.id,
					favorite: c.metadata?.favorite,
					default: c.metadata?.default,
				}),
			}),
		),
		secondary_colors: (r.brandguide_object?.guide?.colors?.secondary_colors || []).map((c) =>
			zeros.colors.colorObjectZero({
				color: zeros.colors.colorZero({ hex_code: c.color?.hex_code, name: c.color?.name }),
				metadata: zeros.colors.metadataZero({
					id: c.metadata?.id,
					favorite: c.metadata?.favorite,
					default: c.metadata?.default,
				}),
			}),
		),
		correct_color_contrasts: (r.brandguide_object?.guide?.colors?.correct_color_contrasts || []).map((c) =>
			zeros.colors.colorContrastObjectZero({
				background: zeros.colors.colorZero({
					hex_code: c.background?.hex_code,
					name: c.background?.name,
				}),
				text: zeros.colors.colorZero({ hex_code: c.text?.hex_code, name: c.text?.name }),
				metadata: zeros.colors.metadataZero({
					id: c.metadata?.id,
					favorite: c.metadata?.favorite,
					default: c.metadata?.default,
				}),
			}),
		),
		incorrect_color_contrasts: (r.brandguide_object?.guide?.colors?.incorrect_color_contrasts || []).map((c) =>
			zeros.colors.colorContrastObjectZero({
				background: zeros.colors.colorZero({
					hex_code: c.background?.hex_code,
					name: c.background?.name,
				}),
				text: zeros.colors.colorZero({ hex_code: c.text?.hex_code, name: c.text?.name }),
				metadata: zeros.colors.metadataZero({
					id: c.metadata?.id,
					favorite: c.metadata?.favorite,
					default: c.metadata?.default,
				}),
			}),
		),
		descriptions: zeros.colors.descZero(r.brandguide_object?.guide?.colors?.descriptions),
	})
	return colorsGuide
}

export function fillLogoGuide(r: BrandguideFindResponse): GuideLogos {
	const logosGuide = zeros.guide.guideLogosZero({
		...r.brandguide_object?.guide?.logos,
		primary: zeros.logos.logoObjectZero(r.brandguide_object?.guide?.logos?.primary),
		secondary: zeros.logos.logoObjectZero(r.brandguide_object?.guide?.logos?.secondary),
		logomark: zeros.logos.logoObjectZero(r.brandguide_object?.guide?.logos?.logomark),
		wordmark: zeros.logos.logoObjectZero(r.brandguide_object?.guide?.logos?.wordmark),
		alternative: zeros.logos.logoObjectZero(r.brandguide_object?.guide?.logos?.alternative),
		placement: zeros.logos.logoPlacementZero(r.brandguide_object?.guide?.logos?.placement),
		descriptions: zeros.logos.descZero(r.brandguide_object?.guide?.logos?.descriptions),
	})
	return logosGuide
}

export function fillTypographyGuide(r: BrandguideFindResponse): GuideTypography {
	const typographyGuide = zeros.guide.guideTypographyZero({
		...r.brandguide_object?.guide?.typography,
		items: (r.brandguide_object?.guide?.typography?.items || []).map((i) => zeros.typography.itemZero(i)),
		fonts: (r.brandguide_object?.guide?.typography?.fonts || []).map((i) => zeros.typography.fontZero(i)),
		descriptions: zeros.typography.descZero(r.brandguide_object?.guide?.typography?.descriptions),
	})
	return typographyGuide
}

export function fillImageryGuide(r: BrandguideFindResponse): GuideImagery {
	const imageryGuide = zeros.guide.guideImageryZero({
		...r.brandguide_object?.guide?.imagery,
		lifestyle: zeros.imagery.sectionZero(r.brandguide_object?.guide?.imagery?.lifestyle),
		product: zeros.imagery.sectionZero(r.brandguide_object?.guide?.imagery?.product),
		illustration: zeros.imagery.sectionZero(r.brandguide_object?.guide?.imagery?.illustration),
		misuse: (r.brandguide_object?.guide?.imagery?.misuse || []).map((m) => zeros.imagery.objectZero(m)),
		fundamentals: (r.brandguide_object?.guide?.imagery?.fundamentals || []).map((f) =>
			zeros.imagery.fundamentalObjectZero({
				...f,
				items: (f.items || []).map((i) => zeros.imagery.fundamentalItemZero(i)),
			}),
		),
		descriptions: zeros.imagery.descZero(r.brandguide_object?.guide?.imagery?.descriptions),
	})
	return imageryGuide
}

export function fillToneOfVoiceGuide(r: BrandguideFindResponse): GuideToneOfVoice {
	const toneAndVoiceGuide = zeros.guide.guideToneOfVoiceZero({
		...r.brandguide_object?.guide?.tone_of_voice,
		tone_of_voice: (r.brandguide_object?.guide?.tone_of_voice?.tone_of_voice || []).map((m) =>
			zeros.tone_of_voice.objectZero(m),
		),
		writing_style: (r.brandguide_object?.guide?.tone_of_voice?.writing_style || []).map((m) =>
			zeros.tone_of_voice.objectZero(m),
		),
		positive_adjectives: (r.brandguide_object?.guide?.tone_of_voice?.positive_adjectives || []).map((m) =>
			zeros.tone_of_voice.adjectiveZero(m),
		),
		negative_adjectives: (r.brandguide_object?.guide?.tone_of_voice?.negative_adjectives || []).map((m) =>
			zeros.tone_of_voice.adjectiveZero(m),
		),
		descriptions: zeros.tone_of_voice.descZero(r.brandguide_object?.guide?.tone_of_voice?.descriptions),
	})
	return toneAndVoiceGuide
}

export function fillArchetype(r: BrandguideFindResponse): GuideArchetype {
	const archetypeGuide = zeros.guide.guideArchetypeZero({
		...r.brandguide_object?.guide?.archetype,
		adjectives: (r.brandguide_object?.guide?.archetype?.adjectives || []).map((m) => zeros.archetype.adjectiveZero(m)),
		descriptions: zeros.archetype.descZero(r.brandguide_object?.guide?.archetype?.descriptions),
	})
	return archetypeGuide
}

export function fillApplication(r: BrandguideFindResponse): GuideApplication {
	const applicationGuide = zeros.guide.guideApplicationZero({
		...r.brandguide_object?.guide?.application,
		items: (r.brandguide_object?.guide?.application?.items || []).map((m) => zeros.application.objectZero(m)),
		descriptions: zeros.application.descZero(r.brandguide_object?.guide?.application?.descriptions),
	})
	return applicationGuide
}

export function fillCompliance(r: BrandguideFindResponse): GuideCompliance {
	const complianceGuide = zeros.guide.guideComplianceZero({
		...r.brandguide_object?.guide?.compliance,
		items: (r.brandguide_object?.guide?.compliance?.items || []).map((m) => zeros.compliance.objectZero(m)),
		descriptions: zeros.compliance.descZero(r.brandguide_object?.guide?.compliance?.descriptions),
	})
	return complianceGuide
}

export function search(
	bid: string,
	req: BrandguideSearchRequest,
	...options: httpx.option[]
): CancellablePromise<BrandguideSearchResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/brandguide`, req, ...options)
}

export function get(
	bid: string,
	version: string,
	...options: httpx.option[]
): CancellablePromise<BrandguideFindResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/brandguide/${bid}/${version}`, {}, ...options)
}

export function create(
	bid: string,
	req: BrandguideCreateRequest,
	...options: httpx.option[]
): CancellablePromise<BrandguideCreateResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/brands/${bid}/brandguide/${bid}`, req, ...options)
}

export function getGoogleFonts(bid: string, ...options: httpx.option[]): CancellablePromise<GoogleFontsResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/brands/${bid}/brandguide/gfonts`, {}, ...options)
}

export function generate(
	file: File,
	bid: string,
	version: string,
	...options: httpx.option[]
): CancellablePromise<BrandguideFindResponse> {
	const data = new FormData()
	data.append("file", file)
	return httpx.upload<BrandguideFindResponse>(
		`${httpx.urlstorage.host()}/brands/${bid}/brandguide/${bid}/${version}/html2pdf`,
		data,
		...options,
	)
}

export function get_pdf(bid: string, version: string, ...options: httpx.option[]): CancellablePromise<Response> {
	return new CancellablePromise(
		new Promise((resolve) =>
			resolve(httpx._fetch(`${httpx.urlstorage.host()}/brands/${bid}/brandguide/${bid}/${version}/pdf`, ...options)),
		),
		() => {},
	)
}

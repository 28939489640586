import * as profiles from "./profile"
import * as layouts from "layouts"
import * as sessions from "sessions"

const colors = [
	"#0AFDED",
	"#04D0FF",
	"#0064FF",
	"#1E2F94",
	"#121E54",
	"#0AFDEDCC",
	"#04D0FFCC",
	"#0064FFCC",
	"#1E2F94CC",
	"#121E54CC",
]

function getHash(str: string) {
	let hash = 0
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	return hash
}

function getColorByEmail(email: string) {
	const hash = getHash(email)
	const index = Math.abs(hash) % colors.length
	return colors[index]
}

export default function Display(props: { profile: profiles.Profile } & layouts.containers.FlexProps): JSX.Element {
	const { profile, ...rest } = props
	const current = sessions.useProfile()
	const color = getColorByEmail(profile.email)
	const initials =
		profile.id === current.id
			? "You"
			: profile.display
					.split(" ")
					.map((n) => n[0])
					.join("")
					.toUpperCase()
	return (
		<layouts.containers.flex
			borderRadius="50%"
			width="50px"
			height="50px"
			background={color}
			ml="10px"
			title={`${profile.display}\n${profile.email}`}
			{...rest}
		>
			<layouts.containers.flex
				flex="1"
				justifyContent="center"
				alignItems="center"
				fontWeight="800"
				color={layouts.theme.colors.white}
			>
				{initials}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

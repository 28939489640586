import * as layouts from "layouts"
import * as brandguide from "brandguide"
import * as typography from "typography"
import * as colorx from "colorsx"
import * as icons from "icons"

interface props extends layouts.containers.ContainerProps {
	item: brandguide.api.ColorContrastObject
	correct: boolean
}

export default function ColorUsageCard(props: props): JSX.Element {
	const { item, correct, ...rest } = props

	const color = item.text!
	const background = item.background!

	const textBaseProps = {
		letterSpacing: "unset",
		lineHeight: "normal",
		whiteSpace: "unset",
		fontSize: "calc(0.3vw + 0.3vh + .5vmin)",
	}

	function StatusIcon(): JSX.Element {
		const iconProps = {
			fill: colorx.getTextColorFromBG(background.hex_code),
			fillOpacity: "0.9",
			width: "15px",
			height: "15px",
		}
		if (correct) return <icons.brandguide.marks.Accept {...iconProps} />
		return <icons.brandguide.marks.Reject {...iconProps} />
	}

	return (
		<layouts.Flex flexDirection="column">
			<layouts.Flex background={background.hex_code} {...rest}>
				<layouts.containers.absolute right="0" top="0" p="10px">
					<StatusIcon />
				</layouts.containers.absolute>
				<layouts.Flex flex="1" alignItems="center" justifyContent="center" px="10%">
					<typography.h4 color={color.hex_code} {...textBaseProps} fontSize="calc(0.4vw + 0.4vh + .5vmin)">
						{color.name} on {background.name}
					</typography.h4>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex
				p="5px"
				className="actions"
				alignItems="center"
				justifyContent="flex-start"
				background={layouts.theme.colors.white}
				color={layouts.theme.colors.grey.dark50}
			>
				<layouts.Flex>
					<typography.h6 fontWeight="400" {...textBaseProps}>
						{color.hex_code} text with <br /> {background.hex_code} background
					</typography.h6>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

ColorUsageCard.defaultProps = {
	borderRadius: "20px",
	alignItems: "center",
	justifyContent: "center",
	correct: false,
	flexDirection: "column",
	height: "100%",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha20}`,
	position: "relative",
}

import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as authzc from "authzcached"
import * as api from "brandguard/api"
import * as icons from "icons"
import * as media from "media"

interface imageProps {
	event: api.Media
	item: api.PrecisionExplanation
}

export function OverlayByPrecision(props: imageProps): JSX.Element {
	const { event, item } = props
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setloading] = useState(true)
	const [url, setUrl] = useState("")
	const [overlayUrl, setOverlayUrl] = useState("")

	useEffect(() => {
		media.cache
			.current(metaauthz.current.account_id, event.md5, authzc.bearer(metaauthz))
			.then(setUrl)
			.catch((cause) => console.warn(cause))
	}, [])

	useEffect(() => {
		if (!url) return
		const img = new Image()
		img.crossOrigin = "anonymous"
		img.src = url
		img.onload = () => {
			const canvas = document.createElement("canvas")
			const ctx = canvas.getContext("2d")
			if (ctx) {
				canvas.width = img.width
				canvas.height = img.height
				ctx.clearRect(0, 0, canvas.width, canvas.height)

				ctx.strokeStyle = "red"
				ctx.lineWidth = 5

				const width = Number(item.dimensions[2].axes[0] - item.dimensions[0].axes[0])
				const height = Number(item.dimensions[2].axes[1] - item.dimensions[0].axes[1])
				ctx.strokeRect(Number(item.dimensions[0].axes[0]), Number(item.dimensions[0].axes[1]), width, height)
				const dataUrl = canvas.toDataURL("image/png")
				setOverlayUrl(dataUrl)
			}
		}
	}, [url])

	useEffect(() => {
		if (!overlayUrl) return
		setloading(false)
	}, [overlayUrl])

	return (
		<layouts.loading.pending
			loading={loading}
			alignItems="center"
			justifyContent="center"
			icon={<icons.loading.Ring3 />}
		>
			<layouts.images.Base
				width="100%"
				height="auto"
				maxWidth="100%"
				maxHeight="100%"
				m="auto"
				alt="overlay"
				src={overlayUrl}
			/>
		</layouts.loading.pending>
	)
}

export function CroppedImage(props: imageProps): JSX.Element {
	const { event, item } = props
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setloading] = useState(true)
	const [url, setUrl] = useState("")
	const [logoUrl, setLogoUrl] = useState("")

	useEffect(() => {
		media.cache
			.current(metaauthz.current.account_id, event.md5, authzc.bearer(metaauthz))
			.then(setUrl)
			.catch((cause) => console.warn(cause))
	}, [])

	useEffect(() => {
		if (!url) return
		const img = new Image()
		img.crossOrigin = "anonymous"
		img.src = url
		img.onload = () => {
			const canvas = document.createElement("canvas")
			const ctx = canvas.getContext("2d")
			if (ctx) {
				const width = Number(item.dimensions[2].axes[0] - item.dimensions[0].axes[0])
				const height = Number(item.dimensions[2].axes[1] - item.dimensions[0].axes[1])
				canvas.width = width
				canvas.height = height
				ctx.drawImage(
					img,
					Number(item.dimensions[0].axes[0]),
					Number(item.dimensions[0].axes[1]),
					width,
					height,
					0,
					0,
					width,
					height,
				)
				const dataUrl = canvas.toDataURL("image/jpeg")
				setLogoUrl(dataUrl)
			}
		}
	}, [url])

	useEffect(() => {
		if (!logoUrl) return
		setloading(false)
	}, [logoUrl])

	return (
		<layouts.loading.pending
			loading={loading}
			alignItems="center"
			justifyContent="center"
			icon={<icons.loading.Ring3 width="30px" height="30px" />}
		>
			<layouts.images.Base
				width="100%"
				height="auto"
				maxWidth="100%"
				maxHeight="100%"
				m="auto"
				alt="logo"
				src={logoUrl}
			/>
		</layouts.loading.pending>
	)
}

import { Route, Routes, Navigate } from "react-router-dom"
import * as layouts from "layouts"
import * as usermanagement from "meta/usermanagement"
import * as oauth2 from "./oauth2"
import { Container, Links } from "."

export default function Routing(): JSX.Element {
	return (
		<layouts.containers.flex height="100vh" overflowY="hidden" flex="1">
			<layouts.containers.flex className="body-container" flex="1 1">
				<Routes>
					<Route
						path="/"
						element={
							<Container>
								<layouts.containers.flex flexDirection="column" height="100%" overflow="hidden">
									<Links activeLink="/u12t" />
									<usermanagement.Display />
								</layouts.containers.flex>
							</Container>
						}
					/>
					<Route
						path="/account"
						element={
							<Container>
								<layouts.containers.flex flexDirection="column" height="100%" overflow="hidden">
									<Links activeLink="/u12t/account" />
									<usermanagement.AccountDisplay flex="1" />
								</layouts.containers.flex>
							</Container>
						}
					/>
					<Route
						path="/oauth2/*"
						element={
							<Container>
								<layouts.containers.flex flexDirection="column" height="100%" overflow="hidden">
									<Links activeLink="/u12t/oauth2" />
									<oauth2.Routes />
								</layouts.containers.flex>
							</Container>
						}
					/>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

import React, { useContext } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as accounts from "accounts"
import * as profiles from "profiles/profile"
import * as api from "./api"

export const Context = React.createContext(zero())
export const Provider = Context.Provider

export interface Replacer {
	(s: Session): void
}

export default interface Session {
	id: string
	unauthenticated: boolean
	credentials: string
	profile: profiles.Profile
	account: accounts.Account
	recording: boolean
	replace: Replacer
}

type Option = (s: Session) => Session

export const options = {
	clone(partial: Partial<Session>): Option {
		return (s: Session): Session => {
			return {
				...s,
				...partial,
			}
		}
	},
	latestlogincreds: (s: Session): Session => {
		s.credentials = api.lasttoken()
		return s
	},
	login: (s: Session): Session => {
		api.storelasttoken(s.credentials)
		api.storelocaltoken(s.account.id, s.credentials)
		return s
	},
	logout: (s: Session): Session => {
		api.storelasttoken("")
		return zero(options.replacer(s.replace))
	},
	replacer(r: Replacer): Option {
		return (s: Session): Session => {
			return {
				...s,
				replace: r,
			}
		}
	},
}

export function zero(...options: Option[]): Session {
	return options.reduce<Session>((s, opt) => opt(s), {
		id: uuid.v4(),
		credentials: "",
		unauthenticated: true,
		recording: false,
		profile: profiles.missing,
		account: accounts.missing,
		replace(s: Session) {
			// noop default.
		},
	})
}

export function useSession(): Session {
	return useContext(Context)
}

export function useProfile(): profiles.Profile {
	return useSession().profile
}

export function useAccount(): accounts.Account {
	return useSession().account
}

export function useToken(): httpx.option {
	const account_id = useAccount().id
	return httpx.options.bearer(api.localtoken(account_id))
}

export function useGetToken(): string {
	const account_id = useAccount().id
	return `BEARER ${api.localtoken(account_id)}`
}

export function bearertoken(account_id: string): httpx.option {
	return httpx.options.bearer(api.localtoken(account_id))
}

export function autoreplace(previous: Session, ...opts: Option[]): (current: api.Authn) => api.Authn {
	return (current: api.Authn): api.Authn => {
		const updated = opts.reduce((s, opt) => opt(s), {
			...previous,
			unauthenticated: false,
			credentials: api.storelocaltoken(current!.account!.id, current.token),
			profile: current.profile || previous.profile,
			account: current.account || previous.account,
		})
		previous.replace(updated)
		return current
	}
}

/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable,generate_dependencies
// @generated from protobuf file "brandguard.notifications.proto" (package "brandguardnotifications", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Prediction } from "./brandguard.approval";
import { Event } from "./meta.notification";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguardnotifications.Notification
 */
export interface Notification {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string initiator_id = 4 [json_name = "initiator_id"];
     */
    initiator_id: string;
    /**
     * @generated from protobuf field: string profile_id = 5 [json_name = "profile_id"];
     */
    profile_id: string;
    /**
     * @generated from protobuf field: string created_at = 6 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string viewed_at = 7 [json_name = "viewed_at"];
     */
    viewed_at: string;
    /**
     * @generated from protobuf field: string tombstoned_at = 8 [json_name = "tombstoned_at"];
     */
    tombstoned_at: string;
    /**
     * @generated from protobuf field: meta.notification.Event event = 9;
     */
    event: Event;
    /**
     * @generated from protobuf field: string event_id = 10 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: brandguard.approval.Prediction event_prediction = 11 [json_name = "event_prediction"];
     */
    event_prediction: Prediction;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguardnotifications.CreateRequest
 */
export interface CreateRequest {
    /**
     * @generated from protobuf field: brandguardnotifications.Notification notification = 1;
     */
    notification?: Notification;
}
/**
 * @generated from protobuf message brandguardnotifications.CreateResponse
 */
export interface CreateResponse {
    /**
     * @generated from protobuf field: brandguardnotifications.Notification notification = 1;
     */
    notification?: Notification;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguardnotifications.UpdateRequest
 */
export interface UpdateRequest {
    /**
     * @generated from protobuf field: brandguardnotifications.Notification notification = 1;
     */
    notification?: Notification;
}
/**
 * @generated from protobuf message brandguardnotifications.UpdateResponse
 */
export interface UpdateResponse {
    /**
     * @generated from protobuf field: brandguardnotifications.Notification notification = 1;
     */
    notification?: Notification;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguardnotifications.SearchRequest
 */
export interface SearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: int64 limit = 2;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguardnotifications.SearchResponse
 */
export interface SearchResponse {
    /**
     * @generated from protobuf field: repeated brandguardnotifications.Notification items = 1;
     */
    items: Notification[];
    /**
     * @generated from protobuf field: brandguardnotifications.SearchRequest next = 2;
     */
    next?: SearchRequest;
}
// @generated message type with reflection information, may provide speed optimized methods
class Notification$Type extends MessageType<Notification> {
    constructor() {
        super("brandguardnotifications.Notification", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "initiator_id", kind: "scalar", localName: "initiator_id", jsonName: "initiator_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "profile_id", kind: "scalar", localName: "profile_id", jsonName: "profile_id", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "viewed_at", kind: "scalar", localName: "viewed_at", jsonName: "viewed_at", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "tombstoned_at", kind: "scalar", localName: "tombstoned_at", jsonName: "tombstoned_at", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "event", kind: "enum", T: () => ["meta.notification.Event", Event] },
            { no: 10, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "event_prediction", kind: "enum", localName: "event_prediction", jsonName: "event_prediction", T: () => ["brandguard.approval.Prediction", Prediction] }
        ]);
    }
    create(value?: PartialMessage<Notification>): Notification {
        const message = { id: "", account_id: "", brand_id: "", initiator_id: "", profile_id: "", created_at: "", viewed_at: "", tombstoned_at: "", event: 0, event_id: "", event_prediction: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Notification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Notification): Notification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string initiator_id = 4 [json_name = "initiator_id"];*/ 4:
                    message.initiator_id = reader.string();
                    break;
                case /* string profile_id = 5 [json_name = "profile_id"];*/ 5:
                    message.profile_id = reader.string();
                    break;
                case /* string created_at = 6 [json_name = "created_at"];*/ 6:
                    message.created_at = reader.string();
                    break;
                case /* string viewed_at = 7 [json_name = "viewed_at"];*/ 7:
                    message.viewed_at = reader.string();
                    break;
                case /* string tombstoned_at = 8 [json_name = "tombstoned_at"];*/ 8:
                    message.tombstoned_at = reader.string();
                    break;
                case /* meta.notification.Event event */ 9:
                    message.event = reader.int32();
                    break;
                case /* string event_id = 10 [json_name = "event_id"];*/ 10:
                    message.event_id = reader.string();
                    break;
                case /* brandguard.approval.Prediction event_prediction = 11 [json_name = "event_prediction"];*/ 11:
                    message.event_prediction = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Notification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string initiator_id = 4 [json_name = "initiator_id"]; */
        if (message.initiator_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.initiator_id);
        /* string profile_id = 5 [json_name = "profile_id"]; */
        if (message.profile_id !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.profile_id);
        /* string created_at = 6 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.created_at);
        /* string viewed_at = 7 [json_name = "viewed_at"]; */
        if (message.viewed_at !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.viewed_at);
        /* string tombstoned_at = 8 [json_name = "tombstoned_at"]; */
        if (message.tombstoned_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.tombstoned_at);
        /* meta.notification.Event event = 9; */
        if (message.event !== 0)
            writer.tag(9, WireType.Varint).int32(message.event);
        /* string event_id = 10 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.event_id);
        /* brandguard.approval.Prediction event_prediction = 11 [json_name = "event_prediction"]; */
        if (message.event_prediction !== 0)
            writer.tag(11, WireType.Varint).int32(message.event_prediction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguardnotifications.Notification
 */
export const Notification = new Notification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
    constructor() {
        super("brandguardnotifications.CreateRequest", [
            { no: 1, name: "notification", kind: "message", T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<CreateRequest>): CreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRequest): CreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguardnotifications.Notification notification */ 1:
                    message.notification = Notification.internalBinaryRead(reader, reader.uint32(), options, message.notification);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguardnotifications.Notification notification = 1; */
        if (message.notification)
            Notification.internalBinaryWrite(message.notification, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguardnotifications.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateResponse$Type extends MessageType<CreateResponse> {
    constructor() {
        super("brandguardnotifications.CreateResponse", [
            { no: 1, name: "notification", kind: "message", T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<CreateResponse>): CreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateResponse): CreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguardnotifications.Notification notification */ 1:
                    message.notification = Notification.internalBinaryRead(reader, reader.uint32(), options, message.notification);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguardnotifications.Notification notification = 1; */
        if (message.notification)
            Notification.internalBinaryWrite(message.notification, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguardnotifications.CreateResponse
 */
export const CreateResponse = new CreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
    constructor() {
        super("brandguardnotifications.UpdateRequest", [
            { no: 1, name: "notification", kind: "message", T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRequest): UpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguardnotifications.Notification notification */ 1:
                    message.notification = Notification.internalBinaryRead(reader, reader.uint32(), options, message.notification);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguardnotifications.Notification notification = 1; */
        if (message.notification)
            Notification.internalBinaryWrite(message.notification, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguardnotifications.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateResponse$Type extends MessageType<UpdateResponse> {
    constructor() {
        super("brandguardnotifications.UpdateResponse", [
            { no: 1, name: "notification", kind: "message", T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<UpdateResponse>): UpdateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateResponse): UpdateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguardnotifications.Notification notification */ 1:
                    message.notification = Notification.internalBinaryRead(reader, reader.uint32(), options, message.notification);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguardnotifications.Notification notification = 1; */
        if (message.notification)
            Notification.internalBinaryWrite(message.notification, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguardnotifications.UpdateResponse
 */
export const UpdateResponse = new UpdateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRequest$Type extends MessageType<SearchRequest> {
    constructor() {
        super("brandguardnotifications.SearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRequest>): SearchRequest {
        const message = { offset: 0n, limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRequest): SearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* int64 limit */ 2:
                    message.limit = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* int64 limit = 2; */
        if (message.limit !== 0n)
            writer.tag(2, WireType.Varint).int64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguardnotifications.SearchRequest
 */
export const SearchRequest = new SearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchResponse$Type extends MessageType<SearchResponse> {
    constructor() {
        super("brandguardnotifications.SearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Notification },
            { no: 2, name: "next", kind: "message", T: () => SearchRequest }
        ]);
    }
    create(value?: PartialMessage<SearchResponse>): SearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchResponse): SearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguardnotifications.Notification items */ 1:
                    message.items.push(Notification.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguardnotifications.SearchRequest next */ 2:
                    message.next = SearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguardnotifications.Notification items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Notification.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguardnotifications.SearchRequest next = 2; */
        if (message.next)
            SearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguardnotifications.SearchResponse
 */
export const SearchResponse = new SearchResponse$Type();

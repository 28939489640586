import React, { useState } from "react"
import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as icons from "icons"
import { colors } from "layouts/theme"
import * as typography from "typography"
import { css } from "@emotion/css"
import * as inputs from "inputs"
import * as uuid from "uuid"

type pages = "Compliance"

export const steps = ["Compliance"] as pages[]

export const styledCarousel = css`
	.content {
		gap: 10px;
	}
`

const styledInput = css`
	font-size: 12px;
	border-radius: 5px;
	line-height: 1.5;
	padding: 5px 10px;
	color: ${colors.grey.dark50alpha80};
	background: ${colors.white};
	height: 28px;
	border: none;
	:focus {
		outline: none;
	}
	::placeholder {
		font-size: 12px;
		color: ${colors.grey.dark50alpha50};
	}
`

export const truncatedText = css`
	overflow: hidden;
	text-overflow: ellipsis;
`

function validate(obj: brandguide.api.ComplianceName): boolean {
	return inputs.assertions.failed(
		inputs.assertions.strings.min(1)(obj.name),
		inputs.assertions.strings.url()(obj.url),
		inputs.assertions.strings.max(150)(obj.name),
		inputs.assertions.strings.max(300)(obj.url),
	)
}

interface itemProps {
	item: brandguide.api.ComplianceItem
	onChange(item: brandguide.api.ComplianceItem): void
}

export function Item(props: itemProps): JSX.Element {
	const { item, onChange, ...rest } = props

	const [focused, setFocused] = useState(false)

	const CloseIcon = () => (
		<icons.Close width="12px" height="12px" fill={colors.grey.dark50alpha30} stroke={colors.grey.dark50alpha30} />
	)

	const Items = item.names.map((n) => (
		<layouts.Flex key={n.id} flexDirection="column" my="5px">
			<layouts.Flex alignItems="center" justifyContent="space-between">
				<typography.h4 fontSize="12px" color={colors.grey.dark50alpha50} lineHeight="unset" className={truncatedText}>
					{n.name}
				</typography.h4>
				<layouts.Flex onClick={() => onChange({ ...item, names: item.names.filter((i) => i.id !== n.id) })}>
					<CloseIcon />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.links.external
				href={n.url}
				target="_blank"
				color={colors.blue.blue}
				style={{ textDecoration: "underline", fontSize: "12px", whiteSpace: "nowrap" }}
				className={truncatedText}
			>
				{n.url}
			</layouts.links.external>
		</layouts.Flex>
	))

	const Close = () => (
		<layouts.containers.absolute right={0}>
			<layouts.Flex p="10px" onClick={() => setFocused(false)}>
				<CloseIcon />
			</layouts.Flex>
		</layouts.containers.absolute>
	)

	const Info = () => (
		<layouts.Flex overflowY="auto" m="10px">
			<typography.h4 fontSize="14px" color={colors.grey.dark50alpha50} whiteSpace="unset">
				{item.info}
			</typography.h4>
		</layouts.Flex>
	)

	const Names = () => {
		const [editMode, setEditMode] = useState(false)
		const [complName, setComplName] = useState(brandguide.api.zeros.compliance.nameZero({ id: uuid.v4() }))

		return (
			<React.Fragment>
				<layouts.Flex
					alignItems="center"
					justifyContent="center"
					borderBottom={`1px solid ${colors.grey.dark50alpha30}`}
					mx="20px"
				>
					<layouts.Flex onClick={() => setFocused(true)}>
						<typography.h4 fontSize="12px" color={colors.blue.blue} m="10px" lineHeight="14px">
							See more info &#8594;
						</typography.h4>
					</layouts.Flex>
				</layouts.Flex>
				<layouts.Flex flexDirection="column-reverse" mx="20px" my="8px" overflowY="auto">
					{editMode ? (
						<layouts.Flex flexDirection="column" my="10px">
							<input
								type="text"
								placeholder="Add name"
								value={complName.name}
								onChange={(evt) => setComplName({ ...complName, name: evt.target.value })}
								maxLength={150}
								className={styledInput}
							/>
							<input
								type="text"
								placeholder="Add URL documentation"
								value={complName.url}
								onChange={(evt) => setComplName({ ...complName, url: evt.target.value })}
								maxLength={300}
								style={{ marginTop: "5px" }}
								className={styledInput}
							/>
							<layouts.Flex m="5px" justifyContent="flex-end">
								<layouts.Flex
									onClick={() => {
										if (validate(complName)) return
										onChange({ ...item, names: [...[complName], ...item.names] })
										setEditMode(false)
									}}
								>
									<icons.brandguide.actions.Accept
										width="20px"
										height="20px"
										fill={
											validate(complName) ? layouts.theme.colors.grey.dark50alpha30 : layouts.theme.colors.blue.blue
										}
										fillOpacity="1"
									/>
								</layouts.Flex>
								<layouts.Flex onClick={() => setEditMode(false)}>
									<icons.brandguide.actions.Reject
										width="20px"
										height="20px"
										fill={layouts.theme.colors.blue.blue}
										fillOpacity="1"
									/>
								</layouts.Flex>
							</layouts.Flex>
						</layouts.Flex>
					) : (
						<React.Fragment>
							<layouts.Flex
								my="10px"
								fontSize="12px"
								color={colors.grey.dark50alpha50}
								alignItems="center"
								gridGap="10px"
								onClick={() => setEditMode(true)}
							>
								<b style={{ fontSize: "16px", color: colors.grey.dark50alpha80 }}>+</b> Add new
							</layouts.Flex>
							{Items}
						</React.Fragment>
					)}
				</layouts.Flex>
			</React.Fragment>
		)
	}

	return (
		<layouts.Flex {...rest}>
			{focused && <Close />}
			<layouts.Flex alignItems="center" justifyContent="center" my="16px">
				<typography.h4 fontSize="16px" color={colors.grey.dark50alpha80}>
					{item.title}
				</typography.h4>
			</layouts.Flex>
			{focused ? <Info /> : <Names />}
		</layouts.Flex>
	)
}

Item.defaultProps = {
	width: "224px",
	height: "242px",
	flex: 1,
	flexDirection: "column",
	position: "relative",
	background: "#EBECED",
}

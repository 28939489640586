import { Archetype, ArchetypeAdjectiveObject } from "brandguide/brandguide_pb"
import * as icons from "icons"

export const adjectives = [
	{ archetype: Archetype.Outlaw, items: ["Disruptive", "Rebellious", "Independent", "Rule Breaker", "Gritty"] },
	{ archetype: Archetype.Magician, items: ["Powerful", "Mystical", "Informative", "Reassuring", "Transformative"] },
	{ archetype: Archetype.Hero, items: ["Mastery", "Honest", "Candid", "Brave", "Courageous"] },
	{ archetype: Archetype.Lover, items: ["Sensual", "Empathetic", "Soothing", "Affectionate", "Indulgent"] },
	{ archetype: Archetype.Jester, items: ["Fun Loving", "Playful", "Optimistic", "Happy", "Funny"] },
	{ archetype: Archetype.Everyman, items: ["Friendly", "Humble", "Authentic", "Welcoming", "Unbiased"] },
	{ archetype: Archetype.Caregiver, items: ["Caring", "Helpful", "Reassuring", "Supportive", "Selfless"] },
	{ archetype: Archetype.Ruler, items: ["Powerful", "Commanding", "Refined", "Articulate", "Authoritative"] },
	{ archetype: Archetype.Creator, items: ["Innovative", "Inspirational", "Daring", "Imaginative", "Visionary"] },
	{ archetype: Archetype.Innocent, items: ["Safe", "Honest", "Humble", "Wholesome", "Simplistic"] },
	{ archetype: Archetype.Sage, items: ["Truthful", "Knowledgeable", "Assured", "Wise", "Intelligent"] },
	{ archetype: Archetype.Explorer, items: ["Fearless", "Adventurous", "Challenging", "Exciting", "Liberated"] },
] as ArchetypeAdjectiveObject[]

export type breakdown = {
	archetype: Archetype
	icon: JSX.Element
	title: string
	promo: string
	description: string
	communication: string
	motivates: string[]
	scares: string[]
}

export const breakdowns = [
	{
		archetype: Archetype.Everyman,
		title: "The Everyman",
		icon: <icons.brandguide.archetype.Everyman />,
		promo: "Tend to blend into society as 'everybody' and don't like to stand out in the crowd.",
		description:
			"The Everyman seeks to belong, often blending into society without seeking attention. Friendly and approachable, they value trust but fear rejection. They strive to fit in, enjoying a variety of interests without intense passion. While liked, they may be easily overlooked.",
		communication:
			"Brands focusing on everyday life resonate with this archetype, conveying acceptance of normalcy. Avoid elitism; embrace honest, humble, and inclusive communication to connect effectively.",
		motivates: ["Connection", "Togetherness", "Equality", "Fellowship", "Inclusion"],
		scares: ["Exclusion", "Standing Out", "Isolation", "Hostility", "Disassociation"],
	},
	{
		archetype: Archetype.Caregiver,
		title: "The Caregiver",
		icon: <icons.brandguide.archetype.Caregiver />,
		promo: "Embodies selflessness, driven by a desire to protect and nurture others.",
		description:
			"The Caregiver embodies selflessness, driven by a desire to protect and nurture others, often associated with maternal figures. They prefer recognition over patronization, being both reactive and proactive in preventing harm.",
		communication:
			"Caregiver brands strive to instill a sense of security and care in their customers. Often requiring external support to provide care, they appeal to wider audiences with educational and conscience-evoking messages.",
		motivates: ["Support", "Help", "Service", "Recognition", "Gratitude"],
		scares: ["Anguish", "Helplessness", "Ingratitude", "Neglect", "Blame"],
	},
	{
		archetype: Archetype.Outlaw,
		title: "The Outlaw",
		icon: <icons.brandguide.archetype.Outlaw />,
		promo: "Disdainful of rules and conformity, their core goodness battles anger, their fuel.",
		description:
			"The Outlaw seeks revolution, driven by a mix of noble intent and a thirst for anarchy. Disdainful of rules and conformity, their core goodness battles anger, their fuel. Fighting is their lifeline.",
		communication:
			"To win over an outlaw, align with their worldview. Reject the status quo and conformity. Embrace revolution to gain their loyalty. Speak informally, with grit and attitude, to connect deeply and swiftly.",
		motivates: ["Liberation", "Change", "Righteousness", "Revenge", "Independence"],
		scares: ["Servitude", "Conformity", "Complacency", "Acceptance", "Dependence"],
	},
	{
		archetype: Archetype.Magician,
		title: "The Magician",
		icon: <icons.brandguide.archetype.Magician />,
		promo: "Challenge reality's limits, driven by thirst for knowledge, shaping visions silently.",
		description:
			"Magicians create dreams with mystical flair, guiding transformative journeys through magical moments. They challenge reality's limits, driven by thirst for knowledge, shaping visions silently. They believe imagination is boundless, leading to a brighter future.",
		communication:
			"The Magician archetype, though uncommon in buyer personas, attracts diverse individuals with its transformative power. Brands offering transformative experiences—like moving from lost to found—may find resonance by embodying the Magician archetype.",
		motivates: ["Transformation", "Knowledge", "Vision", "Belief", "Discovery"],
		scares: ["Consequences", "Stagnation", "Ignorance", "Doubt", "Uncertainty"],
	},
	{
		archetype: Archetype.Hero,
		title: "The Hero",
		icon: <icons.brandguide.archetype.Hero />,
		promo: "Meeting challenges head-on, they bear failures until rectified.",
		description:
			"The Hero's drive is to showcase valor and resolve. They tirelessly hone skills, distinguishing themselves with diligence. Meeting challenges head-on, they bear failures until rectified. Their aim: self-validation and recognition as saviors.",
		communication:
			"To engage a hero, inspire and empower them for success. They champion justice, standing against bullies. Acknowledge their ambitions and encourage challenges for connection. Heroes seek inspiration and gratification in achieving their aspirations.",
		motivates: ["Mastery", "Courageousness", "Growth", "Development", "Defense"],
		scares: ["Incompetence", "Cowardice", "Deterioration", "Downfall", "Incapability"],
	},
	{
		archetype: Archetype.Jester,
		title: "The Jester",
		icon: <icons.brandguide.archetype.Jester />,
		promo: "Optimistic and resilient, they find silver linings in every situation.",
		description:
			"The Jester lives for fun, spreading joy to all. Optimistic and resilient, they find silver linings in every situation. Forever young at heart, they refuse to let seriousness dim their playful spirit.",
		communication:
			"The Jester archetype is all about living in the moment, spreading joy and laughter. They blend into society but seek to brighten everyone's life. Resilient optimists, they find humor in every situation.",
		motivates: ["Fun", "Happiness", "Laughter", "Togetherness", "Positivity"],
		scares: ["Boredom", "Gloom", "Sadness", "Loneliness", "Negativity"],
	},
	{
		archetype: Archetype.Ruler,
		title: "The Ruler",
		icon: <icons.brandguide.archetype.Ruler />,
		promo: "Intimidating yet confident, they aim for prosperity, expecting loyalty in return.",
		description:
			"The Ruler seeks absolute control, projecting dominance and authority. Intimidating yet confident, they aim for prosperity, expecting loyalty in return. They defend their position at the top, expecting others to follow suit.",
		communication:
			"To appeal to a Ruler, reinforce their power, control, and sense of superiority. They crave exclusivity and acknowledgment of their elite status. Mass appeal won't suffice; they seek affirmation of their top-tier status.",
		motivates: ["Power", "Prosperity", "Status", "Success", "Wealth"],
		scares: ["Weakness", "Insignificance", "Failure", "Poverty", "Destitution"],
	},
	{
		archetype: Archetype.Creator,
		title: "The Creator",
		icon: <icons.brandguide.archetype.Creator />,
		promo: "Express themselves through unique talents, striving to manifest their visions.",
		description:
			"Creators aspire to craft novel and enduring creations. They express themselves through unique talents, striving to manifest their visions. Believing in the power of imagination, they're often hindered by their pursuit of perfection.",
		communication:
			"Appeal to creators by celebrating the creative process and inspiring self-expression. Brands offering tools for free expression and fostering innovation resonate well with the Creator Archetype, inspiring customers to unleash their creativity.",
		motivates: ["Creation", "Originality", "Self-Expression", "Vision", "Imagination"],
		scares: ["Stagnation", "Duplication", "Familiarity", "Disillusion", "Indifference"],
	},
	{
		archetype: Archetype.Innocent,
		title: "The Innocent",
		icon: <icons.brandguide.archetype.Innocent />,
		promo: "Embodies positivity and optimism, prioritizing happiness and safety for all.",
		description:
			"The Innocent embodies positivity and optimism, prioritizing happiness and safety for all. Honest and pure, they harbor no ill-will, seeing beauty in everyone and believing in the innate right to authenticity and inner beauty.",
		communication:
			"To attract an innocent, use sincere, positive communication to build trust. Avoid negativity and guilt, focusing on safety and recognizing inner beauty. Associating your brand with positivity and authenticity fosters a strong connection.",
		motivates: ["Happiness", "Morality", "Simplicity", "Honesty", "Positivity"],
		scares: ["Anguish", "Depravity", "Complexity", "Deceit", "Negativity"],
	},
	{
		archetype: Archetype.Sage,
		title: "The Sage",
		icon: <icons.brandguide.archetype.Sage />,
		promo: "Seeks truth and wisdom, driven to understand the world and share insights.",
		description:
			"The Sage seeks truth and wisdom, driven to understand the world and share insights. Lifelong learners, they enjoy philosophical discussions and prioritize passing wisdom to those who can use it to make a difference.",
		communication:
			"To appeal to a Sage, honor their intellect with sophisticated communication. They appreciate higher-level vocabulary and factual, well-researched information. Messages should be layered and avoid oversimplification to gain their respect.",
		motivates: ["Wisdom", "Intelligence", "Expertise", "Information", "Influence"],
		scares: ["Ignorance", "Insanity", "Powerlessness", "Misinformation", "Inaccuracy"],
	},
	{
		archetype: Archetype.Explorer,
		title: "The Explorer",
		icon: <icons.brandguide.archetype.Explorer />,
		promo: "Driven by a quest for self-understanding rather than external validation.",
		description:
			"Explorers thrive on pushing beyond comfort zones into rugged environments, driven by a quest for self-understanding rather than external validation. Brave, adventurous, they embark on an eternal journey of discovery.",
		communication:
			"Appealing to explorers means challenging them, urging them to break free from modern constraints. Emphasize the allure of the outdoors and the unknown, inviting them to explore with your brand. Rebellion against societal norms resonates deeply.",
		motivates: ["Adventure", "Exploration", "THe Unknown", "Self-Discovery", "Liberation"],
		scares: ["Confinement", "Immobility", "Entrapment", "Incarceration", "Cautiousness"],
	},
	{
		archetype: Archetype.Lover,
		title: "The Lover",
		icon: <icons.brandguide.archetype.Lover />,
		promo: "Craves desire, seeking intimacy and sensuality.",
		description:
			"The Lover craves desire, seeking intimacy and sensuality. They strive to enhance allure for connection. Fearful of being unnoticed or unloved, passion may cloud judgment, yet even fulfilled desires bring fear of loss.",
		communication:
			"To captivate a Lover, evoke their allure and ignite their yearning for connection. Sensual language and imagery resonate deeply. Brands catering to Lovers can leverage sensory pleasures and embrace the allure of red.",
		motivates: ["Sensuality", "Closeness", "Indulgence", "Affection Love"],
		scares: ["Rejection", "Loneliness", "Isolation", "Invisibility", "Contempt"],
	},
] as breakdown[]

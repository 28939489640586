import React, { useState, useEffect } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import { CancellablePromise } from "real-cancellable-promise"
import classnames from "classnames"
import * as sessions from "sessions"
import * as luxon from "luxon"
import * as errors from "errors"
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex"
import * as layouts from "layouts"
import * as icons from "icons"
import * as brands from "brands"
import * as brandgpt from "brandgpt"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as styleguide from "styleguide"
import * as styleguideApi from "styleguide/api"
import * as cache from "media/cache"
import { PageNum, Viewer } from "./pdf"
import "react-reflex/styles.css"

function groupByName(items: styleguide.api.Styleguide[]) {
	const grouped: styleguide.api.Styleguide[] = []
	const names = new Map()

	items.forEach((obj) => {
		if (!names.has(obj.description)) {
			names.set(obj.description, { childs: [obj] })
		} else {
			names.get(obj.description).childs.push(obj)
		}
	})

	names.forEach(({ childs }) => {
		grouped.push(...childs)
	})

	return grouped
}

export default function Display(): JSX.Element {
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current
	const brand = brands.caching.useCached()
	const [loading, setLoading] = useState(false)
	const [loadingPdf, setLoadingPdf] = useState(true)
	const [url, SetUrl] = useState("")
	const [fileMd5, setFileMd5] = useState("")
	const [page, setPage] = useState<PageNum>({ num: 1, scroll: false })
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [showUpload, setShowUpload] = useState(true)
	const bearertoken = sessions.useToken()

	const [focusedSG, setFocusedSG] = useState(undefined as styleguide.api.Styleguide | undefined)

	const [sreq] = useState({
		brand_id: brand.id,
		offset: 0n,
		limit: 20n,
	} as styleguide.api.StyleguideSearchRequest)

	const [styleguides, setStyleguides] = useState({
		next: sreq,
		items: [],
	} as styleguide.api.StyleguideSearchResponse)
	const styleguides_list = groupByName(styleguides.items)

	function handleUpload(files: File[]): void {
		files.forEach((f) => {
			setShowUpload(false)
			setFocusedSG(undefined)
			setCause(undefined)
			const retry = httpx.autoretry()
			retry
				.wrap(() => styleguide.api.upload(brand.id, f, authzc.bearer(authzaccount)))
				.then((sg) => {
					setStyleguides((prevState) => ({
						...prevState,
						items: [sg.styleguide!, ...prevState.items],
					}))
					setFocusedSG(sg.styleguide!)
				})
				.catch(
					httpx.errors.entitytoolarge((cause) => {
						setCause(
							<errors.Inline>
								<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
									file size too large
								</errors.Textual>
							</errors.Inline>,
						)
						setShowUpload(true)
						setFocusedSG(undefined)
					}),
				)
				.catch((cause) => console.error("unable to upload bgpt styleguide", cause))
		})
	}

	function selectStyleguidePage(p: number): void {
		setPage({ num: p, scroll: false })
	}

	function checkState(): CancellablePromise<void> {
		const retry = httpx.autoretry()
		return retry
			.wrap(() => styleguideApi.find(brand.id, focusedSG!.id, bearertoken))
			.then((res) => {
				setFocusedSG(res!.styleguide!)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => console.error("unable to retry focusing bgpt styleguide", cause))
	}

	function styleGuideOptionName(i: number, s: styleguide.api.Styleguide): string {
		const fileNameMaxLen = 42
		let count = 0
		if (styleguides_list.length - 1 > i) {
			count = styleguides_list.slice(i + 1).reduce((c, next) => {
				if (next.description === s.description) {
					c++
				}
				return c
			}, 0)
			if (count > 0) {
				count++
			}
		}
		if (count === 0 && i > 0) {
			count = styleguides_list.slice(0, i).reduce((c, before) => {
				if (before.description === s.description) {
					c++
				}
				return c
			}, 0)
			if (count > 0) {
				count = 1
			}
		}

		if (count > 0) {
			return `v${count} | ${luxon.DateTime.fromISO(s.created_at).toFormat("LLL dd, yyyy")} | ${s.description.substring(
				0,
				fileNameMaxLen,
			)}${s.description.length > fileNameMaxLen ? "..." : ""}`
		}

		return `${luxon.DateTime.fromISO(s.created_at).toFormat("LLL dd, yyyy")} | ${s.description.substring(
			0,
			fileNameMaxLen,
		)}${s.description.length > fileNameMaxLen ? "..." : ""}`
	}

	useEffect(() => {
		if (sreq.brand_id === uuid.NIL) return
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => styleguide.api.search(brand.id, sreq, bearertoken))
			.then((sgs) => {
				setStyleguides(sgs)
				if (sgs.items.length > 0) setFocusedSG(sgs.items[0])
				setLoading(false)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="2px" m="5px" borderRadius="5px">
						<errors.Textual onClick={() => setCause(undefined)}>
							<layouts.containers.span color={layouts.theme.colors.white} fontSize="12px" fontWeight="600">
								unable to retrieve Styleguides
							</layouts.containers.span>
						</errors.Textual>
					</errors.Inline>,
				)
				setLoading(false)
			})
		return pending.cancel
	}, [sreq])

	useEffect(() => {
		if (!focusedSG) return
		if (focusedSG.state === styleguideApi.State.EMBEDDINGS_CREATED) {
			setLoadingPdf(true)
			const retry = httpx.autoretry()
			retry
				.wrap(() => cache.current(focusedSG.account_id, focusedSG.md5, bearertoken))
				.then((url) => {
					SetUrl(url)
					setFileMd5(focusedSG.md5)
					setPage({ num: 1, scroll: false })
					setLoadingPdf(false)
				})
			return
		}
		const timer = setTimeout(() => checkState(), 10000)
		return () => {
			clearTimeout(timer)
		}
	}, [focusedSG])

	return (
		<layouts.loading.pending loading={loading}>
			{focusedSG && (
				<layouts.containers.flex flexDirection="row" justifyContent="flex-end" p="15px">
					<layouts.containers.flex>
						<brandgpt.layouts.Select
							value={focusedSG.id}
							onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
								setFocusedSG(styleguides_list.find((i) => i.id === evt.currentTarget.value))
							}}
						>
							{styleguides_list.map((o, i) => (
								<option key={o.id} value={o.id}>
									{styleGuideOptionName(i, o)}
								</option>
							))}
						</brandgpt.layouts.Select>
					</layouts.containers.flex>
				</layouts.containers.flex>
			)}

			<layouts.containers.flex
				className="brandgpt-working-area"
				flex="1"
				overflow="hidden"
				flexDirection="row"
				p="15px"
				height="100vh"
			>
				<ReflexContainer orientation="vertical">
					<ReflexElement flex={2}>
						<layouts.containers.flex
							flex="1"
							overflowX="auto"
							overflowY="hidden"
							flexDirection="row-reverse"
							flexWrap="wrap"
							height="100%"
							className={classnames(brandgpt.layouts.styledscroll)}
						>
							<brandgpt.Channels
								styleguide={focusedSG}
								DropWell={<brandgpt.UploadSimple onUpload={(files) => handleUpload(files)} />}
								selectStyleguidePage={selectStyleguidePage}
							/>
						</layouts.containers.flex>
					</ReflexElement>
					<ReflexSplitter className={classnames(brandgpt.layouts.styleguideresize)}>
						<icons.brandgpt.arrowleftright />
					</ReflexSplitter>
					<ReflexElement flex={1}>
						<layouts.containers.flex
							className="right-sidebar"
							flexDirection="column"
							flex="1"
							background={layouts.theme.colors.white}
							height="100%"
						>
							{cause}
							{focusedSG ? (
								<layouts.overlays.Container flex="1">
									<layouts.overlays.Screen enabled={focusedSG.state === styleguideApi.State.EMBEDDINGS_CREATED}>
										<layouts.loading.pending loading={loadingPdf} flex="1">
											<Viewer fileUrl={url} fileMd5={fileMd5} page={page} onChangePage={setPage} />
										</layouts.loading.pending>
									</layouts.overlays.Screen>
									<layouts.overlays.Screen enabled={focusedSG.state !== styleguideApi.State.EMBEDDINGS_CREATED}>
										<brandgpt.UploadProcessing msg="processing..." />
									</layouts.overlays.Screen>
								</layouts.overlays.Container>
							) : (
								<authz.Protected enabled={permission.brandguard_train} rejected={<brandgpt.UploadForbidden />}>
									<layouts.overlays.Container flex="1">
										<layouts.overlays.Screen enabled={showUpload}>
											<brandgpt.Upload onUpload={(files) => handleUpload(files)} />
										</layouts.overlays.Screen>
										<layouts.overlays.Screen enabled={!showUpload}>
											<brandgpt.UploadProcessing msg="uploading..." />
										</layouts.overlays.Screen>
									</layouts.overlays.Container>
								</authz.Protected>
							)}
						</layouts.containers.flex>
					</ReflexElement>
				</ReflexContainer>
			</layouts.containers.flex>
		</layouts.loading.pending>
	)
}

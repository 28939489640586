import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as brands from "brands"
import { getAlphabetArray, fontFamily } from "."

const max_columns_cnt = 4

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const brand = brands.caching.useCached()
	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = guide.guide?.typography!

	if (!result_components.validations.typography.has_alternative_fonts(data)) return <></>

	const fonts = data.fonts || []
	const alternatives = fonts.filter((f) => f.id !== "primary" && f.id !== "secondary")

	const rows = Math.ceil(alternatives.length / max_columns_cnt)
	const columns = alternatives.length >= max_columns_cnt ? max_columns_cnt : alternatives.length

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Typography</result_components.NavTitle>
				<result_components.NavDescription>
					There are {result_components.pluralize(fonts.length, "font")} used for {brand.description} marketing
					materials.
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.uploads} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer>
					<layouts.Flex flexDirection="column" height="100%" flex="1">
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
								Alternative fonts
							</typography.h4>
						</layouts.Flex>
						<layouts.containers.grid
							gap="50px"
							gridTemplateRows={`repeat(${rows}, 1fr)`}
							gridTemplateColumns={`repeat(${columns}, 1fr)`}
							flex="1"
						>
							{alternatives.map((f, index) => (
								<layouts.Flex key={index} flexDirection="column">
									<layouts.Flex>
										<typography.h4
											color={layouts.theme.colors.grey.dark50alpha80}
											fontFamily={fontFamily(f)}
											lineHeight="60px"
											fontSize="24px"
										>
											{f?.font_family || "Error"}
										</typography.h4>
									</layouts.Flex>
									<layouts.Flex flex="1" flexWrap="wrap">
										{getAlphabetArray().map((i) => (
											<layouts.Flex key={i}>
												<typography.h1
													fontSize="8vh"
													fontWeight="400"
													lineHeight="9vh"
													letterSpacing="0.5vh"
													color={layouts.theme.colors.grey.dark50alpha80}
													fontFamily={fontFamily(f)}
												>
													{i}
												</typography.h1>
											</layouts.Flex>
										))}
									</layouts.Flex>
									<layouts.Flex flexWrap="wrap">
										{Array.from({ length: 10 }, (_, i) => i).map((i) => (
											<layouts.Flex key={i}>
												<typography.h1
													fontSize="8vh"
													fontWeight="400"
													lineHeight="9vh"
													letterSpacing="0.5vh"
													color={layouts.theme.colors.grey.dark50alpha80}
													fontFamily={fontFamily(f)}
												>
													{i}
												</typography.h1>
											</layouts.Flex>
										))}
									</layouts.Flex>
								</layouts.Flex>
							))}
						</layouts.containers.grid>
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable,generate_dependencies
// @generated from protobuf file "brandguard.approval.proto" (package "brandguard.approval", syntax proto3)
// tslint:disable
// @ts-nocheck
/**
 * @generated from protobuf enum brandguard.approval.Prediction
 */
export enum Prediction {
    /**
     * @generated from protobuf enum value: NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: REJECTED = 1;
     */
    REJECTED = 1,
    /**
     * @generated from protobuf enum value: APPROVED = 2;
     */
    APPROVED = 2,
    /**
     * @generated from protobuf enum value: INREVIEW = 3;
     */
    INREVIEW = 3,
    /**
     * @generated from protobuf enum value: APPROVED_BY_SYSTEM = 4;
     */
    APPROVED_BY_SYSTEM = 4,
    /**
     * @generated from protobuf enum value: APPROVED_BY_REVIEW = 5;
     */
    APPROVED_BY_REVIEW = 5,
    /**
     * @generated from protobuf enum value: REJECTED_BY_REVIEW = 6;
     */
    REJECTED_BY_REVIEW = 6,
    /**
     * @generated from protobuf enum value: DISCARDED_BY_UPLOADER = 7;
     */
    DISCARDED_BY_UPLOADER = 7,
    /**
     * @generated from protobuf enum value: ERROR = 999;
     */
    ERROR = 999
}

import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import * as uuid from "uuid"
import { generateCodeVerifier } from "@badgateway/oauth2-client"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as httpx from "httpx"
import * as api from "./api"
import * as icons from "icons"
import * as typography from "typography"
import * as timex from "timex"

export function Display(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex className="header" px="50px" alignItems="center" justifyContent="flex-start" height="60px">
				<icons.MainLogo width="202px" />
			</layouts.Flex>
			<layouts.Flex className="main-container" justifyContent="center" alignItems="center" flex="1">
				<layouts.Flex flex="1" />
				<layouts.Flex flex="2">
					<layouts.Flex flex="3" flexDirection="column" alignItems="flex-start" justifyContent="center" pr="10px">
						<typography.h1
							color={layouts.theme.colors.grey.dark50}
							fontSize="30px"
							lineHeight="44px"
							fontWeight="700"
							letterSpacing="0.4px"
						>
							Great, you&apos;re logged in!
						</typography.h1>
						<typography.h5
							pt="10px"
							color={layouts.theme.colors.grey.dark50alpha80}
							fontSize="16px"
							fontWeight="500"
							lineHeight="23px"
							letterSpacing="normal"
						>
							Your BrandGuard plugin is ready for use.
							<br />
							Your designs are waiting to be scored.
						</typography.h5>
						{children}
					</layouts.Flex>
					<layouts.Flex
						px="10px"
						flexDirection="column"
						flex="1"
						background={layouts.theme.extension.colors.blue.background}
						height="600px"
						minWidth="336px"
						maxWidth="336px"
						borderRadius="7px"
					>
						<layouts.Flex pt="80px" justifyContent="center">
							<icons.MainLogo width="202px" fill={layouts.theme.colors.white} />
						</layouts.Flex>
						<layouts.Flex pt="120px" justifyContent="center">
							<icons.Check />
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
				<layouts.Flex flex="1" />
			</layouts.Flex>
			<layouts.Flex className="footer" px="50px" alignItems="center" justifyContent="flex-start" height="60px">
				<typography.h4 fontSize="12px" color={layouts.theme.colors.grey.dark50alpha80}>
					{`Copyright © ${timex.local().year} BrandGuard. All rights reserved.`}
				</typography.h4>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface props {
	codeuri: string
	tabbed: boolean
	stategen(entroy: string): Promise<string>
	onLogin: (uri: string) => void
}

const LoginButton = styled(layouts.buttons.primary_box)`
	font-size: 13px;
	border-radius: 9px;
	padding: 10px 20px 10px 20px;
	margin-left: auto;
	margin-right: auto;
	width: 183px;
	height: 38px;
	cursor: pointer;
	background-color: ${layouts.theme.colors.blue.blue};
	color: ${layouts.theme.colors.white};
	&.disabled {
		pointer-events: none;
		background-color: ${layouts.theme.colors.blue.blue}55;
		color: ${layouts.theme.colors.white}55;
		cursor: not-allowed;
	}
`

export default function PKCE(props: props & layouts.containers.ContainerProps): JSX.Element {
	const { onLogin, codeuri, stategen, ...rest } = props
	const oauth2 = api.oauth2.nsuiteclient()
	const [uri, setURI] = useState("")

	function genauthorizeuri() {
		generateCodeVerifier()
			.then((codeVerifier) => {
				const codeVerifierSHA256 = api.oauth2.challenge(codeVerifier)
				return stategen(codeVerifierSHA256).then((state) => {
					return sessions.cache.pkce.state
						.set({
							state: state,
							codeVerifier: codeVerifier,
						})
						.then((cached) => {
							const params = {
								// URL in the app that the user should get redirected to after authenticating
								redirectUri: codeuri,
								state: cached.state,
								codeVerifier: cached.codeVerifier,
								scope: ["login"],
							}

							return oauth2.authorizationCode.getAuthorizeUri(params)
						})
						.then(setURI)
				})
			})
			.catch((c) => {
				console.error("failed to generate authorize url", c)
				setTimeout(genauthorizeuri, 3000)
			})
	}

	useEffect(genauthorizeuri, [])
	return (
		<LoginButton className={uri === "" ? "disabled" : ""} onClick={() => onLogin(uri)} {...rest}>
			Log in
		</LoginButton>
	)
}

PKCE.defaultProps = {
	stategen: (entropy: string) => new Promise((resolve) => resolve(uuid.v4())),
	codeuri: `${document.location.origin}/authorize.html`,
	tabbed: false,
}

interface authorizeprops {
	codeuri: string
	onDone(): void
}

export function Authorize(props: React.PropsWithChildren<authorizeprops & layouts.containers.FlexProps>): JSX.Element {
	const { onDone, codeuri, ...rest } = props
	const [loading, setLoading] = useState(true)
	const session = sessions.useSession()
	const oauth2 = api.oauth2.nsuiteclient()

	useEffect(() => {
		// retrieve values in local storage.
		sessions.cache.pkce.state.get().then((d) => {
			oauth2.authorizationCode
				.getTokenFromCodeRedirect(document.location.href, {
					redirectUri: codeuri,
					state: d.state,
					codeVerifier: d.codeVerifier,
				})
				.then((token) => {
					return sessions.api
						.login(httpx.options.bearer(token.accessToken))
						.then(sessions.autoreplace(session, sessions.options.login))
						.then((current) => {
							setLoading(false)
						})
				})
				.catch((cause) => {
					// TODO: update rendering to inform user of the problem.
					setLoading(false)
					console.error(console.error("unable to retrieve values in local storage", cause))
					setTimeout(() => window.location.reload(), 3000)
				})
		})
	}, [])

	return (
		<layouts.Flex {...rest}>
			<layouts.loading.pending loading={loading}>
				<layouts.buttons.primary width="180px" height="38px" color={layouts.theme.colors.blue.bright} onClick={onDone}>
					Done
				</layouts.buttons.primary>
			</layouts.loading.pending>
		</layouts.Flex>
	)
}

Authorize.defaultProps = {
	codeuri: `${document.location.origin}/authorize.html`,
	onDone: window.close,
}

import * as httpx from "httpx"
import { CancellablePromise } from "real-cancellable-promise"
import * as uuid from "uuid"
import * as timex from "timex"

import {
	Channel,
	ChannelCreateRequest,
	ChannelCreateResponse,
	ChannelUpdateRequest,
	ChannelUpdateResponse,
	ChannelDeleteRequest,
	ChannelDeleteResponse,
	ChannelsSearchRequest,
	ChannelsSearchResponse,
} from "./brandgpt.channel_pb"

import { Message, MessagesSearchRequest, MessagesSearchResponse } from "./brandgpt.message_pb"
import { SearchRequest, SearchResponse } from "./brandgpt.metrics_pb"
import { Direction } from "./brandgpt_pb"

export { Direction } from "./brandgpt_pb"
export {
	Channel,
	ChannelCreateRequest,
	ChannelCreateResponse,
	ChannelUpdateRequest,
	ChannelUpdateResponse,
	ChannelDeleteRequest,
	ChannelDeleteResponse,
	ChannelsSearchRequest,
	ChannelsSearchResponse,
} from "./brandgpt.channel_pb"

export {
	Message,
	MessageCreateRequest,
	MessageCreateResponse,
	MessagesSearchRequest,
	MessagesSearchResponse,
} from "./brandgpt.message_pb"

export function channelZero(c: Partial<Channel> = {}): Channel {
	const ts = timex.local()
	return {
		id: uuid.NIL,
		brand_id: uuid.NIL,
		account_id: uuid.NIL,
		styleguide_id: uuid.NIL,
		description: "",
		created_at: ts.toISO(),
		updated_at: ts.toISO(),
		tombstoned_at: timex.infinity().toISO(),
		...c,
	}
}

export function messageZero(m: Partial<Message> = {}): Message {
	return {
		id: uuid.NIL,
		channel_id: uuid.NIL,
		account_id: uuid.NIL,
		profile_id: uuid.NIL,
		direction: Direction.USER,
		body: "",
		created_at: timex.local().toISO(),
		page_number: [0],
		...m,
	}
}

export namespace channels {
	export function ws(bid: string, chan_id: string, token: string): WebSocket {
		return new WebSocket(`${httpx.urlstorage.wshost()}/brands/${bid}/brandgpt/channel/${chan_id}/ws?token=${token}`)
	}

	export function search(
		bid: string,
		req: ChannelsSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<ChannelsSearchResponse> {
		return httpx
			.get<ChannelsSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/brandgpt/channels`, req, ...options)
			.then((resp) => ({
				...resp,
				items: (resp.items || []).map((item) => channelZero(item)),
			}))
	}
	export function create(
		bid: string,
		req: ChannelCreateRequest,
		...options: httpx.option[]
	): CancellablePromise<ChannelCreateResponse> {
		return httpx.post(`${httpx.urlstorage.host()}/brands/${bid}/brandgpt/channels`, req, ...options)
	}

	export function update(
		bid: string,
		req: ChannelUpdateRequest,
		...options: httpx.option[]
	): CancellablePromise<ChannelUpdateResponse> {
		return httpx.put(`${httpx.urlstorage.host()}/brands/${bid}/brandgpt/channels/${req.channel!.id}`, req, ...options)
	}

	export function destroy(
		bid: string,
		req: ChannelDeleteRequest,
		...options: httpx.option[]
	): CancellablePromise<ChannelDeleteResponse> {
		return httpx.destroy(
			`${httpx.urlstorage.host()}/brands/${bid}/brandgpt/channels/${req.channel!.id}`,
			{},
			...options,
		)
	}
}

export namespace messages {
	export function search(
		bid: string,
		req: MessagesSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<MessagesSearchResponse> {
		return httpx
			.get<MessagesSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/brandgpt/messages`, req, ...options)
			.then((resp) => ({
				...resp,
				items: (resp.items || []).map((item) => messageZero(item)),
			}))
	}
}

export namespace metrics {
	export function search(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<SearchResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/brands/${req.brand_id}/brandgpt/metrics`, req, ...options)
	}
}

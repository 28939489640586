export function load<T>(src: string, loaded: () => Promise<T>) {
	return new Promise<T>((resolve, reject) => {
		try {
			let el = document.createElement("script")
			el.type = "text/javascript"
			el.async = true
			el.src = src
			el.onload = () => {
				loaded().then(resolve).catch(reject)
			}
			el.onerror = reject
			document.head.appendChild(el)
		} catch (e) {
			console.error("failed to load dynamic script", src)
			reject(e)
		}
	})
}

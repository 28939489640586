import * as httpx from "httpx"
import * as uuid from "uuid"
import { kernels } from "brandguard/api"
import { CancellablePromise } from "real-cancellable-promise"
import {
	ImageKernelSettingsCreateRequest,
	ImageKernelSettingsCreateResponse,
	ImageKernelSettingsResponse,
	ImageKernelSearchRequest,
	ImageKernelSearchResponse,
} from "brandguard/brandguard.image_pb"

import { ImageKernelSetting, ImageKernelLogo, ImageClearspace } from "brandguard/brandguard.kernel_pb"

export namespace config {
	export function zero(d: Partial<ImageKernelSetting> = {}): ImageKernelSetting {
		return {
			id: "",
			threshold: 100,
			brand_voice: {
				enabled: true,
			},
			sexually_explicit: {
				enabled: true,
			},
			image_quality_general: {
				enabled: false,
			},
			image_violence: {
				enabled: false,
			},
			image_logo_detection: {
				enabled: false,
				logos: [],
			},
			image_font_detection: {
				enabled: false,
				fonts: [],
			},
			image_background_colors: {
				enabled: false,
				colors: [],
			},
			image_obscure_rules: {
				enabled: false,
				rules: [],
			},
			...d,
		}
	}

	export function latest(
		bid: string,
		id: string,
		...options: httpx.option[]
	): CancellablePromise<ImageKernelSettingsResponse> {
		return httpx.get(
			`${httpx.urlstorage.host()}/brands/${bid}/bg/image/kernel/${id}/settings/${uuid.NIL}`,
			{},
			...options,
		)
	}
}

export namespace searches {
	export function request(d: Partial<ImageKernelSearchRequest> = {}): ImageKernelSearchRequest {
		return {
			offset: 0n,
			brand_id: "",
			limit: 100n,
			...d,
		}
	}

	export function response(d: Partial<ImageKernelSearchResponse> = {}): ImageKernelSearchResponse {
		return {
			next: request(),
			items: [],
			...d,
		}
	}
}

export function search(
	bid: string,
	req: ImageKernelSearchRequest,
	...options: httpx.option[]
): CancellablePromise<ImageKernelSearchResponse> {
	return httpx
		.get<ImageKernelSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/kernel/`, req, ...options)
		.then((r) => ({
			...r,
			items: (r.items || []).map(kernels.metadata.zero),
		}))
}

export function create_version(
	bid: string,
	id: string,
	req: ImageKernelSettingsCreateRequest,
	...options: httpx.option[]
): CancellablePromise<ImageKernelSettingsCreateResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/kernel/${id}/settings`, req, ...options)
}
export namespace logos {
	export function zero(d: Partial<ImageKernelLogo> = {}): ImageKernelLogo {
		return {
			mimetype: "",
			md5: "",
			description: "",
			image: "",
			clearspace: { horizontal: 0, vertical: 0 },
			...d,
		}
	}
	export namespace clearspace {
		export function zero(d: Partial<ImageClearspace> = {}): ImageClearspace {
			return {
				horizontal: 0,
				vertical: 0,
				...d,
			}
		}
	}
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as uuid from "uuid"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideColors | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideColorsZero(_guide.guide?.colors))

	useEffect(() => {
		if (!((data.correct_color_contrasts || []).filter((c) => c.metadata?.default).length === 0)) return
		const primary = data.primary_colors
		const secondary = data.secondary_colors
		setData({
			...data,
			correct_color_contrasts: brandguide.build.generators.initial.colors.correct(primary, secondary),
		})
	}, [])

	const items = data.correct_color_contrasts || ([] as brandguide.api.ColorContrastObject[])

	const nonDefaultItems = items.filter((el) => !el.metadata?.default)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Correct Color Usage" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please remove the color combinations you do not approve or add your own.">
							<layouts.overlays.Container flexDirection="column" overflow="auto" height="30vh" mt="25px">
								<layouts.containers.grid
									gap="15px"
									gridTemplateColumns="repeat(3, minmax(250px, 1fr))"
									gridTemplateRows="repeat(auto-fit, minmax(120px, 1fr))"
									style={{ alignContent: "start", alignItems: "start" }}
									minHeight="0px"
								>
									{items.map((item) => (
										<colors.layouts.ColorWithTextItem
											key={item.metadata?.id}
											item={item}
											onDelete={(id) => {
												setData({ ...data, correct_color_contrasts: items.filter((o) => o.metadata?.id !== id) })
											}}
											onChange={(u) => {
												setData({
													...data,
													correct_color_contrasts: items.map((a) => (u.metadata?.id === a.metadata?.id ? u : a)),
												})
											}}
											correct
										/>
									))}
									{nonDefaultItems.length === 0 && (
										<colors.layouts.AddColorWithTextItem
											onChange={() => {
												setData({
													...data,
													correct_color_contrasts: [
														...items,
														...[
															{
																background: brandguide.api.zeros.colors.colorZero({
																	hex_code: layouts.theme.colors.white,
																	name: "white",
																}),
																text: brandguide.api.zeros.colors.colorZero({
																	hex_code: layouts.theme.colors.black.default,
																	name: "black",
																}),
																metadata: brandguide.api.zeros.colors.metadataZero({ id: uuid.v4() }),
															},
														],
													],
												})
											}}
										/>
									)}
								</layouts.containers.grid>
							</layouts.overlays.Container>
							<colors.Explanation
								val={data.descriptions?.correct_color_contrasts || ""}
								placeholder="Add further explanation of color usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.colors.descZero({
											...data.descriptions,
											correct_color_contrasts: text,
										}),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(colors.Steps.SECONDARY, undefined)}
								onNext={() => onChange(colors.Steps.INCORRECT, data)}
								disabled={!brandguide.validations.colors.valid.correct(data)}
								my="10px"
							/>
							<colors.Progress activeStep={colors.Steps.CORRECT} steps={colors.layouts.steps} flex="1" />
						</brandguide.build.layouts.SectionStepContainer>
						<colors.Guidelines activPage="colors" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import React, { useEffect, useState } from "react"
import * as b64 from "js-base64"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import * as md5x from "md5x"
import * as api from "brandguard/api"
import * as typography from "typography"
import { css } from "@emotion/css"
import * as uuid from "uuid"

interface props extends layouts.containers.FlexProps {
	settings: api.ImageKernelLogosEnabled
	onChange(upd: api.ImageKernelLogosEnabled): void
}

const acceptedLogos = {
	"image/png": [".png"],
	"image/jpeg": [".jpeg", ".jpg"],
}

export default function LogoDetection(props: React.PropsWithChildren<props>): JSX.Element {
	const { settings, onChange, ...rest } = props

	return (
		<layouts.Flex flexDirection="column" flex="1" {...rest}>
			<inputs.Dropwell
				multiple
				accept={acceptedLogos}
				onDrop={(accepted, rejected) => {
					if (accepted.length === 0) return
					const pending = Array.from(accepted).map((f) => {
						return f.arrayBuffer().then((b) => {
							const barray = new Uint8Array(b)
							return api.images.kernel.logos.zero({
								mimetype: f.type,
								description: f.name,
								md5: md5x.string(barray),
								image: b64.fromUint8Array(barray),
								clearspace: api.images.kernel.logos.clearspace.zero(),
							})
						})
					})

					Promise.all(pending).then((upd) => {
						const union = [
							...upd.filter((l) => (settings.logos || []).find((o) => o.md5 === l.md5) === undefined),
							...(settings.logos || []),
						]
						onChange({ ...settings, logos: union })
					})
				}}
			>
				<layouts.dnd.Container>
					<icons.FileUpload />
					<layouts.dnd.Textual>Drag & drop your logos here or click to browse files</layouts.dnd.Textual>
				</layouts.dnd.Container>
			</inputs.Dropwell>
			<layouts.Flex flexDirection="column" py="15px">
				{(settings.logos || []).map((l) => {
					return (
						<LogoSettings
							key={l.md5}
							py="5px"
							logo={api.images.kernel.logos.zero({
								...l,
								clearspace: api.images.kernel.logos.clearspace.zero(l.clearspace),
							})}
							onDelete={() => onChange({ ...settings, logos: settings.logos.filter((o) => o.md5 !== l.md5) })}
							onChange={(upd) =>
								onChange({ ...settings, logos: settings.logos.map((el) => (el.md5 === upd.md5 ? upd : el)) })
							}
						/>
					)
				})}
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface settingsProps extends layouts.containers.FlexProps {
	logo: api.ImageKernelLogo
	onDelete(): void
	onChange(logo: api.ImageKernelLogo): void
}

export function LogoSettings(props: React.PropsWithChildren<settingsProps>): JSX.Element {
	const { logo, onDelete, onChange, ...rest } = props
	const [focused, setFocused] = useState(false)

	const _clearspace = { horizontal: logo.clearspace!.horizontal * 100, vertical: logo.clearspace!.vertical * 100 }
	const [clearspace, setClearspace] = useState(_clearspace)

	const [horKey, setHorKey] = useState(uuid.v4())
	const [vertKey, setVerKey] = useState(uuid.v4())

	const toggle = layouts.modals.useToggle()

	const validate = (): boolean => {
		switch (true) {
			case clearspace.horizontal > 100:
				setClearspace({ ...clearspace, horizontal: 100 })
				setHorKey(uuid.v4())
				return false
			case clearspace.horizontal < 0:
				setClearspace({ ...clearspace, horizontal: 0 })
				setHorKey(uuid.v4())
				return false
			case clearspace.vertical > 100:
				setClearspace({ ...clearspace, vertical: 100 })
				setVerKey(uuid.v4())
				return false
			case clearspace.vertical < 0:
				setClearspace({ ...clearspace, vertical: 0 })
				setVerKey(uuid.v4())
				return false

			default:
				return true
		}
	}

	useEffect(() => {
		validate() &&
			onChange({
				...logo,
				clearspace: {
					horizontal: clearspace?.horizontal / 100,
					vertical: clearspace?.vertical / 100,
				},
			})
	}, [clearspace])

	return (
		<layouts.Flex flexDirection="column" borderBottom={layouts.theme.borders.grey.dark50alphamedium1px} {...rest}>
			<layouts.containers.box tabIndex={0} className="accordion-containers-box">
				<layouts.containers.flex flexDirection="row">
					<layouts.containers.flex p="10px" flexDirection="column" width="min-content">
						<typography.h6 fontSize="14px" lineHeight="20px" fontWeight="400" color={layouts.theme.colors.grey.dark50}>
							{logo.description}
						</typography.h6>
					</layouts.containers.flex>

					<layouts.containers.flex flex="1" p="10px" justifyContent="flex-start" alignItems="center">
						<layouts.Flex onClick={() => setFocused(!focused)}>
							{focused ? <icons.accordions.Opened /> : <icons.accordions.Closed />}
						</layouts.Flex>
					</layouts.containers.flex>
				</layouts.containers.flex>
				{focused && (
					<layouts.Flex m="20px">
						<layouts.Flex flexDirection="column" px="20px">
							<typography.h5 fontWeight="400" color={layouts.theme.colors.grey.dark50}>
								Horizontal Clearspace %
							</typography.h5>
							<layouts.Flex mt="10px">
								<inputs.Numeric
									key={horKey}
									className={styledInput}
									defaultValue={clearspace.horizontal}
									onChange={(evt) => setClearspace({ ...clearspace, horizontal: Number(evt.target.value) })}
								/>
								<layouts.Flex
									width="20px"
									justifyContent="center"
									alignItems="center"
									background={layouts.theme.backgrounds.bluealpha5}
									onClick={() => {
										if (clearspace?.horizontal === 0) return
										setClearspace({ ...clearspace, horizontal: clearspace.horizontal - 1 })
										setHorKey(uuid.v4())
									}}
								>
									<typography.h3
										fontWeight="400"
										lineHeight="unset"
										color={
											clearspace.horizontal === 0
												? layouts.theme.colors.grey.dark50alpha20
												: layouts.theme.colors.black.dark
										}
									>
										-
									</typography.h3>
								</layouts.Flex>
								<layouts.Flex
									width="20px"
									justifyContent="center"
									alignItems="center"
									background={layouts.theme.backgrounds.bluealpha5}
									onClick={() => {
										if (clearspace.horizontal === 100) return
										setClearspace({ ...clearspace, horizontal: clearspace.horizontal + 1 })
										setHorKey(uuid.v4())
									}}
								>
									<typography.h3
										fontWeight="400"
										lineHeight="unset"
										color={
											clearspace.horizontal === 100
												? layouts.theme.colors.grey.dark50alpha20
												: layouts.theme.colors.black.dark
										}
									>
										+
									</typography.h3>
								</layouts.Flex>
							</layouts.Flex>
						</layouts.Flex>
						<layouts.Flex flexDirection="column" px="20px">
							<typography.h5 fontWeight="400" color={layouts.theme.colors.grey.dark50}>
								Vertical Clearspace %
							</typography.h5>
							<layouts.Flex mt="10px">
								<inputs.Numeric
									key={vertKey}
									className={styledInput}
									defaultValue={clearspace.vertical}
									onChange={(evt) => setClearspace({ ...clearspace, vertical: Number(evt.target.value) })}
								/>
								<layouts.Flex
									width="20px"
									justifyContent="center"
									alignItems="center"
									background={layouts.theme.backgrounds.bluealpha5}
									onClick={() => {
										if (clearspace.vertical === 0) return
										setClearspace({ ...clearspace, vertical: clearspace.vertical - 1 })
										setVerKey(uuid.v4())
									}}
								>
									<typography.h3
										fontWeight="400"
										lineHeight="unset"
										color={
											clearspace.vertical === 0
												? layouts.theme.colors.grey.dark50alpha20
												: layouts.theme.colors.black.dark
										}
									>
										-
									</typography.h3>
								</layouts.Flex>
								<layouts.Flex
									width="20px"
									justifyContent="center"
									alignItems="center"
									background={layouts.theme.backgrounds.bluealpha5}
									onClick={() => {
										if (clearspace.vertical === 100) return
										setClearspace({ ...clearspace, vertical: clearspace.vertical + 1 })
										setVerKey(uuid.v4())
									}}
								>
									<typography.h3
										fontWeight="400"
										lineHeight="unset"
										color={
											clearspace.vertical === 100
												? layouts.theme.colors.grey.dark50alpha20
												: layouts.theme.colors.black.dark
										}
									>
										+
									</typography.h3>
								</layouts.Flex>
							</layouts.Flex>
						</layouts.Flex>
						<layouts.Flex alignItems="center" mt="10px" ml="auto" onClick={onDelete}>
							<icons.Trash width="14px" height="14px" />
						</layouts.Flex>
						<layouts.Flex
							alignItems="center"
							mt="10px"
							ml="auto"
							onClick={() =>
								toggle(
									<layouts.Flex
										flex="1"
										alignItems="center"
										justifyContent="center"
										background={layouts.theme.colors.grey.bg}
										position="relative"
									>
										<layouts.containers.absolute right="0" top="0">
											<layouts.Flex onClick={() => toggle(undefined)} p="10px">
												<icons.Close
													width="25px"
													height="25px"
													fill={layouts.theme.colors.grey.dark50}
													fillOpacity="0.6"
												/>
											</layouts.Flex>
										</layouts.containers.absolute>
										<layouts.Flex border="1px solid grey">
											<img
												src={`data:image/png;base64,${logo.image}`}
												alt={logo.description}
												style={{ maxHeight: "600px", maxWidth: "600px" }}
											/>
										</layouts.Flex>
									</layouts.Flex>,
								)
							}
						>
							<icons.Eye width="14px" height="14px" />
						</layouts.Flex>
					</layouts.Flex>
				)}
			</layouts.containers.box>
		</layouts.Flex>
	)
}

const styledInput = css`
	input {
		height: 30px;
		background: ${layouts.theme.backgrounds.bluealpha5};
		border-radius: 5px 0 0 5px;
		padding-left: 10px;
		padding-right: 10px;
		&:hover,
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
`

import React, { useState } from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "brandguard/api"
import * as dropwells from "./dropwell"
import * as bglayouts from "brandguard/components/layouts"
import * as bgscores from "brandguard/display/scores"

const TextInsert = (
	props: { onChange(item: File[]): void; foreground: string } & layouts.containers.FlexProps,
): JSX.Element => {
	const { onChange, foreground, ...rest } = props
	const [text, setText] = useState("")

	const assertions = [inputs.assertions.strings.min(1)(text), inputs.assertions.strings.max(1012)(text)]
	const disabled = inputs.assertions.failed(...assertions)
	return (
		<layouts.Flex
			className="text-manual-input"
			flex="1"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			{...rest}
		>
			<bglayouts.uploads.TextArea
				placeholder="Enter text manually here"
				value={text}
				onChange={(evt) => {
					setText(evt.target.value)
				}}
			></bglayouts.uploads.TextArea>
			<layouts.Flex className="review-button-container" justifyContent="flex-end" width="100%" py="5px">
				<bglayouts.uploads.TabPrimaryButton
					backgroundColor={foreground}
					disabled={disabled}
					onClick={() => {
						const file = new File([new Blob([text])], "manually", { type: "text/plain" })
						onChange([file])
						setText("")
					}}
				>
					Review
				</bglayouts.uploads.TabPrimaryButton>
			</layouts.Flex>
		</layouts.Flex>
	)
}

TextInsert.defaultProps = {
	foreground: layouts.theme.colors.blue.blue,
}

export default function Upload(
	props: React.PropsWithChildren<
		{
			background: string
			observation: api.Prediction
		} & inputs.uploads.api.Props
	>,
): JSX.Element {
	const { children, background, observation, onChange } = props

	return (
		<>
			<dropwells.default
				className={classnames(
					"text",
					"uploads",
					bglayouts.uploads.navdropwellsimple,
					observation === api.Prediction.REJECTED
						? `${bglayouts.uploads.navdropwellred} ${bglayouts.uploads.navareared}`
						: "",
				)}
				onChange={(files) => onChange(files, background)}
				mb="20px"
			/>
			{children}
			<layouts.containers.flex
				color={layouts.theme.colors.grey.dark3}
				justifyContent="center"
				fontSize="12px"
				fontWeight="700"
				lineHeight="17px"
				mb="20px"
			>
				Or
			</layouts.containers.flex>
			<TextInsert
				onChange={(files) => onChange(files, background)}
				className={classnames(
					bgscores.layouts.zerotextinsert,
					observation === api.Prediction.REJECTED ? bglayouts.uploads.navareared : "",
				)}
			/>
		</>
	)
}

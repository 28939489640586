import React, { useState, useEffect } from "react"
import * as sessions from "sessions"
import * as api from "./api"
import * as cache from "./cache"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as errors from "errors"

interface textProps {
	media: api.Media
}

export function Text(props: textProps & layouts.containers.ContainerProps): JSX.Element {
	const { media, ...rest } = props
	const [loading, setLoading] = useState(false)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [response, setResponse] = useState("")
	const account = sessions.useAccount()
	const bearertoken = sessions.useToken()

	useEffect(() => {
		setLoading(true)
		cache
			.current(account.id, media.content_digest, bearertoken)
			.then((url) => httpx._fetch(url))
			.then((resp) => resp.text())
			.then((data) => setResponse(data))
			.catch(() =>
				setCause(
					<errors.Inline flex="1" display="flex">
						<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
							unable to retrieve asset
						</errors.Textual>
					</errors.Inline>,
				),
			)
			.finally(() => setLoading(false))
	}, [media.content_digest])

	return (
		<layouts.loading.screen loading={loading} {...rest}>
			{cause ? cause : response}
		</layouts.loading.screen>
	)
}

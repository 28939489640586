import * as httpx from "httpx"
import * as text from "."
import { TextEvent, TextSearchResponseItem } from "brandguard/brandguard.text_pb"
import { Prediction } from "brandguard/brandguard.approval_pb"

export const agree = async (
	item: TextSearchResponseItem & { event: TextEvent },
	...options: httpx.option[]
): Promise<TextSearchResponseItem> => {
	if (item.event.approved === Prediction.APPROVED) {
		return text.uploads.review(item.event.id, item.event.brand_id, ...options)
	}

	return text.uploads.publish(item.event.id, item.event.brand_id, ...options)
}

export const disagree = (
	item: TextSearchResponseItem & { event: TextEvent },
	...options: httpx.option[]
): Promise<TextSearchResponseItem> => {
	return text.uploads.review(item.event.id, item.event.brand_id, ...options)
}

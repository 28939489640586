import * as httpx from "httpx"
import * as proto from "./meta.account"
import { CancellablePromise } from "real-cancellable-promise"
import * as as from "./meta.account.score.settings"
import * as uuid from "uuid"
import * as timex from "timex"

export {
	AccountSettings,
	State,
	UpdateAccountSettingsRequest,
	UpdateAccountSettingsResponse,
} from "./meta.account.score.settings"

export function update(
	req: proto.UpdateAccountRequest,
	...options: httpx.option[]
): Promise<proto.UpdateAccountResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/accounts/${req.account?.id}`, req, ...options)
}

export namespace settings {
	export function zero(ctx: Partial<as.AccountSettings> = {}): as.AccountSettings {
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			confidence: as.State.DISABLED,
			verdict: as.State.DISABLED,
			brand_voice: as.State.DISABLED,
			onbrand: as.State.DISABLED,
			image_obscure_rules: as.State.DISABLED,
			text_tone_and_voice: as.State.DISABLED,
			text_reference_rules: as.State.DISABLED,
			brand_uniqueness: as.State.DISABLED,
			copy_consist: as.State.DISABLED,
			copy_ui_uniq: as.State.DISABLED,
			image_logo_detection: as.State.DISABLED,
			image_logo_distortion: as.State.DISABLED,
			image_logo_colors: as.State.DISABLED,
			image_logo_clearspace: as.State.DISABLED,
			image_font_detection: as.State.DISABLED,
			image_background_colors: as.State.DISABLED,
			image_violence: as.State.DISABLED,
			sexually_explicit: as.State.DISABLED,
			image_quality_general: as.State.DISABLED,
			grammar: as.State.DISABLED,
			racism: as.State.DISABLED,
			profanity: as.State.DISABLED,
			image_compliance: as.State.DISABLED,
			copy_compliance: as.State.DISABLED,
			created_at: timex.local().toISO(),
			updated_at: timex.local().toISO(),
			...ctx,
		}
	}

	export function get(
		req: as.FindAccountSettingsRequest,
		...options: httpx.option[]
	): CancellablePromise<as.FindAccountSettingsResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/accounts/settings`, req, ...options)
	}

	export function update(
		req: as.UpdateAccountSettingsRequest,
		...options: httpx.option[]
	): CancellablePromise<as.UpdateAccountSettingsResponse> {
		return httpx.put(
			`${httpx.urlstorage.host()}/accounts/settings/${req.account_settings?.account_id}`,
			req,
			...options,
		)
	}
}

import React, { useState } from "react"
import { useReactToPrint } from "react-to-print"
import * as retries from "retries"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as api from "brandguide/api"
import * as brands from "brands"

const pageStyle = (fontFaces: string) => `
	${fontFaces}	
	body {
	margin: 0;
	padding: 0;
	}
	@page {
		size: landscape;
	}
	@media print {
		.main-print {
			display: block !important;
			border: 0 !important;
			box-shadow: none !important;
		}
		.section-print {
			page-break-after: always;
		}
		.page-break {
			page-break-inside: avoid;
			transform: scale(0.8);
			transform-origin: top left;
		}
		.no-print {
			display: none !important;
		}
	}
`

export const download = (d: Blob) => {
	const url = window.URL.createObjectURL(new Blob([d]))
	const link = document.createElement("a")
	link.href = url
	link.setAttribute("download", "brandguide.pdf")
	document.body.appendChild(link)
	link.click()
	link.remove()
	window.URL.revokeObjectURL(url)
}

export const useGeneratePdf = (
	toPrint: React.RefObject<HTMLDivElement>,
	version: string,
	fonts: api.FontObject[] | undefined,
) => {
	const brand = brands.caching.useCached()
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const [loading, setLoading] = useState(false)

	const fontFaces = (fonts || [])
		.map(
			(font) =>
				`@font-face { font-family: '${font.font_family}_${font.id}'; src: url(data:font/${font.font_type};base64,${font.font}) format('opentype');}`,
		)
		.join("")

	const generatePdf = useReactToPrint({
		content: () => toPrint.current,
		copyStyles: true,
		pageStyle: pageStyle(fontFaces),
		print: async (printIframe: HTMLIFrameElement) => {
			const document = printIframe.contentDocument
			if (document) {
				setLoading(true)
				const html = document.getElementsByTagName("html")[0]
				const blob = new Blob([html.innerHTML], { type: "text/html" })
				const retry = httpx.autoretry()
				retry
					.wrap(() => api.generate(blob as File, brand.id, version, authzc.bearer(authzaccount)))
					.then(() => {
						const retryOnErrors = retries.options.errors(
							httpx.checkerror(httpx.errors.ratelimited((cause) => true)),
							httpx.checkerror(httpx.errors.badgateway((cause) => true)),
							httpx.checkerror(httpx.errors.notAcceptable((cause) => true)),
						)
						const wait = httpx.autoretry(retryOnErrors)
						wait
							.wrap(() => api.get_pdf(brand.id, version, authzc.bearer(authzaccount)))
							.then((r) => {
								return r.blob()
							})
							.then((b) => {
								download(b)
								setLoading(false)
							})
					})
					.catch((e) => {
						console.error(e)
						setLoading(false)
					})
					.finally(() => {})
			}
		},
	})

	return { loading, generatePdf }
}

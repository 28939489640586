import { useState, useEffect } from "react"
import * as brands from "brands"
import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as icons from "icons"

interface props extends layouts.containers.ContainerProps {
	onLoad(): void
}

export default function Display(props: props): JSX.Element {
	const { onLoad, ...rest } = props
	const brand = brands.caching.useCached()

	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = brandguide.api.zeros.guide.guideImageryZero(guide.guide?.imagery)
	const misuse = data.misuse || []

	const [loadedImages, setLoadedImages] = useState(misuse.length)

	useEffect(() => {
		if (loadedImages !== 0) return
		onLoad()
	}, [loadedImages])

	if (misuse.length === 0) return <></>

	const gridSize = Math.ceil(Math.sqrt(misuse.length))
	const totalWidth = 60 // in vw
	const maxItemWidth = totalWidth / gridSize

	if (misuse.length === 0) return <></>

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Photography to Avoid</result_components.NavTitle>
				<result_components.NavDescription>
					Please follow these guidelines when working with imagery for {brand.description}.
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.misuse} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer>
					<layouts.Flex>
						<typography.h4 color={colors.grey.dark50} lineHeight="30px" fontSize="16px">
							Photography to Avoid
						</typography.h4>
					</layouts.Flex>
					<layouts.containers.grid
						gap="20px"
						style={{ justifyContent: "center" }}
						gridTemplateColumns={`repeat(auto-fit, minmax(${maxItemWidth - 1}vw, auto))`}
					>
						{(data.misuse || []).map((i) => (
							<layouts.Flex
								key={i.id}
								alignItems="center"
								justifyContent="end"
								flexDirection="column"
								background={colors.grey.bg}
								height="100%"
							>
								<layouts.Flex flex="1" position="relative">
									<layouts.containers.absolute right="0" p="3px">
										<icons.brandguide.actions.Reject
											fill={layouts.theme.colors.grey.dark50}
											fillOpacity="0.9"
											width="20px"
											height="20px"
										/>
									</layouts.containers.absolute>
									<brandguide.build.layouts.B64ImageForPDF
										contentdigest={i.thumbnail_contentdigest}
										styles={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
										onLoad={() => setLoadedImages((prev) => prev - 1)}
									/>
								</layouts.Flex>
								<layouts.Flex justifyContent="start" alignItems="end">
									<typography.h6
										fontSize="12px"
										fontWeight="400"
										whiteSpace="unset"
										color={layouts.theme.colors.grey.dark50alpha80}
										lineHeight="20px"
									>
										{i.description}
									</typography.h6>
								</layouts.Flex>
							</layouts.Flex>
						))}
					</layouts.containers.grid>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as api from "./api"
import * as sessions from "sessions"
import { default as Form, validate } from "./credentials.form"

interface props extends layouts.containers.FlexProps {
	onChange(b: api.Credential): void
	onCancel?(): void
}

export default function New(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const profile = sessions.useProfile()
	const [cred, setCredential] = useState(api.credentials.zero({ email: profile.email }))
	const disabled = validate(cred)

	return (
		<layouts.Flex {...rest}>
			<Form current={cred} onChange={setCredential} />
			<layouts.Flex justifyContent="flex-start" gridGap="10px" mt="25px">
				<layouts.buttons.primary
					width="151px"
					height="35px"
					borderRadius="37px"
					disabled={disabled}
					onClick={() => {
						onChange(cred)
					}}
				>
					Save
				</layouts.buttons.primary>
			</layouts.Flex>
		</layouts.Flex>
	)
}

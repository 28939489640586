import React, { useContext, useEffect, useState } from "react"
import * as brands from "brands"
import * as api from "./api"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as errors from "errors"
import * as md5x from "md5x"
import { CancellablePromise } from "real-cancellable-promise"

export const Context = React.createContext(zero())
export const Provider = Context.Provider

export function useCached(): cached {
	return useContext(Context)
}

export function useGuide(): api.Brandguide {
	const ctx = useContext(Context)
	return ctx.guide
}

export interface Storer {
	(s: cached, onlyLocal?: boolean): Promise<void>
}

export default interface cached {
	guide: api.Brandguide
	md5: string
	store: Storer
}

export function zero(c: Partial<cached> = {}): cached {
	return {
		guide: api.zeros.zero(),
		md5: "",
		store(s: cached) {
			// noop default.
			return new Promise((resolve, reject) => {})
		},
		...c,
	}
}

export function Storage(props: React.PropsWithChildren<unknown>): JSX.Element {
	const brand = brands.caching.useCached()
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [cached, setCached] = useState(zero({ guide: api.zeros.zero({ brand_id: brand.id }) }))
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const createAndReplaceStorage = (c: cached, onlyLocal: boolean = false) => {
		if (onlyLocal) return CancellablePromise.resolve(setCached(c))

		const retry = httpx.autoretry()
		return retry
			.wrap(() => brandguide.api.create(brand.id, c.guide, authzc.bearer(metaauthz)))
			.then((r) => {
				const guide = brandguide.api.fillGuide(r)
				setCached({ ...c, guide: brandguide.api.fillGuide(r), md5: md5x.string(JSON.stringify(guide.guide)) })
			})
			.catch((e) => console.error(e))
	}

	useEffect(() => {
		const req = api.zeros.searches.zero({ limit: 1, brand_id: brand.id })
		const retry = httpx.autoretry()
		const pending = retry
			.wrap(() => api.search(brand.id, req, authzc.bearer(metaauthz)))
			.then((r) => {
				if ((r.versions || []).length === 0) {
					setCached({ ...cached, store: createAndReplaceStorage })
					setLoading(false)
					return
				}
				retry
					.wrap(() => api.get(brand.id, r.versions[0], authzc.bearer(metaauthz)))
					.then((r) => {
						const guide = brandguide.api.fillGuide(r)
						setCached({ guide, store: createAndReplaceStorage, md5: md5x.string(JSON.stringify(guide.guide)) })
					})
					.catch(httpx.errors.cancellation(console.warn))
					.finally(() => setLoading(false))
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view brand guide builder", cause)
					setCause(
						<layouts.Flex flex="1" alignItems="center" justifyContent="center">
							<errors.Textual textAlign="center" lineHeight="30px">
								You do not have permission to view BrandGuide Builder
								<br />
								You need to talk to your account administrator to check permissions.
							</errors.Textual>
						</layouts.Flex>,
					)
				}),
			)
			.catch((e) => console.error(e))

		return () => {
			pending.cancel()
		}
	}, [])

	return (
		<layouts.loading.pending loading={loading}>
			<Provider value={cached}>{cause ? cause : props.children}</Provider>
		</layouts.loading.pending>
	)
}

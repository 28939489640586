import React, { useState } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as archetype from "brandguide/build/archetype"

const roundToNearestTen = (num: number) => Math.round(num / 10) * 10

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideArchetype | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const initial = (): brandguide.api.GuideArchetype => {
		const zero = brandguide.api.zeros.guide.guideArchetypeZero(_guide.guide?.archetype)
		if (!zero.adjectives.map((i) => i.percent).includes(0)) return zero
		const totalSelectedItems = zero.adjectives.reduce((total, column) => total + column.items.length, 0)
		return {
			...zero,
			adjectives: zero.adjectives.map((a) => {
				return { ...a, percent: roundToNearestTen((a.items.length / totalSelectedItems) * 100) }
			}),
		}
	}

	const [data, setData] = useState(initial())

	const items = data.adjectives

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Archetype" Help={<archetype.helps.Modal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Here is the breakdown based on your selections. Edit these percentages as you see fit. ">
							<archetype.Carousel my="25px" className={archetype.layouts.styledCarousel}>
								{items.map((i) => (
									<archetype.layouts.BreakDownItem
										key={i.archetype}
										item={i}
										onChange={(a, i) => {
											const selected = items.find((s) => s.archetype === a)
											if (!selected) return undefined
											const supd = selected.percent + i
											const rupd = 100 - supd
											setData({
												...data,
												adjectives: items.map((adj) => {
													return adj.archetype === a
														? brandguide.api.zeros.archetype.adjectiveZero({ ...adj, percent: supd })
														: brandguide.api.zeros.archetype.adjectiveZero({ ...adj, percent: rupd })
												}),
											})
										}}
									/>
								))}
							</archetype.Carousel>

							<archetype.Explanation
								val={data.descriptions?.breakdown || ""}
								placeholder="Add further explanation here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.archetype.descZero({
											...data.descriptions,
											breakdown: text,
										}),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(archetype.Steps.SELECTION, undefined)}
								onNext={() => onChange(archetype.Steps.RESULT, data)}
								disabled={!brandguide.validations.archetype.valid.breakdown(data)}
								isLastStep
							/>
							<archetype.Progress activeStep={archetype.Steps.BREAKDOWN} steps={archetype.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<archetype.Guidelines activPage="archetype" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import { useState, useEffect } from "react"
import * as brandguide from "brandguide"
import * as res_components from "brandguide/results/components"

interface props {
	itemsOnPage: number
	onLoad(): void
}

export default function Display(props: props): JSX.Element {
	const { itemsOnPage, onLoad } = props

	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = brandguide.api.zeros.guide.guideImageryZero(guide.guide?.imagery)

	const sectionDescription =
		"Welcome to the Products page of our brand's style guide This section is dedicated to showcasing the product images that encapsulate our brand's unique visual identity. Product images are a vital part of our brand's visual communication. They not only showcase the features, quality, and design of our products, but also convey our brand's values and aesthetics."
	const items = res_components.chunkImageItems(data.product?.items || [], itemsOnPage)
	const [loadedImages, setLoadedImages] = useState(items.length)

	useEffect(() => {
		if (loadedImages !== 0) return
		onLoad()
	}, [loadedImages])

	if (!brandguide.validations.imagery.valid.product(data)) return <></>

	return (
		<>
			{items.map((i, index) => {
				const firstPage = index === 0
				const pageTitle = firstPage ? "Product Imagery" : undefined
				const sectionDesc = firstPage ? sectionDescription : undefined
				const explanation = firstPage ? data.descriptions?.product : undefined
				return (
					<res_components.ImagesPage
						key={index}
						items={i}
						sectionTitle="Product Imagery"
						sectionDescription={sectionDesc}
						pageTitle={pageTitle}
						explanation={explanation}
						onLoad={() => setLoadedImages((prev) => prev - 1)}
						className="section-print"
					/>
				)
			})}
		</>
	)
}

Display.defaultProps = {
	itemsOnPage: 9,
}

import * as layouts from "layouts"
import * as brandguide from "brandguide"
import { ColorDescription } from "."

interface props extends layouts.containers.ContainerProps {
	item: brandguide.api.ColorObject
}

export default function ColorCard(props: props): JSX.Element {
	const { item, ...rest } = props
	return (
		<layouts.Flex
			{...rest}
			background={item.color?.hex_code}
			border={`1px solid ${layouts.theme.colors.grey.dark50alpha10}`}
		>
			<ColorDescription item={item} />
		</layouts.Flex>
	)
}

ColorCard.defaultProps = {
	borderRadius: "20px",
	alignItems: "end",
	justifyContent: "start",
}

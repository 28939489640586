import React, { useContext, useState } from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import { css } from "@emotion/css"
import { DateTime } from "luxon"
import * as inputs from "inputs"
import * as domains from "sso/domains"
import * as icons from "icons"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as typography from "typography"

export const ModalContainer = styled(layouts.containers.flex)<{ outerBackground: string }>`
	width: 430px;
	flex-direction: column;
	background: ${layouts.theme.colors.white};
	border-radius: 10px;
	box-shadow: ${layouts.theme.boxshadow.black.wide};
`

export const StyledInput = styled.input`
	border: none;
	min-width: 100px;
	outline: none;
	width: 100%;
	min-width: 100px;
	border-radius: 23px;
	border: ${layouts.theme.borders.grey.dark50alphamedium1px};
	height: 38px;
	padding-right: 10px;
	padding-left: 10px;
`

export const StyledDatePicker = css`
	.react-datepicker-wrapper {
		padding-left: 10px;
		.react-datepicker__input-container {
			height: 100%;

			input {
				outline: none;
				border: none;
				height: 100%;
			}
		}
	}
`

function AccordionDomainHead(
	props: React.PropsWithChildren<
		{ description: string; authDomains: string[]; created: string; updated: string } & layouts.accordions.props &
			layouts.containers.FlexProps
	>,
): JSX.Element {
	const { open, toggle } = useContext(layouts.accordions.AccordionContext)
	const { description, authDomains, updated, created, children, ...rest } = { ...props }
	return (
		<layouts.accordions.AccordionProvider value={{ open, toggle }}>
			<layouts.containers.flex
				flexDirection="row"
				fontSize="14px"
				lineHeight="20px"
				color={layouts.theme.colors.grey.dark50}
				{...rest}
			>
				<layouts.containers.flex flex="3" p="10px" flexDirection="column">
					{description}
					<layouts.Flex color={layouts.theme.colors.grey.dark50alpha40}>{authDomains.join(", ")}</layouts.Flex>
				</layouts.containers.flex>

				<layouts.containers.flex flex="1" p="10px" justifyContent="center" alignItems="center">
					{DateTime.fromISO(created).toLocaleString(DateTime.DATE_MED)}
				</layouts.containers.flex>

				<layouts.containers.flex flex="1" p="10px" justifyContent="center" alignItems="center">
					{DateTime.fromISO(updated).toLocaleString(DateTime.DATE_MED)}
				</layouts.containers.flex>
				<layouts.containers.flex
					flex="1"
					p="10px"
					justifyContent="center"
					alignItems="center"
					onClick={() => toggle(!open)}
				>
					{open ? <icons.accordions.Opened /> : <icons.accordions.Closed />}
				</layouts.containers.flex>
			</layouts.containers.flex>
			{children}
		</layouts.accordions.AccordionProvider>
	)
}

export function AccordionDomainContainer(
	props: React.PropsWithChildren<{ domain: domains.api.proto.Domainapi } & layouts.containers.FlexProps>,
): JSX.Element {
	const { domain, children, ...rest } = props

	return (
		<layouts.accordions.Container
			minHeight="unset"
			padding="unset"
			flexDirection="column"
			borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
			m="unset"
			{...rest}
		>
			<layouts.containers.box
				tabIndex={0}
				className="accordion-containers-box"
				background={layouts.theme.colors.grey.bg}
			>
				<AccordionDomainHead
					description={domain.description}
					authDomains={domain.authorized_domains}
					created={domain.created_at}
					updated={domain.updated_at}
				>
					<layouts.accordions.Content>
						<layouts.containers.flex flexDirection="column" width="100%" flex="1">
							{children}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</AccordionDomainHead>
			</layouts.containers.box>
		</layouts.accordions.Container>
	)
}

export const Input = styled.input`
	outline: none;
	width: 100%;
	min-width: 100px;
	font-weight: 600;
	color: ${layouts.theme.colors.grey.dark50};
	background: ${layouts.theme.colors.white};
	border: ${layouts.theme.borders.grey.dark50alphamedium1px};
	padding-left: 10px;
	padding-right: 10px;
`

export function EditDomain(props: {
	readonly?: boolean
	current: domains.Domainapi
	onRemove?(): void
	onChange(upd: domains.Domainapi): Promise<domains.Domainapi>
}): JSX.Element {
	const { current, onChange, onRemove } = props

	const { open, toggle } = useContext(layouts.accordions.AccordionContext)
	const permission = authzc.useCache((cached) => cached.meta.current)
	const mtoggle = layouts.modals.useToggle()
	const [upd, setUpd] = useState(current)

	function validate(dom: domains.Domainapi): boolean {
		return inputs.assertions.failed(
			inputs.assertions.strings.min(1)(dom.description),
			inputs.assertions.strings.rfc1035_sub()(dom.description),
		)
	}
	const disabled = validate(upd)

	return (
		<>
			<layouts.Flex flexDirection="row" width="100%" py="5px">
				<layouts.Flex flex="1" mr="10px">
					<layouts.containers.flex pr="10px">Name:</layouts.containers.flex>
					<Input
						required
						disabled={!permission.usermanagement}
						defaultValue={upd.description}
						onChange={(evt) => setUpd((prevState) => ({ ...prevState, description: evt.target.value }))}
					/>
				</layouts.Flex>
				<layouts.Flex flex="1" mr="10px">
					<layouts.containers.flex pr="10px">Link:</layouts.containers.flex>
					<layouts.Span fontWeight={600} cursor="pointer">
						{domains.api.ssoAccessLink(upd.description)}
					</layouts.Span>
					<layouts.Clipboard ml="1ch" body={domains.api.ssoAccessLink(upd.description)} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" width="100%" py="5px" background={layouts.theme.colors.white}>
				<layouts.Flex flexDirection="column" flex="1" p="1ch">
					<layouts.Span>Authorized domains:</layouts.Span>
				</layouts.Flex>
				<layouts.Flex flexDirection="column" flex="1" p="1ch">
					<AuthorizedDomains
						key={upd.authorized_domains.length}
						object={upd}
						onChange={(ch: domains.Domainapi) => {
							setUpd((prevState) => ({ ...prevState, authorized_domains: ch.authorized_domains }))
						}}
					/>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.containers.grid
				width="100%"
				justifyContent="center"
				display="grid"
				gridGap="10px"
				gridTemplateColumns="repeat(3, 1fr);"
				gridTemplateRows="min-content"
				px="10px"
			></layouts.containers.grid>
			<authz.Protected enabled={permission.usermanagement}>
				<layouts.containers.flex flexDirection="row" justifyContent="flex-end" width="100%" gridGap="10px" my="20px">
					{onRemove && (
						<layouts.buttons.outline
							borderRadius="33px"
							onClick={() =>
								mtoggle(
									<ModalContainer outerBackground={layouts.theme.backgrounds.blackalpha75}>
										<RemoveModal
											dismiss={() => mtoggle(undefined)}
											accept={() => {
												onRemove()
												mtoggle(undefined)
												toggle(!open)
											}}
										/>
										,
									</ModalContainer>,
								)
							}
							minWidth="85px"
						>
							Remove
						</layouts.buttons.outline>
					)}
					<layouts.buttons.primary
						disabled={disabled}
						borderRadius="33px"
						onClick={() => {
							onChange(upd)
							toggle(!open)
						}}
						minWidth="85px"
					>
						Save
					</layouts.buttons.primary>
				</layouts.containers.flex>
			</authz.Protected>
		</>
	)
}

export default function AuthorizedDomains(
	props: React.PropsWithChildren<{
		object: domains.Domainapi
		onChange(upd: domains.Domainapi): void
	}>,
): JSX.Element {
	const labelwidth = "12ch"
	const { object, onChange, ...rest } = props
	const [dom, setDom] = useState("")
	const permission = authzc.useCache((cached) => cached.meta.current)

	function validate(d: string): boolean {
		return inputs.assertions.failed(inputs.assertions.strings.rfc1035_domain()(d))
	}
	const disabled = validate(dom)

	const domainsList = (object.authorized_domains || []).map((r, i) => (
		<layouts.Flex key={i} py="5px" flexDirection="column">
			<layouts.Flex>
				<layouts.Span fontWeight={600}>{r}</layouts.Span>
				<authz.Protected enabled={permission.usermanagement}>
					<layouts.Span
						ml="auto"
						onClick={(evt) => {
							evt.stopPropagation()
							onChange({ ...object, authorized_domains: object.authorized_domains.filter((o) => o !== r) })
						}}
					>
						<icons.Trash width="14px" height="14px" />
					</layouts.Span>
				</authz.Protected>
			</layouts.Flex>
		</layouts.Flex>
	))

	return (
		<layouts.Flex flexDirection="column" flex="1" {...rest}>
			<layouts.Flex flexDirection="column">{domainsList}</layouts.Flex>
			<authz.Protected enabled={permission.usermanagement}>
				<layouts.forms.Container p="10px">
					<layouts.forms.Group>
						<layouts.forms.Label width={labelwidth}>Domain</layouts.forms.Label>
						<Input onChange={(evt) => setDom(evt.target.value)} defaultValue={dom} />
					</layouts.forms.Group>
					<layouts.Flex flex="1" flexDirection="row" justifyContent="space-between">
						<layouts.buttons.primary
							disabled={disabled}
							onClick={() => {
								onChange({
									...object,
									authorized_domains: [...object.authorized_domains, dom],
								})
							}}
						>
							Add
						</layouts.buttons.primary>
					</layouts.Flex>
				</layouts.forms.Container>
			</authz.Protected>
		</layouts.Flex>
	)
}

export function RemoveModal(props: { dismiss(): void; accept(): void }): JSX.Element {
	const { dismiss, accept } = props
	return (
		<>
			<layouts.containers.flex p="10px" justifyContent="flex-end" alignItems="flex-start">
				<layouts.containers.flex onClick={dismiss}>
					<icons.Close width="15px" height="15px" fill={layouts.theme.colors.grey.dark50} fillOpacity="0.6" p="5px" />
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="column" px="50px" justifyContent="flex-start" alignItems="flex-start">
				<typography.h3 fontSize="16px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
					Remove
				</typography.h3>
				<typography.h5
					fontSize="14px"
					lineHeight="20px"
					fontWeight="400"
					color={layouts.theme.colors.grey.dark50}
					whiteSpace="unset"
				>
					Are you sure you want to remove this access link?
				</typography.h5>
			</layouts.containers.flex>
			<layouts.containers.flex p="25px" justifyContent="flex-end" gridGap="10px">
				<layouts.buttons.outline color={layouts.theme.colors.blue.bluedarkness} borderRadius="39px" onClick={dismiss}>
					Keep
				</layouts.buttons.outline>
				<layouts.buttons.danger borderRadius="39px" background={layouts.theme.colors.red.cinnabar} onClick={accept}>
					Remove
				</layouts.buttons.danger>
			</layouts.containers.flex>
		</>
	)
}

import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as icons from "icons"
import { css } from "@emotion/css"
import classnames from "classnames"

export const Input = styled.input`
	border: none;
	outline: none;
	width: 100%;
	min-width: 100px;
`

export const svgfixed = css`
	svg {
		flex-shrink: 0;
	}
`

interface props {
	query: string
	onChange(text: string): void
	icon: JSX.Element
}

export default function Search(props: React.PropsWithChildren<props & layouts.containers.FlexProps>): JSX.Element {
	const { query, onChange, icon, children, className, ...rest } = props
	return (
		<layouts.containers.flex className={classnames(svgfixed, className)} {...rest}>
			{icon}
			<Input type="text" placeholder="Search" value={query} onChange={(evt) => onChange(evt.currentTarget.value)} />
			{children}
		</layouts.containers.flex>
	)
}

Search.defaultProps = {
	icon: <icons.searches.Magnifier pl="20px" pr="10px" fill={layouts.theme.colors.grey.dark50} />,
	mt: "50px",
	height: "50px",
	borderRadius: "30px",
	border: layouts.theme.borders.grey.dark50alphamedium1px,
	alignItems: "center",
}

import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as components from "."
import * as brands from "brands"

export const textProps = {
	color: colors.grey.dark50,
	letterSpacing: "normal",
	whiteSpace: "unset",
}

interface props extends layouts.containers.ContainerProps {
	title: string
}

export default function Display(props: props): JSX.Element {
	const { title, ...rest } = props
	const brand = brands.caching.useCached()

	return (
		<layouts.Flex {...rest}>
			<result_components.MainContainer position="relative">
				<layouts.containers.absolute top="0" left="0">
					<layouts.Flex flexDirection="column" flex="1" p="10px">
						<typography.h3 fontSize="18px" lineHeight="24px" {...textProps}>
							{brand.description} Brand Guidelines
						</typography.h3>
						<typography.h5 fontSize="12px" {...textProps}>
							by BrandGuide Builder
						</typography.h5>
					</layouts.Flex>
				</layouts.containers.absolute>
				<result_components.ContentContainer justifyContent="center" alignItems="center">
					<typography.h4 color={colors.grey.dark50} fontWeight="700" lineHeight="13vh" fontSize="12vh">
						{title}
					</typography.h4>
				</result_components.ContentContainer>
				<layouts.containers.absolute bottom="0" left="0">
					<layouts.Flex flexDirection="column" flex="1" p="10px" justifyContent="end">
						<typography.h5 fontSize="12px" {...textProps}>
							{components.FormattedDate()}
						</typography.h5>
					</layouts.Flex>
				</layouts.containers.absolute>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

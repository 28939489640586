import { SVG, SVGProps } from "./svg"

const Icon = (props: SVGProps) => {
	const { fill, ...rest } = props

	return (
		<SVG viewBox="0 0 100 80" width="40px" height="40px" fill={fill} {...rest}>
			<rect width="100" height="20" rx="8"></rect>
			<rect y="30" width="100" height="20" rx="8"></rect>
			<rect y="60" width="100" height="20" rx="8"></rect>
		</SVG>
	)
}

export default Icon

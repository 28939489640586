import React from "react"
import * as layouts from "layouts"
import * as icons from "icons"
import * as routing from "brandguard/routing"
import { Link } from "react-router-dom"
import * as brands from "brands"

export default function Navigation(): JSX.Element {
	const brand = brands.caching.useCached()
	return (
		<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="15px">
			<layouts.containers.flex flexDirection="row" flex="1" borderBottom={layouts.theme.borders.grey.dark50alphamedium}>
				<layouts.containers.flex
					p="5px"
					flexDirection="row"
					borderBottom={`2px solid ${layouts.theme.colors.blue.blue}`}
				>
					<layouts.containers.flex>
						<icons.brandguard.image pr="5px" />
					</layouts.containers.flex>
					<layouts.containers.flex fontSize="14px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
						Image
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<Link to={routing.text.score(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.flex>
							<icons.brandguard.text strokeOpacity="0.5" pr="5px" />
						</layouts.containers.flex>
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Text
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

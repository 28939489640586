import React, { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"

interface TooltipProps extends layouts.containers.ContainerProps {
	text: string
}

function Tooltip(props: React.PropsWithChildren<TooltipProps>): JSX.Element {
	const { text, children, fontSize, fontWeight, ...rest } = props
	const [showTooltip, setShowTooltip] = useState(false)

	const textRest = { fontSize, fontWeight }

	return (
		<layouts.Box
			position="relative"
			display="inline-block"
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
		>
			{children}
			{showTooltip && (
				<layouts.containers.absolute {...rest}>
					<typography.h5 color={layouts.theme.colors.white} {...textRest}>
						{text}
					</typography.h5>
				</layouts.containers.absolute>
			)}
		</layouts.Box>
	)
}

Tooltip.defaultProps = {
	bottom: "100%",
	left: "50%",
	transform: "translateX(-50%)",
	backgroundColor: layouts.theme.colors.grey.dark50,
	padding: "5px",
	borderRadius: "3px",
	whiteSpace: "nowrap",
	fontSize: "14px",
	fontWeight: "500",
}

export default Tooltip

import * as svg from "icons/svg"
import { css } from "@emotion/css"
import * as theme from "layouts/theme"

const styled = css`
	.c {
		stroke-width: 6.62px;
	}

	.c,
	.d,
	.e,
	.f,
	.g,
	.h,
	.i,
	.j,
	.k,
	.l,
	.m,
	.n {
		fill: none;
	}

	.c,
	.d,
	.g,
	.h,
	.i,
	.k,
	.l,
	.m,
	.n {
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	.c,
	.e,
	.h,
	.j,
	.k {
		stroke: #fff;
	}

	.d,
	.i,
	.m {
		stroke: #d7d8dc;
	}

	.d,
	.n {
		stroke-width: 6px;
	}

	.o {
		stroke-width: 0.6px;
	}

	.o,
	.p,
	.q,
	.r {
		fill: #fff;
	}

	.o,
	.e,
	.q,
	.f,
	.r,
	.s,
	.j {
		stroke-miterlimit: 10;
	}

	.o,
	.q,
	.r,
	.s {
		stroke: #e6e6e6;
	}

	.t {
		fill: #e43e3e;
	}

	.u {
		fill: ${theme.colors.blue.dark10};
	}

	.v {
		fill: #222f8f;
	}

	.w {
		fill: #151e51;
	}

	.x {
		fill: #2960f2;
	}

	.y {
		fill: #5fcefa;
	}

	.z {
		fill: #5d6571;
	}

	.e {
		stroke-width: 4.4px;
	}

	.q {
		stroke-width: 0.79px;
	}

	.f {
		stroke: #151e51;
		stroke-width: 4.89px;
	}

	.g,
	.i {
		stroke-width: 12.44px;
	}

	.g,
	.l,
	.n {
		stroke: #0362fb;
	}

	.r {
		stroke-width: 0.95px;
	}

	.h,
	.j {
		stroke-width: 5.35px;
	}

	.s {
		fill: url(#b);
	}

	.k {
		stroke-width: 5.39px;
	}

	.l,
	.m {
		stroke-width: 8px;
	}
`

const Icon = (props: svg.SVGProps) => {
	const { ...rest } = props
	return (
		<svg.SVG
			id="a"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			width="732.58"
			height="100vh"
			viewBox="0 0 732.58 707.39"
			className={styled}
			transform="scale(0.75)"
			{...rest}
		>
			<defs>
				<radialGradient
					id="b"
					cx="370.83"
					cy="378.08"
					fx="370.83"
					fy="378.08"
					r="582.35"
					gradientTransform="translate(0 856.23) scale(1 -1.28)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#fff" />
					<stop offset=".26" stopColor="#f9f9fa" />
					<stop offset=".61" stopColor="#e9e9ec" />
					<stop offset=".99" stopColor="#ced0d5" />
					<stop offset="1" stopColor="#ced0d5" />
				</radialGradient>
			</defs>
			<rect className="u" x="-16.42" y="-20.62" width="780.2" height="780.2" />
			<g>
				<rect className="t" x="7.47" y="423" width="179.52" height="165.87" rx="17.02" ry="17.02" />
				<g>
					<g>
						<circle
							className="k"
							cx="97.23"
							cy="490.65"
							r="36.24"
							transform="translate(-396.38 571.5) rotate(-88.09)"
						/>
						<g>
							<line className="k" x1="83.29" y1="477.18" x2="111.18" y2="504.12" />
							<line className="k" x1="111.18" y1="477.18" x2="83.29" y2="504.12" />
						</g>
					</g>
					<g>
						<path
							className="p"
							d="M65.96,550.38c0,1.42-.25,2.67-.74,3.74s-1.19,1.9-2.11,2.47-1.96,.86-3.15,.86-2.22-.29-3.15-.86c-.92-.57-1.63-1.39-2.13-2.46-.5-1.06-.76-2.29-.76-3.68v-.82c0-1.41,.25-2.66,.75-3.75,.5-1.08,1.21-1.91,2.12-2.49s1.96-.86,3.14-.86,2.23,.28,3.14,.85,1.62,1.39,2.12,2.45c.5,1.07,.75,2.31,.76,3.72v.83Zm-2.51-.76c0-1.61-.3-2.84-.91-3.7s-1.47-1.29-2.59-1.29-1.94,.43-2.56,1.28c-.61,.85-.93,2.06-.94,3.62v.84c0,1.59,.31,2.83,.93,3.7s1.48,1.31,2.59,1.31,1.97-.43,2.58-1.28c.6-.85,.9-2.09,.9-3.73v-.76h0Z"
						/>
						<path
							className="p"
							d="M69.09,557.26v-8.99h-1.64v-1.79h1.64v-.99c0-1.2,.33-2.12,1-2.77,.66-.65,1.59-.98,2.79-.98,.43,0,.88,.06,1.36,.18l-.06,1.89c-.27-.05-.57-.08-.93-.08-1.16,0-1.73,.59-1.73,1.78v.96h2.19v1.79h-2.19v8.99h-2.43Z"
						/>
						<path
							className="p"
							d="M76.32,557.26v-8.99h-1.64v-1.79h1.64v-.99c0-1.2,.33-2.12,1-2.77,.66-.65,1.59-.98,2.79-.98,.43,0,.88,.06,1.36,.18l-.06,1.89c-.27-.05-.57-.08-.93-.08-1.16,0-1.73,.59-1.73,1.78v.96h2.19v1.79h-2.19v8.99h-2.43Z"
						/>
						<path className="p" d="M87.41,552.07h-5.23v-1.95h5.23v1.95Z" />
						<path
							className="p"
							d="M89.64,557.26v-14.51h4.97c1.64,0,2.89,.33,3.75,.99s1.29,1.64,1.29,2.94c0,.66-.18,1.26-.54,1.79-.36,.53-.88,.94-1.58,1.24,.78,.21,1.39,.61,1.82,1.2s.64,1.29,.64,2.12c0,1.36-.44,2.41-1.31,3.14-.87,.73-2.12,1.1-3.75,1.1h-5.29Zm2.52-8.41h2.47c.78,0,1.4-.18,1.84-.53,.44-.36,.66-.86,.66-1.51,0-.72-.2-1.24-.61-1.55-.41-.32-1.04-.48-1.91-.48h-2.45v4.08h0Zm0,1.84v4.53h2.8c.79,0,1.41-.2,1.85-.59,.44-.39,.67-.94,.67-1.63,0-1.51-.77-2.28-2.31-2.31h-3.01Z"
						/>
						<path
							className="p"
							d="M107.93,548.69c-.32-.05-.65-.08-.99-.08-1.11,0-1.86,.43-2.24,1.28v7.38h-2.42v-10.78h2.31l.06,1.21c.59-.94,1.4-1.41,2.43-1.41,.35,0,.63,.05,.86,.14v2.27h-.01Z"
						/>
						<path
							className="p"
							d="M115.6,557.26c-.11-.21-.2-.54-.28-1.01-.77,.8-1.71,1.21-2.83,1.21s-1.97-.31-2.65-.93-1.03-1.38-1.03-2.29c0-1.15,.43-2.03,1.28-2.65,.85-.61,2.07-.92,3.66-.92h1.48v-.71c0-.56-.16-1-.47-1.34s-.79-.5-1.43-.5c-.55,0-1,.14-1.36,.41-.35,.28-.53,.63-.53,1.05h-2.42c0-.59,.2-1.14,.59-1.66,.39-.52,.93-.92,1.6-1.21,.67-.29,1.43-.44,2.26-.44,1.26,0,2.27,.32,3.02,.95s1.14,1.53,1.16,2.68v4.86c0,.97,.14,1.74,.41,2.32v.17h-2.46Zm-2.66-1.73c.48,0,.93-.12,1.35-.35s.74-.54,.95-.94v-2.03h-1.31c-.9,0-1.57,.16-2.02,.47s-.68,.75-.68,1.33c0,.46,.16,.84,.46,1.11,.31,.28,.72,.41,1.24,.41h.01Z"
						/>
						<path
							className="p"
							d="M122.45,546.48l.07,1.25c.8-.96,1.84-1.45,3.14-1.45,2.25,0,3.39,1.29,3.43,3.86v7.13h-2.42v-6.99c0-.68-.15-1.19-.44-1.52-.3-.33-.78-.49-1.45-.49-.98,0-1.7,.44-2.18,1.33v7.67h-2.42v-10.78h2.27Z"
						/>
						<path
							className="p"
							d="M131.1,551.79c0-1.66,.38-2.99,1.16-4,.77-1.01,1.8-1.51,3.1-1.51,1.14,0,2.07,.4,2.77,1.2v-5.52h2.42v15.31h-2.19l-.12-1.12c-.72,.88-1.69,1.32-2.9,1.32s-2.28-.51-3.06-1.52c-.78-1.02-1.17-2.4-1.17-4.15h-.01Zm2.42,.2c0,1.1,.21,1.95,.63,2.57s1.02,.92,1.8,.92c.99,0,1.71-.44,2.17-1.33v-4.61c-.44-.86-1.16-1.3-2.15-1.3-.78,0-1.39,.31-1.81,.93-.43,.62-.64,1.56-.64,2.81h0Z"
						/>
					</g>
				</g>
			</g>
			<rect className="s" x="62.08" y="294.15" width="617.49" height="156.28" rx="30.64" ry="30.64" />
			<g>
				<g>
					<path
						className="w"
						d="M205.1,403.44v-53.4h10.25v53.4h-10.25Zm7.6,0v-8.43h13.9c2.48,0,4.42-.76,5.81-2.28s2.09-3.32,2.09-5.39c0-1.42-.3-2.71-.91-3.87s-1.51-2.09-2.7-2.77-2.62-1.03-4.29-1.03h-13.9v-8.35h12.76c2.13,0,3.84-.53,5.13-1.6,1.29-1.06,1.94-2.66,1.94-4.79s-.65-3.8-1.94-4.86c-1.29-1.06-3-1.59-5.13-1.59h-12.76v-8.43h13.98c3.54,0,6.52,.65,8.92,1.94s4.22,2.99,5.43,5.09c1.22,2.1,1.82,4.39,1.82,6.88,0,3.04-.92,5.66-2.77,7.86-1.85,2.2-4.55,3.83-8.09,4.9l.46-3.34c3.95,1.11,7,2.95,9.15,5.51s3.23,5.63,3.23,9.23c0,2.84-.7,5.41-2.09,7.71s-3.42,4.15-6.08,5.54-5.89,2.09-9.68,2.09h-14.28v-.02Z"
					/>
					<path
						className="w"
						d="M250.06,403.44v-36.69h9.95v36.69h-9.95Zm9.95-20.28l-3.87-2.58c.46-4.51,1.77-8.06,3.95-10.67,2.18-2.61,5.32-3.91,9.42-3.91,1.77,0,3.37,.29,4.79,.87s2.71,1.53,3.87,2.85l-6.23,7.14c-.56-.61-1.23-1.06-2.01-1.37-.79-.3-1.68-.46-2.7-.46-2.13,0-3.86,.67-5.2,2.01-1.34,1.34-2.01,3.38-2.01,6.11h0Z"
					/>
					<path
						className="w"
						d="M294.12,404.2c-3.34,0-6.36-.84-9.04-2.51-2.68-1.67-4.79-3.95-6.3-6.84-1.52-2.89-2.28-6.13-2.28-9.72s.76-6.91,2.28-9.8,3.62-5.17,6.3-6.84c2.68-1.67,5.7-2.51,9.04-2.51,2.63,0,4.99,.53,7.06,1.59,2.08,1.06,3.73,2.54,4.98,4.44,1.24,1.9,1.91,4.04,2.01,6.42v13.22c-.1,2.43-.77,4.58-2.01,6.46-1.24,1.88-2.9,3.36-4.98,4.44-2.08,1.09-4.43,1.63-7.06,1.63v.02Zm1.82-9.19c2.78,0,5.04-.92,6.76-2.77,1.72-1.85,2.58-4.24,2.58-7.18,0-1.92-.39-3.63-1.18-5.13-.79-1.49-1.87-2.66-3.27-3.49-1.39-.84-3.03-1.25-4.9-1.25s-3.43,.42-4.82,1.25-2.48,2-3.27,3.49c-.79,1.49-1.18,3.2-1.18,5.13s.39,3.71,1.18,5.2c.78,1.49,1.87,2.66,3.27,3.49,1.39,.84,3,1.25,4.82,1.25h0Zm8.81,8.44v-9.87l1.59-8.96-1.59-8.81v-9.04h9.87v36.69h-9.87Z"
					/>
					<path
						className="w"
						d="M321.53,403.44v-36.69h9.95v36.69h-9.95Zm24.68,0v-21.04c0-2.18-.68-3.95-2.05-5.32s-3.14-2.05-5.32-2.05c-1.42,0-2.68,.3-3.8,.91-1.11,.61-1.99,1.47-2.62,2.58-.63,1.12-.95,2.41-.95,3.87l-3.87-1.97c0-2.89,.62-5.41,1.86-7.56s2.96-3.84,5.17-5.05,4.7-1.82,7.48-1.82,5.09,.67,7.22,2.01,3.8,3.09,5.01,5.24c1.21,2.15,1.82,4.47,1.82,6.95v23.24h-9.95Z"
					/>
					<path
						className="w"
						d="M377.82,404.2c-3.39,0-6.43-.84-9.11-2.51s-4.8-3.95-6.34-6.84c-1.55-2.89-2.32-6.13-2.32-9.72s.77-6.91,2.32-9.8c1.54-2.89,3.65-5.17,6.3-6.84,2.66-1.67,5.71-2.51,9.15-2.51,2.63,0,5,.53,7.1,1.59s3.8,2.54,5.09,4.44,1.99,4.04,2.09,6.42v13.06c-.1,2.38-.79,4.53-2.05,6.46-1.27,1.92-2.96,3.44-5.09,4.56-2.13,1.11-4.51,1.67-7.14,1.67v.02Zm1.67-9.19c1.87,0,3.51-.42,4.9-1.25,1.39-.83,2.48-2,3.27-3.49,.78-1.49,1.18-3.23,1.18-5.2s-.39-3.63-1.18-5.13c-.79-1.49-1.87-2.66-3.27-3.49-1.39-.84-3-1.25-4.82-1.25s-3.51,.43-4.9,1.29c-1.39,.86-2.48,2.03-3.27,3.49-.79,1.47-1.18,3.17-1.18,5.09s.39,3.71,1.18,5.2c.78,1.49,1.89,2.66,3.3,3.49,1.42,.84,3.01,1.25,4.79,1.25Zm18.68,8.43h-9.87v-9.87l1.59-8.96-1.67-8.81v-27.27h9.95v54.91h0Z"
					/>
					<path
						className="w"
						d="M430.83,404.2c-3.8,0-7.36-.7-10.67-2.09-3.32-1.39-6.23-3.35-8.73-5.89-2.51-2.53-4.46-5.46-5.85-8.77-1.39-3.32-2.09-6.9-2.09-10.75s.71-7.51,2.13-10.82,3.39-6.23,5.92-8.73c2.53-2.51,5.49-4.46,8.89-5.85,3.39-1.39,7.06-2.09,11.01-2.09,4.41,0,8.46,.82,12.15,2.47,3.7,1.65,6.76,3.96,9.19,6.95l-6.99,6.99c-1.67-2.18-3.75-3.85-6.23-5.01-2.48-1.17-5.22-1.75-8.2-1.75-3.39,0-6.39,.75-9,2.24s-4.65,3.57-6.11,6.23c-1.47,2.66-2.2,5.76-2.2,9.3s.72,6.67,2.16,9.38,3.42,4.82,5.92,6.34c2.51,1.52,5.38,2.28,8.62,2.28s6.2-.62,8.58-1.86c2.38-1.24,4.2-3.06,5.47-5.47,1.27-2.4,1.9-5.36,1.9-8.85l6.76,4.63-23.32-.23v-8.96h27.19v1.44c0,6.38-1.14,11.71-3.42,15.99s-5.41,7.49-9.38,9.65c-3.98,2.15-8.54,3.23-13.71,3.23h.01Z"
					/>
					<path
						className="w"
						d="M477.92,404.2c-3.24,0-6.11-.68-8.62-2.05s-4.47-3.27-5.89-5.7c-1.42-2.43-2.13-5.24-2.13-8.43v-21.27h10.03v21.04c0,1.52,.25,2.82,.76,3.91s1.27,1.91,2.28,2.47,2.2,.84,3.57,.84c2.08,0,3.7-.63,4.86-1.9,1.16-1.27,1.75-3.04,1.75-5.32v-21.04h9.95v21.27c0,3.24-.7,6.08-2.09,8.51-1.39,2.43-3.34,4.32-5.85,5.66s-5.38,2.01-8.62,2.01h0Z"
					/>
					<path
						className="w"
						d="M515.82,404.2c-3.34,0-6.36-.84-9.04-2.51-2.68-1.67-4.79-3.95-6.3-6.84-1.52-2.89-2.28-6.13-2.28-9.72s.76-6.91,2.28-9.8,3.62-5.17,6.3-6.84c2.68-1.67,5.7-2.51,9.04-2.51,2.63,0,4.99,.53,7.06,1.59,2.08,1.06,3.73,2.54,4.98,4.44,1.24,1.9,1.91,4.04,2.01,6.42v13.22c-.1,2.43-.77,4.58-2.01,6.46s-2.9,3.36-4.98,4.44c-2.08,1.09-4.43,1.63-7.06,1.63v.02Zm1.82-9.19c2.78,0,5.04-.92,6.76-2.77,1.72-1.85,2.58-4.24,2.58-7.18,0-1.92-.39-3.63-1.18-5.13-.79-1.49-1.87-2.66-3.27-3.49-1.39-.84-3.03-1.25-4.9-1.25s-3.43,.42-4.82,1.25-2.48,2-3.27,3.49c-.79,1.49-1.18,3.2-1.18,5.13s.39,3.71,1.18,5.2c.78,1.49,1.87,2.66,3.27,3.49,1.39,.84,3,1.25,4.82,1.25h.01Zm8.81,8.44v-9.87l1.59-8.96-1.59-8.81v-9.04h9.87v36.69h-9.87Z"
					/>
					<path
						className="w"
						d="M543.24,403.44v-36.69h9.95v36.69h-9.95Zm9.95-20.28l-3.87-2.58c.46-4.51,1.77-8.06,3.95-10.67,2.18-2.61,5.32-3.91,9.42-3.91,1.77,0,3.37,.29,4.79,.87s2.71,1.53,3.87,2.85l-6.23,7.14c-.56-.61-1.23-1.06-2.01-1.37-.79-.3-1.68-.46-2.7-.46-2.13,0-3.86,.67-5.2,2.01s-2.01,3.38-2.01,6.11h0Z"
					/>
					<path
						className="w"
						d="M587.45,404.2c-3.39,0-6.43-.84-9.11-2.51s-4.8-3.95-6.34-6.84c-1.55-2.89-2.32-6.13-2.32-9.72s.77-6.91,2.32-9.8c1.54-2.89,3.65-5.17,6.3-6.84,2.66-1.67,5.71-2.51,9.15-2.51,2.63,0,5,.53,7.1,1.59s3.8,2.54,5.09,4.44c1.29,1.9,1.99,4.04,2.09,6.42v13.06c-.1,2.38-.79,4.53-2.05,6.46-1.27,1.92-2.96,3.44-5.09,4.56-2.13,1.11-4.51,1.67-7.14,1.67v.02Zm1.67-9.19c1.87,0,3.51-.42,4.9-1.25s2.48-2,3.27-3.49c.78-1.49,1.18-3.23,1.18-5.2s-.39-3.63-1.18-5.13c-.79-1.49-1.87-2.66-3.27-3.49-1.39-.84-3-1.25-4.82-1.25s-3.51,.43-4.9,1.29c-1.39,.86-2.48,2.03-3.27,3.49-.79,1.47-1.18,3.17-1.18,5.09s.39,3.71,1.18,5.2c.78,1.49,1.89,2.66,3.3,3.49,1.42,.84,3.01,1.25,4.79,1.25h0Zm18.68,8.43h-9.87v-9.87l1.59-8.96-1.67-8.81v-27.27h9.95v54.91h0Z"
					/>
				</g>
				<path
					className="f"
					d="M152.63,372.29c6.01,0,10.89,4.87,10.89,10.89s-4.87,10.89-10.89,10.89-10.89-4.87-10.89-10.89"
				/>
				<path
					className="f"
					d="M152.63,358.51c13.62,0,24.67,11.04,24.67,24.67s-11.04,24.67-24.67,24.67c-11.82,0-21.7-8.32-24.11-19.42"
				/>
				<path className="f" d="M152.63,372.29h-24.67s-.66,4.88-.54,9.17c.13,4.36,1.42,8.28,1.42,8.28" />
				<line className="f" x1="152.63" y1="358.51" x2="125.56" y2="358.51" />
				<polyline className="f" points="127.97 360.84 127.97 335.3 141.75 335.3 141.75 383.63" />
			</g>
			<g>
				<path
					className="v"
					d="M46.94,157.72h100.06c14.73,0,26.68,11.96,26.68,26.68v126.22H47.67c-14.84,0-26.89-12.05-26.89-26.89v-99.86c0-14.44,11.72-26.15,26.15-26.15h0Z"
				/>
				<g>
					<circle className="j" cx="95.02" cy="234.17" r="48.12" />
					<circle className="j" cx="95.02" cy="234.17" r="28.64" />
					<circle className="j" cx="95.02" cy="234.17" r="7.12" />
				</g>
			</g>
			<g>
				<rect className="q" x="188.86" y="11.75" width="331.74" height="83.96" rx="16.46" ry="16.46" />
				<g>
					<path
						className="z"
						d="M221.43,47.46c-2.25-.65-3.89-1.44-4.92-2.39s-1.54-2.11-1.54-3.49c0-1.57,.63-2.86,1.88-3.89s2.88-1.54,4.89-1.54c1.37,0,2.59,.26,3.66,.79,1.07,.53,1.9,1.26,2.49,2.19s.88,1.95,.88,3.05h-2.64c0-1.21-.38-2.16-1.15-2.85s-1.85-1.04-3.24-1.04c-1.29,0-2.3,.29-3.03,.86-.72,.57-1.09,1.36-1.09,2.38,0,.81,.34,1.5,1.03,2.06s1.86,1.08,3.51,1.54,2.95,.98,3.88,1.54c.93,.56,1.63,1.21,2.08,1.96s.68,1.63,.68,2.64c0,1.61-.63,2.91-1.89,3.88s-2.94,1.46-5.04,1.46c-1.37,0-2.64-.26-3.83-.79-1.19-.52-2.1-1.24-2.74-2.15s-.96-1.95-.96-3.1h2.64c0,1.2,.44,2.15,1.33,2.85s2.08,1.05,3.56,1.05c1.39,0,2.45-.28,3.19-.85,.74-.56,1.11-1.33,1.11-2.31s-.34-1.73-1.03-2.26-1.92-1.06-3.72-1.58h0Z"
					/>
					<path
						className="z"
						d="M234.52,37.96v3.58h2.76v1.96h-2.76v9.19c0,.59,.12,1.04,.37,1.33,.25,.3,.67,.44,1.26,.44,.29,0,.69-.05,1.2-.16v2.04c-.67,.18-1.31,.27-1.94,.27-1.13,0-1.98-.34-2.56-1.03s-.86-1.65-.86-2.91v-9.17h-2.69v-1.96h2.69v-3.58h2.53Z"
					/>
					<path
						className="z"
						d="M244.37,52.63l3.45-11.09h2.71l-5.95,17.08c-.92,2.46-2.38,3.69-4.39,3.69l-.48-.04-.94-.18v-2.05l.68,.05c.86,0,1.52-.17,2-.52s.87-.98,1.18-1.9l.56-1.5-5.28-14.63h2.76l3.69,11.09h.01Z"
					/>
					<path className="z" d="M254.83,56.33h-2.53v-21h2.53v21Z" />
					<path
						className="z"
						d="M264.31,56.61c-2.01,0-3.64-.66-4.89-1.98-1.26-1.32-1.89-3.08-1.89-5.28v-.46c0-1.47,.28-2.78,.84-3.93s1.34-2.05,2.35-2.71c1.01-.65,2.1-.98,3.27-.98,1.92,0,3.42,.63,4.48,1.9,1.07,1.27,1.6,3.08,1.6,5.44v1.05h-10.02c.04,1.46,.46,2.64,1.28,3.53,.82,.9,1.85,1.35,3.11,1.35,.89,0,1.65-.18,2.27-.55,.62-.36,1.16-.85,1.63-1.45l1.54,1.2c-1.24,1.91-3.1,2.86-5.58,2.86h.01Zm-.31-13.25c-1.02,0-1.88,.37-2.57,1.11s-1.12,1.78-1.29,3.12h7.41v-.19c-.07-1.29-.42-2.28-1.04-2.99-.62-.71-1.46-1.06-2.52-1.06h.01Z"
					/>
				</g>
				<g>
					<path
						className="z"
						d="M458.44,48.01c-1.11,1.18-2.41,1.76-3.9,1.76-1.75,0-3.14-.61-4.18-1.84-1.04-1.23-1.56-2.84-1.56-4.83,0-1.31,.26-2.5,.79-3.57s1.28-1.9,2.26-2.49,2.11-.89,3.39-.89c2,0,3.6,.75,4.77,2.24,1.18,1.5,1.76,3.5,1.76,6v.93c0,3.59-.81,6.33-2.43,8.22s-4.04,2.84-7.26,2.87h-.4v-2.75h.48c1.96-.03,3.46-.5,4.51-1.42,1.05-.92,1.64-2.33,1.76-4.25v.02Zm-3.19-.83c.67,0,1.29-.19,1.87-.57s1.03-.91,1.35-1.59v-1.3c0-1.47-.3-2.65-.9-3.54s-1.38-1.34-2.34-1.34-1.72,.39-2.29,1.18-.85,1.78-.85,2.99,.29,2.26,.86,3.02,1.34,1.15,2.3,1.15h0Z"
					/>
					<path
						className="z"
						d="M474.57,36.33v2.75h-.41c-1.86,.03-3.35,.54-4.46,1.53-1.11,.99-1.77,2.4-1.98,4.22,1.07-1.12,2.43-1.68,4.09-1.68,1.76,0,3.15,.62,4.16,1.86,1.02,1.24,1.52,2.84,1.52,4.8s-.59,3.66-1.78,4.92c-1.19,1.25-2.75,1.88-4.68,1.88s-3.6-.73-4.83-2.2c-1.24-1.47-1.85-3.39-1.85-5.77v-1.13c0-3.49,.85-6.22,2.55-8.2,1.7-1.98,4.14-2.97,7.33-2.97h.34Zm-3.57,9.49c-.73,0-1.4,.21-2,.62-.61,.41-1.05,.96-1.33,1.64v1.01c0,1.48,.31,2.65,.93,3.52s1.42,1.31,2.41,1.31,1.76-.37,2.34-1.11,.86-1.71,.86-2.91-.29-2.18-.88-2.94c-.58-.76-1.36-1.13-2.32-1.13h0Z"
					/>
					<path
						className="z"
						d="M479.31,40.25c0-1.2,.39-2.19,1.16-2.96s1.79-1.16,3.05-1.16,2.3,.39,3.07,1.16c.77,.77,1.16,1.78,1.16,3.03v.98c0,1.19-.39,2.17-1.16,2.94-.77,.77-1.78,1.15-3.04,1.15s-2.24-.38-3.04-1.13c-.8-.75-1.2-1.77-1.2-3.04v-.97h0Zm2.32,1.05c0,.63,.18,1.14,.53,1.53s.81,.58,1.39,.58,1.02-.19,1.37-.58c.35-.39,.52-.92,.52-1.58v-1c0-.63-.18-1.14-.53-1.54-.35-.39-.81-.59-1.39-.59s-1.03,.2-1.37,.6-.51,.92-.51,1.57v1.01h0Zm2.6,13.54l-1.71-.98,9.72-15.56,1.71,.98-9.72,15.56Zm4.65-3.39c0-1.22,.4-2.21,1.19-2.97,.79-.76,1.8-1.13,3.04-1.13s2.25,.38,3.04,1.13c.79,.76,1.18,1.78,1.18,3.06v.97c0,1.19-.38,2.16-1.14,2.93s-1.78,1.16-3.06,1.16-2.3-.38-3.08-1.14-1.17-1.77-1.17-3.01v-1Zm2.32,1.05c0,.62,.18,1.13,.54,1.52,.36,.4,.82,.59,1.39,.59s1.04-.19,1.37-.57,.5-.91,.5-1.59v-1.03c0-.64-.18-1.15-.53-1.53s-.81-.57-1.37-.57-1.02,.19-1.37,.57-.53,.91-.53,1.59v1.01h0Z"
					/>
				</g>
				<g>
					<line className="m" x1="216.56" y1="72.13" x2="492.5" y2="72.13" />
					<line className="l" x1="216.56" y1="72.13" x2="481.49" y2="72.13" />
				</g>
			</g>
			<g>
				<rect className="r" x="351.52" y="432.52" width="369.37" height="107.38" rx="19.65" ry="19.65" />
				<g>
					<path
						className="z"
						d="M408.02,478.85c0,2.41-.4,4.51-1.21,6.3-.81,1.79-1.96,3.16-3.44,4.11-1.49,.95-3.22,1.42-5.2,1.42s-3.65-.47-5.15-1.43c-1.5-.95-2.66-2.31-3.48-4.07-.83-1.76-1.25-3.8-1.27-6.12v-1.77c0-2.36,.41-4.45,1.23-6.26s1.98-3.2,3.49-4.16,3.22-1.44,5.16-1.44,3.7,.47,5.21,1.43,2.66,2.33,3.47,4.13c.81,1.8,1.21,3.91,1.21,6.3v1.55h-.02Zm-3.22-1.58c0-2.91-.59-5.15-1.76-6.71s-2.81-2.34-4.91-2.34-3.66,.78-4.84,2.34-1.78,3.72-1.81,6.49v1.81c0,2.82,.59,5.04,1.78,6.66,1.19,1.61,2.82,2.42,4.9,2.42s3.71-.76,4.86-2.29,1.74-3.71,1.77-6.56v-1.82h.01Z"
					/>
					<path
						className="z"
						d="M414.48,472.09l.1,2.29c1.39-1.75,3.22-2.63,5.47-2.63,3.86,0,5.81,2.18,5.84,6.53v12.07h-3.12v-12.08c-.01-1.32-.31-2.29-.9-2.92s-1.51-.95-2.76-.95c-1.01,0-1.9,.27-2.67,.81s-1.36,1.25-1.79,2.13v13.01h-3.12v-18.26h2.95Z"
					/>
					<path className="z" d="M436.23,481.18h-8.24v-2.55h8.24v2.55Z" />
					<path
						className="z"
						d="M438.89,490.35v-24.57h8.03c2.67,0,4.67,.55,6.02,1.65,1.34,1.1,2.02,2.73,2.02,4.89,0,1.15-.33,2.16-.98,3.05-.65,.88-1.54,1.57-2.67,2.05,1.33,.37,2.38,1.08,3.15,2.12s1.16,2.28,1.16,3.72c0,2.21-.71,3.94-2.14,5.2-1.43,1.26-3.45,1.89-6.06,1.89h-8.53Zm3.24-14.09h4.89c1.42,0,2.55-.35,3.4-1.06s1.27-1.67,1.27-2.89c0-1.35-.39-2.34-1.18-2.95-.79-.61-1.99-.92-3.59-.92h-4.79v7.83h0Zm0,2.59v8.84h5.35c1.51,0,2.7-.39,3.57-1.17s1.31-1.86,1.31-3.23c0-2.96-1.61-4.44-4.83-4.44h-5.4Z"
					/>
					<path
						className="z"
						d="M467.88,474.89c-.47-.08-.98-.12-1.54-.12-2.05,0-3.44,.87-4.17,2.62v12.96h-3.12v-18.26h3.04l.05,2.11c1.02-1.63,2.48-2.45,4.35-2.45,.61,0,1.07,.08,1.38,.24v2.9h.01Z"
					/>
					<path
						className="z"
						d="M480.48,490.35c-.18-.36-.33-1-.44-1.92-1.45,1.51-3.18,2.26-5.2,2.26-1.8,0-3.28-.51-4.43-1.53s-1.73-2.31-1.73-3.87c0-1.9,.72-3.38,2.17-4.43s3.48-1.58,6.1-1.58h3.04v-1.43c0-1.09-.33-1.96-.98-2.61-.65-.65-1.61-.97-2.89-.97-1.11,0-2.05,.28-2.8,.84s-1.13,1.24-1.13,2.04h-3.14c0-.91,.32-1.79,.97-2.64s1.52-1.52,2.63-2.02c1.11-.5,2.33-.74,3.65-.74,2.1,0,3.75,.53,4.94,1.58s1.81,2.5,1.86,4.35v8.4c0,1.68,.21,3.01,.64,4v.27h-3.26Zm-5.18-2.38c.98,0,1.91-.25,2.78-.76,.88-.51,1.51-1.16,1.91-1.97v-3.75h-2.45c-3.83,0-5.74,1.12-5.74,3.36,0,.98,.33,1.74,.98,2.29,.65,.55,1.49,.83,2.51,.83h0Z"
					/>
					<path
						className="z"
						d="M490.1,472.09l.1,2.29c1.39-1.75,3.22-2.63,5.47-2.63,3.86,0,5.81,2.18,5.84,6.53v12.07h-3.12v-12.08c-.01-1.32-.31-2.29-.9-2.92s-1.51-.95-2.76-.95c-1.01,0-1.9,.27-2.67,.81s-1.36,1.25-1.79,2.13v13.01h-3.12v-18.26h2.95Z"
					/>
					<path
						className="z"
						d="M504.59,481.06c0-2.8,.66-5.05,1.99-6.76,1.33-1.7,3.07-2.56,5.21-2.56s3.83,.73,5.08,2.19v-9.52h3.12v25.92h-2.87l-.15-1.96c-1.25,1.53-2.99,2.29-5.21,2.29s-3.84-.87-5.17-2.6c-1.33-1.73-2-3.99-2-6.78v-.22Zm3.12,.35c0,2.07,.43,3.69,1.28,4.86s2.04,1.75,3.54,1.75c1.98,0,3.43-.89,4.34-2.67v-8.39c-.93-1.72-2.37-2.58-4.3-2.58-1.53,0-2.72,.59-3.58,1.77s-1.28,2.93-1.28,5.25h0Z"
					/>
				</g>
				<g>
					<path
						className="z"
						d="M637.7,480.07c-1.37,1.45-2.98,2.18-4.81,2.18-2.16,0-3.88-.76-5.16-2.27-1.28-1.51-1.92-3.5-1.92-5.97,0-1.62,.33-3.09,.98-4.4,.65-1.32,1.58-2.34,2.78-3.07s2.6-1.1,4.19-1.1c2.48,0,4.44,.92,5.89,2.77,1.45,1.84,2.18,4.31,2.18,7.41v1.15c0,4.43-1,7.81-3,10.14-2,2.33-4.99,3.51-8.96,3.54h-.49v-3.39h.59c2.42-.03,4.27-.62,5.57-1.75s2.02-2.88,2.18-5.24h-.02Zm-3.95-1.03c.82,0,1.59-.24,2.31-.71s1.28-1.12,1.67-1.96v-1.6c0-1.81-.37-3.27-1.11-4.37s-1.7-1.65-2.89-1.65-2.12,.49-2.83,1.46c-.7,.97-1.06,2.2-1.06,3.69s.35,2.78,1.06,3.73,1.65,1.42,2.83,1.42h.02Z"
					/>
					<path
						className="z"
						d="M658.72,481.59h3.05v3.29h-3.05v5.47h-4.1v-5.47h-10.62l-.12-2.5,10.6-16.61h4.24v15.81h0Zm-10.53,0h6.43v-10.26l-.3,.54-6.13,9.72Z"
					/>
					<path
						className="z"
						d="M663.46,470.5c0-1.49,.48-2.7,1.43-3.65,.96-.95,2.21-1.43,3.76-1.43s2.84,.48,3.79,1.43c.95,.95,1.43,2.2,1.43,3.74v1.21c0,1.47-.48,2.68-1.43,3.63s-2.2,1.42-3.75,1.42-2.77-.46-3.75-1.39c-.98-.93-1.48-2.18-1.48-3.76v-1.2h0Zm2.87,1.29c0,.78,.22,1.41,.65,1.89,.43,.48,1,.72,1.71,.72s1.26-.24,1.69-.72,.64-1.13,.64-1.96v-1.23c0-.78-.22-1.41-.65-1.9s-1-.73-1.71-.73-1.27,.25-1.7,.74c-.42,.49-.63,1.14-.63,1.94v1.25Zm3.21,16.71l-2.11-1.22,12-19.21,2.11,1.21-12,19.21h0Zm5.74-4.18c0-1.51,.49-2.73,1.47-3.66s2.23-1.4,3.75-1.4,2.78,.47,3.76,1.4c.97,.93,1.46,2.19,1.46,3.78v1.2c0,1.46-.47,2.67-1.41,3.62s-2.2,1.43-3.77,1.43-2.84-.47-3.81-1.41c-.96-.94-1.44-2.18-1.44-3.72v-1.23h-.01Zm2.87,1.29c0,.77,.22,1.39,.67,1.88,.44,.49,1.02,.73,1.71,.73s1.29-.23,1.7-.7c.41-.47,.62-1.12,.62-1.97v-1.27c0-.79-.22-1.42-.66-1.89s-1-.71-1.69-.71-1.26,.24-1.7,.71-.65,1.12-.65,1.96v1.25h0Z"
					/>
				</g>
				<g>
					<line className="i" x1="390.33" y1="509.85" x2="683.19" y2="509.85" />
					<line className="g" x1="390.33" y1="509.85" x2="640.67" y2="509.85" />
				</g>
			</g>
			<g>
				<path
					className="v"
					d="M356.9,639.25h-87.26c-12.84,0-23.27-10.43-23.27-23.27v-110.06h109.88c12.94,0,23.45,10.51,23.45,23.45v87.08c0,12.59-10.22,22.81-22.81,22.81h.01Z"
				/>
				<g>
					<polyline className="h" points="302.76 573.61 310.3 581.73 328.43 563.44" />
					<rect className="j" x="284.39" y="543.93" width="57.32" height="57.32" rx="5.2" ry="5.2" />
				</g>
			</g>
			<g>
				<path
					className="y"
					d="M259.05,204.07h-96.25V107.66c0-11.25,9.13-20.38,20.38-20.38h76.43c11.03,0,19.98,8.95,19.98,19.98v76.28c0,11.33-9.2,20.54-20.54,20.54h0Z"
				/>
				<path
					className="e"
					d="M250.04,143.2c-.08-2.43-1.53-4.18-3.9-4.76-.73-.18-1.47-.33-2.21-.4-.65-.07-.95-.4-1.24-.96-.46-.91-.48-1.65,.25-2.43,.43-.46,.76-1.03,1.09-1.57,1.13-1.9,.95-4.22-.54-5.85-1.19-1.3-2.44-2.56-3.75-3.75-1.76-1.61-4.13-1.75-6.14-.47-.63,.41-1.2,.93-1.86,1.27-.37,.19-.88,.22-1.31,.15-1.28-.19-1.79-1.04-1.82-2.28,0-.41-.13-.82-.22-1.22-.51-2.37-2.31-3.97-4.75-4.08-1.69-.07-3.39-.07-5.08,0-2.48,.11-4.28,1.73-4.77,4.19-.13,.66-.28,1.33-.35,2-.06,.64-.38,.96-.95,1.24-.91,.45-1.66,.46-2.43-.26-.46-.43-1.02-.77-1.57-1.1-1.84-1.09-4.08-.96-5.67,.46-1.4,1.25-2.74,2.58-4,3.97-1.53,1.7-1.67,3.88-.48,5.85,.43,.71,.96,1.35,1.39,2.05,.18,.29,.33,.7,.28,1.01-.28,1.47-.64,1.77-2.12,2-.45,.07-.89,.14-1.33,.23-1.98,.39-3.29,1.54-3.96,3.44-.07,.2-.19,.39-.29,.59v6.54c.14,.3,.29,.59,.41,.9,.66,1.66,1.88,2.68,3.6,3.09,.55,.13,1.11,.27,1.66,.27,1.36,0,1.88,.88,2.15,1.99,.07,.28-.05,.66-.2,.91-.41,.67-.92,1.28-1.34,1.94-1.3,2.06-1.14,4.33,.5,6.12,1.17,1.27,2.39,2.5,3.66,3.66,1.79,1.64,4.06,1.79,6.12,.49,.51-.32,.99-.68,1.48-1.03,1.55-1.1,2.92-.51,3.25,1.36,.15,.85,.29,1.7,.56,2.52,.62,1.85,2.16,2.68,3.87,3.28h6.32c2.87-.82,4.2-2.83,4.42-5.7,.02-.22,0-.48,.1-.66,.18-.33,.39-.71,.69-.92,.96-.65,1.91-.71,2.8,.21,.33,.34,.78,.59,1.19,.85,2.05,1.31,4.44,1.14,6.23-.53,1.24-1.15,2.42-2.37,3.59-3.59,.99-1.03,1.37-2.28,1.32-3.71-.06-1.63-1.01-2.81-1.92-3.99-.49-.64-.59-1.16-.27-1.93,.35-.82,.75-1.31,1.7-1.33,.59,0,1.19-.18,1.77-.32,2.47-.58,3.96-2.35,4.05-4.89,.06-1.62,.05-3.24,0-4.85h.02Zm-20.91,2.54c-.07,4.31-3.74,7.91-7.99,7.84-4.31-.07-7.91-3.74-7.84-7.99,.07-4.31,3.74-7.91,7.99-7.84,4.31,.07,7.91,3.74,7.84,7.99Z"
				/>
			</g>
			<g>
				<rect className="x" x="384.21" y="115.53" width="221.45" height="204.61" rx="21" ry="21" />
				<g>
					<g>
						<path
							className="p"
							d="M458.28,272.41c0,1.75-.3,3.29-.91,4.62-.61,1.32-1.47,2.34-2.6,3.05s-2.42,1.06-3.89,1.06-2.74-.35-3.88-1.06c-1.13-.71-2.01-1.72-2.63-3.03s-.93-2.82-.94-4.54v-1.01c0-1.75,.31-3.29,.93-4.62,.62-1.34,1.49-2.36,2.62-3.07s2.42-1.06,3.88-1.06,2.75,.35,3.88,1.05,2,1.71,2.61,3.02c.61,1.32,.93,2.85,.93,4.59v1h0Zm-3.11-.93c0-1.98-.38-3.5-1.12-4.56-.75-1.06-1.81-1.59-3.19-1.59s-2.4,.53-3.15,1.58c-.76,1.05-1.15,2.54-1.16,4.47v1.03c0,1.97,.38,3.49,1.15,4.56s1.83,1.61,3.19,1.61,2.44-.52,3.18-1.57c.74-1.05,1.11-2.58,1.11-4.6v-.93h0Z"
						/>
						<path
							className="p"
							d="M463.86,267.59l.09,1.54c.98-1.19,2.27-1.78,3.87-1.78,2.77,0,4.18,1.59,4.23,4.76v8.79h-2.99v-8.62c0-.84-.18-1.47-.55-1.88s-.96-.61-1.79-.61c-1.21,0-2.1,.55-2.69,1.64v9.47h-2.99v-13.3h2.82Z"
						/>
						<path className="p" d="M480.89,274.49h-6.45v-2.41h6.45v2.41Z" />
						<path
							className="p"
							d="M483.64,280.9v-17.9h6.14c2.02,0,3.57,.41,4.62,1.22,1.06,.81,1.59,2.02,1.59,3.63,0,.82-.22,1.56-.66,2.21-.44,.66-1.09,1.16-1.94,1.52,.97,.26,1.72,.76,2.24,1.48s.79,1.6,.79,2.61c0,1.68-.54,2.97-1.62,3.87s-2.62,1.35-4.63,1.35h-6.53Zm3.11-10.35h3.05c.97,0,1.72-.22,2.27-.66,.54-.44,.82-1.06,.82-1.86,0-.88-.25-1.52-.76-1.92-.5-.39-1.29-.59-2.35-.59h-3.03v5.03h0Zm0,2.29v5.59h3.46c.98,0,1.74-.24,2.29-.73,.55-.48,.82-1.16,.82-2.02,0-1.86-.95-2.81-2.85-2.85h-3.72Z"
						/>
						<path
							className="p"
							d="M506.2,270.32c-.39-.07-.8-.1-1.22-.1-1.37,0-2.29,.52-2.77,1.57v9.1h-2.99v-13.3h2.85l.07,1.49c.72-1.16,1.72-1.73,3-1.73,.43,0,.78,.06,1.06,.17v2.8h0Z"
						/>
						<path
							className="p"
							d="M515.67,280.9c-.13-.25-.25-.67-.34-1.24-.95,.99-2.11,1.49-3.49,1.49s-2.43-.38-3.27-1.14-1.27-1.71-1.27-2.83c0-1.42,.53-2.51,1.58-3.26,1.05-.76,2.56-1.14,4.52-1.14h1.83v-.87c0-.69-.19-1.24-.58-1.65s-.97-.62-1.76-.62c-.68,0-1.24,.17-1.67,.51s-.65,.77-.65,1.3h-2.99c0-.73,.24-1.41,.72-2.05,.48-.64,1.14-1.13,1.97-1.49s1.76-.54,2.79-.54c1.56,0,2.8,.39,3.72,1.17,.93,.78,1.4,1.88,1.43,3.3v6c0,1.2,.17,2.15,.5,2.86v.21h-3.04Zm-3.28-2.16c.59,0,1.15-.14,1.67-.43s.91-.67,1.17-1.16v-2.51h-1.61c-1.11,0-1.94,.19-2.5,.58-.56,.39-.84,.93-.84,1.64,0,.57,.19,1.03,.57,1.37s.89,.51,1.53,.51h.01Z"
						/>
						<path
							className="p"
							d="M524.11,267.59l.09,1.54c.98-1.19,2.27-1.78,3.87-1.78,2.77,0,4.18,1.59,4.23,4.76v8.79h-2.99v-8.62c0-.84-.18-1.47-.55-1.88s-.96-.61-1.79-.61c-1.21,0-2.1,.55-2.69,1.64v9.47h-2.99v-13.3h2.82Z"
						/>
						<path
							className="p"
							d="M534.79,274.15c0-2.05,.47-3.69,1.43-4.94,.95-1.24,2.23-1.86,3.82-1.86,1.41,0,2.55,.49,3.42,1.48v-6.81h2.99v18.88h-2.71l-.15-1.38c-.89,1.08-2.09,1.62-3.58,1.62s-2.82-.63-3.78-1.88-1.44-2.96-1.44-5.12h0Zm2.99,.27c0,1.35,.26,2.41,.78,3.17s1.26,1.14,2.22,1.14c1.22,0,2.11-.54,2.68-1.63v-5.68c-.55-1.07-1.43-1.6-2.66-1.6-.97,0-1.71,.38-2.24,1.15-.53,.77-.79,1.92-.79,3.46h0Z"
						/>
					</g>
					<g>
						<polyline className="c" points="479.7 199.59 489.39 210.03 512.72 186.51" />
						<circle className="c" cx="494.93" cy="199.07" r="44.55" />
					</g>
				</g>
			</g>
			<g>
				<rect className="o" x="35.54" y="615.61" width="250.62" height="63.43" rx="12.44" ry="12.44" />
				<g>
					<path
						className="z"
						d="M80.17,645.02c-.18,1.5-.73,2.66-1.66,3.48s-2.17,1.22-3.72,1.22c-1.68,0-3.02-.6-4.03-1.8s-1.52-2.81-1.52-4.83v-1.37c0-1.32,.24-2.48,.71-3.48s1.14-1.77,2-2.31,1.87-.81,3-.81c1.51,0,2.72,.42,3.63,1.26,.91,.84,1.44,2.01,1.59,3.5h-1.88c-.16-1.13-.52-1.96-1.06-2.47-.54-.51-1.3-.76-2.28-.76-1.2,0-2.14,.44-2.81,1.33-.68,.88-1.02,2.14-1.02,3.78v1.38c0,1.54,.32,2.77,.97,3.68,.64,.91,1.54,1.37,2.7,1.37,1.04,0,1.84-.24,2.4-.71,.56-.47,.93-1.29,1.11-2.46h1.87Z"
					/>
					<path
						className="z"
						d="M81.47,644.15c0-1.03,.2-1.96,.61-2.79,.41-.83,.97-1.46,1.7-1.91,.73-.45,1.55-.67,2.48-.67,1.44,0,2.6,.5,3.49,1.49s1.33,2.32,1.33,3.97v.13c0,1.03-.2,1.95-.59,2.77s-.96,1.45-1.69,1.91c-.73,.46-1.57,.68-2.52,.68-1.43,0-2.59-.5-3.48-1.49s-1.33-2.31-1.33-3.95v-.13h0Zm1.81,.2c0,1.17,.27,2.11,.81,2.82s1.27,1.06,2.18,1.06,1.65-.36,2.19-1.08c.54-.72,.81-1.73,.81-3.02,0-1.16-.27-2.1-.82-2.81-.55-.72-1.28-1.08-2.19-1.08s-1.61,.35-2.16,1.06-.82,1.72-.82,3.04h0Z"
					/>
					<path
						className="z"
						d="M94.54,638.97l.05,1.17c.77-.91,1.82-1.37,3.13-1.37,1.48,0,2.48,.57,3.01,1.7,.35-.51,.81-.92,1.37-1.23,.56-.31,1.23-.47,2-.47,2.31,0,3.49,1.23,3.53,3.68v7.07h-1.8v-6.96c0-.75-.17-1.32-.52-1.69s-.92-.56-1.74-.56c-.67,0-1.23,.2-1.67,.6-.44,.4-.7,.94-.77,1.62v7h-1.81v-6.92c0-1.54-.75-2.3-2.25-2.3-1.18,0-1.99,.5-2.43,1.51v7.71h-1.8v-10.56h1.7Z"
					/>
					<path
						className="z"
						d="M118.77,644.36c0,1.61-.37,2.9-1.1,3.88-.74,.98-1.73,1.47-2.99,1.47s-2.29-.41-3.02-1.22v5.08h-1.8v-14.61h1.65l.09,1.17c.74-.91,1.76-1.37,3.06-1.37s2.27,.48,3.01,1.43c.74,.96,1.11,2.29,1.11,3.99v.17h-.01Zm-1.8-.2c0-1.19-.25-2.13-.76-2.82s-1.2-1.03-2.09-1.03c-1.09,0-1.91,.48-2.46,1.45v5.04c.54,.96,1.37,1.44,2.48,1.44,.86,0,1.55-.34,2.06-1.03,.51-.69,.77-1.71,.77-3.06h0Z"
					/>
					<path className="z" d="M122.52,649.52h-1.8v-14.98h1.8v14.98Z" />
					<path
						className="z"
						d="M124.92,636.17c0-.29,.09-.54,.27-.74s.44-.3,.79-.3,.62,.1,.8,.3,.27,.45,.27,.74-.09,.54-.27,.73c-.18,.2-.45,.29-.8,.29s-.62-.1-.79-.29c-.18-.19-.27-.44-.27-.73Zm1.95,13.36h-1.8v-10.56h1.8v10.56Z"
					/>
					<path
						className="z"
						d="M135.78,649.52c-.1-.21-.19-.58-.25-1.11-.84,.87-1.84,1.31-3,1.31-1.04,0-1.89-.29-2.56-.88s-1-1.33-1-2.24c0-1.1,.42-1.95,1.25-2.56s2.01-.91,3.53-.91h1.76v-.83c0-.63-.19-1.13-.57-1.51-.38-.37-.93-.56-1.67-.56-.64,0-1.18,.16-1.62,.49s-.65,.72-.65,1.18h-1.81c0-.53,.19-1.04,.56-1.53s.88-.88,1.52-1.17,1.34-.43,2.11-.43c1.22,0,2.17,.3,2.86,.91s1.05,1.45,1.07,2.51v4.86c0,.97,.12,1.74,.37,2.31v.16h-1.9Zm-2.99-1.38c.57,0,1.1-.15,1.61-.44s.88-.67,1.1-1.14v-2.17h-1.41c-2.21,0-3.32,.65-3.32,1.94,0,.57,.19,1.01,.57,1.33s.86,.48,1.45,.48Z"
					/>
					<path
						className="z"
						d="M141.34,638.97l.06,1.33c.81-1.01,1.86-1.52,3.16-1.52,2.23,0,3.36,1.26,3.38,3.78v6.97h-1.8v-6.98c0-.76-.18-1.32-.52-1.69-.34-.36-.87-.55-1.6-.55-.59,0-1.1,.16-1.54,.47-.44,.31-.79,.72-1.03,1.23v7.52h-1.8v-10.56h1.69Z"
					/>
					<path
						className="z"
						d="M154.39,648.24c.64,0,1.21-.2,1.69-.59s.75-.88,.8-1.46h1.71c-.03,.6-.24,1.18-.62,1.73s-.9,.98-1.54,1.31c-.64,.32-1.32,.49-2.03,.49-1.44,0-2.58-.48-3.43-1.44-.85-.96-1.27-2.27-1.27-3.94v-.3c0-1.03,.19-1.94,.57-2.74s.92-1.42,1.62-1.86c.71-.44,1.54-.66,2.5-.66,1.18,0,2.17,.35,2.95,1.06s1.2,1.63,1.25,2.76h-1.71c-.05-.68-.31-1.24-.78-1.68s-1.04-.66-1.72-.66c-.92,0-1.63,.33-2.13,.99s-.76,1.61-.76,2.86v.34c0,1.22,.25,2.15,.75,2.81s1.22,.99,2.15,.99h0Z"
					/>
					<path
						className="z"
						d="M164.49,649.72c-1.43,0-2.6-.47-3.49-1.41-.9-.94-1.35-2.2-1.35-3.77v-.33c0-1.05,.2-1.98,.6-2.8,.4-.82,.96-1.47,1.68-1.93s1.5-.7,2.34-.7c1.37,0,2.44,.45,3.2,1.36,.76,.9,1.14,2.2,1.14,3.88v.75h-7.15c.03,1.04,.33,1.88,.91,2.52s1.32,.96,2.22,.96c.64,0,1.18-.13,1.62-.39s.83-.6,1.16-1.03l1.1,.86c-.88,1.36-2.21,2.04-3.98,2.04h0Zm-.22-9.47c-.73,0-1.34,.26-1.83,.79s-.8,1.27-.92,2.23h5.29v-.14c-.05-.92-.3-1.63-.74-2.13s-1.04-.76-1.8-.76h0Z"
					/>
				</g>
				<g>
					<path
						className="z"
						d="M245.03,643.58c-.79,.84-1.72,1.26-2.78,1.26-1.25,0-2.24-.44-2.99-1.31-.74-.88-1.11-2.02-1.11-3.45,0-.94,.19-1.79,.57-2.55s.91-1.35,1.61-1.78c.7-.42,1.5-.63,2.42-.63,1.43,0,2.57,.53,3.4,1.6s1.26,2.49,1.26,4.28v.66c0,2.56-.58,4.52-1.74,5.86-1.16,1.35-2.88,2.03-5.18,2.05h-.28v-1.96h.34c1.4-.02,2.47-.36,3.22-1.01s1.17-1.66,1.26-3.03h0Zm-2.28-.58c.48,0,.92-.14,1.34-.41,.42-.27,.74-.65,.97-1.13v-.93c0-1.05-.21-1.89-.64-2.53s-.99-.96-1.67-.96-1.23,.28-1.63,.84c-.41,.56-.61,1.27-.61,2.13s.21,1.61,.61,2.16,.96,.82,1.64,.82h0Z"
					/>
					<path
						className="z"
						d="M258.42,643.54c0,2.04-.38,3.58-1.15,4.62-.76,1.04-1.93,1.56-3.49,1.56s-2.69-.51-3.47-1.52-1.17-2.51-1.19-4.49v-2.46c0-2.04,.38-3.57,1.15-4.59,.76-1.02,1.93-1.53,3.5-1.53s2.71,.5,3.48,1.49c.76,1,1.16,2.48,1.18,4.47v2.46h0Zm-2.37-2.66c0-1.33-.18-2.31-.55-2.93s-.94-.93-1.74-.93-1.34,.29-1.71,.88c-.36,.59-.56,1.51-.58,2.76v3.22c0,1.33,.19,2.32,.56,2.97,.37,.65,.95,.98,1.75,.98s1.32-.3,1.69-.9,.56-1.54,.58-2.82v-3.23Z"
					/>
					<path
						className="z"
						d="M259.92,638.05c0-.86,.28-1.56,.83-2.11s1.28-.82,2.18-.82,1.64,.28,2.19,.82c.55,.55,.82,1.27,.82,2.16v.7c0,.85-.28,1.55-.82,2.1-.55,.55-1.27,.82-2.17,.82s-1.6-.27-2.17-.8c-.57-.54-.85-1.26-.85-2.17v-.69h0Zm1.66,.74c0,.45,.13,.81,.38,1.09s.58,.42,.99,.42,.73-.14,.98-.42,.37-.65,.37-1.13v-.71c0-.45-.13-.81-.38-1.09s-.58-.42-.99-.42-.74,.14-.98,.43c-.24,.28-.37,.66-.37,1.12v.72h0Zm1.85,9.65l-1.22-.7,6.94-11.1,1.22,.7-6.94,11.1Zm3.32-2.42c0-.87,.28-1.58,.85-2.12s1.29-.81,2.17-.81,1.61,.27,2.17,.81,.84,1.27,.84,2.19v.69c0,.85-.27,1.54-.81,2.09-.54,.55-1.27,.82-2.18,.82s-1.64-.27-2.2-.81-.83-1.26-.83-2.15v-.71h-.01Zm1.66,.75c0,.44,.13,.8,.39,1.09s.59,.42,.99,.42,.74-.14,.98-.41,.36-.65,.36-1.14v-.73c0-.46-.13-.82-.38-1.09s-.58-.41-.98-.41-.73,.14-.98,.41c-.25,.27-.38,.65-.38,1.13v.72h0Z"
					/>
				</g>
				<g>
					<line className="d" x1="68.08" y1="660.8" x2="264.98" y2="660.8" />
					<line className="n" x1="68.08" y1="660.8" x2="247.62" y2="660.8" />
				</g>
			</g>
		</svg.SVG>
	)
}

export default Icon

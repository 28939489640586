import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as imagery from "brandguide/build/imagery"
import { Link } from "react-router-dom"
import { results } from "brandguide/build/layouts"
import * as navigation from "brandguide/build/navigation"
import * as icons from "icons"
import * as brands from "brands"
import * as authz from "authz"
import * as authzc from "authzcached"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideImagery | undefined): void
	onLoad(): void
}

export function SectionResult(props: props): JSX.Element {
	const { onChange, onLoad, ...rest } = props
	const guide = brandguide.cache.useGuide()
	const data = brandguide.api.zeros.guide.guideImageryZero(guide.guide?.imagery)
	const brand = brands.caching.useCached()

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	const _loadedImages =
		(data.lifestyle?.items || []).length +
		(data.product?.items || []).length +
		(data.illustration?.items || []).length +
		(data.misuse || []).length

	const [loadedImages, setLoadedImages] = useState(_loadedImages)

	useEffect(() => {
		if (loadedImages !== 0) return
		onLoad()
	}, [loadedImages])

	return (
		<layouts.Flex flex="1" {...rest}>
			<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
				<results.Title>Imagery</results.Title>
				<results.ResultDesc>
					Please follow these guidelines when working with imagery for {brand.description}.
				</results.ResultDesc>

				{brandguide.validations.imagery.enabled.lifestyle(data) && (
					<results.SectionContainer>
						<results.Title>Lifestyle Photography</results.Title>
						<results.Explanation desc={data.descriptions?.lifestyle} />

						<layouts.containers.grid
							gridTemplateColumns="repeat(auto-fill, minmax(150px, 150px))"
							gridTemplateRows="repeat(auto-fill, minmax(150px, 150px))"
							gap="5px"
							width="100%"
						>
							{(data.lifestyle?.items || []).map((i) => (
								<layouts.Flex key={i.id} width="150px" height="150px" alignItems="center" justifyContent="center">
									<brandguide.build.layouts.B64ImageForPDF
										contentdigest={i.thumbnail_contentdigest}
										styles={{ maxWidth: "150px", maxHeight: "150px" }}
										onLoad={() => setLoadedImages((prev) => prev - 1)}
									/>
								</layouts.Flex>
							))}
						</layouts.containers.grid>
					</results.SectionContainer>
				)}

				{brandguide.validations.imagery.enabled.product(data) && (
					<results.SectionContainer>
						<results.Title>Product Photography</results.Title>
						<results.Explanation desc={data.descriptions?.product} />

						<layouts.containers.grid
							gridTemplateColumns="repeat(auto-fill, minmax(250px, 250px))"
							gridTemplateRows="repeat(auto-fill, minmax(250px, 250px))"
							gap="5px"
							width="100%"
						>
							{(data.product?.items || []).map((i) => (
								<layouts.Flex key={i.id} width="250px" height="250px" alignItems="center" justifyContent="center">
									<brandguide.build.layouts.B64ImageForPDF
										contentdigest={i.thumbnail_contentdigest}
										styles={{ maxWidth: "250px", maxHeight: "250px" }}
										onLoad={() => setLoadedImages((prev) => prev - 1)}
									/>
								</layouts.Flex>
							))}
						</layouts.containers.grid>
					</results.SectionContainer>
				)}

				{brandguide.validations.imagery.enabled.illustration(data) && (
					<results.SectionContainer>
						<results.Title>Illustration Style</results.Title>
						<results.Explanation desc={data.descriptions?.illustration} />

						<layouts.containers.grid
							gridTemplateColumns="repeat(auto-fill, minmax(250px, 250px))"
							gridTemplateRows="repeat(auto-fill, minmax(250px, 250px))"
							gap="5px"
							width="100%"
						>
							{(data.illustration?.items || []).map((i) => (
								<layouts.Flex key={i.id} width="250px" height="250px" alignItems="center" justifyContent="center">
									<brandguide.build.layouts.B64ImageForPDF
										contentdigest={i.thumbnail_contentdigest}
										styles={{ maxWidth: "250px", maxHeight: "250px" }}
										onLoad={() => setLoadedImages((prev) => prev - 1)}
									/>
								</layouts.Flex>
							))}
						</layouts.containers.grid>
					</results.SectionContainer>
				)}

				{(data.misuse || []).length > 0 && (
					<results.SectionContainer>
						<results.Title>Photography to Avoid</results.Title>
						<results.Explanation desc={data.descriptions?.misuse} />

						<layouts.containers.grid
							mt="20px"
							gridTemplateColumns="repeat(auto-fill, minmax(250px, 250px))"
							gridTemplateRows="repeat(auto-fill, minmax(275px, 250px))"
							gap="5px"
							width="100%"
						>
							{(data.misuse || []).map((i) => (
								<layouts.Flex key={i.id} width="250px" alignItems="start" justifyContent="end" flexDirection="column">
									<layouts.Flex position="relative">
										<layouts.containers.absolute right="0" p="3px">
											<icons.brandguide.actions.Reject
												fill={layouts.theme.colors.grey.dark50}
												fillOpacity="0.9"
												width="20px"
												height="20px"
											/>
										</layouts.containers.absolute>
										<brandguide.build.layouts.B64ImageForPDF
											contentdigest={i.thumbnail_contentdigest}
											styles={{ maxWidth: "250px", maxHeight: "250px" }}
											onLoad={() => setLoadedImages((prev) => prev - 1)}
										/>
									</layouts.Flex>
									<layouts.Flex>
										<typography.h6
											fontSize="12px"
											fontWeight="400"
											whiteSpace="unset"
											color={layouts.theme.colors.grey.dark50alpha80}
											lineHeight="20px"
										>
											{i.description}
										</typography.h6>
									</layouts.Flex>
								</layouts.Flex>
							))}
						</layouts.containers.grid>
					</results.SectionContainer>
				)}
				<results.SectionContainer>
					<results.Title>Photography Fundamentals</results.Title>
					<results.Explanation desc={data.descriptions?.fundamentals} />

					<layouts.Flex alignItems="start" mt="25px">
						{(data.fundamentals || []).map((f) => {
							if ((f.items || []).length === 0) return <></>
							return (
								<layouts.Flex key={f.id} flexDirection="column" alignItems="center" justifyContent="center" mx="10px">
									<typography.h5 fontSize="16px" fontWeight="600" lineHeight="normal" mb="13px">
										{f.name}
									</typography.h5>
									{(f.items || []).map((i) => (
										<layouts.Flex
											key={i.name}
											width="151px"
											height="37px"
											alignItems="center"
											justifyContent="center"
											borderRadius="5px"
											border={`1px solid ${layouts.theme.colors.grey.dark50alpha30}`}
											boxShadow={`0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`}
											gridGap="10px"
											mt="5px"
										>
											<typography.h5
												whiteSpace="unset"
												fontSize="14px"
												fontWeight="400"
												lineHeight="normal"
												color={layouts.theme.colors.grey.dark50alpha50}
											>
												{i.name}
											</typography.h5>
										</layouts.Flex>
									))}
								</layouts.Flex>
							)
						})}
					</layouts.Flex>
				</results.SectionContainer>

				<authz.Protected enabled={permission.brandguide_edit}>
					<layouts.Flex justifyContent="center" mt="100px" mb="20px" alignItems="center">
						<NextStageLink guide={guide} className="no-print" />
						<navigation.Section
							title="Imagery"
							steps={imagery.layouts.actualSteps(data)}
							onChange={(s) => {
								const step = imagery.layouts.steps.findIndex((i) => i === imagery.layouts.actualSteps(data)[s])
								onChange(step, undefined)
							}}
						/>
					</layouts.Flex>
				</authz.Protected>
			</layouts.Flex>
			<colors.Guidelines activPage="imagery" className="no-print" />
		</layouts.Flex>
	)
}

function Display(props: props): JSX.Element {
	const { onChange, onLoad, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<layouts.Flex justifyContent="right" mr="47px">
						<brandguide.build.SavePDF />
					</layouts.Flex>
					<SectionResult onChange={onChange} onLoad={onLoad} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	onLoad: () => undefined,
}

interface nextProps extends layouts.containers.FlexProps {
	guide: brandguide.api.Brandguide
}

function NextStageLink(props: nextProps): JSX.Element {
	const { guide, ...rest } = props
	const brand = brands.caching.useCached()

	const nextLink = (): string | undefined => {
		if (guide.guide?.tone_of_voice?.enabled) return brandguide.routing.build.tone_of_voice(brand.id)
		if (guide.guide?.archetype?.enabled) return brandguide.routing.build.archetype(brand.id)
		if (guide.guide?.compliance?.enabled) return brandguide.routing.build.compliance(brand.id)
		if (guide.guide?.application?.enabled) return brandguide.routing.build.application(brand.id)
		return undefined
	}

	if (!nextLink()) return <></>

	return (
		<layouts.Flex {...rest}>
			<Link to={nextLink()!}>
				<layouts.buttons.unstyled
					width="130px"
					height="34px"
					background={layouts.theme.colors.grey.dark50}
					borderRadius="5px"
					color={layouts.theme.colors.white}
					onClick={() => undefined}
				>
					Next Section
				</layouts.buttons.unstyled>
			</Link>
		</layouts.Flex>
	)
}

NextStageLink.defaultProps = {
	flex: "1",
	justifyContent: "end",
}

export default Display

import React, { useState } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"
import * as oauth2 from "."
import * as typography from "typography"

interface props extends layouts.containers.ContainerProps {
	onChange(b: api.Credential): void
}

export function validate(cred: api.Credential): boolean {
	return inputs.assertions.failed(
		inputs.assertions.strings.min(1)(cred.description),
		inputs.assertions.arrays.every(inputs.assertions.strings.url())(cred.origins),
		inputs.assertions.arrays.min(1)(cred.origins),
		inputs.assertions.arrays.every(inputs.assertions.strings.url())(cred.redirects),
		inputs.assertions.arrays.min(1)(cred.redirects),
	)
}

export default function Form(props: props & { current: api.Credential }): JSX.Element {
	const { current, onChange, ...rest } = props
	const [origin, setOrigin] = useState({ current: "", generation: 0 })
	const [redirect, setRedirect] = useState({ current: "", generation: 0 })

	return (
		<layouts.forms.Container className="oauth2.credentials.form" flexDirection="row" {...rest}>
			<layouts.forms.Group flex="1" flexDirection="column" mb="20px">
				<typography.h3 color={layouts.theme.colors.grey.dark50} fontSize="14px" lineHeight="30px">
					Email
				</typography.h3>
				<oauth2.layouts.StyledInput
					type="text"
					placeholder="Email"
					defaultValue={current.email}
					onChange={(evt) => onChange({ ...current, email: evt.currentTarget.value })}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group flex="1" flexDirection="column" mb="20px">
				<typography.h3 color={layouts.theme.colors.grey.dark50} fontSize="14px" lineHeight="30px">
					Description
				</typography.h3>
				<oauth2.layouts.StyledInput
					type="text"
					placeholder="Name"
					defaultValue={current.description}
					onChange={(evt) => onChange({ ...current, description: evt.currentTarget.value })}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group flex="1" flexDirection="column" mb="20px">
				<typography.h3 color={layouts.theme.colors.grey.dark50} fontSize="14px" lineHeight="30px">
					Origins
				</typography.h3>
				<oauth2.layouts.StyledInput
					key={origin.generation}
					placeholder="origins"
					defaultValue={origin.current}
					onChange={(evt) => setOrigin({ ...origin, current: evt.currentTarget.value })}
					onKeyUp={inputs.keyboard.enter((evt) => {
						if (inputs.assertions.strings.url()(origin.current) !== undefined) return
						onChange({ ...current, origins: [origin.current, ...current.origins] })
						setOrigin({ current: "", generation: origin.generation + 1 })
					})}
					onBlur={(evt) => {
						if (inputs.assertions.strings.url()(origin.current) !== undefined) return
						onChange({ ...current, origins: [origin.current, ...current.origins] })
						setOrigin({ current: "", generation: origin.generation + 1 })
					}}
				/>

				<layouts.containers.flex flexDirection="column" flex="1" mt="5px" px="20px" justifyContent="center">
					{current.origins.map((o, i) => (
						<li key={i}>
							<layouts.containers.span verticalAlign="middle" fontSize="10px" color={layouts.theme.colors.grey.dark50}>
								{o}
							</layouts.containers.span>
						</li>
					))}
				</layouts.containers.flex>
			</layouts.forms.Group>
			<layouts.forms.Group flex="1" flexDirection="column" mb="20px">
				<typography.h3 color={layouts.theme.colors.grey.dark50} fontSize="14px" lineHeight="30px">
					Redirects
				</typography.h3>
				<oauth2.layouts.StyledInput
					key={redirect.generation}
					placeholder="redirects"
					defaultValue={redirect.current}
					onChange={(evt) => setRedirect({ ...redirect, current: evt.currentTarget.value })}
					onKeyUp={inputs.keyboard.enter((evt) => {
						if (inputs.assertions.strings.url()(redirect.current) !== undefined) return
						onChange({ ...current, redirects: [redirect.current, ...current.redirects] })
						setRedirect({ current: "", generation: origin.generation + 1 })
					})}
					onBlur={(evt) => {
						if (inputs.assertions.strings.url()(redirect.current) !== undefined) return
						onChange({ ...current, redirects: [redirect.current, ...current.redirects] })
						setRedirect({ current: "", generation: origin.generation + 1 })
					}}
				/>
				<layouts.containers.flex flexDirection="column" flex="1" mt="5px" px="20px" justifyContent="center">
					{current.redirects.map((o, i) => (
						<li key={i}>
							<layouts.containers.span verticalAlign="middle" fontSize="10px" color={layouts.theme.colors.grey.dark50}>
								{o}
							</layouts.containers.span>
						</li>
					))}
				</layouts.containers.flex>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}

import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"

export function Modal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Typography Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>
							Typography plays a significant role in shaping the brand&apos;s identity and conveying its personality,
							values, and message.
						</b>
						<br />
						<br />
						Here&apos;s why:
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Brand Identity:</b> Fonts help establish the visual identity of a brand. By
						selecting fonts that reflect the brand&apos;s character and resonate with its target audience, a brand can
						create a cohesive and memorable identity that sets it apart from competitors.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Consistency:</b> Consistent use of fonts across various brand touchpoints,
						such as logos, websites, packaging, and marketing materials, reinforces brand recognition and strengthens
						brand consistency. Consistency in typography builds trust and familiarity with consumers over time.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Communication:</b> Fonts influence how information is perceived and
						understood. Different fonts convey different emotions and attitudes, allowing brands to communicate their
						message effectively. Whether it&apos;s conveying professionalism, friendliness, or innovation, the right
						font choice can enhance the brand&apos;s communication efforts.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Differentiation:</b> Fonts can be used strategically to differentiate a
						brand from competitors. By choosing unique or custom fonts, a brand can create a distinct visual identity
						that stands out in a crowded marketplace and leaves a lasting impression on consumers.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Modal.defaultProps = {
	width: "815px",
	height: "525px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

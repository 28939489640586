import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as selector from "brands/selector"
import * as icons from "icons"
import { Link } from "react-router-dom"
import * as brands from "brands"

export default function Container(props: React.PropsWithChildren<selector.props & { title: string }>): JSX.Element {
	const { title, children, urlgen } = props

	return (
		<layouts.backgrounds.Grey className="brandguard-container" flex="1">
			<layouts.containers.flex justifyContent="center" flex="1">
				<layouts.containers.flex
					px="50px"
					pt="25px"
					className="brandguard"
					flexDirection="column"
					flex="1"
					overflowY="hidden"
				>
					<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="5px">
						<layouts.containers.flex flex="2">
							<typography.h3 color={layouts.theme.colors.grey.medium} fontWeight="400">
								<b style={{ fontWeight: "700" }}>Assets Training:</b> {title}
							</typography.h3>
						</layouts.containers.flex>
						<layouts.containers.flex flex="2" justifyContent="end" height="48px">
							<selector.BrandSelector width="30%" urlgen={urlgen} />
						</layouts.containers.flex>
					</layouts.containers.flex>
					{children}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

Container.defaultProps = {
	title: "Trained Assets",
}

export function ImageActiveLink(props: { urlgen(bid: string): string }): JSX.Element {
	const brand = brands.caching.useCached()
	return (
		<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="5px">
			<layouts.containers.flex flexDirection="row" flex="1" borderBottom={layouts.theme.borders.grey.dark50alphamedium}>
				<layouts.containers.flex
					p="5px"
					flexDirection="row"
					borderBottom={`2px solid ${layouts.theme.colors.blue.blue}`}
				>
					<layouts.containers.flex>
						<icons.brandguard.image pr="5px" />
					</layouts.containers.flex>
					<layouts.containers.flex fontSize="14px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
						Image
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<Link to={props.urlgen(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.flex>
							<icons.brandguard.text strokeOpacity="0.5" pr="5px" />
						</layouts.containers.flex>
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Text
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function TextActiveLink(props: { urlgen(bid: string): string }): JSX.Element {
	const brand = brands.caching.useCached()
	return (
		<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="5px">
			<layouts.containers.flex flexDirection="row" flex="1" borderBottom={layouts.theme.borders.grey.dark50alphamedium}>
				<Link to={props.urlgen(brand.id)} style={{ textDecoration: "none" }}>
					<layouts.containers.flex p="5px" flexDirection="row">
						<layouts.containers.flex>
							<icons.brandguard.image fillOpacity="0.5" pr="5px" />
						</layouts.containers.flex>
						<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
							Image
						</layouts.containers.span>
					</layouts.containers.flex>
				</Link>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<layouts.containers.flex
					p="5px"
					flexDirection="row"
					borderBottom={`2px solid ${layouts.theme.colors.blue.blue}`}
				>
					<layouts.containers.flex>
						<icons.brandguard.text pr="5px" />
					</layouts.containers.flex>
					<layouts.containers.flex fontSize="14px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
						Text
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

import * as ls from "localstoragex"
import JSON from "jsonx"
import { default as _Cache } from "./cache"

export { default as Cache } from "./cache"
export { default as Autoflush } from "./autoflush"

export default _Cache

export function get(key: string): Promise<string> {
	return ls.getItem(key)
}

export function set(key: string, v: string): Promise<string> {
	return ls.setItem(key, v).then(() => v)
}

export function cas(key: string, v: string): Promise<string> {
	return ls.getItem(key).then((s) => s || ls.setItem(key, v).then(() => v))
}

export function removeItem(key: string): Promise<void> {
	return ls.removeItem(key)
}

export namespace numeric {
	export function set(key: string, n: number): Promise<number> {
		return ls.setItem(key, String(n)).then(() => n)
	}

	export async function get(key: string, fallback: number = 0): Promise<number> {
		return ls.getItem(key).then((found) => {
			if (found) return parseInt(found, 10)
			return fallback
		})
	}
}

export namespace boolean {
	export function set(key: string, n: boolean): Promise<boolean> {
		return ls.setItem(key, String(n)).then(() => n)
	}

	export async function get(key: string, fallback: boolean = false): Promise<boolean> {
		return ls.getItem(key).then((found) => {
			if (found) return JSON.parse(found)
			return fallback
		})
	}
}

export namespace object {
	export function set<T>(key: string, v: T): Promise<T> {
		return ls.setItem(key, JSON.stringify(v)).then(() => v)
	}

	export async function get<T>(key: string): Promise<T> {
		return ls.getItem(key).then((found) => {
			if (found) return JSON.parse(found) as T
			return Promise.reject(`cache miss ${key}`)
		})
	}
}

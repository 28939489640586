import { CancellablePromise } from "real-cancellable-promise"
import * as timex from "timex"

export interface Retry {
	wrap<T>(op: () => CancellablePromise<T>): CancellablePromise<T>
}

export interface backoff {
	calculate(attempt: number): timex.Duration
}

export interface retryable {
	(cause: unknown): boolean
}

interface retrier {
	initiated: timex.DateTime
	attempts: number
	backoff: backoff
	allowed: retryable
}

export type option = (r: retrier) => retrier

export namespace options {
	export function backoff(b: backoff): option {
		return (r: retrier): retrier => {
			return {
				...r,
				backoff: b,
			}
		}
	}

	export function errors(...cases: retryable[]): option {
		return (r: retrier): retrier => {
			return {
				...r,
				allowed: (cause: unknown) => !!cases.find((c) => c(cause)),
			}
		}
	}
}

export namespace backoffs {
	export function constant(d: timex.Duration): backoff {
		return {
			calculate(attempt: number): timex.Duration {
				return d
			},
		}
	}
}

export function zero(...opts: option[]): Retry {
	return {
		wrap<T>(op: () => CancellablePromise<T>): CancellablePromise<T> {
			const initial = opts.reduce((r, opt) => opt(r), {
				initiated: timex.utc(),
				attempts: 0,
				backoff: backoffs.constant(timex.duration.seconds(1)),
				allowed: (cause: unknown) => false,
			})

			const retry = (r: retrier): CancellablePromise<T> => {
				return op().catch((cause) => {
					if (r.allowed(cause)) {
						console.debug("reattempting operation", r, cause)
						return delay(r.backoff.calculate(r.attempts)).then(() => retry({ ...r, attempts: r.attempts + 1 }))
					}

					return CancellablePromise.reject(cause)
				})
			}

			return retry(initial)
		},
	}
}

function delay(d: timex.Duration): Promise<void> {
	return new Promise((resolve) => {
		setTimeout(resolve, d.toMillis())
	})
}

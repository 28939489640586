import React from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import { css } from "@emotion/css"

export function Tab(props: React.PropsWithChildren<layouts.containers.FlexProps & { focused: boolean }>): JSX.Element {
	const { children, className, focused, ...rest } = props
	const style = css`
		font-family: Liberation Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 1em;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		margin-right: 5px;
		background: ${focused ? layouts.theme.colors.blue.blue : layouts.theme.colors.white};
		color: ${focused ? layouts.theme.colors.white : layouts.theme.colors.grey.dark10};
		border-color: ${layouts.theme.colors.white};
		box-shadow: ${layouts.theme.boxshadow.grey.lightest};

		&:hover {
			border-color: ${layouts.theme.colors.blue.blue};
			background: ${layouts.theme.colors.blue.blue};
			color: ${layouts.theme.colors.white};
		}

		a {
			padding: 0px 15px;
			margin: auto;
			color: inherit;
			cursor: inherit;
			width: 100%;
			text-align: center;
			justify-content: center;
		}
	`

	return (
		<Heading className={classnames(className, style)} {...rest}>
			{children}
		</Heading>
	)
}

Tab.defaultProps = {
	focused: false,
}

export default function Heading(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return <layouts.containers.flex {...rest}>{children}</layouts.containers.flex>
}

Heading.defaultProps = {
	height: "28px",
	fontWeight: 500,
	fontSize: "12px",
	lineHeight: "1em",
	mr: "5px",
	justifyContent: "center",
}

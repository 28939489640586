/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguard.kernel.proto" (package "brandguard.kernel", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.KernelMetadata
 */
export interface KernelMetadata {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * @generated from protobuf field: string account_id = 3 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string mimetype = 5;
     */
    mimetype: string;
    /**
     * @generated from protobuf field: string created_at = 8 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 9 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: brandguard.kernel.Status status = 10;
     */
    status: Status;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.TextKernelScoringEnabled
 */
export interface TextKernelScoringEnabled {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.TextKernelScoringToneAndVoice
 */
export interface TextKernelScoringToneAndVoice {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: string prompt = 2;
     */
    prompt: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.TextKernelReferenceRule
 */
export interface TextKernelReferenceRule {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string; // md5 of the rule
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: string rule = 3;
     */
    rule: string;
    /**
     * @generated from protobuf field: string explanation = 4;
     */
    explanation: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.TextKernelReferenceRulesEnabled
 */
export interface TextKernelReferenceRulesEnabled {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguard.kernel.TextKernelReferenceRule rules = 2;
     */
    rules: TextKernelReferenceRule[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.TextKernelSetting
 */
export interface TextKernelSetting {
    /**
     * @generated from protobuf field: string id = 1 [json_name = "kernel_id"];
     */
    id: string;
    /**
     * @generated from protobuf field: int32 threshold = 2;
     */
    threshold: number;
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelScoringEnabled brand_voice = 3 [json_name = "brand_voice"];
     */
    brand_voice?: TextKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelScoringEnabled profanity = 4;
     */
    profanity?: TextKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelScoringEnabled racism = 5;
     */
    racism?: TextKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelScoringToneAndVoice tnv = 6;
     */
    tnv?: TextKernelScoringToneAndVoice;
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelScoringEnabled emotions = 7;
     */
    emotions?: TextKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelScoringEnabled grammar = 8;
     */
    grammar?: TextKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.TextKernelReferenceRulesEnabled reference_rules = 9 [json_name = "reference_rules"];
     */
    reference_rules?: TextKernelReferenceRulesEnabled;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelScoringEnabled
 */
export interface ImageKernelScoringEnabled {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageClearspace
 */
export interface ImageClearspace {
    /**
     * @generated from protobuf field: double horizontal = 1;
     */
    horizontal: number;
    /**
     * @generated from protobuf field: double vertical = 2;
     */
    vertical: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelLogo
 */
export interface ImageKernelLogo {
    /**
     * @generated from protobuf field: string mimetype = 1;
     */
    mimetype: string;
    /**
     * @generated from protobuf field: string md5 = 2;
     */
    md5: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string image = 4;
     */
    image: string; // these should be bytes but we have to base64 encode to deal with javascript stupidity.
    /**
     * @generated from protobuf field: brandguard.kernel.ImageClearspace clearspace = 5;
     */
    clearspace?: ImageClearspace;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelLogosEnabled
 */
export interface ImageKernelLogosEnabled {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguard.kernel.ImageKernelLogo logos = 2;
     */
    logos: ImageKernelLogo[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelBackgroundColor
 */
export interface ImageKernelBackgroundColor {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string color = 2;
     */
    color: string; // hexadecimal string representing color
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelBackgroundColorEnabled
 */
export interface ImageKernelBackgroundColorEnabled {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguard.kernel.ImageKernelBackgroundColor colors = 2;
     */
    colors: ImageKernelBackgroundColor[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelFont
 */
export interface ImageKernelFont {
    /**
     * @generated from protobuf field: string md5 = 1;
     */
    md5: string;
    /**
     * @generated from protobuf field: string mimetype = 2;
     */
    mimetype: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string font = 4;
     */
    font: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelFontEnabled
 */
export interface ImageKernelFontEnabled {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguard.kernel.ImageKernelFont fonts = 2;
     */
    fonts: ImageKernelFont[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelObscureRule
 */
export interface ImageKernelObscureRule {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string; // md5 of the rule
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: string rule = 3;
     */
    rule: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelObscureRulesEnabled
 */
export interface ImageKernelObscureRulesEnabled {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguard.kernel.ImageKernelObscureRule rules = 2;
     */
    rules: ImageKernelObscureRule[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.kernel.ImageKernelSetting
 */
export interface ImageKernelSetting {
    /**
     * @generated from protobuf field: string id = 1 [json_name = "kernel_id"];
     */
    id: string;
    /**
     * @generated from protobuf field: int32 threshold = 2;
     */
    threshold: number;
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelScoringEnabled brand_voice = 3 [json_name = "brand_voice"];
     */
    brand_voice?: ImageKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelScoringEnabled sexually_explicit = 4 [json_name = "sexually_explicit"];
     */
    sexually_explicit?: ImageKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelScoringEnabled image_quality_general = 5 [json_name = "quality_general"];
     */
    image_quality_general?: ImageKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelScoringEnabled image_violence = 6 [json_name = "image_violence"];
     */
    image_violence?: ImageKernelScoringEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelLogosEnabled image_logo_detection = 7 [json_name = "image_logo_detection"];
     */
    image_logo_detection?: ImageKernelLogosEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelObscureRulesEnabled image_obscure_rules = 8 [json_name = "image_obscure_rules"];
     */
    image_obscure_rules?: ImageKernelObscureRulesEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelFontEnabled image_font_detection = 9 [json_name = "image_font_detection"];
     */
    image_font_detection?: ImageKernelFontEnabled;
    /**
     * @generated from protobuf field: brandguard.kernel.ImageKernelBackgroundColorEnabled image_background_colors = 10 [json_name = "image_background_colors"];
     */
    image_background_colors?: ImageKernelBackgroundColorEnabled;
}
/**
 * @generated from protobuf enum brandguard.kernel.Status
 */
export enum Status {
    /**
     * @generated from protobuf enum value: PENDING = 0;
     */
    PENDING = 0,
    /**
     * @generated from protobuf enum value: COMPILED = 1;
     */
    COMPILED = 1,
    /**
     * @generated from protobuf enum value: VALIDATED = 2;
     */
    VALIDATED = 2,
    /**
     * @generated from protobuf enum value: READY = 3;
     */
    READY = 3,
    /**
     * @generated from protobuf enum value: Error = 1000;
     */
    Error = 1000,
    /**
     * @generated from protobuf enum value: CompilationError = 1001;
     */
    CompilationError = 1001
}
// @generated message type with reflection information, may provide speed optimized methods
class KernelMetadata$Type extends MessageType<KernelMetadata> {
    constructor() {
        super("brandguard.kernel.KernelMetadata", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "mimetype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "status", kind: "enum", T: () => ["brandguard.kernel.Status", Status] }
        ]);
    }
    create(value?: PartialMessage<KernelMetadata>): KernelMetadata {
        const message = { id: "", version: "", account_id: "", brand_id: "", mimetype: "", created_at: "", updated_at: "", status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KernelMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KernelMetadata): KernelMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* string account_id = 3 [json_name = "account_id"];*/ 3:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* string mimetype */ 5:
                    message.mimetype = reader.string();
                    break;
                case /* string created_at = 8 [json_name = "created_at"];*/ 8:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 9 [json_name = "updated_at"];*/ 9:
                    message.updated_at = reader.string();
                    break;
                case /* brandguard.kernel.Status status */ 10:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KernelMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* string account_id = 3 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* string mimetype = 5; */
        if (message.mimetype !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.mimetype);
        /* string created_at = 8 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 9 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.updated_at);
        /* brandguard.kernel.Status status = 10; */
        if (message.status !== 0)
            writer.tag(10, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.KernelMetadata
 */
export const KernelMetadata = new KernelMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelScoringEnabled$Type extends MessageType<TextKernelScoringEnabled> {
    constructor() {
        super("brandguard.kernel.TextKernelScoringEnabled", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TextKernelScoringEnabled>): TextKernelScoringEnabled {
        const message = { enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelScoringEnabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelScoringEnabled): TextKernelScoringEnabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelScoringEnabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.TextKernelScoringEnabled
 */
export const TextKernelScoringEnabled = new TextKernelScoringEnabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelScoringToneAndVoice$Type extends MessageType<TextKernelScoringToneAndVoice> {
    constructor() {
        super("brandguard.kernel.TextKernelScoringToneAndVoice", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "prompt", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TextKernelScoringToneAndVoice>): TextKernelScoringToneAndVoice {
        const message = { enabled: false, prompt: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelScoringToneAndVoice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelScoringToneAndVoice): TextKernelScoringToneAndVoice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* string prompt */ 2:
                    message.prompt = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelScoringToneAndVoice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* string prompt = 2; */
        if (message.prompt !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.prompt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.TextKernelScoringToneAndVoice
 */
export const TextKernelScoringToneAndVoice = new TextKernelScoringToneAndVoice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelReferenceRule$Type extends MessageType<TextKernelReferenceRule> {
    constructor() {
        super("brandguard.kernel.TextKernelReferenceRule", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rule", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "explanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TextKernelReferenceRule>): TextKernelReferenceRule {
        const message = { id: "", description: "", rule: "", explanation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelReferenceRule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelReferenceRule): TextKernelReferenceRule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* string rule */ 3:
                    message.rule = reader.string();
                    break;
                case /* string explanation */ 4:
                    message.explanation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelReferenceRule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* string rule = 3; */
        if (message.rule !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.rule);
        /* string explanation = 4; */
        if (message.explanation !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.explanation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.TextKernelReferenceRule
 */
export const TextKernelReferenceRule = new TextKernelReferenceRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelReferenceRulesEnabled$Type extends MessageType<TextKernelReferenceRulesEnabled> {
    constructor() {
        super("brandguard.kernel.TextKernelReferenceRulesEnabled", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "rules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TextKernelReferenceRule }
        ]);
    }
    create(value?: PartialMessage<TextKernelReferenceRulesEnabled>): TextKernelReferenceRulesEnabled {
        const message = { enabled: false, rules: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelReferenceRulesEnabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelReferenceRulesEnabled): TextKernelReferenceRulesEnabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguard.kernel.TextKernelReferenceRule rules */ 2:
                    message.rules.push(TextKernelReferenceRule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelReferenceRulesEnabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* repeated brandguard.kernel.TextKernelReferenceRule rules = 2; */
        for (let i = 0; i < message.rules.length; i++)
            TextKernelReferenceRule.internalBinaryWrite(message.rules[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.TextKernelReferenceRulesEnabled
 */
export const TextKernelReferenceRulesEnabled = new TextKernelReferenceRulesEnabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextKernelSetting$Type extends MessageType<TextKernelSetting> {
    constructor() {
        super("brandguard.kernel.TextKernelSetting", [
            { no: 1, name: "id", kind: "scalar", jsonName: "kernel_id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "threshold", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "brand_voice", kind: "message", localName: "brand_voice", jsonName: "brand_voice", T: () => TextKernelScoringEnabled },
            { no: 4, name: "profanity", kind: "message", T: () => TextKernelScoringEnabled },
            { no: 5, name: "racism", kind: "message", T: () => TextKernelScoringEnabled },
            { no: 6, name: "tnv", kind: "message", T: () => TextKernelScoringToneAndVoice },
            { no: 7, name: "emotions", kind: "message", T: () => TextKernelScoringEnabled },
            { no: 8, name: "grammar", kind: "message", T: () => TextKernelScoringEnabled },
            { no: 9, name: "reference_rules", kind: "message", localName: "reference_rules", jsonName: "reference_rules", T: () => TextKernelReferenceRulesEnabled }
        ]);
    }
    create(value?: PartialMessage<TextKernelSetting>): TextKernelSetting {
        const message = { id: "", threshold: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextKernelSetting>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextKernelSetting): TextKernelSetting {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id = 1 [json_name = "kernel_id"];*/ 1:
                    message.id = reader.string();
                    break;
                case /* int32 threshold */ 2:
                    message.threshold = reader.int32();
                    break;
                case /* brandguard.kernel.TextKernelScoringEnabled brand_voice = 3 [json_name = "brand_voice"];*/ 3:
                    message.brand_voice = TextKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.brand_voice);
                    break;
                case /* brandguard.kernel.TextKernelScoringEnabled profanity */ 4:
                    message.profanity = TextKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.profanity);
                    break;
                case /* brandguard.kernel.TextKernelScoringEnabled racism */ 5:
                    message.racism = TextKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.racism);
                    break;
                case /* brandguard.kernel.TextKernelScoringToneAndVoice tnv */ 6:
                    message.tnv = TextKernelScoringToneAndVoice.internalBinaryRead(reader, reader.uint32(), options, message.tnv);
                    break;
                case /* brandguard.kernel.TextKernelScoringEnabled emotions */ 7:
                    message.emotions = TextKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.emotions);
                    break;
                case /* brandguard.kernel.TextKernelScoringEnabled grammar */ 8:
                    message.grammar = TextKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.grammar);
                    break;
                case /* brandguard.kernel.TextKernelReferenceRulesEnabled reference_rules = 9 [json_name = "reference_rules"];*/ 9:
                    message.reference_rules = TextKernelReferenceRulesEnabled.internalBinaryRead(reader, reader.uint32(), options, message.reference_rules);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextKernelSetting, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1 [json_name = "kernel_id"]; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 threshold = 2; */
        if (message.threshold !== 0)
            writer.tag(2, WireType.Varint).int32(message.threshold);
        /* brandguard.kernel.TextKernelScoringEnabled brand_voice = 3 [json_name = "brand_voice"]; */
        if (message.brand_voice)
            TextKernelScoringEnabled.internalBinaryWrite(message.brand_voice, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.TextKernelScoringEnabled profanity = 4; */
        if (message.profanity)
            TextKernelScoringEnabled.internalBinaryWrite(message.profanity, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.TextKernelScoringEnabled racism = 5; */
        if (message.racism)
            TextKernelScoringEnabled.internalBinaryWrite(message.racism, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.TextKernelScoringToneAndVoice tnv = 6; */
        if (message.tnv)
            TextKernelScoringToneAndVoice.internalBinaryWrite(message.tnv, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.TextKernelScoringEnabled emotions = 7; */
        if (message.emotions)
            TextKernelScoringEnabled.internalBinaryWrite(message.emotions, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.TextKernelScoringEnabled grammar = 8; */
        if (message.grammar)
            TextKernelScoringEnabled.internalBinaryWrite(message.grammar, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.TextKernelReferenceRulesEnabled reference_rules = 9 [json_name = "reference_rules"]; */
        if (message.reference_rules)
            TextKernelReferenceRulesEnabled.internalBinaryWrite(message.reference_rules, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.TextKernelSetting
 */
export const TextKernelSetting = new TextKernelSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelScoringEnabled$Type extends MessageType<ImageKernelScoringEnabled> {
    constructor() {
        super("brandguard.kernel.ImageKernelScoringEnabled", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ImageKernelScoringEnabled>): ImageKernelScoringEnabled {
        const message = { enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelScoringEnabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelScoringEnabled): ImageKernelScoringEnabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelScoringEnabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelScoringEnabled
 */
export const ImageKernelScoringEnabled = new ImageKernelScoringEnabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageClearspace$Type extends MessageType<ImageClearspace> {
    constructor() {
        super("brandguard.kernel.ImageClearspace", [
            { no: 1, name: "horizontal", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "vertical", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<ImageClearspace>): ImageClearspace {
        const message = { horizontal: 0, vertical: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageClearspace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageClearspace): ImageClearspace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double horizontal */ 1:
                    message.horizontal = reader.double();
                    break;
                case /* double vertical */ 2:
                    message.vertical = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageClearspace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double horizontal = 1; */
        if (message.horizontal !== 0)
            writer.tag(1, WireType.Bit64).double(message.horizontal);
        /* double vertical = 2; */
        if (message.vertical !== 0)
            writer.tag(2, WireType.Bit64).double(message.vertical);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageClearspace
 */
export const ImageClearspace = new ImageClearspace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelLogo$Type extends MessageType<ImageKernelLogo> {
    constructor() {
        super("brandguard.kernel.ImageKernelLogo", [
            { no: 1, name: "mimetype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "clearspace", kind: "message", T: () => ImageClearspace }
        ]);
    }
    create(value?: PartialMessage<ImageKernelLogo>): ImageKernelLogo {
        const message = { mimetype: "", md5: "", description: "", image: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelLogo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelLogo): ImageKernelLogo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mimetype */ 1:
                    message.mimetype = reader.string();
                    break;
                case /* string md5 */ 2:
                    message.md5 = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string image */ 4:
                    message.image = reader.string();
                    break;
                case /* brandguard.kernel.ImageClearspace clearspace */ 5:
                    message.clearspace = ImageClearspace.internalBinaryRead(reader, reader.uint32(), options, message.clearspace);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelLogo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mimetype = 1; */
        if (message.mimetype !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.mimetype);
        /* string md5 = 2; */
        if (message.md5 !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.md5);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string image = 4; */
        if (message.image !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.image);
        /* brandguard.kernel.ImageClearspace clearspace = 5; */
        if (message.clearspace)
            ImageClearspace.internalBinaryWrite(message.clearspace, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelLogo
 */
export const ImageKernelLogo = new ImageKernelLogo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelLogosEnabled$Type extends MessageType<ImageKernelLogosEnabled> {
    constructor() {
        super("brandguard.kernel.ImageKernelLogosEnabled", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "logos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageKernelLogo }
        ]);
    }
    create(value?: PartialMessage<ImageKernelLogosEnabled>): ImageKernelLogosEnabled {
        const message = { enabled: false, logos: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelLogosEnabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelLogosEnabled): ImageKernelLogosEnabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguard.kernel.ImageKernelLogo logos */ 2:
                    message.logos.push(ImageKernelLogo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelLogosEnabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* repeated brandguard.kernel.ImageKernelLogo logos = 2; */
        for (let i = 0; i < message.logos.length; i++)
            ImageKernelLogo.internalBinaryWrite(message.logos[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelLogosEnabled
 */
export const ImageKernelLogosEnabled = new ImageKernelLogosEnabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelBackgroundColor$Type extends MessageType<ImageKernelBackgroundColor> {
    constructor() {
        super("brandguard.kernel.ImageKernelBackgroundColor", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageKernelBackgroundColor>): ImageKernelBackgroundColor {
        const message = { id: "", color: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelBackgroundColor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelBackgroundColor): ImageKernelBackgroundColor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string color */ 2:
                    message.color = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelBackgroundColor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string color = 2; */
        if (message.color !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.color);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelBackgroundColor
 */
export const ImageKernelBackgroundColor = new ImageKernelBackgroundColor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelBackgroundColorEnabled$Type extends MessageType<ImageKernelBackgroundColorEnabled> {
    constructor() {
        super("brandguard.kernel.ImageKernelBackgroundColorEnabled", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "colors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageKernelBackgroundColor }
        ]);
    }
    create(value?: PartialMessage<ImageKernelBackgroundColorEnabled>): ImageKernelBackgroundColorEnabled {
        const message = { enabled: false, colors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelBackgroundColorEnabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelBackgroundColorEnabled): ImageKernelBackgroundColorEnabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguard.kernel.ImageKernelBackgroundColor colors */ 2:
                    message.colors.push(ImageKernelBackgroundColor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelBackgroundColorEnabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* repeated brandguard.kernel.ImageKernelBackgroundColor colors = 2; */
        for (let i = 0; i < message.colors.length; i++)
            ImageKernelBackgroundColor.internalBinaryWrite(message.colors[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelBackgroundColorEnabled
 */
export const ImageKernelBackgroundColorEnabled = new ImageKernelBackgroundColorEnabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelFont$Type extends MessageType<ImageKernelFont> {
    constructor() {
        super("brandguard.kernel.ImageKernelFont", [
            { no: 1, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "mimetype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "font", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageKernelFont>): ImageKernelFont {
        const message = { md5: "", mimetype: "", description: "", font: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelFont>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelFont): ImageKernelFont {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string md5 */ 1:
                    message.md5 = reader.string();
                    break;
                case /* string mimetype */ 2:
                    message.mimetype = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string font */ 4:
                    message.font = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelFont, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string md5 = 1; */
        if (message.md5 !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.md5);
        /* string mimetype = 2; */
        if (message.mimetype !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.mimetype);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string font = 4; */
        if (message.font !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.font);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelFont
 */
export const ImageKernelFont = new ImageKernelFont$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelFontEnabled$Type extends MessageType<ImageKernelFontEnabled> {
    constructor() {
        super("brandguard.kernel.ImageKernelFontEnabled", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "fonts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageKernelFont }
        ]);
    }
    create(value?: PartialMessage<ImageKernelFontEnabled>): ImageKernelFontEnabled {
        const message = { enabled: false, fonts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelFontEnabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelFontEnabled): ImageKernelFontEnabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguard.kernel.ImageKernelFont fonts */ 2:
                    message.fonts.push(ImageKernelFont.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelFontEnabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* repeated brandguard.kernel.ImageKernelFont fonts = 2; */
        for (let i = 0; i < message.fonts.length; i++)
            ImageKernelFont.internalBinaryWrite(message.fonts[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelFontEnabled
 */
export const ImageKernelFontEnabled = new ImageKernelFontEnabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelObscureRule$Type extends MessageType<ImageKernelObscureRule> {
    constructor() {
        super("brandguard.kernel.ImageKernelObscureRule", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rule", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageKernelObscureRule>): ImageKernelObscureRule {
        const message = { id: "", description: "", rule: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelObscureRule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelObscureRule): ImageKernelObscureRule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* string rule */ 3:
                    message.rule = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelObscureRule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* string rule = 3; */
        if (message.rule !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.rule);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelObscureRule
 */
export const ImageKernelObscureRule = new ImageKernelObscureRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelObscureRulesEnabled$Type extends MessageType<ImageKernelObscureRulesEnabled> {
    constructor() {
        super("brandguard.kernel.ImageKernelObscureRulesEnabled", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "rules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageKernelObscureRule }
        ]);
    }
    create(value?: PartialMessage<ImageKernelObscureRulesEnabled>): ImageKernelObscureRulesEnabled {
        const message = { enabled: false, rules: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelObscureRulesEnabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelObscureRulesEnabled): ImageKernelObscureRulesEnabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguard.kernel.ImageKernelObscureRule rules */ 2:
                    message.rules.push(ImageKernelObscureRule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelObscureRulesEnabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* repeated brandguard.kernel.ImageKernelObscureRule rules = 2; */
        for (let i = 0; i < message.rules.length; i++)
            ImageKernelObscureRule.internalBinaryWrite(message.rules[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelObscureRulesEnabled
 */
export const ImageKernelObscureRulesEnabled = new ImageKernelObscureRulesEnabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageKernelSetting$Type extends MessageType<ImageKernelSetting> {
    constructor() {
        super("brandguard.kernel.ImageKernelSetting", [
            { no: 1, name: "id", kind: "scalar", jsonName: "kernel_id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "threshold", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "brand_voice", kind: "message", localName: "brand_voice", jsonName: "brand_voice", T: () => ImageKernelScoringEnabled },
            { no: 4, name: "sexually_explicit", kind: "message", localName: "sexually_explicit", jsonName: "sexually_explicit", T: () => ImageKernelScoringEnabled },
            { no: 5, name: "image_quality_general", kind: "message", localName: "image_quality_general", jsonName: "quality_general", T: () => ImageKernelScoringEnabled },
            { no: 6, name: "image_violence", kind: "message", localName: "image_violence", jsonName: "image_violence", T: () => ImageKernelScoringEnabled },
            { no: 7, name: "image_logo_detection", kind: "message", localName: "image_logo_detection", jsonName: "image_logo_detection", T: () => ImageKernelLogosEnabled },
            { no: 8, name: "image_obscure_rules", kind: "message", localName: "image_obscure_rules", jsonName: "image_obscure_rules", T: () => ImageKernelObscureRulesEnabled },
            { no: 9, name: "image_font_detection", kind: "message", localName: "image_font_detection", jsonName: "image_font_detection", T: () => ImageKernelFontEnabled },
            { no: 10, name: "image_background_colors", kind: "message", localName: "image_background_colors", jsonName: "image_background_colors", T: () => ImageKernelBackgroundColorEnabled }
        ]);
    }
    create(value?: PartialMessage<ImageKernelSetting>): ImageKernelSetting {
        const message = { id: "", threshold: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageKernelSetting>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageKernelSetting): ImageKernelSetting {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id = 1 [json_name = "kernel_id"];*/ 1:
                    message.id = reader.string();
                    break;
                case /* int32 threshold */ 2:
                    message.threshold = reader.int32();
                    break;
                case /* brandguard.kernel.ImageKernelScoringEnabled brand_voice = 3 [json_name = "brand_voice"];*/ 3:
                    message.brand_voice = ImageKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.brand_voice);
                    break;
                case /* brandguard.kernel.ImageKernelScoringEnabled sexually_explicit = 4 [json_name = "sexually_explicit"];*/ 4:
                    message.sexually_explicit = ImageKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.sexually_explicit);
                    break;
                case /* brandguard.kernel.ImageKernelScoringEnabled image_quality_general = 5 [json_name = "quality_general"];*/ 5:
                    message.image_quality_general = ImageKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.image_quality_general);
                    break;
                case /* brandguard.kernel.ImageKernelScoringEnabled image_violence = 6 [json_name = "image_violence"];*/ 6:
                    message.image_violence = ImageKernelScoringEnabled.internalBinaryRead(reader, reader.uint32(), options, message.image_violence);
                    break;
                case /* brandguard.kernel.ImageKernelLogosEnabled image_logo_detection = 7 [json_name = "image_logo_detection"];*/ 7:
                    message.image_logo_detection = ImageKernelLogosEnabled.internalBinaryRead(reader, reader.uint32(), options, message.image_logo_detection);
                    break;
                case /* brandguard.kernel.ImageKernelObscureRulesEnabled image_obscure_rules = 8 [json_name = "image_obscure_rules"];*/ 8:
                    message.image_obscure_rules = ImageKernelObscureRulesEnabled.internalBinaryRead(reader, reader.uint32(), options, message.image_obscure_rules);
                    break;
                case /* brandguard.kernel.ImageKernelFontEnabled image_font_detection = 9 [json_name = "image_font_detection"];*/ 9:
                    message.image_font_detection = ImageKernelFontEnabled.internalBinaryRead(reader, reader.uint32(), options, message.image_font_detection);
                    break;
                case /* brandguard.kernel.ImageKernelBackgroundColorEnabled image_background_colors = 10 [json_name = "image_background_colors"];*/ 10:
                    message.image_background_colors = ImageKernelBackgroundColorEnabled.internalBinaryRead(reader, reader.uint32(), options, message.image_background_colors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageKernelSetting, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1 [json_name = "kernel_id"]; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 threshold = 2; */
        if (message.threshold !== 0)
            writer.tag(2, WireType.Varint).int32(message.threshold);
        /* brandguard.kernel.ImageKernelScoringEnabled brand_voice = 3 [json_name = "brand_voice"]; */
        if (message.brand_voice)
            ImageKernelScoringEnabled.internalBinaryWrite(message.brand_voice, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.ImageKernelScoringEnabled sexually_explicit = 4 [json_name = "sexually_explicit"]; */
        if (message.sexually_explicit)
            ImageKernelScoringEnabled.internalBinaryWrite(message.sexually_explicit, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.ImageKernelScoringEnabled image_quality_general = 5 [json_name = "quality_general"]; */
        if (message.image_quality_general)
            ImageKernelScoringEnabled.internalBinaryWrite(message.image_quality_general, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.ImageKernelScoringEnabled image_violence = 6 [json_name = "image_violence"]; */
        if (message.image_violence)
            ImageKernelScoringEnabled.internalBinaryWrite(message.image_violence, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.ImageKernelLogosEnabled image_logo_detection = 7 [json_name = "image_logo_detection"]; */
        if (message.image_logo_detection)
            ImageKernelLogosEnabled.internalBinaryWrite(message.image_logo_detection, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.ImageKernelObscureRulesEnabled image_obscure_rules = 8 [json_name = "image_obscure_rules"]; */
        if (message.image_obscure_rules)
            ImageKernelObscureRulesEnabled.internalBinaryWrite(message.image_obscure_rules, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.ImageKernelFontEnabled image_font_detection = 9 [json_name = "image_font_detection"]; */
        if (message.image_font_detection)
            ImageKernelFontEnabled.internalBinaryWrite(message.image_font_detection, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.kernel.ImageKernelBackgroundColorEnabled image_background_colors = 10 [json_name = "image_background_colors"]; */
        if (message.image_background_colors)
            ImageKernelBackgroundColorEnabled.internalBinaryWrite(message.image_background_colors, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.kernel.ImageKernelSetting
 */
export const ImageKernelSetting = new ImageKernelSetting$Type();

import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import Image from "images/file.loading.gif"
import { css } from "@emotion/css"
import * as inputs from "inputs"

export function Loading(): JSX.Element {
	return (
		<layouts.containers.flex flexDirection="column" className="processing-component" width="100%">
			<layouts.containers.flex
				minHeight="600px"
				background={layouts.theme.colors.white}
				borderRadius="10px"
				flexDirection="column"
				pt="60px"
			>
				<layouts.containers.flex flexDirection="column" p="50px" my="20px" mx="100px">
					<layouts.images.Base width="288px" height="212px" m="auto" src={Image} />
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export const StyledBtn = styled(layouts.buttons.outline)`
	background: ${layouts.theme.colors.white};
	border: 1px solid ${layouts.theme.colors.grey.light20};
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
	height: 48px;
	border-radius: 48px;
	padding: 0 23px;
	&:hover {
		border: 1px solid ${layouts.theme.colors.blue.blue};
		background: ${layouts.theme.colors.white};
		color: ${layouts.theme.colors.blue.blue};
		box-shadow: 2px 2px 4px ${layouts.theme.colors.blue.blue}7E;
	}
`
export const navdropwell = css`
	display: block;
	width: 100%;
	align-items: center;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	margin-top: 20px;
	margin-bottom: 20px;
	.styled-drop-area {
		border: none;
		padding: 0;
		background: ${layouts.theme.colors.white};
		.dropwell-icon {
			height: 60px;
			width: 60px;
			margin-top: 25px;
			svg {
				height: 40px;
				width: 40px;
			}
		}
		h3 {
			color: ${layouts.theme.colors.grey.dark50};
			text-align: center;
			font-size: 10px;
			font-weight: 400;
			line-height: 16.5px;
			padding-top: 10px;
			padding-bottom: 20px;
		}
	}
`

export const navdropwellsimple = css`
	.styled-drop-area {
		padding: 0;
		.dropwell-icon {
			height: 60px;
			width: 60px;
			margin-top: 25px;
			svg {
				height: 40px;
				width: 40px;
			}
		}
		h3 {
			color: ${layouts.theme.colors.grey.dark50};
			text-align: center;
			font-size: 10px;
			font-weight: 400;
			line-height: 16.5px;
			padding-top: 10px;
			padding-bottom: 20px;
		}
	}
`

export const navdropwellred = css`
	.styled-drop-area {
		.dropwell-icon {
			background: ${layouts.theme.backgrounds.redalpha};
			svg {
				path {
					stroke: ${layouts.theme.colors.red.cinnabar};
				}
			}
		}
	}
`

export const navareared = css`
	.styled-drop-area {
		background: ${layouts.theme.backgrounds.cinnabaralpha};
		border: 1px dashed ${layouts.theme.colors.red.cinnabar};
	}
	.text-area-content {
		background: ${layouts.theme.backgrounds.cinnabaralpha};
	}
`

export const TabPrimaryButton = styled(layouts.buttons.primary)<{ backgroundColor: string }>`
	width: 78px;
	height: 33px;
	border-radius: 5px;
	background-color: ${(props) => (props.disabled ? layouts.theme.buttons.colors.disabled : props.backgroundColor)};
	color: ${layouts.theme.colors.white};
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 5px;
	padding: 0;
	border: none;
`
TabPrimaryButton.defaultProps = {
	backgroundColor: layouts.theme.colors.blue.blue,
}

export const TextArea = styled(inputs.TextArea)`
	border-radius: 10px;
	outline: none;
	height: 100px;
	padding: 20px;
	font-family: Roboto;
`

export const zerotab = css`
	.dropWell-container {
		display: none;
	}
	.progress-container {
		background: ${layouts.theme.colors.grey.bg};
		margin: 20px;
		flex: 0;
	}
`

export const uploadsimple = css`
	.display-no-in-process {
		display: none;
	}
	.dropWell-container {
		padding-left: 10px;
		padding-right: 10px;
	}
	textarea {
		font-size: 12px;
		font-weight: 400;
		line-height: 17px;
		letter-spacing: 0px;
		text-align: left;
	}
`

export const zerotextinsert = css`
	.review-button-container {
		justify-content: center;
		padding-top: 25px;
	}
	textarea {
		border: none;
		outline: 1px dashed ${layouts.theme.colors.blue.blue};
		background: ${layouts.theme.backgrounds.bluealpha};
	}
`

export const zerotextinsertred = css`
	textarea {
		outline: 1px dashed ${layouts.theme.colors.red.cinnabar};
		background: ${layouts.theme.backgrounds.cinnabaralpha};
	}
`

export const zerodropwellred = css`
	.styled-drop-area {
		background: ${layouts.theme.backgrounds.cinnabaralpha};
		border: 1px dashed ${layouts.theme.colors.red.cinnabar};
		.dropwell-icon {
			background: ${layouts.theme.backgrounds.redalpha};
			svg {
				path {
					stroke: ${layouts.theme.colors.red.cinnabar};
				}
			}
		}
	}
`
export const navdenied = css`
	display: block;
	width: 100%;
	margin: 20px 10px 20px 10px;
	.styled-area {
		border: none;
		padding: 10px;
		background: ${layouts.theme.colors.white};
	}
`

import React, { useEffect, useState } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as errors from "errors"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as typography from "typography"
import * as api from "./api"
import * as oauth2 from "./layouts"
import NewForm from "./credentials.new"
import * as inputs from "inputs"
import * as icons from "icons"

function CreatedRow(props: React.PropsWithChildren<{ cred: api.credentials.Secret }>): JSX.Element {
	const { cred, children } = props

	return (
		<oauth2.AccordionContainer key={cred.id} credential={cred}>
			<layouts.containers.flex
				flexDirection="column"
				px="50px"
				py="10px"
				alignItems="flex-start"
				fontSize="14px"
				lineHeight="20px"
				color={layouts.theme.colors.grey.dark50}
			>
				<oauth2.Row title="Description" value={cred.description} />
				<oauth2.Row title="Email" value={cred.email} />
				<oauth2.Row title="ID" value={cred.id} cancopy />
				<oauth2.Row title="Secret" value={cred.secret} cancopy />
				{children}
			</layouts.containers.flex>
		</oauth2.AccordionContainer>
	)
}

function CredentialRow(props: React.PropsWithChildren<{ item: api.Credential }>): JSX.Element {
	const { item, children } = props

	return (
		<oauth2.AccordionContainer key={item.id} credential={item}>
			<layouts.containers.flex
				flexDirection="column"
				px="50px"
				py="10px"
				alignItems="flex-start"
				fontSize="14px"
				lineHeight="20px"
				color={layouts.theme.colors.grey.dark50}
			>
				<oauth2.Row title="Description" value={item.description} />
				<oauth2.Row title="Email" value={item.email} />
				<oauth2.Row title="ID" value={item.id} cancopy />
				{children}
			</layouts.containers.flex>
		</oauth2.AccordionContainer>
	)
}

export default function Table(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const [creationkey, setCreationKey] = useState(uuid.v4())
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [lastcreated, setLastCreated] = useState(undefined as JSX.Element | undefined)
	const [req, setReq] = useState(api.credentials.searches.request())
	const [set, setItems] = useState(api.credentials.searches.response({ next: req }))

	useEffect(() => {
		if (!permission.usermanagement) return
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.credentials.search(req, authzc.bearer(metaauthz)))
			.then((resp) => {
				setItems(resp)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				setCause(
					<layouts.Flex flex="1" justifyContent="center">
						<errors.UnknownCause
							cause={cause}
							onClick={() => {
								setReq({ ...req })
								setCause(undefined)
							}}
						/>
					</layouts.Flex>,
				)
			})

		return p.cancel
	}, [req])

	return (
		<layouts.containers.flex
			flexDirection="row"
			flex="1"
			background={layouts.theme.colors.white}
			my="20px"
			overflow="hidden"
			{...rest}
		>
			<layouts.containers.flex flexDirection="column" flex="2" p="50px" overflowY="auto">
				<typography.h3
					fontSize="16px"
					fontWeight="700"
					lineHeight="30px"
					color={layouts.theme.colors.grey.dark50}
					mb="5px"
				>
					Create Credentials
				</typography.h3>

				<typography.h6
					fontSize="12px"
					fontWeight="400"
					lineHeight="20px"
					color={layouts.theme.colors.grey.dark50}
					whiteSpace="unset"
				>
					<authz.Protected
						enabled={permission.usermanagement}
						rejected={<>You do not have permissions to create credentials. Please contact an admin.</>}
					>
						Add new credentials
					</authz.Protected>
				</typography.h6>

				<authz.Protected enabled={permission.usermanagement}>
					<NewForm
						key={creationkey}
						mt="20px"
						flexDirection="column"
						onChange={(cred) => {
							return api.credentials
								.create({ credential: cred }, authzc.bearer(metaauthz))
								.then((resp) => {
									const i = { ...api.credentials.zero(resp.credential), secret: resp.secret }
									setLastCreated(
										<CreatedRow cred={i}>
											<oauth2.CredentialActions
												item={i}
												onDelete={(r) => {
													setLastCreated(undefined)
												}}
											/>
										</CreatedRow>,
									)
									setCreationKey(uuid.v4())
								})
								.catch(httpx.errors.cancellation(console.warn))
								.catch((cause) => {
									setCause(<errors.UnknownCause flex="1" cause={cause} onClick={() => setCause(undefined)} />)
								})
						}}
					/>
				</authz.Protected>
			</layouts.containers.flex>
			<layouts.containers.flex flex="7">
				<layouts.containers.flex px="10px" flex="1" flexDirection="column">
					<inputs.Search
						query={req.query}
						onChange={(s) => {
							setReq({ ...req, query: s })
						}}
					>
						<layouts.containers.flex pr="20px" onClick={() => setReq({ ...req, query: "" })}>
							<icons.searches.Clear />
						</layouts.containers.flex>
					</inputs.Search>
					<layouts.containers.flex
						flexDirection="column"
						flex="1"
						background={layouts.theme.colors.grey.bg}
						my="20px"
						overflowY="auto"
					>
						<layouts.containers.flex
							flexDirection="row"
							fontWeight="700"
							fontSize="16px"
							lineHeight="30px"
							justifyContent="center"
							borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
							mx="5px"
						>
							<layouts.containers.flex flex="3" p="10px">
								Description
							</layouts.containers.flex>
							<layouts.containers.flex flex="1" p="10px" justifyContent="center">
								Enabled
							</layouts.containers.flex>
							<layouts.containers.flex flex="1" p="10px" justifyContent="center">
								Created
							</layouts.containers.flex>
							<layouts.containers.flex flex="1" p="10px"></layouts.containers.flex>
						</layouts.containers.flex>
						<errors.OverlayV2 styled flex="1" cause={cause}>
							{lastcreated}
							{set.items.map((item) => {
								return (
									<CredentialRow key={item.id} item={item}>
										<oauth2.CredentialActions
											item={item}
											onDelete={(r) => {
												setItems((prevState) => ({
													...prevState,
													items: prevState.items.filter((i) => i.id !== r.credential?.id),
												}))
											}}
										/>
									</CredentialRow>
								)
							})}
							<layouts.pagination.Cursor
								mx="auto"
								mt="auto"
								py="5px"
								pr="10px"
								current={req.offset}
								advance={set.next!.offset === "" || cause !== undefined ? undefined : set.next?.offset}
								onChange={(next) => {
									setReq({
										...(set.next || req),
										offset: next,
									})
								}}
							/>
						</errors.OverlayV2>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

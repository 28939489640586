import React, { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as caching from "brandguide/cache"
import * as icons from "icons"
import * as brandguide from "brandguide"
import * as imagery from "brandguide/build/imagery"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideImagery | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideImageryZero(_guide.guide?.imagery))

	const nextStep = (): number => {
		if (brandguide.validations.imagery.enabled.lifestyle(data)) return imagery.Steps.LIFESTYLE
		if (brandguide.validations.imagery.enabled.product(data)) return imagery.Steps.PRODUCT
		if (brandguide.validations.imagery.enabled.illustration(data)) return imagery.Steps.ILLUSTRATION
		return imagery.Steps.START
	}

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Imagery" Help={<imagery.helps.Modal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please select all that apply to your brand.">
							<layouts.Flex gridGap="5px" mt="65px" mx="155px">
								<Card
									enabled={brandguide.validations.imagery.enabled.lifestyle(data)}
									title="Lifestyle"
									desc="Authentic moments and scenes that depict your product(s) or services in real-life contexts"
									onChange={() => {
										const upd = brandguide.api.zeros.imagery.sectionZero({ ...data.lifestyle })
										upd.enabled = !(data.lifestyle?.enabled || false)
										setData({ ...data, lifestyle: upd })
									}}
								/>
								<Card
									enabled={brandguide.validations.imagery.enabled.product(data)}
									title="Product"
									desc="Showcasing the physical attributes and features of your product(s) in a visually appealing and informative manner"
									onChange={() => {
										const upd = brandguide.api.zeros.imagery.sectionZero({ ...data.product })
										upd.enabled = !(data.product?.enabled || false)
										setData({ ...data, product: upd })
									}}
								/>
								<Card
									enabled={brandguide.validations.imagery.enabled.illustration(data)}
									title="Illustration"
									desc="Unique and recognizable artwork that reflects your brand's personality, values, and message"
									onChange={() => {
										const upd = brandguide.api.zeros.imagery.sectionZero({ ...data.illustration })
										upd.enabled = !(data.illustration?.enabled || false)
										setData({ ...data, illustration: upd })
									}}
								/>
							</layouts.Flex>
							<brandguide.build.layouts.NextStepsNav
								onNext={() => onChange(nextStep(), data)}
								disabled={!brandguide.validations.imagery.enabled.sections(data)}
							/>
							<imagery.Progress activeStep={imagery.Steps.START} steps={imagery.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<imagery.Guidelines activPage="imagery" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

type section = "Lifestyle" | "Product" | "Illustration"

interface iconProps extends icons.SVGProps {
	section: section
}

function Icon(props: iconProps): JSX.Element {
	const { section, ...rest } = props
	if (section === "Lifestyle") return <icons.brandguide.nav.imagery.Lifestyle {...rest} />
	if (section === "Product") return <icons.brandguide.nav.imagery.Product {...rest} />
	if (section === "Illustration") return <icons.brandguide.nav.imagery.Illustration {...rest} />
	return <></>
}

interface cardProps extends layouts.containers.ContainerProps {
	title: section
	desc: string
	enabled: boolean
	onChange(): void
}

function Card(props: cardProps): JSX.Element {
	const { title, desc, enabled, onChange, ...rest } = props
	return (
		<layouts.Flex
			background={enabled ? layouts.theme.colors.blue.blue : layouts.theme.colors.grey.dark50alpha10}
			onClick={onChange}
			{...rest}
		>
			<layouts.Flex flex="2" alignItems="center" mt="10px" minHeight="120px">
				<Icon
					section={title}
					stroke={enabled ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50}
					strokeOpacity={enabled ? "0.9" : "0.5"}
				/>
			</layouts.Flex>
			<layouts.Flex flex="1" mx="20px">
				<typography.h6
					whiteSpace="unset"
					fontSize="12px"
					fontWeight="400"
					color={enabled ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50}
					lineHeight="18px"
				>
					{desc}
				</typography.h6>
			</layouts.Flex>
			<layouts.Flex flex="1" alignItems="end" mb="28px">
				<typography.h5
					fontSize="16px"
					fontWeight="400"
					color={enabled ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50}
				>
					{title}
				</typography.h5>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Card.defaultProps = {
	cursor: "pointer",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	height: "260px",
	width: "240px",
}

export default Display

import * as httpx from "httpx"
import * as uuid from "uuid"
import { kernels } from "brandguard/api"
import { CancellablePromise } from "real-cancellable-promise"
import {
	TextKernelSettingsRequest,
	TextKernelSettingsResponse,
	TextKernelSearchRequest,
	TextKernelSearchResponse,
} from "brandguard/brandguard.text_pb"

import { TextKernelSetting } from "brandguard/brandguard.kernel_pb"

export namespace config {
	export function zero(d: Partial<TextKernelSetting> = {}): TextKernelSetting {
		return {
			id: "",
			threshold: 95,
			profanity: {
				enabled: false,
			},
			racism: {
				enabled: false,
			},
			grammar: {
				enabled: false,
			},
			tnv: {
				enabled: false,
				prompt: "",
			},
			brand_voice: {
				enabled: true,
			},
			reference_rules: {
				enabled: false,
				rules: [],
			},
			...d,
		}
	}

	export function latest(
		bid: string,
		id: string,
		...options: httpx.option[]
	): CancellablePromise<TextKernelSettingsResponse> {
		return httpx.get(
			`${httpx.urlstorage.host()}/brands/${bid}/bg/text/kernel/${id}/settings/${uuid.NIL}`,
			{},
			...options,
		)
	}
}

export namespace searches {
	export function request(d: Partial<TextKernelSearchRequest> = {}): TextKernelSearchRequest {
		return {
			offset: 0n,
			brand_id: "",
			limit: 100n,
			...d,
		}
	}

	export function response(d: Partial<TextKernelSearchResponse> = {}): TextKernelSearchResponse {
		return {
			next: request(),
			items: [],
			...d,
		}
	}
}

export function search(
	bid: string,
	req: TextKernelSearchRequest,
	...options: httpx.option[]
): CancellablePromise<TextKernelSearchResponse> {
	return httpx
		.get<TextKernelSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/kernel/`, req, ...options)
		.then((r) => ({
			...r,
			items: (r.items || []).map(kernels.metadata.zero),
		}))
}

export function create_version(
	bid: string,
	id: string,
	req: TextKernelSettingsRequest,
	...options: httpx.option[]
): CancellablePromise<TextKernelSettingsResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/kernel/${id}/settings`, req, ...options)
}

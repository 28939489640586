import React, { useState } from "react"
import styled from "@emotion/styled"
import classnames from "classnames"
import * as icons from "icons"
import * as containers from "./containers"

interface PagedProps {
	current: number
	total: number
	onChange: (n: number) => void
	className?: string
}

interface CursorProps<T> extends containers.FlexProps {
	current: T
	advance?: T
	onChange: (t: T) => void
}

const Container = styled(containers.flex)`
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
`

const IconContainer = styled(containers.span)`
	margin-right: 12px;
	cursor: pointer;
`

const StyledInput = styled.input`
	width: 20px;
	margin-right="8px"
	text-align: center;
`

export function Paged(props: React.PropsWithChildren<PagedProps>): JSX.Element {
	const [page, setPage] = useState(props.current)
	const { className, onChange, current, total } = props

	const previousDisabled = current === 1
	const nextDisabled = current === total

	function handleKeyDown(evt: React.KeyboardEvent<HTMLInputElement> & { target: { value?: string } }) {
		//first check that they hit enter, then check value is valid
		if (evt.key !== "Enter") return

		let newPage = parseInt(evt.target?.value || "1")
		if (newPage < 1) {
			newPage = 1
		}
		if (newPage > total) {
			newPage = total
		}

		onChange(newPage)
		setPage(newPage)
	}

	return (
		<Container alignItems="center" className={classnames("pagination", "paged", className)}>
			<IconContainer onClick={() => (previousDisabled ? null : onChange(1))}>
				<icons.chevrons.double_left disabled={previousDisabled} />
			</IconContainer>
			<IconContainer onClick={() => (previousDisabled ? null : onChange(current - 1))}>
				<icons.chevrons.left disabled={previousDisabled} />
			</IconContainer>
			<containers.span marginRight="12px">
				<StyledInput
					type="number"
					value={page}
					onChange={(evt) => {
						setPage(parseInt(evt.target.value))
					}}
					onKeyDown={(evt) => handleKeyDown(evt)}
				/>
			</containers.span>
			<IconContainer onClick={() => (nextDisabled ? null : onChange(current + 1))}>
				<icons.chevrons.right disabled={nextDisabled} />
			</IconContainer>
			<IconContainer onClick={() => (nextDisabled ? null : onChange(total))}>
				<icons.chevrons.double_right disabled={nextDisabled} />
			</IconContainer>
		</Container>
	)
}

const CursorAction = styled(containers.flex)`
	align-items: center;
	cursor: ${(props) => (props.onClick ? "pointer" : "not-allowed")};
`

// Cursor keeps track of previous positions
export function Cursor<T>(props: React.PropsWithChildren<CursorProps<T>>): JSX.Element {
	const [stack, setStack] = useState([] as Array<T>)
	const { className, onChange, current, advance, ...rest } = props

	const previousDisabled = stack.length === 0
	const nextDisabled = advance === undefined

	function onPrevious() {
		if (stack.length === 0) return

		let tmp = stack.pop()
		setStack(stack)

		if (tmp === undefined) return
		onChange(tmp)
	}

	function onNext() {
		if (!advance) return
		setStack(stack.concat(current))
		onChange(advance)
	}

	return (
		<Container alignItems="center" className={classnames("pagination", "cursor", className)} {...rest}>
			<CursorAction onClick={previousDisabled ? undefined : onPrevious} mr="12px">
				<icons.chevrons.arrow_left disabled={previousDisabled} />
			</CursorAction>
			<CursorAction onClick={nextDisabled ? undefined : onNext}>
				<icons.chevrons.arrow_right disabled={nextDisabled} />
			</CursorAction>
		</Container>
	)
}

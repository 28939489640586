import React, { useState, useMemo } from "react"
import { CancellablePromise } from "real-cancellable-promise"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as authzc from "authzcached"
import * as inputs from "inputs"
import * as icons from "icons"
import * as typography from "typography"
import * as errors from "errors"
import classnames from "classnames"
import * as authz from "authz"
import * as bgimages from "brandguard/display/brandguard/images"
import * as bglayouts from "brandguard/components/layouts"
import { UploadSimple } from "brandguard/components"
import * as predictions from "brandguard/predictions"
import * as nav from "brandguard/components/navigation"
import * as training from "brandguard/display/training"

function ModalTitle(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex flexDirection="row" overflow="hidden" justifyContent="space-between" {...rest}>
			{children}
			<layouts.containers.flex alignItems="center">
				<layouts.containers.flex onClick={() => mtoggle(undefined)}>
					<icons.Close width="15px" height="15px" fill={layouts.theme.colors.grey.dark50} fillOpacity="0.6" p="5px" />
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.Flex>
	)
}

interface importFromUrlProps extends layouts.containers.FlexProps {
	enqueue: (url: string) => Promise<api.ImportFromURLResponse>
}

export function URLModal(props: importFromUrlProps): JSX.Element {
	const { enqueue, ...rest } = props
	const mtoggle = layouts.modals.useToggle()

	const [domain, setDomain] = useState("")
	const disabled = inputs.assertions.failed(inputs.assertions.strings.url()(domain))
	const [cause, setCause] = useState(undefined as errors.Cause)

	return (
		<layouts.Flex
			flexDirection="column"
			overflow="hidden"
			styled
			p="20px"
			width="430px"
			borderRadius="10px"
			background={layouts.theme.colors.white}
			boxShadow={layouts.theme.boxshadow.black.wide}
			{...rest}
		>
			<ModalTitle>
				<layouts.containers.flex justifyContent="flex-start">
					<typography.h3 color={layouts.theme.colors.grey.medium}>Import from URL</typography.h3>
				</layouts.containers.flex>
			</ModalTitle>
			{cause && <layouts.containers.flex justifyContent="center">{cause}</layouts.containers.flex>}
			<layouts.Flex flexDirection="row">
				<layouts.Flex flex="1" py="20px" gridGap="10px">
					<inputs.Text
						autoFocus
						placeholder="https://example.com"
						onChange={(evt) => setDomain(evt.currentTarget.value)}
						className={training.layouts.styledTextInput}
					/>
					<layouts.buttons.primary
						width="10ch"
						borderRadius="5px"
						disabled={disabled}
						onClick={() => {
							enqueue(domain)
								.catch((c: unknown) => {
									console.error("unable to enqueue images from URL suggestions", cause)
									setCause(
										<errors.Inline flex="1" p="5px" mb="5px">
											<errors.Textual cause={c} onClick={() => setCause(undefined)}>
												<layouts.Span color={layouts.theme.colors.white} fontSize="12px">
													Unable to Import from URL
												</layouts.Span>
											</errors.Textual>
										</errors.Inline>,
									)
									return Promise.reject(c)
								})
								.then(() => {
									mtoggle(undefined)
								})
						}}
					>
						Import
					</layouts.buttons.primary>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface importStokProps extends layouts.containers.FlexProps {
	enqueue: (num: number) => Promise<api.StockImportResponse>
}

export function StockModal(props: importStokProps): JSX.Element {
	const { enqueue, ...rest } = props

	const [num, setNum] = useState(20)
	const assertions = [inputs.assertions.numeric.min(1)(num), inputs.assertions.numeric.max(100)(num)]
	const disabled = inputs.assertions.failed(...assertions)
	const mtoggle = layouts.modals.useToggle()
	const [cause, setCause] = useState(undefined as errors.Cause)
	return (
		<layouts.Flex
			flexDirection="column"
			overflow="hidden"
			styled
			p="20px"
			width="430px"
			borderRadius="10px"
			background={layouts.theme.colors.white}
			boxShadow={layouts.theme.boxshadow.black.wide}
			{...rest}
		>
			<ModalTitle>
				<layouts.containers.flex justifyContent="flex-start">
					<typography.h3 color={layouts.theme.colors.grey.medium}>Import Stock Photos</typography.h3>
				</layouts.containers.flex>
			</ModalTitle>
			{cause && <layouts.containers.flex justifyContent="center">{cause}</layouts.containers.flex>}
			<layouts.Flex flexDirection="row">
				<layouts.Flex flex="1" py="20px" gridGap="10px">
					<inputs.Numeric
						autoFocus
						placeholder="# of stock images"
						defaultValue={num}
						min={1}
						max={100}
						onChange={(evt) => setNum(parseInt(evt.currentTarget.value))}
						className={training.layouts.styledTextInput}
					/>
					<layouts.buttons.primary
						width="10ch"
						borderRadius="5px"
						disabled={disabled}
						onClick={() => {
							enqueue(num)
								.catch((cause) => {
									console.error("unable to enqueue images from stock photos", cause)
									setCause(
										<errors.Inline flex="1" p="5px" mb="5px">
											<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
												<layouts.Span color={layouts.theme.colors.white} fontSize="12px">
													Unable to Import Stock Photos
												</layouts.Span>
											</errors.Textual>
										</errors.Inline>,
									)
									return Promise.reject(cause)
								})
								.then(() => {
									mtoggle(undefined)
								})
						}}
					>
						Import
					</layouts.buttons.primary>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface importGoogleImagesProps extends layouts.containers.FlexProps {
	enqueue: (keyword: string, num: number) => Promise<api.GoogleImagesResponse>
}

export function GoogleImagesModal(props: importGoogleImagesProps): JSX.Element {
	const { enqueue, ...rest } = props
	const mtoggle = layouts.modals.useToggle()

	const [keywordsearch, setKeywordSearch] = useState("")
	const [num, setNum] = useState(20)
	const assertions = [
		inputs.assertions.strings.min(3)(keywordsearch),
		inputs.assertions.numeric.max(100)(num),
		inputs.assertions.numeric.min(1)(num),
	]
	const disabled = inputs.assertions.failed(...assertions)
	const [cause, setCause] = useState(undefined as errors.Cause)
	return (
		<layouts.Flex
			flexDirection="column"
			overflow="hidden"
			styled
			p="20px"
			width="465px"
			borderRadius="10px"
			background={layouts.theme.colors.white}
			boxShadow={layouts.theme.boxshadow.black.wide}
			{...rest}
		>
			<ModalTitle>
				<layouts.containers.flex justifyContent="flex-start">
					<typography.h3 color={layouts.theme.colors.grey.medium}>Import from Google Images</typography.h3>
				</layouts.containers.flex>
			</ModalTitle>
			{cause && <layouts.containers.flex justifyContent="center">{cause}</layouts.containers.flex>}
			<layouts.Flex flexDirection="row">
				<layouts.Flex flex="1" py="20px" gridGap="10px">
					<inputs.Text
						autoFocus
						placeholder="An old man sitting at a bench"
						onChange={(evt) => setKeywordSearch(evt.currentTarget.value)}
						className={training.layouts.styledTextInput}
					/>
					<inputs.Numeric
						placeholder="# of Google Images"
						onChange={(evt) => setNum(parseInt(evt.currentTarget.value))}
						className={training.layouts.styledTextInput}
					/>
					<layouts.buttons.primary
						width="10ch"
						borderRadius="5px"
						disabled={disabled}
						onClick={() => {
							enqueue(keywordsearch, num)
								.catch((cause) => {
									console.error("unable to display image from Google Image suggestions", cause)
									setCause(
										<errors.Inline flex="1" p="5px" mb="5px">
											<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
												<layouts.Span color={layouts.theme.colors.white} fontSize="12px">
													Unable to Import from Google Images
												</layouts.Span>
											</errors.Textual>
										</errors.Inline>,
									)
									return Promise.reject(cause)
								})
								.then(() => {
									mtoggle(undefined)
								})
						}}
					>
						Import
					</layouts.buttons.primary>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface fileProps extends layouts.containers.FlexProps {
	onChange(t: api.ImageSearchResponseItem): void
	resume(): CancellablePromise<inputs.uploads.api.uploaded<api.ImageSearchResponseItem>[]>
	refresh(data: api.ImageSearchResponseItem): CancellablePromise<api.ImageSearchResponseItem>
	upload(stats: inputs.uploads.api.statistics, content: File): CancellablePromise<api.ImageSearchResponseItem>
}

export function FileModal(props: fileProps): JSX.Element {
	const { onChange, resume, refresh, upload, ...rest } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const mtoggle = layouts.modals.useToggle()

	const MemoUpload = useMemo(() => {
		return function MemodUpload(props: inputs.uploads.api.Props) {
			return (
				<authz.Protected
					enabled={permission.brandguard_upload}
					rejected={
						<layouts.containers.flex flex="1" className={classnames(bglayouts.uploads.navdenied)}>
							<authz.layouts.UploadDenied className="styled-area">
								You do not have permissions to upload new assets for scoring. Please contact an admin.
							</authz.layouts.UploadDenied>
						</layouts.containers.flex>
					}
				>
					<bgimages.uploads.default
						background={layouts.theme.colors.blue.blue}
						observation={api.Prediction.NONE}
						{...props}
					/>
				</authz.Protected>
			)
		}
	}, [])

	return (
		<UploadSimple
			className={classnames(nav.layouts.slideDownAnimation)}
			title="Upload Assets"
			isProcessing={(i: api.ImageSearchResponseItem) => predictions.processing(i.event?.approved)}
			onChange={onChange}
			resume={resume}
			refresh={refresh}
			upload={upload}
			DropWell={MemoUpload}
			onClose={() => mtoggle(undefined)}
			{...rest}
		/>
	)
}

import React, { useEffect } from "react"
import * as lscache from "lscache"
import * as timex from "timex"

export default function Autoflush(props: React.PropsWithChildren<unknown>): JSX.Element {
	const period = timex.duration.iso("PT1M").toMillis()
	useEffect(() => {
		const id = setInterval(() => {
			console.debug("flushing expired cache")
			lscache.resetBucket()
			lscache.flushExpired()
		}, period)
		return () => clearInterval(id)
	}, [])

	return <>{props.children}</>
}

import * as httpx from "httpx"
import * as uuid from "uuid"
import * as text from "."
import { CancellablePromise } from "real-cancellable-promise"
import {
	TextUploadResponse,
	TextSearchRequest,
	TextSearchResponse,
	TextFindResponse,
	TextPatchRequest,
	TextPatchResponse,
} from "brandguard/brandguard.text_pb"

export function create(file: File, bid: string, ...options: httpx.option[]): CancellablePromise<TextUploadResponse> {
	const data = new FormData()
	data.append("file", file)
	return httpx
		.upload<TextUploadResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}`, data, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function searchZero(sr: Partial<TextSearchRequest> = {}): TextSearchRequest {
	return {
		offset: 0n,
		limit: 20n,
		deprecated_uploaded_by: "",
		uploaded_by: [],
		brand_id: uuid.NIL,
		queues: [],
		approved: [],
		observation: [],
		confidence: { min: 0, max: 1 },
		...sr,
	}
}

export function search(
	bid: string,
	req: TextSearchRequest,
	...options: httpx.option[]
): CancellablePromise<TextSearchResponse> {
	return httpx.get<TextSearchResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}`, req, ...options).then((resp) => ({
		...resp,
		items: (resp.items || []).map((item) => ({ media: item.media, event: text.EventZero(item.event) })),
		next: searchZero(resp.next),
	}))
}

export function find(id: string, bid: string, ...options: httpx.option[]): CancellablePromise<TextFindResponse> {
	return httpx
		.get<TextFindResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function patch(
	id: string,
	bid: string,
	req: TextPatchRequest,
	...options: httpx.option[]
): Promise<TextPatchResponse> {
	return httpx
		.patch<TextPatchResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}/${id}`, req, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function requeue(id: string, bid: string, ...options: httpx.option[]): Promise<TextFindResponse> {
	return httpx
		.post<TextFindResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}/${id}/requeue`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function review(id: string, bid: string, ...options: httpx.option[]): Promise<TextPatchResponse> {
	return httpx
		.post<TextPatchResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}/${id}/review`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function publish(id: string, bid: string, ...options: httpx.option[]): Promise<TextPatchResponse> {
	return httpx
		.post<TextPatchResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}/${id}/publish`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function destroy(bid: string, id: string, ...options: httpx.option[]): Promise<TextPatchResponse> {
	return httpx
		.destroy<TextPatchResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

import * as httpx from "httpx"
import * as metasearch from "metasearch"
import { SearchRequest, SearchResponse } from "./brandguard.metrics_pb"
import { GroupBy } from "metasearch/meta.search.filters"
import { CancellablePromise } from "real-cancellable-promise"

import * as uuid from "uuid"

export function SearchZero(proto: Partial<SearchRequest> = {}): SearchRequest {
	return {
		brand_id: uuid.NIL,
		range: metasearch.dateranges.everything(),
		group_by: GroupBy.UPLOADER,
		...proto,
	}
}

export function search_approvals(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<SearchResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/brands/${req.brand_id}/bg/metrics/approvals`, req, ...options)
}

export function search_training(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<SearchResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/brands/${req.brand_id}/bg/metrics/training`, req, ...options)
}

import * as typography from "typography"
import * as brandguide from "brandguide"
import { colors } from "layouts/theme"
import { PropsWithChildren } from "react"
import { fontFamily } from "."

interface typographyProps extends typography.TypographyProps {
	font: brandguide.api.FontObject
	text_color?: string
}

export function H1MainText(props: PropsWithChildren<typographyProps>): JSX.Element {
	const { font, text_color, children, ...rest } = props
	return (
		<typography.h1 {...rest} fontFamily={fontFamily(font)} color={text_color}>
			{children}
		</typography.h1>
	)
}

H1MainText.defaultProps = {
	fontSize: "calc(4vw + 4vh + .5vmin)",
	fontWeight: "400",
	lineHeight: "normal",
	letterSpacing: "normal",
	text_color: colors.grey.dark50alpha80,
}

import * as httpx from "httpx"
import * as proto from "./meta.profile"
import { CancellablePromise } from "real-cancellable-promise"

export * as proto from "./meta.profile"

export enum StatusFilters {
	All = 0, // eslint-disable-line no-unused-vars
	Enabled = 1, // eslint-disable-line no-unused-vars
	Disabled = 2, // eslint-disable-line no-unused-vars
	Pending = 3, // eslint-disable-line no-unused-vars
	NotPending = 4, // eslint-disable-line no-unused-vars
}

export interface AuthzResponse {
	token: string
	view: boolean
	edit: boolean
}

// authz returns the authorization credentials for the current profile.
export function authz(c: httpx.Client): Promise<AuthzResponse> {
	return c.get(`${httpx.urlstorage.host()}/u12t/authz`)
}

export namespace searches {
	export function zero(d: Partial<proto.SearchRequest> = {}): proto.SearchRequest {
		return {
			query: "",
			offset: 0n,
			limit: 0n,
			enabled: StatusFilters.All,
			created: undefined,
			...d,
		}
	}

	export function pzero(d: Partial<proto.SearchRequest> = {}): proto.SearchRequest {
		return {
			query: "",
			offset: 0n,
			limit: 0n,
			enabled: StatusFilters.Pending,
			created: undefined,
			...d,
		}
	}
}

// search profiles
export function search(req: proto.SearchRequest, ...options: httpx.option[]): CancellablePromise<proto.SearchResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/u12t`, req, ...options)
}

export function create(req: proto.CreateRequest, ...options: httpx.option[]): Promise<proto.CreateResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/u12t`, req, ...options)
}

// update a profile
export function update(req: proto.UpdateRequest, ...options: httpx.option[]): Promise<proto.UpdateResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/u12t/${req.profile?.id}`, req, ...options)
}

// find a profile
export function find(pid: string, ...options: httpx.option[]): CancellablePromise<proto.Profile> {
	return httpx.get(`${httpx.urlstorage.host()}/u12t/${pid}`, {}, ...options)
}

import React from "react"
import JSZip from "jszip"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as sessions from "sessions"
import * as api from "brandguard/api"
import * as cache from "media/cache"
import * as media from "media"
import * as bglayouts from "brandguard/components/layouts"

interface props {
	uploadItems: api.ImageSearchResponseItem[]
	historicalItems: api.ImageSearchResponseItem[]
	deleteAction?(
		bid: string,
		id: string,
		req: api.ImagePatchRequest,
		...options: httpx.option[]
	): Promise<api.ImagePatchResponse>
	onDelete?(items: api.ImageSearchResponseItem[]): void
}

export default function MultiActions(props: React.PropsWithChildren<props>): JSX.Element {
	const { uploadItems, historicalItems, deleteAction, onDelete, children } = props
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const bearertoken = sessions.useToken()
	const allItems = [...uploadItems, ...historicalItems]

	const handleDelete = (): void => {
		const promises: Promise<api.ImagePatchRequest>[] = []
		uploadItems.forEach((i) => {
			i.media!.tombstoned_at = new Date().toISOString()
			promises.push(deleteAction!(i.media!.brand_id, i.media!.id, i, authzc.bearer(authzaccount)))
		})
		Promise.all(promises)
			.then((results) => {
				onDelete!(results)
			})
			.catch((cause) => console.error("unable to multi-select brandguard images", cause))
	}

	const handleDownload = (): void => {
		if (uploadItems.length === 1) {
			downloadSingle(uploadItems[0])
			return
		}

		const zip = new JSZip()

		const downloadZip = (): void => {
			zip.generateAsync({ type: "blob" }).then((content) => {
				const downloadLink = document.createElement("a")
				downloadLink.href = URL.createObjectURL(content)
				downloadLink.download = "brandguard-assets.zip"
				downloadLink.click()
			})
		}

		const promises: Promise<api.ImagePatchRequest | JSZip>[] = []
		allItems.forEach((i) => {
			promises.push(
				cache.current(authzaccount.current.id, i.media!.md5, bearertoken).then((url) => {
					return fetch(url)
						.then((response) => {
							return response.arrayBuffer()
						})
						.then((imageBuffer) => {
							const imageName = media.downloads.filename(i.media!.md5, i.media!.mimetype)
							return zip.file(imageName, imageBuffer)
						})
				}),
			)
		})

		Promise.all(promises).then(() => {
			downloadZip()
		})
	}

	const downloadSingle = (i: api.ImageSearchResponseItem): void => {
		media.downloads.file(
			i.media!.md5,
			i.media!.mimetype,
			cache.current(authzaccount.current.id, i.media!.md5, bearertoken),
		)
	}

	if (!allItems.length) return <></>

	return (
		<bglayouts.DisplayActions
			cnt={allItems.length}
			canDelete={uploadItems.length > 0 && deleteAction !== undefined && onDelete !== undefined}
			handleDelete={handleDelete}
			handleDownload={handleDownload}
		>
			{children}
		</bglayouts.DisplayActions>
	)
}

MultiActions.defaultProps = {
	uploadItems: [],
	historicalItems: [],
}

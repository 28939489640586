import mime from "mime"

export function filename(name: string, mimetype: string): string {
	return `${name}.${mime.getExtension(mimetype) || "bin"}`
}

export function file(name: string, mimetype: string, pending: Promise<string>): Promise<void> {
	return pending.then((url) => {
		return fetch(url)
			.then((response) => {
				return response.blob()
			})
			.then((blob) => {
				const tmpEl = document.createElement("a")
				tmpEl.href = URL.createObjectURL(blob)
				tmpEl.download = filename(name, mimetype)
				tmpEl.click()
				tmpEl.remove()
			})
	})
}

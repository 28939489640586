import React from "react"
import * as layouts from "layouts"
import * as api from "./api"

interface props extends layouts.containers.FlexProps {
	token: api.tokens.Secret
	cred: api.credentials.Secret
}

export default function Display(props: props): JSX.Element {
	const { token, cred, ...rest } = props

	return (
		<layouts.containers.flex className="oauth2.token.display" flexDirection="column" {...rest}>
			<layouts.forms.Group flex="1" gridGap="10px">
				<layouts.forms.Label width="30ch">Client ID</layouts.forms.Label>
				<layouts.containers.span ml="auto">{cred.id}</layouts.containers.span>
			</layouts.forms.Group>
			<layouts.forms.Group flex="1" gridGap="10px">
				<layouts.forms.Label width="30ch">Client Secret</layouts.forms.Label>
				<layouts.containers.span ml="auto">{cred.secret}</layouts.containers.span>
			</layouts.forms.Group>
			<layouts.forms.Group>
				<layouts.forms.Label width="30ch">Refresh Token</layouts.forms.Label>
				<layouts.containers.span ml="auto">{token.refresh_token}</layouts.containers.span>
			</layouts.forms.Group>
		</layouts.containers.flex>
	)
}

import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import { results } from "brandguide/build/layouts"
import { breakdowns } from "brandguide/build/archetype/templates"
import { css } from "@emotion/css"
import * as archetype from "brandguide/build/archetype"

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const data = brandguide.api.zeros.guide.guideArchetypeZero(guide.guide?.archetype)

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Archetype</result_components.NavTitle>
				<result_components.NavDescription>
					A brand archetype is a symbolic representation of a brand&apos;s personality, values, and characteristics. It
					serves as a relatable character for the audience, guiding how the brand is perceived, expressed, and
					understood. Understanding the archetype informs the brand&apos;s voice, appearance, and behavior, as well as
					its values and worldview, enabling the creation of a cohesive brand personality and guiding future brand
					strategy.
					<br />
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.selection} />
				<result_components.Explanation desc={data.descriptions?.breakdown} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer gridGap="2%">
					<layouts.Flex flex="1" flexDirection="column">
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px">
								Archetype
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex flex="1" flexDirection="column">
							{data.adjectives.map((i) => (
								<BreakDownItem key={i.archetype} item={i} />
							))}
						</layouts.Flex>
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

interface breakdownProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ArchetypeAdjectiveObject
}

const styledIcon = css`
	svg {
		path {
			stroke: ${layouts.theme.colors.white};
			fill: ${layouts.theme.colors.white};
			fill-opacity: 1;
			stroke-opacity: 1;
		}
	}
`

function BreakDownItem(props: breakdownProps): JSX.Element {
	const { item, ...rest } = props
	const breakdownItem = breakdowns.find((i) => i.archetype === item.archetype)

	if (!breakdownItem) return <></>

	return (
		<layouts.Flex flex="1" flexDirection="column" {...rest}>
			<results.Title>{breakdownItem.title}</results.Title>
			<layouts.Flex flex="1" color={layouts.theme.colors.grey.dark50} gridGap="2%">
				<layouts.Flex>
					<layouts.Flex
						justifyContent="center"
						alignItems="center"
						height="20vh"
						width="20vh"
						borderRadius="5px"
						background={layouts.theme.colors.grey.dark50}
						className={styledIcon}
						position="relative"
					>
						<layouts.containers.absolute right="0" top="0">
							<layouts.Flex p="5px" alignItems="center" justifyContent="center">
								<typography.h5 color={layouts.theme.colors.white}>{item.percent.toFixed(1)}%</typography.h5>
							</layouts.Flex>
						</layouts.containers.absolute>
						<layouts.Flex>{breakdownItem.icon}</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
				<layouts.Flex flexDirection="column">
					<typography.h5 whiteSpace="unset" fontWeight="400" fontSize="1.5vh" lineHeight="20px">
						{breakdownItem.description}
						<br />
						<b style={{ fontWeight: "700" }}>Communication</b>
						<br />
						{breakdownItem.communication}
					</typography.h5>
					<layouts.Flex flex="1">
						<layouts.Flex
							flex="1"
							flexDirection="column"
							borderRight={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`}
							alignItems="center"
							justifyContent="center"
						>
							<archetype.layouts.ColumnTitle color={layouts.theme.colors.grey.dark50alpha80} fontSize="1.5vh">
								What motivates you
							</archetype.layouts.ColumnTitle>
							{breakdownItem.motivates.map((i) => (
								<archetype.layouts.ColumnList key={i} fontSize="1.5vh" lineHeight="2vh">
									{i}
								</archetype.layouts.ColumnList>
							))}
						</layouts.Flex>
						<layouts.Flex flex="1" flexDirection="column" alignItems="center" justifyContent="center">
							<archetype.layouts.ColumnTitle mb="12px" color={layouts.theme.colors.grey.dark50alpha80} fontSize="1.5vh">
								What scares you
							</archetype.layouts.ColumnTitle>
							{breakdownItem.scares.map((i) => (
								<archetype.layouts.ColumnList key={i} fontSize="1.5vh" lineHeight="2vh">
									{i}
								</archetype.layouts.ColumnList>
							))}
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import * as layouts from "layouts"
import * as navigation from "navigation"
import * as errors from "errors"
import * as authzc from "authzcached"
import * as brands from "brands"
import * as brandguard from "brandguard"

interface Kernels {
	image: brandguard.api.ImageKernelSetting
	text: brandguard.api.TextKernelSetting
}

interface DemoBrandButtonProps {
	brand: brands.Brand
	kernels: Kernels
	disabled: boolean
	onGenerate(pending: Promise<brands.Brand>): void
}

function DemoBrandButton(props: React.PropsWithChildren<DemoBrandButtonProps>): JSX.Element {
	const { brand, kernels, disabled, onGenerate, children } = props
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const generate_demo_brand = (): Promise<brands.Brand> => {
		return brands.api
			.create({ brand: brand }, authzc.bearer(authzaccount))
			.then((r) => {
				return brands.api.zero(r.brand)
			})
			.then((b) => {
				return brandguard.api.images.kernel
					.create_version(b.id, b.id, { settings: kernels.image }, authzc.bearer(authzaccount))
					.then((r) => b)
			})
			.then((b) => {
				return brandguard.api.text.kernel
					.create_version(b.id, b.id, { settings: kernels.text }, authzc.bearer(authzaccount))
					.then((r) => b)
			})
	}

	return (
		<layouts.buttons.outline
			disabled={disabled}
			onClick={() => {
				onGenerate(generate_demo_brand())
			}}
		>
			{children}
		</layouts.buttons.outline>
	)
}

export function Demo(): JSX.Element {
	const [loading, setLoading] = useState(false)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const navigate = useNavigate()
	const genbrand = (pending: Promise<brands.Brand>): void => {
		setLoading(true)
		pending
			.then((b) => {
				setLoading(false)
				brands.api.setLastBrandId(b.account_id, b.id)
				setImmediate(() => navigate(brands.routing.root(b.id)))
			})
			.catch((cause) => {
				setLoading(false)
				setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
			})
	}

	return (
		<errors.overlay styled cause={cause}>
			<layouts.containers.flex
				className="demo brands"
				styled
				m="auto"
				flex="1"
				p="20px"
				width="512px"
				justifyContent="space-around"
			>
				<DemoBrandButton
					brand={brands.api.zero({
						description: "Coca-Cola Demo",
						domain: "https://us.coca-cola.com/",
					})}
					kernels={{
						image: brandguard.api.images.kernel.config.zero(),
						text: brandguard.api.text.kernel.config.zero(),
					}}
					disabled={loading}
					onGenerate={genbrand}
				>
					Coca-Cola
				</DemoBrandButton>
				<DemoBrandButton
					brand={brands.api.zero({
						description: "Nike Demo",
						domain: "https://nike.com/",
					})}
					kernels={{
						image: brandguard.api.images.kernel.config.zero(),
						text: brandguard.api.text.kernel.config.zero(),
					}}
					disabled={loading}
					onGenerate={genbrand}
				>
					Nike
				</DemoBrandButton>
			</layouts.containers.flex>
		</errors.overlay>
	)
}

export default function Container(): JSX.Element {
	return (
		<layouts.backgrounds.Grey className="background-grey" flex="0 1 100%" background={layouts.theme.colors.grey.bg}>
			<navigation.Standard />
			<layouts.containers.flex className="setup" flex="1" overflowY="hidden">
				<layouts.modals.Root initialized={<Demo />}>
					<brands.Grid nobrands={<></>} />
				</layouts.modals.Root>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

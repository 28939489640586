import React from "react"
import * as layouts from "layouts"

interface props extends layouts.containers.ContainerProps, layouts.containers.DefaultStyling {
	cause?: JSX.Element
}

export function Inline(props: React.PropsWithChildren<props>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.containers.box p="20px" background={layouts.theme.colors.orange} mb="15px" {...rest}>
			{children}
		</layouts.containers.box>
	)
}

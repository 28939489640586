import { NavLink } from "react-router-dom"
import * as icons from "icons"
import * as layouts from "layouts"

export function Logo(props: layouts.containers.ContainerProps): JSX.Element {
	return (
		<layouts.containers.span {...props}>
			<NavLink to="/">
				<icons.Logo height="50px" />
			</NavLink>
		</layouts.containers.span>
	)
}

import { CancellablePromise } from "real-cancellable-promise"
import * as uuid from "uuid"
import { DateTime } from "luxon"
import * as httpx from "httpx"

import { Notification, SearchRequest, SearchResponse, UpdateResponse } from "./brandguard.notifications"
import { Event } from "./meta.notification"
import { Prediction } from "./brandguard.approval"
export { Notification, SearchRequest, SearchResponse, UpdateResponse } from "./brandguard.notifications"
export { Event } from "./meta.notification"
export { Prediction } from "./brandguard.approval"

export namespace zeros {
	export function search(r: Partial<SearchRequest> = {}): SearchRequest {
		return {
			limit: 20n,
			offset: 0n,
			...r,
		}
	}

	export function notification(n: Partial<Notification> = {}): Notification {
		let ts = DateTime.now()
		return {
			id: uuid.NIL,
			account_id: uuid.NIL,
			brand_id: uuid.NIL,
			initiator_id: uuid.NIL,
			profile_id: uuid.NIL,
			viewed_at: ts.toISO(),
			created_at: ts.toISO(),
			tombstoned_at: ts.toISO(),
			event: Event.UNKNOWN,
			event_id: uuid.NIL,
			event_prediction: Prediction.NONE,
			...n,
		}
	}
}

export function search(
	bid: string,
	req: SearchRequest,
	...options: httpx.option[]
): CancellablePromise<SearchResponse> {
	return httpx
		.get<SearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/notifications`, req, ...options)
		.then((resp) => ({ ...resp, items: resp.items.map(zeros.notification) }))
}

export function unread_cnt(bid: string, ...options: httpx.option[]): CancellablePromise<{ total: number }> {
	return httpx
		.get<{ total: number }>(`${httpx.urlstorage.host()}/brands/${bid}/notifications/unread_cnt`, {}, ...options)
		.then((resp) => ({ total: resp.total || 0 }))
}

export function set_as_read(bid: string, unid: string, ...options: httpx.option[]): CancellablePromise<UpdateResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/brands/${bid}/notifications/set_as_read/${unid}`, {}, ...options)
}

export function destroy(bid: string, id: string, ...options: httpx.option[]): CancellablePromise<UpdateResponse> {
	return httpx.destroy(`${httpx.urlstorage.host()}/brands/${bid}/notifications/${id}`, {}, ...options)
}

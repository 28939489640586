import * as caching from "caching"
import * as timex from "timex"
import * as httpx from "httpx"
import * as api from "./api"
import { CancellablePromise } from "real-cancellable-promise"

export const cache = new caching.Cache({
	namespace: "novacloud.media.urls",
	ttl: timex.duration.iso("PT48H").toMillis(),
})

export function cachable(mid: string, ...options: httpx.option[]): CancellablePromise<string> {
	const retry = httpx.autoretry()
	return retry.wrap(() => api.url(mid, ...options)).then((r) => r.url)
}

// get or load a media url by id.
export function current(aid: string, mid: string, ...options: httpx.option[]): CancellablePromise<string> {
	return cache.maybe(`${aid}.${mid}`, () => cachable(mid, ...options))
}

export default cache

// min asserts at least a minimum number of charaters.
export function min<T>(n: number, invalid: JSX.Element = <>too short</>): (i: T[]) => JSX.Element | undefined {
	return (i: T[]) => (i.length >= n ? undefined : invalid)
}

export function unique<T>(
	id: (x: T) => string,
	invalid: JSX.Element = <>contains duplicate values</>,
): (i: T[]) => JSX.Element | undefined {
	return (i: T[]): JSX.Element | undefined => {
		const dups = i.reduce((dups, v) => {
			const uniq = id(v)
			dups[uniq] = (dups[uniq] || 0) + 1
			return dups
		}, {} as { [key: string]: number })

		return Object.values(dups).every((v) => v === 1) ? undefined : invalid
	}
}

// checks every element
export function every<T>(id: (x: T) => JSX.Element | undefined): (i: T[]) => JSX.Element | undefined {
	return (i: T[]): JSX.Element | undefined => {
		const invalid = i.find((v) => id(v) !== undefined)
		return invalid ? id(invalid) : undefined
	}
}

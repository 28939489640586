import { colors } from "layouts/theme"
import * as typography from "typography"

function calculateLineHeight(text: string): string {
	const maxSymbols = 1000
	const minLineHeight = 12
	const maxLineHeight = 20

	const symbolsCount = text.length
	const lineHeight = maxLineHeight - ((maxLineHeight - minLineHeight) * symbolsCount) / maxSymbols

	return `${Math.max(lineHeight, minLineHeight)}px`
}

export default function Explanation(props: { desc: string | undefined }): JSX.Element {
	const { desc } = props
	if (!desc) return <></>
	return (
		<typography.h5
			my="5px"
			fontSize="10px"
			fontWeight="400"
			letterSpacing="unset"
			lineHeight={calculateLineHeight(desc)}
			color={colors.grey.dark50}
			whiteSpace="pre-wrap"
		>
			{desc}
		</typography.h5>
	)
}

import * as svg from "./svg"
import * as theme from "layouts/theme"

export const download = (props: svg.SVGProps) => {
	let { stroke, ...rest } = props
	return (
		<svg.SVG width="28" height="28" viewBox="0 0 28 28" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8 15.8143L8 18.4779C8 18.8816 8.15804 19.2687 8.43934 19.5542C8.72064 19.8396 9.10218 20 9.5 20H18.5C18.8978 20 19.2794 19.8396 19.5607 19.5542C19.842 19.2687 20 18.8816 20 18.4779V15.8143M14.0008 7V15.6251M14.0008 15.6251L17.4294 12.3295M14.0008 15.6251L10.5723 12.3295"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg.SVG>
	)
}

download.defaultProps = {
	stroke: theme.colors.grey.dark50,
}

import * as httpx from "httpx"
import * as sessions from "sessions"
import * as uuid from "uuid"
import { OAuth2Client } from "@badgateway/oauth2-client"
import * as sha from "hash.js"
import * as b64 from "js-base64"
export namespace endpoints {
	export function zero(d: Partial<sessions.api.Endpoint>): sessions.api.Endpoint {
		return {
			display: "",
			url: "",
			...d,
		}
	}
}

export namespace oauth2 {
	// oauth2-client library doesn't export this type. define the interface
	// for use.
	export interface ClientSettings {
		server: string
		clientId: string
		clientSecret: string
		authorizationEndpoint: string
		tokenEndpoint: string
	}

	// compute the challenge
	export function challenge(verifier: string): string {
		return b64
			.fromUint8Array(new Uint8Array(sha.sha256().update(verifier).digest()))
			.replace(/\+/g, "-")
			.replace(/\//g, "_")
			.replace(/=+$/, "")
	}

	export function nsuiteclient(d: Partial<ClientSettings> = {}): OAuth2Client {
		return new OAuth2Client({
			server: httpx.urlstorage.host(),
			clientId: uuid.NIL, // our oauth2 server library chokes if the clientId is a empty string, default to uuid.NIL for PKCE flows.
			authorizationEndpoint: "/oauth2/authorize",
			tokenEndpoint: "/oauth2/token",
			...d,
		})
	}

	export function consumeotp(token: string): Promise<{ code: string }> {
		return httpx.post<{ code: string }>(`${httpx.urlstorage.host()}/oauth2/otp`, {}, httpx.options.bearer(token))
	}
}

import { CancellablePromise } from "real-cancellable-promise"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as metasso from "./meta.sso"
import * as localstoragex from "localstoragex"

export type {
	LoginOptions,
	Identity,
	Authed,
	Authn,
	AccountWithToken,
	AvailableRequest,
	AvailableResponse,
	JoinResponse,
} from "./meta.sso"

const sessionstoragekey = "nsuite.session"
export const sessionlastloginkey = "nsuite.last.login"

export interface Endpoint {
	display: "Microsoft" | "GSuite" | "Facebook" | ""
	url: string
}
export interface Login {
	options: string
	endpoints: Endpoint[]
}

export function localtoken(account_id: string): string {
	return window.localStorage.getItem(`${sessionstoragekey}.${account_id}`) || ""
}

export function lasttoken(): string {
	return window.localStorage.getItem(sessionlastloginkey) || ""
}

export function destroylasttoken(): void {
	window.localStorage.removeItem(sessionlastloginkey)
}

export function storelocaltoken(account_id: string, s: string): string {
	window.localStorage.setItem(`${sessionstoragekey}.${account_id}`, s)
	return s
}

export function storelasttoken(s: string): string {
	window.localStorage.setItem(sessionlastloginkey, s)
	return s
}

export namespace browserExtension {
	export async function lasttoken(): Promise<string> {
		return localstoragex.getItem(sessionlastloginkey)
	}
}

export namespace loginopts {
	export function from<T extends metasso.LoginOptions>(
		d: T,
	): [metasso.LoginOptions, Omit<T, "id" | "autologin" | "redirect" | "account_id">] {
		const { id, autologin, redirect, account_id, ...rest } = d
		const opts = {
			id: id,
			autologin: autologin,
			redirect: redirect,
			account_id: account_id,
		}

		return [opts, rest]
	}

	export function zero(d: Partial<metasso.LoginOptions> = {}): metasso.LoginOptions {
		return {
			id: "",
			autologin: true,
			redirect: "",
			account_id: "",
			...d,
		}
	}

	/**
	 * @returns login options and a signed token for backend consumption.
	 */
	export function get(
		path: string,
		req: metasso.LoginOptions,
		...options: httpx.option[]
	): CancellablePromise<metasso.LoginOptions> {
		return httpx.get(`${httpx.urlstorage.host()}/authn/s/${path}`, req, ...options)
	}
}
/**
 * @returns the information required to build our login panel.
 */
export function generate(req: metasso.LoginOptions): CancellablePromise<Login> {
	return httpx.get(`${httpx.urlstorage.host()}/authn/`, req)
}

/**
 * @returns the current session information
 */
export function current(...options: httpx.option[]): CancellablePromise<CurrentSession> {
	return httpx.get(`${httpx.urlstorage.host()}/authn/current`, {}, ...options)
}

/**
 * @returns the current session information
 */
export function login(...options: httpx.option[]): CancellablePromise<CurrentSession> {
	return httpx.get(`${httpx.urlstorage.host()}/authn/login`, {}, ...options)
}

/**
 * @returns the current session information after signup
 */
export function signup(...options: httpx.option[]): CancellablePromise<CurrentSession> {
	return httpx.post(`${httpx.urlstorage.host()}/authn/signup`, {}, ...options)
}

/**
 * @returns the current session information after registering a user with a particular account.
 */
export function register(...options: httpx.option[]): CancellablePromise<CurrentSession> {
	return httpx.post(`${httpx.urlstorage.host()}/authn/register`, {}, ...options)
}

/**
 * takes a subject id (typically a uuid) and generates a signed otp.
 * @returns a jwt one time pass token
 */
export function onetimepass(subject: string, ...options: httpx.option[]): CancellablePromise<{ token: string }> {
	return httpx.get<{ token: string }>(`${httpx.urlstorage.host()}/authn/otpgen/${subject}`, {}, ...options)
}

export namespace accounts {
	export namespace available {
		/**
		 * @returns a list of accounts that the end user has outstanding invitations for.
		 */
		export async function list(
			req: metasso.AvailableRequest,
			...options: httpx.option[]
		): Promise<metasso.AvailableResponse> {
			return httpx
				.get<metasso.AvailableResponse>(`${httpx.urlstorage.host()}/authn/available`, req, ...options)
				.then((resp) => ({
					...resp,
					cursor: accounts.available.requests.zero(resp.cursor),
					items: resp.items || [],
				}))
		}

		/**
		 * accept an invitation to join an account.
		 * @returns
		 */
		export function accept(...options: httpx.option[]): Promise<metasso.JoinResponse> {
			return httpx.post<metasso.JoinResponse>(`${httpx.urlstorage.host()}/authn/accept`, {}, ...options)
		}

		export namespace requests {
			export function zero(r: Partial<metasso.AvailableRequest> = {}): metasso.AvailableRequest {
				return {
					offset: uuid.NIL,
					account_id: uuid.NIL,
					...r,
				}
			}
		}
	}
}

/**
 * authentication endpoints.
 */
export const authn = {
	zero: (d: Partial<metasso.Authn>): metasso.Authn => {
		return {
			token: "",
			...d,
		}
	},
	gsuite: (code: string, state: string, scope: string): CancellablePromise<metasso.Authed> => {
		return httpx.post(`${httpx.urlstorage.host()}/authn/gsuite`, {
			code: code,
			state: state,
			scope: scope,
		})
	},
	facebook: (code: string, state: string, scope: string): CancellablePromise<metasso.Authed> => {
		return httpx.post(`${httpx.urlstorage.host()}/authn/facebook`, {
			code: code,
			state: state,
			scope: scope,
		})
	},
	microsoft: (code: string, state: string, scope: string): CancellablePromise<metasso.Authed> => {
		return httpx
			._fetchv2(
				`${httpx.urlstorage.host()}/authn/supertokens/signinup`,
				httpx.requests.zero({
					body: JSON.stringify({
						thirdPartyId: "active-directory",
						redirectURIInfo: {
							redirectURIQueryParams: { code: code, state: state, scope: scope },
							redirectURIOnProviderDashboard: `${window.location.origin}/authn/microsoft`,
						},
					}),
				}),
				httpx.methods.post,
			)
			.then((resp) => {
				return httpx.post(
					`${httpx.urlstorage.host()}/authn/supertokens/stoken`,
					{ state: state },
					httpx.options.bearer(resp.headers.get("st-access-token") || ""),
				)
			})
	},
	ephemeral: (uid: string, state: string, scope: string): CancellablePromise<metasso.Authed> => {
		return httpx.post<metasso.Authed>(`${httpx.urlstorage.host()}/authn/ephemeral`, {
			state: state,
			code: uid,
			scope: scope,
		})
	},
	emailotp: (email: string): Promise<unknown> => {
		return httpx.post(`${httpx.urlstorage.host()}/authn/supertokens/signinup/code`, { email: email })
	},
	supertokensemailotp(state: string, ...options: httpx.option[]): CancellablePromise<metasso.Authed> {
		return httpx
			.post<metasso.Authed>(`${httpx.urlstorage.host()}/authn/supertokens/emailotp`, { state: state }, ...options)
			.then((authed) => {
				return { ...authed, profiles: authed.profiles || [] }
			})
	},
}

export interface CurrentSession extends metasso.Authn {
	redirect: string
}

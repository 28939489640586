import * as caching from "caching"
import * as timex from "timex"
import * as httpx from "httpx"

const cache = new caching.Cache({ namespace: "novacloud.debug", ttl: timex.duration.iso("P30D").toMillis() })

// check if alpha functionality is enabled.
export namespace alpha {
	export function autodetecturl(location: Location): void {
		try {
			const debug = httpx.qs.parse(location.search, {
				parseBooleans: true,
			}) as { alpha: boolean }
			if (debug.alpha === undefined) return
			cache.set("alpha", debug.alpha)
		} catch (cause) {
			console.error("unable to parse url parameter", location, cause)
		}
	}

	export function enabled(): boolean {
		return cache.get("alpha") || false
	}

	export function toggle(upd: boolean): void {
		cache.set("alpha", upd)
	}
}

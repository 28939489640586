import React from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import { FileRejection } from "react-dropzone"
import classnames from "classnames"
import * as brandgpt from "brandgpt"
import * as icons from "icons"

interface props {
	onUpload(acceptedFiles: File[], rejectedFiles: FileRejection[]): void
}

const acceptedTypes = {
	"application/pdf": [".pdf"],
}

function UploadContainer(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.containers.flex flexDirection="column" className="upload-component" height="100%" {...rest}>
			<layouts.containers.flex width="100%" height="100%" flexDirection="column">
				{children}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function UploadSimple(props: props): JSX.Element {
	const { onUpload } = props
	return (
		<UploadContainer>
			<layouts.containers.flex m="5px" flex="1">
				<inputs.Dropwell
					accept={acceptedTypes}
					onDrop={(accepted, rejected, evt) => onUpload(accepted, rejected)}
					width="100%"
				>
					<layouts.dnd.Container p="10px" px="20px" borderRadius="5px">
						<layouts.dnd.Textual pt="5px" className={classnames(brandgpt.layouts.textsimple)}>
							Drag & drop or choose file
						</layouts.dnd.Textual>
					</layouts.dnd.Container>
				</inputs.Dropwell>
			</layouts.containers.flex>
		</UploadContainer>
	)
}

export function Upload(props: props): JSX.Element {
	const { onUpload } = props
	return (
		<UploadContainer flex="1">
			<layouts.containers.flex my="25px" mx="35px">
				<inputs.Dropwell
					accept={acceptedTypes}
					onDrop={(accepted, rejected, evt) => onUpload(accepted, rejected)}
					flex="1"
				>
					<layouts.dnd.Container>
						<icons.FileUpload className="dropwell-icon" />
						<layouts.dnd.Textual className={classnames(brandgpt.layouts.textssmall)}>
							Drag & drop your file here or choose file <br /> (max size: 100Mb)
						</layouts.dnd.Textual>
					</layouts.dnd.Container>
				</inputs.Dropwell>
			</layouts.containers.flex>
		</UploadContainer>
	)
}

export function UploadProcessing(props: { msg: string }): JSX.Element {
	const { msg } = props
	return (
		<UploadContainer flex="1">
			<layouts.containers.flex my="25px" mx="35px" flex="1" flexDirection="column">
				<layouts.dnd.Container justifyContent="center" alignItems="center">
					<layouts.containers.flex justifyContent="center" alignItems="center" height="100%">
						<icons.loading.Ring3 />
					</layouts.containers.flex>
					<layouts.containers.flex my="15px" className={classnames(brandgpt.layouts.textsmedium)}>
						{msg}
					</layouts.containers.flex>
					<layouts.containers.flex className={classnames(brandgpt.layouts.textssmall)}>
						This may take 30 seconds or more...
					</layouts.containers.flex>
				</layouts.dnd.Container>
			</layouts.containers.flex>
		</UploadContainer>
	)
}

export function UploadForbidden(): JSX.Element {
	return (
		<UploadContainer flex="1">
			<layouts.containers.flex my="25px" mx="35px" flex="1" flexDirection="column">
				<layouts.dnd.Container justifyContent="center" alignItems="center">
					<layouts.containers.flex justifyContent="center" alignItems="center" height="100%">
						<icons.brandgpt.attention />
					</layouts.containers.flex>
				</layouts.dnd.Container>
			</layouts.containers.flex>
		</UploadContainer>
	)
}

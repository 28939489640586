import { PropsWithChildren } from "react"
import * as layouts from "layouts"

interface props extends layouts.containers.FlexProps {
	checked: boolean
	onChange(): void
	disabled: boolean
}

export default function checkbox(props: PropsWithChildren<props>): JSX.Element {
	const { checked, onChange, disabled, children, ...rest } = props

	return (
		<layouts.forms.Group {...rest}>
			<input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
			{children}
		</layouts.forms.Group>
	)
}

checkbox.defaultProps = {
	disabled: false,
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as colors from "."
import * as brandguide from "brandguide"
import * as md5x from "md5x"

export const Steps = {
	PRIMARY: 0,
	SECONDARY: 1,
	CORRECT: 2,
	INCORRECT: 3,
	RESULT: 4,
}

export function Container(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()

	const _colors = brandguide.api.zeros.guide.guideColorsZero(cache.guide.guide?.colors)

	const curstep = () => {
		switch (false) {
			case brandguide.validations.colors.valid.primary(_colors):
				return Steps.PRIMARY
			case brandguide.validations.colors.valid.secondary(_colors):
				return Steps.SECONDARY
			case brandguide.validations.colors.valid.correct(_colors):
				return Steps.CORRECT
			case brandguide.validations.colors.valid.incorrect(_colors):
				return Steps.INCORRECT
			default:
				return Steps.RESULT
		}
	}

	const [step, setStep] = useState(curstep)
	const [loading, setLoading] = useState(false)

	const handleOnStep = (step: number, data: brandguide.api.GuideColors | undefined) => {
		if (!data) {
			setStep(step)
			return
		}

		const guide = { ...cache.guide.guide, colors: data }
		const md5 = md5x.string(JSON.stringify(guide))

		if (md5 === cache.md5) {
			setStep(step)
			return
		}

		setLoading(true)
		cache.store({ ...cache, guide: { ...cache.guide, guide: guide } }).then(() => {
			setStep(step)
			setLoading(false)
		})
	}

	return (
		<layouts.Flex overflowY="auto" {...rest}>
			<layouts.loading.screen flex="1" loading={loading}>
				{step === Steps.PRIMARY && <colors.Primary onChange={handleOnStep} />}
				{step === Steps.SECONDARY && <colors.Secondary onChange={handleOnStep} />}
				{step === Steps.CORRECT && <colors.Correct onChange={handleOnStep} />}
				{step === Steps.INCORRECT && <colors.Incorrect onChange={handleOnStep} />}
				{step === Steps.RESULT && <colors.Result onChange={handleOnStep} />}
			</layouts.loading.screen>
		</layouts.Flex>
	)
}

Container.defaultProps = {
	flexDirection: "column",
	flex: "1",
	mb: "25px",
}

export default Container

import React, { useEffect } from "react"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as api from "./api"
import * as typography from "typography"

interface props {
	authd: api.Authed
	suggested: api.AvailableResponse
	onChange(c: Promise<api.CurrentSession>): void
}

export default function Signup(props: React.PropsWithChildren<props>): JSX.Element {
	const { authd, suggested, onChange } = props

	const autoregister =
		authd.options?.autologin && // autologin enabled
		!!authd.options?.id && // we're in the custom domain context.
		authd.profiles.length === 0 && // we have no profiles.
		suggested.items.length === 0

	const signup = () => {
		const retry = httpx.autoretry()
		return retry.wrap(() => api.signup(httpx.options.bearer(authd.signup_token)))
	}

	const register = () => {
		const retry = httpx.autoretry()
		return retry.wrap(() => api.register(httpx.options.bearer(authd.signup_token)))
	}

	useEffect(() => {
		if (!autoregister) return
		onChange(register())
	}, [])

	// handle sso domains profile signup.
	if (authd.options?.id) {
		return (
			<layouts.containers.flex className="signup" mt="20px" justifyContent="center" alignContent="flex-end">
				<layouts.buttons.outline
					height="34px"
					onClick={() => {
						onChange(register())
					}}
				>
					Signup
				</layouts.buttons.outline>
			</layouts.containers.flex>
		)
	}

	return (
		<>
			<layouts.Flex flexDirection="column" my="20px">
				<layouts.Flex alignItems="center">
					<layouts.Flex flex="1" borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`} />
					<typography.h6 fontWeight="500" fontSize="14px" px="10px" color={layouts.theme.colors.grey.medium}>
						or
					</typography.h6>
					<layouts.Flex flex="1" borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.containers.flex className="signup" justifyContent="center" alignContent="flex-end">
				<layouts.buttons.outline
					height="34px"
					onClick={() => {
						onChange(signup())
					}}
				>
					Create a New Account
				</layouts.buttons.outline>
			</layouts.containers.flex>
		</>
	)
}

import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as icons from "icons"
import { Link } from "react-router-dom"
import { PositionProps } from "styled-system"
import { css } from "@emotion/css"

export const LinkButton = styled(layouts.buttons.outline)`
	font-weight: 500;
	font-size: 12px;
	border-radius: 34px;
	border: 1px solid ${layouts.theme.colors.white};
	background: transparent;
	color: ${layouts.theme.colors.white};
	svg.icon-with-stroke,
	svg.icon-with-stroke > path,
	svg.icon-with-stroke > circle {
		stroke: ${layouts.theme.colors.white};
	}
	svg.icon-without-stroke,
	svg.icon-without-stroke > path,
	svg.icon-without-stroke > circle {
		fill: ${layouts.theme.colors.white};
	}
	&.active,
	&:hover {
		background-color: ${layouts.theme.colors.grey.light15};
		border: 1px solid ${layouts.theme.colors.grey.light15};
	}
`

export const ActiveTab = styled(layouts.containers.flex)`
	flex: 1;
	font-weight: 500;
	font-size: 12px;
	border-radius: 10px;
	height: 50px;
	background: ${layouts.theme.colors.grey.dark50};
	color: ${layouts.theme.colors.white};
`

export const ActiveButton = styled(layouts.buttons.outline)`
	width: 155px;
	font-size: 12px;
	font-weight: 500;
	border-radius: 34px;
	border: 1px solid ${layouts.theme.colors.blue.blue};
	background: ${layouts.theme.colors.blue.blue};
	color: ${layouts.theme.colors.white};
	svg.icon-with-stroke,
	svg.icon-with-stroke > path,
	svg.icon-with-stroke > circle {
		stroke: ${layouts.theme.colors.white};
	}
	svg.icon-without-stroke,
	svg.icon-without-stroke > path,
	svg.icon-without-stroke > circle {
		fill: ${layouts.theme.colors.white};
	}
`

export const Container = styled(layouts.containers.flex)`
	border-radius: 0px 0px 10px 10px;
	background: ${layouts.theme.colors.grey.dark50};
	margin-bottom: 20px;
`
Container.defaultProps = {
	minHeight: "175px",
}

export const poligon = (props: React.PropsWithChildren<PositionProps>): JSX.Element => {
	const { ...rest } = props
	return (
		<layouts.containers.absolute top="59px" left="62pt" {...rest}>
			<icons.brandguard.poligon />
		</layouts.containers.absolute>
	)
}

export const review = (): JSX.Element => {
	return (
		<ActiveButton borderRadius="19px" mr="10px">
			<layouts.containers.flex alignItems="center" pl="10px">
				<icons.brandguard.review className="icon-with-stroke" width="20px" height="15px" mr="8px" />
				Review Assets
			</layouts.containers.flex>
		</ActiveButton>
	)
}

export const approved = (): JSX.Element => {
	return (
		<ActiveButton borderRadius="19px" mr="10px">
			<layouts.containers.flex alignItems="center" pl="10px">
				<icons.brandguard.accepted className="icon-with-stroke" width="20px" height="15px" mr="8px" />
				Approved Assets
			</layouts.containers.flex>
		</ActiveButton>
	)
}

export const rejected = (): JSX.Element => {
	return (
		<ActiveButton borderRadius="19px" mr="10px">
			<layouts.containers.flex alignItems="center" pl="10px">
				<icons.brandguard.rejected className="" width="20px" height="15px" mr="8px" />
				Rejected Assets
			</layouts.containers.flex>
		</ActiveButton>
	)
}

export const onbrand = (): JSX.Element => {
	return (
		<ActiveButton borderRadius="19px" mr="10px">
			<layouts.containers.flex alignItems="center" pl="10px">
				<icons.brandguard.accepted className="icon-with-stroke" width="20px" height="15px" mr="8px" />
				On-Brand Assets
			</layouts.containers.flex>
		</ActiveButton>
	)
}

export const offbrand = (): JSX.Element => {
	return (
		<ActiveButton borderRadius="19px" mr="10px">
			<layouts.containers.flex alignItems="center" pl="10px">
				<icons.brandguard.rejected className="" width="20px" height="15px" mr="8px" />
				Off-Brand Assets
			</layouts.containers.flex>
		</ActiveButton>
	)
}

export function NavLink(props: { url: string; title: string; active?: boolean; icon: JSX.Element }): JSX.Element {
	const { url, title, active, icon } = props
	return (
		<layouts.containers.flex pl="20px">
			<Link to={url}>
				<LinkButton borderRadius="19px" mr="10px" className={active ? "active" : "unset"}>
					<layouts.containers.flex alignItems="center" px="10px">
						{icon}
						{title}
					</layouts.containers.flex>
				</LinkButton>
			</Link>
		</layouts.containers.flex>
	)
}

export const slideDownAnimation = css`
	animation: slideDown 0.25s ease-out;

	@keyframes slideDown {
		0% {
			transform: translateY(-100%);
		}
		100% {
			transform: translateY(0);
		}
	}
`

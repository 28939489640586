import * as CSS from "csstype"

export interface config {
	color(light: CSS.DataType.Color, dark: CSS.DataType.Color): CSS.DataType.Color
}

export function autodetect(d: Partial<config> = {}): config {
	// TODO: enable autodetect.
	// const color = window?.matchMedia("(prefers-color-scheme: dark)")?.matches ? colors.dark : colors.light
	const color = colors.light
	return {
		color: color,
		...d,
	}
}

export function colorWithOpacity(hex: string, opacity: number): string {
	const isHex = /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)
	if (!isHex) {
		console.error("Invalid HEX color.")
		return hex
	}

	if (hex.length === 9) {
		console.error("color already have opacity.")
		return hex
	}

	if (opacity < 0 || opacity > 100) {
		console.error("Opacity must be between 0 and 100.")
		return hex
	}

	const hexOpacity = Math.floor((opacity * 255) / 100).toString(16)

	return `${hex}${hexOpacity.padStart(2, "0")}`
}

export function darktheme(d: Partial<config> = {}): config {
	return {
		color: colors.dark,
		...d,
	}
}

export const colors = {
	dark(light: CSS.DataType.Color, dark: CSS.DataType.Color): CSS.DataType.Color {
		return dark
	},
	light(light: CSS.DataType.Color, dark: CSS.DataType.Color): CSS.DataType.Color {
		return light
	},
	black: {
		lightest: "#292934",
		light: "#171616",
		medium: "#0F0F0F",
		darkest: "#0B0B0B",
		dark: "#040404",
		default: "#000000",
	},
	white: "#FFFFFF",
	navy: "#16133F",
	orange: "#EF463B",
	blue: {
		blue: "#3E6EE4",
		dark10: "#15215b",
		dark20: "#151E51",
		bluesoft: "#F0F3FD",
		bluedarkness: "#222F8F",
		bluedark20: "#121E54",
		bright: "#0362FB",
	},
	grey: {
		lightest: "#F5F5F5",
		light05: "#F3F4F5",
		light10: "#EEEFF0",
		light15: "#A1ABB7",
		light20: "#EAEAEA",
		light25: "#E7E7E7",
		light30: "#C2C2C2",
		light40: "#7E8490",
		light50: "#D9D9D9",
		light80: "#C9C9C9",
		medium: "#4D5E80",
		dark3: "#3C4B67",
		dark5: "#464646",
		dark10: "#5F5F71",
		dark20: "#616161",
		dark20alpha: "#61616166",
		dark50: "#353E4E",
		dark50alpha: "#353E4E1A",
		dark50alpha05: "#353E4E0D",
		dark50alpha10: "#353E4E1A",
		dark50alpha20: "#353e4e33",
		dark50alpha30: "#353E4E4D",
		dark50alpha40: "#353E4E66",
		dark50alpha50: "#353E4E80",
		dark50alpha60: "#353E4E99",
		dark50alpha70: "#353E4EB3",
		dark50alpha80: "#353E4ECC",
		dark50alpha90: "#353E4EE6",
		bg: "#F7F8FA",
	},
	gradient: {
		pink: "#EC1977",
		orange: "#F05E22",
	},
	hinting: {
		lightest: "#D92B4B",
		medium: "#A62139",
		darkest: "#73192A",
		darkestalpha: "#73192A66",
	},
	danger: "#A62139",
	success: "#14A44D",
	hover: "#3C54B70A",
	dianthus: "linear-gradient(85.94deg, #EC1977 1.81%, #F05E22 97.64%)",
	nova: "linear-gradient(54.62deg, #5C1AFF 0.61%, #800080 99.38%)",
	green: "#2B5D31",
	link: {
		hover: "#014BD0",
	},
	red: {
		light: "#F74C45",
		cinnabar: "#E43E3E",
		mandarin: "#E47A3E",
		sun: "#ED5407",
		bright: "#FF4444",
	},
	space_cadet: "#13244B",
	charts: {
		green: {
			base: "#54ddcf",
			alpha: "rgba(154, 234, 226, 0.5)",
		},
		blue: {
			base: "#50B1FA",
			alpha: "rgba(177, 222, 253, 0.5)",
		},
		violet: {
			base: "#7261B0",
			alpha: "rgba(193, 185, 222, 0.5)",
		},
		yellow: {
			base: "#F8CD44",
			alpha: "rgba(253, 237, 180, 0.5)",
		},
	},
}

export const borders = {
	grey: {
		darkest: "1px ridge #666666",
		darkestalpha: "1px ridge #66666666",
		light: "2px solid #b1b1ba",
		light_thin: "0.5px solid #b1b1ba",
		dark50alpha: `1px solid ${colors.grey.dark50alpha}`,
		dark50alphamedium1px: `1px solid ${colors.grey.dark50}33`,
		dark50alphamedium: `1.5px solid ${colors.grey.dark50}33`,
		dark50alphamedium2px: `2px solid ${colors.grey.dark50}33`,
	},
	hinting: {
		darkest: "1px ridge #73192A",
	},
	radius: {
		standard: "0.25em",
	},
}

export const boxshadow = {
	default: "0px 2px 4px rgba(0, 0, 0, 0.25)",
	grey: {
		lightest: "0px 2px 4px rgba(0, 0, 0, 0.25)",
	},
	black: {
		medium: "0px 2px 2px rgba(0, 0, 0, 0.25)",
		wide: "0px 4px 4px rgba(0, 0, 0, 0.25)",
	},
}

export const backgrounds = {
	danger: "#A6213977",
	bluealpha: "rgba(62, 110, 228, 0.04)",
	redalpha: "rgba(228, 62, 62, 0.27)",
	cinnabaralpha: "rgba(228, 62, 62, 0.05)",
	uploadicon: "rgba(62, 110, 228, 0.27)",
	blackalpha75: "rgba(0, 0, 0, 0.75);",
	whitealpha80: "rgba(255, 255, 255, 0.8)",
	bluealpha5: "rgba(53, 62, 78, 0.05)",
	dark50alpha20: "#353e4e33",
	lightblue: "#F4F6F8",
}

export const buttons = {
	colors: {
		enabled: colors.blue.blue,
		disabled: colors.grey.light30,
	},
}

export const extension = {
	colors: {
		blue: {
			background_dark: "#0C1131",
			background: "#151E51",
			header: "#222F8F",
			text: "#D9DEFF",
			text_background: "#ECEFFD",
			text_secondary: "#75FAED",
			border: "#27368C",
			feather: "#3347B5",
			select_item: "rgba(21, 30, 81, 0.45)",
		},
		black: {
			text: "#202124",
		},
		white: {
			alpha90: "rgba(255, 255, 255, 0.9)",
			alpha85: "rgba(255, 255, 255, 0.85)",
			alpha80: "rgba(255, 255, 255, 0.8)",
			alpha75: "rgba(255, 255, 255, 0.75)",
			alpha40: "rgba(255, 255, 255, 0.4)",
			alpha13: "rgba(255, 255, 255, 0.13)",
			alpha10: "rgba(255, 255, 255, 0.1)",
		},
	},
	boxshadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
}

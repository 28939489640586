import React, { useState } from "react"
import * as layouts from "layouts"
import * as profiles from "profiles"
import * as meta from "meta"
import * as authzc from "authzcached"
import * as inputs from "inputs"
import * as typography from "typography"
import * as timex from "timex"
import * as umlayouts from "./layouts"
import { css } from "@emotion/css"

interface props extends layouts.containers.FlexProps {
	onChange(p: profiles.Profile): void
	onCancel?(): void
}

export default function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const [profile, setProfile] = useState(profiles.zero({ disabled_invited_at: timex.infinity().toISO() }))
	const [token, setToken] = useState(meta.authz.tokens.zero({ brandguard_upload: true }))
	const disabled = validate(profile)

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = authzc.useCache((cached) => cached.meta.current)
	const [showForm, setShowForm] = useState(false)

	if (!permission.usermanagement) return <></>

	if (!showForm)
		return (
			<layouts.Flex py="20px">
				<layouts.buttons.primary
					onClick={() => {
						setProfile(profiles.zero({ disabled_invited_at: timex.infinity().toISO() }))
						setToken(meta.authz.tokens.zero({ brandguard_upload: true }))
						setShowForm(!showForm)
					}}
					borderRadius="37px"
					width="151px"
					height="35px"
				>
					Invite New User
				</layouts.buttons.primary>
			</layouts.Flex>
		)

	return (
		<layouts.Flex mt="20px" flexDirection="column" {...rest}>
			<Form current={profile} token={token} onChange={setProfile} onTokenChange={setToken} />
			<layouts.Flex justifyContent="flex-start" gridGap="10px" mt="20px">
				<layouts.buttons.primary
					width="151px"
					height="35px"
					borderRadius="37px"
					disabled={disabled}
					onClick={() => {
						return profiles.api
							.create({ profile: profile }, authzc.bearer(metaauthz))
							.then((r) => {
								return meta.authz.grant(
									{
										authorization: token,
										profile_id: r.profile!.id,
									},
									authzc.bearer(metaauthz),
								)
							})
							.finally(() => {
								onChange(profile)
								setShowForm(!showForm)
								setProfile(profiles.zero())
								setToken(meta.authz.tokens.zero())
							})
					}}
				>
					Send Invite
				</layouts.buttons.primary>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface formProps extends layouts.containers.ContainerProps {
	current: profiles.Profile
	token: meta.authz.Token
	onChange(upd: profiles.Profile): void
	onTokenChange(t: meta.authz.Token): void
}

export function validate(cred: profiles.Profile): boolean {
	return inputs.assertions.failed(
		inputs.assertions.strings.min(1)(cred.display),
		inputs.assertions.strings.email()(cred.email),
	)
}

const aligned = css`
	input {
		margin: 0;
	}
`

export function Form(props: formProps): JSX.Element {
	const { current, token, onChange, onTokenChange, ...rest } = props
	return (
		<layouts.forms.Container className="invite.form" flexDirection="row" {...rest}>
			<layouts.forms.Group flex="1" flexDirection="column" mb="10px">
				<typography.h3 color={layouts.theme.colors.grey.dark50} fontSize="14px" lineHeight="30px">
					Name
				</typography.h3>
				<umlayouts.StyledInput
					type="text"
					placeholder="Name"
					defaultValue={current.display}
					onChange={(evt) => onChange({ ...current, display: evt.currentTarget.value })}
				/>
			</layouts.forms.Group>
			<layouts.forms.Group flex="1" flexDirection="column" mb="10px">
				<typography.h3 color={layouts.theme.colors.grey.dark50} fontSize="14px" lineHeight="30px">
					Email
				</typography.h3>
				<umlayouts.StyledInput
					type="text"
					placeholder="Email"
					defaultValue={current.email}
					onChange={(evt) => onChange({ ...current, email: evt.currentTarget.value })}
				/>
			</layouts.forms.Group>
			<layouts.Flex fontSize="12px" color={layouts.theme.colors.grey.dark50} flexDirection="column" className={aligned}>
				<layouts.Flex mt="5px" mb="10px" fontWeight="600">
					Permissions:
				</layouts.Flex>
				<layouts.containers.grid
					width="100%"
					justifyContent="center"
					display="grid"
					gridGap="2px"
					gridTemplateColumns="repeat(2, 1fr);"
					gridTemplateRows="min-content"
				>
					<layouts.forms.Container>
						<inputs.Checkbox
							alignItems="flex-start"
							checked={profiles.invited(current)}
							onChange={() => {
								const disabled = profiles.invited(current) ? timex.local().toISO() : timex.infinity().toISO()
								onChange({ ...current, disabled_invited_at: disabled })
							}}
						>
							<layouts.forms.Label my="unset" ml="10px">
								Login
							</layouts.forms.Label>
						</inputs.Checkbox>
					</layouts.forms.Container>
					<layouts.forms.Container>
						<inputs.Checkbox
							alignItems="flex-start"
							checked={token.usermanagement}
							onChange={() => onTokenChange({ ...token, usermanagement: !token.usermanagement })}
						>
							<layouts.forms.Label my="unset" ml="10px">
								Manage Settings
							</layouts.forms.Label>
						</inputs.Checkbox>
					</layouts.forms.Container>
					<layouts.forms.Container>
						<inputs.Checkbox
							alignItems="flex-start"
							checked={token.brandguard_upload}
							onChange={() => onTokenChange({ ...token, brandguard_upload: !token.brandguard_upload })}
						>
							<layouts.forms.Label my="unset" ml="10px">
								Upload & Score Assets
							</layouts.forms.Label>
						</inputs.Checkbox>
					</layouts.forms.Container>
					<layouts.forms.Container>
						<inputs.Checkbox
							alignItems="flex-start"
							checked={token.brandguard_review}
							onChange={() => onTokenChange({ ...token, brandguard_review: !token.brandguard_review })}
						>
							<layouts.forms.Label my="unset" ml="10px">
								Review Assets
							</layouts.forms.Label>
						</inputs.Checkbox>
					</layouts.forms.Container>
					<layouts.forms.Container>
						<inputs.Checkbox
							alignItems="flex-start"
							checked={token.brandguard_train}
							onChange={() => onTokenChange({ ...token, brandguard_train: !token.brandguard_train })}
						>
							<layouts.forms.Label my="unset" ml="10px">
								Train System
							</layouts.forms.Label>
						</inputs.Checkbox>
					</layouts.forms.Container>
					<layouts.forms.Container>
						<inputs.Checkbox
							alignItems="flex-start"
							checked={token.brandgpt_use}
							onChange={() => onTokenChange({ ...token, brandgpt_use: !token.brandgpt_use })}
						>
							<layouts.forms.Label my="unset" ml="10px">
								Use BrandGPT
							</layouts.forms.Label>
						</inputs.Checkbox>
					</layouts.forms.Container>
					<layouts.forms.Container>
						<layouts.Flex flexDirection="column">
							<inputs.Checkbox
								alignItems="flex-start"
								checked={token.brandguide_view || token.brandguide_edit}
								onChange={() => {
									if (token.brandguide_view || token.brandguide_edit) {
										onTokenChange({ ...token, brandguide_view: false, brandguide_edit: false })
										return
									}
									onTokenChange({ ...token, brandguide_view: true, brandguide_edit: true })
								}}
							>
								<layouts.forms.Label my="unset" ml="10px">
									BrandGuide Buider:
								</layouts.forms.Label>
							</inputs.Checkbox>
							<layouts.Flex flexDirection="column" ml="10px" mt="2px">
								<inputs.Checkbox
									alignItems="flex-start"
									disabled={token.brandguide_edit}
									checked={token.brandguide_view}
									onChange={() => {
										if (token.brandguide_edit) return
										onTokenChange({ ...token, brandguide_view: !token.brandguide_view })
									}}
								>
									<layouts.forms.Label my="unset" ml="10px">
										View
									</layouts.forms.Label>
								</inputs.Checkbox>
								<inputs.Checkbox
									alignItems="flex-start"
									checked={token.brandguide_edit}
									onChange={() => {
										if (!token.brandguide_edit) {
											onTokenChange({ ...token, brandguide_edit: true, brandguide_view: true })
											return
										}
										onTokenChange({ ...token, brandguide_edit: false })
									}}
								>
									<layouts.forms.Label my="unset" ml="10px">
										Edit
									</layouts.forms.Label>
								</inputs.Checkbox>
							</layouts.Flex>
						</layouts.Flex>
					</layouts.forms.Container>
				</layouts.containers.grid>
			</layouts.Flex>
		</layouts.forms.Container>
	)
}

export function getItem(key: string): Promise<string> {
	return Promise.resolve(window.localStorage.getItem(key) || "")
}

export function setItem(key: string, value: string): Promise<void> {
	try {
		return Promise.resolve(window.localStorage.setItem(key, value))
	} catch (e) {
		return Promise.reject(e)
	}
}

export function removeItem(key: string): Promise<void> {
	try {
		return Promise.resolve(window.localStorage.removeItem(key))
	} catch (e) {
		return Promise.reject(e)
	}
}

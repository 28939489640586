import useResizeObserver from "@react-hook/resize-observer"
import React, { useRef, useState, useEffect } from "react"
import * as layouts from "layouts"
import classnames from "classnames"
import { css } from "@emotion/css"
import * as icons from "icons"

const styled = css`
	.carousel {
		scroll-behavior: smooth;
		scrollbar-width: none;
	}
	.carousel::-webkit-scrollbar {
		height: 0;
	}
	.prev,
	.next {
		transform: translate(50%, -50%);
	}

	.content {
		grid-auto-flow: column;
		box-sizing: border-box;
	}
`

function scrollBy(element: HTMLElement, offset: number, callback: () => void): void {
	const targetScrollPosition = element.scrollLeft + offset

	const onScroll = function (): void {
		if (Math.abs(element.scrollLeft - targetScrollPosition) < 1) {
			element.removeEventListener("scroll", onScroll)
		}
		callback()
	}

	element.addEventListener("scroll", onScroll)
	element.scrollBy({
		left: offset,
		behavior: "smooth",
	})
}

interface carouselProps extends layouts.containers.ContainerProps {
	autoscrollright: boolean
}

function Carousel(props: React.PropsWithChildren<carouselProps>): JSX.Element {
	const { autoscrollright, children, className, ...rest } = props

	const carousel = useRef<HTMLDivElement>(null)
	const [width, setWidth] = useState(0)

	const [showNext, setShowNext] = useState(false)
	const [showPrev, setShowPrev] = useState(false)

	const checkScrollPosition = () => {
		if (!carousel.current) return
		const { scrollLeft, scrollWidth, offsetWidth } = carousel.current
		setShowPrev(scrollLeft !== 0)
		setShowNext(scrollLeft + offsetWidth !== scrollWidth)
	}

	const handleNextClick = () => {
		if (!carousel.current) return
		scrollBy(carousel.current, width, checkScrollPosition)
	}

	const handlePrevClick = () => {
		if (!carousel.current) return
		scrollBy(carousel.current, -width, checkScrollPosition)
	}

	useResizeObserver(carousel, (entry) => {
		setWidth(entry.contentRect.width)
	})

	useEffect(() => {
		setWidth(carousel.current?.offsetWidth || 0)
	}, [children])

	useEffect(() => {
		if (!carousel.current) return
		if (autoscrollright)
			scrollBy(carousel.current, carousel.current.scrollWidth + width, checkScrollPosition) // scroll to right
		else checkScrollPosition()
	}, [width])

	function Next(): JSX.Element {
		if (!showNext) return <></>
		return (
			<layouts.Flex position="absolute" onClick={handleNextClick} right="0" className="next" {...navProps}>
				<icons.arrows.ArrowRight />
			</layouts.Flex>
		)
	}

	function Prev(): JSX.Element {
		if (!showPrev) return <></>
		return (
			<layouts.Flex onClick={handlePrevClick} position="absolute" right="100%" className="prev" {...navProps}>
				<icons.arrows.ArrowLeft />
			</layouts.Flex>
		)
	}

	return (
		<layouts.Flex className={classnames("wrapper", styled, className)} {...rest}>
			<layouts.Flex overflow="auto" className="carousel" ref={carousel}>
				<layouts.containers.grid gridAutoFlow="column" margin="auto" className="content">
					{children}
				</layouts.containers.grid>
			</layouts.Flex>
			<Prev />
			<Next />
		</layouts.Flex>
	)
}

Carousel.defaultProps = {
	flex: "1",
	position: "relative",
	width: "100%",
	maxWidth: "60vw",
	autoscrollright: false,
}

const navProps = {
	top: "50%",
	width: "38px",
	height: "38px",
	justifyContent: "center",
	alignItems: "center",
	background: layouts.theme.colors.white,
	borderRadius: "50%",
	boxShadow: "0px 1px 2px 0px #00000040",
}

export default Carousel

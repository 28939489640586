import * as httpx from "httpx"
import * as uuid from "uuid"
import * as events from "./events"
import { CancellablePromise } from "real-cancellable-promise"
import {
	ImageSearchRequest,
	ImageSearchResponse,
	ImageFindResponse,
	ImagePatchRequest,
	ImagePatchResponse,
	ImageUploadResponse,
} from "brandguard/brandguard.image_pb"

export function create(file: File, bid: string, ...options: httpx.option[]): CancellablePromise<ImageUploadResponse> {
	const data = new FormData()
	data.append("file", file)
	return httpx
		.upload<ImageUploadResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}`, data, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export namespace searches {
	export function zero(sr: Partial<ImageSearchRequest> = {}): ImageSearchRequest {
		return {
			offset: 0n,
			limit: 20n,
			uploaded_by: [],
			deprecated_uploaded_by: "",
			brand_id: uuid.NIL,
			queues: [],
			approved: [],
			observation: [],
			confidence: { min: 0, max: 1 },
			...sr,
		}
	}
}

export function search(
	bid: string,
	req: ImageSearchRequest,
	...options: httpx.option[]
): CancellablePromise<ImageSearchResponse> {
	return httpx.get<ImageSearchResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}`, req, ...options).then((resp) => ({
		...resp,
		items: (resp.items || []).map((item) => ({ media: item.media, event: events.zero(item.event) })),
		next: searches.zero(resp.next),
	}))
}

export function find(id: string, bid: string, ...options: httpx.option[]): CancellablePromise<ImageFindResponse> {
	return httpx
		.get<ImageFindResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function patch(
	bid: string,
	id: string,
	req: ImagePatchRequest,
	...options: httpx.option[]
): CancellablePromise<ImagePatchResponse> {
	return httpx
		.patch<ImagePatchResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}/${id}`, req, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function requeue(id: string, bid: string, ...options: httpx.option[]): Promise<ImageFindResponse> {
	return httpx
		.post<ImageFindResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}/${id}/requeue`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function review(id: string, bid: string, ...options: httpx.option[]): Promise<ImagePatchResponse> {
	return httpx
		.post<ImagePatchResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}/${id}/review`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function publish(id: string, bid: string, ...options: httpx.option[]): Promise<ImagePatchResponse> {
	return httpx
		.post<ImagePatchResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}/${id}/publish`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function destroy(bid: string, id: string, ...options: httpx.option[]): Promise<ImagePatchResponse> {
	return httpx
		.destroy<ImagePatchResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"

export function LogoModal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Logo Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>A logo serves as the visual cornerstone of a brand</b>, encapsulating its
						identity, values, and essence in a single mark. Beyond mere aesthetics, a well-designed logo establishes
						instant recognition and fosters a lasting connection with consumers. It acts as a symbol of trust and
						credibility, communicating professionalism and expertise. Moreover, a carefully crafted logo distinguishes a
						brand from its competitors, helping it stand out in a crowded marketplace.
						<br />
						<br />
						A few steps to guide you in the design process:
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Understand your Brand:</b> Start by gaining a deep understanding your
						company&apos;s values, mission, target audience, and unique selling points. This foundational knowledge will
						inform the design process and ensure that the logo accurately represents the essence of the brand.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Research and Inspiration:</b> Conduct thorough research into your industry,
						competitors, and design trends. Gather inspiration from a variety of sources, including existing logos,
						design blogs, and historical references. This will help generate ideas and identify potential directions for
						the logo design.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

LogoModal.defaultProps = {
	width: "815px",
	height: "400px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

export function ClearspaceModal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Clearspace Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>Clearspace for a logo is crucial</b> when creating a brand because it
						ensures that the logo maintains its visibility, legibility, and impact in various contexts. Clearspace
						refers to the area around the logo that must remain free from any other graphic elements, text, or imagery.
						<br />
						<br />
						Here are a few reasons why clearspace is important:
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Visibility:</b> Clearspace allows the logo to be easily seen and
						recognized, even when it is surrounded by other design elements or placed in busy environments. Without
						clearspace, the logo may get lost or obscured, diminishing its visibility and effectiveness as a brand
						identifier.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Legibility:</b> Clearspace ensures that the logo remains legible,
						particularly at smaller sizes or when viewed from a distance. By providing adequate space around the logo,
						it prevents any text or graphics from encroaching on its design elements, such as letters or symbols, which
						could hinder readability.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Brand Consistency:</b> Clearspace helps maintain brand consistency across
						different applications and mediums. By establishing clear guidelines for the minimum space required around
						the logo, it ensures that the logo is presented consistently across various materials, such as print
						collateral, digital assets, signage, and packaging.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

ClearspaceModal.defaultProps = {
	width: "815px",
	height: "460px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

export function ProportionModal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Proportions Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>
							Having a minimum logo size is essential for maintaining visibility, brand integrity, quality control, and
							recognition,
						</b>
						ensuring that the logo effectively represents the brand across various contexts and platforms.
						<br />
						<br />
						Here are a few reasons why proportion is important:
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Visibility:</b> A minimum size ensures that the logo remains legible and
						recognizable, even when reproduced at smaller scales or viewed from a distance. It prevents the logo from
						becoming distorted or indistinguishable, maintaining its visibility and impact across various applications
						and mediums.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Brand Integrity:</b> Enforcing a minimum logo size helps to preserve the
						integrity and consistency of the brand&apos;s visual identity. It ensures that the logo is always presented
						in a clear and professional manner, reinforcing brand recognition and credibility.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Quality Control:</b> Establishing a minimum size requirement allows brands
						to maintain quality control over how their logo is used. It prevents unauthorized alterations or resizing
						that could compromise the logo&apos;s appearance and effectiveness as a brand identifier.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Brand Recognition:</b> Consistently presenting the logo at an appropriate
						size reinforces brand recognition and recall among consumers. A clear and consistent presentation of the
						logo enhances its memorability and reinforces associations with the brand&apos;s products or services.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

ProportionModal.defaultProps = {
	width: "815px",
	height: "520px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

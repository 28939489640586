import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as logo_compts from "./components"

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const secondary = guide.guide?.logos?.secondary
	const logomark = guide.guide?.logos?.logomark
	const wordmark = guide.guide?.logos?.wordmark
	const alternative = guide.guide?.logos?.alternative

	if (!result_components.validations.logos.has_additional_logos(guide.guide?.logos!)) return <></>

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>The Logo</result_components.NavTitle>
				<result_components.NavDescription>
					The primary logo is the preferred and most recognizable version of our brand mark. Use the primary logo in
					most applications, including print, digital, and signage.
					<br />
					<br />
					Maintain the original color scheme for the primary logo. It can also be used on our secondary colored
					backgrounds and over imagery (but please ensure optimum legibility). Always maintain a clear space around the
					logo to preserve its visibility and impact. Do not alter the proportions of the logo. Ensure it is scaled
					proportionally to maintain its integrity.
				</result_components.NavDescription>
				<result_components.Explanation desc={guide.guide?.logos?.descriptions?.uploads} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer gridGap="2%">
					{secondary?.present && (
						<layouts.Flex flex="1" flexDirection="column" {...logo_compts.fullSizerProps}>
							<layouts.Flex>
								<typography.h4 color={colors.grey.dark50} lineHeight="50px">
									Secondary Logo
								</typography.h4>
							</layouts.Flex>
							<layouts.Flex
								flex="1"
								{...logo_compts.centeredProps}
								maxHeight="45vh"
								className={logo_compts.ContrastBGColorClassName(secondary.svg)}
							>
								<logo_compts.LogoImage src={secondary.svg} alt="Secondary Logo" maxWidth="100%" maxHeight="100%" />
							</layouts.Flex>
						</layouts.Flex>
					)}
					{(logomark?.present || wordmark?.present || alternative?.present) && (
						<layouts.Flex flex="1" gridGap="2%" {...logo_compts.fullSizerProps} {...logo_compts.centeredProps}>
							{logomark?.present && (
								<layouts.Flex
									flex="1"
									flexDirection="column"
									{...logo_compts.fullSizerProps}
									className={logo_compts.ContrastBGColorClassName(logomark.svg)}
								>
									<layouts.Flex flex="1" {...logo_compts.centeredProps} maxHeight="100%">
										<logo_compts.LogoImage src={logomark.svg} alt="Logomark" />
									</layouts.Flex>
									<layouts.Flex {...logo_compts.centeredProps}>
										<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
											Logomark
										</typography.h4>
									</layouts.Flex>
								</layouts.Flex>
							)}
							{wordmark?.present && (
								<layouts.Flex flex="1" flexDirection="column" {...logo_compts.fullSizerProps}>
									<layouts.Flex
										flex="1"
										{...logo_compts.centeredProps}
										maxHeight="100%"
										className={logo_compts.ContrastBGColorClassName(wordmark.svg)}
									>
										<logo_compts.LogoImage src={wordmark.svg} alt="Logomark" />
									</layouts.Flex>
									<layouts.Flex {...logo_compts.centeredProps}>
										<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
											Wordmark
										</typography.h4>
									</layouts.Flex>
								</layouts.Flex>
							)}
							{alternative?.present && (
								<layouts.Flex flex="1" flexDirection="column" {...logo_compts.fullSizerProps}>
									<layouts.Flex
										flex="1"
										{...logo_compts.centeredProps}
										maxHeight="100%"
										className={logo_compts.ContrastBGColorClassName(alternative.svg)}
									>
										<logo_compts.LogoImage src={alternative.svg} alt="Logomark" />
									</layouts.Flex>
									<layouts.Flex {...logo_compts.centeredProps}>
										<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
											Alternative
										</typography.h4>
									</layouts.Flex>
								</layouts.Flex>
							)}
						</layouts.Flex>
					)}
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

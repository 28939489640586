import * as svg from "./svg"
import * as theme from "layouts/theme"

const Icon = (props: svg.SVGProps) => {
	const { fill, width, height, ...rest } = props
	return (
		<svg.SVG
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 224 224"
			fill="none"
			{...rest}
		>
			<g clipPath="url(#clip0_4_1070)">
				<path
					d="M112 0C50.1445 0 0 50.1445 0 112C0 173.859 50.1445 224 112 224C173.859 224 224 173.859 224 112C224 50.1445 173.859 0 112 0ZM112 210.221C57.9635 210.221 14 166.036 14 112C14 57.9631 57.9635 13.9996 112 13.9996C166.036 13.9996 210 57.9633 210 112C210 166.036 166.036 210.221 112 210.221ZM156.699 71.0185L90.9858 137.144L61.3933 107.551C58.6598 104.818 54.2288 104.818 51.4918 107.551C48.7583 110.285 48.7583 114.716 51.4918 117.449L86.1383 152.1C88.8718 154.829 93.3028 154.829 96.0398 152.1C96.3548 151.785 96.6245 151.441 96.8695 151.085L166.604 80.9198C169.334 78.1863 169.334 73.7553 166.604 71.0185C163.867 68.285 159.436 68.285 156.699 71.0185Z"
					fill={fill}
				/>
			</g>
		</svg.SVG>
	)
}

Icon.defaultProps = {
	width: "224",
	height: "224",
	fill: theme.colors.blue.bright,
}

export default Icon

import * as uuid from "uuid"
import { ImageEvent } from "brandguard/brandguard.image_pb"
import { Prediction } from "brandguard/brandguard.approval_pb"

export function zero(ie: Partial<ImageEvent> = {}): ImageEvent {
	return {
		id: uuid.NIL,
		account_id: uuid.NIL,
		brand_id: uuid.NIL,
		approved: Prediction.NONE,
		observation: Prediction.NONE,
		queue: "",
		confidence: 0.0,
		brand_uniqueness: 0.0,
		brand_voice: 0.0,
		sexually_explicit: 0.0,
		image_quality_general: 0.0,
		image_violence: 0.0,
		image_logo_detection: 0.0,
		image_logo_colors: 0.0,
		image_logo_distortion: 0.0,
		image_obscure_rule_checks: 0n,
		image_obscure_rule_violations: 0n,
		image_obscure_rule_conformance: 0.0,
		image_font_detection: 0.0,
		image_logo_clearspace: 0.0,
		image_background_colors: 0.0,
		updated_at: "",
		...ie,
	}
}

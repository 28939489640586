import React, { useState, useRef } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as logos from "brandguide/build/logos"
import { css } from "@emotion/css"
import * as icons from "icons"
import { Link } from "react-router-dom"
import * as brands from "brands"
import { results } from "brandguide/build/layouts"
import * as navigation from "brandguide/build/navigation"
import classnames from "classnames"
import * as authz from "authz"
import * as authzc from "authzcached"

const styledArrow = css`
	.arrow:before,
	.arrow:after {
		content: "";
		position: absolute;
		top: 0;
		width: 20px;
		height: 20px;
		background: #ee4c2c; /* PyTorch Orange */
	}

	.arrow:before {
		left: -10px;
		transform: rotate(45deg);
	}

	.arrow:after {
		right: -10px;
		transform: rotate(-45deg);
	}
`

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideLogos | undefined): void
}

export function SectionResult(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const guide = brandguide.cache.useCached().guide
	const data = brandguide.api.zeros.guide.guideLogosZero(guide.guide?.logos)
	const items = [data.primary, data.secondary, data.logomark, data.wordmark, data.alternative].filter((l) => l?.present)
	const secondaryItems = [data.secondary, data.logomark, data.wordmark, data.alternative].filter((l) => l?.present)
	const focused = data.primary!

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	const usage = (data.usage || []).filter((el) => el.use)
	const misuse = (data.usage || []).filter((el) => !el.use)

	const Placements = (): JSX.Element => {
		const allPlacement = (data.placement?.all || []).length > 0
		if (allPlacement)
			return (
				<layouts.Flex gridGap="50px">
					<logos.layouts.PlacementItem
						minWidth="250px"
						items={data.placement?.all || []}
						posX="center"
						readonly
						alignAll
						logo={focused.svg}
						title="All"
						onChange={() => undefined}
					/>
					<layouts.Flex>
						<typography.h6
							whiteSpace="unset"
							fontSize="14px"
							fontWeight="400"
							color={layouts.theme.colors.grey.dark50alpha80}
						>
							The placement of the logo can be anywhere that is most impactful within the composition of the piece you
							are creating.
						</typography.h6>
					</layouts.Flex>
				</layouts.Flex>
			)
		return (
			<layouts.containers.grid
				gap="15px"
				gridTemplateColumns="repeat(4, minmax(200px, 1fr))"
				gridTemplateRows="repeat(auto-fit, minmax(120px, 1fr))"
				style={{ alignContent: "start", alignItems: "start" }}
				minHeight="0px"
			>
				<logos.layouts.PlacementItem
					items={data.placement?.left || []}
					readonly
					posX="start"
					logo={focused.svg}
					title="Left Aligned"
					onChange={() => undefined}
				/>
				<logos.layouts.PlacementItem
					items={data.placement?.center || []}
					readonly
					posX="center"
					logo={focused.svg}
					title="Center Aligned"
					onChange={() => undefined}
				/>
				<logos.layouts.PlacementItem
					items={data.placement?.right || []}
					readonly
					posX="end"
					logo={focused.svg}
					title="Right Aligned"
					onChange={() => undefined}
				/>
			</layouts.containers.grid>
		)
	}

	return (
		<layouts.Flex flex="1" {...rest}>
			<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
				<results.Title>The Logo</results.Title>
				<results.SectionContainer>
					<results.Title>Main Logo & Alternatives</results.Title>
					<results.Explanation desc={data.descriptions?.uploads} />

					<layouts.Flex flexDirection="column" width="100%" alignItems="center">
						<layouts.Flex>
							{data.primary?.present && (
								<layouts.Flex
									flexDirection="column"
									m="22px"
									className={logos.layouts.ContrastBGColorClassName(data.primary?.svg)}
								>
									<img src={data.primary?.svg} alt="Main Logo" style={{ maxHeight: "150px", maxWidth: "150px" }} />
									<typography.h4
										mt="14px"
										fontSize="18px"
										fontWeight="600"
										color={layouts.theme.colors.grey.dark50}
										textAlign="center"
									>
										{logos.layouts.LogoTitle(data.primary)}
									</typography.h4>
								</layouts.Flex>
							)}
						</layouts.Flex>
						{secondaryItems.length > 0 && (
							<layouts.Flex gridGap="30px" alignItems="end">
								{data.secondary?.present && (
									<layouts.Flex
										flexDirection="column"
										m="22px"
										className={logos.layouts.ContrastBGColorClassName(data.secondary?.svg)}
									>
										<img
											src={data.secondary?.svg}
											alt="Secondary Logo"
											style={{ maxHeight: "150px", maxWidth: "150px" }}
										/>
										<typography.h4
											mt="14px"
											fontSize="18px"
											fontWeight="600"
											color={layouts.theme.colors.grey.dark50}
											textAlign="center"
										>
											{logos.layouts.LogoTitle(data.secondary)}
										</typography.h4>
									</layouts.Flex>
								)}
								{data.logomark?.present && (
									<layouts.Flex
										flexDirection="column"
										m="22px"
										className={logos.layouts.ContrastBGColorClassName(data.logomark?.svg)}
									>
										<img src={data.logomark?.svg} alt="Logomark" style={{ maxHeight: "150px", maxWidth: "150px" }} />
										<typography.h4
											mt="14px"
											fontSize="18px"
											fontWeight="600"
											color={layouts.theme.colors.grey.dark50}
											textAlign="center"
										>
											{logos.layouts.LogoTitle(data.logomark)}
										</typography.h4>
									</layouts.Flex>
								)}
								{data.wordmark?.present && (
									<layouts.Flex
										flexDirection="column"
										m="22px"
										className={logos.layouts.ContrastBGColorClassName(data.wordmark?.svg)}
									>
										<img src={data.wordmark?.svg} alt="Wordmark" style={{ maxHeight: "150px", maxWidth: "150px" }} />
										<typography.h4
											mt="14px"
											fontSize="18px"
											fontWeight="600"
											color={layouts.theme.colors.grey.dark50}
											textAlign="center"
										>
											{logos.layouts.LogoTitle(data.wordmark)}
										</typography.h4>
									</layouts.Flex>
								)}
								{data.alternative?.present && (
									<layouts.Flex
										flexDirection="column"
										m="22px"
										className={logos.layouts.ContrastBGColorClassName(data.alternative?.svg)}
									>
										<img
											src={data.alternative?.svg}
											alt="Alternative"
											style={{ maxHeight: "150px", maxWidth: "150px" }}
										/>
										<typography.h4
											mt="14px"
											fontSize="18px"
											fontWeight="600"
											color={layouts.theme.colors.grey.dark50}
											textAlign="center"
										>
											{logos.layouts.LogoTitle(data.alternative)}
										</typography.h4>
									</layouts.Flex>
								)}
							</layouts.Flex>
						)}
					</layouts.Flex>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Clearspace</results.Title>
					<results.Explanation desc={data.descriptions?.clearspace} />

					<layouts.containers.grid
						px="50px"
						width="100%"
						mb="50px"
						gridGap="50px"
						gridTemplateColumns="repeat(auto-fill, 300px)"
						gridTemplateRows="repeat(auto-fill, 250px)"
						style={{ alignContent: "center", alignItems: "center" }}
					>
						{items.map((item, index) => (
							<ClearspaseElement key={item?.id} item={item} />
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Proportions</results.Title>
					<results.Explanation desc={data.descriptions?.proportions} />

					<layouts.containers.grid
						width="100%"
						my="20px"
						gridGap="50px"
						gridTemplateColumns="repeat(auto-fill, 250px)"
						gridAutoRows="min-content"
						style={{ alignContent: "center", alignItems: "center" }}
					>
						{items.map((item, index) => (
							<layouts.Flex
								key={item?.id}
								justifyContent="center"
								alignItems="center"
								flexDirection="column"
								className={classnames(styledArrow, logos.layouts.ContrastBGColorClassName(item?.svg))}
							>
								<img src={item?.svg} alt={item?.id} style={{ maxWidth: `${item?.min_size}px` }} />
								<layouts.Flex flexDirection="column" my="10px">
									<icons.brandguide.arrows.ArrowLeftRight width={`${item?.min_size}px`} />
									<typography.h6
										fontSize="12px"
										fontWeight="500"
										color={layouts.theme.colors.grey.dark50alpha80}
										textAlign="center"
									>
										{item!.min_size}px
									</typography.h6>
								</layouts.Flex>
							</layouts.Flex>
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Correct Placement</results.Title>
					<results.Explanation desc={data.descriptions?.placement} />

					<layouts.Flex my="20px">
						<Placements />
					</layouts.Flex>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Misuse</results.Title>
					<results.Explanation desc={data.descriptions?.misuse} />

					<layouts.containers.grid
						my="20px"
						gap="15px"
						gridTemplateColumns="repeat(3, minmax(250px, 1fr))"
						gridTemplateRows="repeat(auto-fit, minmax(120px, 1fr))"
						style={{ alignContent: "start", alignItems: "start" }}
						minHeight="0px"
					>
						{(data.misuse || []).map((item) => (
							<logos.layouts.MisUseItem key={item.title} readonly item={item} onDelete={() => undefined} />
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Logo: Things To Do</results.Title>
					<results.Explanation desc={data.descriptions?.usage} />

					<layouts.containers.grid
						my="20px"
						gap="15px"
						gridTemplateColumns="repeat(2, minmax(250px, 1fr))"
						gridTemplateRows="repeat(auto-fit, minmax(120px, 1fr))"
						style={{ alignContent: "start", alignItems: "start" }}
						minHeight="0px"
					>
						{usage.map((item) => (
							<logos.layouts.UsageItem
								key={item.title}
								readonly
								svg={focused.svg}
								item={item}
								onDelete={() => undefined}
								correct
							/>
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<results.SectionContainer>
					<results.Title>Logo: Things To Avoid</results.Title>

					<layouts.containers.grid
						my="20px"
						gap="15px"
						gridTemplateColumns="repeat(2, minmax(250px, 1fr))"
						gridTemplateRows="repeat(auto-fit, minmax(120px, 1fr))"
						style={{ alignContent: "start", alignItems: "start" }}
						minHeight="0px"
					>
						{misuse.map((item) => (
							<logos.layouts.UsageItem
								key={item.title}
								svg={focused.svg}
								item={item}
								readonly
								onDelete={() => undefined}
							/>
						))}
					</layouts.containers.grid>
				</results.SectionContainer>

				<authz.Protected enabled={permission.brandguide_edit}>
					<layouts.Flex justifyContent="center" mt="100px" mb="20px" alignItems="center">
						<NextStageLink guide={guide} className="no-print" />
						<navigation.Section title="Logos" steps={logos.layouts.steps} onChange={(s) => onChange(s, undefined)} />
					</layouts.Flex>
				</authz.Protected>
			</layouts.Flex>
			<logos.Guidelines activPage="logos" className="no-print" />
		</layouts.Flex>
	)
}

interface clearspaceProps {
	item: brandguide.api.LogoObject | undefined
}

function ClearspaseElement(props: clearspaceProps): JSX.Element {
	const { item } = props
	const imgRef = useRef<HTMLImageElement>(null)

	const [imgWidth, setImgWidth] = useState(0)
	const [imgHeight, setImgHeight] = useState(0)

	const onImageLoad = () => {
		if (!imgRef.current) return
		setImgWidth(imgRef.current.offsetWidth)
		setImgHeight(imgRef.current.offsetHeight)
	}
	if (!item) return <></>

	return (
		<layouts.Flex flex="1" alignItems="center" justifyContent="center">
			<layouts.Flex position="relative" flexDirection="column">
				<layouts.Flex className={logos.layouts.ContrastBGColorClassName(item.svg)}>
					<img
						src={item.svg}
						alt={logos.layouts.LogoTitle(item)}
						style={{ height: "75px", width: "75px" }}
						ref={imgRef}
						onLoad={onImageLoad}
					/>
				</layouts.Flex>
				<logos.ClearspaceGrid
					key={item.id}
					focused={item}
					imgHeight={imgHeight}
					imgWidth={imgWidth}
					pathbbox={{ height: imgHeight, width: imgWidth, y: 0 } as DOMRect}
				/>
				<logos.XIndicator
					key={item.id}
					focused={item}
					imgHeight={imgHeight}
					imgWidth={imgWidth}
					pathbbox={{ height: imgHeight, width: imgWidth, y: 0 } as DOMRect}
				/>
			</layouts.Flex>
		</layouts.Flex>
	)
}

function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<layouts.Flex justifyContent="right" mr="47px">
						<brandguide.build.SavePDF />
					</layouts.Flex>
					<SectionResult onChange={onChange} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface nextProps extends layouts.containers.FlexProps {
	guide: brandguide.api.Brandguide
}

function NextStageLink(props: nextProps): JSX.Element {
	const { guide, ...rest } = props
	const brand = brands.caching.useCached()

	const nextLink = (): string | undefined => {
		if (guide.guide?.typography?.enabled) return brandguide.routing.build.typography(brand.id)
		if (guide.guide?.imagery?.enabled) return brandguide.routing.build.imagery(brand.id)
		if (guide.guide?.tone_of_voice?.enabled) return brandguide.routing.build.tone_of_voice(brand.id)
		if (guide.guide?.archetype?.enabled) return brandguide.routing.build.archetype(brand.id)
		if (guide.guide?.compliance?.enabled) return brandguide.routing.build.compliance(brand.id)
		if (guide.guide?.application?.enabled) return brandguide.routing.build.application(brand.id)
		return undefined
	}

	if (!nextLink()) return <></>

	return (
		<layouts.Flex {...rest}>
			<Link to={nextLink()!}>
				<layouts.buttons.unstyled
					width="130px"
					height="34px"
					background={layouts.theme.colors.grey.dark50}
					borderRadius="5px"
					color={layouts.theme.colors.white}
					onClick={() => undefined}
				>
					Next Section
				</layouts.buttons.unstyled>
			</Link>
		</layouts.Flex>
	)
}

NextStageLink.defaultProps = {
	flex: "1",
	justifyContent: "end",
}

export default Display

import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as typography from "typography"
import * as httpx from "httpx"
import * as api from "./api"

interface props {
	codeuri: string
	loadingIcon: JSX.Element
}

export function PKCEOTP(props: React.PropsWithChildren<props>): JSX.Element {
	const { codeuri, loadingIcon } = props
	const [loading, setLoading] = useState(true)
	const session = sessions.useSession()
	const oauth2 = api.oauth2.nsuiteclient()
	const navigate = useNavigate()

	const loginOTP = () => {
		sessions.cache.pkce.state.get().then((pkce_data: sessions.cache.pkce.state.data) => {
			api.oauth2
				.consumeotp(pkce_data.state)
				.then((code) => {
					oauth2.authorizationCode
						.getTokenFromCodeRedirect(`${codeuri}?code=${code.code}&state=${pkce_data.state}`, {
							redirectUri: codeuri,
							state: pkce_data.state,
							codeVerifier: pkce_data.codeVerifier,
						})
						.then((token) => {
							sessions.api
								.login(httpx.options.bearer(token.accessToken))
								.then(sessions.autoreplace(session, sessions.options.login))
								.then((current) => {
									setLoading(false)
									setTimeout(() => navigate("/"), 0) // as figma not supported setImmediate()
								})
						})
						.catch((cause) => {
							// TODO: update rendering to inform user of the problem.
							setLoading(false)
							console.error("unable to loginOTP", cause)
							setTimeout(() => window.location.reload(), 3000)
						})
				})
				.catch((e) => {
					if (e.status === 404 || e.status === 429) setTimeout(() => loginOTP(), 2000)
				})
		})
	}

	useEffect(() => {
		loginOTP()
	}, [])

	return (
		<layouts.loading.pending loading={loading} icon={loadingIcon}>
			<typography.h3>Authentication Complete</typography.h3>
			<layouts.buttons.primary onClick={() => navigate("/")}>Done</layouts.buttons.primary>
		</layouts.loading.pending>
	)
}

PKCEOTP.defaultProps = {
	codeuri: `${document.location.origin}/authorize.html`,
	loadingIcon: <></>,
}

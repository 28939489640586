import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { className, fill, ...rest } = props
	return (
		<svg.SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="15px"
			height="15px"
			viewBox="0 0 15 15"
			version="1.1"
			fill="none"
			{...rest}
		>
			<path
				fill={fill}
				d="M 14.867188 7.042969 C 13.273438 4.257812 10.566406 2.421875 7.5 2.421875 C 4.433594 2.421875 1.726562 4.257812 0.132812 7.042969 C 0.0898438 7.121094 0.00390625 7.300781 0 7.484375 C -0.00390625 7.667969 0.0703125 7.847656 0.109375 7.917969 C 1.703125 10.726562 4.417969 12.578125 7.5 12.578125 C 10.582031 12.578125 13.296875 10.726562 14.890625 7.917969 C 14.929688 7.847656 15.003906 7.667969 15 7.484375 C 14.996094 7.300781 14.910156 7.121094 14.867188 7.042969 Z M 7.5 10.9375 C 5.625 10.9375 4.101562 9.414062 4.101562 7.539062 C 4.101562 5.664062 5.625 4.140625 7.5 4.140625 C 9.375 4.140625 10.898438 5.664062 10.898438 7.539062 C 10.898438 9.414062 9.375 10.9375 7.5 10.9375 Z M 7.5 10.9375 "
			/>
			<path
				fill={fill}
				d="M 7.5 5.285156 C 7.128906 5.285156 6.785156 5.382812 6.476562 5.539062 L 7.308594 7.421875 L 5.394531 6.75 C 5.304688 6.996094 5.246094 7.261719 5.246094 7.539062 C 5.246094 8.785156 6.253906 9.792969 7.5 9.792969 C 8.746094 9.792969 9.753906 8.785156 9.753906 7.539062 C 9.753906 6.292969 8.746094 5.285156 7.5 5.285156 Z M 7.5 5.285156 "
			/>
		</svg.SVG>
	)
}

export default Icon

import React from "react"
import * as authzc from "authzcached"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as api from "brandguard/api"
import * as mediaapi from "media/api"
import * as bglayouts from "brandguard/components/layouts"

interface props {
	uploadItems: api.TextSearchResponseItem[]
	historicalItems: api.TextSearchResponseItem[]
	deleteAction?(
		bid: string,
		id: string,
		req: api.TextPatchRequest,
		...options: httpx.option[]
	): Promise<api.TextPatchResponse>
	onDelete?(items: api.TextSearchResponseItem[]): void
}

export default function MultiActions(props: React.PropsWithChildren<props>): JSX.Element {
	const { uploadItems, historicalItems, deleteAction, onDelete, children } = props
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const bearertoken = sessions.useToken()
	const allItems = [...uploadItems, ...historicalItems]

	const handleDelete = (): void => {
		const promises: Promise<api.TextPatchRequest>[] = []
		uploadItems.forEach((i) => {
			i.media!.tombstoned_at = new Date().toISOString()
			promises.push(deleteAction!(i.media!.brand_id, i.media!.id, i, authzc.bearer(authzaccount)))
		})
		Promise.all(promises)
			.then((results) => {
				onDelete!(results)
			})
			.catch((cause) => console.error("unable to handle multi-delete text", cause))
	}

	const handleDownload = (): void => {
		const downloadCsv = (csvContent: string): void => {
			const downloadLink = document.createElement("a")
			downloadLink.href = URL.createObjectURL(new Blob([csvContent], { type: "text/csv" }))
			downloadLink.download = "brandguard-assets.csv"
			downloadLink.click()
		}

		const promises: Promise<api.TextPatchRequest | string>[] = []
		allItems.forEach((i) => {
			promises.push(
				mediaapi.url(i.media?.md5!, bearertoken).then((result) => {
					return fetch(result.url).then((resp) => resp.text())
				}),
			)
		})

		Promise.all(promises).then((content) => {
			downloadCsv(content.join("\n"))
		})
	}

	if (!allItems.length) return <></>

	return (
		<bglayouts.DisplayActions
			cnt={allItems.length}
			canDelete={uploadItems.length > 0 && deleteAction !== undefined && onDelete !== undefined}
			handleDelete={handleDelete}
			handleDownload={handleDownload}
		>
			{children}
		</bglayouts.DisplayActions>
	)
}

MultiActions.defaultProps = {
	uploadItems: [],
	historicalItems: [],
}

import { Route, Routes, Navigate } from "react-router-dom"
import { useState } from "react"
import * as brands from "brands"
import * as layouts from "layouts"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as meta from "meta/authz"
import * as routing from "./routing"
import * as training from "brandguard/display/training"
import * as api from "brandguard/api"

interface props {
	permission: meta.Token
}

export namespace images {
	export function Review(props: props): JSX.Element {
		const { permission } = props
		return (
			<training.Container urlgen={routing.image.review} title="Suggested Assets">
				<layouts.Flex
					flexDirection="column"
					height="100%"
					overflow="hidden"
					data-testid="img-training-review-container"
				>
					<training.ImageActiveLink urlgen={routing.text.review} />
					<training.navigation.ImagesNav />
					<authz.Protected
						enabled={permission.brandguard_train}
						rejected={
							<authz.layouts.BlockDenied height="100%">
								You do not have permissions to train system. Please contact an admin.
							</authz.layouts.BlockDenied>
						}
					>
						<training.images.Suggested />
					</authz.Protected>
				</layouts.Flex>
			</training.Container>
		)
	}

	export function Trained(props: props): JSX.Element {
		const { permission } = props
		return (
			<training.Container urlgen={routing.image.upload}>
				<layouts.Flex
					flexDirection="column"
					height="100%"
					overflow="hidden"
					data-testid="img-training-upload-container"
				>
					<training.ImageActiveLink urlgen={routing.text.upload} />
					<training.navigation.ImagesNav />
					<authz.Protected
						enabled={permission.brandguard_train}
						rejected={
							<authz.layouts.BlockDenied height="100%">
								You do not have permissions to train system. Please contact an admin.
							</authz.layouts.BlockDenied>
						}
					>
						<training.images.Trained />
					</authz.Protected>
				</layouts.Flex>
			</training.Container>
		)
	}

	export function Settings(props: props): JSX.Element {
		const { permission } = props
		const [settings, setSettings] = useState(api.images.kernel.config.zero())

		return (
			<training.Container urlgen={routing.image.settings} title="Settings">
				<layouts.Flex
					flexDirection="column"
					height="100%"
					overflow="hidden"
					data-testid="img-training-settings-container"
				>
					<training.ImageActiveLink urlgen={routing.text.settings} />
					<training.navigation.ImagesNav />
					<authz.Protected
						enabled={permission.brandguard_train}
						rejected={
							<authz.layouts.BlockDenied height="100%">
								You do not have permissions to train system. Please contact an admin.
							</authz.layouts.BlockDenied>
						}
					>
						<layouts.Flex flex="1" flexDirection="column" overflowY="auto" data-testid="img-training-settings-display">
							<training.images.Settings current={settings} onChange={setSettings} />
							<training.images.Kernels key={settings.id} />
						</layouts.Flex>
					</authz.Protected>
				</layouts.Flex>
			</training.Container>
		)
	}
}

export namespace text {
	export function review(props: props): JSX.Element {
		const { permission } = props
		return (
			<training.Container urlgen={routing.text.review} title="Suggested Assets">
				<layouts.Flex
					flexDirection="column"
					height="100%"
					overflow="hidden"
					data-testid="text-training-review-container"
				>
					<training.TextActiveLink urlgen={routing.image.review} />
					<training.navigation.TextsNav />
					<authz.Protected
						enabled={permission.brandguard_train}
						rejected={
							<authz.layouts.BlockDenied height="100%">
								You do not have permissions to train system. Please contact an admin.
							</authz.layouts.BlockDenied>
						}
					>
						<training.texts.Suggested />
					</authz.Protected>
				</layouts.Flex>
			</training.Container>
		)
	}

	export function upload(props: props): JSX.Element {
		const { permission } = props
		return (
			<training.Container urlgen={routing.text.upload}>
				<layouts.Flex
					flexDirection="column"
					height="100%"
					overflow="hidden"
					data-testid="text-training-upload-container"
				>
					<training.TextActiveLink urlgen={routing.image.upload} />
					<training.navigation.TextsNav />
					<authz.Protected
						enabled={permission.brandguard_train}
						rejected={
							<authz.layouts.BlockDenied height="100%">
								You do not have permissions to train system. Please contact an admin.
							</authz.layouts.BlockDenied>
						}
					>
						<training.texts.Trained />
					</authz.Protected>
				</layouts.Flex>
			</training.Container>
		)
	}

	export function Settings(props: props): JSX.Element {
		const { permission } = props
		const [settings, setSettings] = useState(api.text.kernel.config.zero())
		return (
			<training.Container urlgen={routing.text.settings} title="Settings">
				<layouts.Flex
					flexDirection="column"
					height="100%"
					overflow="hidden"
					data-testid="text-training-settings-container"
				>
					<training.TextActiveLink urlgen={routing.image.settings} />
					<training.navigation.TextsNav />
					<authz.Protected
						enabled={permission.brandguard_train}
						rejected={
							<authz.layouts.BlockDenied height="100%">
								You do not have permissions to train system. Please contact an admin.
							</authz.layouts.BlockDenied>
						}
					>
						<layouts.Flex flex="1" flexDirection="column" overflowY="auto" data-testid="text-training-settings-display">
							<training.texts.Settings current={settings} onChange={setSettings} />
							<training.texts.Kernels key={settings.id} />
						</layouts.Flex>
					</authz.Protected>
				</layouts.Flex>
			</training.Container>
		)
	}
}

export default function Routing(): JSX.Element {
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const brand = brands.caching.useCached()

	return (
		<layouts.backgrounds.Grey flex="0 1 100%" height="100%" overflowY="hidden">
			<Routes>
				<Route path="/image/upload" element={<images.Trained permission={permission} />} />
				<Route path="/image/review" element={<images.Review permission={permission} />} />
				<Route path="/image/settings" element={<images.Settings permission={permission} />} />
				<Route path="/text/upload" element={<text.upload permission={permission} />} />
				<Route path="/text/review" element={<text.review permission={permission} />} />
				<Route path="/text/settings" element={<text.Settings permission={permission} />} />
				<Route path="*" element={<Navigate to={routing.image.upload(brand.id)} replace />} />
			</Routes>
		</layouts.backgrounds.Grey>
	)
}

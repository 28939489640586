import React from "react"
import { useMatch, NavLink } from "react-router-dom"
import * as layouts from "layouts"
import * as icons from "icons"
import classNames from "classnames"
import { colors } from "layouts/theme"
import { css } from "@emotion/css"
import * as uuid from "uuid"
import * as typography from "typography"

const styledStrokeOnHover = css`
	&:hover svg path {
		stroke: ${colors.grey.dark10};
	}
`

const styledFillOnHover = css`
	&:hover svg path {
		fill: ${colors.grey.dark10};
	}
`

interface linkProps {
	bid: string
	collapsed: boolean
	permissions: boolean
}

export function LogoLink(props: linkProps): JSX.Element {
	const { collapsed } = props
	return (
		<layouts.containers.box pb="50px" textAlign="left" ml="7px">
			<NavLink to="/">
				<icons.Logo fill={layouts.theme.colors.blue.bluedark20} height={collapsed ? "40px" : "60px"} />
			</NavLink>
		</layouts.containers.box>
	)
}

LogoLink.defaultProps = {
	bid: "",
	permissions: true,
}

export function UserNotification(props: linkProps & { unread_cnt: number }): JSX.Element {
	const { bid, unread_cnt, collapsed } = props
	const href = `/brands/${bid}/notifications`
	const active = !!useMatch({ path: href, end: true })
	if (bid === uuid.NIL) return <></>
	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledFillOnHover} testid="notifications-link">
			<layouts.Flex position="relative">
				{unread_cnt > 0 && (
					<layouts.containers.absolute top="-5px" right="-5px">
						<layouts.Flex
							borderRadius="50%"
							background={layouts.theme.colors.red.cinnabar}
							width="17px"
							height="17px"
							alignItems="center"
							justifyContent="center"
						>
							<typography.h4 fontSize="12px" fontWeight="700" lineHeight="normal" color={layouts.theme.colors.white}>
								{unread_cnt > 99 ? "99" : unread_cnt}
							</typography.h4>
						</layouts.Flex>
					</layouts.containers.absolute>
				)}
				<icons.MenuNotification width="20px" height="20px" stroke={active ? colors.white : colors.grey.dark10} />
			</layouts.Flex>
			<layouts.containers.span ml="10px" pt="4px">
				Notifications
			</layouts.containers.span>
		</Link>
	)
}

UserNotification.defaultProps = {
	permissions: true,
}

export function Brands(props: linkProps): JSX.Element {
	const { collapsed } = props
	const href = "/brands"
	const selfMatch = !!useMatch({ path: href, end: true })
	const unknownMatch = !!useMatch({ path: `${href}/unknown`, end: true })
	const active = selfMatch || unknownMatch
	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledFillOnHover} testid="brands-link">
			<icons.Dashboard width="20px" height="20px" fill={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				Brands
			</layouts.containers.span>
		</Link>
	)
}

Brands.defaultProps = {
	permissions: true,
}

export function StyleGuide(props: linkProps): JSX.Element {
	const { bid, collapsed } = props
	const href = `/brands/${bid}/styleguard`
	const active = !!useMatch({ path: href, end: true })
	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledStrokeOnHover} testid="styleguide-link">
			<icons.Styleguard width="20px" height="20px" stroke={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				StyleGuide
			</layouts.containers.span>
		</Link>
	)
}

StyleGuide.defaultProps = {
	permissions: true,
}

export function BrandGuide(props: linkProps): JSX.Element {
	const { bid, collapsed, permissions } = props
	const href = `/brands/${bid}/brandguide`
	const active = !!useMatch({ path: href, end: false })

	if (!permissions) return <></>

	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledFillOnHover} testid="brandguide-link">
			<icons.brandguide.nav.Icon width="20px" height="20px" fill={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				BrandGuide Builder
			</layouts.containers.span>
		</Link>
	)
}

BrandGuide.defaultProps = {
	permissions: true,
}

export function AssetsTraining(props: linkProps): JSX.Element {
	const { bid, collapsed, permissions } = props
	const href = `/brands/${bid}/training`
	const active = !!useMatch({ path: href, end: false })

	if (!permissions) return <></>

	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledFillOnHover} testid="assets-training-link">
			<icons.AssetsTraining width="20px" height="20px" fill={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				Assets Training
			</layouts.containers.span>
		</Link>
	)
}

export function BrandGuard(props: linkProps): JSX.Element {
	const { bid, collapsed } = props
	const href = `/brands/${bid}/brandguard`
	const selfMatch = !!useMatch({ path: href, end: false })
	const approvalMatch = !!useMatch({ path: `${href}/approval`, end: false })
	const active = approvalMatch ? false : selfMatch
	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledStrokeOnHover} testid="brandguard-link">
			<icons.Brandguard width="20px" height="20px" stroke={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				BrandGuard
			</layouts.containers.span>
		</Link>
	)
}

BrandGuard.defaultProps = {
	permissions: true,
}

export function Approvals(props: linkProps): JSX.Element {
	const { bid, collapsed } = props
	const href = `/brands/${bid}/brandguard/approval`
	const active = !!useMatch({ path: href, end: false })
	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledStrokeOnHover} testid="approvals-link">
			<icons.Approvals width="20px" height="20px" stroke={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				Approvals
			</layouts.containers.span>
		</Link>
	)
}

Approvals.defaultProps = {
	permissions: true,
}

export function Metrics(props: linkProps): JSX.Element {
	const { bid, collapsed } = props
	const href = `/brands/${bid}/metrics`
	const active = !!useMatch({ path: href, end: false })
	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledFillOnHover} testid="metrics-link">
			<icons.Metrics width="20px" height="20px" fill={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				Metrics
			</layouts.containers.span>
		</Link>
	)
}

Metrics.defaultProps = {
	permissions: true,
}

export function BrandGPT(props: linkProps): JSX.Element {
	const { permissions, bid, collapsed } = props
	const href = `/brands/${bid}/brandgpt`
	const active = !!useMatch({ path: href, end: false })

	if (!permissions) return <></>

	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledFillOnHover} testid="brandgpt-link">
			<icons.BrandGPT width="20px" height="20px" fill={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				BrandGPT
			</layouts.containers.span>
		</Link>
	)
}

export function AdPools(props: linkProps): JSX.Element {
	const { permissions, bid, collapsed } = props
	const href = `/brands/${bid}/ads`
	const active = !!useMatch({ path: href, end: false })

	if (!permissions) return <></>

	return (
		<Link href={href} collapsed={collapsed} active={active} className={styledFillOnHover}>
			<icons.Adpools width="20px" height="20px" fill={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				Ad Pools
			</layouts.containers.span>
		</Link>
	)
}

export function Settings(props: linkProps): JSX.Element {
	const { permissions, collapsed } = props
	const href = "/u12t"
	const active = !!useMatch({ path: href, end: false })

	if (!permissions) return <></>

	return (
		<Link href="/u12t" collapsed={collapsed} active={active} className={styledFillOnHover} testid="settings-link">
			<icons.Settings width="20px" height="20px" fill={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				Settings
			</layouts.containers.span>
		</Link>
	)
}

export function Logout(props: linkProps): JSX.Element {
	const { collapsed } = props
	const href = "/logout"
	const active = !!useMatch({ path: href, end: false })
	return (
		<Link
			href="/logout"
			mt="10px"
			collapsed={collapsed}
			active={active}
			className={styledFillOnHover}
			testid="logout-link"
		>
			<icons.Logout width="20px" height="20px" fill={active ? colors.white : colors.grey.dark10} />
			<layouts.containers.span ml="10px" pt="4px">
				Logout
			</layouts.containers.span>
		</Link>
	)
}

Logout.defaultProps = {
	permissions: true,
}

interface props extends layouts.containers.ContainerProps {
	href: string
	active: boolean
	collapsed: boolean
	testid?: string
}

function Link(props: React.PropsWithChildren<props>): JSX.Element {
	const { href, active, collapsed, children, className, testid } = props

	return (
		<layouts.links.navlink
			to={href}
			background={layouts.theme.colors.white}
			disabled={active}
			className={classNames({ collapsed: collapsed, active: active ? "active-link" : "" }, className)}
		>
			<layouts.containers.flex flexDirection="row" flex="1" data-testid={testid}>
				{children}
			</layouts.containers.flex>
		</layouts.links.navlink>
	)
}

import * as luxon from "luxon"
import * as authz from "authz"
import { CancellablePromise } from "real-cancellable-promise"

export function token<T extends authz.tokens.Token>(t: authz.tokens.Cached<T>): CancellablePromise<T> {
	const ts = luxon.DateTime.local().minus(10000)
	if (!authz.tokens.expired(t.current, ts)) {
		return new CancellablePromise(Promise.resolve(t.current), () => {})
	}

	t.pending ||= t.refresh().then((next) => {
		t.current = next
		t.pending = undefined
		return next
	})
	return t.pending
}

export function cached<T extends authz.tokens.Token>(
	t: T,
	refresh: (c: authz.tokens.Cached<T>) => CancellablePromise<T>,
): authz.tokens.Cached<T> {
	const cached = {
		current: t,
		pending: undefined,
		refresh: () => refresh(cached),
		token: (): CancellablePromise<T> => token(cached),
	}

	return cached
}

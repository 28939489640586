import React, { useEffect } from "react"
import * as layouts from "layouts"
import * as timex from "timex"
import classnames from "classnames"
import { uploaded } from "./api"
import * as styles from "./progress.styles"

export default function Completed<T>(
	props: React.PropsWithChildren<{
		className: string
		item: uploaded<T>
		onChange(item: uploaded<T>): void
	}>,
): JSX.Element {
	const { className, item, onChange } = props
	useEffect(() => {
		let cancelled = false
		const tid = setTimeout(() => cancelled || onChange(item), timex.duration.seconds(2).toMillis())
		return () => {
			clearTimeout(tid)
			cancelled = true
		}
	}, [])

	return (
		<layouts.Flex className={classnames("display-completed", className, styles.Container)} mb="5px">
			<layouts.Span className={styles.Description} flex="1">
				Completed: {item.display}
			</layouts.Span>
			<layouts.Span className={styles.Description} flex="1">
				<layouts.bars.progress.Percentage val={100} progressColor={item.background} />
			</layouts.Span>
		</layouts.Flex>
	)
}

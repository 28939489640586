import React, { useState } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as imagery from "brandguide/build/imagery"
import * as uuid from "uuid"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideImagery | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideImageryZero(_guide.guide?.imagery))

	const items = data.product?.items || []

	const hasTwoRowsItems = items.length >= brandguide.build.layouts.dualViewItemsCnt

	const ImageComponent = (item: brandguide.build.layouts.imageItem) => (
		<imagery.layouts.Image
			key={item.id}
			item={{ ...item, description: "" }}
			onDelete={() => {
				const upd = brandguide.api.zeros.imagery.sectionZero({ ...data.product })
				upd.items = items.filter((i) => i.id !== item.id)
				setData({ ...data, product: upd })
			}}
			height={hasTwoRowsItems ? "150px" : "250px"}
			width={hasTwoRowsItems ? "150px" : "250px"}
		/>
	)
	const itemsView = brandguide.build.layouts.itemsToView(ImageComponent, items)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Product Photography" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please upload all of your product photos here. Add any further explanation below.">
							<layouts.Flex flexDirection="column" flex="1" my="24px">
								{items.length === 0 && (
									<brandguide.build.dropwells.images.Dropwell
										onChange={(fs) => {
											const upd = brandguide.api.zeros.imagery.sectionZero({ ...data.product })
											fs.forEach((f) =>
												upd.items.push(
													brandguide.api.zeros.imagery.objectZero({
														id: uuid.v4(),
														thumbnail_contentdigest: f.thumbnail_contentdigest,
														contentdigest: f.contentdigest,
													}),
												),
											)
											setData({ ...data, product: upd })
										}}
									/>
								)}
								{items.length > 0 && (
									<imagery.Carousel my="10px" autoscrollright>
										<layouts.Flex gridGap="5px">
											{itemsView}
											<layouts.Flex height={hasTwoRowsItems ? "300px" : "250px"} width="250px">
												<brandguide.build.dropwells.images.Dropwell
													onChange={(fs) => {
														const upd = brandguide.api.zeros.imagery.sectionZero({ ...data.product })
														fs.forEach((f) =>
															upd.items.push(
																brandguide.api.zeros.imagery.objectZero({
																	id: uuid.v4(),
																	thumbnail_contentdigest: f.thumbnail_contentdigest,
																	contentdigest: f.contentdigest,
																}),
															),
														)
														setData({ ...data, product: upd })
													}}
												/>
											</layouts.Flex>
										</layouts.Flex>
									</imagery.Carousel>
								)}
							</layouts.Flex>

							<imagery.Explanation
								val={data.descriptions?.product || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.imagery.descZero({ ...data.descriptions, product: text }),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => {
									let back = imagery.Steps.START
									if (brandguide.validations.imagery.enabled.lifestyle(data))
										back = imagery.layouts.steps.findIndex((i) => i === "Lifestyle")
									onChange(back, undefined)
								}}
								onNext={() => {
									let next = imagery.layouts.steps.findIndex((i) => i === "Misuse")
									if (brandguide.validations.imagery.enabled.illustration(data))
										next = imagery.layouts.steps.findIndex((i) => i === "Illustration")
									onChange(next, data)
								}}
								disabled={!brandguide.validations.imagery.valid.product(data)}
							/>
							<imagery.Progress
								activeStep={imagery.layouts.actualSteps(data).findIndex((i) => i === "Product")}
								steps={imagery.layouts.actualSteps(data)}
							/>
						</brandguide.build.layouts.SectionStepContainer>
						<imagery.Guidelines activPage="imagery" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

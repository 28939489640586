import * as theme from "layouts/theme"
import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { fill, stroke, fillOpacity, strokeOpacity, ...rest } = props
	return (
		<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M3.57143 4.75H10.375M3.10714 1V2.12514M10.75 1V2.125M13 4L13 12.2501C13 13.2856 12.1605 14.1251 11.125 14.1251H2.875C1.83947 14.1251 1 13.2856 1 12.2501V4C1 2.96447 1.83946 2.125 2.875 2.125H11.125C12.1605 2.125 13 2.96446 13 4Z"
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<mask id="path-2-inside-1_2767_1419" fill="white">
				<rect x="2.875" y="6.93756" width="1.875" height="1.875" rx="0.5" />
			</mask>
			<rect
				x="2.875"
				y="6.93756"
				width="1.875"
				height="1.875"
				rx="0.5"
				fill={fill}
				fillOpacity={fillOpacity}
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeWidth="1.875"
				mask="url(#path-2-inside-1_2767_1419)"
			/>
			<mask id="path-3-inside-2_2767_1419" fill="white">
				<rect x="2.875" y="10.0626" width="1.875" height="1.875" rx="0.5" />
			</mask>
			<rect
				x="2.875"
				y="10.0626"
				width="1.875"
				height="1.875"
				rx="0.5"
				fill={fill}
				fillOpacity={fillOpacity}
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeWidth="1.875"
				mask="url(#path-3-inside-2_2767_1419)"
			/>
			<mask id="path-4-inside-3_2767_1419" fill="white">
				<rect x="6" y="6.93756" width="1.875" height="1.875" rx="0.5" />
			</mask>
			<rect
				x="6"
				y="6.93756"
				width="1.875"
				height="1.875"
				rx="0.5"
				fill={fill}
				fillOpacity={fillOpacity}
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeWidth="1.875"
				mask="url(#path-4-inside-3_2767_1419)"
			/>
			<mask id="path-5-inside-4_2767_1419" fill="white">
				<rect x="6" y="10.0626" width="1.875" height="1.875" rx="0.5" />
			</mask>
			<rect
				x="6"
				y="10.0626"
				width="1.875"
				height="1.875"
				rx="0.5"
				fill={fill}
				fillOpacity={fillOpacity}
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeWidth="1.875"
				mask="url(#path-5-inside-4_2767_1419)"
			/>
			<mask id="path-6-inside-5_2767_1419" fill="white">
				<rect x="9.125" y="6.93756" width="1.875" height="1.875" rx="0.5" />
			</mask>
			<rect
				x="9.125"
				y="6.93756"
				width="1.875"
				height="1.875"
				rx="0.5"
				fill={fill}
				fillOpacity={fillOpacity}
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeWidth="1.875"
				mask="url(#path-6-inside-5_2767_1419)"
			/>
			<mask id="path-7-inside-6_2767_1419" fill="white">
				<rect x="9.125" y="10.0626" width="1.875" height="1.875" rx="0.5" />
			</mask>
			<rect
				x="9.125"
				y="10.0626"
				width="1.875"
				height="1.875"
				rx="0.5"
				fill={fill}
				fillOpacity={fillOpacity}
				stroke={stroke}
				strokeOpacity={strokeOpacity}
				strokeWidth="1.875"
				mask="url(#path-7-inside-6_2767_1419)"
			/>
		</svg>
	)
}

Icon.defaultProps = {
	stroke: theme.colors.grey.dark50,
	strokeOpacity: "0.8",
	fill: theme.colors.grey.dark50,
	fillOpacity: "0.8",
}

export default Icon

/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguide.proto" (package "brandguide", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message brandguide.GoogleFont
 */
export interface GoogleFont {
    /**
     * @generated from protobuf field: string family = 1;
     */
    family: string;
    /**
     * @generated from protobuf field: repeated string variants = 2;
     */
    variants: string[];
    /**
     * @generated from protobuf field: repeated string subsets = 3;
     */
    subsets: string[];
    /**
     * @generated from protobuf field: string version = 4;
     */
    version: string;
    /**
     * @generated from protobuf field: string lastModified = 5;
     */
    lastModified: string;
    /**
     * @generated from protobuf field: map<string, string> files = 6;
     */
    files: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string category = 7;
     */
    category: string;
    /**
     * @generated from protobuf field: string kind = 8;
     */
    kind: string;
    /**
     * @generated from protobuf field: string menu = 9;
     */
    menu: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GoogleFontsResponse
 */
export interface GoogleFontsResponse {
    /**
     * @generated from protobuf field: string kind = 1;
     */
    kind: string;
    /**
     * @generated from protobuf field: repeated brandguide.GoogleFont items = 2;
     */
    items: GoogleFont[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.Color
 */
export interface Color {
    /**
     * @generated from protobuf field: string hex_code = 1 [json_name = "hex_code"];
     */
    hex_code: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.MetaData
 */
export interface MetaData {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool favorite = 2;
     */
    favorite: boolean;
    /**
     * @generated from protobuf field: bool default = 3 [json_name = "dafault"];
     */
    default: boolean;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ColorObject
 */
export interface ColorObject {
    /**
     * @generated from protobuf field: brandguide.Color color = 1;
     */
    color?: Color;
    /**
     * @generated from protobuf field: brandguide.MetaData metadata = 2;
     */
    metadata?: MetaData;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ColorContrastObject
 */
export interface ColorContrastObject {
    /**
     * @generated from protobuf field: brandguide.Color background = 1;
     */
    background?: Color;
    /**
     * @generated from protobuf field: brandguide.Color text = 2;
     */
    text?: Color;
    /**
     * @generated from protobuf field: brandguide.MetaData metadata = 3;
     */
    metadata?: MetaData;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ColorDescriptions
 */
export interface ColorDescriptions {
    /**
     * @generated from protobuf field: string primary_colors = 1 [json_name = "primary_colors"];
     */
    primary_colors: string;
    /**
     * @generated from protobuf field: string secondary_colors = 2 [json_name = "secondary_colors"];
     */
    secondary_colors: string;
    /**
     * @generated from protobuf field: string correct_color_contrasts = 3 [json_name = "correct_color_contrasts"];
     */
    correct_color_contrasts: string;
    /**
     * @generated from protobuf field: string incorrect_color_contrasts = 4 [json_name = "incorrect_color_contrasts"];
     */
    incorrect_color_contrasts: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GuideColors
 */
export interface GuideColors {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: repeated brandguide.ColorObject primary_colors = 2 [json_name = "primary_colors"];
     */
    primary_colors: ColorObject[];
    /**
     * @generated from protobuf field: repeated brandguide.ColorObject secondary_colors = 3 [json_name = "secondary_colors"];
     */
    secondary_colors: ColorObject[];
    /**
     * @generated from protobuf field: repeated brandguide.ColorContrastObject correct_color_contrasts = 4 [json_name = "correct_color_contrasts"];
     */
    correct_color_contrasts: ColorContrastObject[];
    /**
     * @generated from protobuf field: repeated brandguide.ColorContrastObject incorrect_color_contrasts = 5 [json_name = "incorrect_color_contrasts"];
     */
    incorrect_color_contrasts: ColorContrastObject[];
    /**
     * @generated from protobuf field: bool enabled = 6;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: brandguide.ColorDescriptions descriptions = 7;
     */
    descriptions?: ColorDescriptions;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.LogoObject
 */
export interface LogoObject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string svg = 2;
     */
    svg: string;
    /**
     * @generated from protobuf field: bool present = 3;
     */
    present: boolean;
    /**
     * @generated from protobuf field: int32 clearspace = 4;
     */
    clearspace: number;
    /**
     * @generated from protobuf field: int32 min_size = 5 [json_name = "min_size"];
     */
    min_size: number;
    /**
     * @generated from protobuf field: int32 clearspace_path_index = 6 [json_name = "clearspace_path_index"];
     */
    clearspace_path_index: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.LogosDescriptions
 */
export interface LogosDescriptions {
    /**
     * @generated from protobuf field: string uploads = 1;
     */
    uploads: string;
    /**
     * @generated from protobuf field: string clearspace = 2;
     */
    clearspace: string;
    /**
     * @generated from protobuf field: string proportions = 3;
     */
    proportions: string;
    /**
     * @generated from protobuf field: string placement = 4;
     */
    placement: string;
    /**
     * @generated from protobuf field: string misuse = 5;
     */
    misuse: string;
    /**
     * @generated from protobuf field: string usage = 6;
     */
    usage: string;
}
/**
 * @generated from protobuf message brandguide.LogoPlacement
 */
export interface LogoPlacement {
    /**
     * @generated from protobuf field: repeated brandguide.Placements left = 1;
     */
    left: Placements[];
    /**
     * @generated from protobuf field: repeated brandguide.Placements center = 2;
     */
    center: Placements[];
    /**
     * @generated from protobuf field: repeated brandguide.Placements right = 3;
     */
    right: Placements[];
    /**
     * @generated from protobuf field: repeated brandguide.Placements all = 4;
     */
    all: Placements[];
}
/**
 * @generated from protobuf message brandguide.LogoMisuse
 */
export interface LogoMisuse {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string svg = 2;
     */
    svg: string;
}
/**
 * @generated from protobuf message brandguide.LogoUsage
 */
export interface LogoUsage {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: bool use = 3;
     */
    use: boolean;
    /**
     * @generated from protobuf field: brandguide.Color color = 4;
     */
    color?: Color;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GuideLogos
 */
export interface GuideLogos {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: brandguide.LogoObject primary = 2;
     */
    primary?: LogoObject;
    /**
     * @generated from protobuf field: brandguide.LogoObject secondary = 3;
     */
    secondary?: LogoObject;
    /**
     * @generated from protobuf field: brandguide.LogoObject logomark = 4;
     */
    logomark?: LogoObject;
    /**
     * @generated from protobuf field: brandguide.LogoObject wordmark = 5;
     */
    wordmark?: LogoObject;
    /**
     * @generated from protobuf field: brandguide.LogoObject alternative = 6;
     */
    alternative?: LogoObject;
    /**
     * @generated from protobuf field: bool enabled = 7;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: brandguide.LogoPlacement placement = 8;
     */
    placement?: LogoPlacement;
    /**
     * @generated from protobuf field: repeated brandguide.LogoMisuse misuse = 9;
     */
    misuse: LogoMisuse[];
    /**
     * @generated from protobuf field: repeated brandguide.LogoUsage usage = 10;
     */
    usage: LogoUsage[];
    /**
     * @generated from protobuf field: brandguide.LogosDescriptions descriptions = 11;
     */
    descriptions?: LogosDescriptions;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.FontObject
 */
export interface FontObject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string font = 2;
     */
    font: string;
    /**
     * @generated from protobuf field: string font_family = 3 [json_name = "font_family"];
     */
    font_family: string;
    /**
     * @generated from protobuf field: string file_name = 4 [json_name = "file_name"];
     */
    file_name: string;
    /**
     * @generated from protobuf field: string font_type = 5 [json_name = "font_type"];
     */
    font_type: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.TypographyObject
 */
export interface TypographyObject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string font_id = 2 [json_name = "font_id"];
     */
    font_id: string;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * @generated from protobuf field: int32 size = 4;
     */
    size: number;
    /**
     * @generated from protobuf field: bool bold = 5;
     */
    bold: boolean;
    /**
     * @generated from protobuf field: bool italic = 6;
     */
    italic: boolean;
    /**
     * @generated from protobuf field: brandguide.TextTransform transform = 7;
     */
    transform: TextTransform;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.TypographyDescriptions
 */
export interface TypographyDescriptions {
    /**
     * @generated from protobuf field: string uploads = 1;
     */
    uploads: string;
    /**
     * @generated from protobuf field: string usage = 2;
     */
    usage: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GuideTypography
 */
export interface GuideTypography {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: repeated brandguide.TypographyObject items = 2;
     */
    items: TypographyObject[];
    /**
     * @generated from protobuf field: repeated brandguide.FontObject fonts = 3;
     */
    fonts: FontObject[];
    /**
     * @generated from protobuf field: bool enabled = 4;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: brandguide.TypographyDescriptions descriptions = 5;
     */
    descriptions?: TypographyDescriptions;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ImageryObject
 */
export interface ImageryObject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string contentdigest = 4;
     */
    contentdigest: string;
    /**
     * @generated from protobuf field: string thumbnail_contentdigest = 5 [json_name = "thumbnail_contentdigest"];
     */
    thumbnail_contentdigest: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ImageryFundamentalItem
 */
export interface ImageryFundamentalItem {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: bool checked = 2;
     */
    checked: boolean;
    /**
     * @generated from protobuf field: bool is_default = 3 [json_name = "is_default"];
     */
    is_default: boolean;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ImageryFundamentalObject
 */
export interface ImageryFundamentalObject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated brandguide.ImageryFundamentalItem items = 3;
     */
    items: ImageryFundamentalItem[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ImagerySection
 */
export interface ImagerySection {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguide.ImageryObject items = 2;
     */
    items: ImageryObject[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ImageryDescriptions
 */
export interface ImageryDescriptions {
    /**
     * @generated from protobuf field: string lifestyle = 1;
     */
    lifestyle: string;
    /**
     * @generated from protobuf field: string product = 2;
     */
    product: string;
    /**
     * @generated from protobuf field: string illustration = 3;
     */
    illustration: string;
    /**
     * @generated from protobuf field: string misuse = 4;
     */
    misuse: string;
    /**
     * @generated from protobuf field: string fundamentals = 5;
     */
    fundamentals: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GuideImagery
 */
export interface GuideImagery {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: brandguide.ImagerySection lifestyle = 3;
     */
    lifestyle?: ImagerySection;
    /**
     * @generated from protobuf field: brandguide.ImagerySection product = 4;
     */
    product?: ImagerySection;
    /**
     * @generated from protobuf field: brandguide.ImagerySection illustration = 5;
     */
    illustration?: ImagerySection;
    /**
     * @generated from protobuf field: brandguide.ImageryDescriptions descriptions = 6;
     */
    descriptions?: ImageryDescriptions;
    /**
     * @generated from protobuf field: repeated brandguide.ImageryObject misuse = 7;
     */
    misuse: ImageryObject[];
    /**
     * @generated from protobuf field: repeated brandguide.ImageryFundamentalObject fundamentals = 8;
     */
    fundamentals: ImageryFundamentalObject[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ToneOfVoiceAdjective
 */
export interface ToneOfVoiceAdjective {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ToneOfVoiceObject
 */
export interface ToneOfVoiceObject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string text = 3;
     */
    text: string;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: bool selected = 5;
     */
    selected: boolean;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ToneOfVoiceDescriptions
 */
export interface ToneOfVoiceDescriptions {
    /**
     * @generated from protobuf field: string tone_of_voice = 1 [json_name = "tone_of_voice"];
     */
    tone_of_voice: string;
    /**
     * @generated from protobuf field: string writing_style = 2 [json_name = "writing_style"];
     */
    writing_style: string;
    /**
     * @generated from protobuf field: string adjectives = 3;
     */
    adjectives: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GuideToneOfVoice
 */
export interface GuideToneOfVoice {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguide.ToneOfVoiceObject tone_of_voice = 3 [json_name = "tone_of_voice"];
     */
    tone_of_voice: ToneOfVoiceObject[];
    /**
     * @generated from protobuf field: repeated brandguide.ToneOfVoiceObject writing_style = 4 [json_name = "writing_style"];
     */
    writing_style: ToneOfVoiceObject[];
    /**
     * @generated from protobuf field: brandguide.ToneOfVoiceDescriptions descriptions = 5;
     */
    descriptions?: ToneOfVoiceDescriptions;
    /**
     * @generated from protobuf field: repeated brandguide.ToneOfVoiceAdjective positive_adjectives = 6 [json_name = "positive_adjectives"];
     */
    positive_adjectives: ToneOfVoiceAdjective[];
    /**
     * @generated from protobuf field: repeated brandguide.ToneOfVoiceAdjective negative_adjectives = 7 [json_name = "negative_adjectives"];
     */
    negative_adjectives: ToneOfVoiceAdjective[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ApplicationObject
 */
export interface ApplicationObject {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string contentdigest = 3;
     */
    contentdigest: string;
    /**
     * @generated from protobuf field: string thumbnail_contentdigest = 4 [json_name = "thumbnail_contentdigest"];
     */
    thumbnail_contentdigest: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ApplicationDescriptions
 */
export interface ApplicationDescriptions {
    /**
     * @generated from protobuf field: string upload = 1;
     */
    upload: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GuideApplication
 */
export interface GuideApplication {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguide.ApplicationObject items = 3;
     */
    items: ApplicationObject[];
    /**
     * @generated from protobuf field: brandguide.ApplicationDescriptions descriptions = 4;
     */
    descriptions?: ApplicationDescriptions;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ArchetypeAdjectiveObject
 */
export interface ArchetypeAdjectiveObject {
    /**
     * @generated from protobuf field: brandguide.Archetype archetype = 1;
     */
    archetype: Archetype;
    /**
     * @generated from protobuf field: repeated string items = 2;
     */
    items: string[];
    /**
     * @generated from protobuf field: int32 percent = 3;
     */
    percent: number;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ArchetypeDescriptions
 */
export interface ArchetypeDescriptions {
    /**
     * @generated from protobuf field: string selection = 1;
     */
    selection: string;
    /**
     * @generated from protobuf field: string breakdown = 2;
     */
    breakdown: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GuideArchetype
 */
export interface GuideArchetype {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguide.ArchetypeAdjectiveObject adjectives = 5;
     */
    adjectives: ArchetypeAdjectiveObject[];
    /**
     * @generated from protobuf field: brandguide.ArchetypeDescriptions descriptions = 6;
     */
    descriptions?: ArchetypeDescriptions;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ComplianceName
 */
export interface ComplianceName {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ComplianceItem
 */
export interface ComplianceItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string info = 3;
     */
    info: string;
    /**
     * @generated from protobuf field: repeated brandguide.ComplianceName names = 4;
     */
    names: ComplianceName[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.ComplianceDescriptions
 */
export interface ComplianceDescriptions {
    /**
     * @generated from protobuf field: string selection = 1;
     */
    selection: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.GuideCompliance
 */
export interface GuideCompliance {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: repeated brandguide.ComplianceItem items = 3;
     */
    items: ComplianceItem[];
    /**
     * @generated from protobuf field: brandguide.ComplianceDescriptions descriptions = 4;
     */
    descriptions?: ComplianceDescriptions;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.Guide
 */
export interface Guide {
    /**
     * @generated from protobuf field: brandguide.GuideColors colors = 1;
     */
    colors?: GuideColors;
    /**
     * @generated from protobuf field: brandguide.GuideLogos logos = 2;
     */
    logos?: GuideLogos;
    /**
     * @generated from protobuf field: brandguide.GuideTypography typography = 3;
     */
    typography?: GuideTypography;
    /**
     * @generated from protobuf field: brandguide.GuideImagery imagery = 4;
     */
    imagery?: GuideImagery;
    /**
     * @generated from protobuf field: brandguide.GuideToneOfVoice tone_of_voice = 5 [json_name = "tone_of_voice"];
     */
    tone_of_voice?: GuideToneOfVoice;
    /**
     * @generated from protobuf field: brandguide.GuideApplication application = 6;
     */
    application?: GuideApplication;
    /**
     * @generated from protobuf field: brandguide.GuideArchetype archetype = 7;
     */
    archetype?: GuideArchetype;
    /**
     * @generated from protobuf field: brandguide.GuideCompliance compliance = 8;
     */
    compliance?: GuideCompliance;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.BrandguideObject
 */
export interface BrandguideObject {
    /**
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string profile_id = 3 [json_name = "profile_id"];
     */
    profile_id: string;
    /**
     * @generated from protobuf field: string brand_id = 4 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 6 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: brandguide.Guide guide = 7;
     */
    guide?: Guide;
    /**
     * @generated from protobuf field: bool pdf_generated = 8 [json_name = "pdf_generated"];
     */
    pdf_generated: boolean;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.BrandguideFindRequest
 */
export interface BrandguideFindRequest {
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.BrandguideFindResponse
 */
export interface BrandguideFindResponse {
    /**
     * @generated from protobuf field: brandguide.BrandguideObject brandguide_object = 1 [json_name = "brandguide"];
     */
    brandguide_object?: BrandguideObject;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.BrandguideCreateRequest
 */
export interface BrandguideCreateRequest {
    /**
     * @generated from protobuf field: brandguide.Guide guide = 1;
     */
    guide?: Guide;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.BrandguideCreateResponse
 */
export interface BrandguideCreateResponse {
    /**
     * @generated from protobuf field: brandguide.BrandguideObject brandguide_object = 1 [json_name = "brandguide"];
     */
    brandguide_object?: BrandguideObject;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.BrandguideSearchRequest
 */
export interface BrandguideSearchRequest {
    /**
     * @generated from protobuf field: int32 limit = 1;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 offset = 2;
     */
    offset: number;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguide.BrandguideSearchResponse
 */
export interface BrandguideSearchResponse {
    /**
     * @generated from protobuf field: brandguide.BrandguideSearchRequest next = 1;
     */
    next?: BrandguideSearchRequest;
    /**
     * @generated from protobuf field: repeated string versions = 2;
     */
    versions: string[];
}
/**
 * @generated from protobuf enum brandguide.Placements
 */
export enum Placements {
    /**
     * @generated from protobuf enum value: UNSET = 0;
     */
    UNSET = 0,
    /**
     * @generated from protobuf enum value: TOP = 1;
     */
    TOP = 1,
    /**
     * @generated from protobuf enum value: BOTTOM = 2;
     */
    BOTTOM = 2,
    /**
     * @generated from protobuf enum value: MIDDLE = 3;
     */
    MIDDLE = 3,
    /**
     * @generated from protobuf enum value: ALL = 4;
     */
    ALL = 4
}
/**
 * easyjson:json
 *
 * @generated from protobuf enum brandguide.TextTransform
 */
export enum TextTransform {
    /**
     * @generated from protobuf enum value: NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: UPPERCASE = 1;
     */
    UPPERCASE = 1,
    /**
     * @generated from protobuf enum value: LOWERCASE = 2;
     */
    LOWERCASE = 2,
    /**
     * @generated from protobuf enum value: TITLECASE = 3;
     */
    TITLECASE = 3,
    /**
     * @generated from protobuf enum value: SMALLCAPS = 4;
     */
    SMALLCAPS = 4
}
/**
 * easyjson:json
 *
 * @generated from protobuf enum brandguide.Archetype
 */
export enum Archetype {
    /**
     * @generated from protobuf enum value: Outlaw = 0;
     */
    Outlaw = 0,
    /**
     * @generated from protobuf enum value: Magician = 1;
     */
    Magician = 1,
    /**
     * @generated from protobuf enum value: Hero = 2;
     */
    Hero = 2,
    /**
     * @generated from protobuf enum value: Lover = 3;
     */
    Lover = 3,
    /**
     * @generated from protobuf enum value: Jester = 4;
     */
    Jester = 4,
    /**
     * @generated from protobuf enum value: Everyman = 5;
     */
    Everyman = 5,
    /**
     * @generated from protobuf enum value: Caregiver = 6;
     */
    Caregiver = 6,
    /**
     * @generated from protobuf enum value: Ruler = 7;
     */
    Ruler = 7,
    /**
     * @generated from protobuf enum value: Creator = 8;
     */
    Creator = 8,
    /**
     * @generated from protobuf enum value: Innocent = 9;
     */
    Innocent = 9,
    /**
     * @generated from protobuf enum value: Sage = 10;
     */
    Sage = 10,
    /**
     * @generated from protobuf enum value: Explorer = 11;
     */
    Explorer = 11
}
// @generated message type with reflection information, may provide speed optimized methods
class GoogleFont$Type extends MessageType<GoogleFont> {
    constructor() {
        super("brandguide.GoogleFont", [
            { no: 1, name: "family", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "variants", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subsets", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "lastModified", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "files", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 7, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "menu", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GoogleFont>): GoogleFont {
        const message = { family: "", variants: [], subsets: [], version: "", lastModified: "", files: {}, category: "", kind: "", menu: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GoogleFont>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GoogleFont): GoogleFont {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string family */ 1:
                    message.family = reader.string();
                    break;
                case /* repeated string variants */ 2:
                    message.variants.push(reader.string());
                    break;
                case /* repeated string subsets */ 3:
                    message.subsets.push(reader.string());
                    break;
                case /* string version */ 4:
                    message.version = reader.string();
                    break;
                case /* string lastModified */ 5:
                    message.lastModified = reader.string();
                    break;
                case /* map<string, string> files */ 6:
                    this.binaryReadMap6(message.files, reader, options);
                    break;
                case /* string category */ 7:
                    message.category = reader.string();
                    break;
                case /* string kind */ 8:
                    message.kind = reader.string();
                    break;
                case /* string menu */ 9:
                    message.menu = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: GoogleFont["files"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GoogleFont["files"] | undefined, val: GoogleFont["files"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field brandguide.GoogleFont.files");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: GoogleFont, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string family = 1; */
        if (message.family !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.family);
        /* repeated string variants = 2; */
        for (let i = 0; i < message.variants.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.variants[i]);
        /* repeated string subsets = 3; */
        for (let i = 0; i < message.subsets.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.subsets[i]);
        /* string version = 4; */
        if (message.version !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.version);
        /* string lastModified = 5; */
        if (message.lastModified !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastModified);
        /* map<string, string> files = 6; */
        for (let k of Object.keys(message.files))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.files[k]).join();
        /* string category = 7; */
        if (message.category !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.category);
        /* string kind = 8; */
        if (message.kind !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.kind);
        /* string menu = 9; */
        if (message.menu !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.menu);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GoogleFont
 */
export const GoogleFont = new GoogleFont$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoogleFontsResponse$Type extends MessageType<GoogleFontsResponse> {
    constructor() {
        super("brandguide.GoogleFontsResponse", [
            { no: 1, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GoogleFont }
        ]);
    }
    create(value?: PartialMessage<GoogleFontsResponse>): GoogleFontsResponse {
        const message = { kind: "", items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GoogleFontsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GoogleFontsResponse): GoogleFontsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string kind */ 1:
                    message.kind = reader.string();
                    break;
                case /* repeated brandguide.GoogleFont items */ 2:
                    message.items.push(GoogleFont.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GoogleFontsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string kind = 1; */
        if (message.kind !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.kind);
        /* repeated brandguide.GoogleFont items = 2; */
        for (let i = 0; i < message.items.length; i++)
            GoogleFont.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GoogleFontsResponse
 */
export const GoogleFontsResponse = new GoogleFontsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Color$Type extends MessageType<Color> {
    constructor() {
        super("brandguide.Color", [
            { no: 1, name: "hex_code", kind: "scalar", localName: "hex_code", jsonName: "hex_code", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Color>): Color {
        const message = { hex_code: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Color>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Color): Color {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string hex_code = 1 [json_name = "hex_code"];*/ 1:
                    message.hex_code = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Color, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string hex_code = 1 [json_name = "hex_code"]; */
        if (message.hex_code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.hex_code);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.Color
 */
export const Color = new Color$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetaData$Type extends MessageType<MetaData> {
    constructor() {
        super("brandguide.MetaData", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "favorite", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "default", kind: "scalar", jsonName: "dafault", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MetaData>): MetaData {
        const message = { id: "", favorite: false, default: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MetaData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MetaData): MetaData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool favorite */ 2:
                    message.favorite = reader.bool();
                    break;
                case /* bool default = 3 [json_name = "dafault"];*/ 3:
                    message.default = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MetaData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool favorite = 2; */
        if (message.favorite !== false)
            writer.tag(2, WireType.Varint).bool(message.favorite);
        /* bool default = 3 [json_name = "dafault"]; */
        if (message.default !== false)
            writer.tag(3, WireType.Varint).bool(message.default);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.MetaData
 */
export const MetaData = new MetaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ColorObject$Type extends MessageType<ColorObject> {
    constructor() {
        super("brandguide.ColorObject", [
            { no: 1, name: "color", kind: "message", T: () => Color },
            { no: 2, name: "metadata", kind: "message", T: () => MetaData }
        ]);
    }
    create(value?: PartialMessage<ColorObject>): ColorObject {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ColorObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ColorObject): ColorObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguide.Color color */ 1:
                    message.color = Color.internalBinaryRead(reader, reader.uint32(), options, message.color);
                    break;
                case /* brandguide.MetaData metadata */ 2:
                    message.metadata = MetaData.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ColorObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguide.Color color = 1; */
        if (message.color)
            Color.internalBinaryWrite(message.color, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.MetaData metadata = 2; */
        if (message.metadata)
            MetaData.internalBinaryWrite(message.metadata, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ColorObject
 */
export const ColorObject = new ColorObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ColorContrastObject$Type extends MessageType<ColorContrastObject> {
    constructor() {
        super("brandguide.ColorContrastObject", [
            { no: 1, name: "background", kind: "message", T: () => Color },
            { no: 2, name: "text", kind: "message", T: () => Color },
            { no: 3, name: "metadata", kind: "message", T: () => MetaData }
        ]);
    }
    create(value?: PartialMessage<ColorContrastObject>): ColorContrastObject {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ColorContrastObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ColorContrastObject): ColorContrastObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguide.Color background */ 1:
                    message.background = Color.internalBinaryRead(reader, reader.uint32(), options, message.background);
                    break;
                case /* brandguide.Color text */ 2:
                    message.text = Color.internalBinaryRead(reader, reader.uint32(), options, message.text);
                    break;
                case /* brandguide.MetaData metadata */ 3:
                    message.metadata = MetaData.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ColorContrastObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguide.Color background = 1; */
        if (message.background)
            Color.internalBinaryWrite(message.background, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.Color text = 2; */
        if (message.text)
            Color.internalBinaryWrite(message.text, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.MetaData metadata = 3; */
        if (message.metadata)
            MetaData.internalBinaryWrite(message.metadata, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ColorContrastObject
 */
export const ColorContrastObject = new ColorContrastObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ColorDescriptions$Type extends MessageType<ColorDescriptions> {
    constructor() {
        super("brandguide.ColorDescriptions", [
            { no: 1, name: "primary_colors", kind: "scalar", localName: "primary_colors", jsonName: "primary_colors", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "secondary_colors", kind: "scalar", localName: "secondary_colors", jsonName: "secondary_colors", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "correct_color_contrasts", kind: "scalar", localName: "correct_color_contrasts", jsonName: "correct_color_contrasts", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "incorrect_color_contrasts", kind: "scalar", localName: "incorrect_color_contrasts", jsonName: "incorrect_color_contrasts", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ColorDescriptions>): ColorDescriptions {
        const message = { primary_colors: "", secondary_colors: "", correct_color_contrasts: "", incorrect_color_contrasts: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ColorDescriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ColorDescriptions): ColorDescriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string primary_colors = 1 [json_name = "primary_colors"];*/ 1:
                    message.primary_colors = reader.string();
                    break;
                case /* string secondary_colors = 2 [json_name = "secondary_colors"];*/ 2:
                    message.secondary_colors = reader.string();
                    break;
                case /* string correct_color_contrasts = 3 [json_name = "correct_color_contrasts"];*/ 3:
                    message.correct_color_contrasts = reader.string();
                    break;
                case /* string incorrect_color_contrasts = 4 [json_name = "incorrect_color_contrasts"];*/ 4:
                    message.incorrect_color_contrasts = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ColorDescriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string primary_colors = 1 [json_name = "primary_colors"]; */
        if (message.primary_colors !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.primary_colors);
        /* string secondary_colors = 2 [json_name = "secondary_colors"]; */
        if (message.secondary_colors !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.secondary_colors);
        /* string correct_color_contrasts = 3 [json_name = "correct_color_contrasts"]; */
        if (message.correct_color_contrasts !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.correct_color_contrasts);
        /* string incorrect_color_contrasts = 4 [json_name = "incorrect_color_contrasts"]; */
        if (message.incorrect_color_contrasts !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.incorrect_color_contrasts);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ColorDescriptions
 */
export const ColorDescriptions = new ColorDescriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideColors$Type extends MessageType<GuideColors> {
    constructor() {
        super("brandguide.GuideColors", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "primary_colors", kind: "message", localName: "primary_colors", jsonName: "primary_colors", repeat: 1 /*RepeatType.PACKED*/, T: () => ColorObject },
            { no: 3, name: "secondary_colors", kind: "message", localName: "secondary_colors", jsonName: "secondary_colors", repeat: 1 /*RepeatType.PACKED*/, T: () => ColorObject },
            { no: 4, name: "correct_color_contrasts", kind: "message", localName: "correct_color_contrasts", jsonName: "correct_color_contrasts", repeat: 1 /*RepeatType.PACKED*/, T: () => ColorContrastObject },
            { no: 5, name: "incorrect_color_contrasts", kind: "message", localName: "incorrect_color_contrasts", jsonName: "incorrect_color_contrasts", repeat: 1 /*RepeatType.PACKED*/, T: () => ColorContrastObject },
            { no: 6, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "descriptions", kind: "message", T: () => ColorDescriptions }
        ]);
    }
    create(value?: PartialMessage<GuideColors>): GuideColors {
        const message = { id: "", primary_colors: [], secondary_colors: [], correct_color_contrasts: [], incorrect_color_contrasts: [], enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideColors>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideColors): GuideColors {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated brandguide.ColorObject primary_colors = 2 [json_name = "primary_colors"];*/ 2:
                    message.primary_colors.push(ColorObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguide.ColorObject secondary_colors = 3 [json_name = "secondary_colors"];*/ 3:
                    message.secondary_colors.push(ColorObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguide.ColorContrastObject correct_color_contrasts = 4 [json_name = "correct_color_contrasts"];*/ 4:
                    message.correct_color_contrasts.push(ColorContrastObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguide.ColorContrastObject incorrect_color_contrasts = 5 [json_name = "incorrect_color_contrasts"];*/ 5:
                    message.incorrect_color_contrasts.push(ColorContrastObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool enabled */ 6:
                    message.enabled = reader.bool();
                    break;
                case /* brandguide.ColorDescriptions descriptions */ 7:
                    message.descriptions = ColorDescriptions.internalBinaryRead(reader, reader.uint32(), options, message.descriptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuideColors, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated brandguide.ColorObject primary_colors = 2 [json_name = "primary_colors"]; */
        for (let i = 0; i < message.primary_colors.length; i++)
            ColorObject.internalBinaryWrite(message.primary_colors[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.ColorObject secondary_colors = 3 [json_name = "secondary_colors"]; */
        for (let i = 0; i < message.secondary_colors.length; i++)
            ColorObject.internalBinaryWrite(message.secondary_colors[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.ColorContrastObject correct_color_contrasts = 4 [json_name = "correct_color_contrasts"]; */
        for (let i = 0; i < message.correct_color_contrasts.length; i++)
            ColorContrastObject.internalBinaryWrite(message.correct_color_contrasts[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.ColorContrastObject incorrect_color_contrasts = 5 [json_name = "incorrect_color_contrasts"]; */
        for (let i = 0; i < message.incorrect_color_contrasts.length; i++)
            ColorContrastObject.internalBinaryWrite(message.incorrect_color_contrasts[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool enabled = 6; */
        if (message.enabled !== false)
            writer.tag(6, WireType.Varint).bool(message.enabled);
        /* brandguide.ColorDescriptions descriptions = 7; */
        if (message.descriptions)
            ColorDescriptions.internalBinaryWrite(message.descriptions, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GuideColors
 */
export const GuideColors = new GuideColors$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoObject$Type extends MessageType<LogoObject> {
    constructor() {
        super("brandguide.LogoObject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "svg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "present", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "clearspace", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "min_size", kind: "scalar", localName: "min_size", jsonName: "min_size", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "clearspace_path_index", kind: "scalar", localName: "clearspace_path_index", jsonName: "clearspace_path_index", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LogoObject>): LogoObject {
        const message = { id: "", svg: "", present: false, clearspace: 0, min_size: 0, clearspace_path_index: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoObject): LogoObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string svg */ 2:
                    message.svg = reader.string();
                    break;
                case /* bool present */ 3:
                    message.present = reader.bool();
                    break;
                case /* int32 clearspace */ 4:
                    message.clearspace = reader.int32();
                    break;
                case /* int32 min_size = 5 [json_name = "min_size"];*/ 5:
                    message.min_size = reader.int32();
                    break;
                case /* int32 clearspace_path_index = 6 [json_name = "clearspace_path_index"];*/ 6:
                    message.clearspace_path_index = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string svg = 2; */
        if (message.svg !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.svg);
        /* bool present = 3; */
        if (message.present !== false)
            writer.tag(3, WireType.Varint).bool(message.present);
        /* int32 clearspace = 4; */
        if (message.clearspace !== 0)
            writer.tag(4, WireType.Varint).int32(message.clearspace);
        /* int32 min_size = 5 [json_name = "min_size"]; */
        if (message.min_size !== 0)
            writer.tag(5, WireType.Varint).int32(message.min_size);
        /* int32 clearspace_path_index = 6 [json_name = "clearspace_path_index"]; */
        if (message.clearspace_path_index !== 0)
            writer.tag(6, WireType.Varint).int32(message.clearspace_path_index);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.LogoObject
 */
export const LogoObject = new LogoObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogosDescriptions$Type extends MessageType<LogosDescriptions> {
    constructor() {
        super("brandguide.LogosDescriptions", [
            { no: 1, name: "uploads", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "clearspace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "proportions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "placement", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "misuse", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "usage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LogosDescriptions>): LogosDescriptions {
        const message = { uploads: "", clearspace: "", proportions: "", placement: "", misuse: "", usage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogosDescriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogosDescriptions): LogosDescriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uploads */ 1:
                    message.uploads = reader.string();
                    break;
                case /* string clearspace */ 2:
                    message.clearspace = reader.string();
                    break;
                case /* string proportions */ 3:
                    message.proportions = reader.string();
                    break;
                case /* string placement */ 4:
                    message.placement = reader.string();
                    break;
                case /* string misuse */ 5:
                    message.misuse = reader.string();
                    break;
                case /* string usage */ 6:
                    message.usage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogosDescriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uploads = 1; */
        if (message.uploads !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uploads);
        /* string clearspace = 2; */
        if (message.clearspace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.clearspace);
        /* string proportions = 3; */
        if (message.proportions !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.proportions);
        /* string placement = 4; */
        if (message.placement !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.placement);
        /* string misuse = 5; */
        if (message.misuse !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.misuse);
        /* string usage = 6; */
        if (message.usage !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.usage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.LogosDescriptions
 */
export const LogosDescriptions = new LogosDescriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoPlacement$Type extends MessageType<LogoPlacement> {
    constructor() {
        super("brandguide.LogoPlacement", [
            { no: 1, name: "left", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["brandguide.Placements", Placements] },
            { no: 2, name: "center", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["brandguide.Placements", Placements] },
            { no: 3, name: "right", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["brandguide.Placements", Placements] },
            { no: 4, name: "all", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["brandguide.Placements", Placements] }
        ]);
    }
    create(value?: PartialMessage<LogoPlacement>): LogoPlacement {
        const message = { left: [], center: [], right: [], all: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoPlacement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoPlacement): LogoPlacement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguide.Placements left */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.left.push(reader.int32());
                    else
                        message.left.push(reader.int32());
                    break;
                case /* repeated brandguide.Placements center */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.center.push(reader.int32());
                    else
                        message.center.push(reader.int32());
                    break;
                case /* repeated brandguide.Placements right */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.right.push(reader.int32());
                    else
                        message.right.push(reader.int32());
                    break;
                case /* repeated brandguide.Placements all */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.all.push(reader.int32());
                    else
                        message.all.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoPlacement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguide.Placements left = 1; */
        if (message.left.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.left.length; i++)
                writer.int32(message.left[i]);
            writer.join();
        }
        /* repeated brandguide.Placements center = 2; */
        if (message.center.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.center.length; i++)
                writer.int32(message.center[i]);
            writer.join();
        }
        /* repeated brandguide.Placements right = 3; */
        if (message.right.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.right.length; i++)
                writer.int32(message.right[i]);
            writer.join();
        }
        /* repeated brandguide.Placements all = 4; */
        if (message.all.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.all.length; i++)
                writer.int32(message.all[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.LogoPlacement
 */
export const LogoPlacement = new LogoPlacement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoMisuse$Type extends MessageType<LogoMisuse> {
    constructor() {
        super("brandguide.LogoMisuse", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "svg", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LogoMisuse>): LogoMisuse {
        const message = { title: "", svg: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoMisuse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoMisuse): LogoMisuse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string svg */ 2:
                    message.svg = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoMisuse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string svg = 2; */
        if (message.svg !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.svg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.LogoMisuse
 */
export const LogoMisuse = new LogoMisuse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoUsage$Type extends MessageType<LogoUsage> {
    constructor() {
        super("brandguide.LogoUsage", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "use", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "color", kind: "message", T: () => Color }
        ]);
    }
    create(value?: PartialMessage<LogoUsage>): LogoUsage {
        const message = { id: "", title: "", use: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoUsage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoUsage): LogoUsage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* bool use */ 3:
                    message.use = reader.bool();
                    break;
                case /* brandguide.Color color */ 4:
                    message.color = Color.internalBinaryRead(reader, reader.uint32(), options, message.color);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoUsage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* bool use = 3; */
        if (message.use !== false)
            writer.tag(3, WireType.Varint).bool(message.use);
        /* brandguide.Color color = 4; */
        if (message.color)
            Color.internalBinaryWrite(message.color, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.LogoUsage
 */
export const LogoUsage = new LogoUsage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideLogos$Type extends MessageType<GuideLogos> {
    constructor() {
        super("brandguide.GuideLogos", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "primary", kind: "message", T: () => LogoObject },
            { no: 3, name: "secondary", kind: "message", T: () => LogoObject },
            { no: 4, name: "logomark", kind: "message", T: () => LogoObject },
            { no: 5, name: "wordmark", kind: "message", T: () => LogoObject },
            { no: 6, name: "alternative", kind: "message", T: () => LogoObject },
            { no: 7, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "placement", kind: "message", T: () => LogoPlacement },
            { no: 9, name: "misuse", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LogoMisuse },
            { no: 10, name: "usage", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LogoUsage },
            { no: 11, name: "descriptions", kind: "message", T: () => LogosDescriptions }
        ]);
    }
    create(value?: PartialMessage<GuideLogos>): GuideLogos {
        const message = { id: "", enabled: false, misuse: [], usage: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideLogos>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideLogos): GuideLogos {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* brandguide.LogoObject primary */ 2:
                    message.primary = LogoObject.internalBinaryRead(reader, reader.uint32(), options, message.primary);
                    break;
                case /* brandguide.LogoObject secondary */ 3:
                    message.secondary = LogoObject.internalBinaryRead(reader, reader.uint32(), options, message.secondary);
                    break;
                case /* brandguide.LogoObject logomark */ 4:
                    message.logomark = LogoObject.internalBinaryRead(reader, reader.uint32(), options, message.logomark);
                    break;
                case /* brandguide.LogoObject wordmark */ 5:
                    message.wordmark = LogoObject.internalBinaryRead(reader, reader.uint32(), options, message.wordmark);
                    break;
                case /* brandguide.LogoObject alternative */ 6:
                    message.alternative = LogoObject.internalBinaryRead(reader, reader.uint32(), options, message.alternative);
                    break;
                case /* bool enabled */ 7:
                    message.enabled = reader.bool();
                    break;
                case /* brandguide.LogoPlacement placement */ 8:
                    message.placement = LogoPlacement.internalBinaryRead(reader, reader.uint32(), options, message.placement);
                    break;
                case /* repeated brandguide.LogoMisuse misuse */ 9:
                    message.misuse.push(LogoMisuse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguide.LogoUsage usage */ 10:
                    message.usage.push(LogoUsage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguide.LogosDescriptions descriptions */ 11:
                    message.descriptions = LogosDescriptions.internalBinaryRead(reader, reader.uint32(), options, message.descriptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuideLogos, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* brandguide.LogoObject primary = 2; */
        if (message.primary)
            LogoObject.internalBinaryWrite(message.primary, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.LogoObject secondary = 3; */
        if (message.secondary)
            LogoObject.internalBinaryWrite(message.secondary, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.LogoObject logomark = 4; */
        if (message.logomark)
            LogoObject.internalBinaryWrite(message.logomark, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.LogoObject wordmark = 5; */
        if (message.wordmark)
            LogoObject.internalBinaryWrite(message.wordmark, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.LogoObject alternative = 6; */
        if (message.alternative)
            LogoObject.internalBinaryWrite(message.alternative, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool enabled = 7; */
        if (message.enabled !== false)
            writer.tag(7, WireType.Varint).bool(message.enabled);
        /* brandguide.LogoPlacement placement = 8; */
        if (message.placement)
            LogoPlacement.internalBinaryWrite(message.placement, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.LogoMisuse misuse = 9; */
        for (let i = 0; i < message.misuse.length; i++)
            LogoMisuse.internalBinaryWrite(message.misuse[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.LogoUsage usage = 10; */
        for (let i = 0; i < message.usage.length; i++)
            LogoUsage.internalBinaryWrite(message.usage[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.LogosDescriptions descriptions = 11; */
        if (message.descriptions)
            LogosDescriptions.internalBinaryWrite(message.descriptions, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GuideLogos
 */
export const GuideLogos = new GuideLogos$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FontObject$Type extends MessageType<FontObject> {
    constructor() {
        super("brandguide.FontObject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "font", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "font_family", kind: "scalar", localName: "font_family", jsonName: "font_family", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "file_name", kind: "scalar", localName: "file_name", jsonName: "file_name", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "font_type", kind: "scalar", localName: "font_type", jsonName: "font_type", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FontObject>): FontObject {
        const message = { id: "", font: "", font_family: "", file_name: "", font_type: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FontObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FontObject): FontObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string font */ 2:
                    message.font = reader.string();
                    break;
                case /* string font_family = 3 [json_name = "font_family"];*/ 3:
                    message.font_family = reader.string();
                    break;
                case /* string file_name = 4 [json_name = "file_name"];*/ 4:
                    message.file_name = reader.string();
                    break;
                case /* string font_type = 5 [json_name = "font_type"];*/ 5:
                    message.font_type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FontObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string font = 2; */
        if (message.font !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.font);
        /* string font_family = 3 [json_name = "font_family"]; */
        if (message.font_family !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.font_family);
        /* string file_name = 4 [json_name = "file_name"]; */
        if (message.file_name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.file_name);
        /* string font_type = 5 [json_name = "font_type"]; */
        if (message.font_type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.font_type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.FontObject
 */
export const FontObject = new FontObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TypographyObject$Type extends MessageType<TypographyObject> {
    constructor() {
        super("brandguide.TypographyObject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "font_id", kind: "scalar", localName: "font_id", jsonName: "font_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "bold", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "italic", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "transform", kind: "enum", T: () => ["brandguide.TextTransform", TextTransform] }
        ]);
    }
    create(value?: PartialMessage<TypographyObject>): TypographyObject {
        const message = { id: "", font_id: "", title: "", size: 0, bold: false, italic: false, transform: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TypographyObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TypographyObject): TypographyObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string font_id = 2 [json_name = "font_id"];*/ 2:
                    message.font_id = reader.string();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* int32 size */ 4:
                    message.size = reader.int32();
                    break;
                case /* bool bold */ 5:
                    message.bold = reader.bool();
                    break;
                case /* bool italic */ 6:
                    message.italic = reader.bool();
                    break;
                case /* brandguide.TextTransform transform */ 7:
                    message.transform = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TypographyObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string font_id = 2 [json_name = "font_id"]; */
        if (message.font_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.font_id);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* int32 size = 4; */
        if (message.size !== 0)
            writer.tag(4, WireType.Varint).int32(message.size);
        /* bool bold = 5; */
        if (message.bold !== false)
            writer.tag(5, WireType.Varint).bool(message.bold);
        /* bool italic = 6; */
        if (message.italic !== false)
            writer.tag(6, WireType.Varint).bool(message.italic);
        /* brandguide.TextTransform transform = 7; */
        if (message.transform !== 0)
            writer.tag(7, WireType.Varint).int32(message.transform);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.TypographyObject
 */
export const TypographyObject = new TypographyObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TypographyDescriptions$Type extends MessageType<TypographyDescriptions> {
    constructor() {
        super("brandguide.TypographyDescriptions", [
            { no: 1, name: "uploads", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "usage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TypographyDescriptions>): TypographyDescriptions {
        const message = { uploads: "", usage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TypographyDescriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TypographyDescriptions): TypographyDescriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uploads */ 1:
                    message.uploads = reader.string();
                    break;
                case /* string usage */ 2:
                    message.usage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TypographyDescriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uploads = 1; */
        if (message.uploads !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uploads);
        /* string usage = 2; */
        if (message.usage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.usage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.TypographyDescriptions
 */
export const TypographyDescriptions = new TypographyDescriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideTypography$Type extends MessageType<GuideTypography> {
    constructor() {
        super("brandguide.GuideTypography", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TypographyObject },
            { no: 3, name: "fonts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FontObject },
            { no: 4, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "descriptions", kind: "message", T: () => TypographyDescriptions }
        ]);
    }
    create(value?: PartialMessage<GuideTypography>): GuideTypography {
        const message = { id: "", items: [], fonts: [], enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideTypography>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideTypography): GuideTypography {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated brandguide.TypographyObject items */ 2:
                    message.items.push(TypographyObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguide.FontObject fonts */ 3:
                    message.fonts.push(FontObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool enabled */ 4:
                    message.enabled = reader.bool();
                    break;
                case /* brandguide.TypographyDescriptions descriptions */ 5:
                    message.descriptions = TypographyDescriptions.internalBinaryRead(reader, reader.uint32(), options, message.descriptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuideTypography, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated brandguide.TypographyObject items = 2; */
        for (let i = 0; i < message.items.length; i++)
            TypographyObject.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.FontObject fonts = 3; */
        for (let i = 0; i < message.fonts.length; i++)
            FontObject.internalBinaryWrite(message.fonts[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool enabled = 4; */
        if (message.enabled !== false)
            writer.tag(4, WireType.Varint).bool(message.enabled);
        /* brandguide.TypographyDescriptions descriptions = 5; */
        if (message.descriptions)
            TypographyDescriptions.internalBinaryWrite(message.descriptions, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GuideTypography
 */
export const GuideTypography = new GuideTypography$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageryObject$Type extends MessageType<ImageryObject> {
    constructor() {
        super("brandguide.ImageryObject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "contentdigest", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "thumbnail_contentdigest", kind: "scalar", localName: "thumbnail_contentdigest", jsonName: "thumbnail_contentdigest", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageryObject>): ImageryObject {
        const message = { id: "", description: "", contentdigest: "", thumbnail_contentdigest: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageryObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageryObject): ImageryObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string contentdigest */ 4:
                    message.contentdigest = reader.string();
                    break;
                case /* string thumbnail_contentdigest = 5 [json_name = "thumbnail_contentdigest"];*/ 5:
                    message.thumbnail_contentdigest = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageryObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string contentdigest = 4; */
        if (message.contentdigest !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.contentdigest);
        /* string thumbnail_contentdigest = 5 [json_name = "thumbnail_contentdigest"]; */
        if (message.thumbnail_contentdigest !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.thumbnail_contentdigest);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ImageryObject
 */
export const ImageryObject = new ImageryObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageryFundamentalItem$Type extends MessageType<ImageryFundamentalItem> {
    constructor() {
        super("brandguide.ImageryFundamentalItem", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "checked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "is_default", kind: "scalar", localName: "is_default", jsonName: "is_default", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ImageryFundamentalItem>): ImageryFundamentalItem {
        const message = { name: "", checked: false, is_default: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageryFundamentalItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageryFundamentalItem): ImageryFundamentalItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* bool checked */ 2:
                    message.checked = reader.bool();
                    break;
                case /* bool is_default = 3 [json_name = "is_default"];*/ 3:
                    message.is_default = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageryFundamentalItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* bool checked = 2; */
        if (message.checked !== false)
            writer.tag(2, WireType.Varint).bool(message.checked);
        /* bool is_default = 3 [json_name = "is_default"]; */
        if (message.is_default !== false)
            writer.tag(3, WireType.Varint).bool(message.is_default);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ImageryFundamentalItem
 */
export const ImageryFundamentalItem = new ImageryFundamentalItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageryFundamentalObject$Type extends MessageType<ImageryFundamentalObject> {
    constructor() {
        super("brandguide.ImageryFundamentalObject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageryFundamentalItem }
        ]);
    }
    create(value?: PartialMessage<ImageryFundamentalObject>): ImageryFundamentalObject {
        const message = { id: "", name: "", items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageryFundamentalObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageryFundamentalObject): ImageryFundamentalObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated brandguide.ImageryFundamentalItem items */ 3:
                    message.items.push(ImageryFundamentalItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageryFundamentalObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated brandguide.ImageryFundamentalItem items = 3; */
        for (let i = 0; i < message.items.length; i++)
            ImageryFundamentalItem.internalBinaryWrite(message.items[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ImageryFundamentalObject
 */
export const ImageryFundamentalObject = new ImageryFundamentalObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImagerySection$Type extends MessageType<ImagerySection> {
    constructor() {
        super("brandguide.ImagerySection", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageryObject }
        ]);
    }
    create(value?: PartialMessage<ImagerySection>): ImagerySection {
        const message = { enabled: false, items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImagerySection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImagerySection): ImagerySection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguide.ImageryObject items */ 2:
                    message.items.push(ImageryObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImagerySection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* repeated brandguide.ImageryObject items = 2; */
        for (let i = 0; i < message.items.length; i++)
            ImageryObject.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ImagerySection
 */
export const ImagerySection = new ImagerySection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageryDescriptions$Type extends MessageType<ImageryDescriptions> {
    constructor() {
        super("brandguide.ImageryDescriptions", [
            { no: 1, name: "lifestyle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "product", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "illustration", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "misuse", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "fundamentals", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageryDescriptions>): ImageryDescriptions {
        const message = { lifestyle: "", product: "", illustration: "", misuse: "", fundamentals: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageryDescriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageryDescriptions): ImageryDescriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string lifestyle */ 1:
                    message.lifestyle = reader.string();
                    break;
                case /* string product */ 2:
                    message.product = reader.string();
                    break;
                case /* string illustration */ 3:
                    message.illustration = reader.string();
                    break;
                case /* string misuse */ 4:
                    message.misuse = reader.string();
                    break;
                case /* string fundamentals */ 5:
                    message.fundamentals = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageryDescriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string lifestyle = 1; */
        if (message.lifestyle !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.lifestyle);
        /* string product = 2; */
        if (message.product !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.product);
        /* string illustration = 3; */
        if (message.illustration !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.illustration);
        /* string misuse = 4; */
        if (message.misuse !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.misuse);
        /* string fundamentals = 5; */
        if (message.fundamentals !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fundamentals);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ImageryDescriptions
 */
export const ImageryDescriptions = new ImageryDescriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideImagery$Type extends MessageType<GuideImagery> {
    constructor() {
        super("brandguide.GuideImagery", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "lifestyle", kind: "message", T: () => ImagerySection },
            { no: 4, name: "product", kind: "message", T: () => ImagerySection },
            { no: 5, name: "illustration", kind: "message", T: () => ImagerySection },
            { no: 6, name: "descriptions", kind: "message", T: () => ImageryDescriptions },
            { no: 7, name: "misuse", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageryObject },
            { no: 8, name: "fundamentals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageryFundamentalObject }
        ]);
    }
    create(value?: PartialMessage<GuideImagery>): GuideImagery {
        const message = { id: "", enabled: false, misuse: [], fundamentals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideImagery>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideImagery): GuideImagery {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                case /* brandguide.ImagerySection lifestyle */ 3:
                    message.lifestyle = ImagerySection.internalBinaryRead(reader, reader.uint32(), options, message.lifestyle);
                    break;
                case /* brandguide.ImagerySection product */ 4:
                    message.product = ImagerySection.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* brandguide.ImagerySection illustration */ 5:
                    message.illustration = ImagerySection.internalBinaryRead(reader, reader.uint32(), options, message.illustration);
                    break;
                case /* brandguide.ImageryDescriptions descriptions */ 6:
                    message.descriptions = ImageryDescriptions.internalBinaryRead(reader, reader.uint32(), options, message.descriptions);
                    break;
                case /* repeated brandguide.ImageryObject misuse */ 7:
                    message.misuse.push(ImageryObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguide.ImageryFundamentalObject fundamentals */ 8:
                    message.fundamentals.push(ImageryFundamentalObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuideImagery, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        /* brandguide.ImagerySection lifestyle = 3; */
        if (message.lifestyle)
            ImagerySection.internalBinaryWrite(message.lifestyle, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.ImagerySection product = 4; */
        if (message.product)
            ImagerySection.internalBinaryWrite(message.product, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.ImagerySection illustration = 5; */
        if (message.illustration)
            ImagerySection.internalBinaryWrite(message.illustration, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.ImageryDescriptions descriptions = 6; */
        if (message.descriptions)
            ImageryDescriptions.internalBinaryWrite(message.descriptions, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.ImageryObject misuse = 7; */
        for (let i = 0; i < message.misuse.length; i++)
            ImageryObject.internalBinaryWrite(message.misuse[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.ImageryFundamentalObject fundamentals = 8; */
        for (let i = 0; i < message.fundamentals.length; i++)
            ImageryFundamentalObject.internalBinaryWrite(message.fundamentals[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GuideImagery
 */
export const GuideImagery = new GuideImagery$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToneOfVoiceAdjective$Type extends MessageType<ToneOfVoiceAdjective> {
    constructor() {
        super("brandguide.ToneOfVoiceAdjective", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ToneOfVoiceAdjective>): ToneOfVoiceAdjective {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ToneOfVoiceAdjective>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ToneOfVoiceAdjective): ToneOfVoiceAdjective {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ToneOfVoiceAdjective, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ToneOfVoiceAdjective
 */
export const ToneOfVoiceAdjective = new ToneOfVoiceAdjective$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToneOfVoiceObject$Type extends MessageType<ToneOfVoiceObject> {
    constructor() {
        super("brandguide.ToneOfVoiceObject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "selected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ToneOfVoiceObject>): ToneOfVoiceObject {
        const message = { id: "", title: "", text: "", description: "", selected: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ToneOfVoiceObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ToneOfVoiceObject): ToneOfVoiceObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string text */ 3:
                    message.text = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* bool selected */ 5:
                    message.selected = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ToneOfVoiceObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string text = 3; */
        if (message.text !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.text);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* bool selected = 5; */
        if (message.selected !== false)
            writer.tag(5, WireType.Varint).bool(message.selected);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ToneOfVoiceObject
 */
export const ToneOfVoiceObject = new ToneOfVoiceObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToneOfVoiceDescriptions$Type extends MessageType<ToneOfVoiceDescriptions> {
    constructor() {
        super("brandguide.ToneOfVoiceDescriptions", [
            { no: 1, name: "tone_of_voice", kind: "scalar", localName: "tone_of_voice", jsonName: "tone_of_voice", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "writing_style", kind: "scalar", localName: "writing_style", jsonName: "writing_style", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "adjectives", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ToneOfVoiceDescriptions>): ToneOfVoiceDescriptions {
        const message = { tone_of_voice: "", writing_style: "", adjectives: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ToneOfVoiceDescriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ToneOfVoiceDescriptions): ToneOfVoiceDescriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tone_of_voice = 1 [json_name = "tone_of_voice"];*/ 1:
                    message.tone_of_voice = reader.string();
                    break;
                case /* string writing_style = 2 [json_name = "writing_style"];*/ 2:
                    message.writing_style = reader.string();
                    break;
                case /* string adjectives */ 3:
                    message.adjectives = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ToneOfVoiceDescriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tone_of_voice = 1 [json_name = "tone_of_voice"]; */
        if (message.tone_of_voice !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tone_of_voice);
        /* string writing_style = 2 [json_name = "writing_style"]; */
        if (message.writing_style !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.writing_style);
        /* string adjectives = 3; */
        if (message.adjectives !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.adjectives);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ToneOfVoiceDescriptions
 */
export const ToneOfVoiceDescriptions = new ToneOfVoiceDescriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideToneOfVoice$Type extends MessageType<GuideToneOfVoice> {
    constructor() {
        super("brandguide.GuideToneOfVoice", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "tone_of_voice", kind: "message", localName: "tone_of_voice", jsonName: "tone_of_voice", repeat: 1 /*RepeatType.PACKED*/, T: () => ToneOfVoiceObject },
            { no: 4, name: "writing_style", kind: "message", localName: "writing_style", jsonName: "writing_style", repeat: 1 /*RepeatType.PACKED*/, T: () => ToneOfVoiceObject },
            { no: 5, name: "descriptions", kind: "message", T: () => ToneOfVoiceDescriptions },
            { no: 6, name: "positive_adjectives", kind: "message", localName: "positive_adjectives", jsonName: "positive_adjectives", repeat: 1 /*RepeatType.PACKED*/, T: () => ToneOfVoiceAdjective },
            { no: 7, name: "negative_adjectives", kind: "message", localName: "negative_adjectives", jsonName: "negative_adjectives", repeat: 1 /*RepeatType.PACKED*/, T: () => ToneOfVoiceAdjective }
        ]);
    }
    create(value?: PartialMessage<GuideToneOfVoice>): GuideToneOfVoice {
        const message = { id: "", enabled: false, tone_of_voice: [], writing_style: [], positive_adjectives: [], negative_adjectives: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideToneOfVoice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideToneOfVoice): GuideToneOfVoice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguide.ToneOfVoiceObject tone_of_voice = 3 [json_name = "tone_of_voice"];*/ 3:
                    message.tone_of_voice.push(ToneOfVoiceObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguide.ToneOfVoiceObject writing_style = 4 [json_name = "writing_style"];*/ 4:
                    message.writing_style.push(ToneOfVoiceObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguide.ToneOfVoiceDescriptions descriptions */ 5:
                    message.descriptions = ToneOfVoiceDescriptions.internalBinaryRead(reader, reader.uint32(), options, message.descriptions);
                    break;
                case /* repeated brandguide.ToneOfVoiceAdjective positive_adjectives = 6 [json_name = "positive_adjectives"];*/ 6:
                    message.positive_adjectives.push(ToneOfVoiceAdjective.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguide.ToneOfVoiceAdjective negative_adjectives = 7 [json_name = "negative_adjectives"];*/ 7:
                    message.negative_adjectives.push(ToneOfVoiceAdjective.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuideToneOfVoice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        /* repeated brandguide.ToneOfVoiceObject tone_of_voice = 3 [json_name = "tone_of_voice"]; */
        for (let i = 0; i < message.tone_of_voice.length; i++)
            ToneOfVoiceObject.internalBinaryWrite(message.tone_of_voice[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.ToneOfVoiceObject writing_style = 4 [json_name = "writing_style"]; */
        for (let i = 0; i < message.writing_style.length; i++)
            ToneOfVoiceObject.internalBinaryWrite(message.writing_style[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.ToneOfVoiceDescriptions descriptions = 5; */
        if (message.descriptions)
            ToneOfVoiceDescriptions.internalBinaryWrite(message.descriptions, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.ToneOfVoiceAdjective positive_adjectives = 6 [json_name = "positive_adjectives"]; */
        for (let i = 0; i < message.positive_adjectives.length; i++)
            ToneOfVoiceAdjective.internalBinaryWrite(message.positive_adjectives[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguide.ToneOfVoiceAdjective negative_adjectives = 7 [json_name = "negative_adjectives"]; */
        for (let i = 0; i < message.negative_adjectives.length; i++)
            ToneOfVoiceAdjective.internalBinaryWrite(message.negative_adjectives[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GuideToneOfVoice
 */
export const GuideToneOfVoice = new GuideToneOfVoice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplicationObject$Type extends MessageType<ApplicationObject> {
    constructor() {
        super("brandguide.ApplicationObject", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "contentdigest", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "thumbnail_contentdigest", kind: "scalar", localName: "thumbnail_contentdigest", jsonName: "thumbnail_contentdigest", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplicationObject>): ApplicationObject {
        const message = { id: "", contentdigest: "", thumbnail_contentdigest: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplicationObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplicationObject): ApplicationObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string contentdigest */ 3:
                    message.contentdigest = reader.string();
                    break;
                case /* string thumbnail_contentdigest = 4 [json_name = "thumbnail_contentdigest"];*/ 4:
                    message.thumbnail_contentdigest = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplicationObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string contentdigest = 3; */
        if (message.contentdigest !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contentdigest);
        /* string thumbnail_contentdigest = 4 [json_name = "thumbnail_contentdigest"]; */
        if (message.thumbnail_contentdigest !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.thumbnail_contentdigest);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ApplicationObject
 */
export const ApplicationObject = new ApplicationObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplicationDescriptions$Type extends MessageType<ApplicationDescriptions> {
    constructor() {
        super("brandguide.ApplicationDescriptions", [
            { no: 1, name: "upload", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplicationDescriptions>): ApplicationDescriptions {
        const message = { upload: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplicationDescriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplicationDescriptions): ApplicationDescriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string upload */ 1:
                    message.upload = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplicationDescriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string upload = 1; */
        if (message.upload !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.upload);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ApplicationDescriptions
 */
export const ApplicationDescriptions = new ApplicationDescriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideApplication$Type extends MessageType<GuideApplication> {
    constructor() {
        super("brandguide.GuideApplication", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApplicationObject },
            { no: 4, name: "descriptions", kind: "message", T: () => ApplicationDescriptions }
        ]);
    }
    create(value?: PartialMessage<GuideApplication>): GuideApplication {
        const message = { id: "", enabled: false, items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideApplication>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideApplication): GuideApplication {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguide.ApplicationObject items */ 3:
                    message.items.push(ApplicationObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguide.ApplicationDescriptions descriptions */ 4:
                    message.descriptions = ApplicationDescriptions.internalBinaryRead(reader, reader.uint32(), options, message.descriptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuideApplication, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        /* repeated brandguide.ApplicationObject items = 3; */
        for (let i = 0; i < message.items.length; i++)
            ApplicationObject.internalBinaryWrite(message.items[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.ApplicationDescriptions descriptions = 4; */
        if (message.descriptions)
            ApplicationDescriptions.internalBinaryWrite(message.descriptions, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GuideApplication
 */
export const GuideApplication = new GuideApplication$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ArchetypeAdjectiveObject$Type extends MessageType<ArchetypeAdjectiveObject> {
    constructor() {
        super("brandguide.ArchetypeAdjectiveObject", [
            { no: 1, name: "archetype", kind: "enum", T: () => ["brandguide.Archetype", Archetype] },
            { no: 2, name: "items", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "percent", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ArchetypeAdjectiveObject>): ArchetypeAdjectiveObject {
        const message = { archetype: 0, items: [], percent: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ArchetypeAdjectiveObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ArchetypeAdjectiveObject): ArchetypeAdjectiveObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguide.Archetype archetype */ 1:
                    message.archetype = reader.int32();
                    break;
                case /* repeated string items */ 2:
                    message.items.push(reader.string());
                    break;
                case /* int32 percent */ 3:
                    message.percent = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ArchetypeAdjectiveObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguide.Archetype archetype = 1; */
        if (message.archetype !== 0)
            writer.tag(1, WireType.Varint).int32(message.archetype);
        /* repeated string items = 2; */
        for (let i = 0; i < message.items.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.items[i]);
        /* int32 percent = 3; */
        if (message.percent !== 0)
            writer.tag(3, WireType.Varint).int32(message.percent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ArchetypeAdjectiveObject
 */
export const ArchetypeAdjectiveObject = new ArchetypeAdjectiveObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ArchetypeDescriptions$Type extends MessageType<ArchetypeDescriptions> {
    constructor() {
        super("brandguide.ArchetypeDescriptions", [
            { no: 1, name: "selection", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "breakdown", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ArchetypeDescriptions>): ArchetypeDescriptions {
        const message = { selection: "", breakdown: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ArchetypeDescriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ArchetypeDescriptions): ArchetypeDescriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string selection */ 1:
                    message.selection = reader.string();
                    break;
                case /* string breakdown */ 2:
                    message.breakdown = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ArchetypeDescriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string selection = 1; */
        if (message.selection !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.selection);
        /* string breakdown = 2; */
        if (message.breakdown !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.breakdown);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ArchetypeDescriptions
 */
export const ArchetypeDescriptions = new ArchetypeDescriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideArchetype$Type extends MessageType<GuideArchetype> {
    constructor() {
        super("brandguide.GuideArchetype", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "adjectives", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ArchetypeAdjectiveObject },
            { no: 6, name: "descriptions", kind: "message", T: () => ArchetypeDescriptions }
        ]);
    }
    create(value?: PartialMessage<GuideArchetype>): GuideArchetype {
        const message = { id: "", enabled: false, adjectives: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideArchetype>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideArchetype): GuideArchetype {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguide.ArchetypeAdjectiveObject adjectives */ 5:
                    message.adjectives.push(ArchetypeAdjectiveObject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguide.ArchetypeDescriptions descriptions */ 6:
                    message.descriptions = ArchetypeDescriptions.internalBinaryRead(reader, reader.uint32(), options, message.descriptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuideArchetype, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        /* repeated brandguide.ArchetypeAdjectiveObject adjectives = 5; */
        for (let i = 0; i < message.adjectives.length; i++)
            ArchetypeAdjectiveObject.internalBinaryWrite(message.adjectives[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.ArchetypeDescriptions descriptions = 6; */
        if (message.descriptions)
            ArchetypeDescriptions.internalBinaryWrite(message.descriptions, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GuideArchetype
 */
export const GuideArchetype = new GuideArchetype$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ComplianceName$Type extends MessageType<ComplianceName> {
    constructor() {
        super("brandguide.ComplianceName", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ComplianceName>): ComplianceName {
        const message = { id: "", name: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ComplianceName>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ComplianceName): ComplianceName {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ComplianceName, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ComplianceName
 */
export const ComplianceName = new ComplianceName$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ComplianceItem$Type extends MessageType<ComplianceItem> {
    constructor() {
        super("brandguide.ComplianceItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "info", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "names", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ComplianceName }
        ]);
    }
    create(value?: PartialMessage<ComplianceItem>): ComplianceItem {
        const message = { id: "", title: "", info: "", names: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ComplianceItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ComplianceItem): ComplianceItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string info */ 3:
                    message.info = reader.string();
                    break;
                case /* repeated brandguide.ComplianceName names */ 4:
                    message.names.push(ComplianceName.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ComplianceItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string info = 3; */
        if (message.info !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.info);
        /* repeated brandguide.ComplianceName names = 4; */
        for (let i = 0; i < message.names.length; i++)
            ComplianceName.internalBinaryWrite(message.names[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ComplianceItem
 */
export const ComplianceItem = new ComplianceItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ComplianceDescriptions$Type extends MessageType<ComplianceDescriptions> {
    constructor() {
        super("brandguide.ComplianceDescriptions", [
            { no: 1, name: "selection", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ComplianceDescriptions>): ComplianceDescriptions {
        const message = { selection: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ComplianceDescriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ComplianceDescriptions): ComplianceDescriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string selection */ 1:
                    message.selection = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ComplianceDescriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string selection = 1; */
        if (message.selection !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.selection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.ComplianceDescriptions
 */
export const ComplianceDescriptions = new ComplianceDescriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuideCompliance$Type extends MessageType<GuideCompliance> {
    constructor() {
        super("brandguide.GuideCompliance", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ComplianceItem },
            { no: 4, name: "descriptions", kind: "message", T: () => ComplianceDescriptions }
        ]);
    }
    create(value?: PartialMessage<GuideCompliance>): GuideCompliance {
        const message = { id: "", enabled: false, items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuideCompliance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuideCompliance): GuideCompliance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                case /* repeated brandguide.ComplianceItem items */ 3:
                    message.items.push(ComplianceItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguide.ComplianceDescriptions descriptions */ 4:
                    message.descriptions = ComplianceDescriptions.internalBinaryRead(reader, reader.uint32(), options, message.descriptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuideCompliance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        /* repeated brandguide.ComplianceItem items = 3; */
        for (let i = 0; i < message.items.length; i++)
            ComplianceItem.internalBinaryWrite(message.items[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.ComplianceDescriptions descriptions = 4; */
        if (message.descriptions)
            ComplianceDescriptions.internalBinaryWrite(message.descriptions, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.GuideCompliance
 */
export const GuideCompliance = new GuideCompliance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Guide$Type extends MessageType<Guide> {
    constructor() {
        super("brandguide.Guide", [
            { no: 1, name: "colors", kind: "message", T: () => GuideColors },
            { no: 2, name: "logos", kind: "message", T: () => GuideLogos },
            { no: 3, name: "typography", kind: "message", T: () => GuideTypography },
            { no: 4, name: "imagery", kind: "message", T: () => GuideImagery },
            { no: 5, name: "tone_of_voice", kind: "message", localName: "tone_of_voice", jsonName: "tone_of_voice", T: () => GuideToneOfVoice },
            { no: 6, name: "application", kind: "message", T: () => GuideApplication },
            { no: 7, name: "archetype", kind: "message", T: () => GuideArchetype },
            { no: 8, name: "compliance", kind: "message", T: () => GuideCompliance }
        ]);
    }
    create(value?: PartialMessage<Guide>): Guide {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Guide>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Guide): Guide {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguide.GuideColors colors */ 1:
                    message.colors = GuideColors.internalBinaryRead(reader, reader.uint32(), options, message.colors);
                    break;
                case /* brandguide.GuideLogos logos */ 2:
                    message.logos = GuideLogos.internalBinaryRead(reader, reader.uint32(), options, message.logos);
                    break;
                case /* brandguide.GuideTypography typography */ 3:
                    message.typography = GuideTypography.internalBinaryRead(reader, reader.uint32(), options, message.typography);
                    break;
                case /* brandguide.GuideImagery imagery */ 4:
                    message.imagery = GuideImagery.internalBinaryRead(reader, reader.uint32(), options, message.imagery);
                    break;
                case /* brandguide.GuideToneOfVoice tone_of_voice = 5 [json_name = "tone_of_voice"];*/ 5:
                    message.tone_of_voice = GuideToneOfVoice.internalBinaryRead(reader, reader.uint32(), options, message.tone_of_voice);
                    break;
                case /* brandguide.GuideApplication application */ 6:
                    message.application = GuideApplication.internalBinaryRead(reader, reader.uint32(), options, message.application);
                    break;
                case /* brandguide.GuideArchetype archetype */ 7:
                    message.archetype = GuideArchetype.internalBinaryRead(reader, reader.uint32(), options, message.archetype);
                    break;
                case /* brandguide.GuideCompliance compliance */ 8:
                    message.compliance = GuideCompliance.internalBinaryRead(reader, reader.uint32(), options, message.compliance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Guide, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguide.GuideColors colors = 1; */
        if (message.colors)
            GuideColors.internalBinaryWrite(message.colors, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.GuideLogos logos = 2; */
        if (message.logos)
            GuideLogos.internalBinaryWrite(message.logos, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.GuideTypography typography = 3; */
        if (message.typography)
            GuideTypography.internalBinaryWrite(message.typography, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.GuideImagery imagery = 4; */
        if (message.imagery)
            GuideImagery.internalBinaryWrite(message.imagery, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.GuideToneOfVoice tone_of_voice = 5 [json_name = "tone_of_voice"]; */
        if (message.tone_of_voice)
            GuideToneOfVoice.internalBinaryWrite(message.tone_of_voice, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.GuideApplication application = 6; */
        if (message.application)
            GuideApplication.internalBinaryWrite(message.application, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.GuideArchetype archetype = 7; */
        if (message.archetype)
            GuideArchetype.internalBinaryWrite(message.archetype, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* brandguide.GuideCompliance compliance = 8; */
        if (message.compliance)
            GuideCompliance.internalBinaryWrite(message.compliance, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.Guide
 */
export const Guide = new Guide$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BrandguideObject$Type extends MessageType<BrandguideObject> {
    constructor() {
        super("brandguide.BrandguideObject", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "profile_id", kind: "scalar", localName: "profile_id", jsonName: "profile_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "guide", kind: "message", T: () => Guide },
            { no: 8, name: "pdf_generated", kind: "scalar", localName: "pdf_generated", jsonName: "pdf_generated", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BrandguideObject>): BrandguideObject {
        const message = { version: "", account_id: "", profile_id: "", brand_id: "", created_at: "", updated_at: "", pdf_generated: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BrandguideObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BrandguideObject): BrandguideObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string version */ 1:
                    message.version = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string profile_id = 3 [json_name = "profile_id"];*/ 3:
                    message.profile_id = reader.string();
                    break;
                case /* string brand_id = 4 [json_name = "brand_id"];*/ 4:
                    message.brand_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 6 [json_name = "updated_at"];*/ 6:
                    message.updated_at = reader.string();
                    break;
                case /* brandguide.Guide guide */ 7:
                    message.guide = Guide.internalBinaryRead(reader, reader.uint32(), options, message.guide);
                    break;
                case /* bool pdf_generated = 8 [json_name = "pdf_generated"];*/ 8:
                    message.pdf_generated = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BrandguideObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string version = 1; */
        if (message.version !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.version);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string profile_id = 3 [json_name = "profile_id"]; */
        if (message.profile_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.profile_id);
        /* string brand_id = 4 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brand_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 6 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.updated_at);
        /* brandguide.Guide guide = 7; */
        if (message.guide)
            Guide.internalBinaryWrite(message.guide, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool pdf_generated = 8 [json_name = "pdf_generated"]; */
        if (message.pdf_generated !== false)
            writer.tag(8, WireType.Varint).bool(message.pdf_generated);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.BrandguideObject
 */
export const BrandguideObject = new BrandguideObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BrandguideFindRequest$Type extends MessageType<BrandguideFindRequest> {
    constructor() {
        super("brandguide.BrandguideFindRequest", []);
    }
    create(value?: PartialMessage<BrandguideFindRequest>): BrandguideFindRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BrandguideFindRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BrandguideFindRequest): BrandguideFindRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BrandguideFindRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.BrandguideFindRequest
 */
export const BrandguideFindRequest = new BrandguideFindRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BrandguideFindResponse$Type extends MessageType<BrandguideFindResponse> {
    constructor() {
        super("brandguide.BrandguideFindResponse", [
            { no: 1, name: "brandguide_object", kind: "message", localName: "brandguide_object", jsonName: "brandguide", T: () => BrandguideObject }
        ]);
    }
    create(value?: PartialMessage<BrandguideFindResponse>): BrandguideFindResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BrandguideFindResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BrandguideFindResponse): BrandguideFindResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguide.BrandguideObject brandguide_object = 1 [json_name = "brandguide"];*/ 1:
                    message.brandguide_object = BrandguideObject.internalBinaryRead(reader, reader.uint32(), options, message.brandguide_object);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BrandguideFindResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguide.BrandguideObject brandguide_object = 1 [json_name = "brandguide"]; */
        if (message.brandguide_object)
            BrandguideObject.internalBinaryWrite(message.brandguide_object, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.BrandguideFindResponse
 */
export const BrandguideFindResponse = new BrandguideFindResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BrandguideCreateRequest$Type extends MessageType<BrandguideCreateRequest> {
    constructor() {
        super("brandguide.BrandguideCreateRequest", [
            { no: 1, name: "guide", kind: "message", T: () => Guide }
        ]);
    }
    create(value?: PartialMessage<BrandguideCreateRequest>): BrandguideCreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BrandguideCreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BrandguideCreateRequest): BrandguideCreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguide.Guide guide */ 1:
                    message.guide = Guide.internalBinaryRead(reader, reader.uint32(), options, message.guide);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BrandguideCreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguide.Guide guide = 1; */
        if (message.guide)
            Guide.internalBinaryWrite(message.guide, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.BrandguideCreateRequest
 */
export const BrandguideCreateRequest = new BrandguideCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BrandguideCreateResponse$Type extends MessageType<BrandguideCreateResponse> {
    constructor() {
        super("brandguide.BrandguideCreateResponse", [
            { no: 1, name: "brandguide_object", kind: "message", localName: "brandguide_object", jsonName: "brandguide", T: () => BrandguideObject }
        ]);
    }
    create(value?: PartialMessage<BrandguideCreateResponse>): BrandguideCreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BrandguideCreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BrandguideCreateResponse): BrandguideCreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguide.BrandguideObject brandguide_object = 1 [json_name = "brandguide"];*/ 1:
                    message.brandguide_object = BrandguideObject.internalBinaryRead(reader, reader.uint32(), options, message.brandguide_object);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BrandguideCreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguide.BrandguideObject brandguide_object = 1 [json_name = "brandguide"]; */
        if (message.brandguide_object)
            BrandguideObject.internalBinaryWrite(message.brandguide_object, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.BrandguideCreateResponse
 */
export const BrandguideCreateResponse = new BrandguideCreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BrandguideSearchRequest$Type extends MessageType<BrandguideSearchRequest> {
    constructor() {
        super("brandguide.BrandguideSearchRequest", [
            { no: 1, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BrandguideSearchRequest>): BrandguideSearchRequest {
        const message = { limit: 0, offset: 0, brand_id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BrandguideSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BrandguideSearchRequest): BrandguideSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 limit */ 1:
                    message.limit = reader.int32();
                    break;
                case /* int32 offset */ 2:
                    message.offset = reader.int32();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BrandguideSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 limit = 1; */
        if (message.limit !== 0)
            writer.tag(1, WireType.Varint).int32(message.limit);
        /* int32 offset = 2; */
        if (message.offset !== 0)
            writer.tag(2, WireType.Varint).int32(message.offset);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.BrandguideSearchRequest
 */
export const BrandguideSearchRequest = new BrandguideSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BrandguideSearchResponse$Type extends MessageType<BrandguideSearchResponse> {
    constructor() {
        super("brandguide.BrandguideSearchResponse", [
            { no: 1, name: "next", kind: "message", T: () => BrandguideSearchRequest },
            { no: 2, name: "versions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BrandguideSearchResponse>): BrandguideSearchResponse {
        const message = { versions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BrandguideSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BrandguideSearchResponse): BrandguideSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandguide.BrandguideSearchRequest next */ 1:
                    message.next = BrandguideSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                case /* repeated string versions */ 2:
                    message.versions.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BrandguideSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandguide.BrandguideSearchRequest next = 1; */
        if (message.next)
            BrandguideSearchRequest.internalBinaryWrite(message.next, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string versions = 2; */
        for (let i = 0; i < message.versions.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.versions[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguide.BrandguideSearchResponse
 */
export const BrandguideSearchResponse = new BrandguideSearchResponse$Type();

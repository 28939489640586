import * as layouts from "layouts"
import { PropsWithChildren } from "react"
import { colors } from "layouts/theme"

export function MainContainer(props: PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return <layouts.Flex {...rest}>{children}</layouts.Flex>
}

MainContainer.defaultProps = {
	flex: "3",
	alignItems: "center",
	justifyContent: "center",
	background: colors.white,
}

export function ContentContainer(props: PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return <layouts.Flex {...rest}>{children}</layouts.Flex>
}

ContentContainer.defaultProps = {
	flex: "1",
	flexDirection: "column",
	py: "2%",
	pl: "2%",
	height: "92%",
	width: "100%",
}

import * as api from "./api"
import * as layouts from "layouts"

export function processing(p: api.Prediction = api.Prediction.NONE): boolean {
	return inverse(p) === p
}

export function inverse(p: api.Prediction = api.Prediction.NONE): api.Prediction {
	if (p !== api.Prediction.APPROVED && p !== api.Prediction.REJECTED && p !== api.Prediction.ERROR) {
		return p
	}

	return p === api.Prediction.APPROVED ? api.Prediction.REJECTED : api.Prediction.APPROVED
}

export function color(p: api.Prediction = api.Prediction.NONE): string {
	if ([api.Prediction.REJECTED, api.Prediction.REJECTED_BY_REVIEW, api.Prediction.DISCARDED_BY_UPLOADER].includes(p))
		return layouts.theme.colors.red.cinnabar
	if ([api.Prediction.APPROVED, api.Prediction.APPROVED_BY_SYSTEM, api.Prediction.APPROVED_BY_REVIEW].includes(p))
		return layouts.theme.colors.blue.blue
	if (p === api.Prediction.INREVIEW) return layouts.theme.colors.grey.dark50

	return layouts.theme.colors.grey.dark10
}

export function color_review(p: api.Prediction = api.Prediction.NONE): string {
	if (p === api.Prediction.REJECTED) return layouts.theme.colors.red.sun
	if (p === api.Prediction.APPROVED) return layouts.theme.colors.blue.bluedarkness
	if (p === api.Prediction.INREVIEW) return layouts.theme.colors.grey.dark50

	return layouts.theme.colors.grey.dark10
}

export function string(p: api.Prediction = api.Prediction.NONE): JSX.Element {
	if (p === api.Prediction.APPROVED) return <>approved</>
	if (p === api.Prediction.REJECTED) return <>rejected</>
	if (p === api.Prediction.INREVIEW) return <>awaiting review</>
	if (p === api.Prediction.ERROR) return <>error</>

	if (p === api.Prediction.APPROVED_BY_SYSTEM) return <>Approved by Brand Reviewer</>
	if (p === api.Prediction.APPROVED_BY_REVIEW) return <>Approved by Reviewer</>
	if (p === api.Prediction.REJECTED_BY_REVIEW) return <>Rejected by Reviewer</>
	if (p === api.Prediction.DISCARDED_BY_UPLOADER) return <>Rejected by Creator</>
	return <>{"processing"}</>
}

export function string_upload(p: api.Prediction): JSX.Element {
	if (!processing(p)) return <>{string(p)}</>
	if (p === api.Prediction.APPROVED) return <>sent to approved assets</>
	if (p === api.Prediction.REJECTED) return <>sent to rejected assets</>
	if (p === api.Prediction.INREVIEW) return <>sent to review assets</>
	return <>{"processing"}</>
}

export function string_changed(p: api.Prediction): JSX.Element {
	if (p === api.Prediction.APPROVED) return <>sent to approved assets</>
	if (p === api.Prediction.REJECTED) return <>sent to rejected assets</>
	if (p === api.Prediction.INREVIEW) return <>sent to review assets</>
	return <>{"processing"}</>
}

export function reason(p: api.Prediction, o: api.Prediction, confidence: number, threshold: number): string {
	if (o === api.Prediction.INREVIEW && p === api.Prediction.APPROVED && confidence < threshold) return "below threshold"
	if (o === api.Prediction.INREVIEW && p === api.Prediction.REJECTED) return "user disagreed"
	return ""
}

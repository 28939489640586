import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as metasearch from "metasearch"
import * as uuid from "uuid"
import * as brands from "brands"
import * as api from "./api"
import * as httpx from "httpx"
import * as errors from "errors"
import * as timex from "timex"
import { card as Card } from "./metric.card"
import { SearchRequest, SearchResponse } from "./brandguard.metrics_pb"
import * as chart from "./chart"
import * as icons from "icons"
import * as inputs from "inputs"
import * as metricsLayouts from "./layouts"

export interface parsed {
	onbrand: chart.metric
	offbrand: chart.metric
}

export function parsedInitial(proto: Partial<parsed> = {}): parsed {
	return {
		onbrand: chart.parsedMetricZero({
			title: "Onbrand",
			chart: chart.parsedChartZero({
				axisID: "y",
				borderColor: layouts.theme.colors.charts.blue.base,
				backgroundColor: layouts.theme.colors.charts.blue.alpha,
			}),
		}),
		offbrand: chart.parsedMetricZero({
			title: "Offbrand",
			chart: chart.parsedChartZero({
				axisID: "y",
				borderColor: layouts.theme.colors.charts.green.base,
				backgroundColor: layouts.theme.colors.charts.green.alpha,
			}),
		}),
		...proto,
	}
}

export const getDaysArray = function (startDate: Date, endDate: Date) {
	for (var result = [], d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
		result.push(new Date(d))
	}
	return result
}

export function parse(r: SearchResponse, dr: metasearch.DateRange): parsed {
	const data = parsedInitial()

	getDaysArray(new Date(dr.oldest), new Date(dr.newest)).forEach((i) => {
		const day = i.toString().slice(0, 10)
		data.onbrand.chart.labels.push(day)
		Object.assign(data.onbrand.chart.data, { [day]: 0 })
		data.offbrand.chart.labels.push(day)
		Object.assign(data.offbrand.chart.data, { [day]: 0 })
	})
	;(r.items || []).forEach((e) => {
		const day = new Date(e.range!.oldest).toString().slice(0, 10)
		data.onbrand.val += Number(BigInt.asIntN(32, BigInt(e.approved || 0)))
		data.onbrand.chart.data[day] += Number(BigInt.asIntN(32, BigInt(e.approved || 0)))

		data.offbrand.val += Number(BigInt.asIntN(32, BigInt(e.rejected || 0)))
		data.offbrand.chart.data[day] += Number(BigInt.asIntN(32, BigInt(e.rejected || 0)))
	})

	return data
}

export default function Display(props: unknown): JSX.Element {
	const brand = brands.caching.useCached()
	const bearertoken = sessions.useToken()

	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [req, setReq] = useState(
		api.SearchZero({
			brand_id: brand.id,
			range: metasearch.dateranges.days(30),
		}) as SearchRequest,
	)

	const [results, setResults] = useState(parsedInitial({}))
	const infiso = timex.infinity().toISO()
	const neginfiso = timex.negInfinity().toISO()

	useEffect(() => {
		if (req.brand_id === uuid.NIL || req.range?.oldest === neginfiso || req.range?.newest === infiso) return
		setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.search_training(req, bearertoken))
			.then((resp) => {
				setResults(parse(resp, req.range!))
				setCause(undefined)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch(
				httpx.errors.forbidden((cause) => {
					console.warn("insufficient priviledges unable to view metrics", cause)
					setCause(
						<layouts.containers.box styled m="auto">
							<errors.Textual>you do not have permission to view metrics</errors.Textual>
						</layouts.containers.box>,
					)
				}),
			)
			.catch((c: unknown) => {
				console.error("unable to retrieve metrics", c)
				setCause(
					<layouts.containers.box styled m="auto" width="100%" height="100%">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve metrics</errors.Textual>
					</layouts.containers.box>,
				)
			})
			.finally(() => setLoading(false))
		return p.cancel
	}, [req])

	return (
		<layouts.loading.screen loading={loading} height="100%">
			<errors.overlay cause={cause} flexDirection="column" flex="1">
				<layouts.containers.flex>
					<inputs.calendars.DateRangeOneDatepicker
						className={metricsLayouts.StyledDatePicker}
						placeholderText="Date Range"
						minStartDate={new Date(timex.from.iso(req.range?.oldest).minus(timex.duration.days(180)).toISO())}
						maxEndDate={new Date()}
						startDate={new Date(req.range?.oldest || neginfiso)}
						endDate={new Date(req.range?.newest || infiso)}
						changeStart={(d: Date) => {
							setReq((prevState) => ({ ...prevState, range: { newest: infiso, oldest: timex.from.date(d).toISO() } }))
						}}
						changeEnd={(d: Date) => {
							setReq((prevState) => ({
								...prevState,
								range: { ...prevState.range, newest: timex.from.date(d).toISO() },
							}))
						}}
					/>

					<layouts.containers.flex mt="3px" ml="10px" fontWeight="600">
						{req.range?.oldest !== neginfiso &&
							req.range?.newest !== infiso &&
							`${timex.from
								.iso(req.range?.newest)
								.diff(timex.from.iso(req.range?.oldest), "days")
								.days.toFixed()} day(s)`}
					</layouts.containers.flex>
				</layouts.containers.flex>

				<layouts.containers.box
					className="metrics"
					width="100%"
					background={layouts.theme.colors.white}
					overflowY="auto"
				>
					<chart.Chart labels={results.onbrand.chart.labels} dataset={[results.onbrand, results.offbrand]} />
					<layouts.containers.flex justifyContent="center">
						<Card
							icon={
								<icons.ImpressionsEye
									fill={results.onbrand.chart.borderColor}
									width="30px"
									height="30px"
									verticalAlign="middle"
								/>
							}
							data={results.onbrand}
						/>
						<Card
							icon={
								<icons.ImpressionsEye
									fill={results.offbrand.chart.borderColor}
									width="30px"
									height="30px"
									verticalAlign="middle"
								/>
							}
							data={results.offbrand}
						/>
					</layouts.containers.flex>
				</layouts.containers.box>
			</errors.overlay>
		</layouts.loading.screen>
	)
}

import * as httpx from "httpx"
import { CancellablePromise } from "real-cancellable-promise"
import * as text from "."
import {
	TextSearchRequest,
	TextSearchResponse,
	TextFindResponse,
	TextPatchRequest,
	TextPatchResponse,
} from "brandguard/brandguard.text_pb"

export namespace requests {
	export function search(d: Partial<TextSearchRequest> = {}): TextSearchRequest {
		return {
			offset: 0n,
			queues: [],
			deprecated_uploaded_by: "",
			uploaded_by: [],
			brand_id: "",
			limit: 10n,
			observation: [],
			approved: [],
			...d,
		}
	}
}
export function search(
	bid: string,
	req: TextSearchRequest,
	...options: httpx.option[]
): CancellablePromise<TextSearchResponse> {
	return httpx
		.get<TextSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/approvallog`, req, ...options)
		.then((resp) => ({
			...resp,
			items: (resp.items || []).map((item) => ({ media: item.media, event: text.EventZero(item.event) })),
		}))
}

export function find(id: string, bid: string, ...options: httpx.option[]): CancellablePromise<TextFindResponse> {
	return httpx
		.get<TextFindResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/approvallog/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function patch(
	id: string,
	bid: string,
	req: TextPatchRequest,
	...options: httpx.option[]
): Promise<TextPatchResponse> {
	return httpx.patch(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/approvallog/${id}`, req, ...options)
}

export function destroy(bid: string, id: string, ...options: httpx.option[]): Promise<TextPatchResponse> {
	return httpx
		.destroy<TextPatchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/approvallog/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

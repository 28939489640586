import * as timex from "timex"

export function int(fallback: number, ...keys: string[]): number {
	for (const k of keys) {
		const v = process.env[k]
		if (v === undefined) continue
		try {
			return parseInt(v, 10)
		} catch (e) {
			console.error("unable to parse", k, e)
		}
	}

	return fallback
}

// extract a duration from a set of environment variables.
// @see https://en.wikipedia.org/wiki/ISO_8601#Durations
export function duration(fallback: timex.Duration, ...keys: string[]): timex.Duration {
	for (const k of keys) {
		const v = process.env[k]
		if (v === undefined) continue
		try {
			return timex.duration.iso(v)
		} catch (e) {
			console.error("unable to parse", k, e)
		}
	}

	return fallback
}

import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"

export function Modal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Application Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>
							Adding current marketing materials to a brand guide is essential for providing reference and training, and
							inspiring creativity.
						</b>{" "}
						It reinforces brand identity and ensures that all brand communications are cohesive and impactful.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Reference for Stakeholders:</b> Having current marketing materials in the
						brand guide serves as a reference for internal and external stakeholders, including employees, partners, and
						vendors. It provides clear guidelines for creating new materials that are consistent with existing brand
						assets.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Training Tool:</b> The inclusion of current ads and marketing materials can
						serve as a training tool for new employees or team members. By studying existing materials, they can better
						understand the brand&apos;s style, voice, and messaging principles.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Inspiration:</b> Current ads and marketing materials can inspire creativity
						and innovation for future campaigns and projects. By showcasing successful examples, the brand guide
						encourages the exploration of new ideas while staying true to the brand&apos;s identity.distinct visual
						identity that stands out in a crowded marketplace and leaves a lasting impression on consumers.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Modal.defaultProps = {
	width: "815px",
	height: "425px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

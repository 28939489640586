import { theme } from "layouts"
import { SVG, SVGProps } from "./svg"

interface props extends SVGProps {
	disabled?: boolean
	fill?: string
}

const Icon = (props: props) => {
	const { fill, ...rest } = props

	return (
		<SVG width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M9.83046 9.93825H7.72727C4.73991 9.93825 2.31818 12.3341 2.31818 15.2896C2.31818 18.2451 4.73991 20.641 7.72727 20.641L12.3636 20.641L12.3636 22.9346H7.72727C3.45962 22.9346 0 19.5117 0 15.2896C0 11.0675 3.45962 7.6448 7.72727 7.6448H7.99608C9.0426 3.26173 13.0226 0 17.7727 0C23.0741 0 27.4164 4.06281 27.7918 9.21293C31.2817 9.58133 34 12.5037 34 16.0541C34 19.854 30.8863 22.9344 27.0455 22.9344H26.315L26.2727 22.9346H21.6364V20.6411L26.2727 20.6411L26.315 20.641L27.0455 20.641C29.606 20.641 31.6818 18.5874 31.6818 16.0541C31.6818 13.6886 29.87 11.7388 27.5459 11.4934L25.6191 11.29L25.4797 9.37793C25.191 5.41858 21.8497 2.29344 17.7727 2.29344C14.1218 2.29344 11.0572 4.80055 10.2522 8.17205L9.83046 9.93825ZM12.4105 14.9465L15.8409 11.5527L15.8409 24.8533C15.8409 25.4866 16.3598 26 17 26C17.6401 26 18.1591 25.4866 18.1591 24.8533L18.1591 11.5527L21.5895 14.9465C22.0421 15.3943 22.776 15.3943 23.2287 14.9465C23.6813 14.4987 23.6813 13.7726 23.2287 13.3248L17.8196 7.97342C17.3669 7.5256 16.6331 7.5256 16.1804 7.97342L10.7713 13.3248C10.3187 13.7726 10.3187 14.4987 10.7713 14.9465C11.224 15.3943 11.9579 15.3943 12.4105 14.9465Z"
				fill={fill}
			/>
		</SVG>
	)
}

Icon.defaultProps = {
	fill: theme.colors.grey.dark10,
}

export default Icon

import * as httpx from "httpx"
import * as text from "."
import { CancellablePromise } from "real-cancellable-promise"
import {
	TextSearchRequest,
	TextSearchResponse,
	TextPatchRequest,
	TextPatchResponse,
	TextUploadResponse,
	TextGenerationRequest,
	TextGenerationResponse,
} from "brandguard/brandguard.text_pb"

export function upload(
	data: FormData,
	file: File,
	bid: string,
	...options: httpx.option[]
): CancellablePromise<TextUploadResponse> {
	data.append("file", file)
	return httpx
		.upload<TextUploadResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/imports/file`, data, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function search(
	bid: string,
	req: TextSearchRequest,
	...options: httpx.option[]
): CancellablePromise<TextSearchResponse> {
	return httpx.get<TextSearchResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}`, req, ...options).then((resp) => ({
		...resp,
		items: (resp.items || []).map((item) => ({ media: item.media, event: text.EventZero(item.event) })),
	}))
}

export function publish(
	id: string,
	bid: string,
	req: TextPatchRequest,
	...options: httpx.option[]
): Promise<TextPatchResponse> {
	return httpx
		.post<TextPatchResponse>(`${httpx.urlstorage.host()}/bg/text/${bid}/${id}/reviewed`, req, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export async function textgeneration(
	bid: string,
	req: TextGenerationRequest,
	...options: httpx.option[]
): Promise<TextGenerationResponse> {
	return httpx.post<TextGenerationResponse>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/text/imports/textgeneration`,
		req,
		...options,
	)
}

import * as svg from "./svg"
export function Icon(props: svg.SVGProps): JSX.Element {
	const { className, ...rest } = props
	return (
		<svg.SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 23 23"
			{...rest}
		>
			<path fill="#f3f3f3" d="M0 0h23v23H0z" />
			<path fill="#f35325" d="M1 1h10v10H1z" />
			<path fill="#81bc06" d="M12 1h10v10H12z" />
			<path fill="#05a6f0" d="M1 12h10v10H1z" />
			<path fill="#ffba08" d="M12 12h10v10H12z" />
		</svg.SVG>
	)
}

export default Icon

import React, { useEffect, useState } from "react"
import * as sessions from "sessions"
import * as brands from "brands"
import * as layouts from "layouts"
import styled from "@emotion/styled"
import * as httpx from "httpx"
import * as icons from "icons"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as bglayouts from "brandguard/components/layouts"
import { Image } from "media/image"
import * as mediaapi from "media/api"
import classnames from "classnames"
import { css } from "@emotion/css"

interface props {
	item: api.ImageSearchResponseItem
	active?: boolean
	panel: JSX.Element
	onCardClick(): void
	onChecked?(state: boolean): void
	onChange(item: api.ImageSearchResponseItem): void
	find(id: string, bid: string, ...options: httpx.option[]): Promise<api.ImageFindResponse>
	imageBackground?: string
	cardClassName?: string
	initialChecked: boolean
}

const Checkbox = styled(icons.brandguard.checkbox)`
	position: absolute;
	top: 7px;
	right: 7px;
`

export default function Display(props: React.PropsWithChildren<props>): JSX.Element {
	const {
		item,
		active,
		onChange,
		onCardClick,
		onChecked,
		find,
		panel,
		imageBackground,
		cardClassName,
		initialChecked,
		...rest
	} = props
	const [checked, setChecked] = useState(initialChecked)
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const processing = predictions.processing(item.event?.approved)

	useEffect(() => {
		if (!onChange) return
		if (!processing) return

		let cancelled = false
		const tid = setTimeout(() => {
			find(item.media?.id!, brand.id, bearertoken)
				.then((result) => {
					cancelled || onChange(result)
				})
				.catch((cause) => console.error("unable to display", cause))
		}, Math.round(Math.random() * 5000 + 5000))

		return () => {
			clearTimeout(tid)
			cancelled = true
		}
	}, [item])

	return (
		<bglayouts.Card
			key={item.media?.id}
			className={`${cardClassName} ${active ? "active" : ""}`}
			onClick={onCardClick}
			flexDirection="column"
			{...rest}
		>
			<Image
				media={mediaapi.zero({ content_digest: item.media?.md5 })}
				background={imageBackground}
				opacity={processing ? 0.3 : 1}
				height="100%"
				className={classnames(maxsizefixed, "card-image")}
			/>
			{onChecked && (
				<Checkbox
					className="card-image-checkbox"
					onClick={(e) => {
						onChecked(!checked)
						setChecked(!checked)
						e.stopPropagation()
					}}
					checked={checked}
				/>
			)}
			{panel}
		</bglayouts.Card>
	)
}

Display.defaultProps = {
	cardClassName: "",
	imageBackground: layouts.theme.colors.grey.lightest,
	initialChecked: false,
}

export const maxsizefixed = css`
	img {
		max-width: 380px;
		max-height: 190px;
		margin: 0;
		margin-top: auto;
	}
`

import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { ...rest } = props
	return (
		<svg.SVG width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path d="M8.5 8.5V7.75C8.08579 7.75 7.75 8.08579 7.75 8.5H8.5ZM8.56641 8.5H9.31641C9.31641 8.08579 8.98063 7.75 8.56641 7.75V8.5ZM8.56641 8.56006V9.31006C8.98063 9.31006 9.31641 8.97427 9.31641 8.56006H8.56641ZM8.5 8.56006H7.75C7.75 8.97427 8.08579 9.31006 8.5 9.31006V8.56006ZM15.25 8.5C15.25 12.2279 12.2279 15.25 8.5 15.25V16.75C13.0563 16.75 16.75 13.0563 16.75 8.5H15.25ZM8.5 15.25C4.77208 15.25 1.75 12.2279 1.75 8.5H0.25C0.25 13.0563 3.94365 16.75 8.5 16.75V15.25ZM1.75 8.5C1.75 4.77208 4.77208 1.75 8.5 1.75V0.25C3.94365 0.25 0.25 3.94365 0.25 8.5H1.75ZM8.5 1.75C12.2279 1.75 15.25 4.77208 15.25 8.5H16.75C16.75 3.94365 13.0563 0.25 8.5 0.25V1.75ZM11.5 8.5C11.5 10.1569 10.1569 11.5 8.5 11.5V13C10.9853 13 13 10.9853 13 8.5H11.5ZM8.5 11.5C6.84315 11.5 5.5 10.1569 5.5 8.5H4C4 10.9853 6.01472 13 8.5 13V11.5ZM5.5 8.5C5.5 6.84315 6.84315 5.5 8.5 5.5V4C6.01472 4 4 6.01472 4 8.5H5.5ZM8.5 5.5C10.1569 5.5 11.5 6.84315 11.5 8.5H13C13 6.01472 10.9853 4 8.5 4V5.5ZM8.5 9.25H8.56641V7.75H8.5V9.25ZM7.81641 8.5V8.56006H9.31641V8.5H7.81641ZM8.56641 7.81006H8.5V9.31006H8.56641V7.81006ZM9.25 8.56006V8.5H7.75V8.56006H9.25Z" />
		</svg.SVG>
	)
}

export default Icon

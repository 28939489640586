import React, { useState } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as tone_of_voices from "brandguide/build/tone_of_voice"
import * as uuid from "uuid"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideToneOfVoice | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideToneOfVoiceZero(_guide.guide?.tone_of_voice))

	const items = data.writing_style || []

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Writing Style" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please add writing samples from ads, marketing materials, or social media that show your brand voice.">
							<tone_of_voices.Carousel my="25px" className={tone_of_voices.layouts.styledCarousel} autoscrollright>
								{items.map((i) => (
									<tone_of_voices.layouts.WriringStyleAsset
										key={i.id}
										item={i}
										onChange={(upd) =>
											setData({ ...data, writing_style: items.map((i) => (i.id === upd.id ? upd : i)) })
										}
										onDelete={() => setData({ ...data, writing_style: items.filter((upd) => upd.id !== i.id) })}
									/>
								))}
								<tone_of_voices.layouts.AddWriringStyleAsset
									onChange={() =>
										setData({
											...data,
											writing_style: [...items, ...[brandguide.api.zeros.tone_of_voice.objectZero({ id: uuid.v4() })]],
										})
									}
								/>
							</tone_of_voices.Carousel>

							<tone_of_voices.Explanation
								val={data.descriptions?.writing_style || ""}
								placeholder="Add further explanation here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.tone_of_voice.descZero({
											...data.descriptions,
											writing_style: text,
										}),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(tone_of_voices.Steps.ADJECTIVES, undefined)}
								onNext={() =>
									onChange(tone_of_voices.Steps.RESULT, {
										...data,
										writing_style: items.filter((i) => i.text !== ""),
									})
								}
								disabled={!brandguide.validations.tone_of_voice.valid.tone_of_voice(data)}
								isLastStep
							/>
							<brandguide.build.layouts.SkipStep
								my="30px"
								onSkip={() => onChange(tone_of_voices.Steps.ADJECTIVES, data)}
								onNeverHave={() => onChange(tone_of_voices.Steps.RESULT, data)}
							/>
							<tone_of_voices.Progress
								activeStep={tone_of_voices.Steps.WRITING_STYLE}
								steps={tone_of_voices.layouts.steps}
							/>
						</brandguide.build.layouts.SectionStepContainer>
						<tone_of_voices.Guidelines activPage="tone_of_voice" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

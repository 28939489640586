import React from "react"
import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"

function formatTitles(titles: string[]): string {
	if (titles.length === 0) return ""

	let titlesString = ""
	let lastTitle = undefined
	switch (true) {
		case titles.length === 1:
			titlesString = titles[0]
			break
		case titles.length === 2:
			titlesString = titles.join(" and ")
			break
		default:
			lastTitle = titles.pop()
			titlesString = titles.join(", ")
			titlesString += " and " + lastTitle
			break
	}

	return titlesString
}

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const data = brandguide.api.zeros.guide.guideComplianceZero(guide.guide?.compliance)

	const selectedItems = (data.items || []).filter((i) => i.names.length > 0)

	const title = `We need to follow the ${formatTitles(
		selectedItems.map((i) => i.title),
	)} compliance requirements in all of our written and visual communications.`

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Compliance</result_components.NavTitle>
				<result_components.NavDescription>
					{title}
					<br />
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.selection} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer gridGap="2%">
					<layouts.Flex flex="1" flexDirection="column">
						<layouts.Flex flexDirection="column" mb="5vh">
							<typography.h4 color={colors.grey.dark50} lineHeight="50px">
								Documentation
							</typography.h4>
							<typography.h5 color={colors.grey.dark50} fontWeight="400" lineHeight="3vh">
								Please refer the the following link(s) for the compliance documentation:
							</typography.h5>
						</layouts.Flex>
						<ul style={{ listStyleType: "initial", marginLeft: "20px" }}>
							{selectedItems.map((item, i) => (
								<React.Fragment key={i}>
									<li>
										<layouts.Flex flex="1" gridGap="20px">
											<layouts.Flex flex="1">{item.title}:</layouts.Flex>
											<layouts.Flex flex="3">
												<ul>
													{item.names.map((n, index) => (
														<React.Fragment key={`${i}-${index}`}>
															<li style={{ marginBottom: "10px" }}>
																<layouts.Flex flexDirection="column" gridGap="5px">
																	<layouts.Flex alignItems="center" maxWidth="50vw">
																		<typography.h4
																			fontSize="1.5vh"
																			color={layouts.theme.colors.grey.dark50}
																			lineHeight="unset"
																			className={brandguide.build.compliance.layouts.truncatedText}
																		>
																			{n.name}
																		</typography.h4>
																	</layouts.Flex>
																	<layouts.Flex maxWidth="50vw">
																		<layouts.links.external
																			href={n.url}
																			target="_blank"
																			color={layouts.theme.colors.blue.blue}
																			style={{ textDecoration: "underline", fontSize: "1.3vh", whiteSpace: "nowrap" }}
																			className={brandguide.build.compliance.layouts.truncatedText}
																		>
																			{n.url}
																		</layouts.links.external>
																	</layouts.Flex>
																</layouts.Flex>
															</li>
														</React.Fragment>
													))}
												</ul>
											</layouts.Flex>
										</layouts.Flex>
									</li>
								</React.Fragment>
							))}
						</ul>
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as debugx from "x/debugx"
import * as api from "./api"
import * as typography from "typography"
import { Brand } from "./account.brand"
import { css } from "@emotion/css"
import * as uuid from "uuid"
import * as icons from "icons"

interface props extends layouts.containers.ContainerProps {
	onChange(b: Brand): void
	onDelete?(b: Brand): void
}

function validate(brand: Brand): boolean {
	return inputs.assertions.failed(
		inputs.assertions.strings.min(1)(brand.description),
		inputs.assertions.strings.url()(brand.domain),
		inputs.assertions.numeric.min(0)(brand.brandguard_threshold),
		inputs.assertions.numeric.max(200)(brand.brandguard_threshold), // we allow up to 200; strictly speaking 101 should be sufficient.
	)
}

export function New(props: props & { defaults: Partial<Brand> }): JSX.Element {
	const { defaults, onChange, ...rest } = props
	const [brand, setBrand] = useState(api.zero(defaults))
	const disabled = validate(brand)

	return (
		<layouts.containers.flex styled flexDirection="column" flex="1" background={layouts.theme.colors.grey.bg} {...rest}>
			<layouts.Flex flexDirection="column" maxWidth="fit-content" px="10px">
				<typography.h3
					lineHeight="30px"
					fontWeight="500"
					color={layouts.theme.colors.blue.blue}
					textAlign="left"
					letterSpacing="unset"
				>
					Add Brand
				</typography.h3>
				<layouts.Flex borderBottom={`2.5px solid ${layouts.theme.colors.blue.blue}`} mt="5px" />
			</layouts.Flex>
			<layouts.Flex my="50px">
				<Form current={brand} onChange={setBrand} flex="1" />
			</layouts.Flex>
			<layouts.containers.flex justifyContent="right">
				<layouts.buttons.primary
					width="120px"
					height="35px"
					borderRadius="37px"
					disabled={disabled}
					onClick={() => onChange(brand)}
				>
					Save
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

New.defaultProps = {
	defaults: {},
	width: "965px",
	px: "25px",
	py: "25px",
}

export function Edit(props: props & { current: Brand }): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const { current, onChange, onDelete, ...rest } = props

	const [brand, setBrand] = useState(current)

	const disabled = validate(brand)
	const removeButton = debugx.alpha.enabled() && onDelete

	return (
		<layouts.containers.flex styled flexDirection="column" flex="1" background={layouts.theme.colors.grey.bg} {...rest}>
			<layouts.Flex flexDirection="column" maxWidth="fit-content" px="10px">
				<typography.h3
					lineHeight="30px"
					fontWeight="500"
					color={layouts.theme.colors.blue.blue}
					textAlign="left"
					letterSpacing="unset"
				>
					Edit Brand
				</typography.h3>
				<layouts.Flex borderBottom={`2.5px solid ${layouts.theme.colors.blue.blue}`} mt="5px" />
			</layouts.Flex>
			<layouts.Flex my="50px">
				<Form current={brand} onChange={setBrand} flex="1" />
			</layouts.Flex>
			<layouts.containers.flex justifyContent="space-between" gridGap="10px">
				<layouts.containers.flex>
					{removeButton && (
						<layouts.Flex onClick={() => onDelete(brand)} alignItems="center">
							<icons.Trash width="15px" height="20px" stroke={layouts.theme.colors.blue.bluedarkness} />
							<typography.h5 pl="5px" fontWeight="400" lineHeight="20px" color={layouts.theme.colors.blue.bluedarkness}>
								Remove Brand
							</typography.h5>
						</layouts.Flex>
					)}
				</layouts.containers.flex>
				<layouts.containers.flex justifyContent="flex-end" gridGap="10px">
					<layouts.buttons.outline width="120px" height="35px" borderRadius="37px" onClick={() => mtoggle(undefined)}>
						Cancel
					</layouts.buttons.outline>
					<layouts.buttons.primary
						width="120px"
						height="35px"
						borderRadius="37px"
						disabled={disabled}
						onClick={() => onChange(brand)}
					>
						Save
					</layouts.buttons.primary>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Edit.defaultProps = {
	width: "965px",
	px: "25px",
	py: "25px",
}

export function AdConnect(): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.containers.box styled width="1100px" height="900px" background={layouts.theme.colors.grey.bg}>
			<typography.h3 color={layouts.theme.colors.grey.medium} p="50px" textAlign="left">
				Connect to Ad platform
			</typography.h3>
			<layouts.containers.flex justifyContent="center" pb="50px">
				<layouts.buttons.primary
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					onClick={() => console.log("Connect to Google")}
				>
					Connect to Google
				</layouts.buttons.primary>
			</layouts.containers.flex>
			<layouts.containers.flex justifyContent="center" pb="50px">
				<layouts.buttons.primary
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					onClick={() => console.log("Connect to Facebook")}
				>
					Connect to Facebook
				</layouts.buttons.primary>
			</layouts.containers.flex>
			<layouts.containers.flex justifyContent="center" pt="50px">
				<layouts.buttons.outline
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					onClick={() => mtoggle(undefined)}
				>
					Cancel
				</layouts.buttons.outline>
			</layouts.containers.flex>
		</layouts.containers.box>
	)
}

export default function Form(props: props & { current: Brand }): JSX.Element {
	const { current, onChange, ...rest } = props
	const [sliderKey, setSliderKey] = useState(uuid.v4())
	const [thresholdMaxKey, setThresholdMaxKey] = useState(uuid.v4())
	return (
		<layouts.forms.Container flexDirection="row" {...rest}>
			<layouts.forms.Group className="brand.details" flex="1" gridGap="50px">
				<layouts.Flex flexDirection="column" flex="1">
					<typography.h3
						px="10px"
						color={layouts.theme.colors.grey.dark50}
						letterSpacing="unset"
						fontWeight="700"
						textAlign="left"
						lineHeight="30px"
					>
						Brand Name
					</typography.h3>
					<layouts.Flex height="50px" mt="10px">
						<inputs.Text
							padding="0px 20px"
							borderRadius="30px"
							placeholder="e.g. Example Industries, Inc."
							defaultValue={current.description}
							onChange={(evt) => onChange({ ...current, description: evt.currentTarget.value })}
							autoFocus
						/>
					</layouts.Flex>
				</layouts.Flex>
				<layouts.Flex flexDirection="column" flex="1">
					<typography.h3
						px="10px"
						color={layouts.theme.colors.grey.dark50}
						letterSpacing="unset"
						fontWeight="700"
						textAlign="left"
						lineHeight="30px"
					>
						Brand URL
					</typography.h3>
					<layouts.Flex height="50px" mt="10px">
						<inputs.Text
							padding="0px 20px"
							borderRadius="30px"
							defaultValue={current.domain}
							placeholder="e.g. https://www.example.com"
							onChange={(evt) => onChange({ ...current, domain: evt.currentTarget.value })}
						/>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.forms.Group>
			{debugx.alpha.enabled() && (
				<>
					<layouts.forms.Group flex="1" my="40px">
						<layouts.Flex flexDirection="column" flex="1">
							<typography.h3
								px="10px"
								color={layouts.theme.colors.grey.dark50}
								letterSpacing="unset"
								fontWeight="700"
								textAlign="left"
								lineHeight="30px"
							>
								BrandGuard Threshold
							</typography.h3>
							<layouts.Flex height="50px" mt="50px" position="relative" flexDirection="column">
								<layouts.Flex
									height="15px"
									background="linear-gradient(90deg, #E43E3E 0%, #E47A3E 51.39%, #3E6EE4 98.67%)"
									borderRadius="12px"
									alignItems="center"
								>
									<inputs.Range
										key={sliderKey}
										flex="1"
										width="100%"
										min={0}
										max={100}
										defaultValue={current.brandguard_threshold}
										onChange={(evt) => {
											setThresholdMaxKey(uuid.v4())
											onChange({ ...current, brandguard_threshold: parseInt(evt.target.value) })
										}}
										className={styledInput}
									/>
								</layouts.Flex>
							</layouts.Flex>
						</layouts.Flex>
					</layouts.forms.Group>
					<layouts.forms.Group flex="1" my="25px" gridGap="25px" mx="auto">
						<layouts.Flex flexDirection="column" flex="1" maxWidth="132px">
							<typography.h5
								px="10px"
								color={layouts.theme.colors.grey.dark50alpha90}
								letterSpacing="unset"
								fontWeight="400"
								textAlign="center"
								lineHeight="30px"
							>
								Min Threshold
							</typography.h5>
							<layouts.Flex height="50px" mt="10px">
								<inputs.Text
									width="132px"
									padding="0px 20px"
									borderRadius="30px"
									defaultValue={0}
									placeholder="0"
									disabled
									icon={
										<layouts.Flex
											borderLeft={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
											alignItems="center"
											px="10px"
											my="auto"
											ml="-40px"
											height="50%"
										>
											%
										</layouts.Flex>
									}
								/>
							</layouts.Flex>
						</layouts.Flex>
						<layouts.Flex
							borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
							width="20px"
							mb="25px"
						/>
						<layouts.Flex flexDirection="column" flex="1" maxWidth="132px">
							<typography.h5
								px="10px"
								color={layouts.theme.colors.grey.dark50alpha90}
								letterSpacing="unset"
								fontWeight="400"
								textAlign="center"
								lineHeight="30px"
							>
								Max Threshold
							</typography.h5>
							<layouts.Flex height="50px" mt="10px">
								<inputs.Text
									key={thresholdMaxKey}
									width="132px"
									padding="0px 20px"
									borderRadius="30px"
									defaultValue={current.brandguard_threshold}
									onChange={(evt) => {
										setSliderKey(uuid.v4())
										onChange({ ...current, brandguard_threshold: parseInt(evt.currentTarget.value) })
									}}
									icon={
										<layouts.Flex
											borderLeft={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
											alignItems="center"
											px="10px"
											my="auto"
											ml="-40px"
											height="50%"
										>
											%
										</layouts.Flex>
									}
								/>
							</layouts.Flex>
						</layouts.Flex>
					</layouts.forms.Group>
				</>
			)}
		</layouts.forms.Container>
	)
}

const styledInput = css`
	input {
		&:hover,
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}

	input[type="range"] {
		-webkit-appearance: none;
		appearance: none;
		height: 15px;
		background: transparent;
		outline: none;
	}
	input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 30px;
		height: 30px;
		border: 8px solid ${layouts.theme.colors.space_cadet};
		background: transparent;
		cursor: pointer;
		border-radius: 50%;
	}

	input[type="range"]::-moz-range-thumb {
		width: 15px;
		height: 15px;
		border: 8px solid ${layouts.theme.colors.space_cadet};
		background: transparent;
		cursor: pointer;
		border-radius: 50%;
	}
`

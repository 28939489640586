import React, { useState } from "react"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as bgapprovals from "brandguard/components/approvals"

interface props {
	item: api.ImageSearchResponseItem
	threshold: number
	display: api.Prediction
	reviewed: boolean
	color(p: api.Prediction): string
	statusText(p: api.Prediction): JSX.Element
	onChange?: (item: api.ImageSearchResponseItem) => void
	reject(item: api.ImageSearchResponseItem): Promise<api.ImageSearchResponseItem>
	approve(item: api.ImageSearchResponseItem): Promise<api.ImageSearchResponseItem>
	undo?(current: api.ImageSearchResponseItem, previously: api.ImageSearchResponseItem): Promise<api.ImagePatchResponse>
	reasonText(p: api.Prediction, o: api.Prediction, confidence: number, threshold: number): string
	extension: boolean
	actiontext: string
}

interface context {
	observation: api.Prediction
	prediction: api.Prediction
	statusText(p: api.Prediction): JSX.Element
}

export default function Approval(props: props): JSX.Element {
	const {
		item,
		threshold,
		color,
		statusText,
		approve,
		reject,
		undo,
		onChange,
		display: _display,
		reviewed: _reviewed,
		extension,
		actiontext,
		reasonText,
	} = props
	const [updated, setUpdated] = useState(item)
	const [loading, setLoading] = useState(false)
	const prediction = item.event?.approved || api.Prediction.NONE
	const observation = item.event?.observation || api.Prediction.NONE
	const confidence = item.event?.confidence || 0
	const agreement = prediction === observation
	const display_init = _display === api.Prediction.NONE ? prediction : _display
	const reviewed = _reviewed && observation === api.Prediction.INREVIEW
	const [feedback, setFeedback] = useState(true)
	const [status, setStatus] = useState<context>({
		observation: observation,
		prediction: display_init,
		statusText: statusText,
	})

	const updateStatus = (i: api.ImageSearchResponseItem): api.ImageSearchResponseItem => {
		const prediction = i.event?.approved || api.Prediction.NONE
		const observation = i.event?.observation || api.Prediction.NONE
		const undo_review = reviewed && !feedback
		const display_update = undo_review ? display_init : observation === api.Prediction.NONE ? prediction : observation
		setStatus({
			observation: observation,
			prediction: display_update,
			statusText: feedback ? predictions.string_changed : statusText,
		})
		setUpdated(i)
		return i
	}

	return (
		<bgapprovals.styles.ActionPanel
			background={color(status.prediction)}
			className={`${extension ? "extension" : ""} status-panel ${
				status.prediction === api.Prediction.INREVIEW ? "inreview" : "noreview"
			}`}
		>
			<bgapprovals.Status
				className="approval-status"
				color={color}
				confidence={confidence}
				threshold={threshold}
				p="0px"
				{...status}
				display={extension ? "none" : "inherit"}
				reasonText={reasonText}
			/>

			{!predictions.processing(display_init) && (
				<layouts.loading.screen loading={loading} cursor="not-allowed" opacity="0" icon={<></>} justifyContent="center">
					<layouts.overlays.Container>
						<layouts.overlays.Inline enabled={feedback}>
							<bgapprovals.styles.FeedbackContainer className={extension ? "extension" : ""}>
								<layouts.containers.span mr="10px">{actiontext}</layouts.containers.span>
								<layouts.containers.flex>
									<bgapprovals.styles.FeedbackYes
										active={observation === api.Prediction.NONE ? false : agreement}
										onClick={(evt) => {
											evt.stopPropagation()
											setLoading(true)
											approve(item)
												.then(updateStatus)
												.then(onChange)
												.then(() => setFeedback(false))
												.finally(() => setLoading(false))
										}}
									/>
									<bgapprovals.styles.FeedbackNo
										active={observation === api.Prediction.NONE ? false : !agreement}
										onClick={(evt) => {
											evt.stopPropagation()
											setLoading(true)
											reject(item)
												.then(updateStatus)
												.then(onChange)
												.then(() => setFeedback(false))
												.finally(() => setLoading(false))
										}}
									/>
								</layouts.containers.flex>
							</bgapprovals.styles.FeedbackContainer>
						</layouts.overlays.Inline>
						<layouts.overlays.Inline enabled={!feedback && undo !== undefined}>
							<bgapprovals.styles.FeedbackContainer>
								<bgapprovals.styles.FeedbackUndo
									onClick={(evt) => {
										evt.stopPropagation()
										setLoading(true)
										undo!(updated, item)
											.then(updateStatus)
											.then(onChange)
											.then(() => setFeedback(true))
											.catch((cause) => console.error("unable to display brandguard image approvals", cause))
											.finally(() => setLoading(false))
									}}
								/>
							</bgapprovals.styles.FeedbackContainer>
						</layouts.overlays.Inline>
					</layouts.overlays.Container>
				</layouts.loading.screen>
			)}
		</bgapprovals.styles.ActionPanel>
	)
}

Approval.defaultProps = {
	display: api.Prediction.NONE,
	threshold: 0,
	reviewed: false,
	color: predictions.color,
	statusText: predictions.string,
	reasonText: predictions.reason,
	extension: false,
	actiontext: "Do you agree?",
}

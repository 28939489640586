import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as inputs from "inputs"
import * as icons from "icons"
import { css } from "@emotion/css"

const styledInput = css`
	padding: 10px;
	border: 1px solid ${layouts.theme.colors.grey.dark50alpha20};
	&:focus {
		outline: none;
	}
	font-family: Roboto;
`

interface props extends layouts.containers.ContainerProps {
	val: string
	placeholder: string
	onChange(e: string): void
	maxLength: number
}

export default function Explanation(props: props): JSX.Element {
	const { val, placeholder, onChange, maxLength, ...rest } = props

	const [text, setText] = useState(val)
	const textlength = text.length

	const [focused, setFocused] = useState(textlength > 0)

	useEffect(() => {
		onChange(text)
	}, [text])

	if (focused)
		return (
			<layouts.Flex position="relative" {...rest}>
				<inputs.TextArea
					className={styledInput}
					value={text}
					onChange={(evt) => setText(evt.target.value)}
					placeholder={placeholder}
					maxLength={maxLength}
				/>
				<layouts.containers.absolute
					padding="10px"
					right="0"
					cursor="pointer"
					onClick={() => {
						setFocused(false)
						setText("")
					}}
				>
					<icons.Close height="16px" width="16px" />
				</layouts.containers.absolute>
				<layouts.containers.absolute padding="10px" bottom="0">
					<typography.h5
						fontSize="12px"
						fontWeight="400"
						color={
							textlength === maxLength ? layouts.theme.colors.red.cinnabar : layouts.theme.colors.grey.dark50alpha80
						}
					>
						{textlength}/{maxLength}
					</typography.h5>
				</layouts.containers.absolute>
			</layouts.Flex>
		)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex onClick={() => setFocused(true)}>
				<typography.h5
					fontSize="16px"
					fontWeight="400"
					letterSpacing="unset"
					color={layouts.theme.colors.grey.dark50}
					whiteSpace="unset"
				>
					+ Add further explanation
				</typography.h5>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Explanation.defaultProps = {
	placeholder: "",
	maxLength: 1000,
}

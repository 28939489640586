import { SVG, SVGProps } from "./svg"
import * as theme from "layouts/theme"

export const processing = (props: SVGProps) => {
	let { width, height, className, ...rest } = props
	return (
		<SVG
			width={width}
			height={height}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M6 3.28571V1M6 11V8.71429M8.71429 6H11M1 6H3.28571M7.91947 4.08081L9.53571 2.46456M2.46411 9.53561L4.08036 7.91936M7.91947 7.91919L9.53571 9.53544M2.46411 2.46439L4.08036 4.08064"
				stroke={theme.colors.white}
				strokeLinecap="round"
				strokeLinejoin="round"
				{...rest}
			/>
		</SVG>
	)
}

processing.defaultProps = {
	width: "12px",
	height: "12px",
}

export const approved = (props: SVGProps) => {
	let { width, height, ...rest } = props
	return (
		<SVG width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.74556 4.87944L5.375 7.25L4.56694 6.44194M6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1Z"
				stroke={theme.colors.white}
				strokeLinecap="round"
				strokeLinejoin="round"
				{...rest}
			/>
		</SVG>
	)
}

approved.defaultProps = {
	width: "12px",
	height: "12px",
}

export const rejected = (props: SVGProps) => {
	let { width, height, ...rest } = props
	return (
		<SVG width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.12132 4.58579C8.31658 4.39052 8.31658 4.07394 8.12132 3.87868C7.92606 3.68342 7.60948 3.68342 7.41421 3.87868L8.12132 4.58579ZM3.87868 7.41421C3.68342 7.60948 3.68342 7.92606 3.87868 8.12132C4.07394 8.31658 4.39052 8.31658 4.58579 8.12132L3.87868 7.41421ZM7.41421 8.12132C7.60948 8.31658 7.92606 8.31658 8.12132 8.12132C8.31658 7.92606 8.31658 7.60948 8.12132 7.41421L7.41421 8.12132ZM4.58579 3.87868C4.39052 3.68342 4.07394 3.68342 3.87868 3.87868C3.68342 4.07394 3.68342 4.39052 3.87868 4.58579L4.58579 3.87868ZM10.5 6C10.5 8.48528 8.48528 10.5 6 10.5V11.5C9.03757 11.5 11.5 9.03757 11.5 6H10.5ZM6 10.5C3.51472 10.5 1.5 8.48528 1.5 6H0.5C0.5 9.03757 2.96243 11.5 6 11.5V10.5ZM1.5 6C1.5 3.51472 3.51472 1.5 6 1.5V0.5C2.96243 0.5 0.5 2.96243 0.5 6H1.5ZM6 1.5C8.48528 1.5 10.5 3.51472 10.5 6H11.5C11.5 2.96243 9.03757 0.5 6 0.5V1.5ZM7.41421 3.87868L5.64645 5.64645L6.35355 6.35355L8.12132 4.58579L7.41421 3.87868ZM5.64645 5.64645L3.87868 7.41421L4.58579 8.12132L6.35355 6.35355L5.64645 5.64645ZM8.12132 7.41421L6.35355 5.64645L5.64645 6.35355L7.41421 8.12132L8.12132 7.41421ZM6.35355 5.64645L4.58579 3.87868L3.87868 4.58579L5.64645 6.35355L6.35355 5.64645Z"
				fill={theme.colors.white}
				{...rest}
			/>
		</SVG>
	)
}

rejected.defaultProps = {
	width: "12px",
	height: "12px",
}

export const inreview = (props: SVGProps) => {
	let { width, height, ...rest } = props
	return (
		<SVG width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.14308 4.079C4.86807 4.054 4.62486 4.25667 4.59986 4.53168C4.57486 4.80669 4.77753 5.0499 5.05254 5.0749L5.14308 4.079ZM7.96241 4.83737L8.46036 4.7921C8.43851 4.55173 8.24806 4.36128 8.00768 4.33942L7.96241 4.83737ZM7.72488 7.74724C7.74989 8.02225 7.99309 8.22492 8.2681 8.19992C8.54311 8.17492 8.74578 7.93171 8.72078 7.65671L7.72488 7.74724ZM4.48384 7.60884C4.28858 7.8041 4.28858 8.12068 4.48384 8.31595C4.6791 8.51121 4.99568 8.51121 5.19094 8.31595L4.48384 7.60884ZM5.05254 5.0749L7.91715 5.33532L8.00768 4.33942L5.14308 4.079L5.05254 5.0749ZM7.46447 4.88264L7.72488 7.74724L8.72078 7.65671L8.46036 4.7921L7.46447 4.88264ZM7.60886 4.48382L4.48384 7.60884L5.19094 8.31595L8.31597 5.19092L7.60886 4.48382ZM10.8999 6.3999C10.8999 8.88518 8.88518 10.8999 6.3999 10.8999V11.8999C9.43747 11.8999 11.8999 9.43747 11.8999 6.3999H10.8999ZM6.3999 10.8999C3.91462 10.8999 1.8999 8.88518 1.8999 6.3999H0.899902C0.899902 9.43747 3.36234 11.8999 6.3999 11.8999V10.8999ZM1.8999 6.3999C1.8999 3.91462 3.91462 1.8999 6.3999 1.8999V0.899902C3.36234 0.899902 0.899902 3.36234 0.899902 6.3999H1.8999ZM6.3999 1.8999C8.88518 1.8999 10.8999 3.91462 10.8999 6.3999H11.8999C11.8999 3.36234 9.43747 0.899902 6.3999 0.899902V1.8999Z"
				fill={theme.colors.white}
				{...rest}
			/>
		</SVG>
	)
}

inreview.defaultProps = {
	width: "12px",
	height: "12px",
}

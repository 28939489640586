import React, { useState } from "react"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import * as md5x from "md5x"
import * as api from "brandguard/api"
import * as training from "brandguard/display/training"

interface props extends layouts.containers.FlexProps {
	settings: api.ImageKernelBackgroundColorEnabled
	onChange(upd: api.ImageKernelBackgroundColorEnabled): void
}

export default function BackgroundColors(props: React.PropsWithChildren<props>): JSX.Element {
	const labelwidth = "12ch"
	const { settings, onChange, ...rest } = props
	const [description, setDescription] = useState("")
	const [color, setColor] = useState("")
	const [reset, setReset] = useState(uuid.v4())
	const disabled = inputs.assertions.failed(
		inputs.assertions.strings.min(6)(color),
		inputs.assertions.strings.max(6)(color),
		inputs.assertions.strings.min(1)(description),
	)

	const colors = (settings.colors || []).map((r) => (
		<layouts.Flex key={r.id} py="5px" flexDirection="column">
			<layouts.Flex gridGap="20px">
				<layouts.Span>{`#${r.color}`}</layouts.Span>
				<layouts.Flex alignItems="center" justifyContent="center">
					<layouts.Box width="20px" height="10px" background={`#${r.color}`} />
				</layouts.Flex>
				<layouts.Span>{r.description}</layouts.Span>
				<layouts.Flex
					ml="auto"
					onClick={(evt) => {
						evt.stopPropagation()
						onChange({ ...settings, colors: settings.colors.filter((o) => o.id !== r.id) })
					}}
				>
					<icons.Trash width="14px" height="14px" />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	))

	return (
		<layouts.Flex flexDirection="column" flex="1" {...rest}>
			<layouts.forms.Container key={reset} p="10px">
				<layouts.forms.Group>
					<layouts.forms.Label width={labelwidth}>Color</layouts.forms.Label>
					<inputs.Text
						autoFocus
						placeholder="Describe your color"
						onChange={(evt) => setDescription(evt.target.value)}
						className={training.layouts.styledTextInput}
					/>
				</layouts.forms.Group>
				<layouts.forms.Group>
					<layouts.forms.Label width={labelwidth}>Hexadecimal</layouts.forms.Label>
					<layouts.Flex alignItems="center" gridGap="2px">
						#
						<inputs.Text
							placeholder="bbbbbb"
							onChange={(evt) => setColor(evt.target.value)}
							className={training.layouts.styledTextInput}
						/>
					</layouts.Flex>
				</layouts.forms.Group>
				<layouts.Flex flex="1" flexDirection="row" justifyContent="space-between" mt="10px">
					<layouts.buttons.outline onClick={() => setReset(uuid.v4())} width="10ch" borderRadius="5px">
						Cancel
					</layouts.buttons.outline>
					<layouts.buttons.primary
						disabled={disabled}
						onClick={() => {
							onChange({
								...settings,
								colors: (settings.colors || []).concat({
									id: md5x.string(color),
									color: color,
									description: description,
								}),
							})
							setReset(uuid.v4())
						}}
						width="10ch"
						borderRadius="5px"
					>
						Add
					</layouts.buttons.primary>
				</layouts.Flex>
			</layouts.forms.Container>
			<layouts.Flex flexDirection="column">{colors}</layouts.Flex>
		</layouts.Flex>
	)
}

import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { className, fill, ...rest } = props
	return (
		<svg.SVG
			className={className}
			width="15"
			height="15"
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M6.06937 9.06026C6.54746 9.4168 7.06608 9.46542 7.74675 9.46542C8.08709 9.46542 8.51657 9.44921 9.03518 9.43301C9.56189 9.4168 10.2102 9.40059 11.0043 9.40059V8.1851C10.1939 8.1851 9.53758 8.2013 9.00276 8.21751C8.45984 8.23372 8.06278 8.24993 7.74675 8.24993C7.09849 8.24993 6.93642 8.1851 6.79867 8.07976L6.07747 9.06026H6.06937ZM5.08887 3.97948C5.08887 5.60825 5.11318 6.69409 5.22663 7.42339C5.34818 8.1851 5.57507 8.69561 6.06937 9.06026L6.79056 8.07976C6.66091 7.98252 6.52316 7.83666 6.42592 7.23701C6.32868 6.61306 6.29626 5.62445 6.29626 3.97948H5.08077H5.08887ZM4.88629 3.87414C4.94301 3.85794 4.99973 3.87414 5.04025 3.89845C5.07266 3.92276 5.08887 3.95517 5.08887 3.97948H6.30437C6.30437 3.0476 5.3968 2.49658 4.59457 2.69106L4.88629 3.87414ZM1.21549 8.76854C1.21549 6.48341 2.97391 4.34413 4.88629 3.87414L4.59457 2.69106C2.12306 3.29881 0 5.93238 0 8.76854H1.21549ZM6.23954 13.7845C3.46821 13.7845 1.21549 11.5399 1.21549 8.76854H0C0 12.2124 2.79564 15 6.23954 15V13.7845ZM11.1988 9.55456C10.826 11.9774 8.75156 13.7845 6.23954 13.7845V15C9.35121 15 11.9362 12.7635 12.398 9.74093L11.1988 9.55456ZM11.0124 9.4087C11.0772 9.4087 11.1339 9.4411 11.1744 9.47352C11.2069 9.50593 11.2069 9.53025 11.2069 9.55456L12.4061 9.74093C12.5439 8.84146 11.7903 8.1932 11.0124 8.1932V9.4087ZM4.74853 3.2826L4.89439 3.87414L4.74853 3.2826ZM11.7984 9.65179L11.1988 9.56265L11.7984 9.65179Z"
				fill={fill}
			/>
			<path
				d="M13.7514 7.1398C14.2862 7.05877 14.6671 6.59689 14.6752 6.07827L13.4597 6.06206C13.4597 6.01344 13.5002 5.95672 13.5731 5.94051L13.7514 7.1398ZM8.54098 7.26136C9.5863 7.31808 11.9768 7.40721 13.7514 7.1398L13.5731 5.94051C11.9363 6.19172 9.65923 6.11069 8.61391 6.05397L8.54098 7.26946V7.26136ZM7.38221 6.10258C7.41462 6.72654 7.90892 7.22084 8.54098 7.26136L8.61391 6.04586L7.39841 6.11879L7.38221 6.10258ZM7.29307 1.03802C7.21204 2.80454 7.31738 5.08157 7.38221 6.10258L8.5977 6.02965C8.54098 5.00864 8.43564 2.79644 8.50856 1.09474L7.29307 1.03802ZM8.3384 0.000800435C7.74685 0.00890373 7.31738 0.495101 7.29307 1.03802L8.50856 1.09474C8.50856 1.09474 8.49236 1.15957 8.47615 1.17578C8.45184 1.20009 8.41943 1.2163 8.37081 1.2163L8.3465 0.000800435H8.3384ZM14.6671 6.07017C14.6914 4.80606 13.9459 3.28264 12.8438 2.10766C11.7337 0.924575 10.1373 -0.0316128 8.3384 0.000800435L8.36271 1.2163C9.71596 1.19199 10.9963 1.91317 11.9606 2.93419C12.933 3.97141 13.4678 5.20312 13.4516 6.04586L14.6671 6.07017ZM13.6542 6.53206L13.565 5.93242L13.6542 6.53206ZM8.56529 6.6455L8.53287 7.25325L8.56529 6.6455ZM7.98185 6.06206L8.5896 6.02965L7.98185 6.06206ZM7.89271 1.06233L8.50046 1.08664L7.89271 1.06233ZM8.3465 0.608548V1.2163H8.3546V0.608548H8.3465ZM14.0431 6.06206H13.4354V6.05397H14.0431V6.06206Z"
				fill={fill}
			/>
		</svg.SVG>
	)
}

export default Icon

import React, { useState } from "react"
import * as layouts from "layouts"
import * as section from "."
import * as brandguide from "brandguide"
import { imagery as validations } from "brandguide/validations"
import * as md5x from "md5x"

export const Steps = {
	START: 0,
	LIFESTYLE: 1,
	PRODUCT: 2,
	ILLUSTRATION: 3,
	MISUSE: 4,
	FUNDAMENTALS: 5,
	RESULT: 6,
}

export function Container(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()

	const imagery = brandguide.api.zeros.guide.guideImageryZero(cache.guide.guide?.imagery)

	const curstep = () => {
		if (validations.valid.completed(imagery)) return Steps.RESULT
		switch (false) {
			case validations.valid.start(imagery):
				return Steps.START
			case validations.enabled.lifestyle(imagery):
				if (validations.enabled.product(imagery) && !validations.valid.product(imagery)) return Steps.PRODUCT
				if (validations.enabled.illustration(imagery) && !validations.valid.illustration(imagery))
					return Steps.ILLUSTRATION
				return Steps.START
			case validations.enabled.product(imagery):
				if (validations.enabled.lifestyle(imagery) && !validations.valid.lifestyle(imagery)) return Steps.LIFESTYLE
				if (validations.enabled.illustration(imagery) && !validations.valid.illustration(imagery)) return Steps.PRODUCT
				return Steps.START
			case validations.enabled.illustration(imagery):
				if (validations.enabled.lifestyle(imagery) && !validations.valid.lifestyle(imagery)) return Steps.LIFESTYLE
				if (validations.enabled.product(imagery) && !validations.valid.product(imagery)) return Steps.PRODUCT
				return Steps.START
			case validations.valid.misuse(imagery):
				return Steps.MISUSE
			case validations.valid.fundamentals(imagery):
				return Steps.FUNDAMENTALS
			default:
				return Steps.RESULT
		}
	}

	const [step, setStep] = useState(curstep)
	const [loading, setLoading] = useState(false)

	const handleOnStep = (step: number, data: brandguide.api.GuideImagery | undefined) => {
		if (!data) {
			setStep(step)
			return
		}

		const guide = { ...cache.guide.guide, imagery: data }
		const md5 = md5x.string(JSON.stringify(guide))

		if (md5 === cache.md5) {
			setStep(step)
			return
		}

		setLoading(true)
		cache.store({ ...cache, guide: { ...cache.guide, guide: guide } }).finally(() => {
			setStep(step)
			setLoading(false)
		})
	}

	return (
		<layouts.Flex overflowY="auto" {...rest}>
			<layouts.loading.screen flex="1" loading={loading}>
				{step === Steps.START && <section.Start onChange={handleOnStep} />}
				{step === Steps.LIFESTYLE && <section.Lifestyle onChange={handleOnStep} />}
				{step === Steps.PRODUCT && <section.Product onChange={handleOnStep} />}
				{step === Steps.ILLUSTRATION && <section.Illustration onChange={handleOnStep} />}
				{step === Steps.MISUSE && <section.Misuse onChange={handleOnStep} />}
				{step === Steps.FUNDAMENTALS && <section.Fundamentals onChange={handleOnStep} />}
				{step === Steps.RESULT && <section.Result onChange={handleOnStep} />}
			</layouts.loading.screen>
		</layouts.Flex>
	)
}

Container.defaultProps = {
	flexDirection: "column",
	flex: "1",
	mb: "25px",
}

export default Container

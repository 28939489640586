import { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as icons from "icons"
import { css } from "@emotion/css"
import { breakdowns } from "./templates"
import styled from "@emotion/styled"

export const styledCarousel = css`
	.content {
		gap: 5px;
	}
`
export const steps = ["Archetype", "Edit"]

interface adjectiveItemProps extends layouts.containers.ContainerProps {
	item: string
	selected: boolean
	selectable: boolean
	onChange(): void
}

export function AdjectiveItem(props: adjectiveItemProps): JSX.Element {
	const { item, selected, selectable, onChange, ...rest } = props

	const background = () => {
		if (!selectable) return layouts.theme.colors.grey.dark50alpha30
		return selected ? layouts.theme.colors.blue.blue : layouts.theme.colors.white
	}

	const color = () => {
		if (!selectable) return layouts.theme.colors.grey.dark50alpha60
		return selected ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50alpha50
	}

	return (
		<layouts.Flex
			onClick={() => (selectable ? onChange() : undefined)}
			background={background()}
			cursor={selectable ? "pointer" : "not-allowed"}
			{...rest}
		>
			<typography.h5 fontSize="14px" fontWeight="400" lineHeight="normal" color={color()}>
				{item}
			</typography.h5>
		</layouts.Flex>
	)
}

AdjectiveItem.defaultProps = {
	alignItems: "center",
	justifyContent: "center",
	width: "151px",
	height: "34px",
	borderRadius: "5px",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha30}`,
	boxShadow: `0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`,
}

export interface SelectedItem {
	archetype: number
	item: string
}

interface activeAdjectiveItemProps extends layouts.containers.ContainerProps {
	selected: SelectedItem | undefined
	onDelete(selected: SelectedItem): void
}

export function ActiveAdjectiveItem(props: activeAdjectiveItemProps): JSX.Element {
	const { selected, onDelete, ...rest } = props

	if (!selected) return <layouts.Flex border={`1px dashed ${layouts.theme.colors.grey.dark50alpha30}`} {...rest} />

	return (
		<layouts.Flex
			background={layouts.theme.colors.blue.blue}
			border={`1px solid ${layouts.theme.colors.grey.dark50alpha30}`}
			boxShadow={`0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`}
			position="relative"
			{...rest}
		>
			<layouts.Flex>
				<typography.h5 fontSize="14px" fontWeight="400" lineHeight="normal" color={layouts.theme.colors.white}>
					{selected.item}
				</typography.h5>
			</layouts.Flex>
			<layouts.containers.absolute right="0">
				<layouts.Flex onClick={() => onDelete(selected)} p="5px">
					<icons.Close fill={layouts.theme.colors.white} width="16px" height="16px" />
				</layouts.Flex>
			</layouts.containers.absolute>
		</layouts.Flex>
	)
}

ActiveAdjectiveItem.defaultProps = {
	mb: "24px",
	alignItems: "center",
	justifyContent: "center",
	width: "151px",
	height: "34px",
	borderRadius: "5px",
}

const SubTitle = styled(typography.h6)`
	color: ${layouts.theme.colors.grey.dark50alpha50};
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
`

const Description = styled(typography.h6)`
	color: ${layouts.theme.colors.grey.dark50alpha50};
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	white-space: unset;
`

export const ColumnTitle = styled(typography.h6)`
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
	white-space: unset;
`

ColumnTitle.defaultProps = {
	color: layouts.theme.colors.grey.dark50alpha50,
	fontSize: "12px",
}

export const ColumnList = styled(typography.h6)`
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	white-space: unset;
`

ColumnList.defaultProps = {
	color: layouts.theme.colors.grey.dark50alpha50,
	fontSize: "14px",
}

const itemMinWidth = 10

interface breakdownProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ArchetypeAdjectiveObject
	onChange(archetype: brandguide.api.Archetype, v: number): void
}

export function BreakDownItem(props: breakdownProps): JSX.Element {
	const { item, onChange, ...rest } = props
	const breakdownItem = breakdowns.find((i) => i.archetype === item.archetype)

	const [focused, setFocused] = useState(false)

	if (!breakdownItem) return <></>

	const canUpPercent = () => item.percent < 80
	const canDownPercent = () => item.percent > 20

	if (focused)
		return (
			<layouts.Flex flexDirection="column" p="25px" minWidth="750px" background="#EBECED">
				<layouts.Flex justifyContent="space-between" maxHeight="22px" alignItems="center">
					<layouts.Flex>
						<typography.h4 fontSize="16px" fontWeight="700" color={layouts.theme.colors.grey.dark50alpha50}>
							{breakdownItem.title}
						</typography.h4>
					</layouts.Flex>
					<layouts.Flex p="5px" onClick={() => setFocused(false)}>
						<icons.Close width="20px" height="20px" fill={layouts.theme.colors.grey.dark50alpha50} />
					</layouts.Flex>
				</layouts.Flex>
				<layouts.Flex mt="13px">
					<layouts.Flex flex="3" flexDirection="column">
						<Description mb="26px">{breakdownItem.description}</Description>
						<SubTitle mb="5px">Communication</SubTitle>
						<Description>{breakdownItem.communication}</Description>
					</layouts.Flex>
					<layouts.Flex
						flex="1"
						minWidth="150px"
						flexDirection="column"
						alignItems="center"
						borderRight={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`}
					>
						<ColumnTitle mb="15px">What motivates you</ColumnTitle>
						{breakdownItem.motivates.map((i) => (
							<ColumnList key={i}>{i}</ColumnList>
						))}
					</layouts.Flex>
					<layouts.Flex flex="1" minWidth="150px" flexDirection="column" alignItems="center">
						<ColumnTitle mb="15px">What scares you</ColumnTitle>
						{breakdownItem.scares.map((i) => (
							<ColumnList key={i}>{i}</ColumnList>
						))}
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		)
	return (
		<layouts.Flex width={`${itemMinWidth * item.percent}px`} maxWidth="750px" position="relative" {...rest}>
			<layouts.Flex flex="2" alignItems="end" justifyContent="center">
				{breakdownItem.icon}
			</layouts.Flex>
			<layouts.containers.absolute right="0" top="0">
				<layouts.Flex
					px="5px"
					alignItems="center"
					justifyContent="center"
					height="27px"
					borderRadius="0px 0px 5px 5px"
					boxShadow={`0px 2px 4px 0px ${layouts.theme.colors.grey.dark50alpha10}`}
					background={layouts.theme.colors.grey.dark50}
				>
					<typography.h5 color={layouts.theme.colors.white}>{item.percent.toFixed(1)}%</typography.h5>
					<layouts.Flex flexDirection="column" alignItems="center" justifyContent="center" minHeight="15px" px="5px">
						<layouts.Flex
							flex="1"
							onClick={() => (canUpPercent() ? onChange(item.archetype, 10) : undefined)}
							cursor={canUpPercent() ? "pointer" : "not-allowed"}
						>
							<icons.brandguide.archetype.actions.Up />
						</layouts.Flex>
						<layouts.Flex
							flex="1"
							onClick={() => (canDownPercent() ? onChange(item.archetype, -10) : undefined)}
							cursor={canDownPercent() ? "pointer" : "not-allowed"}
						>
							<icons.brandguide.archetype.actions.Down />
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.containers.absolute>
			<layouts.Flex
				flex="1"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				mt="25px"
				mb="16px"
				mx="20%"
			>
				<PromoText textAlign="center">{breakdownItem.promo}</PromoText>
				<PromoText
					color={layouts.theme.colors.blue.blue}
					style={{ textDecorationLine: "underline", cursor: "pointer" }}
					onClick={() => setFocused(true)}
				>
					Learn More
				</PromoText>
			</layouts.Flex>
			<layouts.Flex flex="1" alignItems="center" justifyContent="center">
				<PromoText fontSize="16px">{breakdownItem.title}</PromoText>
			</layouts.Flex>
		</layouts.Flex>
	)
}

BreakDownItem.defaultProps = {
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	minHeight: "250px",
	background: "#EBECED",
}

export const PromoText = styled(typography.h4)``

PromoText.defaultProps = {
	color: layouts.theme.colors.grey.dark50alpha50,
	fontSize: "12px",
	fontWeight: "400",
	lineHeight: "17.5px",
	whiteSpace: "unset",
}

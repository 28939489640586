import React, { useState } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as archetype from "brandguide/build/archetype"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideArchetype | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const maxSelectedCnt = 6
	const [data, setData] = useState(brandguide.api.zeros.guide.guideArchetypeZero(_guide.guide?.archetype))
	const [selected, setSelected] = useState<brandguide.api.ArchetypeAdjectiveObject[]>(data.adjectives)

	const adjectives = archetype.templates.adjectives

	const selectedAdjectives = [] as archetype.layouts.SelectedItem[]
	selected.forEach((a) => a.items.forEach((i) => selectedAdjectives.push({ archetype: a.archetype, item: i })))

	const totalSelectedItems = selected.reduce((total, column) => total + column.items.length, 0)

	const handleSelect = (archetype: number, item: string) => {
		const selectedColumn = selected.find((column) => column.archetype === archetype)
		if (totalSelectedItems < maxSelectedCnt && (!selectedColumn || selectedColumn.items.length < maxSelectedCnt)) {
			if (selectedColumn) {
				selectedColumn.items.push(item)
				setSelected([...selected])
			} else {
				setSelected([...selected, { archetype, items: [item], percent: 0 }])
			}
		}
	}

	const handleRemove = (archetype: number, item: string) => {
		const selectedColumn = selected.find((column) => column.archetype === archetype)
		if (selectedColumn) {
			selectedColumn.items = selectedColumn.items.filter((i) => i !== item)
			if (selectedColumn.items.length === 0) {
				setSelected(selected.filter((column) => column.archetype !== archetype))
			} else {
				setSelected([...selected])
			}
		}
	}

	const isSelectableColumn = (archetype: brandguide.api.Archetype): boolean => {
		if (selected.length < 2) return true
		return !!selected.find((a) => a.archetype === archetype)
	}

	const isSelectedItem = (archetype: brandguide.api.Archetype, item: string): boolean => {
		const column = selected.find((a) => a.archetype === archetype)
		if (!column) return false
		return !!column.items.find((c) => c === item)
	}

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Archetype" Help={<archetype.helps.Modal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please select the six adjectives that best describe your brand. Choose from 1 or 2 of the columns below.">
							<archetype.Carousel my="25px" className={archetype.layouts.styledCarousel}>
								{adjectives.map((adjective, index) => (
									<layouts.Flex key={index} flexDirection="column">
										{index < maxSelectedCnt ? (
											<archetype.layouts.ActiveAdjectiveItem
												selected={selectedAdjectives[index]}
												onDelete={(i) => handleRemove(i.archetype, i.item)}
											/>
										) : (
											<layouts.Flex minHeight="34px" minWidth="151px" mb="26px" />
										)}

										{adjective.items.map((i, idx) => (
											<archetype.layouts.AdjectiveItem
												mb="5px"
												key={i + idx}
												item={i}
												selectable={isSelectableColumn(adjective.archetype)}
												onChange={() => handleSelect(adjective.archetype, i)}
												selected={isSelectedItem(adjective.archetype, i)}
											/>
										))}
									</layouts.Flex>
								))}
							</archetype.Carousel>

							<archetype.Explanation
								val={data.descriptions?.selection || ""}
								placeholder="Add further explanation here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.archetype.descZero({
											...data.descriptions,
											selection: text,
										}),
									})
								}}
							/>
							<brandguide.build.layouts.NextStepsNav
								onNext={() => onChange(archetype.Steps.BREAKDOWN, { ...data, adjectives: selected })}
								disabled={!brandguide.validations.archetype.valid.selection({ ...data, adjectives: selected })}
							/>
							<archetype.Progress activeStep={archetype.Steps.SELECTION} steps={archetype.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<archetype.Guidelines activPage="archetype" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import React from "react"
import { useDropzone, FileRejection, DropEvent } from "react-dropzone"
import * as containers from "layouts/containers"

Dropwell.defaultProps = {
	multiple: false,
	disabled: false,
}

interface props extends containers.ContainerProps {
	onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
	accept?: { [fileType: string]: string[] }
	multiple: boolean
	disabled?: boolean
	maxSize?: number
}

export function Dropwell(props: React.PropsWithChildren<props>): JSX.Element {
	const { children, multiple, disabled, onDrop, accept, maxSize, ...rest } = props

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: onDrop,
		accept: accept,
		multiple: multiple,
		disabled: disabled,
		maxSize: maxSize,
	})

	return (
		<containers.box cursor={disabled ? undefined : "pointer"} {...getRootProps({})} {...rest}>
			<input {...getInputProps()} />
			{children}
		</containers.box>
	)
}

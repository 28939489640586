import { SectionTitle } from "brandguide/results/components"
import { default as Main } from "./main"

export { default as Main } from "./main"

export function Section(): JSX.Element {
	return (
		<>
			<SectionTitle title="Compliance" className="section-print" />
			<Main className="section-print" />
		</>
	)
}

import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import * as timex from "timex"
import * as authzc from "authzcached"
import * as sessions from "./session"
import * as scripts from "scripts"

interface g {
	appcuesp: boolean
	Appcues: api
}

interface api {
	identify(id: string, data: unknown): void
	page(): void
}

export function Appcues(props: React.PropsWithChildren<unknown>): JSX.Element {
	const location = useLocation()
	const session = sessions.useSession()
	const [appcues, setAppcues] = useState(undefined as api | undefined)
	const [auth] = useState(authzc.useCache((cached) => cached.meta))

	useEffect(() => {
		const g = window as unknown as g

		// nothing to do when appcues isn't configured
		if (process.env.REACT_APP_APPCUES === undefined || process.env.REACT_APP_APPCUES === "" || g.appcuesp) return

		g.appcuesp = true
		scripts
			.load<api>(process.env.REACT_APP_APPCUES, () => {
				const appq = (window as unknown as g).Appcues
				setAppcues(appq)
				return Promise.resolve(appq)
			})
			.catch((error) => {
				console.error("Error loading Appcues:", error)
			})
	}, [])

	useEffect(() => {
		if (appcues === undefined) return

		auth.token().then((token) => {
			const accountts = timex.from.iso(session.account.created_at)
			const profilets = timex.from.iso(session.profile.created_at)
			const firstsession = timex.local().diff(profilets) < timex.duration.iso("PT1H")
			appcues.identify(session.profile.id, {
				domain: window?.location?.host || "",
				account_id: session.account.id,
				account_creation: accountts.toUnixInteger(),
				account: session.account.description,
				display: session.profile.display,
				firstsession: firstsession,
				usermanagement: auth.current.usermanagement,
			})
		})
	}, [appcues])

	useEffect(() => {
		if (appcues === undefined) return
		appcues.page()
	}, [appcues, location])

	return <>{props.children}</>
}

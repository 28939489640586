import { Route, Routes, Navigate } from "react-router-dom"
import * as routing from "./routing"
import * as brandguide from "brandguide"
import * as colors from "brandguide/build/colors"
import * as logos from "brandguide/build/logos"
import * as typography from "brandguide/build/typography"
import * as imagery from "brandguide/build/imagery"
import * as tone_of_voice from "brandguide/build/tone_of_voice"
import * as archetype from "brandguide/build/archetype"
import * as application from "brandguide/build/application"
import * as compliance from "brandguide/build/compliance"

export default function Routing(): JSX.Element {
	const guide = brandguide.cache.useGuide()
	const redirect = <Navigate to={brandguide.routing.start(guide.brand_id)} replace />
	return (
		<Routes>
			<Route path={routing.colors()} element={guide.guide?.colors?.enabled ? <colors.Container /> : redirect} />
			<Route path={routing.logos()} element={guide.guide?.logos?.enabled ? <logos.Container /> : redirect} />
			<Route
				path={routing.typography()}
				element={guide.guide?.typography?.enabled ? <typography.Container /> : redirect}
			/>
			<Route path={routing.imagery()} element={guide.guide?.imagery?.enabled ? <imagery.Container /> : redirect} />
			<Route
				path={routing.tone_of_voice()}
				element={guide.guide?.tone_of_voice?.enabled ? <tone_of_voice.Container /> : redirect}
			/>
			<Route
				path={routing.archetype()}
				element={guide.guide?.archetype?.enabled ? <archetype.Container /> : redirect}
			/>
			<Route
				path={routing.compliance()}
				element={guide.guide?.compliance?.enabled ? <compliance.Container /> : redirect}
			/>
			<Route
				path={routing.application()}
				element={guide.guide?.application?.enabled ? <application.Container /> : redirect}
			/>
			<Route path="*" element={redirect} />
		</Routes>
	)
}

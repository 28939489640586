import React from "react"
import classnames from "classnames"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import * as system from "styled-system"
import * as layouts from "layouts"

export namespace transforms {
	export namespace strings {
		export function trimStart(s: string): string {
			return s.trimStart()
		}
	}
}

export interface props
	extends system.PositionProps,
		system.SpaceProps,
		system.FlexProps,
		system.BorderRadiusProps,
		Omit<React.InputHTMLAttributes<HTMLInputElement>, "value"> {
	className?: string
	disabled?: boolean
	autofocus?: boolean
	placeholder?: string
	icon?: JSX.Element
	outlined?: boolean
	transforms: ((s: string) => string)[]
}

interface input extends layouts.containers.ContainerProps {
	disabled?: boolean
}

const Input = styled.input<input>`
	box-sizing: border-box;
	flex: 1;
	background: ${(props) => (props.disabled ? layouts.theme.colors.grey.light30 : layouts.theme.colors.white)};
	height: ${(props) => props.height || "unset"};
	&:focus {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}

	&:hover {
		color: ${(props) => (props.onClick ? layouts.theme.colors.white : "unset")};
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}

	${system.zIndex}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
	${system.background}
	${system.opacity}
	${system.flex}
	${system.boxShadow}
	${system.border}
	${system.borderRadius}
	${system.height}
	${system.width}
`

Input.defaultProps = {
	width: "inherit",
	padding: "inherit",
	borderRadius: "inherit",
	border: "none",
}

export function text(props: React.PropsWithChildren<props>): JSX.Element {
	const { className, icon, width, height, transforms, onChange, ...rest } = props
	const parentRest = { height, width }

	function _onChange(evt: React.ChangeEvent<HTMLInputElement>): void {
		evt.target.value = transforms.reduce((v, op) => op(v), evt.target.value)
		onChange && onChange(evt)
	}

	return (
		<layouts.Flex position="relative" flex="1" className={classnames("text-input", className)} {...parentRest}>
			<Input onChange={_onChange} {...rest} />
			{icon}
		</layouts.Flex>
	)
}

text.defaultProps = {
	placeholder: "",
	className: "",
	disabled: false,
	transforms: [transforms.strings.trimStart],
}

export function numeric(props: React.PropsWithChildren<props>): JSX.Element {
	const resetcss = css`
		margin: 0px;
		padding: 0px;
		-webkit-appearance: none;
		-moz-appearance: textfield;
		::-webkit-inner-spin-button,
		::-webkit-outer-spin-button {
			display: none;
		}
	`
	const { className, icon, width, height, onChange, ...rest } = props
	const parentRest = { height, width }

	return (
		<layouts.Flex position="relative" flex="1" className={classnames("numeric-input", className)} {...parentRest}>
			<Input className={resetcss} type="number" onChange={onChange} {...rest} />
			{icon}
		</layouts.Flex>
	)
}

numeric.defaultProps = {
	placeholder: "",
	className: "",
	disabled: false,
	transforms: [],
}

export function Range(props: React.PropsWithChildren<props>): JSX.Element {
	const { className, icon, width, height, onChange, ...rest } = props
	const parentRest = { height, width }

	return (
		<layouts.Flex className={classnames("range-input", className)} {...parentRest}>
			<Input type="range" onChange={onChange} {...rest} />
			{icon}
		</layouts.Flex>
	)
}

Range.defaultProps = {
	placeholder: "",
	className: "",
	disabled: false,
	transforms: [],
}

export function Color(props: React.PropsWithChildren<props>): JSX.Element {
	const { onChange, ...rest } = props

	return <Input type="color" onChange={onChange} {...rest} />
}

Color.defaultProps = {
	placeholder: "",
	className: "",
	disabled: false,
	transforms: [],
}

/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandgpt.message.proto" (package "brandgpt.message", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Direction } from "./brandgpt_pb";
/**
 * @generated from protobuf message brandgpt.message.Message
 */
export interface Message {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string channel_id = 2 [json_name = "channel_id"];
     */
    channel_id: string;
    /**
     * @generated from protobuf field: string body = 3;
     */
    body: string;
    /**
     * @generated from protobuf field: brandgpt.Direction direction = 4;
     */
    direction: Direction;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string account_id = 6 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string profile_id = 7 [json_name = "profile_id"];
     */
    profile_id: string;
    /**
     * @generated from protobuf field: repeated uint32 page_number = 8 [json_name = "page_number"];
     */
    page_number: number[];
}
/**
 * @generated from protobuf message brandgpt.message.MessagesSearchRequest
 */
export interface MessagesSearchRequest {
    /**
     * @generated from protobuf field: string channel_id = 1 [json_name = "channel_id"];
     */
    channel_id: string;
    /**
     * @generated from protobuf field: int64 offset = 2;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: int64 limit = 3;
     */
    limit: bigint;
}
/**
 * @generated from protobuf message brandgpt.message.MessagesSearchResponse
 */
export interface MessagesSearchResponse {
    /**
     * @generated from protobuf field: repeated brandgpt.message.Message items = 1;
     */
    items: Message[];
    /**
     * @generated from protobuf field: brandgpt.message.MessagesSearchRequest next = 2;
     */
    next?: MessagesSearchRequest;
}
/**
 * @generated from protobuf message brandgpt.message.MessageCreateRequest
 */
export interface MessageCreateRequest {
    /**
     * @generated from protobuf field: brandgpt.message.Message message = 1;
     */
    message?: Message;
}
/**
 * @generated from protobuf message brandgpt.message.MessageCreateResponse
 */
export interface MessageCreateResponse {
    /**
     * @generated from protobuf field: brandgpt.message.Message message = 1;
     */
    message?: Message;
}
// @generated message type with reflection information, may provide speed optimized methods
class Message$Type extends MessageType<Message> {
    constructor() {
        super("brandgpt.message.Message", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "channel_id", kind: "scalar", localName: "channel_id", jsonName: "channel_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "direction", kind: "enum", T: () => ["brandgpt.Direction", Direction] },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "profile_id", kind: "scalar", localName: "profile_id", jsonName: "profile_id", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", localName: "page_number", jsonName: "page_number", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Message>): Message {
        const message = { id: "", channel_id: "", body: "", direction: 0, created_at: "", account_id: "", profile_id: "", page_number: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Message>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Message): Message {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string channel_id = 2 [json_name = "channel_id"];*/ 2:
                    message.channel_id = reader.string();
                    break;
                case /* string body */ 3:
                    message.body = reader.string();
                    break;
                case /* brandgpt.Direction direction */ 4:
                    message.direction = reader.int32();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string account_id = 6 [json_name = "account_id"];*/ 6:
                    message.account_id = reader.string();
                    break;
                case /* string profile_id = 7 [json_name = "profile_id"];*/ 7:
                    message.profile_id = reader.string();
                    break;
                case /* repeated uint32 page_number = 8 [json_name = "page_number"];*/ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.page_number.push(reader.uint32());
                    else
                        message.page_number.push(reader.uint32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Message, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string channel_id = 2 [json_name = "channel_id"]; */
        if (message.channel_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.channel_id);
        /* string body = 3; */
        if (message.body !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.body);
        /* brandgpt.Direction direction = 4; */
        if (message.direction !== 0)
            writer.tag(4, WireType.Varint).int32(message.direction);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string account_id = 6 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.account_id);
        /* string profile_id = 7 [json_name = "profile_id"]; */
        if (message.profile_id !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.profile_id);
        /* repeated uint32 page_number = 8 [json_name = "page_number"]; */
        if (message.page_number.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.page_number.length; i++)
                writer.uint32(message.page_number[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.message.Message
 */
export const Message = new Message$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessagesSearchRequest$Type extends MessageType<MessagesSearchRequest> {
    constructor() {
        super("brandgpt.message.MessagesSearchRequest", [
            { no: 1, name: "channel_id", kind: "scalar", localName: "channel_id", jsonName: "channel_id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<MessagesSearchRequest>): MessagesSearchRequest {
        const message = { channel_id: "", offset: 0n, limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessagesSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessagesSearchRequest): MessagesSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id = 1 [json_name = "channel_id"];*/ 1:
                    message.channel_id = reader.string();
                    break;
                case /* int64 offset */ 2:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* int64 limit */ 3:
                    message.limit = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessagesSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string channel_id = 1 [json_name = "channel_id"]; */
        if (message.channel_id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channel_id);
        /* int64 offset = 2; */
        if (message.offset !== 0n)
            writer.tag(2, WireType.Varint).int64(message.offset);
        /* int64 limit = 3; */
        if (message.limit !== 0n)
            writer.tag(3, WireType.Varint).int64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.message.MessagesSearchRequest
 */
export const MessagesSearchRequest = new MessagesSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessagesSearchResponse$Type extends MessageType<MessagesSearchResponse> {
    constructor() {
        super("brandgpt.message.MessagesSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Message },
            { no: 2, name: "next", kind: "message", T: () => MessagesSearchRequest }
        ]);
    }
    create(value?: PartialMessage<MessagesSearchResponse>): MessagesSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessagesSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessagesSearchResponse): MessagesSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandgpt.message.Message items */ 1:
                    message.items.push(Message.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandgpt.message.MessagesSearchRequest next */ 2:
                    message.next = MessagesSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessagesSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandgpt.message.Message items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Message.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandgpt.message.MessagesSearchRequest next = 2; */
        if (message.next)
            MessagesSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.message.MessagesSearchResponse
 */
export const MessagesSearchResponse = new MessagesSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageCreateRequest$Type extends MessageType<MessageCreateRequest> {
    constructor() {
        super("brandgpt.message.MessageCreateRequest", [
            { no: 1, name: "message", kind: "message", T: () => Message }
        ]);
    }
    create(value?: PartialMessage<MessageCreateRequest>): MessageCreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageCreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageCreateRequest): MessageCreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandgpt.message.Message message */ 1:
                    message.message = Message.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageCreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandgpt.message.Message message = 1; */
        if (message.message)
            Message.internalBinaryWrite(message.message, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.message.MessageCreateRequest
 */
export const MessageCreateRequest = new MessageCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageCreateResponse$Type extends MessageType<MessageCreateResponse> {
    constructor() {
        super("brandgpt.message.MessageCreateResponse", [
            { no: 1, name: "message", kind: "message", T: () => Message }
        ]);
    }
    create(value?: PartialMessage<MessageCreateResponse>): MessageCreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageCreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageCreateResponse): MessageCreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandgpt.message.Message message */ 1:
                    message.message = Message.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageCreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandgpt.message.Message message = 1; */
        if (message.message)
            Message.internalBinaryWrite(message.message, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.message.MessageCreateResponse
 */
export const MessageCreateResponse = new MessageCreateResponse$Type();

import * as svg from "./svg"
import * as theme from "layouts/theme"

export const filter = (props: svg.SVGProps) => {
	const { fill, fillOpacity, stroke, ...rest } = props
	return (
		<svg.SVG width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M6.25001 10.5967V10.6299L6.2544 10.6627C6.25592 10.6741 6.25467 10.687 6.25098 10.6979C6.24928 10.7029 6.24741 10.7064 6.24601 10.7086C6.24466 10.7107 6.24379 10.7115 6.24371 10.7116L6.23514 10.7194L6.22693 10.7276C6.21941 10.7352 6.21047 10.7412 6.20064 10.7452L6.39221 11.2071L6.20063 10.7452C6.1908 10.7493 6.18025 10.7514 6.1696 10.7514C6.15895 10.7514 6.1484 10.7493 6.13857 10.7452L5.94698 11.2071L6.13856 10.7452C6.12872 10.7412 6.11979 10.7352 6.11227 10.7276L6.1119 10.7273L3.77274 8.38811L3.77276 8.38809L3.7687 8.38412C3.76022 8.37583 3.75377 8.36569 3.74986 8.35449C3.74594 8.34329 3.74467 8.33134 3.74615 8.31957L3.75002 8.28866V8.2575V5.27083V5.09865L3.644 4.96297L0.850297 1.38774C0.850253 1.38768 0.85021 1.38763 0.850167 1.38757C0.836725 1.37022 0.830666 1.34827 0.833312 1.32648C0.835718 1.30667 0.84514 1.28845 0.859758 1.27507C0.877129 1.26316 0.890479 1.25694 0.899248 1.25377C0.908765 1.25033 0.914071 1.25 0.916682 1.25H9.08335C9.08596 1.25 9.09127 1.25033 9.10078 1.25377C9.10955 1.25694 9.1229 1.26316 9.14027 1.27507C9.15489 1.28845 9.16431 1.30667 9.16672 1.32648C9.16937 1.34829 9.16329 1.37026 9.14983 1.38762C9.14979 1.38766 9.14976 1.3877 9.14973 1.38774L6.35603 4.96297L6.25001 5.09865V5.27083V10.5967ZM2.10668 1.41667H1.08046L1.71293 2.22482L3.91668 5.04073V8.08833V8.29544L4.06313 8.44189L5.22979 9.60855L6.08335 10.4621V9.255V5.03516L8.2868 2.2252L8.92082 1.41667H7.89335H2.10668Z"
				fill={fill}
				fillOpacity={fillOpacity}
				stroke={stroke}
			/>
		</svg.SVG>
	)
}

filter.defaultProps = {
	fill: theme.colors.grey.dark50,
	fillOpacity: "0.8",
	stroke: "#5C6370",
}

export const arrowDown = (props: svg.SVGProps) => {
	const { strokeOpacity, stroke, ...rest } = props
	return (
		<svg.SVG width="15px" height="8px" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path d="M1 0.75L7.5 7.25L14 0.75" stroke={stroke} strokeOpacity={strokeOpacity} strokeLinecap="round" />
		</svg.SVG>
	)
}

arrowDown.defaultProps = {
	strokeOpacity: "0.8",
	stroke: theme.colors.grey.dark50,
}

export const zeroIcon = (props: svg.SVGProps) => {
	const { ...rest } = props
	return (
		<svg.SVG width="202" height="202" viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<g clipPath="url(#clip0_494_1729)">
				<path
					opacity="0.4"
					d="M45.1932 90.3711C70.1527 90.3711 90.3864 70.1409 90.3864 45.1856C90.3864 20.2303 70.1527 0 45.1932 0C20.2337 0 0 20.2303 0 45.1856C0 70.1409 20.2337 90.3711 45.1932 90.3711Z"
					fill="#D0D2DD"
				/>
				<path
					opacity="0.4"
					d="M164.818 164.79C185.372 164.79 202.034 148.131 202.034 127.581C202.034 107.03 185.372 90.3711 164.818 90.3711C144.265 90.3711 127.602 107.03 127.602 127.581C127.602 148.131 144.265 164.79 164.818 164.79Z"
					fill="#D0D2DD"
				/>
				<path
					opacity="0.4"
					d="M66.4546 202C82.6026 202 95.6932 188.911 95.6932 172.766C95.6932 156.621 82.6026 143.532 66.4546 143.532C50.3065 143.532 37.2159 156.621 37.2159 172.766C37.2159 188.911 50.3065 202 66.4546 202Z"
					fill="#D0D2DD"
				/>
				<path
					d="M178.068 10.6455H55.8068C51.401 10.6455 47.8295 14.2165 47.8295 18.6214V119.604C47.8295 124.009 51.401 127.58 55.8068 127.58H178.068C182.474 127.58 186.045 124.009 186.045 119.604V18.6214C186.045 14.2165 182.474 10.6455 178.068 10.6455Z"
					fill="white"
				/>
				<path
					d="M178.068 21.2578H87.7159C83.3102 21.2578 79.7386 24.8288 79.7386 29.2337V119.571C79.7386 123.976 83.3102 127.547 87.7159 127.547H178.068C182.474 127.547 186.045 123.976 186.045 119.571V29.2337C186.045 24.8288 182.474 21.2578 178.068 21.2578Z"
					fill="#99C1FF"
				/>
				<path
					d="M178.068 129.566H55.8068C50.2946 129.566 45.8438 125.081 45.8438 119.604V18.6215C45.8438 13.1445 50.3288 8.66016 55.8068 8.66016H178.068C183.58 8.66016 188.031 13.1445 188.031 18.6215V119.604C188.031 125.081 183.546 129.566 178.068 129.566ZM55.8068 12.631C52.52 12.631 49.8153 15.3011 49.8153 18.6215V119.604C49.8153 122.891 52.4858 125.595 55.8068 125.595H178.068C181.355 125.595 184.06 122.925 184.06 119.604V18.6215C184.06 15.3353 181.389 12.631 178.068 12.631H55.8068Z"
					fill="#1E2F94"
				/>
				<path
					d="M162.148 37.21H39.8865C35.4807 37.21 31.9092 40.7809 31.9092 45.1859V146.169C31.9092 150.574 35.4807 154.145 39.8865 154.145H162.148C166.554 154.145 170.125 150.574 170.125 146.169V45.1859C170.125 40.7809 166.554 37.21 162.148 37.21Z"
					fill="white"
				/>
				<path
					d="M162.148 156.13H39.8522C34.34 156.13 29.8892 151.646 29.8892 146.169V45.186C29.8892 39.7089 34.3742 35.2246 39.8522 35.2246H162.148C167.66 35.2246 172.111 39.7089 172.111 45.186V146.169C172.111 151.646 167.626 156.13 162.148 156.13ZM39.8522 39.1955C36.5654 39.1955 33.8607 41.8655 33.8607 45.186V146.169C33.8607 149.455 36.5312 152.159 39.8522 152.159H162.148C165.435 152.159 168.139 149.489 168.139 146.169V45.186C168.139 41.8997 165.469 39.1955 162.148 39.1955H39.8522Z"
					fill="#1E2F94"
				/>
				<path
					d="M146.193 63.7734H23.9319C19.5261 63.7734 15.9546 67.3444 15.9546 71.7494V172.732C15.9546 177.137 19.5261 180.708 23.9319 180.708H146.193C150.599 180.708 154.171 177.137 154.171 172.732V71.7494C154.171 67.3444 150.599 63.7734 146.193 63.7734Z"
					fill="white"
				/>
				<path
					d="M146.159 63.7734H55.8068C51.401 63.7734 47.8295 67.3444 47.8295 71.7494V172.732C47.8295 177.137 51.401 180.708 55.8068 180.708H146.159C150.565 180.708 154.136 177.137 154.136 172.732V71.7494C154.136 67.3444 150.565 63.7734 146.159 63.7734Z"
					fill="#99C1FF"
				/>
				<path
					d="M146.193 182.728H23.9319C18.4197 182.728 13.9689 178.244 13.9689 172.767V71.7494C13.9689 66.2724 18.454 61.7881 23.9319 61.7881H146.193C151.705 61.7881 156.156 66.2724 156.156 71.7494V172.732C156.156 178.209 151.671 182.694 146.193 182.694V182.728ZM23.9319 65.7932C20.6451 65.7932 17.9404 68.4632 17.9404 71.7837V172.767C17.9404 176.053 20.6109 178.757 23.9319 178.757H146.193C149.48 178.757 152.185 176.087 152.185 172.767V71.7494C152.185 68.4632 149.514 65.7589 146.193 65.7589H23.9319V65.7932Z"
					fill="#1E2F94"
				/>
				<path d="M140.886 77.0889H29.2386V167.46H140.886V77.0889Z" fill="white" />
				<path
					d="M140.852 169.411H29.2387C28.1431 169.411 27.2529 168.521 27.2529 167.426V77.0889C27.2529 75.9935 28.1431 75.1035 29.2387 75.1035H140.852C141.948 75.1035 142.838 75.9935 142.838 77.0889V167.46C142.838 168.555 141.948 169.445 140.852 169.445V169.411ZM31.2245 165.44H138.866V79.0744H31.2245V165.44Z"
					fill="#1E2F94"
				/>
				<path
					d="M74.4319 111.629C80.3125 111.629 85.0797 106.863 85.0797 100.983C85.0797 95.1033 80.3125 90.3369 74.4319 90.3369C68.5512 90.3369 63.7841 95.1033 63.7841 100.983C63.7841 106.863 68.5512 111.629 74.4319 111.629Z"
					fill="white"
				/>
				<path
					d="M85.0454 100.983C85.0454 96.5326 82.3064 92.7329 78.4034 91.124C74.5003 92.6987 71.7614 96.4984 71.7614 100.983C71.7614 105.467 74.5003 109.232 78.4034 110.807C82.3064 109.232 85.0454 105.433 85.0454 100.983Z"
					fill="#99C1FF"
				/>
				<path
					d="M74.4319 113.614C67.4817 113.614 61.7983 107.966 61.7983 100.983C61.7983 93.9998 67.4475 88.3516 74.4319 88.3516C81.4163 88.3516 87.0655 93.9998 87.0655 100.983C87.0655 107.966 81.4163 113.614 74.4319 113.614ZM74.4319 92.3566C69.6729 92.3566 65.8041 96.2248 65.8041 100.983C65.8041 105.741 69.6729 109.609 74.4319 109.609C79.1909 109.609 83.0597 105.741 83.0597 100.983C83.0597 96.2248 79.1909 92.3566 74.4319 92.3566Z"
					fill="#1E2F94"
				/>
				<path
					d="M42.5227 89.6867C43.6195 89.6867 44.5085 88.7978 44.5085 87.7012C44.5085 86.6047 43.6195 85.7158 42.5227 85.7158C41.426 85.7158 40.537 86.6047 40.537 87.7012C40.537 88.7978 41.426 89.6867 42.5227 89.6867Z"
					fill="#1E2F94"
				/>
				<path
					d="M53.1705 97.6623C54.2672 97.6623 55.1562 96.7734 55.1562 95.6768C55.1562 94.5803 54.2672 93.6914 53.1705 93.6914C52.0737 93.6914 51.1847 94.5803 51.1847 95.6768C51.1847 96.7734 52.0737 97.6623 53.1705 97.6623Z"
					fill="#1E2F94"
				/>
				<path
					d="M95.6933 105.639C96.79 105.639 97.679 104.75 97.679 103.653C97.679 102.557 96.79 101.668 95.6933 101.668C94.5966 101.668 93.7075 102.557 93.7075 103.653C93.7075 104.75 94.5966 105.639 95.6933 105.639Z"
					fill="#1E2F94"
				/>
				<path
					d="M106.307 89.6867C107.404 89.6867 108.293 88.7978 108.293 87.7012C108.293 86.6047 107.404 85.7158 106.307 85.7158C105.21 85.7158 104.321 86.6047 104.321 87.7012C104.321 88.7978 105.21 89.6867 106.307 89.6867Z"
					fill="#1E2F94"
				/>
				<path
					d="M130.239 97.6623C131.335 97.6623 132.224 96.7734 132.224 95.6768C132.224 94.5803 131.335 93.6914 130.239 93.6914C129.142 93.6914 128.253 94.5803 128.253 95.6768C128.253 96.7734 129.142 97.6623 130.239 97.6623Z"
					fill="#1E2F94"
				/>
				<path d="M85.696 124.397L53.821 167.426H117.605L85.696 124.397Z" fill="white" />
				<path d="M96.2068 138.535L91.3793 140.452L71.3848 167.427H117.605L96.2068 138.535Z" fill="#99C1FF" />
				<path
					d="M117.605 169.411H53.821C53.0677 169.411 52.383 169.001 52.0406 168.316C51.6983 167.631 51.7667 166.844 52.2118 166.228L84.121 123.199C84.8742 122.172 86.586 122.172 87.3393 123.199L119.248 166.228C119.694 166.844 119.762 167.631 119.42 168.316C119.077 169.001 118.392 169.411 117.639 169.411H117.605ZM57.7583 165.441H113.633L85.6959 127.752L57.7583 165.441Z"
					fill="#1E2F94"
				/>
				<path d="M48.0007 110.842L29.2386 148.873V167.426H75.9041L48.0007 110.842Z" fill="white" />
				<path d="M58.58 132.305L51.4244 135.831L35.8464 167.426H75.9041L58.58 132.305Z" fill="#99C1FF" />
				<path
					d="M75.9041 169.412H29.2387C28.1431 169.412 27.2529 168.522 27.2529 167.427V148.873C27.2529 148.565 27.3214 148.257 27.4584 147.983L46.2204 109.918C46.5628 109.233 47.2475 108.822 48.0007 108.822C48.7539 108.822 49.4387 109.267 49.7811 109.918L77.6845 166.502C77.9926 167.118 77.9584 167.837 77.5817 168.454C77.2051 169.035 76.5889 169.378 75.9041 169.378V169.412ZM31.2587 165.441H72.72L48.035 115.326L31.2587 149.318V165.407V165.441Z"
					fill="#1E2F94"
				/>
				<path
					d="M85.6959 144.663C85.4562 144.663 85.2166 144.628 84.9769 144.526L74.5003 140.418C73.4732 140.007 72.9596 138.843 73.3705 137.816C73.7813 136.789 74.9454 136.276 75.9383 136.687L85.6959 140.521L95.4535 136.687C96.4806 136.276 97.6447 136.789 98.0213 137.816C98.4322 138.843 97.9186 140.007 96.8915 140.418L86.4149 144.526C86.1752 144.628 85.9356 144.663 85.6959 144.663Z"
					fill="#1E2F94"
				/>
				<path d="M117.605 97.1494L87.5448 167.427H140.852V151.543L117.605 97.1494Z" fill="white" />
				<path d="M120.31 125.287L102.267 167.426H140.852V151.543L127.945 121.351L120.31 125.287Z" fill="#99C1FF" />
				<path
					d="M140.852 169.412H87.5447C86.86 169.412 86.2437 169.069 85.8671 168.522C85.4905 167.974 85.422 167.255 85.6959 166.639L115.756 96.3618C116.373 94.8898 118.803 94.8898 119.42 96.3618L142.701 150.756C142.804 150.995 142.872 151.269 142.872 151.543V167.426C142.872 167.94 142.667 168.453 142.29 168.83C141.914 169.206 141.4 169.412 140.886 169.412H140.852ZM90.5576 165.441H138.866V151.954L117.605 102.215L90.5576 165.441Z"
					fill="#1E2F94"
				/>
				<path
					d="M47.8295 139.596C47.5214 139.596 47.2132 139.528 46.9393 139.391L36.4969 134.085C35.5041 133.572 35.1275 132.374 35.641 131.415C36.1546 130.422 37.3529 130.046 38.3115 130.559L47.8637 135.42L57.6898 130.559C58.6827 130.08 59.881 130.457 60.3603 131.449C60.8397 132.442 60.463 133.64 59.4702 134.119L48.7539 139.425C48.48 139.562 48.1719 139.631 47.8637 139.631L47.8295 139.596Z"
					fill="#1E2F94"
				/>
				<path
					d="M117.605 128.676C117.297 128.676 116.989 128.608 116.681 128.471L106.341 123.165C105.348 122.652 104.972 121.454 105.485 120.495C105.999 119.502 107.197 119.126 108.156 119.639L117.605 124.5L127.055 119.639C128.048 119.126 129.246 119.537 129.725 120.495C130.239 121.488 129.828 122.686 128.869 123.165L118.53 128.471C118.256 128.608 117.947 128.676 117.605 128.676Z"
					fill="#1E2F94"
				/>
				<path
					d="M135.545 57.817C134.45 57.817 133.56 56.927 133.56 55.8316V45.1856C133.56 44.0902 134.45 43.2002 135.545 43.2002C136.641 43.2002 137.531 44.0902 137.531 45.1856V55.8316C137.531 56.927 136.641 57.817 135.545 57.817Z"
					fill="#1E2F94"
				/>
				<path
					d="M140.852 52.4767H130.204C129.109 52.4767 128.219 51.5867 128.219 50.4913C128.219 49.3959 129.109 48.5059 130.204 48.5059H140.852C141.948 48.5059 142.838 49.3959 142.838 50.4913C142.838 51.5867 141.948 52.4767 140.852 52.4767Z"
					fill="#1E2F94"
				/>
				<path
					d="M119.591 52.4767C120.688 52.4767 121.577 51.5878 121.577 50.4913C121.577 49.3948 120.688 48.5059 119.591 48.5059C118.494 48.5059 117.605 49.3948 117.605 50.4913C117.605 51.5878 118.494 52.4767 119.591 52.4767Z"
					fill="#1E2F94"
				/>
				<path
					d="M111.614 52.4767C112.71 52.4767 113.599 51.5878 113.599 50.4913C113.599 49.3948 112.71 48.5059 111.614 48.5059C110.517 48.5059 109.628 49.3948 109.628 50.4913C109.628 51.5878 110.517 52.4767 111.614 52.4767Z"
					fill="#1E2F94"
				/>
				<path
					d="M101 52.4767C102.097 52.4767 102.986 51.5878 102.986 50.4913C102.986 49.3948 102.097 48.5059 101 48.5059C99.9033 48.5059 99.0143 49.3948 99.0143 50.4913C99.0143 51.5878 99.9033 52.4767 101 52.4767Z"
					fill="#1E2F94"
				/>
				<path
					d="M85.0455 52.4767C86.1422 52.4767 87.0312 51.5878 87.0312 50.4913C87.0312 49.3948 86.1422 48.5059 85.0455 48.5059C83.9487 48.5059 83.0597 49.3948 83.0597 50.4913C83.0597 51.5878 83.9487 52.4767 85.0455 52.4767Z"
					fill="#1E2F94"
				/>
				<path
					d="M74.4319 25.9132H63.7841C62.6885 25.9132 61.7983 25.0232 61.7983 23.9278C61.7983 22.8324 62.6885 21.9424 63.7841 21.9424H74.4319C75.5275 21.9424 76.4177 22.8324 76.4177 23.9278C76.4177 25.0232 75.5275 25.9132 74.4319 25.9132Z"
					fill="#1E2F94"
				/>
				<path
					d="M69.125 31.2194C68.0295 31.2194 67.1393 30.3294 67.1393 29.234V18.588C67.1393 17.4926 68.0295 16.6025 69.125 16.6025C70.2206 16.6025 71.1108 17.4926 71.1108 18.588V29.234C71.1108 30.3294 70.2206 31.2194 69.125 31.2194Z"
					fill="#1E2F94"
				/>
				<path
					d="M29.2387 26.5639C32.1695 26.5639 34.5454 24.1884 34.5454 21.258C34.5454 18.3277 32.1695 15.9521 29.2387 15.9521C26.3078 15.9521 23.9319 18.3277 23.9319 21.258C23.9319 24.1884 26.3078 26.5639 29.2387 26.5639Z"
					fill="white"
				/>
				<path
					d="M29.2386 28.5836C25.1986 28.5836 21.946 25.2974 21.946 21.2923C21.946 17.2872 25.2328 14.001 29.2386 14.001C33.2444 14.001 36.5311 17.2872 36.5311 21.2923C36.5311 25.2974 33.2444 28.5836 29.2386 28.5836ZM29.2386 17.9376C27.3898 17.9376 25.9176 19.4438 25.9176 21.2581C25.9176 23.0723 27.424 24.5785 29.2386 24.5785C31.0532 24.5785 32.5596 23.0723 32.5596 21.2581C32.5596 19.4438 31.0532 17.9376 29.2386 17.9376Z"
					fill="#1E2F94"
				/>
				<path
					d="M172.796 180.708C175.726 180.708 178.102 178.333 178.102 175.403C178.102 172.472 175.726 170.097 172.796 170.097C169.865 170.097 167.489 172.472 167.489 175.403C167.489 178.333 169.865 180.708 172.796 180.708Z"
					fill="white"
				/>
				<path
					d="M172.795 182.728C168.755 182.728 165.503 179.442 165.503 175.437C165.503 171.432 168.79 168.146 172.795 168.146C176.801 168.146 180.088 171.432 180.088 175.437C180.088 179.442 176.801 182.728 172.795 182.728ZM172.795 172.082C170.947 172.082 169.474 173.588 169.474 175.403C169.474 177.217 170.981 178.723 172.795 178.723C174.61 178.723 176.116 177.217 176.116 175.403C176.116 173.588 174.61 172.082 172.795 172.082Z"
					fill="#1E2F94"
				/>
			</g>
			<defs>
				<clipPath id="clip0_494_1729">
					<rect width="202" height="202" fill="white" />
				</clipPath>
			</defs>
		</svg.SVG>
	)
}

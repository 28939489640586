import * as authzc from "authzcached"
import * as api from "brandguard/api"
import * as brandguide from "brandguide"
import { uploads } from "brandguard/display/training"
import * as caching from "brandguard/display/training/cache"
import * as httpx from "httpx"

export const useDataToTrainingImg = (
	onBrand: brandguide.api.ImageryObject[],
	offBrand: brandguide.api.ImageryObject[],
) => {
	const guideObj = brandguide.cache.useGuide()
	const cachekey = `imguploads.${guideObj.brand_id}`
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const delay = uploads.next(cachekey)

	const passOnBrand = () => {
		if (onBrand.length === 0) return
		const req = {
			md5s: onBrand.map((i) => i.contentdigest),
			observation: api.Prediction.APPROVED,
			delay: BigInt(delay.toMillis().toFixed(0)),
		}
		const retry = httpx.autoretry()
		return retry
			.wrap(() => api.images.training.media_upload(req, guideObj.brand_id, authzc.bearer(authzaccount)))
			.then((resp) =>
				resp.items.forEach((i) => {
					if (i.media?.id) caching.uploads.img.add(cachekey, [i])
				}),
			)
	}

	const passOffBrand = () => {
		if (offBrand.length === 0) return
		const req = {
			md5s: offBrand.map((i) => i.contentdigest),
			observation: api.Prediction.REJECTED,
			delay: BigInt(delay.toMillis().toFixed(0)),
		}
		const retry = httpx.autoretry()
		return retry
			.wrap(() => api.images.training.media_upload(req, guideObj.brand_id, authzc.bearer(authzaccount)))
			.then((resp) =>
				resp.items.forEach((i) => {
					if (i.media?.id) caching.uploads.img.add(cachekey, [i])
				}),
			)
	}

	const passImgDataToTraining = () => {
		passOnBrand()
		passOffBrand()
	}

	return { passImgDataToTraining }
}

export const useDataToTrainingText = (onBrand: string[]) => {
	const guideObj = brandguide.cache.useGuide()
	const cachekey = `textuploads.${guideObj.brand_id}`
	const authzaccount = authzc.useCache((cached) => cached.meta)

	const passOnBrand = () => {
		if (onBrand.length === 0) return
		onBrand.forEach((text) => {
			const file = new File([new Blob([text])], "BrandGuide Bulder onBrand text", { type: "text/plain" })
			const data = new FormData()
			data.append("observation", api.Prediction.APPROVED.toString())
			return api.text.training.upload(data, file, guideObj.brand_id, authzc.bearer(authzaccount)).then((resp) => {
				caching.uploads.text.add(cachekey, [{ description: file.name, ...resp }])
				return resp
			})
		})
	}

	const passTextDataToTraining = () => {
		passOnBrand()
	}

	return { passTextDataToTraining }
}

import React from "react"
import { Route, Routes } from "react-router-dom"
import * as layouts from "layouts"
import * as selector from "brands/selector"
import * as typography from "typography"
import * as routing from "./routing"
import { Display } from "."

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<layouts.containers.flex className="brands" flex="1" overflowY="hidden">
			<layouts.containers.flex className="body-container" flex="1 1">
				<Routes>
					<Route
						path="*"
						element={
							<Container urlgen={routing.root}>
								<Display />
							</Container>
						}
					/>
				</Routes>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

function Container(props: React.PropsWithChildren<selector.props>): JSX.Element {
	const { children, urlgen } = props

	return (
		<layouts.backgrounds.Grey className="brandguard-container" flex="1">
			<layouts.containers.flex justifyContent="center" flex="1">
				<layouts.containers.flex
					px="50px"
					pt="25px"
					className="brandguard"
					flexDirection="column"
					flex="1"
					overflowY="hidden"
				>
					<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="60px">
						<layouts.containers.flex flex="2">
							<typography.h3 color={layouts.theme.colors.grey.medium} fontWeight="700">
								Notifications
							</typography.h3>
						</layouts.containers.flex>
						<layouts.containers.flex flex="2" justifyContent="end" height="48px">
							<selector.BrandSelector width="30%" urlgen={urlgen} />
						</layouts.containers.flex>
					</layouts.containers.flex>
					{children}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

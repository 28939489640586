import { useState, useEffect } from "react"
import * as brandguide from "brandguide"
import * as res_components from "brandguide/results/components"

interface props {
	itemsOnPage: number
	onLoad(): void
}

export default function Display(props: props): JSX.Element {
	const { itemsOnPage, onLoad } = props

	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = brandguide.api.zeros.guide.guideImageryZero(guide.guide?.imagery)

	const sectionDescription =
		"Lifestyle images are more than just pictures; they are stories that reflect the values, aspirations, and ethos of our brand. They provide context, evoke emotions, and create a connection between our brand and our audience. In this section you will find a collection of lifestyle images that have been thoughtfully curated to align with our brand's narrative and aesthetic. Each image has been captured and edited with our brand's color palette, design principles, and visual guidelines in mind, ensuring a consistent and cohesive visual identity across all our materials."
	const items = res_components.chunkImageItems(data.lifestyle?.items || [], itemsOnPage)
	const [loadedImages, setLoadedImages] = useState(items.length)

	useEffect(() => {
		if (loadedImages !== 0) return
		onLoad()
	}, [loadedImages])

	if (!brandguide.validations.imagery.valid.lifestyle(data)) return <></>

	return (
		<>
			{items.map((i, index) => {
				const firstPage = index === 0
				const pageTitle = firstPage ? "Lifestyle Photography" : undefined
				const sectionDesc = firstPage ? sectionDescription : undefined
				const explanation = firstPage ? data.descriptions?.lifestyle : undefined
				return (
					<res_components.ImagesPage
						key={index}
						items={i}
						sectionTitle="Lifestyle Photography"
						sectionDescription={sectionDesc}
						pageTitle={pageTitle}
						explanation={explanation}
						onLoad={() => setLoadedImages((prev) => prev - 1)}
						className="section-print"
					/>
				)
			})}
		</>
	)
}

Display.defaultProps = {
	itemsOnPage: 9,
}

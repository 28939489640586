import * as layouts from "layouts"
import * as routing from "brandguard/routing"
import * as bgapprovals from "brandguard/display/approval"

export function upload(): JSX.Element {
	return (
		<bgapprovals.Container urlgen={routing.text.approval.upload}>
			<layouts.containers.flex
				flexDirection="column"
				height="100%"
				overflow="hidden"
				data-testid="text-brandguard-upload"
			>
				<bgapprovals.TextActiveLink urlgen={routing.image.approval.upload} />
				<bgapprovals.navigation.TextsNav />
				<bgapprovals.texts.UploadDisplay />
			</layouts.containers.flex>
		</bgapprovals.Container>
	)
}

export function review(): JSX.Element {
	return (
		<bgapprovals.Container urlgen={routing.text.approval.review}>
			<layouts.containers.flex
				flexDirection="column"
				height="100%"
				overflow="hidden"
				data-testid="text-brandguard-review"
			>
				<bgapprovals.TextActiveLink urlgen={routing.image.approval.review} />
				<bgapprovals.navigation.TextsNav />
				<bgapprovals.texts.ReviewDisplay />
			</layouts.containers.flex>
		</bgapprovals.Container>
	)
}

export function approved(): JSX.Element {
	return (
		<bgapprovals.Container urlgen={routing.text.approval.approved}>
			<layouts.containers.flex
				flexDirection="column"
				height="100%"
				overflow="hidden"
				data-testid="text-brandguard-approved"
			>
				<bgapprovals.TextActiveLink urlgen={routing.image.approval.approved} />
				<bgapprovals.navigation.TextsNav />
				<bgapprovals.texts.ApprovedDisplay />
			</layouts.containers.flex>
		</bgapprovals.Container>
	)
}

export function rejected(): JSX.Element {
	return (
		<bgapprovals.Container urlgen={routing.text.approval.rejected}>
			<layouts.containers.flex
				flexDirection="column"
				height="100%"
				overflow="hidden"
				data-testid="text-brandguard-rejected"
			>
				<bgapprovals.TextActiveLink urlgen={routing.image.approval.rejected} />
				<bgapprovals.navigation.TextsNav />
				<bgapprovals.texts.RejectedDisplay />
			</layouts.containers.flex>
		</bgapprovals.Container>
	)
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as brandguide from "brandguide"
import * as icons from "icons"
import { css } from "@emotion/css"

type pages = "Upload"

export const steps = ["Upload"] as pages[]

export const styledGap5 = css`
	.content {
		gap: 5px;
	}
`

export const styledGap60 = css`
	.content {
		gap: 60px;
	}
`

export const slyledDropWell = css`
	.styled-drop-area {
		padding: 10px;
		min-height: 150px;
	}
	.upload-icon {
		width: 50px;
		height: 50px;
		svg {
			width: 25px;
			height: 25px;
		}
	}
`

interface imageProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ApplicationObject
	onDelete(): void
}

export function Image(props: imageProps): JSX.Element {
	const { item, onDelete, height, width, ...rest } = props
	const [focused, setFocused] = useState(false)

	const parentRest = { height, width }

	return (
		<layouts.Flex
			onMouseEnter={() => setFocused(true)}
			onMouseLeave={() => setFocused(false)}
			{...rest}
			{...parentRest}
		>
			<brandguide.build.layouts.Image
				contentdigest={item.thumbnail_contentdigest}
				styles={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }}
			/>

			{focused && (
				<layouts.Flex position="absolute" {...parentRest}>
					<layouts.Flex flex="1" flexDirection="column" alignItems="end" p="5px">
						<layouts.Flex onClick={onDelete} mb="6px">
							<icons.Close
								width="20px"
								height="20px"
								stroke={layouts.theme.colors.white}
								fill={layouts.theme.colors.grey.dark50}
							/>
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
			)}
		</layouts.Flex>
	)
}

Image.defaultProps = {
	height: "150px",
	width: "150px",
	alignItems: "center",
	justifyContent: "center",
	position: "relative",
}

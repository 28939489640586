/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable,generate_dependencies
// @generated from protobuf file "meta.profile.proto" (package "meta", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DateRange } from "./meta.search";
/**
 * @generated from protobuf message meta.Profile
 */
export interface Profile {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string session_watermark = 3 [json_name = "session_watermark"];
     */
    session_watermark: string;
    /**
     * @generated from protobuf field: string created_at = 4 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 5 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string disabled_at = 6 [json_name = "disabled_at"];
     */
    disabled_at: string;
    /**
     * @generated from protobuf field: string disabled_manually_at = 7 [json_name = "disabled_manually_at"];
     */
    disabled_manually_at: string;
    /**
     * @generated from protobuf field: string disabled_pending_approval_at = 8 [json_name = "disabled_pending_approval_at"];
     */
    disabled_pending_approval_at: string;
    /**
     * @generated from protobuf field: string display = 9;
     */
    display: string;
    /**
     * @generated from protobuf field: string email = 10;
     */
    email: string;
    /**
     * @generated from protobuf field: string disabled_invited_at = 11 [json_name = "disabled_invited_at"];
     */
    disabled_invited_at: string;
}
/**
 * @generated from protobuf message meta.Sort
 */
export interface Sort {
    /**
     * @generated from protobuf field: meta.Sorting by = 1;
     */
    by: Sorting;
    /**
     * @generated from protobuf field: meta.Direction direction = 2;
     */
    direction: Direction;
}
/**
 * @generated from protobuf message meta.SearchRequest
 */
export interface SearchRequest {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: meta.DateRange created = 2;
     */
    created?: DateRange;
    /**
     * @generated from protobuf field: int32 enabled = 3;
     */
    enabled: number;
    /**
     * @generated from protobuf field: int64 limit = 6;
     */
    limit: bigint;
    /**
     * @generated from protobuf field: int64 offset = 7;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: meta.Sort sorted = 8;
     */
    sorted?: Sort;
}
/**
 * @generated from protobuf message meta.SearchResponse
 */
export interface SearchResponse {
    /**
     * @generated from protobuf field: repeated meta.Profile items = 1;
     */
    items: Profile[];
    /**
     * @generated from protobuf field: meta.SearchRequest next = 2;
     */
    next?: SearchRequest;
}
/**
 * @generated from protobuf message meta.CreateRequest
 */
export interface CreateRequest {
    /**
     * @generated from protobuf field: meta.Profile profile = 1;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf message meta.CreateResponse
 */
export interface CreateResponse {
    /**
     * @generated from protobuf field: meta.Profile profile = 1;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf message meta.UpdateRequest
 */
export interface UpdateRequest {
    /**
     * @generated from protobuf field: meta.Profile profile = 1;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf message meta.UpdateResponse
 */
export interface UpdateResponse {
    /**
     * @generated from protobuf field: meta.Profile profile = 1;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf message meta.DisableRequest
 */
export interface DisableRequest {
    /**
     * @generated from protobuf field: meta.Profile profile = 1;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf message meta.DisableResponse
 */
export interface DisableResponse {
    /**
     * @generated from protobuf field: meta.Profile profile = 1;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf enum meta.Sorting
 */
export enum Sorting {
    /**
     * @generated from protobuf enum value: CREATED_AT = 0;
     */
    CREATED_AT = 0,
    /**
     * @generated from protobuf enum value: UPDATED_AT = 1;
     */
    UPDATED_AT = 1
}
/**
 * @generated from protobuf enum meta.Direction
 */
export enum Direction {
    /**
     * @generated from protobuf enum value: DESC = 0;
     */
    DESC = 0,
    /**
     * @generated from protobuf enum value: ASC = 1;
     */
    ASC = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Profile$Type extends MessageType<Profile> {
    constructor() {
        super("meta.Profile", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "session_watermark", kind: "scalar", localName: "session_watermark", jsonName: "session_watermark", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "disabled_at", kind: "scalar", localName: "disabled_at", jsonName: "disabled_at", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "disabled_manually_at", kind: "scalar", localName: "disabled_manually_at", jsonName: "disabled_manually_at", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "disabled_pending_approval_at", kind: "scalar", localName: "disabled_pending_approval_at", jsonName: "disabled_pending_approval_at", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "display", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "disabled_invited_at", kind: "scalar", localName: "disabled_invited_at", jsonName: "disabled_invited_at", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Profile>): Profile {
        const message = { id: "", account_id: "", session_watermark: "", created_at: "", updated_at: "", disabled_at: "", disabled_manually_at: "", disabled_pending_approval_at: "", display: "", email: "", disabled_invited_at: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Profile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Profile): Profile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string session_watermark = 3 [json_name = "session_watermark"];*/ 3:
                    message.session_watermark = reader.string();
                    break;
                case /* string created_at = 4 [json_name = "created_at"];*/ 4:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 5 [json_name = "updated_at"];*/ 5:
                    message.updated_at = reader.string();
                    break;
                case /* string disabled_at = 6 [json_name = "disabled_at"];*/ 6:
                    message.disabled_at = reader.string();
                    break;
                case /* string disabled_manually_at = 7 [json_name = "disabled_manually_at"];*/ 7:
                    message.disabled_manually_at = reader.string();
                    break;
                case /* string disabled_pending_approval_at = 8 [json_name = "disabled_pending_approval_at"];*/ 8:
                    message.disabled_pending_approval_at = reader.string();
                    break;
                case /* string display */ 9:
                    message.display = reader.string();
                    break;
                case /* string email */ 10:
                    message.email = reader.string();
                    break;
                case /* string disabled_invited_at = 11 [json_name = "disabled_invited_at"];*/ 11:
                    message.disabled_invited_at = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Profile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string session_watermark = 3 [json_name = "session_watermark"]; */
        if (message.session_watermark !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.session_watermark);
        /* string created_at = 4 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 5 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.updated_at);
        /* string disabled_at = 6 [json_name = "disabled_at"]; */
        if (message.disabled_at !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.disabled_at);
        /* string disabled_manually_at = 7 [json_name = "disabled_manually_at"]; */
        if (message.disabled_manually_at !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.disabled_manually_at);
        /* string disabled_pending_approval_at = 8 [json_name = "disabled_pending_approval_at"]; */
        if (message.disabled_pending_approval_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.disabled_pending_approval_at);
        /* string display = 9; */
        if (message.display !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.display);
        /* string email = 10; */
        if (message.email !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.email);
        /* string disabled_invited_at = 11 [json_name = "disabled_invited_at"]; */
        if (message.disabled_invited_at !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.disabled_invited_at);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.Profile
 */
export const Profile = new Profile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sort$Type extends MessageType<Sort> {
    constructor() {
        super("meta.Sort", [
            { no: 1, name: "by", kind: "enum", T: () => ["meta.Sorting", Sorting] },
            { no: 2, name: "direction", kind: "enum", T: () => ["meta.Direction", Direction] }
        ]);
    }
    create(value?: PartialMessage<Sort>): Sort {
        const message = { by: 0, direction: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Sort>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Sort): Sort {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Sorting by */ 1:
                    message.by = reader.int32();
                    break;
                case /* meta.Direction direction */ 2:
                    message.direction = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Sort, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Sorting by = 1; */
        if (message.by !== 0)
            writer.tag(1, WireType.Varint).int32(message.by);
        /* meta.Direction direction = 2; */
        if (message.direction !== 0)
            writer.tag(2, WireType.Varint).int32(message.direction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.Sort
 */
export const Sort = new Sort$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRequest$Type extends MessageType<SearchRequest> {
    constructor() {
        super("meta.SearchRequest", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created", kind: "message", T: () => DateRange },
            { no: 3, name: "enabled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "sorted", kind: "message", T: () => Sort }
        ]);
    }
    create(value?: PartialMessage<SearchRequest>): SearchRequest {
        const message = { query: "", enabled: 0, limit: 0n, offset: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRequest): SearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* meta.DateRange created */ 2:
                    message.created = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* int32 enabled */ 3:
                    message.enabled = reader.int32();
                    break;
                case /* int64 limit */ 6:
                    message.limit = reader.int64().toBigInt();
                    break;
                case /* int64 offset */ 7:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* meta.Sort sorted */ 8:
                    message.sorted = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sorted);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* meta.DateRange created = 2; */
        if (message.created)
            DateRange.internalBinaryWrite(message.created, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 enabled = 3; */
        if (message.enabled !== 0)
            writer.tag(3, WireType.Varint).int32(message.enabled);
        /* int64 limit = 6; */
        if (message.limit !== 0n)
            writer.tag(6, WireType.Varint).int64(message.limit);
        /* int64 offset = 7; */
        if (message.offset !== 0n)
            writer.tag(7, WireType.Varint).int64(message.offset);
        /* meta.Sort sorted = 8; */
        if (message.sorted)
            Sort.internalBinaryWrite(message.sorted, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.SearchRequest
 */
export const SearchRequest = new SearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchResponse$Type extends MessageType<SearchResponse> {
    constructor() {
        super("meta.SearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Profile },
            { no: 2, name: "next", kind: "message", T: () => SearchRequest }
        ]);
    }
    create(value?: PartialMessage<SearchResponse>): SearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchResponse): SearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated meta.Profile items */ 1:
                    message.items.push(Profile.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* meta.SearchRequest next */ 2:
                    message.next = SearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated meta.Profile items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Profile.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* meta.SearchRequest next = 2; */
        if (message.next)
            SearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.SearchResponse
 */
export const SearchResponse = new SearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
    constructor() {
        super("meta.CreateRequest", [
            { no: 1, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<CreateRequest>): CreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRequest): CreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Profile profile */ 1:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Profile profile = 1; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateResponse$Type extends MessageType<CreateResponse> {
    constructor() {
        super("meta.CreateResponse", [
            { no: 1, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<CreateResponse>): CreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateResponse): CreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Profile profile */ 1:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Profile profile = 1; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.CreateResponse
 */
export const CreateResponse = new CreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
    constructor() {
        super("meta.UpdateRequest", [
            { no: 1, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRequest): UpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Profile profile */ 1:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Profile profile = 1; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateResponse$Type extends MessageType<UpdateResponse> {
    constructor() {
        super("meta.UpdateResponse", [
            { no: 1, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<UpdateResponse>): UpdateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateResponse): UpdateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Profile profile */ 1:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Profile profile = 1; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.UpdateResponse
 */
export const UpdateResponse = new UpdateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableRequest$Type extends MessageType<DisableRequest> {
    constructor() {
        super("meta.DisableRequest", [
            { no: 1, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<DisableRequest>): DisableRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DisableRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableRequest): DisableRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Profile profile */ 1:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Profile profile = 1; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.DisableRequest
 */
export const DisableRequest = new DisableRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableResponse$Type extends MessageType<DisableResponse> {
    constructor() {
        super("meta.DisableResponse", [
            { no: 1, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<DisableResponse>): DisableResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DisableResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableResponse): DisableResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Profile profile */ 1:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Profile profile = 1; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.DisableResponse
 */
export const DisableResponse = new DisableResponse$Type();

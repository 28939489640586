import React, { useState, useEffect } from "react"
import { css } from "@emotion/css"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as typography from "typography"
import * as icons from "icons"
import * as sessions from "sessions"
import * as profiles from "profiles"
import * as httpx from "httpx"
import * as errors from "errors"
import { SearchRequest } from "./brandguard.metrics_pb"
import { layouts as bgApprovalLayout, Checkbox } from "brandguard/display/approval"
import { GroupBy } from "metasearch/meta.search.filters"

export interface user {
	key: string
	name: string
}

interface filterProps {
	req: SearchRequest
	setReq: (value: React.SetStateAction<SearchRequest>) => void
	currentUser: user
	setCurrentUser: (user: user) => void
}

export const zeroUser: user = { key: uuid.NIL, name: "All users" }

const styledSearchSelect = css`
	input {
		padding-left: 5px;
		background: ${layouts.theme.backgrounds.dark50alpha20};
		color: ${layouts.theme.colors.grey.dark20};
		border: 1px solid ${layouts.theme.colors.grey.dark50alpha60};
	}
	input:hover,
	input:focus {
		outline: none;
		box-shadow: none;
	}
	ul {
		position: relative;
		z-index: 1;
		list-style-type: none;
		overflow-y: auto;
		overflow-x: hidden;
		margin-top: 2px;
		border-radius: 10px;
		border: 1px solid ${layouts.theme.colors.grey.dark50alpha05};
		font-size: 16px;
		background-color: ${layouts.theme.colors.white};

		li {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			cursor: pointer;
			padding: 7px 9px;
			border-bottom: 1px solid ${layouts.theme.colors.grey.dark50alpha05};
			color: ${layouts.theme.colors.grey.medium};
			&:hover,
			&.active {
				color: ${layouts.theme.colors.blue.blue};
			}
			:hover {
				background-color: ${layouts.theme.colors.grey.dark50alpha05};
			}
		}
	}
`

export function ApprovalFilter(props: filterProps): JSX.Element {
	const { req, setReq, currentUser, setCurrentUser } = props
	const bearertoken = sessions.useToken()

	const [loading, setLoading] = useState(true)
	const [filterFocused, setFilterFocused] = useState(false)
	const [searchFocused, setSearchFocused] = useState(false)
	const [selectKey, setSelectKey] = useState(uuid.v4())
	const profilesReqZero = profiles.api.searches.zero({ limit: 20n, enabled: profiles.api.StatusFilters.NotPending })
	const [profilesReq, setProfilesReq] = useState(profilesReqZero)
	const [users, setUsers] = useState([zeroUser])
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	useEffect(() => {
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => profiles.api.search(profilesReq, bearertoken))
			.then((resp) => {
				setUsers([zeroUser].concat(resp.items.map((i) => ({ key: i.id, name: i.display }))))
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
			})
			.finally(() => setLoading(false))
		return p.cancel
	}, [profilesReq])

	return (
		<layouts.loading.screen loading={loading} height="100%">
			<errors.overlay cause={cause} flexDirection="column" flex="1">
				<layouts.containers.flex flexDirection="column" ml="12px" mb="0" zIndex="0" position="relative">
					<bgApprovalLayout.FilterButton
						minWidth="145px"
						height="20px"
						borderRadius="4px"
						padding="2px 15px"
						onClick={() => setFilterFocused(!filterFocused)}
						className={filterFocused ? "active" : ""}
					>
						<layouts.containers.flex flexDirection="row" justifyContent="center">
							<layouts.containers.box>
								<icons.bgapproval.filter />
							</layouts.containers.box>
							<layouts.containers.box px="10px">
								{req.group_by === GroupBy.UPLOADER ? "uploaded" : "reviewed"} by: {currentUser.name}
							</layouts.containers.box>
							<layouts.containers.box>
								<icons.bgapproval.arrowDown />
							</layouts.containers.box>
						</layouts.containers.flex>
					</bgApprovalLayout.FilterButton>
					<layouts.containers.flex
						flexDirection="column"
						display={filterFocused ? "flex" : "none"}
						background="white"
						borderRadius="5px"
						boxShadow={layouts.theme.boxshadow.black.wide}
						position="absolute"
						top="100%"
						height="120px"
						padding="10px 20px 20px 0"
					>
						<typography.h3 color={layouts.theme.colors.grey.medium} fontSize="18px" px="20px" fontWeight="600">
							Filter Metrics
						</typography.h3>
						<layouts.containers.flex>
							<layouts.containers.flex flexDirection="column">
								<Checkbox
									description="By uploader"
									checked={req.group_by === GroupBy.UPLOADER}
									onChange={() => {
										if (req.group_by === GroupBy.UPLOADER) return
										setReq({ ...req, group_by: GroupBy.UPLOADER })
									}}
								/>
								<Checkbox
									description="By reviewer"
									checked={req.group_by === GroupBy.REVIEWER}
									onChange={() => {
										if (req.group_by === GroupBy.REVIEWER) return
										setReq({ ...req, group_by: GroupBy.REVIEWER })
									}}
								/>
							</layouts.containers.flex>
							<layouts.containers.flex>
								<layouts.Box className={styledSearchSelect} onClick={() => setSearchFocused(!searchFocused)}>
									<inputs.Text
										key={selectKey}
										height="31px"
										borderRadius="5px"
										defaultValue={profilesReq.query}
										placeholder={currentUser.name}
										onChange={(evt) => setProfilesReq({ ...profilesReq, query: evt.target.value })}
										onBlur={() => setSearchFocused(false)}
									/>
									{searchFocused && users.length > 0 && (
										<ul>
											{users.map((u) => (
												<li
													className={u.key === currentUser.key ? "active" : ""}
													key={u.key}
													title={u.name}
													onMouseDown={() => {
														setSearchFocused(false)
														setProfilesReq(profilesReqZero)
														setCurrentUser(u)
														setSelectKey(uuid.v4())
													}}
												>
													{u.name}
												</li>
											))}
										</ul>
									)}
								</layouts.Box>
							</layouts.containers.flex>
						</layouts.containers.flex>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</errors.overlay>
		</layouts.loading.screen>
	)
}

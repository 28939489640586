import React, { useContext } from "react"
import * as layouts from "layouts"

export default function Description(
	props: React.PropsWithChildren<layouts.accordions.props & layouts.containers.FlexProps>,
): JSX.Element {
	const { open, toggle } = useContext(layouts.accordions.AccordionContext)
	const { children, ...rest } = { ...props }

	return (
		<layouts.containers.flex
			justifyContent="space-between"
			onClick={() => toggle(!open)}
			cursor="pointer"
			className="accordion-description"
			{...rest}
		>
			{children}
			<layouts.containers.flex minWidth="54px" alignItems="center" justifyContent="center">
				{open ? <layouts.accordions.FeatherUp /> : <layouts.accordions.FeatherDown />}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

import * as svg from "./svg"
import * as theme from "layouts/theme"

export const ArrowDown = (props: svg.SVGProps) => {
	const { className, fill, ...rest } = props
	return (
		<svg.SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="10px"
			height="10px"
			viewBox="0 0 10 10"
			version="1.1"
			fill={fill}
			{...rest}
		>
			<path d="M 9.332031 6.03125 C 9.324219 6.207031 9.246094 6.375 9.113281 6.492188 L 5.449219 9.828125 C 5.328125 9.941406 5.167969 10.007812 5 10.011719 C 4.832031 10.007812 4.671875 9.941406 4.550781 9.828125 L 0.886719 6.492188 C 0.613281 6.246094 0.59375 5.824219 0.839844 5.550781 C 1.085938 5.277344 1.507812 5.257812 1.78125 5.507812 L 4.332031 7.828125 L 4.332031 0.65625 C 4.332031 0.289062 4.632812 -0.0117188 5 -0.0117188 C 5.367188 -0.0117188 5.667969 0.289062 5.667969 0.65625 L 5.667969 7.828125 L 8.21875 5.507812 C 8.492188 5.257812 8.914062 5.277344 9.160156 5.550781 C 9.277344 5.683594 9.339844 5.855469 9.332031 6.03125 Z M 9.332031 6.03125 " />
		</svg.SVG>
	)
}

export const ArrowUp = (props: svg.SVGProps) => {
	const { className, fill, ...rest } = props
	return (
		<svg.SVG
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="10px"
			height="10px"
			viewBox="0 0 10 10"
			version="1.1"
			fill={fill}
			{...rest}
		>
			<path d="M 9.332031 3.96875 C 9.324219 3.792969 9.246094 3.625 9.113281 3.507812 L 5.449219 0.171875 C 5.328125 0.0585938 5.167969 -0.0078125 5 -0.0117188 C 4.832031 -0.0078125 4.671875 0.0585938 4.550781 0.171875 L 0.886719 3.507812 C 0.613281 3.753906 0.59375 4.175781 0.839844 4.449219 C 1.085938 4.722656 1.507812 4.742188 1.78125 4.492188 L 4.332031 2.171875 L 4.332031 9.34375 C 4.332031 9.710938 4.632812 10.011719 5 10.011719 C 5.367188 10.011719 5.667969 9.710938 5.667969 9.34375 L 5.667969 2.171875 L 8.21875 4.492188 C 8.492188 4.742188 8.914062 4.722656 9.160156 4.449219 C 9.277344 4.316406 9.339844 4.144531 9.332031 3.96875 Z M 9.332031 3.96875 " />
		</svg.SVG>
	)
}

export const SortUp = (props: svg.SVGProps) => {
	const { fill, fillOpacity, ...rest } = props
	return (
		<svg.SVG width="20px" height="10px" viewBox="25 0 60 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<polygon fill={fill} fillOpacity={fillOpacity} points="24.15,0 38.5,14.35 52.85,0  " />
		</svg.SVG>
	)
}

SortUp.defaultProps = {
	fill: theme.colors.grey.dark50,
	fillOpacity: "0.8",
}

export const ArrowLeft = (props: svg.SVGProps) => {
	const { fill, ...rest } = props
	return (
		<svg.SVG width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M10.7176 2.67551C10.8078 2.58004 10.8783 2.46773 10.9251 2.34501C10.9719 2.22228 10.9941 2.09153 10.9904 1.96023C10.9867 1.82892 10.9572 1.69964 10.9035 1.57975C10.8498 1.45987 10.773 1.35173 10.6776 1.26151C10.5821 1.17129 10.4698 1.10076 10.3471 1.05395C10.2243 1.00713 10.0936 0.984947 9.96229 0.988661C9.83098 0.992374 9.7017 1.02192 9.58181 1.07559C9.46193 1.12927 9.35379 1.20604 9.26357 1.30151L0.76357 10.3015C0.588034 10.4872 0.490227 10.733 0.490227 10.9885C0.490227 11.244 0.588034 11.4898 0.76357 11.6755L9.26357 20.6765C9.35319 20.7741 9.46131 20.8529 9.58163 20.9083C9.70196 20.9637 9.8321 20.9947 9.96449 20.9994C10.0969 21.0041 10.2289 20.9824 10.3529 20.9357C10.4768 20.8889 10.5902 20.818 10.6866 20.7271C10.7829 20.6361 10.8602 20.5269 10.9139 20.4059C10.9677 20.2848 10.9969 20.1542 10.9998 20.0218C11.0026 19.8893 10.9792 19.7576 10.9307 19.6343C10.8823 19.511 10.8098 19.3986 10.7176 19.3035L2.86557 10.9885L10.7176 2.67551Z"
				fill={fill}
			/>
		</svg.SVG>
	)
}

ArrowLeft.defaultProps = {
	fill: theme.colors.black.dark,
}

export const ArrowRight = (props: svg.SVGProps) => {
	const { fill, ...rest } = props
	return (
		<svg.SVG width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M0.282431 18.3245C0.192213 18.42 0.121683 18.5323 0.0748672 18.655C0.0280517 18.7777 0.00586719 18.9085 0.00958151 19.0398C0.0132958 19.1711 0.0428359 19.3004 0.0965146 19.4202C0.150193 19.5401 0.226959 19.6483 0.32243 19.7385C0.417901 19.8287 0.530208 19.8992 0.652937 19.9461C0.775666 19.9929 0.906413 20.0151 1.03771 20.0113C1.16902 20.0076 1.2983 19.9781 1.41819 19.9244C1.53807 19.8707 1.64621 19.794 1.73643 19.6985L10.2364 10.6985C10.412 10.5128 10.5098 10.267 10.5098 10.0115C10.5098 9.75598 10.412 9.51016 10.2364 9.32449L1.73643 0.323489C1.64681 0.225928 1.53869 0.147146 1.41837 0.0917218C1.29804 0.0362972 1.1679 0.00533441 1.03551 0.000630571C0.903113 -0.00407327 0.771103 0.0175772 0.647146 0.0643226C0.523189 0.111068 0.409756 0.181976 0.313435 0.272931C0.217115 0.363886 0.139826 0.473073 0.0860602 0.59415C0.032294 0.715227 0.00312157 0.84578 0.000237086 0.978227C-0.00264739 1.11067 0.0208133 1.24237 0.0692573 1.36568C0.117701 1.48898 0.190163 1.60143 0.282432 1.69649L8.13443 10.0115L0.282431 18.3245Z"
				fill={fill}
			/>
		</svg.SVG>
	)
}

ArrowRight.defaultProps = {
	fill: theme.colors.black.dark,
}

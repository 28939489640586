import React, { useEffect } from "react"
import * as sessions from "sessions"
import * as brands from "brands"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as icons from "icons"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as bglayouts from "brandguard/components/layouts"
import { Image } from "media/image"
import * as mediaapi from "media/api"

interface props extends layouts.containers.FlexProps {
	item: api.ImageSearchResponseItem
	active?: boolean
	panel: JSX.Element
	onCardClick(): void
	onChecked?(state: boolean): void
	onChange(item: api.ImageSearchResponseItem): void
	find(id: string, bid: string, ...options: httpx.option[]): Promise<api.ImageFindResponse>
	imageBackground?: string
	cardClassName?: string
	checked: boolean
	score: JSX.Element
}

export default function Display(props: React.PropsWithChildren<props>): JSX.Element {
	const {
		item,
		active,
		onChange,
		onCardClick,
		onChecked,
		find,
		panel,
		imageBackground,
		cardClassName,
		checked,
		score,
		...rest
	} = props
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const processing = predictions.processing(item.event?.approved)

	useEffect(() => {
		if (!onChange) return
		if (!processing) return

		let cancelled = false
		const tid = setTimeout(() => {
			find(item.media?.id!, brand.id, bearertoken)
				.then((result) => {
					cancelled || onChange(result)
				})
				.catch((cause) => console.error("unable to find brandguard approval images", cause))
		}, Math.round(Math.random() * 5000 + 5000))

		return () => {
			clearTimeout(tid)
			cancelled = true
		}
	}, [item])

	return (
		<bglayouts.Card
			key={item.media?.id}
			className={`${cardClassName} ${active ? "active" : ""}`}
			flexDirection="column"
			marginBottom="unset"
			{...rest}
		>
			<layouts.containers.flex onClick={onCardClick}>
				<Image
					media={mediaapi.zero({ content_digest: item.media?.md5 })}
					background={imageBackground}
					opacity={processing ? 0.3 : 1}
					height="100%"
					className="card-image"
				/>
				{score}
			</layouts.containers.flex>

			{onChecked && (
				<icons.brandguard.checkbox
					position="absolute"
					bottom="10px"
					right="10px"
					onClick={(e) => {
						onChecked(!checked)
						e.stopPropagation()
					}}
					checked={checked}
				/>
			)}
			{panel}
		</bglayouts.Card>
	)
}

Display.defaultProps = {
	cardClassName: "",
	imageBackground: layouts.theme.colors.grey.lightest,
	score: <></>,
	minWidth: "280px",
}

import React from "react"
import * as layouts from "layouts"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import estyled from "@emotion/styled"
import * as typography from "typography"
import * as icons from "icons"

export const Select = styled.select`
	width: 80%;
	height: 31px;
	background: ${layouts.theme.backgrounds.dark50alpha20};
	color: ${layouts.theme.colors.grey.dark20};
	box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
	border-radius: 5px;
	border: 1px solid ${layouts.theme.colors.grey.dark50alpha60};
	&:hover {
		box-shadow: none;
	}
	&:focus {
		outline: 0;
	}
`

export const FilterButton = estyled(layouts.buttons.styled)`
	background-color: unset;
	border: none;
	color: ${layouts.theme.colors.grey.dark50alpha80};
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0px;
	text-align: left;
	&.active,
	&:hover {
		background-color: ${layouts.theme.colors.blue.blue};
		color: ${layouts.theme.colors.white};
		svg{
			path{
				stroke: ${layouts.theme.colors.white};
				stroke-opacity: 1;
				fill-opacity: 1;
			}
		}
	}
`

export const styledactions = css`
	.actions {
		margin-top: 0;
		.selected-assets-description {
			display: none;
		}
		.download-action,
		.remove-action {
			width: 45px;
			height: 35px;
			background: ${layouts.theme.colors.white};
			justify-content: center;
			align-items: center;
			svg {
				circle {
					display: none;
				}
				path {
					stroke: ${layouts.theme.colors.grey.dark50};
					stroke-opacity: 0.8;
				}
			}
		}
		.remove-action {
			margin-right: 5px;
		}
		.download-action {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
`

export const SelectAll = styled(layouts.buttons.outline)`
	color: ${layouts.theme.colors.grey.dark50}CC;
	width: 100px;
	height: 35px;
	border: none;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0px;
	&:hover {
		background-color: unset;
		color: unset;
	}
`

export function ZeroState(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { ...rest } = props
	return (
		<layouts.containers.flex flexDirection="column" flex="1" pt="20px" {...rest}>
			<layouts.containers.flex justifyContent="center">
				<typography.h3
					fontWeight="700"
					fontSize="24px"
					lineHeight="32px"
					color={layouts.theme.colors.grey.dark50alpha80}
				>
					You do not have any assets here.
				</typography.h3>
			</layouts.containers.flex>
			<layouts.containers.flex justifyContent="center" pt="50px">
				<icons.bgapproval.zeroIcon />
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

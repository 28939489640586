import React, { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"
import { useNavigate } from "react-router-dom"
import * as brandguide from "brandguide"
import { section } from "brandguide/validations"
import * as authz from "authz"
import * as authzc from "authzcached"
import * as routes from "./routes"

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const cache = brandguide.cache.useCached()
	const _guide = cache.guide
	const navigate = useNavigate()

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	const [guide, setGuide] = useState(_guide.guide!)

	const pages = brandguide.validations.guide.sections(guide)
	const nextDisabled = !pages.map((p) => p?.enabled).includes(true)

	const firstPage = pages.find((page) => page?.enabled)

	const handleStart = () => {
		cache
			.store({ ...cache, guide: { ...cache.guide, guide: guide } })
			.finally(() => navigate(routes.nextSection(firstPage?.id as brandguide.validations.pages, _guide.brand_id)))
	}

	return (
		<layouts.Flex flexDirection="column-reverse" flex="1" mb="25px" data-testid="brandguide-start">
			<layouts.Flex className="center-panel" flex="1" flexDirection="column">
				<layouts.Flex
					flex="1"
					flexDirection="row"
					background={layouts.theme.colors.white}
					border={layouts.theme.borders.grey.dark50alpha}
					boxShadow={layouts.theme.boxshadow.black.wide}
				>
					<layouts.Flex flexDirection="column" my="65px" flex="1" alignItems="center">
						<typography.h3
							fontSize="24px"
							fontWeight="400"
							letterSpacing="unset"
							color={layouts.theme.colors.grey.dark50}
						>
							Welcome to BrandGuide Builder
						</typography.h3>
						<typography.h4
							pt="15px"
							fontSize="16px"
							fontWeight="400"
							letterSpacing="unset"
							color={layouts.theme.colors.grey.dark50}
							whiteSpace="unset"
						>
							Begin by selecting the sections of your brand guidelines.
						</typography.h4>
						<typography.h4
							fontSize="16px"
							fontWeight="400"
							letterSpacing="unset"
							color={layouts.theme.colors.grey.dark50}
							whiteSpace="unset"
						>
							Start with what you have and build more later.
						</typography.h4>
						<layouts.Flex flexDirection="column" mx="155px" mt="65px">
							<layouts.Flex gridGap="15px">
								<Card
									section={guide.colors!}
									title="Colors"
									onChange={() => {
										const updColors = brandguide.api.zeros.guide.guideColorsZero(guide.colors)
										updColors.enabled = !(guide.colors?.enabled || false)
										setGuide({ ...guide, colors: updColors })
									}}
									data-testid="colors-card"
								/>
								<Card
									section={guide.logos!}
									title="Logos"
									onChange={() => {
										const updLogos = brandguide.api.zeros.guide.guideLogosZero(guide.logos)
										updLogos.enabled = !(guide.logos?.enabled || false)
										setGuide({ ...guide, logos: updLogos })
									}}
								/>
								<Card
									section={guide.typography!}
									title="Typography"
									onChange={() => {
										const updTypography = brandguide.api.zeros.guide.guideTypographyZero(guide.typography)
										updTypography.enabled = !(guide.typography?.enabled || false)
										setGuide({ ...guide, typography: updTypography })
									}}
								/>
								<Card
									section={guide.imagery!}
									title="Imagery"
									onChange={() => {
										const updImagery = brandguide.api.zeros.guide.guideImageryZero(guide.imagery)
										updImagery.enabled = !(guide.imagery?.enabled || false)
										setGuide({ ...guide, imagery: updImagery })
									}}
								/>
							</layouts.Flex>
							<layouts.Flex gridGap="15px" mt="15px">
								<Card
									section={guide.tone_of_voice!}
									title="Tone of Voice"
									onChange={() => {
										const updData = brandguide.api.zeros.guide.guideToneOfVoiceZero(guide.tone_of_voice)
										updData.enabled = !(guide.tone_of_voice?.enabled || false)
										setGuide({ ...guide, tone_of_voice: updData })
									}}
								/>
								<Card
									section={guide.archetype!}
									title="Archetype"
									onChange={() => {
										const updArchetype = brandguide.api.zeros.guide.guideArchetypeZero(guide.archetype)
										updArchetype.enabled = !(guide.archetype?.enabled || false)
										setGuide({ ...guide, archetype: updArchetype })
									}}
								/>
								<Card
									section={guide.compliance!}
									title="Compliance"
									onChange={() => {
										const updData = brandguide.api.zeros.guide.guideComplianceZero(guide.compliance)
										updData.enabled = !(guide.compliance?.enabled || false)
										setGuide({ ...guide, compliance: updData })
									}}
								/>
								<Card
									section={guide.application!}
									title="Application"
									onChange={() => {
										const updData = brandguide.api.zeros.guide.guideApplicationZero(guide.application)
										updData.enabled = !(guide.application?.enabled || false)
										setGuide({ ...guide, application: updData })
									}}
								/>
							</layouts.Flex>
						</layouts.Flex>
						<layouts.Flex mt="30px">
							<authz.Protected
								enabled={permission.brandguide_edit}
								rejected={
									<layouts.Flex
										alignItems="center"
										justifyContent="center"
										color={layouts.theme.colors.grey.dark50}
										fontSize="14px"
										flexDirection="column"
										gridGap="5px"
										data-testid="brandguide-no-permissions"
									>
										<layouts.Flex>You do not have permission to create/edit BrandGuides</layouts.Flex>
										<layouts.Flex>Request access from your account administrator.</layouts.Flex>
									</layouts.Flex>
								}
							>
								<layouts.buttons.unstyled
									cursor="pointer"
									disabled={nextDisabled}
									height="34px"
									width="134px"
									borderRadius="5px"
									background={nextDisabled ? layouts.theme.colors.grey.dark50alpha05 : layouts.theme.colors.grey.dark50}
									color={nextDisabled ? layouts.theme.colors.grey.dark50alpha60 : layouts.theme.colors.white}
									onClick={handleStart}
									data-testid="brandguide-start-button"
								>
									Start Building
								</layouts.buttons.unstyled>
							</authz.Protected>
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface iconProps extends icons.SVGProps {
	section: section
}

function Icon(props: iconProps): JSX.Element {
	const { section, ...rest } = props
	switch (true) {
		case section.id === "logos":
			return <icons.brandguide.nav.Logos {...rest} />
		case section.id === "colors":
			return <icons.brandguide.nav.Colors {...rest} />
		case section.id === "typography":
			return <icons.brandguide.nav.Typography {...rest} />
		case section.id === "imagery":
			return <icons.brandguide.nav.Imagery {...rest} />
		case section.id === "tone_of_voice":
			return <icons.brandguide.nav.ToneOfVoice {...rest} />
		case section.id === "archetype":
			return <icons.brandguide.nav.Archetype {...rest} />
		case section.id === "application":
			return <icons.brandguide.nav.Application {...rest} />
		case section.id === "compliance":
			return <icons.brandguide.nav.Compliance {...rest} />
		default:
			return <></>
	}
}

interface cardProps extends layouts.containers.ContainerProps {
	section: section
	title: string
	onChange(): void
}

function Card(props: cardProps): JSX.Element {
	const { section, title, onChange, ...rest } = props
	return (
		<layouts.Flex
			background={section.enabled ? layouts.theme.colors.blue.blue : layouts.theme.colors.grey.dark50alpha10}
			onClick={onChange}
			{...rest}
		>
			<Icon
				section={section}
				stroke={section.enabled ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50}
				strokeOpacity={section.enabled ? "0.9" : "0.5"}
			/>
			<typography.h5
				mt="10px"
				fontSize="16px"
				fontWeight="400"
				color={section.enabled ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50}
			>
				{title}
			</typography.h5>
		</layouts.Flex>
	)
}

Card.defaultProps = {
	cursor: "pointer",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	height: "150px",
	width: "150px",
}

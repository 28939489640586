import * as httpx from "httpx"
import * as events from "./events"
import { CancellablePromise } from "real-cancellable-promise"
import {
	ImageSearchRequest,
	ImageSearchResponse,
	ImageFindResponse,
	ImagePatchRequest,
	ImagePatchResponse,
	ImageUploadResponse,
	ImageTrainRequest,
	ImageTrainResponse,
	ImageRescoreRequest,
	ImageRescoreResponse,
	ImageMediaUploadResponse,
	ImageMediaUploadRequest,
} from "brandguard/brandguard.image_pb"

export function upload(
	data: FormData,
	file: File,
	bid: string,
	...options: httpx.option[]
): CancellablePromise<ImageUploadResponse> {
	data.append("content", file)
	return httpx
		.upload<ImageUploadResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training`, data, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function media_upload(
	req: ImageMediaUploadRequest,
	bid: string,
	...options: httpx.option[]
): CancellablePromise<ImageMediaUploadResponse> {
	return httpx.post<ImageMediaUploadResponse>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/train_media_uploads`,
		req,
		...options,
	)
}

export function search(
	req: ImageSearchRequest,
	bid: string,
	...options: httpx.option[]
): CancellablePromise<ImageSearchResponse> {
	return httpx
		.get<ImageSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training`, req, ...options)
		.then((resp) => ({
			...resp,
			items: (resp.items || []).map((item) => ({ media: item.media, event: events.zero(item.event) })),
		}))
}

export function find(id: string, bid: string, ...options: httpx.option[]): CancellablePromise<ImageFindResponse> {
	return httpx
		.get<ImageFindResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function patch(
	id: string,
	bid: string,
	req: ImagePatchRequest,
	...options: httpx.option[]
): Promise<ImagePatchResponse> {
	return httpx
		.patch<ImagePatchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/${id}`, req, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function requeue(id: string, bid: string, ...options: httpx.option[]): Promise<ImageFindResponse> {
	return httpx
		.post<ImageFindResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/${id}/requeue`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function transfer(
	id: string,
	bid: string,
	req: ImagePatchRequest,
	...options: httpx.option[]
): CancellablePromise<ImagePatchResponse> {
	return httpx
		.post<ImagePatchResponse>(
			`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/${id}/transfer`,
			req,
			...options,
		)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export async function enqueue(
	bid: string,
	req: ImageTrainRequest,
	...options: httpx.option[]
): Promise<ImageTrainResponse> {
	return httpx.post<ImageTrainRequest>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/enqueue`,
		req,
		...options,
	)
}

export async function rescore(
	bid: string,
	req: ImageRescoreRequest,
	...options: httpx.option[]
): Promise<ImageRescoreResponse> {
	return httpx.post<ImageRescoreRequest>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/rescore`,
		req,
		...options,
	)
}

export function destroy(bid: string, id: string, ...options: httpx.option[]): Promise<ImagePatchResponse> {
	return httpx
		.destroy<ImagePatchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/training/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

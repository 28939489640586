import * as brands from "brands"
import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import styled from "@emotion/styled"

const Card = styled(layouts.Flex)``
Card.defaultProps = {
	flex: "1",
	height: "100%",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "20px",
	background: colors.blue.blue,
	border: `1px solid ${colors.grey.dark50alpha30}`,
	gridGap: "10px",
	position: "relative",
}

const CardText = styled(typography.h5)``
CardText.defaultProps = {
	p: "5%",
	whiteSpace: "unset",
	fontSize: "2vh",
	fontWeight: "700",
	lineHeight: "normal",
}

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const brand = brands.caching.useCached()
	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = brandguide.api.zeros.guide.guideImageryZero(guide.guide?.imagery)

	const fundamentals = data.fundamentals || []
	const maxItems = fundamentals.reduce((max, obj) => {
		if (obj.items.length > max) return obj.items.length
		return max
	}, 0)

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Imagery</result_components.NavTitle>
				<result_components.NavDescription>
					Please follow these guidelines when working with imagery for {brand.description}.
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.fundamentals} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer>
					<layouts.Flex>
						<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
							Photography Fundamentals
						</typography.h4>
					</layouts.Flex>
					<layouts.Flex flex="1" flexDirection="column" alignItems="start" gridGap="5%">
						{fundamentals.map((f) => {
							if ((f.items || []).length === 0) return <></>
							return (
								<layouts.Flex key={f.id} flex="1" alignItems="center" justifyContent="left" width="100%" gridGap="1%">
									{(f.items || []).map((i) => (
										<Card key={i.name} maxWidth={`${100 / maxItems - 1}%`}>
											<layouts.containers.absolute p="20px" left="0" top="0">
												<CardText color={colors.white} fontSize="1.5vh" fontWeight="400" width="100%">
													{f.name}
												</CardText>
											</layouts.containers.absolute>
											<CardText color={colors.white}>{i.name}</CardText>
										</Card>
									))}
								</layouts.Flex>
							)
						})}
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as icons from "icons"
import * as typography from "typography"
import * as timex from "timex"
import * as sessions from "sessions"

export default function Display(): JSX.Element {
	const location = useLocation()
	const decoded = httpx.qs.parse(location.search, {
		parseBooleans: true,
		parseNumbers: true,
	}) as unknown as { loginopts: string }

	useEffect(() => {
		sessions.cache.magiclink.state.set(decoded.loginopts).then(() => {})
	}, [])

	return (
		<layouts.Flex
			flexDirection="column"
			minHeight="100vh"
			margin="0"
			flex="1"
			background={layouts.theme.backgrounds.lightblue}
		>
			<layouts.Flex className="header" px="50px" alignItems="center" justifyContent="flex-start" height="60px">
				<icons.MainLogo width="202px" />
			</layouts.Flex>
			<layouts.Flex justifyContent="center" alignItems="center" flex="1">
				<layouts.Flex
					p="40px"
					borderRadius="11px"
					flexDirection="column"
					background={layouts.theme.colors.white}
					boxShadow={layouts.theme.extension.boxshadow}
					alignItems="center"
				>
					<typography.h1
						px="40px"
						color={layouts.theme.colors.grey.dark50}
						fontSize="30px"
						lineHeight="44px"
						fontWeight="700"
						letterSpacing="0.4px"
					>
						BrandGuard
					</typography.h1>
					<typography.h5
						pt="5px"
						color={layouts.theme.colors.grey.dark50alpha80}
						fontSize="13px"
						fontWeight="500"
						letterSpacing="normal"
						whiteSpace="unset"
						lineHeight="25px"
					>
						We have sent an email to your registered address containing a link for Brandguard access.
						<br />
						Please check your inbox and follow the instructions in the email to proceed.
					</typography.h5>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex className="footer" px="50px" alignItems="center" justifyContent="flex-start" height="60px">
				<typography.h4 fontSize="12px" color={layouts.theme.colors.grey.dark50alpha80}>
					{`Copyright © ${timex.local().year} BrandGuard. All rights reserved.`}
				</typography.h4>
			</layouts.Flex>
		</layouts.Flex>
	)
}

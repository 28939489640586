import * as layouts from "layouts"
import * as profiles from "profiles"
import * as domains from "sso/domains"
import * as um from "meta/usermanagement"
import * as icons from "icons"
import * as inputs from "inputs"
import * as timex from "timex"

interface props {
	req: profiles.api.proto.SearchRequest | domains.api.proto.SearchRequest
	onChange(upd: profiles.api.proto.SearchRequest | domains.api.proto.SearchRequest): void
	reset(): void
	showStatus: boolean
}

export default function Display(props: props): JSX.Element {
	const { req, onChange, reset, showStatus } = props
	const infiso = timex.infinity().toISO()
	const neginfiso = timex.negInfinity().toISO()

	return (
		<inputs.Search query={req.query} onChange={(s) => onChange({ ...req, query: s })}>
			<layouts.containers.flex mx="20px" height="80%" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
			<icons.Calendar />
			<inputs.calendars.DateRangeOneDatepicker
				className={um.layouts.StyledDatePicker}
				placeholderText="Date Range"
				startDate={new Date(req.created?.oldest || neginfiso)}
				endDate={new Date(req.created?.newest || infiso)}
				maxEndDate={new Date()}
				changeStart={(d: Date) => {
					const upd = { newest: infiso, oldest: d.toISOString() }
					onChange({ ...req, created: upd })
				}}
				changeEnd={(d: Date) => {
					const upd = { oldest: req.created?.oldest || neginfiso, newest: d.toISOString() }
					onChange({ ...req, created: upd })
				}}
			/>
			<layouts.containers.flex mx="20px" height="80%" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
			{showStatus && <um.layouts.StatusSelect val={req?.enabled} onSelect={(s) => onChange({ ...req, enabled: s })} />}
			<layouts.containers.flex pr="20px" onClick={reset}>
				<icons.searches.Clear />
			</layouts.containers.flex>
		</inputs.Search>
	)
}

Display.defaultProps = {
	showStatus: true,
}

import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as system from "styled-system"

export interface ToggleProps extends system.LayoutProps, system.TypographyProps, system.SpaceProps {
	active: boolean
}

const Container = styled(layouts.containers.box)`
	position: relative;
	width: 40px;
	height: 24px;
`

const ToggleBackground = styled.div<ToggleProps>`
	position: absolute;
	width: 32px;
	height: 16px;
	left: calc(50% - 32px / 2);
	top: calc(50% - 16px / 2);
	background: ${(props) => (props.active ? layouts.theme.colors.blue.blue : "#616161")};
	border-radius: 8px;
`

const ToggleIcon = styled.div<ToggleProps>`
	position: absolute;
	width: 12px;
	height: 12px;
	left: ${(props) => (props.active ? "calc(50% - 12px/2 + 8px)" : "calc(50% - 12px/2 - 8px)")};
	top: calc(50% - 12px / 2);
	background: #ffffff;
	border-radius: 8px;
`

interface props {
	active: boolean
	onClick: (() => void) | undefined
	className?: string
}

export function Display(props: React.PropsWithChildren<props>): JSX.Element {
	const { active, onClick } = props
	return (
		<Container onClick={onClick} cursor="pointer">
			<ToggleBackground active={active} />
			<ToggleIcon active={active} />
		</Container>
	)
}

Display.defaultProps = { active: false }

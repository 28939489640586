import { useEffect } from "react"
import { useLocation, useNavigate, useMatch } from "react-router-dom"
import * as layouts from "layouts"
import * as logos from "titlebars/logos"
import * as httpx from "httpx"

export default function Redirect(): JSX.Element {
	const m = useMatch("/authn/*")
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		const path = location.pathname.replace(m?.pathname || "", "")
		if (window.location.host.endsWith(httpx.urlstorage.ssohostname())) {
			navigate("/login", { state: { redirect: path === "/" ? "" : path } })
			return
		}
		const uri = new URL(httpx.urlstorage.sso())
		uri.pathname = path
		uri.search = location.search
		window.location.href = uri.toString()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<layouts.backgrounds.Grey>
			<layouts.containers.flex className="authenticated" flexDirection="column" width="100vw" height="100vh">
				<layouts.containers.flex className="navigation" mt="40px" mx="20px" px="120px">
					<logos.Logo />
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					height="100vh"
					width="100vw"
					overflow="hidden"
				>
					<layouts.containers.flex
						styled
						flexDirection="column"
						justifyContent="center"
						alignContent="space-around"
						width="608px"
						minHeight="240px"
						maxHeight="512px"
						borderRadius="20px"
						padding="60px"
					>
						<layouts.loading.pending loading margin="auto" />
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

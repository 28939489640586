import { useEffect, useState } from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as timex from "timex"
import * as profiles from "./profile"
import * as inputs from "inputs"

interface props {
	current: profiles.Profile
	readonly: boolean
	onChange(p: profiles.Profile): Promise<profiles.Profile>
}

export default function Edit(props: props & layouts.containers.FlexProps): JSX.Element {
	const { readonly, current, onChange, className, ...rest } = props

	const [cause, setCause] = useState(undefined as unknown | undefined)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setCause(undefined)
	}, [current])

	return (
		<layouts.forms.Container
			className={classnames(className, "profile disable toggle")}
			readonly={readonly}
			cause={cause}
			loading={loading}
			{...rest}
		>
			<inputs.Checkbox
				alignItems="flex-start"
				disabled={readonly}
				checked={profiles.enabled(current)}
				onChange={() => {
					const upd = profiles.enabled(current)
						? { ...current, disabled_manually_at: timex.local().toISO() }
						: { ...current, disabled_manually_at: timex.infinity().toISO(), disabled_at: timex.infinity().toISO() }
					setLoading(true)
					onChange(upd).finally(() => setLoading(false))
				}}
			>
				<layouts.forms.Label my="unset" ml="10px">
					Login
				</layouts.forms.Label>
			</inputs.Checkbox>
		</layouts.forms.Container>
	)
}

import * as svg from "./svg"
import * as theme from "layouts/theme"

const Icon = (props: svg.SVGProps) => {
	const { stroke, ...rest } = props
	return (
		<svg.SVG width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M8.36511 8.06543V13.0246"
				stroke={stroke}
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.97546 12.4795L8.36511 13.5422L9.75476 12.4795"
				stroke={stroke}
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.6484 0.749512H1.41684C1.04815 0.749512 0.749268 1.0484 0.749268 1.41709V7.18003C0.749268 7.54872 1.04815 7.8476 1.41684 7.8476H11.6484C12.0171 7.8476 12.316 7.54872 12.316 7.18003V1.41709C12.316 1.0484 12.0171 0.749512 11.6484 0.749512Z"
				stroke={stroke}
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
			<path
				d="M11.7166 12.1934H13.5831C13.9509 12.1934 14.2507 12.4931 14.2507 12.8609V18.6239C14.2507 18.9917 13.9509 19.2915 13.5831 19.2915H3.33786C2.97002 19.2915 2.67029 18.9917 2.67029 18.6239V12.8609C2.67029 12.4931 2.97002 12.1934 3.33786 12.1934H5.01361"
				stroke={stroke}
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
		</svg.SVG>
	)
}

Icon.defaultProps = {
	stroke: theme.colors.grey.dark50,
}

export default Icon

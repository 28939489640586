import React, { useState, useRef, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as colorx from "colorsx"
import * as brandguide from "brandguide"
import * as icons from "icons"
import { css } from "@emotion/css"

export const steps = ["Primary", "Secondary", "Correct", "Incorrect"]

const activeText = css`
	&:hover {
		background: ${layouts.theme.colors.grey.dark50alpha05};
	}
`

const styledInput = css`
	font-size: 12px;
	border-radius: 6px;
	line-height: 1.5;
	padding: 5px 10px;
	transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
	border: 2px solid #dee1e2;
	color: rgb(14, 14, 16);
	background: ${layouts.theme.colors.grey.dark50alpha10};
	display: block;
	height: 16px;
	max-width: 20%;
	:hover {
		border-color: ${layouts.theme.colors.grey.dark50alpha20};
	}
	:focus {
		border-color: ${layouts.theme.colors.grey.dark50alpha20};
		background: ${layouts.theme.colors.white};
		outline: none;
	}
`

interface colorItemProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ColorObject
	onDelete(id: string): void
	onChange(c: brandguide.api.ColorObject): void
	disabled: boolean
}

export function ColorItem(props: colorItemProps): JSX.Element {
	const { item, onChange, onDelete, disabled, ...rest } = props
	const inputRef = useRef<HTMLInputElement>(null)

	const [color, setColor] = useState(brandguide.api.zeros.colors.colorZero(item.color))
	const [metadata, setMetadata] = useState(brandguide.api.zeros.colors.metadataZero(item.metadata))
	const [focused, setFocused] = useState(false)

	const [name, setName] = useState(color.name)
	const [nameFocused, setNameFocused] = useState(false)

	const handleClick = () => {
		if (disabled) return
		inputRef.current?.click()
	}

	const textColor = colorx.getTextColorFromBG(color.hex_code)

	useEffect(() => {
		onChange({ color: color, metadata: metadata })
	}, [color, metadata])

	useEffect(() => {
		setName(color.name)
	}, [color.name])

	const handleChangeName = () => {
		if (!name) return
		setColor({ ...color, name: name })
		setNameFocused(false)
	}

	return (
		<layouts.Flex background={color?.hex_code} {...rest}>
			<layouts.Flex
				flex="3"
				onMouseEnter={() => setFocused(true)}
				onMouseLeave={() => setFocused(false)}
				justifyContent="center"
				alignItems="center"
			>
				{focused && (
					<layouts.Flex>
						<layouts.Flex flex="1" flexDirection="column">
							<layouts.Flex onClick={() => onDelete(metadata!.id)} mb="6px" justifyContent="center">
								<icons.Close width="20px" height="20px" fill={textColor} />
							</layouts.Flex>
							<layouts.Flex
								onClick={() => {
									const val = metadata?.favorite || false
									setMetadata({ ...metadata, favorite: !val })
								}}
								mb="6px"
								justifyContent="center"
							>
								<icons.brandguide.Favorite
									width="24px"
									height="24px"
									stroke={textColor}
									fill={metadata!.favorite ? layouts.theme.colors.red.cinnabar : "unset"}
								/>
							</layouts.Flex>
						</layouts.Flex>
					</layouts.Flex>
				)}
			</layouts.Flex>
			<layouts.Flex flexDirection="column" flex="1" justifyContent="flex-end" alignItems="center" position="relative">
				<layouts.Flex onClick={handleClick}>
					<typography.h4 mb="10px" fontSize="20px" className={activeText} color={textColor} {...textBaseProps}>
						{color?.hex_code}
						<input
							type="color"
							value={color?.hex_code}
							ref={inputRef}
							style={{ width: "50px", height: "50px", opacity: "0", position: "absolute", zIndex: "-1" }}
							onChange={(evt) => {
								const val = evt.target.value.toUpperCase()
								const name = colorx.getClosestColorName(val)
								setColor({ ...color, hex_code: val, name: name })
							}}
						/>
					</typography.h4>
				</layouts.Flex>
				{nameFocused ? (
					<layouts.Flex justifyContent="center" p="5px">
						<input
							type="text"
							placeholder={color?.name}
							className={styledInput}
							autoFocus
							value={name}
							onChange={(evt) => setName(evt.target.value)}
							maxLength={30}
						/>
						<layouts.Flex m="5px">
							<layouts.Flex onClick={handleChangeName}>
								<icons.brandguide.actions.Accept
									width="20px"
									height="20px"
									fill={name !== "" ? layouts.theme.colors.blue.blue : layouts.theme.colors.grey.dark50alpha30}
									fillOpacity="1"
								/>
							</layouts.Flex>
							<layouts.Flex onClick={() => setNameFocused(false)}>
								<icons.brandguide.actions.Reject
									width="20px"
									height="20px"
									fill={layouts.theme.colors.blue.blue}
									fillOpacity="1"
								/>
							</layouts.Flex>
						</layouts.Flex>
					</layouts.Flex>
				) : (
					<layouts.Flex
						onClick={() => {
							if (disabled) return
							setNameFocused(true)
						}}
						px="5px"
						className={activeText}
						mb="9px"
					>
						<typography.h5 fontSize="12px" textAlign="center" color={textColor} {...textBaseProps}>
							{color?.name}
						</typography.h5>
					</layouts.Flex>
				)}
			</layouts.Flex>
		</layouts.Flex>
	)
}

ColorItem.defaultProps = {
	flexDirection: "column",
	width: "140px",
	height: "240px",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha20}`,
	disabled: false,
}

interface addColorItemProps extends layouts.containers.ContainerProps {
	onChange(): void
}

export function AddColorItem(props: addColorItemProps): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flexDirection="column" flex="1" justifyContent="flex-end" alignItems="center">
				<layouts.Flex onClick={onChange}>
					<typography.h4 mb="10px" fontSize="20px" color={layouts.theme.colors.grey.dark50} {...textBaseProps}>
						+
					</typography.h4>
				</layouts.Flex>
				<typography.h5 mb="9px" fontSize="12px" color={layouts.theme.colors.grey.dark50} {...textBaseProps}>
					Add New
				</typography.h5>
			</layouts.Flex>
		</layouts.Flex>
	)
}

AddColorItem.defaultProps = {
	width: "140px",
	height: "240px",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha20}`,
	background: layouts.theme.colors.white,
}

const textBaseProps = {
	fontWeight: "400",
	letterSpacing: "unset",
	lineHeight: "normal",
	whiteSpace: "unset",
}

const actionsTextProps = {
	fontSize: "10px",
	color: layouts.theme.colors.grey.dark50alpha80,
	cursor: "pointer",
}

interface colorContractProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ColorContrastObject
	onDelete(id: string): void
	onChange(c: brandguide.api.ColorContrastObject): void
	correct: boolean
	readonly: boolean
}

export function ColorWithTextItem(props: colorContractProps): JSX.Element {
	const { item, onChange, onDelete, correct, readonly, ...rest } = props
	const inputTextColorRef = useRef<HTMLInputElement>(null)
	const inputBGColorRef = useRef<HTMLInputElement>(null)

	const [background, setBackground] = useState(brandguide.api.zeros.colors.colorZero(item.background))
	const [color, setColor] = useState(brandguide.api.zeros.colors.colorZero(item.text))

	const handleTextClick = () => {
		if (readonly) return
		inputTextColorRef.current?.click()
	}
	const handleBGClick = () => {
		if (readonly) return
		inputBGColorRef.current?.click()
	}

	function StatusIcon(): JSX.Element {
		const iconProps = {
			fill: colorx.getTextColorFromBG(background.hex_code),
			fillOpacity: "0.9",
			width: "15px",
			height: "15px",
		}
		if (correct) return <icons.brandguide.marks.Accept {...iconProps} />
		return <icons.brandguide.marks.Reject {...iconProps} />
	}

	useEffect(() => {
		if (readonly) return
		onChange({ background: background, text: color, metadata: item.metadata })
	}, [color, background])

	return (
		<layouts.Flex {...rest}>
			<layouts.containers.absolute right="0" p="5px">
				<StatusIcon />
			</layouts.containers.absolute>
			<layouts.Flex
				flex="1"
				alignItems="center"
				justifyContent="center"
				background={background.hex_code}
				minHeight="100px"
				border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
			>
				<typography.h4 fontSize="16px" p="20px" textAlign="center" color={color.hex_code} {...textBaseProps}>
					{color.name} sample text
				</typography.h4>
			</layouts.Flex>
			<layouts.Flex
				p="5px"
				className="actions"
				alignItems="center"
				justifyContent="flex-start"
				background={layouts.theme.colors.white}
				color={layouts.theme.colors.grey.dark50}
			>
				<layouts.Flex position="relative" onClick={handleTextClick}>
					<typography.h6 className={activeText} {...textBaseProps} {...actionsTextProps}>
						{color.hex_code}
					</typography.h6>
					<input
						disabled={readonly}
						type="color"
						value={color.hex_code}
						ref={inputTextColorRef}
						style={{ width: "50px", height: "20px", opacity: "0", position: "absolute", zIndex: "-1" }}
						onChange={(evt) => {
							const val = evt.target.value.toUpperCase()
							const name = colorx.getClosestColorName(val)
							setColor({ ...color, hex_code: val, name: name })
						}}
					/>
				</layouts.Flex>
				<typography.h6 px="5px" {...textBaseProps} {...actionsTextProps}>
					text with
				</typography.h6>
				<layouts.Flex position="relative" onClick={handleBGClick}>
					<typography.h6 className={activeText} {...textBaseProps} {...actionsTextProps}>
						{background.hex_code}
					</typography.h6>
					<input
						disabled={readonly}
						type="color"
						value={background.hex_code}
						ref={inputBGColorRef}
						style={{ width: "50px", height: "20px", opacity: "0", position: "absolute", zIndex: "-1" }}
						onChange={(evt) => {
							const val = evt.target.value.toUpperCase()
							const name = colorx.getClosestColorName(val)
							setBackground({ ...background, hex_code: val, name: name })
						}}
					/>
				</layouts.Flex>
				<typography.h6 px="5px" {...textBaseProps} {...actionsTextProps}>
					background
				</typography.h6>
				{!readonly && (
					<layouts.Flex flex="1" justifyContent="flex-end" cursor="pointer" onClick={() => onDelete(item.metadata!.id)}>
						<icons.brandguide.Trash width="10px" height="10px" fill={layouts.theme.colors.grey.dark50alpha80} />
					</layouts.Flex>
				)}
			</layouts.Flex>
		</layouts.Flex>
	)
}

ColorWithTextItem.defaultProps = {
	flexDirection: "column",
	position: "relative",
	correct: false,
	readonly: false,
}

interface addColorItemWithtextProps extends layouts.containers.ContainerProps {
	onChange(): void
}

export function AddColorWithTextItem(props: addColorItemWithtextProps): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				alignItems="center"
				justifyContent="center"
				background={layouts.theme.colors.white}
				minHeight="100px"
				border={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`}
				onClick={onChange}
			>
				<typography.h4
					fontSize="16px"
					p="20px"
					textAlign="center"
					color={layouts.theme.colors.grey.dark50}
					{...textBaseProps}
				>
					+
				</typography.h4>
			</layouts.Flex>
			<layouts.Flex
				p="5px"
				className="actions"
				alignItems="center"
				justifyContent="flex-start"
				background={layouts.theme.colors.white}
				color={layouts.theme.colors.grey.dark50}
			>
				<layouts.Flex>
					<typography.h6 className={activeText} {...textBaseProps} {...actionsTextProps}>
						#000000 text with #FFFFFF background
					</typography.h6>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

AddColorWithTextItem.defaultProps = {
	flexDirection: "column",
}

import { SectionTitle } from "brandguide/results/components"
import { default as Primary } from "./primary"
import { default as Secondary } from "./secondary"
import { default as Usage } from "./usage"

export { default as Primary } from "./primary"
export { default as Secondary } from "./secondary"
export { default as Usage } from "./usage"
export * as components from "./components"

export function Section(): JSX.Element {
	return (
		<>
			<SectionTitle title="Colors" className="section-print" />
			<Primary className="section-print" />
			<Secondary className="section-print" />
			<Usage className="section-print" />
		</>
	)
}

import { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as icons from "icons"
import * as inputs from "inputs"
import { css } from "@emotion/css"
import * as uuid from "uuid"

export const styledGap60 = css`
	.content {
		gap: 60px;
	}
`

export const styledCarousel = css`
	.content {
		gap: 5px;
	}
`

const styledTextArea = css`
	font-family: Roboto;
	padding: 10px;
	background: none;
	&:focus {
		outline: none;
	}
	font-family: Roboto;
	border: none;
	height: unset !important;
	line-height: 18px;
	resize: none;
`

export const steps = ["Tone of Voice", "Are & Are Not", "Writing Style"]

interface tavProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ToneOfVoiceObject
	onChange(item: brandguide.api.ToneOfVoiceObject): void
}

export function TovItem(props: tavProps): JSX.Element {
	const { item, onChange, ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flexDirection="column" flex="1" m="20px" justifyContent="center" alignItems="center">
				<typography.h3
					fontSize="16px"
					fontWeight="400"
					color={layouts.theme.colors.grey.dark50alpha80}
					lineHeight="normal"
					mb="18px"
				>
					{item.title}
				</typography.h3>
				<typography.h4
					fontSize="14px"
					fontWeight="700"
					color={layouts.theme.colors.grey.dark50alpha80}
					lineHeight="20px"
					whiteSpace="unset"
				>
					&quot;{item.text}&quot;
				</typography.h4>
			</layouts.Flex>

			<layouts.Flex flexDirection="column" flex="1" px="20px" justifyContent="space-between">
				<layouts.Flex
					borderTop={`1px solid ${layouts.theme.colors.grey.dark50alpha30}`}
					minHeight="17px"
					width="100%"
				/>
				<typography.h5
					textAlign="center"
					fontSize="12px"
					fontWeight="400"
					color={layouts.theme.colors.grey.dark50alpha80}
					lineHeight="18px"
					whiteSpace="unset"
				>
					{item.description}
				</typography.h5>
				<layouts.Flex m="13px" justifyContent="center">
					<layouts.buttons.unstyled
						cursor="pointer"
						borderRadius="5px"
						border={`1px solid ${layouts.theme.colors.grey.dark50alpha30}`}
						width="90px"
						height="25px"
						onClick={() => onChange({ ...item, selected: !item.selected })}
						boxShadow={`0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`}
						background={item.selected ? layouts.theme.colors.blue.blue : layouts.theme.colors.white}
					>
						<typography.h5
							fontSize="14px"
							fontWeight="400"
							color={item.selected ? layouts.theme.colors.white : layouts.theme.colors.grey.dark50alpha50}
						>
							Select
						</typography.h5>
					</layouts.buttons.unstyled>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

TovItem.defaultProps = {
	width: "224px",
	minHeight: "244px",
	borderRadius: "5px",
	background: layouts.theme.colors.grey.dark50alpha10,
	flexDirection: "column",
}

interface wsAssetProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ToneOfVoiceObject
	onChange(item: brandguide.api.ToneOfVoiceObject): void
	onDelete(): void
}

export function WriringStyleAsset(props: wsAssetProps): JSX.Element {
	const { item, onChange, onDelete, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1">
				<inputs.TextArea
					autoFocus
					className={styledTextArea}
					value={item.text}
					onChange={(e) => onChange({ ...item, text: e.target.value })}
					placeholder="add a text sample, like a social post, ad, blog post, or email."
				/>
			</layouts.Flex>
			<layouts.Flex justifyContent="end">
				<layouts.Flex onClick={onDelete}>
					<icons.brandguide.Trash fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

WriringStyleAsset.defaultProps = {
	width: "221px",
	background: layouts.theme.colors.grey.dark50alpha05,
	justifyContent: "center",
	flexDirection: "column",
	p: "20px",
}

interface addWSAssetProps extends layouts.containers.ContainerProps {
	onChange(): void
}

export function AddWriringStyleAsset(props: addWSAssetProps): JSX.Element {
	const { onChange, ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flexDirection="column" alignItems="center" justifyContent="center" onClick={onChange}>
				<typography.h3 fontSize="36px" fontWeight="400" color={layouts.theme.colors.grey.dark50alpha80}>
					+
				</typography.h3>
				<typography.h4 fontSize="16px" fontWeight="400" color={layouts.theme.colors.grey.dark50alpha80}>
					Add new
				</typography.h4>
			</layouts.Flex>
		</layouts.Flex>
	)
}

AddWriringStyleAsset.defaultProps = {
	width: "261px",
	height: "238px",
	background: layouts.theme.colors.grey.dark50alpha05,
	justifyContent: "center",
}

interface adjectiveSectionProps extends layouts.containers.ContainerProps {
	title: string
	items: brandguide.api.ToneOfVoiceAdjective[]
	onChange(items: brandguide.api.ToneOfVoiceAdjective[]): void
}

export function AdjectiveSectionItem(props: adjectiveSectionProps): JSX.Element {
	const { title, items, onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex alignItems="start" justifyContent="center" mb="10px">
				<typography.h5
					fontSize="16px"
					fontWeight="600"
					lineHeight="normal"
					color={layouts.theme.colors.grey.dark50alpha80}
				>
					{title}
				</typography.h5>
			</layouts.Flex>
			<layouts.containers.grid gridTemplateRows="repeat(4, auto)" gridAutoFlow="column" gap="5px">
				{items.map((tova) => (
					<AdjectiveItem key={tova.id} item={tova} onDelete={() => onChange(items.filter((i) => i.id !== tova.id))} />
				))}
			</layouts.containers.grid>
			<AddAdjectiveItem
				items={items}
				onChange={(upd) => {
					onChange([...items, ...[upd]])
				}}
			/>
		</layouts.Flex>
	)
}

AdjectiveSectionItem.defaultProps = {
	flexDirection: "column",
}

interface adjectiveItemProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ToneOfVoiceAdjective
	onDelete(): void
}

export function AdjectiveItem(props: adjectiveItemProps): JSX.Element {
	const { item, onDelete, ...rest } = props

	return (
		<layouts.Flex background={layouts.theme.colors.blue.blue} {...rest}>
			<layouts.Flex flex="1" alignItems="center">
				<layouts.Flex flex="12" justifyContent="center" alignItems="center">
					<typography.h5
						fontSize="14px"
						fontWeight="400"
						lineHeight="normal"
						color={layouts.theme.colors.white}
						overflow="hidden"
						maxWidth="230px"
						style={{ textOverflow: "ellipsis" }}
					>
						{item.name}
					</typography.h5>
				</layouts.Flex>
				<layouts.Flex flex="1" onClick={onDelete}>
					<icons.Close width="10px" height="10px" fill={layouts.theme.colors.white} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

AdjectiveItem.defaultProps = {
	width: "261px",
	height: "37px",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "5px",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha30}`,
	boxShadow: `0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`,
	gap: "10px",
	mt: "5px",
}

const styledInput = css`
	input {
		padding-left: 2px;
		padding-right: 5px;
		:hover,
		:focus {
			outline: none;
			box-shadow: none;
		}
	}
`

interface addAdjectiveItemProps extends layouts.containers.ContainerProps {
	items: brandguide.api.ToneOfVoiceAdjective[]
	onChange(item: brandguide.api.ToneOfVoiceAdjective): void
}

export function AddAdjectiveItem(props: addAdjectiveItemProps): JSX.Element {
	const { items, onChange, ...rest } = props
	const zero = brandguide.api.zeros.tone_of_voice.adjectiveZero({ id: uuid.v4() })
	const [item, setItem] = useState(zero)
	const [focused, setFocused] = useState(false)

	const disabled =
		item.name.length === 0 || items.map((i) => i.name.toLocaleLowerCase()).includes(item.name.toLocaleLowerCase())

	return (
		<layouts.Flex border={`1px ${focused ? "solid" : "dashed"} ${layouts.theme.colors.grey.dark50alpha30}`} {...rest}>
			{focused ? (
				<layouts.Flex>
					<layouts.Flex className={styledInput}>
						<inputs.Text
							type="text"
							defaultValue={item.name}
							placeholder="add text here"
							onChange={(e) => setItem({ ...item, name: e.target.value })}
						/>
					</layouts.Flex>
					<layouts.Flex
						onClick={() => {
							if (disabled) return
							onChange(item)
							// reset form
							setItem(zero)
							setFocused(false)
						}}
					>
						<icons.brandguide.actions.Accept
							fill={disabled ? layouts.theme.colors.grey.dark50 : layouts.theme.colors.blue.blue}
						/>
					</layouts.Flex>
					<layouts.Flex onClick={() => setFocused(false)}>
						<icons.brandguide.actions.Reject fill={layouts.theme.colors.blue.blue} />
					</layouts.Flex>
				</layouts.Flex>
			) : (
				<layouts.Flex onClick={() => setFocused(true)}>
					<typography.h5
						whiteSpace="unset"
						fontSize="14px"
						fontWeight="400"
						lineHeight="normal"
						color={layouts.theme.colors.grey.dark50alpha50}
						px="5px"
					>
						+ Add New
					</typography.h5>
				</layouts.Flex>
			)}
		</layouts.Flex>
	)
}

AddAdjectiveItem.defaultProps = {
	width: "261px",
	height: "37px",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "5px",
	boxShadow: `0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`,
	gap: "10px",
	mt: "10px",
}

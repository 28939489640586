import { PropsWithChildren, useEffect, useState } from "react"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as brandapi from "brands/api"
import { Brand } from "brands/account.brand"
import * as sessions from "sessions"
import { useNavigate } from "react-router-dom"
import * as caching from "./cache"
import * as inputs from "inputs"
import { css } from "@emotion/css"
import * as icons from "icons"

export interface props {
	brands?: Brand[]
	urlgen?(bid: string): string
}

export function zero(c: Partial<props> = {}): props {
	return {
		brands: [],
		...c,
	}
}

export function BrandSelector(props: PropsWithChildren<props & layouts.containers.FlexProps>): JSX.Element {
	const { brands, urlgen, ...rest } = { ...props }
	const navigate = useNavigate()
	const bearertoken = sessions.useToken()
	const brand = caching.useCached()
	const [missing, setMissing] = useState(false)
	const [rbrands, setBrands] = useState(zero({ brands: brands }))
	const [breq, setReq] = useState(brandapi.searches.zero({ limit: 20 }))
	const [focused, setFocused] = useState(false)

	useEffect(() => {
		if (brands!.length > 0) return setBrands(zero({ brands: brands }))

		const retry = httpx.autoretry()
		const req = retry
			.wrap(() => brandapi.search(breq, bearertoken))
			.then((resp) => {
				setBrands(zero({ brands: resp.items }))
				setMissing(resp.items.find((b) => b.id === brand.id) === undefined)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.finally(() => {
				if (!focused && breq.query !== "") setFocused(true)
			})
		return req.cancel
	}, [brands, breq])

	const options = rbrands.brands!.map((b) => ({ value: b.id, label: b.description }))
	if (missing) options.unshift({ value: brand.id, label: brand.description })

	return (
		<layouts.Box className={styledSelect} onClick={() => setFocused(!focused)} {...rest}>
			<inputs.Text
				width="100%"
				height="48px"
				padding="10px"
				paddingRight="40px"
				borderRadius={focused ? "20px 20px 0 0" : "30px"}
				defaultValue={breq.query}
				placeholder={brand.description}
				onChange={(evt) => setReq({ ...breq, query: evt.target.value })}
				onBlur={() => setFocused(false)}
				icon={
					<inputs.icons.right>
						<icons.bgapproval.arrowDown
							p="5px"
							strokeOpacity="1"
							transform={focused ? "matrix(1, 0, 0, -1, 0, 0)" : "matrix(1, 0, 0, 1, 0, 0)"}
						/>
					</inputs.icons.right>
				}
			/>
			{focused && options.length > 0 && (
				<ul>
					{options.map((o) => {
						return (
							<li
								key={o.value}
								title={o.label}
								onMouseDown={() => {
									setFocused(false)
									const newBrand = rbrands.brands!.find((e) => e.id === o.value)
									urlgen && navigate(urlgen(newBrand!.id))
								}}
							>
								{o.label}
							</li>
						)
					})}
				</ul>
			)}
		</layouts.Box>
	)
}

BrandSelector.defaultProps = {
	brands: [],
	urlgen: (bid: string) => "",
}

const styledSelect = css`
	input::placeholder {
		font-weight: 700;
		line-height: 20px;
		color: ${layouts.theme.colors.grey.dark50};
	}

	input:hover,
	input:focus {
		outline: 1px solid ${layouts.theme.colors.grey.dark50alpha05};
		box-shadow: none;
	}
	ul {
		position: relative;
		z-index: 1;
		list-style-type: none;
		overflow-y: auto;
		overflow-x: hidden;
		border-radius: 0px 0px 10px 10px;
		outline: 1px solid ${layouts.theme.colors.grey.dark50alpha05};
		font-size: 12px;
		line-height: 20px;
		background-color: ${layouts.theme.colors.white};

		li {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			cursor: pointer;
			padding: 7px 15px;
			border-bottom: 1px solid ${layouts.theme.colors.grey.dark50alpha05};
			color: ${layouts.theme.colors.grey.dark50alpha80};
			:hover {
				color: ${layouts.theme.colors.blue.blue};
			}
		}
	}
`

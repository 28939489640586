import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as color_components from "./components"

const max_columns_cnt = 5

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const items = guide.guide?.colors?.secondary_colors || []
	const rows = Math.ceil(items.length / max_columns_cnt)
	const columns = items.length >= max_columns_cnt ? max_columns_cnt : items.length

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Color Palette</result_components.NavTitle>
				<result_components.NavDescription>
					Color is a valuable asset for the brand identity. It helps us gain recognition through consistent use of
					color.
				</result_components.NavDescription>
				<result_components.Explanation desc={guide.guide?.colors?.descriptions?.secondary_colors} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer>
					<layouts.Flex>
						<typography.h4 color={colors.grey.dark50} lineHeight="50px">
							Secondary Color Palette
						</typography.h4>
					</layouts.Flex>
					<layouts.containers.grid
						gap="10px"
						gridTemplateRows={`repeat(${rows}, 1fr)`}
						gridTemplateColumns={`repeat(${columns}, 1fr)`}
						flex="1"
					>
						{items.map((i, index) => (
							<color_components.Card key={index} item={i} />
						))}
					</layouts.containers.grid>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

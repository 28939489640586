import { Route, Routes, Navigate } from "react-router-dom"
import * as brands from "brands"
import * as layouts from "layouts"
import {
	MetricsContainer,
	MetricsApprovalsActiveLink,
	MetricsTrainingActiveLink,
	MetricsBrandgptActiveLink,
} from "./container"
import MetricsDisplayApproval from "./display.approval"
import MetricsDisplayTraining from "./display.training"
import MetricsDisplayBrandgpt from "./display.brandgpt"
import * as routing from "./routing"

export function MetricsApprovals(): JSX.Element {
	return (
		<MetricsContainer urlgen={routing.metrics_approvals}>
			<layouts.containers.flex flexDirection="column" height="100%" overflow="hidden" data-testid="metrics-approvals">
				<MetricsApprovalsActiveLink />
				<MetricsDisplayApproval />
			</layouts.containers.flex>
		</MetricsContainer>
	)
}

export function MetricsTraining(): JSX.Element {
	return (
		<MetricsContainer urlgen={routing.metrics_training}>
			<layouts.containers.flex flexDirection="column" height="100%" overflow="hidden" data-testid="metrics-training">
				<MetricsTrainingActiveLink />
				<MetricsDisplayTraining />
			</layouts.containers.flex>
		</MetricsContainer>
	)
}

export function MetricsBrandgpt(): JSX.Element {
	return (
		<MetricsContainer urlgen={routing.metrics_brandgpt}>
			<layouts.containers.flex flexDirection="column" height="100%" overflow="hidden" data-testid="metrics-brandgpt">
				<MetricsBrandgptActiveLink />
				<MetricsDisplayBrandgpt />
			</layouts.containers.flex>
		</MetricsContainer>
	)
}

export default function Routing(): JSX.Element {
	const brand = brands.caching.useCached()

	return (
		<layouts.backgrounds.Grey flex="0 1 100%" height="100%" overflowY="hidden">
			<Routes>
				<Route path="/approvals" element={<MetricsApprovals />} />
				<Route path="/training" element={<MetricsTraining />} />
				<Route path="/brandgpt" element={<MetricsBrandgpt />} />
				<Route path="*" element={<Navigate to={routing.metrics_approvals(brand.id)} replace />} />
			</Routes>
		</layouts.backgrounds.Grey>
	)
}

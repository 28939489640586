import React, { useEffect, useState } from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as errors from "errors"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as api from "brandguard/api"
import * as bgscores from "brandguard/display/scores"
import { ScoreAccordionDescription, SubSubScoreContainer } from "brandguard/components/layouts/scores"
import * as icons from "icons"
import * as brands from "brands"
import * as bgimages from "brandguard/display/brandguard/images"
import * as uuid from "uuid"

interface props extends layouts.containers.FlexProps {
	event: api.Media
}

export default function Display(props: React.PropsWithChildren<props>): JSX.Element {
	const { className, event, children, ...rest } = props
	const brand = brands.caching.useCached()
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)
	const [req, setReq] = useState(
		api.explanations.fontdetection.searches.request({
			event_ids: [event.id],
			brand_id: brand.id,
			limit: 20n,
		}),
	)

	const [fonts, setFonts] = useState({
		next: req,
		items: [],
	} as api.FontDetectionExplanationSearchResponse)
	const [loading, setloading] = useState(false)

	const [focused, setFocused] = useState(undefined as JSX.Element[] | undefined)

	const overlay = bgimages.overlays.useCached()
	const [overlayKey] = useState(uuid.v4())

	useEffect(() => {
		setloading(true)
		const retry = httpx.autoretry()
		const pending = retry
			.wrap(() => api.explanations.fontdetection.search(req, authzc.bearer(metaauthz)))
			.then(setFonts)
			.catch(httpx.errors.cancellation(console.debug))
			.catch((cause) => {
				setCause(<errors.UnknownCause logging={console.error} cause={cause} onClick={() => setCause(undefined)} />)
			})
			.finally(() => setloading(false))

		return pending.cancel
	}, [req])

	useEffect(() => {
		if (!focused) {
			overlay.update({
				...overlay,
				items: overlay.items.filter((ov) => ov.uuid !== overlayKey),
			})
			return
		}
		const upd = focused.map((f) => {
			return { uuid: overlayKey, position: 3, element: f }
		})
		overlay.update({ ...overlay, items: [...overlay.items, ...upd] })
		return () =>
			overlay.update({
				...overlay,
				items: overlay.items.filter((ov) => ov.uuid !== overlayKey),
			})
	}, [focused])

	useEffect(() => {
		setReq({ ...req, event_ids: [event.id] })
	}, [event.id])

	if (loading)
		return (
			<SubSubScoreContainer className={className}>
				<layouts.containers.flex flexDirection="row" flex="1">
					<layouts.containers.flex flex="3" justifyContent="flex-start" alignItems="center" color={"unset"}>
						Correct Font(s)
					</layouts.containers.flex>
					<layouts.containers.flex flex="1" justifyContent="flex-end" alignItems="center">
						<icons.loading.Ring3 width="14px" height="14px" />
					</layouts.containers.flex>
				</layouts.containers.flex>
				<bgscores.PercentageBar weight={0} />
			</SubSubScoreContainer>
		)
	if (cause) return <layouts.Flex p="10px">{cause}</layouts.Flex>
	if (fonts.items.length === 0) return <>{children}</>

	return (
		<bgscores.SectionAccordion
			borderWidth="0"
			borderRadius="0"
			borderColor="unset"
			borderStyle="unset"
			borderBottom="none"
			my="0"
			className={classnames(className, bgscores.layouts.styledAccordion)}
			{...rest}
		>
			<layouts.containers.box tabIndex={0}>
				<bgscores.SectionDescription>
					<ScoreAccordionDescription padding="0" className={className}>
						{children}
					</ScoreAccordionDescription>
				</bgscores.SectionDescription>
				<layouts.accordions.Content>
					<layouts.Flex
						flexDirection="column"
						flex="1"
						pr="54px"
						className={classnames("bcexplanations.list", className)}
					>
						<errors.overlay styled cause={cause} flex="1">
							<layouts.Flex flexDirection="column" fontWeight="700" pl="35px" lineHeight="14px" my="10px">
								<layouts.Flex alignItems="center" gridGap="10px">
									<layouts.toggles.Display
										active={!!focused}
										onClick={() => {
											const upd = () => {
												if (focused) return undefined
												return fonts.items.map((i, index) => (
													<bgscores.shared.images.OverlayByPrecision key={index} event={event} item={i} />
												))
											}
											setFocused(upd)
										}}
									/>
									<layouts.Flex>Show Detected Font(s)</layouts.Flex>
								</layouts.Flex>
								{fonts.items.map((v) => (
									<layouts.Flex key={v.id} alignItems="center" gridGap="10px" mt="5px">
										<bgscores.shared.images.CroppedImage event={event} item={v} />
										<layouts.Flex>{v.description}</layouts.Flex>
									</layouts.Flex>
								))}
							</layouts.Flex>
						</errors.overlay>
						{fonts.next!.offset > 0 && <Pagination req={fonts.next!} onChange={(upd) => setReq(upd)} />}
					</layouts.Flex>
				</layouts.accordions.Content>
			</layouts.containers.box>
		</bgscores.SectionAccordion>
	)
}

interface paginationProps {
	req: api.LogoClearspaceExplanationSearchRequest
	onChange(upd: api.LogoClearspaceExplanationSearchRequest): void
}

export function Pagination(props: paginationProps): JSX.Element {
	const { req, onChange } = props
	return (
		<layouts.pagination.Cursor
			my="auto"
			justifyContent="center"
			current={req.offset}
			advance={Number(req.offset) === -1 ? undefined : req.offset}
			onChange={(next) => {
				onChange({
					...req,
					offset: next,
				})
			}}
		/>
	)
}

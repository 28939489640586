import React from "react"
import * as layouts from "layouts"
import * as api from "brandguard/api"
import styled from "@emotion/styled"
import * as system from "styled-system"
import * as icons from "icons"

const StatusText = styled(layouts.containers.flex)`
	align-items: center;
	text-transform: capitalize;
	font-size: 12pt;
	font-weight: 400;
	line-height: 20px;
	color: ${layouts.theme.colors.white};
	${system.background}
`
StatusText.defaultProps = {
	background: layouts.theme.colors.grey.dark10,
}

function color(p: api.Prediction = api.Prediction.NONE): string {
	if (p === api.Prediction.REJECTED) return layouts.theme.colors.red.cinnabar
	if (p === api.Prediction.APPROVED) return layouts.theme.colors.blue.blue

	return layouts.theme.colors.grey.light80
}

function text(p: api.Prediction = api.Prediction.NONE): string {
	if (p === api.Prediction.REJECTED) return "Off-Brand"
	if (p === api.Prediction.APPROVED) return "On-Brand"
	return "processing"
}

function icon(p: api.Prediction = api.Prediction.NONE): JSX.Element {
	if (p === api.Prediction.REJECTED) return <icons.prediction.rejected width="20px" height="20px" />
	if (p === api.Prediction.APPROVED) return <icons.prediction.approved width="20px" height="20px" />
	return <icons.prediction.processing />
}

export default function Status(props: { prediction: api.Prediction } & layouts.containers.FlexProps): JSX.Element {
	const { prediction, ...rest } = props

	return (
		<StatusText background={color(prediction)} {...rest}>
			{icon(prediction)}
			<layouts.containers.span ml="10px">{text(prediction)}</layouts.containers.span>
		</StatusText>
	)
}

Status.defaultProps = {
	prediction: api.Prediction.NONE,
	px: "10px",
	height: "25px",
}

type searchResponseItem = api.ImageSearchResponseItem | api.TextSearchResponseItem

interface props {
	item: searchResponseItem
	previtemstate: searchResponseItem | undefined
	agree(item: searchResponseItem): Promise<searchResponseItem>
	disagree(item: searchResponseItem): Promise<searchResponseItem>
	undo(current: searchResponseItem, previously: searchResponseItem): Promise<searchResponseItem>
}

export const ActionButton = styled(layouts.buttons.outline)`
	border: 1px solid ${layouts.theme.colors.white};
	color: ${layouts.theme.colors.white};
	font-size: 12px;
	font-weight: 400;
	background-color: unset;
	padding: 5px;
	margin-right: 10px;
	&:hover {
		background-color: ${layouts.theme.backgrounds.dark50alpha20};
		color: unset;
	}
`

export function Container(props: React.PropsWithChildren): JSX.Element {
	const { children } = props
	return (
		<layouts.containers.flex
			flex="1"
			minHeight="35px"
			maxHeight="35px"
			fontSize="12pt"
			fontWeight="400px"
			color={layouts.theme.colors.white}
		>
			{children}
		</layouts.containers.flex>
	)
}

export function Suggestion(props: props & layouts.containers.FlexProps): JSX.Element {
	const { item, previtemstate, agree, disagree, undo, ...rest } = props

	const _approve = <ActionButton onClick={() => agree(item)}>YES</ActionButton>
	const _reject = <ActionButton onClick={(evt) => disagree(item)}>NO</ActionButton>
	const _undo = previtemstate ? (
		<ActionButton onClick={(evt) => undo(item, previtemstate)}>UNDO</ActionButton>
	) : undefined

	return (
		<layouts.containers.flex background={color(item.event?.observation)} {...rest}>
			{_undo ? (
				<>
					<layouts.containers.flex px="10px">Sent to {text(item.event?.observation)} Assets</layouts.containers.flex>
					{_undo}
				</>
			) : (
				<>
					<layouts.containers.flex px="10px">Is this On-Brand?</layouts.containers.flex>
					{_approve}
					{_reject}
				</>
			)}
		</layouts.containers.flex>
	)
}

Suggestion.defaultProps = {
	flex: "1",
	justifyContent: "flex-start",
	alignItems: "center",
}

import { option, get, post, put, destroy } from "./index"

// client is for specifying predefined options (like authentication and authorization credentials)
export class Client {
	defaults: option[]

	constructor(...options: option[]) {
		this.defaults = options
	}

	get<T>(path: string, data = {}, ...options: option[]): Promise<T> {
		return get<T>(path, data, ...[...this.defaults, ...options])
	}

	post<T>(path: string, data = {}, ...options: option[]): Promise<T> {
		return post<T>(path, data, ...[...this.defaults, ...options])
	}

	put<T>(path: string, data = {}, ...options: option[]): Promise<T> {
		return put<T>(path, data, ...[...this.defaults, ...options])
	}

	destroy<T>(path: string, data = {}, ...options: option[]): Promise<T> {
		return destroy<T>(path, data, ...[...this.defaults, ...options])
	}
}

import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as system from "styled-system"

export const ActionPanel = styled(layouts.containers.flex)`
	justify-content: space-between;
	height: 25px;
	bottom: 0px;
	padding: 2px 9px;
	color: ${layouts.theme.colors.white};
	${system.background}
	&.extension {
		padding: 2px 0;
	}
`

ActionPanel.defaultProps = {
	background: layouts.theme.colors.grey.dark10,
}

export const StatusText = styled(layouts.containers.flex)`
	align-items: center;
	text-transform: capitalize;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	color: ${layouts.theme.colors.white};
	transition: 0.3s;
	${system.background}
`
StatusText.defaultProps = {
	background: layouts.theme.colors.grey.dark10,
}

export const FeedbackContainer = styled(layouts.containers.flex)`
	align-items: center;
	font-size: 12px;
	font-weight: 400;
	&.extension {
		justify-content: space-between;
		width: 100%;
	}
`

export const FeedbackOption = styled(layouts.containers.box)<{ active?: boolean }>`
	position: relative;
	width: 25px;
	height: 15px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	border: 0.5px solid ${layouts.theme.colors.white};
	color: ${layouts.theme.colors.white};
	&:not(:last-of-type) {
		margin-right: 10px;
	}
	transition: 0.5s;
	&:hover {
		border-color: ${layouts.theme.colors.space_cadet};
		color: ${layouts.theme.colors.space_cadet};
	}
	&:before {
		position: absolute;
		width: 55px;
		height: 13px;
		left: -50%;
		background: ${layouts.theme.colors.grey.dark5};
		border-radius: 3px;
		bottom: 19px;
		color: ${layouts.theme.colors.white};
		content: attr(data-tooltip);
		display: block;
		white-space: nowrap;
		text-transform: none;
		font-weight: 400;
		font-size: 10px;
		line-height: 13px;
		opacity: 0;
		transition: 0.5s;
	}
	&:hover:before {
		opacity: 1;
	}
`

export const FeedbackOptionUndo = styled(FeedbackOption)`
	width: 45px;
	&:hover {
		border-color: ${layouts.theme.colors.white};
		color: ${layouts.theme.colors.white};
	}
`

export function FeedbackYes(props: { active?: boolean } & React.DOMAttributes<HTMLDivElement>): JSX.Element {
	const { ...rest } = props
	return (
		<FeedbackOption data-tooltip="I agree" {...rest}>
			YES
		</FeedbackOption>
	)
}

export function FeedbackNo(props: { active?: boolean } & React.DOMAttributes<HTMLDivElement>): JSX.Element {
	const { ...rest } = props
	return (
		<FeedbackOption data-tooltip="I disagree" {...rest}>
			NO
		</FeedbackOption>
	)
}

export function FeedbackUndo(props: { active?: boolean } & React.DOMAttributes<HTMLDivElement>): JSX.Element {
	const { ...rest } = props
	return (
		<FeedbackOptionUndo data-tooltip="Undo" {...rest}>
			UNDO
		</FeedbackOptionUndo>
	)
}

/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "meta.search.filters.proto" (package "meta", syntax proto3)
// tslint:disable
// @ts-nocheck
/**
 * @generated from protobuf enum meta.GroupBy
 */
export enum GroupBy {
    /**
     * @generated from protobuf enum value: UPLOADER = 0;
     */
    UPLOADER = 0,
    /**
     * @generated from protobuf enum value: REVIEWER = 1;
     */
    REVIEWER = 1
}

export function root(bid: string): string {
	return `/brands/${bid}/brandguide`
}

export function start(bid: string): string {
	return `${root(bid)}/start`
}

export function redirect(bid: string): string {
	return `${root(bid)}/redirect`
}

export namespace build {
	export function build_root(bid: string): string {
		return `${root(bid)}/build`
	}

	export function colors(bid: string): string {
		return `${build_root(bid)}/colors`
	}

	export function logos(bid: string): string {
		return `${build_root(bid)}/logos`
	}

	export function typography(bid: string): string {
		return `${build_root(bid)}/typography`
	}

	export function imagery(bid: string): string {
		return `${build_root(bid)}/imagery`
	}

	export function tone_of_voice(bid: string): string {
		return `${build_root(bid)}/tov`
	}

	export function archetype(bid: string): string {
		return `${build_root(bid)}/archetype`
	}

	export function application(bid: string): string {
		return `${build_root(bid)}/application`
	}

	export function compliance(bid: string): string {
		return `${build_root(bid)}/compliance`
	}
}

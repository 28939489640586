import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as api from "brandguard/api"
import * as profiles from "profiles"
import * as errors from "errors"
import * as authzc from "authzcached"
import styled from "@emotion/styled"
import * as inputs from "inputs"
import { css } from "@emotion/css"
import * as uuid from "uuid"
import * as httpx from "httpx"

export const Input = styled.input<layouts.containers.FlexProps>`
	max-width: 50px;
	border-radius: 5px;
	box-sizing: border-box;
	margin-right: 2px;
	text-align: center;
	background: ${layouts.theme.backgrounds.dark50alpha20};
	color: ${layouts.theme.colors.grey.dark20};
	border: 1px solid ${layouts.theme.colors.grey.dark50alpha60};
	&:focus {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}

	&:hover {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue.blue};
		box-shadow: ${layouts.theme.boxshadow.default};
	}
`

type SearchRequest = api.ImageSearchRequest | api.TextSearchRequest

export interface props {
	_searchReq: SearchRequest
	onFilterClear(): void
	onFilterChange(updSearchReq: SearchRequest): void
}

export function FilterDisplay(props: React.PropsWithChildren<props>): JSX.Element {
	const { _searchReq, onFilterClear, onFilterChange, children } = props
	const [metaauthz] = useState(authzc.useCache((cached) => cached.meta))
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)

	const pzero = profiles.api.searches.zero({ limit: 20n, enabled: profiles.api.StatusFilters.NotPending })
	const [preq, setProfilesReq] = useState(pzero)
	const [pset, setProfiles] = useState<profiles.api.proto.SearchResponse>({
		items: [],
		next: preq,
	})

	const [focused, setFocused] = useState(false)
	const [selectKey, setSelectKey] = useState(uuid.v4())

	const [uploadedBy, setUploadedBy] = useState(_searchReq.uploaded_by)
	const [selectedProfile, setSelectedProfile] = useState("All")

	const confidenceZero = { min: 0, max: 100 } as api.FloatRange
	const [confidence, setConfidence] = useState(confidenceZero)

	useEffect(() => {
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => profiles.api.search(preq, authzc.bearer(metaauthz)))
			.then(setProfiles)
			.catch((cause) => {
				setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
			})
			.finally(() => {
				if (!focused && preq.query !== "") setFocused(true)
			})
		return p.cancel
	}, [preq])

	useEffect(() => {
		onFilterChange({ uploaded_by: uploadedBy } as SearchRequest)
	}, [uploadedBy])

	useEffect(() => {
		const correct = { min: confidence.min / 100, max: confidence.max / 100 }
		onFilterChange({ confidence: correct } as SearchRequest)
	}, [confidence])

	const options = pset.items.map((p) => ({ value: p.id, label: p.display }))

	return (
		<layouts.containers.flex
			minWidth="400px"
			minHeight="225px"
			background="white"
			borderRadius="5px"
			justifyContent="flex-start"
			flexDirection="column"
			boxShadow={layouts.theme.boxshadow.black.wide}
		>
			{cause}
			<layouts.containers.flex flexDirection="row" px="20px" pt="10px" flex="1" maxHeight="30px">
				<layouts.containers.flex>
					<typography.h3 color={layouts.theme.colors.grey.medium} fontSize="18px" fontWeight="600">
						Filter Assets
					</typography.h3>
				</layouts.containers.flex>
				<layouts.containers.flex
					flex="1"
					justifyContent="flex-end"
					onClick={() => {
						setUploadedBy(_searchReq.uploaded_by)
						setConfidence(confidenceZero)
						setProfilesReq(pzero)
						setSelectedProfile("All")
						setSelectKey(uuid.v4())
						onFilterClear()
					}}
					cursor="pointer"
				>
					<typography.h6 color={layouts.theme.colors.grey.medium} fontSize="14px" fontWeight="600">
						Reset
					</typography.h6>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex
				flexDirection="row"
				flex="10"
				px="20px"
				py="10px"
				justifyContent="flex-start"
				maxHeight="40px"
			>
				<layouts.containers.flex
					flex="3"
					color={layouts.theme.colors.grey.medium}
					fontSize="16px"
					fontWeight="400"
					mt="7px"
				>
					Uploaded By
				</layouts.containers.flex>
				<layouts.containers.flex flex="5" alignItems="center" flexDirection="column">
					<layouts.Box className={styledSearchSelect} onClick={() => setFocused(!focused)}>
						<inputs.Text
							key={selectKey}
							height="31px"
							borderRadius="5px"
							defaultValue={preq.query}
							placeholder={selectedProfile}
							onChange={(evt) => setProfilesReq({ ...preq, query: evt.target.value })}
							onBlur={() => setFocused(false)}
						/>
						{focused && options.length > 0 && (
							<ul>
								{selectedProfile !== "All" && (
									<li
										onMouseDown={() => {
											setFocused(false)
											setProfilesReq(pzero)
											setUploadedBy([])
											setSelectedProfile("All")
											setSelectKey(uuid.v4())
										}}
									>
										All
									</li>
								)}
								{options.map((o) => {
									return (
										<li
											key={o.value}
											title={o.label}
											onMouseDown={() => {
												setFocused(false)
												setProfilesReq(pzero)
												setSelectedProfile(o.label)
												setUploadedBy([o.value])
												setSelectKey(uuid.v4())
											}}
										>
											{o.label}
										</li>
									)
								})}
							</ul>
						)}
					</layouts.Box>
				</layouts.containers.flex>
			</layouts.containers.flex>
			{children}
			<layouts.containers.flex flexDirection="row" pt="25px" borderBottom={layouts.theme.borders.grey.dark50alpha} />

			{/* Remains commented out until we remove Frontend interim fix for BrandGuard scores being polarized e.g. only 0 or 100 scores */}
			{/* <layouts.containers.flex
				flexDirection="row"
				flex="10"
				px="20px"
				py="20px"
				justifyContent="flex-start"
				color={layouts.theme.colors.grey.medium}
				fontSize="16px"
				fontWeight="400"
				alignItems="center"
			>
				<layouts.containers.flex flex="5">Assets scores from</layouts.containers.flex>
				<Input
					type="number"
					min={0}
					max={100}
					step={1}
					value={confidence.min}
					onChange={(evt) => setConfidence((prevState) => ({ ...prevState, min: Number(evt.target.value) }))}
				/>
				%
				<layouts.containers.flex flex="1" justifyContent="center" px="5px">
					to
				</layouts.containers.flex>
				<Input
					type="number"
					min={0}
					max={100}
					step={1}
					value={confidence.max}
					onChange={(evt) => setConfidence((prevState) => ({ ...prevState, max: Number(evt.target.value) }))}
				/>
				%
			</layouts.containers.flex> */}
		</layouts.containers.flex>
	)
}

export function Checkbox(
	props: { description: string; checked: boolean; onChange(): void } & layouts.containers.FlexProps,
): JSX.Element {
	const { description, checked, onChange } = props
	return (
		<layouts.containers.flex
			flexDirection="row"
			flex="10"
			px="20px"
			py="10px"
			justifyContent="flex-start"
			maxHeight="10px"
		>
			<layouts.containers.flex
				flex="1"
				color={layouts.theme.colors.grey.medium}
				fontSize="16px"
				fontWeight="400"
				alignItems="center"
			>
				<input type="checkbox" checked={checked} onChange={onChange} />
			</layouts.containers.flex>
			<layouts.containers.flex flex="10" alignItems="center" justifyContent="flex-start">
				<typography.h3 color={layouts.theme.colors.grey.medium} fontSize="14px" fontWeight="400">
					{description}
				</typography.h3>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

export function CalculateFilterSCount(zero: SearchRequest, req: SearchRequest): number {
	let cnt = 0
	if (req.confidence?.min !== zero.confidence?.min || req.confidence?.max !== zero.confidence?.max) cnt = cnt + 1
	if (req.approved.length !== zero.approved.length) cnt = cnt + 1
	if (req.observation.length !== zero.observation.length) cnt = cnt + 1
	if (req.uploaded_by.length !== zero.uploaded_by.length) cnt = cnt + 1
	return cnt
}

const styledSearchSelect = css`
	input {
		padding-left: 5px;
		background: ${layouts.theme.backgrounds.dark50alpha20};
		color: ${layouts.theme.colors.grey.dark20};
		border: 1px solid ${layouts.theme.colors.grey.dark50alpha60};
	}
	input:hover,
	input:focus {
		outline: none;
		box-shadow: none;
	}
	ul {
		position: relative;
		z-index: 1;
		list-style-type: none;
		overflow-y: auto;
		overflow-x: hidden;
		margin-top: 2px;
		border-radius: 10px;
		border: 1px solid ${layouts.theme.colors.grey.dark50alpha05};
		font-size: 16px;
		background-color: ${layouts.theme.colors.white};

		li {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			cursor: pointer;
			padding: 7px 9px;
			border-bottom: 1px solid ${layouts.theme.colors.grey.dark50alpha05};
			color: ${layouts.theme.colors.grey.medium};
			:hover {
				background-color: ${layouts.theme.colors.grey.dark50alpha05};
			}
		}
	}
`

import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import * as httpx from "httpx"
import * as caching from "caching"
import * as api from "./api"
import * as timex from "timex"
import * as uuid from "uuid"
import * as layouts from "layouts"
import * as sessions from "sessions"
import * as routing from "./routing"
import { Brand } from "./account.brand"
import { CancellablePromise } from "real-cancellable-promise"
import * as navigation from "navigation"

export const Context = React.createContext<api.Brand>(api.zero())

export const Provider = Context.Provider

export const cache = new caching.Cache({ namespace: "novacloud.brands", ttl: timex.duration.iso("PT1H").toMillis() })

function cachable(bid: string, ...options: httpx.option[]): CancellablePromise<Brand> {
	return api.get(bid, ...options).then((r) => r.brand)
}

// get or load a brand by id.
export function current(bid: string, ...options: httpx.option[]): CancellablePromise<Brand> {
	return cache.maybe(bid, () => cachable(bid, ...options))
}

export function useCached(): Brand {
	return useContext(Context)
}

interface props {
	bid?: string
	autonav_disabled: boolean
}

export function Node(props: React.PropsWithChildren<props>): JSX.Element {
	const { bid } = props
	const location = useLocation()
	const navigate = useNavigate()
	const bearertoken = sessions.useToken()
	const account = sessions.useAccount()
	const [brand, setBrand] = useState(api.zero())
	const [loading, setLoading] = useState(true) // only care about initial load
	const nav = navigation.context.useCached()

	const [focused, setFocused] = useState(bid)

	useEffect(() => {
		if (bid === focused) return
		setLoading(true)
		setFocused(bid)
	}, [location.pathname])

	useEffect(() => {
		if (bid && uuid.validate(bid)) {
			nav.update({ ...nav, bid })
			//we always set the actual brand here
			api.setLastBrandId(account.id, bid)
		}
		const brandp = api
			.getLastBrandId(account.id)
			.then((bid) => (bid === undefined ? CancellablePromise.resolve(api.zero()) : current(bid, bearertoken)))
		brandp
			.catch(
				httpx.errors.notFound((cause) => {
					console.debug("unable to retrieve latest brand", cause)
					return api.zero()
				}),
			)
			.then((brand: api.Brand) => {
				// handle detecting if we need to select a brand
				if (!props.autonav_disabled && brand.id === uuid.NIL && location.pathname !== routing.unknown()) {
					setImmediate(() => navigate(routing.unknown()))
					return
				}
				setBrand(brand)
				setLoading(false)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				console.error(cause)
				setLoading(false)
			})

		return brandp.cancel
	}, [focused])

	return (
		<layouts.loading.pending className="brand-cache" loading={loading}>
			<Provider key={bid} value={brand}>
				{props.children}
			</Provider>
		</layouts.loading.pending>
	)
}

Node.defaultProps = {
	autonav_disabled: false,
}

export default cache

import React, { useState } from "react"
import * as layouts from "layouts"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as typography from "typography"
import * as domains from "sso/domains"
import * as um from "meta/usermanagement"
import * as sessions from "sessions"
import * as uuid from "uuid"
import * as httpx from "httpx"

export default function Display(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const session = sessions.useSession()

	const _req = domains.api.searches.zero({ limit: 20n })
	const [req, setReq] = useState(_req)
	const [dsKey, setDsKey] = useState(uuid.v4())
	const mtoggle = layouts.modals.useToggle()

	return (
		<>
			<layouts.containers.flex
				flexDirection="row"
				flex="2"
				background={layouts.theme.colors.white}
				mt="10px"
				overflow="hidden"
				{...rest}
			>
				<layouts.containers.flex flexDirection="column" flex="2" py="50px" px="40px" minWidth="150px" overflowY="auto">
					<typography.h3
						fontSize="16px"
						fontWeight="700"
						lineHeight="30px"
						color={layouts.theme.colors.grey.dark50}
						mb="5px"
					>
						Account {session.account.description}
					</typography.h3>
					<typography.h6
						fontSize="12px"
						fontWeight="400"
						lineHeight="20px"
						color={layouts.theme.colors.grey.dark50}
						whiteSpace="unset"
					>
						<authz.Protected
							enabled={permission.usermanagement}
							rejected={
								<>You do not have permissions to edit account settings and manage domains. Please contact an admin.</>
							}
						>
							Edit account settings and manage your domains
						</authz.Protected>
					</typography.h6>
					<authz.Protected enabled={permission.usermanagement}>
						<layouts.Flex py="20px">
							<layouts.buttons.primary
								onClick={() => mtoggle(<um.AccountForm onSuccess={() => mtoggle(undefined)} />)}
								borderRadius="37px"
								width="151px"
								height="35px"
							>
								Edit Account
							</layouts.buttons.primary>
						</layouts.Flex>

						<layouts.Flex py="20px">
							<layouts.buttons.primary
								onClick={() =>
									mtoggle(
										<layouts.containers.flex
											flexDirection="column"
											fontSize="14px"
											lineHeight="20px"
											flex="1"
											p="10px"
											minWidth="700px"
											background={layouts.theme.colors.grey.bg}
											minHeight="300px"
											styled
										>
											<typography.h3 color={layouts.theme.colors.grey.medium} textAlign="left">
												Add New Link
											</typography.h3>
											<domains.layouts.EditDomain
												current={domains.api.zero()}
												onChange={async (upd) => {
													const retry = httpx.autoretry()
													return retry
														.wrap(() =>
															domains.api.create(
																{
																	domain: upd,
																},
																authzc.bearer(metaauthz),
															),
														)
														.then((r) => {
															setReq(_req)
															setDsKey(uuid.v4())
															return r.domain!
														})
														.finally(() => mtoggle(undefined))
												}}
											/>
										</layouts.containers.flex>,
									)
								}
								borderRadius="37px"
								width="151px"
								height="35px"
							>
								Generate New Link
							</layouts.buttons.primary>
						</layouts.Flex>
					</authz.Protected>
				</layouts.containers.flex>
				<layouts.containers.flex flex="7">
					<layouts.containers.flex px="10px" flex="1" flexDirection="column">
						<um.Search
							showStatus={false}
							req={req}
							onChange={(r) => {
								setReq({ ...req, query: r.query, created: r.created })
								setDsKey(uuid.v4())
							}}
							reset={() => {
								setReq(_req)
								setDsKey(uuid.v4())
							}}
						/>

						<domains.Display key={dsKey} req={req} />
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</>
	)
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as api from "brandguard/api"
import * as bglayouts from "brandguard/components/layouts"
import * as timex from "timex"
import * as icons from "icons"
import JSON from "jsonx"

interface props {
	item: api.TextSearchResponseItem
}

export default function Debug(props: props): JSX.Element {
	const { item } = props
	const [isCopied, setIsCopied] = useState(false)
	const handleCopyClick = () => {
		const content = JSON.stringify(item, undefined, 2)
		const textblob = new Blob([content], { type: "text/plain" })
		// eslint-disable-next-line no-undef
		const data = [new ClipboardItem({ [textblob.type]: textblob })]
		navigator.clipboard.write(data).then(() => {
			setIsCopied(true)
			setTimeout(() => {
				setIsCopied(false)
			}, 2000)
		})
	}

	return (
		<bglayouts.fileDetails.InformationGroup>
			<layouts.containers.flex flexDirection="row" justifyContent="space-between">
				<bglayouts.fileDetails.InformationGroupTitle>Debug</bglayouts.fileDetails.InformationGroupTitle>
				<layouts.containers.flex title="Copy to clipboard" onClick={handleCopyClick}>
					{!isCopied ? <icons.Clipboard /> : <layouts.containers.span>Copied!</layouts.containers.span>}
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.box>
				<typography.h6>ID</typography.h6>
				<layouts.containers.span>{item.event?.id}</layouts.containers.span>
			</layouts.containers.box>
			<layouts.containers.box>
				<typography.h6>Created</typography.h6>
				<layouts.containers.span>
					{timex.from.iso(item.media!.created_at).toLocaleString(timex.DateTime.DATETIME_SHORT)}
				</layouts.containers.span>
			</layouts.containers.box>
			<layouts.containers.box>
				<typography.h6>Exported deadline</typography.h6>
				<layouts.containers.span>
					{timex.from
						.iso(item.media!.scored_at)
						.startOf("hour")
						.plus(timex.duration.hours(2))
						.toLocaleString(timex.DateTime.DATETIME_SHORT)}
				</layouts.containers.span>
			</layouts.containers.box>
			<layouts.containers.box>
				<typography.h6>Trained deadline</typography.h6>
				<layouts.containers.span>
					{timex.from
						.iso(item.media!.scored_at)
						.startOf("hour")
						.plus(timex.duration.hours(3))
						.toLocaleString(timex.DateTime.DATETIME_SHORT)}
				</layouts.containers.span>
			</layouts.containers.box>
			<layouts.containers.box>
				<typography.h6>Scored</typography.h6>
				<layouts.containers.span>
					{timex.from.iso(item.media!.scored_at).toFormat("LLL dd yyyy")}
				</layouts.containers.span>
			</layouts.containers.box>
			<layouts.containers.box>
				<typography.h6>Approved</typography.h6>
				<layouts.containers.span>{api.Prediction[item.event!.approved]}</layouts.containers.span>
			</layouts.containers.box>
			<layouts.containers.box>
				<typography.h6>Observation</typography.h6>
				<layouts.containers.span>{api.Prediction[item.event!.observation]}</layouts.containers.span>
			</layouts.containers.box>
		</bglayouts.fileDetails.InformationGroup>
	)
}

import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"

export function Modal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Archetype Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>
							A brand archetype is a symbolic representation of a brand&apos;s personality, values, and characteristics.
						</b>{" "}
						Derived from Jungian psychology, brand archetypes are universal symbols or patterns that resonate with
						consumers on a subconscious level. Each archetype embodies certain traits and qualities, helping brands
						establish a distinct identity and connect with their target audience on a deeper level. By aligning their
						brand with a specific archetype, companies can effectively communicate their brand message, differentiate
						themselves from competitors, and foster emotional connections with consumers.
						<br />
						<br />
						When defining a Brand Archetype for your company, consider the following:
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Understanding Your Audience:</b> Identify your target audience&apos;s
						values, aspirations, and preferences to align the archetype with their needs and desires.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Brand Values and Personality:</b> Define your brand&apos;s core values,
						personality traits, and unique characteristics to ensure alignment with the chosen archetype.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Market Positioning:</b> Evaluate your competitive landscape and choose an
						archetype that differentiates your brand and resonates with your target market.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Emotional Connection:</b> Aim to create an emotional connection with your
						audience by selecting an archetype that evokes relatable emotions and sentiments.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Authenticity and Credibility:</b> Ensure that the selected archetype aligns
						with your brand&apos;s authenticity and credibility to build trust with your audience.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Storytelling and Messaging:</b> Develop compelling storytelling and
						messaging strategies that leverage the chosen archetype to engage and connect with your audience
						effectively.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Modal.defaultProps = {
	width: "815px",
	height: "640x",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

import * as httpx from "httpx"
import { EventViolation, EventViolationSearchRequest, EventViolationSearchResponse } from "brandguard/brandguard_pb"
import { CancellablePromise } from "real-cancellable-promise"

export function zero(d: Partial<EventViolation>): EventViolation {
	return {
		id: "",
		account_id: "",
		brand_id: "",
		event_id: "",
		created_at: "",
		description: "",
		explanation: "",
		...d,
	}
}

export namespace searches {
	export function request(d: Partial<EventViolationSearchRequest> = {}): EventViolationSearchRequest {
		return {
			offset: 0n,
			brand_id: "",
			limit: 100n,
			event_ids: [],
			...d,
		}
	}

	export function response(d: Partial<EventViolationSearchResponse> = {}): EventViolationSearchResponse {
		return {
			next: request(),
			items: [],
			...d,
		}
	}
}

export function search(
	req: EventViolationSearchRequest,
	...options: httpx.option[]
): CancellablePromise<EventViolationSearchResponse> {
	return httpx
		.get<EventViolationSearchResponse>(`${httpx.urlstorage.host()}/bg/violations/`, req, ...options)
		.then((r) => ({
			...r,
			items: (r.items || []).map(zero),
		}))
}

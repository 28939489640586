import * as luxon from "luxon"
import * as uuid from "uuid"
import * as timex from "timex"
import * as md5x from "md5x"
import { CancellablePromise } from "real-cancellable-promise"

// general interface for application authorization tokens
export interface Token {
	id: string
	profile_id: string
	account_id: string
	expires: bigint
	bearer: string
}

export interface Cached<T extends Token> {
	current: T
	pending: CancellablePromise<T> | undefined
	refresh(): CancellablePromise<T>
	token(): CancellablePromise<T>
}

export function expired(t: Token, ts: luxon.DateTime): boolean {
	return luxon.DateTime.fromMillis(Number(t.expires)) < ts
}

export function zero<T extends Token>(ctx: Partial<T> = {}): T {
	return {
		id: uuid.NIL,
		profile_id: uuid.NIL,
		account_id: uuid.NIL,
		expires: BigInt(timex.negInfinity().toMillis()),
		bearer: "",
		...ctx,
	} as T
}

export function clone<T extends Token>(ctx: Partial<T> = {}): T {
	return {
		id: ctx.id || (ctx.bearer ? md5x.string(ctx.bearer) : uuid.NIL),
		profile_id: ctx.profile_id || uuid.NIL,
		account_id: ctx.account_id || uuid.NIL,
		expires: ctx.expires || BigInt(timex.negInfinity().toMillis()),
		bearer: ctx.bearer || "",
		...ctx,
	} as T
}

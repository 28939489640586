import { CancellablePromise } from "real-cancellable-promise"
import * as uuid from "uuid"
import { DateTime } from "luxon"
import * as httpx from "httpx"
import * as proto from "./account.brand"
import * as localstoragex from "localstoragex"

export type { Brand, SearchRequest, SearchResponse } from "./account.brand"

const brand_id_key = "nsuite.brand.id"

export function getLastBrandId(account_id: string): CancellablePromise<string> {
	return new CancellablePromise(localstoragex.getItem(`${brand_id_key}.${account_id}`), () => undefined)
}

// this should only be invoked in response to a *direct* user action.
// it should never be set implicitly from urls or other sources.
export function setLastBrandId(account_id: string, s: string): string {
	localstoragex.setItem(`${brand_id_key}.${account_id}`, s)
	return s
}

export function removeLastBrandId(account_id: string): void {
	window.localStorage.removeItem(`${brand_id_key}.${account_id}`)
}

export function zero(b: Partial<proto.Brand> = {}): proto.Brand {
	let ts = DateTime.now()
	return {
		id: uuid.NIL,
		description: "",
		domain: "",
		created_at: ts.toISO(),
		updated_at: ts.toISO(),
		account_id: uuid.NIL,
		brandguard_threshold: 100,
		cloned_at: ts.toISO(),
		...b,
	}
}
export namespace searches {
	export function zero(sr: Partial<proto.SearchRequest> = {}): proto.SearchRequest {
		return {
			query: "",
			offset: 0,
			limit: 0,
			...sr,
		}
	}
}

export function first(...options: httpx.option[]): CancellablePromise<proto.Brand | undefined> {
	return search(searches.zero({ limit: 1 }), ...options).then((r) => {
		return r.items.pop()
	})
}

export function search(req: proto.SearchRequest, ...options: httpx.option[]): CancellablePromise<proto.SearchResponse> {
	return httpx
		.get<proto.SearchResponse>(`${httpx.urlstorage.host()}/brands`, req, ...options)
		.then((resp) => ({ ...resp, items: resp.items.map(zero) }))
}

export function get(id: string, ...options: httpx.option[]): CancellablePromise<{ brand: proto.Brand }> {
	return httpx
		.get<{ brand: proto.Brand }>(`${httpx.urlstorage.host()}/brands/${id}`, {}, ...options)
		.then((r) => ({ ...r, brand: zero(r.brand) }))
}

export function create(req: proto.CreateRequest, ...options: httpx.option[]): CancellablePromise<proto.CreateResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/brands`, req, ...options)
}

export function clone(req: proto.CloneRequest, ...options: httpx.option[]): CancellablePromise<proto.CloneResponse> {
	return httpx.post(`${httpx.urlstorage.host()}/brands/clone`, req, ...options)
}

export function update(req: proto.UpdateRequest, ...options: httpx.option[]): CancellablePromise<proto.UpdateResponse> {
	return httpx.put(`${httpx.urlstorage.host()}/brands/${req.brand?.id}`, req, ...options)
}

export function destroy(id: string, ...options: httpx.option[]): CancellablePromise<proto.DeleteResponse> {
	return httpx.destroy(`${httpx.urlstorage.host()}/brands/${id}`, {}, ...options)
}

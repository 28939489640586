import { useEffect, useState } from "react"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as errors from "errors"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as typography from "typography"
import * as api from "./api"
import * as oauth2 from "."
import * as inputs from "inputs"
import * as icons from "icons"

export default function Table(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)

	const [req, setReq] = useState(api.tokens.searches.request())
	const [set, setItems] = useState(api.tokens.searches.response({ next: req }))

	useEffect(() => {
		if (!permission.usermanagement) return
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.tokens.search(req, authzc.bearer(metaauthz)))
			.then((resp) => {
				setItems(resp)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				setCause(
					<layouts.Flex flex="1" justifyContent="center">
						<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />
					</layouts.Flex>,
				)
			})

		return p.cancel
	}, [req])

	return (
		<layouts.containers.flex
			flexDirection="row"
			flex="1"
			background={layouts.theme.colors.white}
			my="20px"
			overflow="hidden"
			{...rest}
		>
			<layouts.containers.flex flexDirection="column" flex="2" p="50px" overflowY="auto">
				<typography.h3
					fontSize="16px"
					fontWeight="700"
					lineHeight="30px"
					color={layouts.theme.colors.grey.dark50}
					mb="5px"
				>
					Manage Access
				</typography.h3>
				<typography.h6
					fontSize="12px"
					fontWeight="400"
					lineHeight="20px"
					color={layouts.theme.colors.grey.dark50}
					whiteSpace="unset"
				>
					<authz.Protected
						enabled={permission.usermanagement}
						rejected={<>You do not have permissions to manage API access. Please contact an admin.</>}
					>
						Add, edit, and delete user access to the Brandguard API.
					</authz.Protected>
				</typography.h6>
			</layouts.containers.flex>
			<layouts.containers.flex flex="7">
				<layouts.containers.flex px="10px" flex="1" flexDirection="column">
					<inputs.Search
						query={req.query}
						onChange={(s) => {
							setReq({ ...req, query: s })
						}}
					>
						<layouts.containers.flex pr="20px" onClick={() => setReq({ ...req, query: "" })}>
							<icons.searches.Clear />
						</layouts.containers.flex>
					</inputs.Search>
					{cause ||
						(set.items.length > 0 && (
							<layouts.containers.flex
								flexDirection="column"
								flex="1"
								background={layouts.theme.colors.grey.bg}
								my="20px"
								overflowY="auto"
							>
								<layouts.containers.flex
									flexDirection="row"
									fontWeight="700"
									fontSize="16px"
									lineHeight="30px"
									justifyContent="center"
									borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
									mx="5px"
								>
									<layouts.containers.flex flex="3" p="10px">
										Description
									</layouts.containers.flex>
									<layouts.containers.flex flex="1" p="10px" justifyContent="center">
										Expires
									</layouts.containers.flex>
									<layouts.containers.flex flex="1" p="10px" justifyContent="center">
										Created
									</layouts.containers.flex>
									<layouts.containers.flex flex="1" p="10px"></layouts.containers.flex>
								</layouts.containers.flex>
								{set.items.map((item) => {
									return (
										<oauth2.layouts.AccordionContainer key={item.id} token={item}>
											<layouts.containers.flex
												flexDirection="column"
												px="50px"
												py="10px"
												alignItems="flex-start"
												fontSize="14px"
												lineHeight="20px"
												color={layouts.theme.colors.grey.dark50}
											>
												<oauth2.layouts.Row title="Description" value={item.description} />
												<oauth2.layouts.Row title="Email" value={item.authorized_by} />
												<oauth2.layouts.Row title="ID" value={item.id} />
												<oauth2.layouts.TokenActions
													item={item}
													onDelete={(r) => {
														setItems((prevState) => ({
															...prevState,
															items: prevState.items.filter((i) => i.id !== r.token?.id),
														}))
													}}
												/>
											</layouts.containers.flex>
										</oauth2.layouts.AccordionContainer>
									)
								})}
								<layouts.pagination.Cursor
									mx="auto"
									mt="auto"
									py="5px"
									pr="10px"
									current={req.offset}
									advance={set.next!.offset === "" ? undefined : set.next?.offset}
									onChange={(next) => {
										setReq({
											...(set.next || req),
											offset: next,
										})
									}}
								/>
							</layouts.containers.flex>
						))}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

import React, { useState, useEffect } from "react"
import * as uuid from "uuid"
import * as api from "./api"
import * as debugx from "x/debugx"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as errors from "errors"
import * as layouts from "layouts"
import * as brands from "brands"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as icons from "icons"
import * as brandgpt from "brandgpt"
import * as styleguideApi from "styleguide/api"
import classnames from "classnames"

import { ForbiddenMessage, PromoStyleguideMessage } from "./messages"

interface props {
	styleguide: styleguideApi.Styleguide | undefined
	DropWell: JSX.Element
	selectStyleguidePage(p: number): void
}

export default function Display(props: props): JSX.Element {
	const { styleguide: _styleguide, DropWell, selectStyleguidePage } = props
	const brand = brands.caching.useCached()
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current
	const [collapse, setCollapse] = useState(false)
	const [styleguide, setStyleguide] = useState(_styleguide!)
	const [loading, setLoading] = useState(false)

	const mtoggle = layouts.modals.useToggle()

	const [cause, setCause] = useState(undefined as errors.Cause)
	const bearertoken = sessions.useToken()
	const [focused, setFocused] = useState(undefined as api.Channel | undefined)

	useEffect(() => {
		setStyleguide(_styleguide!)
	}, [_styleguide])

	const [creq, setCreq] = useState({
		brand_id: brand.id,
		styleguide_id: styleguide?.id || uuid.NIL,
		offset: 0n,
		limit: 20n,
	} as api.ChannelsSearchRequest)

	const [channels, setChannels] = useState({
		next: creq,
		items: [],
	} as api.ChannelsSearchResponse)

	useEffect(() => {
		if (!styleguide) return
		if (styleguide!.state !== styleguideApi.State.EMBEDDINGS_CREATED) {
			setChannels((prevState) => ({ ...prevState, items: [] }))
			setFocused(undefined)
			return
		}
		setCreq((prevState) => ({ ...prevState, styleguide_id: styleguide.id }))
	}, [styleguide])

	useEffect(() => {
		if ([creq.brand_id, creq.styleguide_id].includes(uuid.NIL)) return
		setLoading(true)
		const pending = api.channels
			.search(brand.id, creq, bearertoken)
			.then((channels) => {
				setChannels(channels)
				setFocused(channels.items.length > 0 ? channels.items[0] : undefined)
				setLoading(false)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="2px" m="5px" borderRadius="5px">
						<errors.Textual onClick={() => setCause(undefined)}>
							<layouts.containers.span color={layouts.theme.colors.white} fontSize="12px" fontWeight="600">
								unable to retrieve chats
							</layouts.containers.span>
						</errors.Textual>
					</errors.Inline>,
				)
				setLoading(false)
			})
		return pending.cancel
	}, [creq])

	return (
		<>
			{!collapse && (
				<layouts.containers.flex
					className={classnames(brandgpt.layouts.styledcontainer, "left-sidebar")}
					flexDirection="column"
					flex="1"
					minWidth="200px"
					order="2"
					background={layouts.theme.colors.white}
					borderRadius="10px 0 10px 10px"
					height="100%"
				>
					<layouts.loading.pending loading={loading}>
						{cause}
						<layouts.containers.flex
							flexDirection="column"
							flex="2"
							minHeight="100px"
							alignItems="center"
							overflowY="auto"
							className={classnames(brandgpt.layouts.styledscroll)}
						>
							<layouts.buttons.outlinewithicon
								width="180px"
								height="45px"
								border="2px"
								borderRadius="10px"
								my="20px"
								disabled={styleguide === undefined || styleguide!.state !== styleguideApi.State.EMBEDDINGS_CREATED}
								onClick={(evt) => {
									mtoggle(
										<brandgpt.NewChannel
											styleguide_id={styleguide!.id}
											onChange={(channel) => {
												api.channels.create(brand.id, { channel: channel }, bearertoken).then((r) => {
													setChannels({
														...channels,
														items: [r.channel!].concat(...channels.items),
													})
													setFocused(r.channel)
													mtoggle(undefined)
												})
											}}
										/>,
									)
								}}
							>
								<layouts.containers.flex flexDirection="row" alignItems="center" justifyContent="flex-start">
									<icons.brandgpt.newchat />
									<layouts.containers.span pl="10px" fontSize="12px" fontWeight="500">
										New Chat
									</layouts.containers.span>
								</layouts.containers.flex>
							</layouts.buttons.outlinewithicon>
							{channels.items.map((c, i) => (
								<layouts.buttons.styled
									key={i}
									width="180px"
									height="45px"
									border="2px"
									borderRadius="10px"
									mt="20px"
									background={focused === c ? layouts.theme.colors.blue.blue : layouts.theme.colors.grey.dark10}
									onClick={() => setFocused(c)}
								>
									<layouts.containers.flex flexDirection="row" alignItems="center" justifyContent="flex-start">
										<icons.brandgpt.chat />
										<layouts.containers.span
											pl="10px"
											fontSize="12px"
											fontWeight="500"
											color={layouts.theme.colors.white}
										>
											{c.description}
										</layouts.containers.span>
									</layouts.containers.flex>
								</layouts.buttons.styled>
							))}
						</layouts.containers.flex>
					</layouts.loading.pending>
					<authz.Protected
						enabled={permission.brandguard_train && styleguide?.state === styleguideApi.State.EMBEDDINGS_CREATED}
					>
						<>
							<hr />
							<layouts.containers.flex flexDirection="column" flex="1">
								<layouts.containers.span
									py="10px"
									px="25px"
									fontSize="12px"
									fontWeight="600"
									lineHeight="16px"
									textAlign="center"
								>
									Have you changed your guidelines?
								</layouts.containers.span>
								<layouts.containers.flex justifyContent="center">{DropWell}</layouts.containers.flex>
							</layouts.containers.flex>
						</>
					</authz.Protected>
				</layouts.containers.flex>
			)}
			<layouts.containers.flex
				flex="3"
				order="1"
				flexDirection="column"
				flexShrink="0"
				minWidth="600px"
				overflowY="auto"
				height="100%"
				className={classnames("center-panel", brandgpt.layouts.styledscroll)}
			>
				<layouts.containers.flex
					flexDirection="column"
					flex={collapse ? "2" : "3"}
					minHeight="100px"
					background={layouts.theme.colors.blue.bluesoft}
				>
					{focused && (
						<layouts.containers.flex flexDirection="row" className="actions" justifyContent="flex-end" p="10px">
							{debugx.alpha.enabled() && (
								<icons.brandgpt.share onClick={() => console.log("share")} cursor="pointer" pr="15px" />
							)}
							<icons.brandgpt.edit
								onClick={(evt) => {
									mtoggle(
										<brandgpt.EditChannel
											current={focused}
											onChange={(channel) => {
												api.channels.update(brand.id, { channel: channel }, bearertoken).then((r) => {
													setChannels({
														...channels,
														items: channels.items.map((el) => (el.id === r.channel?.id ? r.channel : el)),
													})
													setFocused(r.channel)
													mtoggle(undefined)
												})
											}}
										/>,
									)
								}}
								cursor="pointer"
								pr="15px"
							/>
							<icons.brandgpt.trash
								onClick={(evt) => {
									mtoggle(
										<brandgpt.DeleteChannel
											current={focused}
											onChange={(channel) => {
												api.channels.destroy(brand.id, { channel: channel }, bearertoken).then((r) => {
													setChannels({
														...channels,
														items: channels.items.filter((c) => c.id !== r.channel!.id),
													})
													setFocused(undefined)
													mtoggle(undefined)
												})
											}}
										/>,
									)
								}}
								cursor="pointer"
								pr="15px"
							/>
							<icons.brandgpt.collapse
								onClick={() => setCollapse(!collapse)}
								cursor="pointer"
								pr="15px"
								stroke={collapse ? layouts.theme.colors.blue.blue : layouts.theme.colors.grey.dark50}
								strokeOpacity={collapse ? "1" : "0.8"}
							/>
						</layouts.containers.flex>
					)}
					{styleguide?.state === styleguideApi.State.EMBEDDINGS_CREATED ? (
						<brandgpt.Messages
							channel={focused}
							styleguide={styleguide}
							key={focused?.id}
							selectStyleguidePage={selectStyleguidePage}
						/>
					) : (
						<authz.Protected enabled={permission.brandguard_train} rejected={<ForbiddenMessage />}>
							<PromoStyleguideMessage />
						</authz.Protected>
					)}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</>
	)
}

import * as httpx from "httpx"
import * as text from "."
import { CancellablePromise } from "real-cancellable-promise"
import {
	TextSearchRequest,
	TextSearchResponse,
	TextFindResponse,
	TextPatchRequest,
	TextPatchResponse,
	TextUploadResponse,
	TextTrainRequest,
	TextTrainResponse,
	TextRescoreRequest,
	TextRescoreResponse,
} from "brandguard/brandguard.text_pb"

export function upload(
	data: FormData,
	file: File,
	bid: string,
	...options: httpx.option[]
): CancellablePromise<TextUploadResponse> {
	data.append("content", file)
	return httpx
		.upload<TextUploadResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training`, data, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function search(
	req: TextSearchRequest,
	bid: string,
	...options: httpx.option[]
): CancellablePromise<TextSearchResponse> {
	return httpx
		.get<TextSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training`, req, ...options)
		.then((resp) => ({
			...resp,
			items: (resp.items || []).map((item) => ({ media: item.media, event: text.EventZero(item.event) })),
		}))
}

export function find(id: string, bid: string, ...options: httpx.option[]): CancellablePromise<TextFindResponse> {
	return httpx
		.get<TextFindResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function patch(
	id: string,
	bid: string,
	req: TextPatchRequest,
	...options: httpx.option[]
): Promise<TextPatchResponse> {
	return httpx
		.patch<TextPatchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/${id}`, req, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export function requeue(id: string, bid: string, ...options: httpx.option[]): Promise<TextFindResponse> {
	return httpx
		.post<TextFindResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/${id}/requeue`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export async function transfer(
	id: string,
	bid: string,
	req: TextPatchRequest,
	...options: httpx.option[]
): Promise<TextPatchResponse> {
	return httpx
		.post<TextPatchResponse>(
			`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/${id}/transfer`,
			req,
			...options,
		)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

export async function enqueue(
	bid: string,
	req: TextTrainRequest,
	...options: httpx.option[]
): Promise<TextTrainResponse> {
	return httpx.post<TextTrainRequest>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/enqueue`,
		req,
		...options,
	)
}

export async function rescore(
	bid: string,
	req: TextRescoreRequest,
	...options: httpx.option[]
): Promise<TextRescoreResponse> {
	return httpx.post<TextRescoreRequest>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/rescore`,
		req,
		...options,
	)
}

export function destroy(bid: string, id: string, ...options: httpx.option[]): Promise<TextPatchResponse> {
	return httpx
		.destroy<TextPatchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/text/training/${id}`, {}, ...options)
		.then((resp) => ({
			media: resp.media,
			event: text.EventZero(resp.event),
		}))
}

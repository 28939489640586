import React, { useContext, useState } from "react"
import * as uuid from "uuid"

export const Context = React.createContext(zero())
export const Provider = Context.Provider

export function useCached(): cached {
	return useContext(Context)
}

export interface layer {
	uuid: string
	position: number
	element: JSX.Element
}

export interface cached {
	event_id: string
	items: layer[]
	update(upd: Partial<cached>): void
}

export function zero(c: Partial<cached> = {}): cached {
	return {
		event_id: uuid.NIL,
		items: [],
		update(s: cached) {},
		...c,
	}
}

export function Storage(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [data, setData] = useState(zero())

	const ctx = {
		...data,
		update(d: Partial<cached>) {
			setData({ ...ctx, ...d })
		},
	}

	return <Provider value={ctx}>{props.children}</Provider>
}

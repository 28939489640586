import * as theme from "layouts/theme"
import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { ...rest } = props
	return (
		<svg.SVG width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<g filter="url(#filter0_d_18_4065)">
				<rect x="5" y="3" width="50" height="50" rx="25" fill="white" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M30 18C24.48 18 20 22.48 20 28C20 33.52 24.48 38 30 38C35.52 38 40 33.52 40 28C40 22.48 35.52 18 30 18ZM34 29H31V32C31 32.55 30.55 33 30 33C29.45 33 29 32.55 29 32V29H26C25.45 29 25 28.55 25 28C25 27.45 25.45 27 26 27H29V24C29 23.45 29.45 23 30 23C30.55 23 31 23.45 31 24V27H34C34.55 27 35 27.45 35 28C35 28.55 34.55 29 34 29Z"
					fill={theme.colors.blue.blue}
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_18_4065"
					x="0"
					y="0"
					width="60"
					height="60"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="2.5" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_18_4065" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_18_4065" result="shape" />
				</filter>
			</defs>
		</svg.SVG>
	)
}

export default Icon

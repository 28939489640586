import * as uuid from "uuid"
import * as httpx from "httpx"

export const scopes = {
	business_management: "business_management",
	ads_management: "ads_management",
	page_engagement: "pages_read_engagement",
	page_manage_ads: "pages_manage_ads",
}

export function credential_zero(d: Partial<Credential> = {}): Credential {
	return {
		id: uuid.v4(),
		business_manager_id: 0,
		description: "",
		created_at: "",
		updated_at: "",
		...d,
	}
}

export interface Credential {
	id: string
	business_manager_id: number
	description: string
	created_at: string
	updated_at: string
}

export interface SearchRequest {
	query: string
}

export interface SearchResponse {
	items: Credential[]
}

export function search(req: SearchRequest, ...options: httpx.option[]): Promise<SearchResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/authz/facebook/`, req, ...options)
}

export interface FindResponse {
	credential: Credential
}

export function find(id: string, ...options: httpx.option[]): Promise<FindResponse> {
	return httpx.get(`${httpx.urlstorage.host()}/authz/facebook/${id}`, {}, ...options)
}

export namespace authorization {
	export namespace requests {
		export function zero(d: Partial<AuthorizeRequest> = {}): AuthorizeRequest {
			return {
				cid: uuid.v4(),
				cbmid: 0,
				scopes: [],
				...d,
			}
		}

		export function testdata(d: Partial<AuthorizeRequest> = {}): AuthorizeRequest {
			return zero({ cbmid: 961888951024166, ...d })
		}
	}

	export function url(req: AuthorizeRequest, ...options: httpx.option[]): Promise<AuthorizeResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/authz/facebook/authz`, req, ...options)
	}

	export function exchange(req: ExchangeRequest, ...options: httpx.option[]): Promise<unknown> {
		return httpx.post(`${httpx.urlstorage.host()}/authz/facebook/authz`, req, ...options)
	}
}

export interface AuthorizeRequest {
	cid: string
	cbmid: number
	scopes: string[]
}

export interface AuthorizeResponse {
	cid: string
	url: string
}

export interface ExchangeRequest {
	code: string
	state: string
	scope: string
}

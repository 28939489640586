import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as selector from "brands/selector"

export default function Container(props: React.PropsWithChildren<selector.props>): JSX.Element {
	const { children, urlgen } = props

	return (
		<layouts.backgrounds.Grey className="brandguard-container" flex="1">
			<layouts.containers.flex justifyContent="center" flex="1">
				<layouts.containers.flex
					px="50px"
					pt="25px"
					className="brandguard"
					flexDirection="column"
					flex="1"
					overflowY="hidden"
				>
					<layouts.containers.flex flexDirection="row" width="100%" textAlign="left" boxShadow="none" mb="60px">
						<layouts.containers.flex flex="2">
							<typography.h3 color={layouts.theme.colors.grey.medium} fontWeight="700">
								BrandGuide Builder
							</typography.h3>
						</layouts.containers.flex>
						<layouts.containers.flex flex="2" justifyContent="end" height="48px">
							<selector.BrandSelector width="30%" urlgen={urlgen} />
						</layouts.containers.flex>
					</layouts.containers.flex>
					{children}
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

import React, { useState, useEffect, useMemo } from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as uuid from "uuid"
import * as authz from "authz"
import * as debugx from "x/debugx"
import { CancellablePromise } from "real-cancellable-promise"
import { pending, uploaded, statistics } from "./api"
import Processing from "./processing"
import Completed from "./completed"
import Pending from "./pending"
import * as typography from "typography"

export const FileListContainer = styled(layouts.containers.flex)`
	.display-pending,
	.display-completed,
	.display-processing,
	.display-zero-progress {
		color: ${layouts.theme.colors.grey.dark50};
		span {
			text-align: start;
		}
	}
	overflow-y: auto;
	max-height: 135px;
	width: 100%;
	::-webkit-scrollbar {
		width: 3px;
	}
	::-webkit-scrollbar-track {
		border-radius: 11px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${layouts.theme.colors.grey.light25};
	}
`

export interface Props {
	concurrent: number // number of concurrent upload
	statistics: statistics
	onChange(item: File[], background?: string): void
}

interface dprops<T> {
	concurrent: number // number of concurrent upload
	onChange(item: T): void // invoked once isProcessing is false.
	isProcessing(item: T): boolean // check if the item is still being processed.
	resume(): CancellablePromise<uploaded<T>[]> // items to resume processing during component mount.
	refresh(data: T): CancellablePromise<T> // refresh pending data.
	upload(stats: statistics, content: File): CancellablePromise<T> // upload content
	DropWell: React.FunctionComponent<React.PropsWithChildren<Props>> // dropwell or another input component
}

export function ProgressDisplay(
	props: React.PropsWithChildren<{ statistics: statistics; concurrent: number }>,
): JSX.Element {
	const { statistics, children } = props
	return (
		<>
			<authz.Protected enabled={debugx.alpha.enabled()}>
				<layouts.Flex justifyContent="center" mb="20px">
					{statistics.pending} / {statistics.uploaded} / {statistics.completed} /
					<typography.danger pl="3px">{statistics.failed}</typography.danger>
				</layouts.Flex>
			</authz.Protected>
			<FileListContainer flex="1" className="file-list" flexDirection="column">
				{children}
			</FileListContainer>
		</>
	)
}

export default function DisplayUploads<T>(
	props: React.PropsWithChildren<dprops<T> & layouts.containers.FlexProps>,
): JSX.Element {
	const { onChange, resume, refresh, isProcessing, upload, DropWell, ...rest } = props
	const [pending, setPending] = useState([] as pending[])
	const [uploading, setUploading] = useState([] as pending[])
	const [uploaded, setUploaded] = useState([] as uploaded<T>[])
	const [completed, setCompleted] = useState([] as uploaded<T>[])
	const [loading, setLoading] = useState(false)
	const [stats, setStatistics] = useState({ pending: 0, uploaded: 0, completed: 0, failed: 0 } as statistics)

	useEffect(() => {
		setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(resume)
			.then((uploads) => {
				setUploaded((prevState) => [...prevState, ...uploads])
				setStatistics((prev) => ({ ...prev, uploaded: uploads.length }))
				setLoading(false)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				console.error(console.error("unable to display uploads", cause))
				setLoading(false)
			})
		return p.cancel
	}, [])

	useEffect(() => {
		if (pending.length === 0) return
		if (uploading.length === props.concurrent) return

		const n = uploading.length === 0 ? props.concurrent : props.concurrent % uploading.length
		setUploading((prev) => [...prev, ...pending.slice(0, n)])
		setPending(pending.slice(n))
		setStatistics((prev) => ({ ...prev, uploading: prev }))
	}, [pending.at(-1)?.id, uploading.at(0)?.id])

	const onFilesSelect = useMemo(() => {
		return (items: File[], background: string = layouts.theme.colors.blue.blue): void => {
			const mitems = items.map((f) => ({ id: uuid.v4(), file: f, background: background } as pending))
			setPending((prev) => [...prev, ...mitems])
			setStatistics((prev) => ({ ...prev, pending: prev.pending + items.length }))
		}
	}, [])

	const _completed = completed.slice(0, props.concurrent).map((p) => {
		return (
			<Completed
				key={p.id}
				className={p.id}
				item={p}
				onChange={(p) => {
					setCompleted((prevState) => prevState.filter((i) => i.id !== p.id))
				}}
			/>
		)
	})

	const _uploading = uploading.slice(0, props.concurrent).map((p) => {
		return (
			<Pending
				key={p.id}
				className={p.id}
				pending={p}
				stats={stats}
				upload={upload}
				onChange={(d) => {
					setUploading((prev) => prev.filter((f) => f.id !== p.id))
					setUploaded((prev) => [...prev, d])
					setStatistics((prev) => ({ ...prev, pending: prev.pending - 1, uploaded: prev.uploaded + 1 }))
				}}
			/>
		)
	})

	const _processing = uploaded.slice(0, Math.max(props.concurrent - _uploading.length, 0)).map((p) => {
		return (
			<Processing
				className={p.id}
				key={p.id}
				item={p}
				refresh={refresh}
				onChange={(p) => {
					setUploaded((prevState) => prevState.map((i) => (i.id === p.id ? p : i)))
					if (isProcessing(p.data)) return
					setUploaded((prevState) => prevState.filter((i) => i.id !== p.id))
					setCompleted((prevState) => [...prevState.filter((i) => i.id !== p.id), p])
					setStatistics((prev) => ({ ...prev, uploaded: prev.uploaded - 1, completed: prev.completed + 1 }))
					onChange(p.data)
				}}
				onError={(p) => {
					setUploaded((prevState) => prevState.filter((i) => i.id !== p.id))
					setStatistics((prev) => ({ ...prev, uploaded: prev.uploaded - 1, failed: prev.failed + 1 }))
				}}
			/>
		)
	})

	const display = [..._uploading, ..._completed, ..._processing].slice(0, props.concurrent)

	return (
		<layouts.loading.screen loading={loading} flex="1" overflow="hidden" icon={<></>} flexDirection="row">
			<layouts.Flex
				borderRadius="0px 0px 10px 10px"
				flexDirection="row"
				background={layouts.theme.colors.grey.dark50}
				minHeight="175px"
				flex="1"
				{...rest}
			>
				<layouts.Flex flex="1" flexDirection="column" className="uploads" px="10px">
					<DropWell concurrent={props.concurrent} statistics={stats} onChange={onFilesSelect}>
						<ProgressDisplay statistics={stats} concurrent={props.concurrent}>
							{display}
						</ProgressDisplay>
					</DropWell>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.loading.screen>
	)
}

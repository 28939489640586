import React, { useEffect, useState } from "react"
import classnames from "classnames"
import * as httpx from "httpx"
import * as layouts from "layouts"
import * as profiles from "profiles"
import * as sessions from "sessions"
import * as authzc from "authzcached"
import * as authz from "./authz"
import * as inputs from "inputs"

interface props extends layouts.containers.FlexProps {
	current: profiles.Profile
	readonly: boolean
	onChange(updated: authz.Token): Promise<authz.Token>
}

export default function Modify(props: props): JSX.Element {
	const { current, onChange, className, readonly, ...rest } = props

	const session = sessions.useSession()
	const [auth] = useState(authzc.useCache((cached) => cached.meta))
	const [cause, setCause] = useState(undefined as unknown | undefined)
	const [loading, setLoading] = useState(true)
	const [modified, setModified] = useState(authz.tokens.denied(current, ""))
	const readonly_user_manage = readonly || current.id === session.profile.id

	useEffect(() => {
		setCause(undefined)
	}, [modified, current.id])

	useEffect(() => {
		setModified(authz.tokens.denied(current, ""))
		setLoading(true)
		auth.token().then(async (token) => {
			authz
				.profile({ profile_id: current.id }, httpx.options.bearer(token.bearer))
				.then((r) => {
					setModified({
						...modified,
						...r.authorization,
					})
				})
				.catch(setCause)
				.finally(() => setLoading(false))
		})
	}, [current.id])

	const update = (upd: Partial<authz.Token>): void => {
		onChange({
			...modified,
			...upd,
		}).then((upd) => setModified(upd))
	}

	function Container(props: React.PropsWithChildren<{ readonly: boolean }>): JSX.Element {
		const { readonly, children } = props
		return (
			<layouts.forms.Container
				className={classnames("meta authz modify", className)}
				cause={cause}
				readonly={readonly}
				loading={loading}
				{...rest}
			>
				{children}
			</layouts.forms.Container>
		)
	}

	return (
		<>
			<Container readonly={readonly_user_manage}>
				<inputs.Checkbox
					alignItems="flex-start"
					disabled={readonly_user_manage}
					checked={modified.usermanagement}
					onChange={() => update({ usermanagement: !modified.usermanagement })}
				>
					<layouts.forms.Label my="unset" ml="10px">
						Manage Settings
					</layouts.forms.Label>
				</inputs.Checkbox>
			</Container>
			<Container readonly={readonly}>
				<inputs.Checkbox
					alignItems="flex-start"
					disabled={readonly}
					checked={modified.brandguard_upload}
					onChange={() => update({ brandguard_upload: !modified.brandguard_upload })}
				>
					<layouts.forms.Label my="unset" ml="10px">
						Upload & Score Assets
					</layouts.forms.Label>
				</inputs.Checkbox>
			</Container>
			<Container readonly={readonly}>
				<inputs.Checkbox
					alignItems="flex-start"
					disabled={readonly}
					checked={modified.brandguard_review}
					onChange={() => update({ brandguard_review: !modified.brandguard_review })}
				>
					<layouts.forms.Label my="unset" ml="10px">
						Review Assets
					</layouts.forms.Label>
				</inputs.Checkbox>
			</Container>
			<Container readonly={readonly}>
				<inputs.Checkbox
					alignItems="flex-start"
					disabled={readonly}
					checked={modified.brandguard_train}
					onChange={() => update({ brandguard_train: !modified.brandguard_train })}
				>
					<layouts.forms.Label my="unset" ml="10px">
						Train System
					</layouts.forms.Label>
				</inputs.Checkbox>
			</Container>
			<Container readonly={readonly}>
				<inputs.Checkbox
					alignItems="flex-start"
					disabled={readonly}
					checked={modified.brandgpt_use}
					onChange={() => update({ brandgpt_use: !modified.brandgpt_use })}
				>
					<layouts.forms.Label my="unset" ml="10px">
						Use BrandGPT
					</layouts.forms.Label>
				</inputs.Checkbox>
			</Container>
			<Container readonly={readonly}>
				<layouts.Flex flexDirection="column">
					<inputs.Checkbox
						alignItems="flex-start"
						disabled={readonly}
						checked={modified.brandguide_view || modified.brandguide_edit}
						onChange={() => {
							if (modified.brandguide_view || modified.brandguide_edit) {
								update({ brandguide_view: false, brandguide_edit: false })
								return
							}
							update({ brandguide_view: true, brandguide_edit: true })
						}}
					>
						<layouts.forms.Label my="unset" ml="10px">
							BrandGuide Buider:
						</layouts.forms.Label>
					</inputs.Checkbox>
					<layouts.Flex flexDirection="column" ml="10px" mt="2px">
						<inputs.Checkbox
							alignItems="flex-start"
							disabled={readonly || modified.brandguide_edit}
							checked={modified.brandguide_view}
							onChange={() => {
								if (modified.brandguide_edit) return
								update({ brandguide_view: !modified.brandguide_view })
							}}
						>
							<layouts.forms.Label my="unset" ml="10px">
								View
							</layouts.forms.Label>
						</inputs.Checkbox>
						<inputs.Checkbox
							alignItems="flex-start"
							disabled={readonly}
							checked={modified.brandguide_edit}
							onChange={() => {
								if (!modified.brandguide_edit) {
									update({ brandguide_edit: true, brandguide_view: true })
									return
								}
								update({ brandguide_edit: false })
							}}
						>
							<layouts.forms.Label my="unset" ml="10px">
								Edit
							</layouts.forms.Label>
						</inputs.Checkbox>
					</layouts.Flex>
				</layouts.Flex>
			</Container>
		</>
	)
}

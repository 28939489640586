import React from "react"
import estyled from "@emotion/styled"
import * as system from "styled-system"
import * as theme from "./theme"
import { ContainerProps } from "./containers"

export interface ButtonProps
	extends React.DOMAttributes<HTMLButtonElement>,
		system.LayoutProps,
		system.TypographyProps,
		system.SpaceProps,
		system.PositionProps,
		system.WidthProps,
		system.BorderProps,
		system.BackgroundProps,
		system.BoxShadowProps,
		system.FlexProps {
	className?: string
	disabled?: boolean
	cursor?: string
}

interface DisabledProps {
	disabled?: boolean
}

export const unstyled = estyled.button<ButtonProps>`
	border: none;
	cursor: ${(props) => (props.cursor ? props.cursor : "inherit")};
	${system.border}
	${system.borderRadius}
	${system.background}
	${system.boxShadow}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
	${system.color}
	${system.flex}
`

export const styled = estyled.button<ButtonProps>`
	font-weight: bold;
	text-align: center;
	cursor: ${(props) => (props.disabled ? "not-allowed" : props.cursor ? props.cursor : "pointer")};
	color: ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.buttons.colors.enabled)};
	&:hover {
		background: ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.buttons.colors.enabled)};
	}
	a {
		color: unset;
		text-decoration: none;
	}
	${system.border}
	${system.borderRadius}
	${system.background}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`

styled.defaultProps = {
	padding: "0.75em",
	border: "none",
	borderRadius: "0.25em",
}

export const styled_box = estyled.div<DisabledProps & ContainerProps>`
	font-weight: bold;
	text-align: center;
	cursor: ${(props) => (props.disabled ? "not-allowed" : props.cursor ? props.cursor : "pointer")};
	color: ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.buttons.colors.enabled)};
	&:hover {
		background: ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.buttons.colors.enabled)};
	}
	a {
		color: unset;
		text-decoration: none;
	}
	${system.border}
	${system.borderRadius}
	${system.background}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`

styled_box.defaultProps = {
	border: "none",
	borderRadius: "0.25em",
}

export const outline = estyled(styled)`
	background-color: ${(props) => props.background};
	border: 1px solid ${(props) => (props.disabled ? theme.buttons.colors.disabled : props.color)};
	color: ${(props) => (props.disabled ? theme.buttons.colors.disabled : props.color)};
	${system.background}
	&:hover {
		background-color: ${(props) => (props.disabled ? theme.buttons.colors.disabled : props.color)};
		color: ${(props) => props.background};
	}
`

outline.defaultProps = {
	color: theme.buttons.colors.enabled,
	background: theme.colors.white,
}

export const outlinered = estyled(styled)`
	background-color: ${theme.colors.white};
	border: 1px solid ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.colors.red.cinnabar)};
	${system.background}
	color: ${theme.colors.red.cinnabar};
	&:hover {
		background-color: ${theme.colors.red.cinnabar};
		color: ${theme.colors.white};
	}
`

export const outlinewithicon = estyled(styled)`
	background-color: ${theme.colors.white};
	border: 1px solid ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.buttons.colors.enabled)};
	${system.background}
	svg {
		path {
			fill: ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.buttons.colors.enabled)};
		}
	}
	&:hover {
		background-color: ${(props) => (props.disabled ? theme.colors.white : theme.colors.blue.blue)};
		color: ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.colors.white)};
		svg {
			path {
				fill: ${(props) => (props.disabled ? theme.buttons.colors.disabled : theme.colors.white)};
			}
		}
	}
`

export const primary = estyled(styled)`
	background-color: ${(props) => (props.disabled ? theme.colors.grey.lightest : theme.colors.blue.blue)};
	color: ${(props) => (props.disabled ? theme.colors.grey.light30 : theme.colors.white)};
	${system.background}
`

export const primary_box = estyled(styled_box)`
	background-color: ${(props) => (props.disabled ? theme.colors.grey.lightest : theme.colors.blue.blue)};
	color: ${(props) => (props.disabled ? theme.colors.grey.light30 : theme.colors.white)};
	${system.background}
`

export const danger = estyled(styled)`
	background-color: ${theme.colors.danger};
	color: ${theme.colors.white};
	${system.background}
`

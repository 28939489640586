import React from "react"
import { FileRejection } from "react-dropzone"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import Papa from "papaparse"

const acceptedTypes = {
	"text/csv": [".csv"],
	"text/plain": [".txt"],
}

interface props {
	onChange(item: File[]): void
	disabled?: boolean
}

export default function Dropwell(
	props: React.PropsWithChildren<props> & layouts.containers.ContainerProps,
): JSX.Element {
	const { onChange, disabled, ...rest } = props
	const onDropHandle = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		if (acceptedFiles.length === 0) return

		Array.from(acceptedFiles).forEach((file) => {
			Papa.parse(file, {
				complete: function (results) {
					const data = results.data
						.map((line) => (line as string[]).join(" "))
						.filter((line) => line.trim().length !== 0)
						.map((line, i) => new File([line], `${i}-${file.name}`, { type: "text/plain" }))
					onChange(data)
				},
			})
		})
	}

	return (
		<inputs.Dropwell
			accept={acceptedTypes}
			onDrop={(accepted, rejected, evt) => onDropHandle(accepted, rejected)}
			flex="1"
			multiple
			disabled={disabled}
			{...rest}
		>
			<layouts.dnd.Container>
				<icons.FileUpload className="dropwell-icon" />
				<layouts.dnd.Textual>Drag & drop your file here or choose file</layouts.dnd.Textual>
			</layouts.dnd.Container>
		</inputs.Dropwell>
	)
}

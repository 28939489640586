import React from "react"
import * as layouts from "layouts"

export default function PercentageBar(
	props: React.PropsWithChildren<{ weight: number } & layouts.containers.FlexProps>,
): JSX.Element {
	const { weight, ...rest } = props

	const color = () => {
		if (weight === 0) return "unset"
		if (weight >= 75) return layouts.theme.colors.blue.blue
		if (weight >= 35) return layouts.theme.colors.red.mandarin
		return layouts.theme.colors.red.cinnabar
	}
	return (
		<layouts.bars.progress.Percentage
			val={weight}
			progressColor={color()}
			tickMark={
				<layouts.containers.absolute right="0" top="-115%">
					<layouts.containers.box background={layouts.theme.colors.grey.dark50} height="7px" width="2px" />
				</layouts.containers.absolute>
			}
			{...rest}
		/>
	)
}

PercentageBar.defaultProps = {
	mb: "10px",
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as brands from "brands"
import * as icons from "icons"
import * as brandguide from "brandguide"
import { Link } from "react-router-dom"
import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { section } from "brandguide/validations"
import * as authz from "authz"
import * as authzc from "authzcached"

interface props extends layouts.containers.ContainerProps {
	activPage: brandguide.validations.pages
}

const NavTitle = styled(typography.h6)<{ pending: boolean }>`
	padding-left: 6px;
	padding-right: 6px;
	font-size: 13px;
	font-weight: 400;
	color: ${(props) => (props.pending ? layouts.theme.colors.grey.dark50 : layouts.theme.colors.grey.dark50alpha70)};
`

const LinkContainer = styled(layouts.Flex)``
LinkContainer.defaultProps = {
	mb: "7px",
	alignItems: "center",
}

function Guidelines(props: props): JSX.Element {
	const { activPage, ...rest } = props
	const brand = brands.caching.useCached()
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const [loading, setLoading] = useState(false)

	const pages = brandguide.validations.guide.sections_enabled(guide)
	const selected = pages.filter((p) => p)
	const pending = brandguide.validations.guide.pending_pages(guide)
	const completed = pending.filter((p) => p)

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	let progressVal = 0
	if (completed.length > 0) progressVal = Number(((completed.length / selected.length) * 100).toFixed(0))

	function PageIcon(props: { state: brandguide.validations.state }): JSX.Element {
		const { state } = props
		if (state === "active") return <icons.brandguide.guideline.InProgress />
		if (state === "pending") return <icons.brandguide.guideline.Pending />
		if (state === "completed") return <icons.brandguide.guideline.Completed />
		return <></>
	}

	const canAddSection = (): boolean => {
		if (!permission.brandguide_edit) return false
		return pages.length - pending.length > 0
	}
	const SectionsToAdd = (): JSX.Element => {
		const allSections = brandguide.validations.guide.sections(guide.guide!)

		const handleAddSection = (section: section): void => {
			const handleStore = (guide: brandguide.api.Brandguide) => {
				setLoading(true)
				cache.store({ ...cache, guide: guide }).finally(() => setLoading(false))
			}

			if (section.id === guide.guide?.colors?.id) {
				const upd = brandguide.api.zeros.guide.guideColorsZero({ ...guide.guide.colors, enabled: true })
				handleStore({ ...cache.guide, guide: { ...cache.guide.guide, colors: upd } })
			}
			if (section.id === guide.guide?.logos?.id) {
				const upd = brandguide.api.zeros.guide.guideLogosZero({ ...guide.guide.logos, enabled: true })
				handleStore({ ...cache.guide, guide: { ...cache.guide.guide, logos: upd } })
			}
			if (section.id === guide.guide?.typography?.id) {
				const upd = brandguide.api.zeros.guide.guideTypographyZero({ ...guide.guide.typography, enabled: true })
				handleStore({ ...cache.guide, guide: { ...cache.guide.guide, typography: upd } })
			}
			if (section.id === guide.guide?.imagery?.id) {
				const upd = brandguide.api.zeros.guide.guideImageryZero({ ...guide.guide.imagery, enabled: true })
				handleStore({ ...cache.guide, guide: { ...cache.guide.guide, imagery: upd } })
			}
			if (section.id === guide.guide?.tone_of_voice?.id) {
				const upd = brandguide.api.zeros.guide.guideToneOfVoiceZero({ ...guide.guide.tone_of_voice, enabled: true })
				handleStore({ ...cache.guide, guide: { ...cache.guide.guide, tone_of_voice: upd } })
			}
			if (section.id === guide.guide?.archetype?.id) {
				const upd = brandguide.api.zeros.guide.guideArchetypeZero({ ...guide.guide.archetype, enabled: true })
				handleStore({ ...cache.guide, guide: { ...cache.guide.guide, archetype: upd } })
			}
			if (section.id === guide.guide?.application?.id) {
				const upd = brandguide.api.zeros.guide.guideApplicationZero({ ...guide.guide.application, enabled: true })
				handleStore({ ...cache.guide, guide: { ...cache.guide.guide, application: upd } })
			}
			if (section.id === guide.guide?.compliance?.id) {
				const upd = brandguide.api.zeros.guide.guideComplianceZero({ ...guide.guide.compliance, enabled: true })
				handleStore({ ...cache.guide, guide: { ...cache.guide.guide, compliance: upd } })
			}
		}

		return (
			<layouts.Flex flexDirection="column" my="20px" flex="1">
				<layouts.Flex justifyContent="center">
					<typography.h4 fontSize="14px" fontWeight="400">
						Add Section(s)
					</typography.h4>
				</layouts.Flex>

				<layouts.Flex flexDirection="column" my="5px">
					<layouts.loading.pending loading={loading}>
						{allSections.map((section, i) => {
							if (section?.enabled || false) return <React.Fragment key={i} />
							return (
								<layouts.Flex
									key={section?.id}
									minHeight="15px"
									minWidth="80px"
									my="8px"
									onClick={() => handleAddSection(section!)}
									data-testid={`add-${section?.id}-section-link`}
								>
									<typography.h5
										fontSize="14px"
										fontWeight="400"
										whiteSpace="unset"
										color={layouts.theme.colors.grey.dark50alpha40}
									>
										+ {brandguide.build.layouts.toTitleCase(section?.id || "")}
									</typography.h5>
								</layouts.Flex>
							)
						})}
					</layouts.loading.pending>
				</layouts.Flex>
			</layouts.Flex>
		)
	}

	const Colors = (
		<LinkContainer data-testid="inactive-colors-link">
			<PageIcon state={brandguide.validations.guide.colors_state(activPage, guide)} />
			<NavTitle pending={brandguide.validations.guide.colors_state(activPage, guide) === "pending"}>Colors</NavTitle>
		</LinkContainer>
	)

	const Logos = (
		<LinkContainer>
			<PageIcon state={brandguide.validations.guide.logos_state(activPage, guide)} />
			<NavTitle pending={brandguide.validations.guide.logos_state(activPage, guide) === "pending"}>Logos</NavTitle>
		</LinkContainer>
	)

	const Typography = (
		<LinkContainer>
			<PageIcon state={brandguide.validations.guide.typography_state(activPage, guide)} />
			<NavTitle pending={brandguide.validations.guide.typography_state(activPage, guide) === "pending"}>
				Typography
			</NavTitle>
		</LinkContainer>
	)

	const Imagery = (
		<LinkContainer>
			<PageIcon state={brandguide.validations.guide.imagery_state(activPage, guide)} />
			<NavTitle pending={brandguide.validations.guide.imagery_state(activPage, guide) === "pending"}>Imagery</NavTitle>
		</LinkContainer>
	)

	const ToneOfVoice = (
		<LinkContainer>
			<PageIcon state={brandguide.validations.guide.tone_and_voice_state(activPage, guide)} />
			<NavTitle pending={brandguide.validations.guide.tone_and_voice_state(activPage, guide) === "pending"}>
				Tone of Voice
			</NavTitle>
		</LinkContainer>
	)

	const Archetype = (
		<LinkContainer>
			<PageIcon state={brandguide.validations.guide.archetype_state(activPage, guide)} />
			<NavTitle pending={brandguide.validations.guide.archetype_state(activPage, guide) === "pending"}>
				Archetype
			</NavTitle>
		</LinkContainer>
	)

	const Application = (
		<LinkContainer>
			<PageIcon state={brandguide.validations.guide.application_state(activPage, guide)} />
			<NavTitle pending={brandguide.validations.guide.application_state(activPage, guide) === "pending"}>
				Application
			</NavTitle>
		</LinkContainer>
	)

	const Compliance = (
		<LinkContainer>
			<PageIcon state={brandguide.validations.guide.compliance_state(activPage, guide)} />
			<NavTitle pending={brandguide.validations.guide.compliance_state(activPage, guide) === "pending"}>
				Compliance
			</NavTitle>
		</LinkContainer>
	)

	return (
		<layouts.Flex className="right-guidelines" {...rest}>
			<typography.h4 fontSize="16px" color={layouts.theme.colors.grey.dark50}>
				{brand.description}
				<br />
				Brand Guidelines
			</typography.h4>
			<layouts.Flex flexDirection="column" my="20px" alignItems="center">
				<typography.h4 fontSize="12px" color={layouts.theme.colors.grey.dark50}>
					{progressVal}% Complete
				</typography.h4>
				<layouts.bars.progress.Percentage
					mt="6px"
					width="100%"
					height="10px"
					borderRadius="18px"
					val={progressVal}
					progressColor={layouts.theme.colors.blue.blue}
				/>
			</layouts.Flex>
			<layouts.Flex
				flexDirection="column"
				pb="10px"
				borderBottom={`1px dashed ${layouts.theme.colors.grey.dark50alpha20}`}
			>
				{guide.guide?.colors?.enabled && (
					<authz.Protected
						enabled={brandguide.validations.colors.valid.completed(guide.guide.colors) || permission.brandguide_edit}
						rejected={Colors}
					>
						<Link
							to={brandguide.routing.build.colors(brand.id)}
							style={{ pointerEvents: activPage === "colors" ? "none" : "unset", textDecoration: "none" }}
							data-testid="guideline-colors-link"
						>
							{Colors}
						</Link>
					</authz.Protected>
				)}
				{guide.guide?.logos?.enabled && (
					<authz.Protected
						enabled={brandguide.validations.logos.valid.completed(guide.guide.logos) || permission.brandguide_edit}
						rejected={Logos}
					>
						<Link
							to={brandguide.routing.build.logos(brand.id)}
							style={{ pointerEvents: activPage === "logos" ? "none" : "unset", textDecoration: "none" }}
							data-testid="guideline-logos-link"
						>
							{Logos}
						</Link>
					</authz.Protected>
				)}
				{guide.guide?.typography?.enabled && (
					<authz.Protected
						enabled={
							brandguide.validations.typography.valid.completed(guide.guide.typography) || permission.brandguide_edit
						}
						rejected={Typography}
					>
						<Link
							to={brandguide.routing.build.typography(brand.id)}
							style={{ pointerEvents: activPage === "typography" ? "none" : "unset", textDecoration: "none" }}
						>
							{Typography}
						</Link>
					</authz.Protected>
				)}
				{guide.guide?.imagery?.enabled && (
					<authz.Protected
						enabled={brandguide.validations.imagery.valid.completed(guide.guide.imagery) || permission.brandguide_edit}
						rejected={Imagery}
					>
						<Link
							to={brandguide.routing.build.imagery(brand.id)}
							style={{ pointerEvents: activPage === "imagery" ? "none" : "unset", textDecoration: "none" }}
						>
							{Imagery}
						</Link>
					</authz.Protected>
				)}
				{guide.guide?.tone_of_voice?.enabled && (
					<authz.Protected
						enabled={
							brandguide.validations.tone_of_voice.valid.completed(guide.guide.tone_of_voice) ||
							permission.brandguide_edit
						}
						rejected={ToneOfVoice}
					>
						<Link
							to={brandguide.routing.build.tone_of_voice(brand.id)}
							style={{ pointerEvents: activPage === "tone_of_voice" ? "none" : "unset", textDecoration: "none" }}
						>
							{ToneOfVoice}
						</Link>
					</authz.Protected>
				)}
				{guide.guide?.archetype?.enabled && (
					<authz.Protected
						enabled={
							brandguide.validations.archetype.valid.completed(guide.guide.archetype) || permission.brandguide_edit
						}
						rejected={Archetype}
					>
						<Link
							to={brandguide.routing.build.archetype(brand.id)}
							style={{ pointerEvents: activPage === "archetype" ? "none" : "unset", textDecoration: "none" }}
						>
							{Archetype}
						</Link>
					</authz.Protected>
				)}
				{guide.guide?.compliance?.enabled && (
					<authz.Protected
						enabled={
							brandguide.validations.compliance.valid.completed(guide.guide.compliance) || permission.brandguide_edit
						}
						rejected={Compliance}
					>
						<Link
							to={brandguide.routing.build.compliance(brand.id)}
							style={{ pointerEvents: activPage === "compliance" ? "none" : "unset", textDecoration: "none" }}
						>
							{Compliance}
						</Link>
					</authz.Protected>
				)}
				{guide.guide?.application?.enabled && (
					<authz.Protected
						enabled={
							brandguide.validations.application.valid.completed(guide.guide.application) || permission.brandguide_edit
						}
						rejected={Application}
					>
						<Link
							to={brandguide.routing.build.application(brand.id)}
							style={{ pointerEvents: activPage === "application" ? "none" : "unset", textDecoration: "none" }}
						>
							{Application}
						</Link>
					</authz.Protected>
				)}
			</layouts.Flex>
			{canAddSection() && (
				<layouts.Flex borderTop={`1px dashed ${layouts.theme.colors.grey.dark50alpha20}`} justifyContent="center">
					<SectionsToAdd />
				</layouts.Flex>
			)}
			<authz.Protected enabled={permission.brandguide_edit}>
				<GuideActions
					onReset={() =>
						cache.store({
							...cache,
							guide: brandguide.api.zeros.zero({ brand_id: brand.id }),
						})
					}
				/>
			</authz.Protected>
		</layouts.Flex>
	)
}

Guidelines.defaultProps = {
	flex: "2",
	flexDirection: "column",
	borderLeft: "1px solid rgba(53, 62, 78, 0.30)",
	px: "29px",
	minWidth: "215px",
}

interface guideActionsProps extends layouts.containers.ContainerProps {
	onReset(): void
}

function GuideActions(props: guideActionsProps): JSX.Element {
	const { onReset, ...rest } = props
	const [focused, setFocused] = useState(false)
	return (
		<layouts.Flex {...rest} data-testid="guide-actions">
			<layouts.Flex position="relative" {...NavProps}>
				<layouts.Flex onClick={() => setFocused(!focused)}>
					<icons.brandguide.Trash />
				</layouts.Flex>
				{focused && (
					<layouts.Flex
						position="absolute"
						flexDirection="column"
						background={layouts.theme.colors.white}
						bottom="0"
						borderRadius="5px"
						border={`1px solid ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 14)}`}
						boxShadow={`0px 2px 2px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 17)}`}
						maxWidth="150px"
						p="5px"
					>
						<layouts.Flex justifyContent="end" onClick={() => setFocused(false)}>
							<icons.Close width="8pt" height="8pt" fill={layouts.theme.colors.grey.dark50alpha80} />
						</layouts.Flex>
						<layouts.Flex px="15px">
							<typography.h6
								fontSize="12px"
								fontWeight="400"
								color={layouts.theme.colors.grey.dark50}
								whiteSpace="unset"
							>
								Are you sure you want to delete this?
							</typography.h6>
						</layouts.Flex>
						<layouts.Flex p="15px" justifyContent="center" alignItems="center" gridGap="10px" className={styledButtons}>
							<layouts.Flex>
								<layouts.buttons.unstyled height="19px" width="40px" onClick={onReset}>
									YES
								</layouts.buttons.unstyled>
							</layouts.Flex>
							<layouts.Flex>
								<layouts.buttons.unstyled height="19px" width="40px" onClick={() => setFocused(false)}>
									NO
								</layouts.buttons.unstyled>
							</layouts.Flex>
						</layouts.Flex>
					</layouts.Flex>
				)}
			</layouts.Flex>
		</layouts.Flex>
	)
}

GuideActions.defaultProps = {
	gridGap: "15px",
	flex: "1",
	justifyContent: "center",
	alignItems: "flex-start",
	mt: "20px",
}

const styledButtons = css`
	button {
		cursor: pointer;
		color: ${layouts.theme.colors.grey.dark50alpha80};
		background: ${layouts.theme.colors.grey.dark50alpha05};
		font-size: 11px;
		font-weight: 400;
		border: 1px solid ${layouts.theme.colors.grey.dark50alpha80};
		border-radius: 3px;
		&:focus,
		&:hover {
			background: ${layouts.theme.colors.grey.dark50alpha80};
			color: ${layouts.theme.colors.white};
		}
	}
`

const NavProps = {
	height: "30px",
	width: "30px",
	borderRadius: "50%",
	boxShadow: "0px 2px 2px rgba(53, 62, 78, 0.15)",
	alignItems: "center",
	justifyContent: "center",
}

export default Guidelines

import React from "react"
import * as svg from "./svg"
import * as layouts from "layouts"

export const Icon = (props: svg.SVGProps) => {
	const { ...rest } = props
	return (
		<svg.SVG width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M23.8469 49.4999H12.8469C9.80933 49.4999 7.3469 47.0375 7.34692 43.9999L7.34714 11C7.34716 7.96248 9.80958 5.50006 12.8471 5.50006H37.5978C40.6353 5.50006 43.0978 7.96249 43.0978 11.0001V26.1251M39.8751 48.5564V40.7782M39.8751 40.7782V33.0001M39.8751 40.7782L32.097 40.7782M39.8751 40.7782L47.6533 40.7782"
				stroke="#3E6EE4"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg.SVG>
	)
}

export default function StyledIcon(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { ...rest } = props
	return (
		<layouts.containers.flex
			mx="auto"
			height="85px"
			width="85px"
			background={layouts.theme.backgrounds.uploadicon}
			borderRadius="50%"
			display="inline-block"
			{...rest}
		>
			<layouts.containers.flex justifyContent="center" alignItems="center" height="100%">
				<Icon />
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

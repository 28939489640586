import React, { useState, useContext, useEffect } from "react"
import styled from "@emotion/styled"
import { useLocation } from "react-router-dom"
import * as errors from "errors"
import * as theme from "./theme"
import * as containers from "./containers"

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${theme.colors.black}CC;
`
const Outer = styled(containers.flex)`
	width: 100%;
	height: 100%;
`

export const Context = React.createContext({
	current: undefined as JSX.Element | undefined,
	toggle: (m: JSX.Element | undefined) => {
		return Promise.resolve(m)
	},
})
export const Provider = Context.Provider

export function useToggle(): (m: JSX.Element | undefined) => Promise<JSX.Element | undefined> {
	const { toggle } = useContext(Context)
	return toggle
}

export function Root(
	props: React.PropsWithChildren<{ initialized?: JSX.Element } & containers.FlexProps>,
): JSX.Element {
	const location = useLocation()
	const noopclose = (m: JSX.Element | undefined) => {}
	const { className, initialized, children, ...rest } = props
	const [m, setModal] = useState(initialized as JSX.Element | undefined)
	const [close, setOnClose] = useState(() => noopclose)
	const cleanup = () => {
		close(m)
		setModal(undefined)
		setOnClose(() => noopclose)
	}

	const modal = m ? (
		<Container>
			<Outer className="modal" onClick={cleanup} background={theme.backgrounds.blackalpha75}>
				<containers.flex
					className="modal inner"
					onClick={(evt) => evt.stopPropagation()}
					background="unset"
					cursor="auto"
					margin="auto"
				>
					<errors.Boundary>{m}</errors.Boundary>
				</containers.flex>
			</Outer>
		</Container>
	) : undefined
	const ctx = {
		current: m,
		toggle: (el: JSX.Element | undefined): Promise<JSX.Element | undefined> => {
			setModal(el)
			return new Promise((resolve) => {
				setOnClose(() => resolve)
			})
		},
	}

	useEffect(cleanup, [location])

	return (
		<Provider value={ctx}>
			<containers.flex className={className} flex="1" position="relative" {...rest}>
				{children}
				{modal}
			</containers.flex>
		</Provider>
	)
}

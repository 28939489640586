import React, { useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as b64 from "js-base64"
import * as typographies from "brandguide/build/typography"
import { Link } from "react-router-dom"
import * as brands from "brands"
import { results } from "brandguide/build/layouts"
import * as navigation from "brandguide/build/navigation"
import * as authz from "authz"
import * as authzc from "authzcached"

function getAlphabetArray(): string[] {
	let alphabetArray: string[] = []
	for (let i = 65; i <= 90; i++) {
		let letter = String.fromCharCode(i)
		alphabetArray.push(letter + letter.toLowerCase())
	}
	return alphabetArray
}

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideTypography | undefined): void
}

export function SectionResult(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const guide = brandguide.cache.useCached().guide
	const data = brandguide.api.zeros.guide.guideTypographyZero(guide.guide?.typography)
	const brand = brands.caching.useCached()

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	const fonts = data.fonts || []
	const primary = fonts.find((f) => f.id === "primary")
	const secondary = fonts.find((f) => f.id === "secondary")
	const alternatives = fonts.filter((f) => f.id !== "primary" && f.id !== "secondary")

	useEffect(() => {
		fonts.forEach((font) => {
			if (font.font === "") return
			const barray = b64.toUint8Array(font.font)
			const blob = new Blob([barray])
			const file = new File([blob], font.file_name)
			const reader = new FileReader()
			const newFontFamilyName = `${font.font_family}_${font.id}`
			reader.onloadend = function (e) {
				const font = new FontFace(newFontFamilyName, e!.target!.result!)
				font
					.load()
					.then(function (loadedFont) {
						document.fonts.add(loadedFont)
					})
					.catch(function (error) {
						console.log("Failed to load font: " + error)
					})
			}
			reader.readAsArrayBuffer(file)
		})
	}, [])

	return (
		<layouts.Flex flex="1" {...rest}>
			<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
				<results.Title>Typography</results.Title>
				<results.ResultDesc>
					There are {fonts.length} fonts used for {brand.description} marketing materials.
					<br />
					<br />
				</results.ResultDesc>

				<results.Explanation desc={data.descriptions?.uploads} />

				{primary && (
					<results.SectionContainer>
						<results.Title>Primary Font</results.Title>

						<layouts.Flex flexDirection="column" width="100%" alignItems="start">
							<layouts.Flex my="20px">
								<typography.h4
									fontSize="48px"
									fontWeight="400"
									lineHeight="50px"
									color={layouts.theme.colors.grey.dark50alpha80}
									fontFamily={`${primary.font_family}_${primary.id}`}
								>
									{primary?.font_family || "Error"}
								</typography.h4>
							</layouts.Flex>
							<layouts.Flex gridGap="5px" flexWrap="wrap">
								{getAlphabetArray().map((i) => (
									<layouts.Flex key={i}>
										<typography.h1
											fontSize="36px"
											fontWeight="400"
											lineHeight="40px"
											color={layouts.theme.colors.grey.dark50alpha80}
											fontFamily={`${primary.font_family}_${primary.id}`}
										>
											{i}
										</typography.h1>
									</layouts.Flex>
								))}
							</layouts.Flex>
						</layouts.Flex>
					</results.SectionContainer>
				)}

				{secondary && (
					<results.SectionContainer>
						<results.Title>Secondary Font</results.Title>
						<layouts.Flex flexDirection="column" width="100%" alignItems="start">
							<layouts.Flex my="20px">
								<typography.h4
									fontSize="48px"
									fontWeight="400"
									lineHeight="50px"
									color={layouts.theme.colors.grey.dark50alpha80}
									fontFamily={`${secondary.font_family}_${secondary.id}`}
								>
									{secondary?.font_family || "Error"}
								</typography.h4>
							</layouts.Flex>
							<layouts.Flex gridGap="5px" flexWrap="wrap">
								{getAlphabetArray().map((i) => (
									<layouts.Flex key={i}>
										<typography.h1
											fontSize="36px"
											fontWeight="400"
											lineHeight="40px"
											color={layouts.theme.colors.grey.dark50alpha80}
											fontFamily={`${secondary.font_family}_${secondary.id}`}
										>
											{i}
										</typography.h1>
									</layouts.Flex>
								))}
							</layouts.Flex>
						</layouts.Flex>
					</results.SectionContainer>
				)}
				{alternatives.map((a) => (
					<layouts.Flex key={a.id} flexDirection="column" alignItems="start" mt="32px" className="page-break">
						<results.Title>Alternative Font</results.Title>
						<layouts.Flex flexDirection="column" width="100%" alignItems="start">
							<layouts.Flex my="20px">
								<typography.h4
									fontSize="48px"
									fontWeight="400"
									lineHeight="50px"
									color={layouts.theme.colors.grey.dark50alpha80}
									fontFamily={`${a.font_family}_${a.id}`}
								>
									{a?.font_family || "Error"}
								</typography.h4>
							</layouts.Flex>
							<layouts.Flex gridGap="5px" flexWrap="wrap">
								{getAlphabetArray().map((i) => (
									<layouts.Flex key={i}>
										<typography.h1
											fontSize="36px"
											fontWeight="400"
											lineHeight="40px"
											color={layouts.theme.colors.grey.dark50alpha80}
											fontFamily={`${a.font_family}_${a.id}`}
										>
											{i}
										</typography.h1>
									</layouts.Flex>
								))}
							</layouts.Flex>
						</layouts.Flex>
					</layouts.Flex>
				))}

				<results.SectionContainer>
					<results.Title>Typography Usage</results.Title>
					<results.Explanation desc={data.descriptions?.usage} />

					<layouts.Flex flexDirection="column" width="100%" alignItems="start">
						{(data.items || []).map((item) => {
							const font = fonts.find((f) => f.id === item.font_id)
							const fontFamilyName = `${font?.font_family}_${font?.id}`
							return (
								<layouts.Flex key={item.id} mt="20px">
									<typography.h4
										fontSize={`${item.size}px`}
										fontWeight="400"
										lineHeight={`${item.size + 10}px`}
										color={layouts.theme.colors.grey.dark50alpha80}
										fontFamily={fontFamilyName}
									>
										{item.title}: {typographies.layouts.combinedTitle(item, font?.font_family || "")}
									</typography.h4>
								</layouts.Flex>
							)
						})}
					</layouts.Flex>
				</results.SectionContainer>

				<results.SectionContainer>
					<layouts.Flex flexDirection="column" width="100%" alignItems="start">
						{(data.items || []).map((item) => {
							const font = fonts.find((f) => f.id === item.font_id)
							const fontFamilyName = `${font?.font_family}_${font?.id}`
							return (
								<layouts.Flex key={item.id} mt="20px" width="100%" alignItems="center">
									<layouts.Flex flex="5">
										<typography.h4
											fontSize={`${item.size}px`}
											fontWeight="400"
											lineHeight={`${item.size + 10}px`}
											color={layouts.theme.colors.grey.dark50}
											fontFamily={fontFamilyName}
											whiteSpace="unset"
										>
											Lorem ipsum dolor sit amet, consectetuer adipiscing elit
										</typography.h4>
									</layouts.Flex>
									<layouts.Flex flex="1" flexDirection="column" px="10px">
										<layouts.Flex flex="1" borderBottom={`2px dotted ${layouts.theme.colors.grey.dark50}`} />
										<layouts.Flex flex="1" />
									</layouts.Flex>
									<layouts.Flex flex="1">
										<typography.h5
											fontSize="32px"
											lineHeight="35px"
											color={layouts.theme.colors.grey.dark50}
											whiteSpace="unset"
										>
											{item.title}
										</typography.h5>
									</layouts.Flex>
								</layouts.Flex>
							)
						})}
					</layouts.Flex>
				</results.SectionContainer>

				<authz.Protected enabled={permission.brandguide_edit}>
					<layouts.Flex justifyContent="center" mt="100px" mb="20px" alignItems="center">
						<NextStageLink guide={guide} className="no-print" />
						<navigation.Section
							title="Typography"
							steps={typographies.layouts.steps}
							onChange={(s) => onChange(s, undefined)}
						/>
					</layouts.Flex>
				</authz.Protected>
			</layouts.Flex>
			<colors.Guidelines activPage="typography" className="no-print" />
		</layouts.Flex>
	)
}

function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<layouts.Flex justifyContent="right" mr="47px">
						<brandguide.build.SavePDF />
					</layouts.Flex>
					<SectionResult onChange={onChange} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface nextProps extends layouts.containers.FlexProps {
	guide: brandguide.api.Brandguide
}

function NextStageLink(props: nextProps): JSX.Element {
	const { guide, ...rest } = props
	const brand = brands.caching.useCached()

	const nextLink = (): string | undefined => {
		if (guide.guide?.imagery?.enabled) return brandguide.routing.build.imagery(brand.id)
		if (guide.guide?.tone_of_voice?.enabled) return brandguide.routing.build.tone_of_voice(brand.id)
		if (guide.guide?.archetype?.enabled) return brandguide.routing.build.archetype(brand.id)
		if (guide.guide?.compliance?.enabled) return brandguide.routing.build.compliance(brand.id)
		if (guide.guide?.application?.enabled) return brandguide.routing.build.application(brand.id)
		return undefined
	}

	if (!nextLink()) return <></>

	return (
		<layouts.Flex {...rest}>
			<Link to={nextLink()!}>
				<layouts.buttons.unstyled
					width="130px"
					height="34px"
					background={layouts.theme.colors.grey.dark50}
					borderRadius="5px"
					color={layouts.theme.colors.white}
					onClick={() => undefined}
				>
					Next Section
				</layouts.buttons.unstyled>
			</Link>
		</layouts.Flex>
	)
}

NextStageLink.defaultProps = {
	flex: "1",
	justifyContent: "end",
}

export default Display

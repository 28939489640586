import * as svg from "./svg"

const Icon = (props: svg.SVGProps) => {
	const { fill, stroke, ...rest } = props
	return (
		<svg.SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px" {...rest}>
			<path fill={fill} stroke={stroke} d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)" />
			<path fill={fill} stroke={stroke} d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)" />
		</svg.SVG>
	)
}

Icon.defaultProps = {
	stroke: "unset",
}

export default Icon

import { SectionTitle } from "brandguide/results/components"
import { useState, useEffect } from "react"

import { default as Fundamentals } from "./fundamentals"
import { default as Lifestyle } from "./lifestyle"
import { default as Illustration } from "./illustration"
import { default as Product } from "./product"
import { default as Misuse } from "./misuse"

export { default as Fundamentals } from "./fundamentals"
export { default as Lifestyle } from "./lifestyle"
export { default as Illustration } from "./illustration"
export { default as Product } from "./product"
export { default as Misuse } from "./misuse"

interface props {
	onLoad(): void
}

export function Section(props: props): JSX.Element {
	const { onLoad } = props

	const [loadedSections, setLoadedSections] = useState(4)

	useEffect(() => {
		if (loadedSections > 0) return
		onLoad()
	}, [loadedSections])

	return (
		<>
			<SectionTitle title="Imagery" className="section-print" />
			<Fundamentals className="section-print" />
			<Lifestyle onLoad={() => setLoadedSections((prev) => prev - 1)} />
			<Product onLoad={() => setLoadedSections((prev) => prev - 1)} />
			<Illustration onLoad={() => setLoadedSections((prev) => prev - 1)} />
			<Misuse onLoad={() => setLoadedSections((prev) => prev - 1)} className="section-print" />
		</>
	)
}

// min asserts at least a minimum number of charaters.
export function min(n: number, invalid: JSX.Element = <>too short</>): (i: string) => JSX.Element | undefined {
	return (i: string) => (i.length >= n ? undefined : invalid)
}

// max asserts at no more than a maximum number of charaters.
export function max(n: number, invalid: JSX.Element = <>too long</>): (i: string) => JSX.Element | undefined {
	return (i: string) => (i.length <= n ? undefined : invalid)
}

// reference rules validations, should be words in quotes separated by commas
export function reference_rules(
	invalid: JSX.Element = <>invalid reference rules</>,
): (i: string) => JSX.Element | undefined {
	return (i: string) =>
		i.split(",").every((rl) => {
			rl = rl.trim()
			// eslint-disable-next-line prettier/prettier
			return rl.length > 2 && rl[0] === "\"" && rl[rl.length - 1] === "\""
		})
			? undefined
			: invalid
}

// email validates the string as an email address, its intentionally extremely lax
// only checking if the string contains an @ symbol.
export function email(invalid: JSX.Element = <>invalid email</>): (i: string) => JSX.Element | undefined {
	return (i: string) => (i.includes("@") ? undefined : invalid)
}

export function url(invalid: JSX.Element = <>invalid url</>): (i: string) => JSX.Element | undefined {
	return (i: string) => {
		const valid = (i.startsWith("http://") || i.startsWith("https://")) && !i.endsWith("://")
		return valid ? undefined : invalid
	}
}

// function for validating a subdomain name (without domain part)
export function rfc1035_sub(
	invalid: JSX.Element = <>invalid subdomain name</>,
): (i: string) => JSX.Element | undefined {
	return (i: string) => {
		const r = /^[a-zA-Z0-9-]+$/
		const valid = i.length >= 1 && i.length <= 63 && r.test(i)
		return valid ? undefined : invalid
	}
}

// function for validating a full domain name.
export function rfc1035_domain(
	invalid: JSX.Element = <>invalid domain name</>,
): (i: string) => JSX.Element | undefined {
	return (i: string) => {
		const r = /^([a-zA-Z0-9](?:[a-zA-Z0-9-]{0,63}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/
		const valid = i.length <= 253 && r.test(i)
		return valid ? undefined : invalid
	}
}

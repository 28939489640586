import * as httpx from "httpx"
import { CancellablePromise } from "real-cancellable-promise"
import * as uuid from "uuid"
import * as timex from "timex"

import {
	Styleguide,
	StyleguideUploadResponse,
	StyleguideSearchRequest,
	StyleguideSearchResponse,
	State,
} from "./styleguide_pb"

export {
	Styleguide,
	StyleguideUploadRequest,
	StyleguideUploadResponse,
	StyleguideSearchRequest,
	StyleguideSearchResponse,
	State,
} from "./styleguide_pb"

export function zero(s: Partial<Styleguide> = {}): Styleguide {
	const ts = timex.local()
	return {
		id: uuid.NIL,
		brand_id: uuid.NIL,
		account_id: uuid.NIL,
		uploaded_by: uuid.NIL,
		md5: "",
		mimetype: "",
		mimetype_md5: "",
		description: "",
		created_at: ts.toISO(),
		updated_at: ts.toISO(),
		tombstoned_at: timex.infinity().toISO(),
		state: State.UPLOADED,
		welcome_message: "",
		...s,
	}
}

export function search(
	bid: string,
	req: StyleguideSearchRequest,
	...options: httpx.option[]
): CancellablePromise<StyleguideSearchResponse> {
	return httpx
		.get<StyleguideSearchResponse>(`${httpx.urlstorage.host()}/brands/${bid}/brandgpt/styleguides`, req, ...options)
		.then((resp) => ({
			...resp,
			items: (resp.items || []).map((item) => zero(item)),
		}))
}
export function upload(
	bid: string,
	file: File,
	...options: httpx.option[]
): CancellablePromise<StyleguideUploadResponse> {
	const data = new FormData()
	data.append("content", file)
	return httpx
		.upload<Styleguide>(`${httpx.urlstorage.host()}/brands/${bid}/brandgpt/styleguides`, data, ...options)
		.then((sg) => ({ styleguide: zero(sg) }))
}
export function find(
	bid: string,
	id: string,
	...options: httpx.option[]
): CancellablePromise<StyleguideUploadResponse> {
	return httpx
		.get<StyleguideUploadResponse>(
			`${httpx.urlstorage.host()}/brands/${bid}/brandgpt/styleguides/${id}`,
			{},
			...options,
		)
		.then((sg) => ({ styleguide: zero(sg.styleguide) }))
}

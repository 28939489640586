import React from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import styled from "@emotion/styled"

const Text = styled(typography.h3)`
	&:before {
		content: "To Begin: ";
		font-weight: 700;
	}
`

export function Container(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return <layouts.containers.flex {...rest}>{children}</layouts.containers.flex>
}

Container.defaultProps = {
	className: "styled-drop-area",
	flexDirection: "column",
	background: layouts.theme.backgrounds.bluealpha,
	border: `1px dashed ${layouts.theme.colors.blue.blue}`,
	borderRadius: "10px",
	p: "50px",
	color: layouts.theme.colors.grey.medium,
}

export function Textual(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return <Text {...rest}>{children}</Text>
}

Textual.defaultProps = {
	pt: "25px",
	fontWeight: "400",
	fontSize: "12pt",
	lineHeight: "16px",
	textAlign: "center",
	color: layouts.theme.colors.grey.dark10,
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as logos from "brandguide/build/logos"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideLogos | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideLogosZero(_guide.guide?.logos))

	const items = data.placement

	const svg = data.primary!.svg!

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Logo Placement" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer
							desc="Please select you preferred placement, multiple selections accepted. These rules will apply to all logo
								variations."
						>
							<layouts.overlays.Container flexDirection="column" overflow="auto" height="42vh" my="10px">
								<layouts.containers.grid
									gap="15px"
									gridTemplateColumns="repeat(4, minmax(200px, 1fr))"
									gridTemplateRows="repeat(auto-fit, minmax(120px, 1fr))"
									style={{ alignContent: "start", alignItems: "start" }}
									minHeight="0px"
								>
									<logos.layouts.PlacementItem
										items={items?.left || []}
										posX="start"
										logo={svg}
										title="Left Aligned"
										onChange={(upd) => {
											const u = brandguide.api.zeros.logos.logoPlacementZero({ ...data.placement, left: upd })
											if (u.left.length !== 0) u.all = []
											setData({ ...data, placement: u })
										}}
									/>
									<logos.layouts.PlacementItem
										items={items?.center || []}
										posX="center"
										logo={svg}
										title="Center Aligned"
										onChange={(upd) => {
											const u = brandguide.api.zeros.logos.logoPlacementZero({ ...data.placement, center: upd })
											if (u.center.length !== 0) u.all = []
											setData({ ...data, placement: u })
										}}
									/>
									<logos.layouts.PlacementItem
										items={items?.right || []}
										posX="end"
										logo={svg}
										title="Right Aligned"
										onChange={(upd) => {
											const u = brandguide.api.zeros.logos.logoPlacementZero({ ...data.placement, right: upd })
											if (u.right.length !== 0) u.all = []
											setData({ ...data, placement: u })
										}}
									/>
									<logos.layouts.PlacementItem
										items={items?.all || []}
										posX="center"
										alignAll
										logo={svg}
										title="All"
										onChange={(upd) => {
											const u = brandguide.api.zeros.logos.logoPlacementZero({ ...data.placement, all: upd })
											if (u.all.length !== 0) {
												u.center = []
												u.left = []
												u.right = []
											}
											setData({ ...data, placement: u })
										}}
									/>
								</layouts.containers.grid>
							</layouts.overlays.Container>

							<logos.Explanation
								val={data.descriptions?.placement || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.logos.descZero({ ...data.descriptions, placement: text }),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(logos.Steps.PROPORTIONS, undefined)}
								onNext={() => onChange(logos.Steps.MISUSE, data)}
								disabled={!brandguide.validations.logos.valid.placement(data)}
							/>
							<brandguide.build.layouts.SkipStep
								my="30px"
								onSkip={() => onChange(logos.Steps.MISUSE, data)}
								onNeverHave={() => onChange(logos.Steps.MISUSE, data)}
							/>
							<logos.Progress activeStep={logos.Steps.PLACEMENT} steps={logos.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<logos.Guidelines activPage="logos" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import React from "react"
import { Route, Routes, Navigate, useLocation, Link } from "react-router-dom"
import * as oauth2 from "."
import * as layouts from "layouts"

interface navlink {
	title: string
	url: string
}

const links = [
	{ title: "Manage Access", url: "/u12t/oauth2" },
	{ title: "Developer Credentials", url: "/u12t/oauth2/credentials" },
] as navlink[]

export function Nav(): JSX.Element {
	const location = useLocation()
	return (
		<layouts.containers.flex
			flexDirection="row"
			height="45px"
			maxWidth="365px"
			mt="10px"
			borderRadius="10px"
			background={layouts.theme.colors.grey.dark50alpha05}
			alignItems="center"
			justifyContent="space-around"
			gridGap="15px"
			fontSize="14px"
			lineHeight="30px"
		>
			{links.map((l) => {
				if (l.url === location.pathname)
					return (
						<layouts.containers.flex
							key={l.url}
							background={layouts.theme.colors.white}
							width="165px"
							height="35px"
							borderRadius="10px"
							justifyContent="center"
							alignItems="center"
							color={layouts.theme.colors.grey.dark50}
						>
							{l.title}
						</layouts.containers.flex>
					)
				return (
					<Link key={l.url} to={l.url} style={{ textDecoration: "none", color: "inherit" }}>
						<layouts.containers.flex
							width="165px"
							height="35px"
							justifyContent="center"
							alignItems="center"
							color={layouts.theme.colors.grey.dark50alpha40}
						>
							{l.title}
						</layouts.containers.flex>
					</Link>
				)
			})}
		</layouts.containers.flex>
	)
}

export default function Routing(): JSX.Element {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<>
						<Nav />
						<oauth2.Display />
					</>
				}
			/>
			<Route
				path="/credentials"
				element={
					<>
						<Nav />
						<oauth2.Credentials />
					</>
				}
			/>
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	)
}

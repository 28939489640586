import * as layouts from "layouts"
import { Line } from "charts"
import { metric } from "./chart"
import * as metricsLayouts from "./layouts"

const chartOptions = {
	responsive: true,
	scales: {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				display: false,
			},
		},

		y: {
			grid: {
				display: false,
			},
			ticks: {
				display: false,
			},
		},
	},
	elements: {
		point: {
			radius: 0,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
	},
}

function chartData(metric: metric) {
	return {
		labels: metric.chart.labels,
		datasets: [
			{
				fill: true,
				data: Object.values(metric.chart.data),
				borderColor: metric.chart.borderColor,
				backgroundColor: metric.chart.backgroundColor,
				tension: 0.2,
			},
		],
	}
}

export function card(props: { data: metric; icon: JSX.Element }): JSX.Element {
	const { data, icon } = props

	return (
		<metricsLayouts.Card
			flex="1"
			maxWidth="49%"
			minHeight="230px"
			maxHeight="230px"
			padding="20px"
			mr="1px"
			textAlign="center"
			flexDirection="column"
		>
			<metricsLayouts.Header mb="15px">{data.title}</metricsLayouts.Header>
			<layouts.containers.flex>
				<layouts.containers.flex pr="10px">{icon}</layouts.containers.flex>
				<layouts.containers.flex fontSize="30px" verticalAlign="middle">
					{String(data.val)}
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex height="110px" className={metricsLayouts.ConstrainChart}>
				<Line options={chartOptions} data={chartData(data)} width={300} height={100} />
			</layouts.containers.flex>
			<metricsLayouts.Header py="15px" color={layouts.theme.colors.grey.dark10}>
				<>Total {data.title}</>
			</metricsLayouts.Header>
		</metricsLayouts.Card>
	)
}

/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable,generate_dependencies
// @generated from protobuf file "meta.notification.proto" (package "meta.notification", syntax proto3)
// tslint:disable
// @ts-nocheck
/**
 * easyjson:json
 *
 * @generated from protobuf enum meta.notification.Event
 */
export enum Event {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: IMG_NEED_REVIEW = 1;
     */
    IMG_NEED_REVIEW = 1,
    /**
     * @generated from protobuf enum value: IMG_REVIEWED = 2;
     */
    IMG_REVIEWED = 2,
    /**
     * @generated from protobuf enum value: TEXT_NEED_REVIEW = 3;
     */
    TEXT_NEED_REVIEW = 3,
    /**
     * @generated from protobuf enum value: TEXT_REVIEWED = 4;
     */
    TEXT_REVIEWED = 4
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as typography from "typography"
import * as profiles from "profiles"
import * as um from "meta/usermanagement"
import * as uuid from "uuid"

export default function Display(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const permission = authzc.useCache((cached) => cached.meta.current)

	const _req = profiles.api.searches.zero({ enabled: profiles.api.StatusFilters.Enabled, limit: 20n })
	const _pendingReq = profiles.api.searches.pzero({ limit: 20n })
	const [req, setReq] = useState(_req)
	const [pendingReq, setPendingReq] = useState(_pendingReq)
	const [profilesKey, setProfilesKey] = useState(uuid.v4())
	const [pendingsKey, setPendingsKey] = useState(uuid.v4())

	return (
		<>
			<layouts.containers.flex
				flexDirection="row"
				flex="2"
				background={layouts.theme.colors.white}
				mt="10px"
				overflow="hidden"
				data-testid="usermanagement-container"
				{...rest}
			>
				<layouts.containers.flex flexDirection="column" flex="2" py="50px" px="40px" minWidth="150px" overflowY="auto">
					<typography.h3
						fontSize="16px"
						fontWeight="700"
						lineHeight="30px"
						color={layouts.theme.colors.grey.dark50}
						mb="5px"
					>
						Users
					</typography.h3>
					<typography.h6
						fontSize="12px"
						fontWeight="400"
						lineHeight="20px"
						color={layouts.theme.colors.grey.dark50}
						whiteSpace="unset"
					>
						<authz.Protected
							enabled={permission.usermanagement}
							rejected={<>You do not have permissions to manage users. Please contact an admin.</>}
						>
							Invite your team members to BrandGuard to work faster and collaborate easily together. Manage their
							permissions to better structure assignments.
						</authz.Protected>
					</typography.h6>
					<um.Invite
						onChange={(p) => {
							setPendingReq({ ...pendingReq })
							setPendingsKey(uuid.v4())
						}}
					/>
				</layouts.containers.flex>
				<layouts.containers.flex flex="7">
					<layouts.containers.flex px="10px" flex="1" flexDirection="column">
						<um.Search
							req={req}
							onChange={(r) => {
								setReq({ ...req, query: r.query, enabled: r.enabled, created: r.created })
								setProfilesKey(uuid.v4())
								setPendingReq({ ...pendingReq, query: r.query, created: r.created })
								setPendingsKey(uuid.v4())
							}}
							reset={() => {
								setReq(_req)
								setProfilesKey(uuid.v4())
								setPendingReq(_pendingReq)
								setPendingsKey(uuid.v4())
							}}
						/>

						<um.Profiles key={profilesKey} req={req} />
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex width="95%" mx="auto" borderBottom={layouts.theme.borders.grey.dark50alphamedium} />
			{permission.usermanagement && <um.Pending key={pendingsKey} req={pendingReq} />}
		</>
	)
}

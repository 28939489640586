import React, { useState, useEffect, useMemo } from "react"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as bgtext from "brandguard/display/brandguard/text"
import * as bglayouts from "brandguard/components/layouts"
import * as bgapprovals from "brandguard/display/approval"
import * as bgscores from "brandguard/display/scores"
import { UploadSimple } from "brandguard/components"
import * as authz from "authz"
import classnames from "classnames"
import * as nav from "brandguard/components/navigation"
import * as scores from "brandguard/components/layouts/scores"
import * as debugx from "x/debugx"
import * as icons from "icons"
import { CancellablePromise } from "real-cancellable-promise"
import { Text } from "media"
import * as mediaapi from "media/api"
import { styledscroll } from "brandguard/components/layouts"
import { useSettings } from "accounts/settings"
import { State } from "accounts/api"

export interface asset {
	data: api.TextSearchResponseItem | undefined
	details: JSX.Element
}

export default function Display(props: React.PropsWithChildren<unknown>): JSX.Element {
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const profile = sessions.useProfile()
	const settings = useSettings()
	const [loading, setLoading] = useState(true)
	const [selectedItem, setSelectedItem] = useState(undefined as api.TextSearchResponseItem | undefined)
	const [uploadFocused, setUploadFocused] = useState(false)
	const [undoItems, setUndoItems] = useState<api.TextSearchResponseItem[]>([])

	const [searchReq] = useState(
		api.text.uploads.searchZero({
			offset: 0n,
			limit: 50n,
			brand_id: brand.id,
			queues: [uuid.NIL, brand.id],
			approved: [api.Prediction.APPROVED, api.Prediction.INREVIEW, api.Prediction.REJECTED],
		}),
	)

	const [texts, setTexts] = useState({
		items: [],
		next: searchReq,
	} as api.TextSearchResponse)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		if (!loading) setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.text.uploads.search(searchReq.brand_id, searchReq, bearertoken))
			.then((r) => {
				setTexts(r)
				setLoading(false)
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				console.error("unable to search for brandguard text assets", cause)
				setLoading(false)
			})
		return p.cancel
	}, [searchReq])

	useEffect(() => {
		if (texts.items.length > 0) {
			if (selectedItem === undefined) setSelectedItem(texts.items[0])
		} else {
			setSelectedItem(undefined)
		}
	}, [texts])

	const onTextUpdate = (item: api.TextSearchResponseItem): void => {
		setTexts((prevState) => ({
			...prevState,
			items: prevState.items.map((i) => (i.media?.id === item.media?.id ? item : i)),
		}))
	}

	const MemoUpload = useMemo(() => {
		return function MemodUpload(props: inputs.uploads.api.Props) {
			return (
				<authz.Protected
					enabled={permission.brandguard_upload}
					rejected={
						<layouts.containers.flex flex="1" className={classnames(bglayouts.uploads.navdenied)}>
							<authz.layouts.UploadDenied className="styled-area">
								You do not have permissions to upload new assets for scoring. Please contact an admin.
							</authz.layouts.UploadDenied>
						</layouts.containers.flex>
					}
				>
					<bgtext.uploads.default
						background={layouts.theme.colors.blue.blue}
						observation={api.Prediction.APPROVED}
						{...props}
					/>
				</authz.Protected>
			)
		}
	}, [])

	const nextText = () => {
		setSelectedItem(texts.items[(texts.items.indexOf(selectedItem!) + 1) % texts.items.length])
	}

	const prevText = () => {
		setSelectedItem(texts.items[(texts.items.indexOf(selectedItem!) - 1 + texts.items.length) % texts.items.length])
	}

	const handleKeyDown = (evt: KeyboardEvent) => {
		if (evt.key === "ArrowRight") {
			nextText()
		} else if (evt.key === "ArrowLeft") {
			prevText()
		}
	}

	const agree = async (
		item: api.TextSearchResponseItem & { event: api.TextEvent },
	): Promise<api.TextSearchResponseItem> =>
		api.text.approval.disagree(item, authzc.bearer(authzaccount)).then((res_i) => {
			setUndoItems([...undoItems, ...[item]])
			setTexts((prevState) => ({
				...prevState,
				items: prevState.items.map((i) => (i.media?.id === res_i.media?.id ? res_i : i)),
			}))
			if (selectedItem && selectedItem.media?.id === res_i.media?.id) setSelectedItem(res_i)
			return res_i
		})

	const disagree = (item: api.TextSearchResponseItem & { event: api.TextEvent }): Promise<api.TextSearchResponseItem> =>
		api.text.uploads
			.patch(
				item.event.brand_id,
				item.event.id,
				{
					...item,
					media: { ...item.media!, tombstoned_at: new Date().toISOString() },
					event: { ...item.event, observation: api.Prediction.DISCARDED_BY_UPLOADER },
				},
				authzc.bearer(authzaccount),
			)
			.then((res_i) => {
				setUndoItems([...undoItems, ...[item]])
				setTexts((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === res_i.media?.id ? res_i : i)),
				}))
				if (selectedItem && selectedItem.media?.id === res_i.media?.id) setSelectedItem(res_i)
				return res_i
			})

	const undo = (
		current: api.TextSearchResponseItem & { event: api.TextEvent },
		previously: api.TextSearchResponseItem & { event: api.TextEvent },
	): Promise<api.TextPatchResponse> => {
		return api.text.uploads
			.patch(previously.event.id, previously.event.brand_id, previously, authzc.bearer(authzaccount))
			.then((resp_i) => {
				setUndoItems(undoItems.filter((i) => i.media?.id !== previously.media?.id))
				setTexts((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === previously.media?.id ? previously : i)),
				}))
				if (selectedItem && selectedItem.media?.id === previously.media?.id) setSelectedItem(previously)
				return resp_i
			})
			.then((upload) => {
				return api.text.approvallog
					.find(current.event.id, current.event.brand_id, bearertoken)
					.then((result) => {
						return api.text.approvallog
							.destroy(result.event!.brand_id, result.event!.id, authzc.bearer(authzaccount))
							.then(() => {
								return upload
							})
					})
					.catch(
						httpx.errors.notFound((cause) => {
							console.debug("unable to find approval text", cause)
							// ignore 404s
							return upload
						}),
					)
			})
	}

	useEffect(() => {
		if (!selectedItem) return
		window.addEventListener("keydown", handleKeyDown)
		return () => {
			window.removeEventListener("keydown", handleKeyDown)
		}
	}, [selectedItem])

	return (
		<layouts.containers.flex flexDirection="column-reverse" flex="1" mb="25px">
			<layouts.loading.pending className="brandguard-working-area" loading={loading} flex="1" overflow="hidden">
				<layouts.containers.flex className="center-panel" flex="1" flexDirection="column">
					{texts.items.length === 0 && <bgscores.layouts.PreviewZero />}
					{selectedItem && (
						<layouts.containers.flex
							flex="1"
							flexDirection="row"
							border={layouts.theme.borders.grey.dark50alpha}
							boxShadow={layouts.theme.boxshadow.black.wide}
						>
							<layouts.containers.flex
								className="left-text-side"
								flex="6"
								borderRight={layouts.theme.borders.grey.dark50alpha}
								alignItems="center"
								justifyContent="center"
								background={layouts.theme.backgrounds.bluealpha5}
								position="relative"
							>
								<layouts.overlays.Screen enabled justifyContent="center" overflowY="auto" className={styledscroll}>
									<Text
										media={mediaapi.zero({ content_digest: selectedItem.media?.md5 })}
										textAlign="left"
										fontWeight="400"
										fontSize="16px"
										lineHeight="30px"
										p="20px"
										my="auto"
									/>
								</layouts.overlays.Screen>
								{texts.items.length > 1 && (
									<>
										<layouts.containers.absolute left="-20px">
											<layouts.Flex
												background={`${layouts.theme.colors.white}BF`}
												boxShadow="0px 1px 2px 0px #00000040"
												alignItems="center"
												justifyContent="center"
												borderRadius="50%"
												width="40px"
												height="40px"
												cursor="pointer"
												onClick={prevText}
											>
												<icons.arrows.ArrowLeft />
											</layouts.Flex>
										</layouts.containers.absolute>
										<layouts.containers.absolute right="-20px">
											<layouts.Flex
												background={`${layouts.theme.colors.white}BF`}
												boxShadow="0px 1px 2px 0px #00000040"
												alignItems="center"
												justifyContent="center"
												borderRadius="50%"
												width="40px"
												height="40px"
												cursor="pointer"
												onClick={nextText}
											>
												<icons.arrows.ArrowRight />
											</layouts.Flex>
										</layouts.containers.absolute>
									</>
								)}
							</layouts.containers.flex>
							<layouts.containers.flex
								flex="5"
								py="10px"
								pl="40px"
								pr="20px"
								className={classnames("right-score-side", bgscores.layouts.styledscores)}
								background={layouts.theme.colors.white}
							>
								<layouts.Flex flex="1" height="calc(100vh - 250px)">
									<layouts.Flex flex="1" flexDirection="column" overflowY="auto" pr="10px" className={styledscroll}>
										<bgscores.layouts.accordion.Container initOpen title="Asset Scores">
											<bgtext.Scores item={selectedItem} />
										</bgscores.layouts.accordion.Container>
										<bgapprovals.status.default>
											<bgapprovals.status.Uploads
												item={selectedItem}
												previtemstate={undoItems.find((u) => u.media?.id === selectedItem.media?.id)}
												agree={agree}
												disagree={disagree}
												undo={undo}
												borderRadius="10px"
											/>
										</bgapprovals.status.default>
										<bgscores.layouts.accordion.Container title="More Assets">
											<layouts.containers.ResponsiveGrid gap={10} breakpointCols={3} py="10px" px="5px">
												{texts.items.map((item) => {
													return (
														<bgtext.Display
															className={classnames(bgscores.layouts.styledcardtext)}
															key={item.media?.id}
															item={item}
															active={item?.media?.id === selectedItem.media?.id}
															onCardClick={() => setSelectedItem(item)}
															onChange={onTextUpdate}
															panel={
																<authz.Protected enabled={settings.confidence !== State.DISABLED}>
																	<layouts.containers.absolute>
																		<scores.BGScoreTextSimple event={item.event!} />
																	</layouts.containers.absolute>
																</authz.Protected>
															}
															find={api.text.uploads.find}
															border={`2px solid ${layouts.theme.colors.grey.dark50}26`}
														/>
													)
												})}
											</layouts.containers.ResponsiveGrid>
										</bgscores.layouts.accordion.Container>
										<authz.Protected enabled={debugx.alpha.enabled()}>
											<bgscores.layouts.accordion.Container title="Debug Info" textAlign="initial">
												<bgtext.Debug item={selectedItem} />
											</bgscores.layouts.accordion.Container>
										</authz.Protected>
										<layouts.containers.flex className="actions"></layouts.containers.flex>
										<authz.Protected enabled={permission.brandguard_upload}>
											<bgtext.MultiActions
												uploadItems={[selectedItem]}
												deleteAction={permission.brandguard_upload ? api.text.uploads.patch : undefined}
												onDelete={(results) => {
													setSelectedItem(undefined)
													setTexts((prevState) => ({
														...prevState,
														items: prevState.items.filter(
															(i) => !results.map((el) => el.media!.id).includes(i.media!.id),
														),
													}))
												}}
											/>
										</authz.Protected>
									</layouts.Flex>
								</layouts.Flex>
							</layouts.containers.flex>
						</layouts.containers.flex>
					)}
				</layouts.containers.flex>
			</layouts.loading.pending>
			<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative">
				<layouts.buttons.outline
					width="150px"
					height="40px"
					borderRadius="4px"
					onClick={() => setUploadFocused(!uploadFocused)}
					mb="5px"
				>
					{uploadFocused ? (
						<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
					) : (
						<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
					)}
					<layouts.Span px="10px">Upload Assets</layouts.Span>
				</layouts.buttons.outline>
				<layouts.containers.flex display={uploadFocused ? "flex" : "none"} position="absolute" top="100%">
					<UploadSimple
						className={classnames(nav.layouts.slideDownAnimation)}
						isProcessing={(i: api.TextSearchResponseItem) => predictions.processing(i.event?.approved)}
						onChange={(text) => {
							setTexts((prevState) => ({ ...prevState, items: [...[text], ...prevState.items] }))
							setSelectedItem(text)
						}}
						resume={() => {
							const searchReq = {
								offset: 0n,
								limit: 20n,
								uploaded_by: [profile.id],
								brand_id: brand.id,
								queues: [uuid.NIL, brand.id],
								approved: [api.Prediction.NONE],
							} as api.TextSearchRequest

							return api.text.uploads.search(searchReq.brand_id, searchReq, bearertoken).then((text) => {
								return text.items.map(
									(i) =>
										({
											id: uuid.v4(),
											display: i.media?.id,
											background: layouts.theme.colors.blue.blue,
											data: i,
										} as inputs.uploads.api.uploaded<api.TextSearchResponseItem>),
								)
							})
						}}
						refresh={(data) => {
							return api.text.uploads.find(data.media?.id!, brand.id, bearertoken).then((resp) => {
								return resp.event?.approved === api.Prediction.ERROR
									? CancellablePromise.reject(resp)
									: CancellablePromise.resolve(resp)
							})
						}}
						upload={(stats, content) => api.text.uploads.create(content, brand.id, authzc.bearer(authzaccount))}
						DropWell={MemoUpload}
						onClose={() => setUploadFocused(false)}
					/>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

import * as layouts from "layouts"
import * as brandguide from "brandguide"

interface clearspaceGridProps {
	imgWidth: number
	imgHeight: number
	pathbbox: DOMRect | undefined
	focused: brandguide.api.LogoObject
}

export function ClearspaceGrid(props: clearspaceGridProps): JSX.Element {
	const { imgWidth, imgHeight, pathbbox, focused } = props
	if (imgWidth + imgHeight === 0) return <></>
	const paddingX = (focused.clearspace / 100) * (pathbbox?.width || 0)
	const paddingY = (focused.clearspace / 100) * (pathbbox?.height || 0)

	return (
		<svg width={imgWidth * 3} height={imgHeight * 3}>
			<image href={focused.svg} x={imgWidth} y={imgHeight} height="33%" width="33%" />
			{/* inner contour */}
			{/* left */}
			<line
				x1={imgWidth}
				y1="0"
				x2={imgWidth}
				y2={imgHeight * 3}
				stroke={layouts.theme.colors.grey.dark50alpha40}
				strokeDasharray="4"
			/>
			{/* right */}
			<line
				x1={imgWidth * 2}
				y1="0"
				x2={imgWidth * 2}
				y2={imgHeight * 3}
				stroke={layouts.theme.colors.grey.dark50alpha40}
				strokeDasharray="4"
			/>
			{/* top */}
			<line
				x1="0"
				y1={imgHeight}
				x2={imgWidth * 3}
				y2={imgHeight}
				stroke={layouts.theme.colors.grey.dark50alpha40}
				strokeDasharray="4"
			/>
			{/* bottom */}
			<line
				x1="0"
				y1={imgHeight * 2}
				x2={imgWidth * 3}
				y2={imgHeight * 2}
				stroke={layouts.theme.colors.grey.dark50alpha40}
				strokeDasharray="4"
			/>
			{/* outer contour */}
			{/* left */}
			<line
				x1={imgWidth - paddingX}
				y1="0"
				x2={imgWidth - paddingX}
				y2={imgHeight * 3}
				stroke={layouts.theme.colors.grey.dark50alpha40}
				strokeDasharray="4"
			/>
			{/* right */}
			<line
				x1={imgWidth * 2 + paddingX}
				y1="0"
				x2={imgWidth * 2 + paddingX}
				y2={imgHeight * 3}
				stroke={layouts.theme.colors.grey.dark50alpha40}
				strokeDasharray="4"
			/>
			{/* top */}
			<line
				x1="0"
				y1={imgHeight - paddingY}
				x2={imgWidth * 3}
				y2={imgHeight - paddingY}
				stroke={layouts.theme.colors.grey.dark50alpha40}
				strokeDasharray="4"
			/>
			{/* bottom */}
			<line
				x1="0"
				y1={imgHeight * 2 + paddingY}
				x2={imgWidth * 3}
				y2={imgHeight * 2 + paddingY}
				stroke={layouts.theme.colors.grey.dark50alpha40}
				strokeDasharray="4"
			/>
			{/* x-indicator */}
			{/* top-line */}
			<line
				x1="0"
				y1={imgHeight}
				x2={imgWidth + paddingX / 2}
				y2={imgHeight}
				stroke={layouts.theme.colors.grey.dark50}
				strokeDasharray="4"
			/>
			{/* bottom line */}
			<line
				x1="0"
				y1={imgHeight + paddingY}
				x2={imgWidth + paddingX / 2}
				y2={imgHeight + paddingY}
				stroke={layouts.theme.colors.grey.dark50}
				strokeDasharray="4"
			/>
			<text
				x="35"
				y={paddingY === 0 ? imgHeight - 4 : imgHeight + paddingY / 2 + 4}
				textAnchor="middle"
				style={{
					fill: layouts.theme.colors.grey.dark50,
					fontSize: imgHeight === paddingY ? "9px" : "10px",
					fontWeight: "500",
				}}
			>
				X indicator {focused.clearspace}%
			</text>
		</svg>
	)
}

interface clearspaceProps {
	item: brandguide.api.LogoObject
	imageSize: number
}

export default function ClearspaseElement(props: clearspaceProps): JSX.Element {
	const { item, imageSize } = props

	return (
		<layouts.Flex flex="1" alignItems="center" justifyContent="center">
			<ClearspaceGrid
				focused={item}
				imgHeight={imageSize}
				imgWidth={imageSize}
				pathbbox={{ height: imageSize, width: imageSize, y: 0 } as DOMRect}
			/>
		</layouts.Flex>
	)
}

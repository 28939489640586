import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as color_components from "./components"

const max_columns_cnt = 6

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const correct = guide.guide?.colors?.correct_color_contrasts || []
	const incorrect = guide.guide?.colors?.incorrect_color_contrasts || []

	const c_rows = Math.ceil(correct.length / max_columns_cnt)
	const c_columns = correct.length >= max_columns_cnt ? max_columns_cnt : correct.length

	const inc_rows = Math.ceil(incorrect.length / max_columns_cnt)
	const inc_columns = incorrect.length >= max_columns_cnt ? max_columns_cnt : incorrect.length

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Color Palette</result_components.NavTitle>
				<result_components.NavDescription>
					Color is a valuable asset for the brand identity. It helps us gain recognition through consistent use of
					color.
				</result_components.NavDescription>
				<result_components.NavTitle>Usage</result_components.NavTitle>
				<result_components.NavDescription>
					This is not an exhaustive set of color do&apos;s and don&apos;ts. The most important takeaway is legibility.
				</result_components.NavDescription>
				<result_components.Explanation desc={guide.guide?.colors?.descriptions?.correct_color_contrasts} />
				<result_components.Explanation desc={guide.guide?.colors?.descriptions?.incorrect_color_contrasts} />
			</result_components.LeftSideNav>

			<result_components.MainContainer>
				<result_components.ContentContainer>
					<layouts.Flex>
						<typography.h4 color={colors.grey.dark50} lineHeight="50px">
							Correct Color Usage
						</typography.h4>
					</layouts.Flex>
					<layouts.containers.grid
						gap="10px"
						gridTemplateRows={`repeat(${c_rows}, 1fr)`}
						gridTemplateColumns={`repeat(${c_columns}, 1fr)`}
						flex="1"
					>
						{correct.map((i, index) => (
							<color_components.UsageCard key={index} item={i} correct />
						))}
					</layouts.containers.grid>

					<layouts.Flex>
						<typography.h4 color={colors.grey.dark50} lineHeight="50px">
							Incorrect Color Usage
						</typography.h4>
					</layouts.Flex>
					<layouts.containers.grid
						gap="10px"
						gridTemplateRows={`repeat(${inc_rows}, 1fr)`}
						gridTemplateColumns={`repeat(${inc_columns}, 1fr)`}
						flex="1"
					>
						{incorrect.map((i, index) => (
							<color_components.UsageCard key={index} item={i} />
						))}
					</layouts.containers.grid>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

import { SectionTitle } from "brandguide/results/components"
import { useState, useEffect } from "react"
import { default as Main } from "./main"

export { default as Main } from "./main"

interface props {
	onLoad(): void
}

export function Section(props: props): JSX.Element {
	const { onLoad } = props

	const [loadedSections, setLoadedSections] = useState(1)

	useEffect(() => {
		if (loadedSections > 0) return
		onLoad()
	}, [loadedSections])

	return (
		<>
			<SectionTitle title="Application" className="section-print" />
			<Main onLoad={() => setLoadedSections((prev) => prev - 1)} />
		</>
	)
}

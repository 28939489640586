import React, { useContext, useState } from "react"
import * as layouts from "layouts"
import * as icons from "icons"
import { DateTime } from "luxon"
import * as api from "./api"
import * as authzc from "authzcached"
import * as typography from "typography"
import TokenDisplay from "./credentials.token.display"
import styled from "@emotion/styled"

export const StyledInput = styled.input`
	border: none;
	min-width: 100px;
	outline: none;
	width: 100%;
	min-width: 100px;
	border-radius: 23px;
	border: ${layouts.theme.borders.grey.dark50alphamedium1px};
	height: 38px;
	padding-right: 10px;
	padding-left: 10px;
`

function AccordionHead(
	props: React.PropsWithChildren<
		{ description: string; enabled: string; created: string } & layouts.accordions.props & layouts.containers.FlexProps
	>,
): JSX.Element {
	const { open, toggle } = useContext(layouts.accordions.AccordionContext)
	const { description, enabled, created, children, ...rest } = { ...props }
	return (
		<>
			<layouts.containers.flex
				flexDirection="row"
				fontSize="14px"
				lineHeight="20px"
				color={layouts.theme.colors.grey.dark50}
				{...rest}
			>
				<layouts.containers.flex flex="3" p="10px">
					{description}
				</layouts.containers.flex>

				<layouts.containers.flex flex="1" p="10px" justifyContent="center">
					{DateTime.fromISO(enabled).toLocaleString(DateTime.DATE_MED)}
				</layouts.containers.flex>

				<layouts.containers.flex flex="1" p="10px" justifyContent="center">
					{DateTime.fromISO(created).toLocaleString(DateTime.DATE_MED)}
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" p="10px" justifyContent="center" onClick={() => toggle(!open)}>
					{open ? <icons.accordions.Opened /> : <icons.accordions.Closed />}
				</layouts.containers.flex>
			</layouts.containers.flex>
			{children}
		</>
	)
}

export function AccordionContainer(
	props: React.PropsWithChildren<{ credential?: api.Credential; token?: api.Token } & layouts.containers.FlexProps>,
): JSX.Element {
	const { credential, token, children, ...rest } = props

	return (
		<layouts.accordions.Container
			minHeight="unset"
			padding="unset"
			flexDirection="column"
			borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
			m="unset"
			{...rest}
		>
			<layouts.containers.box
				tabIndex={0}
				className="accordion-containers-box"
				background={layouts.theme.colors.grey.bg}
			>
				{(credential || token) && (
					<AccordionHead
						description={credential?.description || token!.description}
						enabled={credential?.updated_at || token!.expires_at}
						created={credential?.created_at || token!.created_at}
					>
						<layouts.accordions.Content>
							<layouts.containers.flex flexDirection="column" width="100%" flex="1">
								{children}
							</layouts.containers.flex>
						</layouts.accordions.Content>
					</AccordionHead>
				)}
			</layouts.containers.box>
		</layouts.accordions.Container>
	)
}

export function Row(props: { title: string; value: string; cancopy: boolean }): JSX.Element {
	const { title, value, cancopy } = props

	const [isCopied, setIsCopied] = useState(false)
	const handleCopyClick = () => {
		const textblob = new Blob([value], { type: "text/plain" })
		// eslint-disable-next-line no-undef
		const data = [new ClipboardItem({ [textblob.type]: textblob })]
		navigator.clipboard.write(data).then(() => {
			setIsCopied(true)
			setTimeout(() => {
				setIsCopied(false)
			}, 2000)
		})
	}

	return (
		<layouts.containers.flex flexDirection="row" width="100%" py="5px">
			<layouts.containers.flex minWidth="100px">{title}:</layouts.containers.flex>
			<layouts.containers.flex
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alphamedium1px}
				flex="1"
				fontWeight="500"
				px="10px"
				justifyContent="space-between"
			>
				{value}
				{cancopy && (
					<layouts.containers.flex title="Copy to clipboard" onClick={handleCopyClick}>
						{!isCopied ? <icons.Clipboard mt="2px" /> : <layouts.containers.span>Copied!</layouts.containers.span>}
					</layouts.containers.flex>
				)}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Row.defaultProps = {
	cancopy: false,
}

export function CredentialActions(props: {
	item: api.Credential
	onDelete(r: api.DisableResponse): void
}): JSX.Element {
	const { item, onDelete } = props
	const mtoggle = layouts.modals.useToggle()
	const [loading, setLoading] = useState(false)
	const [metaauthz] = useState(authzc.useCache((cached) => cached.meta))
	const [isCopied, setIsCopied] = useState(false)
	const handleCopyClick = () => {
		const content = JSON.stringify(item, undefined, 2)
		const textblob = new Blob([content], { type: "text/plain" })
		// eslint-disable-next-line no-undef
		const data = [new ClipboardItem({ [textblob.type]: textblob })]
		navigator.clipboard.write(data).then(() => {
			setIsCopied(true)
			setTimeout(() => {
				setIsCopied(false)
			}, 2000)
		})
	}

	function handleRemove() {
		api.credentials.disable(item.id, authzc.bearer(metaauthz)).then(onDelete).catch(console.error)
	}

	return (
		<layouts.containers.flex flexDirection="row" justifyContent="flex-end" width="100%" gridGap="10px" my="10px">
			<layouts.buttons.outline
				disabled={loading}
				borderRadius="33px"
				minWidth="85px"
				onClick={() => {
					setLoading(true)
					api.credentials
						.accessToken(authzc.bearer(metaauthz))
						.then((r) => {
							mtoggle(
								<layouts.containers.box
									styled
									width="100%"
									p="50px"
									pt="25px"
									background={layouts.theme.colors.grey.bg}
								>
									<layouts.containers.flex className="oauth2.token.display" flexDirection="column">
										<layouts.forms.Group flex="1" gridGap="10px">
											<layouts.forms.Label width="30ch">Access token</layouts.forms.Label>
											<layouts.containers.span style={{ overflowWrap: "anywhere" }} ml="auto">
												{r.access_token}
											</layouts.containers.span>
										</layouts.forms.Group>
									</layouts.containers.flex>
									<layouts.containers.flex justifyContent="center" pt="50px">
										<layouts.buttons.outline
											m="10px"
											width="220px"
											height="57px"
											paddingRight="20px"
											borderRadius="37px"
											onClick={() => {
												const textblob = new Blob(
													[JSON.stringify({ access_token: r.access_token, expires_at: r.expires_at })],
													{
														type: "text/plain",
													},
												)
												// eslint-disable-next-line no-undef
												const data = [new ClipboardItem({ [textblob.type]: textblob })]
												navigator.clipboard
													.write(data)
													.then(() => {
														mtoggle(undefined)
													})
													.catch((cause) => {
														console.error("unable to copy token", cause)
														alert("unable to copy")
													})
											}}
										>
											Copy
										</layouts.buttons.outline>
										<layouts.buttons.outline
											m="10px"
											width="220px"
											height="57px"
											borderRadius="37px"
											onClick={() => mtoggle(undefined)}
										>
											Cancel
										</layouts.buttons.outline>
									</layouts.containers.flex>
								</layouts.containers.box>,
							)
							setLoading(false)
						})
						.catch((cause) => {
							console.error(cause)
							setLoading(false)
						})
				}}
			>
				Generate Test Access Token
			</layouts.buttons.outline>
			<layouts.buttons.outline
				disabled={loading}
				borderRadius="33px"
				minWidth="85px"
				onClick={() => {
					setLoading(true)
					api.credentials
						.refreshToken(item.id, authzc.bearer(metaauthz))
						.then((r) => {
							const c = { ...api.credentials.zero(r.credential), secret: r.secret }
							const t = { ...api.tokens.zero(r.token), refresh_token: r.refresh }
							mtoggle(
								<layouts.containers.box
									styled
									width="100%"
									p="50px"
									pt="25px"
									background={layouts.theme.colors.grey.bg}
								>
									<typography.h3 color={layouts.theme.colors.grey.medium} pb="50px" textAlign="left">
										Test Token
									</typography.h3>
									<TokenDisplay cred={c} token={t} />
									<layouts.containers.flex justifyContent="center" pt="50px">
										<layouts.buttons.outline
											m="10px"
											width="220px"
											height="57px"
											paddingRight="20px"
											borderRadius="37px"
											onClick={() => {
												const textblob = new Blob(
													[JSON.stringify({ client_id: c.id, client_secret: r.secret, refresh_token: r.refresh })],
													{
														type: "text/plain",
													},
												)
												// eslint-disable-next-line no-undef
												const data = [new ClipboardItem({ [textblob.type]: textblob })]
												navigator.clipboard
													.write(data)
													.then(() => {
														mtoggle(undefined)
													})
													.catch((cause) => {
														console.error("unable to copy", cause)
														alert("unable to copy")
													})
											}}
										>
											Copy
										</layouts.buttons.outline>
										<layouts.buttons.outline
											m="10px"
											width="220px"
											height="57px"
											borderRadius="37px"
											onClick={() => mtoggle(undefined)}
										>
											Cancel
										</layouts.buttons.outline>
									</layouts.containers.flex>
								</layouts.containers.box>,
							)
							setLoading(false)
						})
						.catch((cause) => {
							console.error(cause)
							setLoading(false)
						})
				}}
			>
				Generate Refresh Token
			</layouts.buttons.outline>
			<layouts.buttons.outline disabled={loading} borderRadius="33px" minWidth="85px" onClick={handleRemove}>
				Remove
			</layouts.buttons.outline>
			<layouts.buttons.primary disabled={loading} borderRadius="33px" minWidth="85px" onClick={handleCopyClick}>
				{isCopied ? "Copied!" : "Copy All"}
			</layouts.buttons.primary>
		</layouts.containers.flex>
	)
}

export function TokenActions(props: { item: api.Token; onDelete(r: api.TokenDisableResponse): void }): JSX.Element {
	const { item, onDelete } = props
	const [metaauthz] = useState(authzc.useCache((cached) => cached.meta))

	function handleRemove() {
		api.tokens
			.disable(item.id, authzc.bearer(metaauthz))
			.then(onDelete)
			.catch((cause) => console.error("unable to handleRemove", cause))
	}

	return (
		<layouts.containers.flex flexDirection="row" justifyContent="flex-end" width="100%" gridGap="10px" my="10px">
			<layouts.buttons.outline borderRadius="33px" onClick={handleRemove} minWidth="85px">
				Remove
			</layouts.buttons.outline>
			<layouts.buttons.primary borderRadius="33px" onClick={() => console.log("Update Click")} minWidth="85px">
				Update
			</layouts.buttons.primary>
		</layouts.containers.flex>
	)
}

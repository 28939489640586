import * as layouts from "layouts"
import * as routing from "brandguard/routing"
import * as bgscores from "brandguard/display/scores"
import * as scoring from "./scoring"

export default function Scoring(): JSX.Element {
	return (
		<bgscores.Container urlgen={routing.text.score}>
			<layouts.containers.flex flexDirection="column" height="100%">
				<scoring.Navigation />
				<scoring.Display />
			</layouts.containers.flex>
		</bgscores.Container>
	)
}

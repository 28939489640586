import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as profiles from "profiles"
import { DateTime } from "luxon"
import * as typography from "typography"
import * as authzc from "authzcached"
import * as httpx from "httpx"
import * as errors from "errors"
import * as um from "meta/usermanagement"
import * as timex from "timex"

export default function Pending(props: { req: profiles.api.proto.SearchRequest }): JSX.Element {
	const { req } = props
	const mtoggle = layouts.modals.useToggle()
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [searchReq, setSearchReq] = useState(req)
	const [pset, setProfiles] = useState<profiles.api.proto.SearchResponse>({
		items: [],
		next: searchReq,
	})
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)

	useEffect(() => {
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => profiles.api.search(searchReq, authzc.bearer(metaauthz)))
			.then(setProfiles)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				console.warn(cause)
				setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
			})
		return p.cancel
	}, [searchReq])

	if (cause) return <errors.overlay styled cause={cause} flex="1" />
	if (pset.items.length === 0) return <></>

	return (
		<layouts.containers.flex
			flexDirection="row"
			flex="1"
			background={layouts.theme.colors.white}
			mb="20px"
			overflow="hidden"
			data-testid="usermanagement-pending"
		>
			<layouts.containers.flex flexDirection="column" flex="2" px="40px" pt="15px" pb="50px" minWidth="150px">
				<typography.h3
					fontSize="16px"
					fontWeight="700"
					lineHeight="30px"
					color={layouts.theme.colors.grey.dark50}
					mb="5px"
				>
					Pending Invites
				</typography.h3>
				<typography.h6 fontSize="12px" fontWeight="400" lineHeight="20px" color={layouts.theme.colors.grey.dark50}>
					Current list of invites that are pending.
				</typography.h6>
			</layouts.containers.flex>
			<layouts.containers.flex flex="7">
				<layouts.containers.flex px="10px" flex="1" flexDirection="column">
					<layouts.containers.flex
						flexDirection="column"
						flex="1"
						background={layouts.theme.colors.grey.bg}
						my="20px"
						overflowY="auto"
					>
						<layouts.containers.flex
							flexDirection="row"
							fontWeight="700"
							fontSize="16px"
							lineHeight="30px"
							justifyContent="center"
							borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
							mx="5px"
						>
							<layouts.containers.flex flex="3" p="10px">
								Users
							</layouts.containers.flex>
							<layouts.containers.flex flex="1" p="10px" justifyContent="center">
								<layouts.Flex>
									Date Sent
									<um.sorting.default
										sort_by={profiles.api.proto.Sorting.CREATED_AT}
										req={searchReq}
										onChange={() => setSearchReq(searchReq)}
									/>
								</layouts.Flex>
							</layouts.containers.flex>
							<layouts.containers.flex flex="1" p="10px"></layouts.containers.flex>
							<layouts.containers.flex flex="1" p="10px"></layouts.containers.flex>
						</layouts.containers.flex>
						{pset.items.map((profile, i) => {
							return (
								<React.Fragment key={i}>
									<layouts.containers.flex
										flexDirection="row"
										fontSize="14px"
										lineHeight="20px"
										color={layouts.theme.colors.grey.dark50}
										borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
									>
										<layouts.containers.flex flex="3" p="10px" flexDirection="column">
											{profile.display}
											<layouts.Flex color={layouts.theme.colors.grey.dark50alpha40}>{profile.email}</layouts.Flex>
										</layouts.containers.flex>
										<layouts.containers.flex flex="1" p="10px" justifyContent="center" alignItems="center">
											{DateTime.fromISO(profile.created_at).toLocaleString(DateTime.DATE_MED)}
										</layouts.containers.flex>
										<layouts.containers.flex flex="1" p="10px" justifyContent="center" alignItems="center">
											<layouts.buttons.primary borderRadius="100px" width="120px" height="35px">
												Resend Invite
											</layouts.buttons.primary>
										</layouts.containers.flex>
										<layouts.containers.flex flex="1" p="10px" justifyContent="center" alignItems="center">
											<layouts.buttons.outline
												borderRadius="100px"
												width="120px"
												height="35px"
												onClick={() =>
													mtoggle(
														<um.layouts.ModalContainer outerBackground={layouts.theme.backgrounds.blackalpha75}>
															<um.layouts.PendingRevokeModal
																dismiss={() => mtoggle(undefined)}
																accept={() => {
																	mtoggle(undefined)
																	profiles.api
																		.update(
																			{
																				profile: {
																					...profile,
																					disabled_manually_at: timex.utc().toISO(),
																				},
																			},
																			authzc.bearer(metaauthz),
																		)
																		.then((r) => {
																			setProfiles((prevState) => ({
																				...prevState,
																				items: pset.items.filter((p) => p.id !== r.profile?.id),
																			}))
																		})
																}}
															/>
															,
														</um.layouts.ModalContainer>,
													)
												}
											>
												Revoke Invite
											</layouts.buttons.outline>
										</layouts.containers.flex>
									</layouts.containers.flex>
								</React.Fragment>
							)
						})}
						<layouts.pagination.Cursor
							mx="auto"
							mt="auto"
							py="5px"
							pr="10px"
							current={searchReq.offset}
							advance={Number(pset.next!.offset) === -1 ? undefined : pset.next?.offset}
							onChange={(next) => {
								setSearchReq({
									...searchReq,
									offset: next,
								})
							}}
						/>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

import React from "react"

export default function Protected(
	props: React.PropsWithChildren<{ enabled: boolean; rejected: JSX.Element }>,
): JSX.Element {
	const { enabled, rejected, children } = props

	if (!enabled) return rejected

	return <>{children}</>
}

Protected.defaultProps = {
	rejected: <></>,
}

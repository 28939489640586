import React, { useState } from "react"
import * as layouts from "layouts"
import * as tone_of_voices from "."
import * as brandguide from "brandguide"
import * as md5x from "md5x"

export const Steps = {
	TONE_AND_VOICE: 0,
	ADJECTIVES: 1,
	WRITING_STYLE: 2,
	RESULT: 3,
}

export function Container(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()

	const _tav = brandguide.api.zeros.guide.guideToneOfVoiceZero(cache.guide.guide?.tone_of_voice)

	const curstep = () => {
		switch (false) {
			case brandguide.validations.tone_of_voice.valid.tone_of_voice(_tav):
				return Steps.TONE_AND_VOICE
			case brandguide.validations.tone_of_voice.valid.adjectives(_tav):
				return Steps.ADJECTIVES
			case brandguide.validations.tone_of_voice.valid.writing_style(_tav):
				return Steps.WRITING_STYLE
			default:
				return Steps.RESULT
		}
	}

	const [step, setStep] = useState(curstep)
	const [loading, setLoading] = useState(false)

	const handleOnStep = (step: number, data: brandguide.api.GuideToneOfVoice | undefined) => {
		if (!data) {
			setStep(step)
			return
		}

		const guide = { ...cache.guide.guide, tone_of_voice: data }
		const md5 = md5x.string(JSON.stringify(guide))

		if (md5 === cache.md5) {
			setStep(step)
			return
		}

		setLoading(true)
		cache.store({ ...cache, guide: { ...cache.guide, guide: guide } }).finally(() => {
			setStep(step)
			setLoading(false)
		})
	}

	return (
		<layouts.Flex overflowY="auto" {...rest}>
			<layouts.loading.screen flex="1" loading={loading}>
				{step === Steps.TONE_AND_VOICE && <tone_of_voices.ToneOfVoice onChange={handleOnStep} />}
				{step === Steps.ADJECTIVES && <tone_of_voices.Adjectives onChange={handleOnStep} />}
				{step === Steps.WRITING_STYLE && <tone_of_voices.WritingStyle onChange={handleOnStep} />}
				{step === Steps.RESULT && <tone_of_voices.Result onChange={handleOnStep} />}
			</layouts.loading.screen>
		</layouts.Flex>
	)
}

Container.defaultProps = {
	flexDirection: "column",
	flex: "1",
	mb: "25px",
}

export default Container

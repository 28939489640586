import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as errors from "errors"
import * as brands from "brands"
import * as authzc from "authzcached"
import * as httpx from "httpx"
import * as api from "brandguard/api"
import * as training from "brandguard/display/training"

export default function Kernels(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { ...rest } = props
	const brand = brands.caching.useCached()
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [loading, setLoading] = useState(false)
	const [req, setReq] = useState(api.text.kernel.searches.request({ brand_id: brand.id, limit: 10n }))
	const [kernels, setKernels] = useState(api.text.kernel.searches.response())

	useEffect(() => {
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.text.kernel.search(brand.id, req, authzc.bearer(authzaccount)))
			.then((kernels) => {
				setKernels(kernels)
				setLoading(false)
			})
			.catch((cause) => {
				setLoading(false)
				setCause(
					<errors.Textual cause={cause} logging={console.error}>
						Unable to load kernels
					</errors.Textual>,
				)
			})
		return p.cancel
	}, [req])

	return (
		<layouts.Flex flexDirection="column" flex="1" {...rest}>
			<layouts.tables.container overflow="auto" flex="1">
				<errors.overlay styled cause={cause}>
					<layouts.loading.pending loading={loading}>
						<table style={{ color: layouts.theme.colors.grey.dark20 }}>
							<thead>
								<tr>
									<training.layouts.TableHead>Version</training.layouts.TableHead>
									<training.layouts.TableHead>Status</training.layouts.TableHead>
									<training.layouts.TableHead>Updated At</training.layouts.TableHead>
									<training.layouts.TableHead>Created At</training.layouts.TableHead>
								</tr>
							</thead>
							<tbody>
								{kernels.items.map((c) => (
									<React.Fragment key={c.version}>
										<training.layouts.TableRow>
											<training.layouts.TableData>{c.version}</training.layouts.TableData>
											<training.layouts.TableData>{api.Status[c.status]}</training.layouts.TableData>
											<training.layouts.TableData>{layouts.tables.Timestamp(c.updated_at)}</training.layouts.TableData>
											<training.layouts.TableData>{layouts.tables.Timestamp(c.created_at)}</training.layouts.TableData>
										</training.layouts.TableRow>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</layouts.loading.pending>
				</errors.overlay>
			</layouts.tables.container>
			<layouts.pagination.Cursor
				m="auto"
				current={req.offset}
				advance={Number(kernels.next!.offset) === -1 ? undefined : kernels.next?.offset}
				onChange={(next) => {
					setReq({
						...req,
						offset: next,
					})
				}}
			/>
		</layouts.Flex>
	)
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as domains from "sso/domains"
import * as authzc from "authzcached"
import * as httpx from "httpx"
import * as errors from "errors"

export default function Domains(props: { req: domains.api.proto.SearchRequest }): JSX.Element {
	const { req } = props
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [searchReq, setSearchReq] = useState(req)
	const [doms, setDoms] = useState<domains.api.proto.SearchResponse>({
		items: [],
		next: searchReq,
	})
	const [cause, setCause] = useState(undefined as undefined | JSX.Element)

	useEffect(() => {
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => domains.api.search(searchReq, authzc.bearer(metaauthz)))
			.then(setDoms)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => {
				console.warn(cause)
				setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
			})
		return p.cancel
	}, [searchReq])

	if (cause) return <errors.overlay styled cause={cause} flex="1" />
	if ((doms.items?.length || 0) === 0) return <></>

	return (
		<layouts.containers.flex
			flexDirection="column"
			flex="2"
			background={layouts.theme.colors.grey.bg}
			my="20px"
			overflowY="auto"
		>
			<layouts.containers.flex
				flexDirection="row"
				fontWeight="700"
				fontSize="16px"
				lineHeight="30px"
				justifyContent="center"
				borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
				mx="5px"
			>
				<layouts.containers.flex flex="3" p="10px">
					Access Links
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" p="10px" justifyContent="center">
					<layouts.Flex>
						Created
						<domains.sorting.default
							sort_by={domains.api.proto.Sorting.CREATED_AT}
							req={searchReq}
							onChange={setSearchReq}
						/>
					</layouts.Flex>
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" p="10px" justifyContent="center">
					<layouts.Flex>
						Updated
						<domains.sorting.default
							sort_by={domains.api.proto.Sorting.UPDATED_AT}
							req={searchReq}
							onChange={setSearchReq}
						/>
					</layouts.Flex>
				</layouts.containers.flex>
				<layouts.containers.flex flex="1" p="10px"></layouts.containers.flex>
			</layouts.containers.flex>
			{doms.items.map((d, i) => {
				return (
					<domains.layouts.AccordionDomainContainer key={d.id} domain={d}>
						<layouts.containers.flex
							flexDirection="column"
							px="50px"
							py="10px"
							alignItems="flex-start"
							fontSize="14px"
							lineHeight="20px"
							color={layouts.theme.colors.grey.dark50}
						>
							<domains.layouts.EditDomain
								current={d}
								onChange={async (upd) => {
									const retry = httpx.autoretry()
									return retry
										.wrap(() =>
											domains.api.update(
												{
													domain: upd,
												},
												authzc.bearer(metaauthz),
											),
										)
										.then((r) => {
											const updated = r.domain || upd
											setDoms((prevState) => ({
												...prevState,
												items: doms.items.map((e) => (e.id === upd.id ? updated : e)),
											}))
											return updated
										})
								}}
								onRemove={() =>
									domains.api.destroy(d.id, authzc.bearer(metaauthz)).then(() => {
										setDoms((prevState) => ({
											...prevState,
											items: doms.items.filter((c) => c.id !== d.id),
										}))
									})
								}
							/>
						</layouts.containers.flex>
					</domains.layouts.AccordionDomainContainer>
				)
			})}
			<layouts.pagination.Cursor
				mx="auto"
				mt="auto"
				py="5px"
				pr="10px"
				current={searchReq.offset}
				advance={Number(doms.next!.offset) === -1 ? undefined : doms.next?.offset}
				onChange={(next) => {
					setSearchReq({
						...searchReq,
						offset: next,
					})
				}}
			/>
		</layouts.containers.flex>
	)
}

import qs from "qs"
import _JSONBig from "json-bigint"
import { CancellablePromise } from "real-cancellable-promise"
import * as retries from "retries"
import * as timex from "timex"
export { default as qs } from "query-string"

const JSONBig = _JSONBig({ useNativeBigInt: true })

// localfetch is used to wrap fetch
let localfetch = fetch as fetcher

interface xhrfields {
	withCredentials: boolean
}

export interface Request {
	method: string
	mode: string
	headers: { [key: string]: string }
	credentials: string
	xhrFields: xhrfields
	data: string | Record<string, unknown>
	body: unknown
	processData: boolean
	dataType: string
	signal: AbortSignal | undefined
}

export interface option {
	(request: Request): Promise<Request>
}

export const status = {
	BadRequest: 400,
	Unauthorized: 401,
	Forbidden: 403,
	NotFound: 404,
	EntityTooLarge: 413,
	TooManyRequests: 429,
	BadGateway: 502,
	ServiceUnavailable: 503,
	StatusNotAcceptable: 406,
}

export const accept = {
	json: (request: Request): Promise<Request> => {
		request.headers["Accept"] = "application/json; charset=utf-8"
		return Promise.resolve(request)
	},
}

export const content = {
	json: (request: Request): Promise<Request> => {
		request.headers["Content-Type"] = "application/json"
		return Promise.resolve(request)
	},
	urlencoded: (request: Request): Promise<Request> => {
		request.headers["Content-Type"] = "application/x-www-form-urlencoded"
		return Promise.resolve(request)
	},
	formdata: (request: Request): Promise<Request> => {
		request.headers["Content-Type"] = "multipart/form-data"
		return Promise.resolve(request)
	},
}

export const methods = {
	patch: (request: Request): Promise<Request> => {
		request.method = "PATCH"
		return Promise.resolve(request)
	},
	put: (request: Request): Promise<Request> => {
		request.method = "PUT"
		return Promise.resolve(request)
	},
	post: (request: Request): Promise<Request> => {
		request.method = "POST"
		return Promise.resolve(request)
	},
	get: (request: Request): Promise<Request> => {
		request.method = "GET"
		return Promise.resolve(request)
	},
	delete: (request: Request): Promise<Request> => {
		request.method = "DELETE"
		return Promise.resolve(request)
	},
}

export const modes = {
	cors: (request: Request): Promise<Request> => {
		request.mode = "cors"
		return Promise.resolve(request)
	},
}

export const options = {
	noop(request: Request): Promise<Request> {
		return Promise.resolve(request)
	},
	bearer(token: string): option {
		return (request: Request): Promise<Request> => {
			if (token) {
				request.headers["Authorization"] = `Bearer ${token}`
			}
			return Promise.resolve(request)
		}
	},
	maybeBearer(token: Promise<string>): option {
		return async (request: Request): Promise<Request> => {
			return token.then((b) => {
				if (b) {
					request.headers["Authorization"] = `BEARER ${b}`
				}
				return Promise.resolve(request)
			})
		}
	},
}
export function optionHeaders(headers: { [key: string]: string }) {
	return (request: Request): void => {
		request.headers = {
			...(request.headers || {}),
			...headers,
		}
	}
}

export function optionXHRFields(options: Partial<xhrfields>) {
	return (request: Request): void => {
		request.xhrFields = {
			...request.xhrFields,
			...options,
		}
	}
}

export function optionDataType(s: string) {
	return (request: Request): void => {
		request.dataType = s
	}
}

export function optionCORS(mode: string) {
	return (request: Request): void => {
		request.mode = mode
	}
}

export namespace requests {
	export function zero(d: Partial<Request> = {}): Request {
		return {
			headers: {},
			...d,
		} as Request
	}
}

export function get<T>(path: string, data = {}, ...options: option[]): CancellablePromise<T> {
	return request<T>(path, data, methods.get, content.urlencoded, ...options)
}

export function post<T>(path: string, data = {}, ...options: option[]): CancellablePromise<T> {
	return request<T>(path, data, methods.post, content.json, ...options)
}

export function put<T>(path: string, data = {}, ...options: option[]): CancellablePromise<T> {
	return request<T>(path, data, methods.put, content.json, ...options)
}

export function patch<T>(path: string, data = {}, ...options: option[]): CancellablePromise<T> {
	return request<T>(path, data, methods.patch, content.json, ...options)
}

export function destroy<T>(path: string, data = {}, ...options: option[]): CancellablePromise<T> {
	return request<T>(path, data, methods.delete, ...options)
}

export function checkerror(onErr: (error: { status: number }) => boolean): retries.retryable {
	return (cause: unknown): boolean => {
		try {
			return onErr(cause as { status: number })
		} catch {
			return false
		}
	}
}

export function autoretry(...opts: retries.option[]): retries.Retry {
	return retries.zero(
		retries.options.backoff(retries.backoffs.constant(timex.duration.seconds(1))),
		retries.options.errors(
			checkerror(errors.ratelimited((cause) => true)),
			checkerror(errors.badgateway((cause) => true)),
		),
		...opts,
	)
}

// generic ajax requests
export function request<T>(path: string, data = {}, ...options: option[]): CancellablePromise<T> {
	const abortable = new AbortController()
	const request = options.reduce(
		async (p, opt) => {
			return p.then((r) => opt(r))
		},
		Promise.resolve(
			requests.zero({
				signal: abortable.signal,
			}),
		),
	)

	return new CancellablePromise<T>(
		request.then(async (r) => {
			switch (r.headers["Content-Type"]) {
				case "application/x-www-form-urlencoded":
					path = `${path}?${qs.stringify(data)}`
					break
				case "application/json":
					r.body = JSONBig.stringify(data)
					break
				case "multipart/form-data":
					delete r.headers["Content-Type"]
					r.body = data
					break
				default:
					r.data = data
			}

			// eslint-disable-next-line no-undef
			return localfetch(path, r as RequestInit).then((response: { ok: boolean; json: () => Promise<T> }) => {
				if (response.ok) {
					return response.json()
				}
				throw response
			})
		}),
		(reason) => {
			abortable.abort(reason)
		},
	)
}
// escape hatch to native fetch.
export function _fetchv2(path: string, r: Request, ...options: option[]): CancellablePromise<Response> {
	const abortable = new AbortController()
	const request = options.reduce(
		async (p, opt) => {
			return p.then((r) => opt(r))
		},
		Promise.resolve(
			requests.zero({
				...r,
				signal: abortable.signal,
			}),
		),
	)

	return new CancellablePromise<Response>(
		request.then(async (r) => {
			// eslint-disable-next-line no-undef
			return localfetch(path, r as RequestInit).then((response: Response) => {
				if (response.ok) {
					return response
				}
				throw response
			})
		}),
		(reason) => {
			abortable.abort(reason)
		},
	)
}

// escape hatch to native fetch.
export async function _fetch(path: string, ...options: option[]): Promise<Response> {
	const request = options.reduce(async (p, opt) => {
		return p.then((r) => opt(r))
	}, Promise.resolve(requests.zero()))

	return request.then(async (r) => {
		// eslint-disable-next-line no-undef
		return localfetch(path, r as RequestInit).then((response: Response) => {
			if (response.ok) {
				return response
			}
			throw response
		})
	})
}

export interface middleware {
	// eslint-disable-next-line no-undef
	(original: fetcher): fetcher
}

interface fetcher {
	// eslint-disable-next-line no-undef
	(input: RequestInfo, init?: RequestInit): Promise<Response>
}

// eslint-disable-next-line no-undef
export function debugrequest(original: fetcher): fetcher {
	let reqcount = -1
	// eslint-disable-next-line no-undef
	return async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
		reqcount += 1
		console.log(`request initiated #${reqcount}`, input, init)
		return original(input, init).finally(() => console.log(`request completed #${reqcount}`, input, init))
	}
}

export function unauthenticatedrequest(error: (cause: unknown) => Response): middleware {
	// eslint-disable-next-line no-undef
	return (original: fetcher): fetcher => {
		// eslint-disable-next-line no-undef
		return (input: RequestInfo, init?: RequestInit): Promise<Response> => {
			return original(input, init).catch(errors.authorization(error))
		}
	}
}

/**
 * global intercept. *not* for general usage.
 * valid use cases: app wide request handling - unauthenticated. logging
 * @param middlewares a set of intercepters to apply
 */
export function intercept(...middlewares: middleware[]): void {
	localfetch = middlewares.reduce((handle, m) => m(handle), localfetch)
}

// upload file data to the given destination.
export function upload<T>(path: string, data: FormData, ...options: option[]): CancellablePromise<T> {
	options = [methods.post, content.formdata].concat(...options)

	return request(path, data, ...options)
}

export const errors = {
	notFound<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }): T => {
			if (error.status !== status.NotFound) {
				throw error
			}
			return onErr(error)
		}
	},
	notAcceptable<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }): T => {
			if (error.status !== status.StatusNotAcceptable) {
				throw error
			}
			return onErr(error)
		}
	},
	authorization<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }): T => {
			if (error.status !== status.Unauthorized) {
				throw error
			}
			return onErr(error)
		}
	},
	forbidden<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.Forbidden) {
				throw error
			}
			return onErr(error)
		}
	},
	unavailable<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.ServiceUnavailable) {
				throw error
			}
			return onErr(error)
		}
	},
	badgateway<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.BadGateway) {
				throw error
			}
			return onErr(error)
		}
	},
	ratelimited<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.TooManyRequests) {
				throw error
			}
			return onErr(error)
		}
	},
	entitytoolarge<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.EntityTooLarge) {
				throw error
			}
			return onErr(error)
		}
	},
	badrequest<T>(onErr: (error: { status: number }) => T) {
		return (error: { status: number }) => {
			if (error.status !== status.BadRequest) {
				throw error
			}
			return onErr(error)
		}
	},
	cancellation<T>(onErr: (error: { name: string }) => T) {
		return (error: { name: string }) => {
			if (error.name !== "AbortError") {
				throw error
			}
			return onErr(error)
		}
	},
}

export * as urlstorage from "./urlstorage"
export { Client } from "./client"

/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguard.proto" (package "brandguard", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.EventViolation
 */
export interface EventViolation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: string explanation = 7;
     */
    explanation: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.EventViolationSearchRequest
 */
export interface EventViolationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 5;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.EventViolationSearchResponse
 */
export interface EventViolationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.EventViolation items = 1;
     */
    items: EventViolation[];
    /**
     * @generated from protobuf field: brandguard.EventViolationSearchRequest next = 2;
     */
    next?: EventViolationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.BackgroundColorExplanation
 */
export interface BackgroundColorExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: string explanation = 7;
     */
    explanation: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.BackgroundColorExplanationSearchRequest
 */
export interface BackgroundColorExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.BackgroundColorExplanationSearchResponse
 */
export interface BackgroundColorExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.BackgroundColorExplanation items = 1;
     */
    items: BackgroundColorExplanation[];
    /**
     * @generated from protobuf field: brandguard.BackgroundColorExplanationSearchRequest next = 2;
     */
    next?: BackgroundColorExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.BackgroundColorExplanationMaskURL
 */
export interface BackgroundColorExplanationMaskURL {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ViolenceExplanation
 */
export interface ViolenceExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ViolenceExplanationSearchRequest
 */
export interface ViolenceExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ViolenceExplanationSearchResponse
 */
export interface ViolenceExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.ViolenceExplanation items = 1;
     */
    items: ViolenceExplanation[];
    /**
     * @generated from protobuf field: brandguard.ViolenceExplanationSearchRequest next = 2;
     */
    next?: ViolenceExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.NudityExplanation
 */
export interface NudityExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.NudityExplanationSearchRequest
 */
export interface NudityExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.NudityExplanationSearchResponse
 */
export interface NudityExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.NudityExplanation items = 1;
     */
    items: NudityExplanation[];
    /**
     * @generated from protobuf field: brandguard.NudityExplanationSearchRequest next = 2;
     */
    next?: NudityExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ImageConsistencyExplanation
 */
export interface ImageConsistencyExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ImageConsistencyExplanationSearchRequest
 */
export interface ImageConsistencyExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ImageConsistencyExplanationSearchResponse
 */
export interface ImageConsistencyExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.ImageConsistencyExplanation items = 1;
     */
    items: ImageConsistencyExplanation[];
    /**
     * @generated from protobuf field: brandguard.ImageConsistencyExplanationSearchRequest next = 2;
     */
    next?: ImageConsistencyExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.PrecisionExplanation
 */
export interface PrecisionExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated brandguard.Dimension dimensions = 7;
     */
    dimensions: Dimension[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.Dimension
 */
export interface Dimension {
    /**
     * @generated from protobuf field: repeated int64 axes = 1;
     */
    axes: bigint[];
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoClearspaceExplanation
 */
export interface LogoClearspaceExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: string dimensions = 7;
     */
    dimensions: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoClearspaceExplanationSearchRequest
 */
export interface LogoClearspaceExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoClearspaceExplanationSearchResponse
 */
export interface LogoClearspaceExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.PrecisionExplanation items = 1;
     */
    items: PrecisionExplanation[];
    /**
     * @generated from protobuf field: brandguard.LogoClearspaceExplanationSearchRequest next = 2;
     */
    next?: LogoClearspaceExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoDistortionExplanation
 */
export interface LogoDistortionExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: string dimensions = 7;
     */
    dimensions: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoDistortionExplanationSearchRequest
 */
export interface LogoDistortionExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoDistortionExplanationSearchResponse
 */
export interface LogoDistortionExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.PrecisionExplanation items = 1;
     */
    items: PrecisionExplanation[];
    /**
     * @generated from protobuf field: brandguard.LogoDistortionExplanationSearchRequest next = 2;
     */
    next?: LogoDistortionExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoColorExplanation
 */
export interface LogoColorExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: string dimensions = 7;
     */
    dimensions: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoColorExplanationSearchRequest
 */
export interface LogoColorExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.LogoColorExplanationSearchResponse
 */
export interface LogoColorExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.PrecisionExplanation items = 1;
     */
    items: PrecisionExplanation[];
    /**
     * @generated from protobuf field: brandguard.LogoColorExplanationSearchRequest next = 2;
     */
    next?: LogoColorExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.FontDetectionExplanationSearchRequest
 */
export interface FontDetectionExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.FontDetectionExplanationSearchResponse
 */
export interface FontDetectionExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.PrecisionExplanation items = 1;
     */
    items: PrecisionExplanation[];
    /**
     * @generated from protobuf field: brandguard.FontDetectionExplanationSearchRequest next = 2;
     */
    next?: FontDetectionExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.TextReferenceRuleViolation
 */
export interface TextReferenceRuleViolation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: string rule = 6;
     */
    rule: string;
    /**
     * @generated from protobuf field: string explanation = 7;
     */
    explanation: string;
    /**
     * @generated from protobuf field: string created_at = 8 [json_name = "created_at"];
     */
    created_at: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.TextReferenceRuleViolationSearchRequest
 */
export interface TextReferenceRuleViolationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 5;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.TextReferenceRuleViolationSearchResponse
 */
export interface TextReferenceRuleViolationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.TextReferenceRuleViolation items = 1;
     */
    items: TextReferenceRuleViolation[];
    /**
     * @generated from protobuf field: brandguard.TextReferenceRuleViolationSearchRequest next = 2;
     */
    next?: TextReferenceRuleViolationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.TextConsistencyExplanation
 */
export interface TextConsistencyExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.TextConsistencyExplanationSearchRequest
 */
export interface TextConsistencyExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.TextConsistencyExplanationSearchResponse
 */
export interface TextConsistencyExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.TextConsistencyExplanation items = 1;
     */
    items: TextConsistencyExplanation[];
    /**
     * @generated from protobuf field: brandguard.TextConsistencyExplanationSearchRequest next = 2;
     */
    next?: TextConsistencyExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ToneNVoiceExplanation
 */
export interface ToneNVoiceExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ToneNVoiceExplanationSearchRequest
 */
export interface ToneNVoiceExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ToneNVoiceExplanationSearchResponse
 */
export interface ToneNVoiceExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.ToneNVoiceExplanation items = 1;
     */
    items: ToneNVoiceExplanation[];
    /**
     * @generated from protobuf field: brandguard.ToneNVoiceExplanationSearchRequest next = 2;
     */
    next?: ToneNVoiceExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.RacismExplanation
 */
export interface RacismExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.RacismExplanationSearchRequest
 */
export interface RacismExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.RacismExplanationSearchResponse
 */
export interface RacismExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.RacismExplanation items = 1;
     */
    items: RacismExplanation[];
    /**
     * @generated from protobuf field: brandguard.RacismExplanationSearchRequest next = 2;
     */
    next?: RacismExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ProfanityExplanation
 */
export interface ProfanityExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ProfanityExplanationSearchRequest
 */
export interface ProfanityExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.ProfanityExplanationSearchResponse
 */
export interface ProfanityExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.ProfanityExplanation items = 1;
     */
    items: ProfanityExplanation[];
    /**
     * @generated from protobuf field: brandguard.ProfanityExplanationSearchRequest next = 2;
     */
    next?: ProfanityExplanationSearchRequest;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.GrammarExplanation
 */
export interface GrammarExplanation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string event_id = 4 [json_name = "event_id"];
     */
    event_id: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.GrammarExplanationSearchRequest
 */
export interface GrammarExplanationSearchRequest {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: repeated string event_ids = 2 [json_name = "event_ids"];
     */
    event_ids: string[];
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: uint64 limit = 4;
     */
    limit: bigint;
}
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguard.GrammarExplanationSearchResponse
 */
export interface GrammarExplanationSearchResponse {
    /**
     * @generated from protobuf field: repeated brandguard.GrammarExplanation items = 1;
     */
    items: GrammarExplanation[];
    /**
     * @generated from protobuf field: brandguard.GrammarExplanationSearchRequest next = 2;
     */
    next?: GrammarExplanationSearchRequest;
}
// @generated message type with reflection information, may provide speed optimized methods
class EventViolation$Type extends MessageType<EventViolation> {
    constructor() {
        super("brandguard.EventViolation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "explanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventViolation>): EventViolation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "", explanation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventViolation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventViolation): EventViolation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* string explanation */ 7:
                    message.explanation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventViolation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* string explanation = 7; */
        if (message.explanation !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.explanation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.EventViolation
 */
export const EventViolation = new EventViolation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventViolationSearchRequest$Type extends MessageType<EventViolationSearchRequest> {
    constructor() {
        super("brandguard.EventViolationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<EventViolationSearchRequest>): EventViolationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventViolationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventViolationSearchRequest): EventViolationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 5:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventViolationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 5; */
        if (message.limit !== 0n)
            writer.tag(5, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.EventViolationSearchRequest
 */
export const EventViolationSearchRequest = new EventViolationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventViolationSearchResponse$Type extends MessageType<EventViolationSearchResponse> {
    constructor() {
        super("brandguard.EventViolationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventViolation },
            { no: 2, name: "next", kind: "message", T: () => EventViolationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<EventViolationSearchResponse>): EventViolationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventViolationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventViolationSearchResponse): EventViolationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.EventViolation items */ 1:
                    message.items.push(EventViolation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.EventViolationSearchRequest next */ 2:
                    message.next = EventViolationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventViolationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.EventViolation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            EventViolation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.EventViolationSearchRequest next = 2; */
        if (message.next)
            EventViolationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.EventViolationSearchResponse
 */
export const EventViolationSearchResponse = new EventViolationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BackgroundColorExplanation$Type extends MessageType<BackgroundColorExplanation> {
    constructor() {
        super("brandguard.BackgroundColorExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "explanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BackgroundColorExplanation>): BackgroundColorExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "", explanation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BackgroundColorExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BackgroundColorExplanation): BackgroundColorExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* string explanation */ 7:
                    message.explanation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BackgroundColorExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* string explanation = 7; */
        if (message.explanation !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.explanation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.BackgroundColorExplanation
 */
export const BackgroundColorExplanation = new BackgroundColorExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BackgroundColorExplanationSearchRequest$Type extends MessageType<BackgroundColorExplanationSearchRequest> {
    constructor() {
        super("brandguard.BackgroundColorExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<BackgroundColorExplanationSearchRequest>): BackgroundColorExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BackgroundColorExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BackgroundColorExplanationSearchRequest): BackgroundColorExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BackgroundColorExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.BackgroundColorExplanationSearchRequest
 */
export const BackgroundColorExplanationSearchRequest = new BackgroundColorExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BackgroundColorExplanationSearchResponse$Type extends MessageType<BackgroundColorExplanationSearchResponse> {
    constructor() {
        super("brandguard.BackgroundColorExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BackgroundColorExplanation },
            { no: 2, name: "next", kind: "message", T: () => BackgroundColorExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<BackgroundColorExplanationSearchResponse>): BackgroundColorExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BackgroundColorExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BackgroundColorExplanationSearchResponse): BackgroundColorExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.BackgroundColorExplanation items */ 1:
                    message.items.push(BackgroundColorExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.BackgroundColorExplanationSearchRequest next */ 2:
                    message.next = BackgroundColorExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BackgroundColorExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.BackgroundColorExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            BackgroundColorExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.BackgroundColorExplanationSearchRequest next = 2; */
        if (message.next)
            BackgroundColorExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.BackgroundColorExplanationSearchResponse
 */
export const BackgroundColorExplanationSearchResponse = new BackgroundColorExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BackgroundColorExplanationMaskURL$Type extends MessageType<BackgroundColorExplanationMaskURL> {
    constructor() {
        super("brandguard.BackgroundColorExplanationMaskURL", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BackgroundColorExplanationMaskURL>): BackgroundColorExplanationMaskURL {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BackgroundColorExplanationMaskURL>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BackgroundColorExplanationMaskURL): BackgroundColorExplanationMaskURL {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BackgroundColorExplanationMaskURL, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.BackgroundColorExplanationMaskURL
 */
export const BackgroundColorExplanationMaskURL = new BackgroundColorExplanationMaskURL$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ViolenceExplanation$Type extends MessageType<ViolenceExplanation> {
    constructor() {
        super("brandguard.ViolenceExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ViolenceExplanation>): ViolenceExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ViolenceExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ViolenceExplanation): ViolenceExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ViolenceExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ViolenceExplanation
 */
export const ViolenceExplanation = new ViolenceExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ViolenceExplanationSearchRequest$Type extends MessageType<ViolenceExplanationSearchRequest> {
    constructor() {
        super("brandguard.ViolenceExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ViolenceExplanationSearchRequest>): ViolenceExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ViolenceExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ViolenceExplanationSearchRequest): ViolenceExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ViolenceExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ViolenceExplanationSearchRequest
 */
export const ViolenceExplanationSearchRequest = new ViolenceExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ViolenceExplanationSearchResponse$Type extends MessageType<ViolenceExplanationSearchResponse> {
    constructor() {
        super("brandguard.ViolenceExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ViolenceExplanation },
            { no: 2, name: "next", kind: "message", T: () => ViolenceExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<ViolenceExplanationSearchResponse>): ViolenceExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ViolenceExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ViolenceExplanationSearchResponse): ViolenceExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.ViolenceExplanation items */ 1:
                    message.items.push(ViolenceExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.ViolenceExplanationSearchRequest next */ 2:
                    message.next = ViolenceExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ViolenceExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.ViolenceExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ViolenceExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.ViolenceExplanationSearchRequest next = 2; */
        if (message.next)
            ViolenceExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ViolenceExplanationSearchResponse
 */
export const ViolenceExplanationSearchResponse = new ViolenceExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NudityExplanation$Type extends MessageType<NudityExplanation> {
    constructor() {
        super("brandguard.NudityExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NudityExplanation>): NudityExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NudityExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NudityExplanation): NudityExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NudityExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.NudityExplanation
 */
export const NudityExplanation = new NudityExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NudityExplanationSearchRequest$Type extends MessageType<NudityExplanationSearchRequest> {
    constructor() {
        super("brandguard.NudityExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<NudityExplanationSearchRequest>): NudityExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NudityExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NudityExplanationSearchRequest): NudityExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NudityExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.NudityExplanationSearchRequest
 */
export const NudityExplanationSearchRequest = new NudityExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NudityExplanationSearchResponse$Type extends MessageType<NudityExplanationSearchResponse> {
    constructor() {
        super("brandguard.NudityExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NudityExplanation },
            { no: 2, name: "next", kind: "message", T: () => NudityExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<NudityExplanationSearchResponse>): NudityExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NudityExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NudityExplanationSearchResponse): NudityExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.NudityExplanation items */ 1:
                    message.items.push(NudityExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.NudityExplanationSearchRequest next */ 2:
                    message.next = NudityExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NudityExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.NudityExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            NudityExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.NudityExplanationSearchRequest next = 2; */
        if (message.next)
            NudityExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.NudityExplanationSearchResponse
 */
export const NudityExplanationSearchResponse = new NudityExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageConsistencyExplanation$Type extends MessageType<ImageConsistencyExplanation> {
    constructor() {
        super("brandguard.ImageConsistencyExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageConsistencyExplanation>): ImageConsistencyExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageConsistencyExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageConsistencyExplanation): ImageConsistencyExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageConsistencyExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ImageConsistencyExplanation
 */
export const ImageConsistencyExplanation = new ImageConsistencyExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageConsistencyExplanationSearchRequest$Type extends MessageType<ImageConsistencyExplanationSearchRequest> {
    constructor() {
        super("brandguard.ImageConsistencyExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ImageConsistencyExplanationSearchRequest>): ImageConsistencyExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageConsistencyExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageConsistencyExplanationSearchRequest): ImageConsistencyExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageConsistencyExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ImageConsistencyExplanationSearchRequest
 */
export const ImageConsistencyExplanationSearchRequest = new ImageConsistencyExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageConsistencyExplanationSearchResponse$Type extends MessageType<ImageConsistencyExplanationSearchResponse> {
    constructor() {
        super("brandguard.ImageConsistencyExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ImageConsistencyExplanation },
            { no: 2, name: "next", kind: "message", T: () => ImageConsistencyExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<ImageConsistencyExplanationSearchResponse>): ImageConsistencyExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageConsistencyExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageConsistencyExplanationSearchResponse): ImageConsistencyExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.ImageConsistencyExplanation items */ 1:
                    message.items.push(ImageConsistencyExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.ImageConsistencyExplanationSearchRequest next */ 2:
                    message.next = ImageConsistencyExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageConsistencyExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.ImageConsistencyExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ImageConsistencyExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.ImageConsistencyExplanationSearchRequest next = 2; */
        if (message.next)
            ImageConsistencyExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ImageConsistencyExplanationSearchResponse
 */
export const ImageConsistencyExplanationSearchResponse = new ImageConsistencyExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrecisionExplanation$Type extends MessageType<PrecisionExplanation> {
    constructor() {
        super("brandguard.PrecisionExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "dimensions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Dimension }
        ]);
    }
    create(value?: PartialMessage<PrecisionExplanation>): PrecisionExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "", dimensions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PrecisionExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrecisionExplanation): PrecisionExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* repeated brandguard.Dimension dimensions */ 7:
                    message.dimensions.push(Dimension.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrecisionExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* repeated brandguard.Dimension dimensions = 7; */
        for (let i = 0; i < message.dimensions.length; i++)
            Dimension.internalBinaryWrite(message.dimensions[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.PrecisionExplanation
 */
export const PrecisionExplanation = new PrecisionExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Dimension$Type extends MessageType<Dimension> {
    constructor() {
        super("brandguard.Dimension", [
            { no: 1, name: "axes", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Dimension>): Dimension {
        const message = { axes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Dimension>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Dimension): Dimension {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 axes */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.axes.push(reader.int64().toBigInt());
                    else
                        message.axes.push(reader.int64().toBigInt());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Dimension, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 axes = 1; */
        if (message.axes.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.axes.length; i++)
                writer.int64(message.axes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.Dimension
 */
export const Dimension = new Dimension$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoClearspaceExplanation$Type extends MessageType<LogoClearspaceExplanation> {
    constructor() {
        super("brandguard.LogoClearspaceExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "dimensions", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LogoClearspaceExplanation>): LogoClearspaceExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "", dimensions: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoClearspaceExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoClearspaceExplanation): LogoClearspaceExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* string dimensions */ 7:
                    message.dimensions = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoClearspaceExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* string dimensions = 7; */
        if (message.dimensions !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dimensions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoClearspaceExplanation
 */
export const LogoClearspaceExplanation = new LogoClearspaceExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoClearspaceExplanationSearchRequest$Type extends MessageType<LogoClearspaceExplanationSearchRequest> {
    constructor() {
        super("brandguard.LogoClearspaceExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<LogoClearspaceExplanationSearchRequest>): LogoClearspaceExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoClearspaceExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoClearspaceExplanationSearchRequest): LogoClearspaceExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoClearspaceExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoClearspaceExplanationSearchRequest
 */
export const LogoClearspaceExplanationSearchRequest = new LogoClearspaceExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoClearspaceExplanationSearchResponse$Type extends MessageType<LogoClearspaceExplanationSearchResponse> {
    constructor() {
        super("brandguard.LogoClearspaceExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrecisionExplanation },
            { no: 2, name: "next", kind: "message", T: () => LogoClearspaceExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<LogoClearspaceExplanationSearchResponse>): LogoClearspaceExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoClearspaceExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoClearspaceExplanationSearchResponse): LogoClearspaceExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.PrecisionExplanation items */ 1:
                    message.items.push(PrecisionExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.LogoClearspaceExplanationSearchRequest next */ 2:
                    message.next = LogoClearspaceExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoClearspaceExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.PrecisionExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            PrecisionExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.LogoClearspaceExplanationSearchRequest next = 2; */
        if (message.next)
            LogoClearspaceExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoClearspaceExplanationSearchResponse
 */
export const LogoClearspaceExplanationSearchResponse = new LogoClearspaceExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoDistortionExplanation$Type extends MessageType<LogoDistortionExplanation> {
    constructor() {
        super("brandguard.LogoDistortionExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "dimensions", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LogoDistortionExplanation>): LogoDistortionExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "", dimensions: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoDistortionExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoDistortionExplanation): LogoDistortionExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* string dimensions */ 7:
                    message.dimensions = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoDistortionExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* string dimensions = 7; */
        if (message.dimensions !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dimensions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoDistortionExplanation
 */
export const LogoDistortionExplanation = new LogoDistortionExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoDistortionExplanationSearchRequest$Type extends MessageType<LogoDistortionExplanationSearchRequest> {
    constructor() {
        super("brandguard.LogoDistortionExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<LogoDistortionExplanationSearchRequest>): LogoDistortionExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoDistortionExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoDistortionExplanationSearchRequest): LogoDistortionExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoDistortionExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoDistortionExplanationSearchRequest
 */
export const LogoDistortionExplanationSearchRequest = new LogoDistortionExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoDistortionExplanationSearchResponse$Type extends MessageType<LogoDistortionExplanationSearchResponse> {
    constructor() {
        super("brandguard.LogoDistortionExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrecisionExplanation },
            { no: 2, name: "next", kind: "message", T: () => LogoDistortionExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<LogoDistortionExplanationSearchResponse>): LogoDistortionExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoDistortionExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoDistortionExplanationSearchResponse): LogoDistortionExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.PrecisionExplanation items */ 1:
                    message.items.push(PrecisionExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.LogoDistortionExplanationSearchRequest next */ 2:
                    message.next = LogoDistortionExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoDistortionExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.PrecisionExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            PrecisionExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.LogoDistortionExplanationSearchRequest next = 2; */
        if (message.next)
            LogoDistortionExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoDistortionExplanationSearchResponse
 */
export const LogoDistortionExplanationSearchResponse = new LogoDistortionExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoColorExplanation$Type extends MessageType<LogoColorExplanation> {
    constructor() {
        super("brandguard.LogoColorExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "dimensions", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LogoColorExplanation>): LogoColorExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "", dimensions: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoColorExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoColorExplanation): LogoColorExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* string dimensions */ 7:
                    message.dimensions = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoColorExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* string dimensions = 7; */
        if (message.dimensions !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dimensions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoColorExplanation
 */
export const LogoColorExplanation = new LogoColorExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoColorExplanationSearchRequest$Type extends MessageType<LogoColorExplanationSearchRequest> {
    constructor() {
        super("brandguard.LogoColorExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<LogoColorExplanationSearchRequest>): LogoColorExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoColorExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoColorExplanationSearchRequest): LogoColorExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoColorExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoColorExplanationSearchRequest
 */
export const LogoColorExplanationSearchRequest = new LogoColorExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoColorExplanationSearchResponse$Type extends MessageType<LogoColorExplanationSearchResponse> {
    constructor() {
        super("brandguard.LogoColorExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrecisionExplanation },
            { no: 2, name: "next", kind: "message", T: () => LogoColorExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<LogoColorExplanationSearchResponse>): LogoColorExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogoColorExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoColorExplanationSearchResponse): LogoColorExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.PrecisionExplanation items */ 1:
                    message.items.push(PrecisionExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.LogoColorExplanationSearchRequest next */ 2:
                    message.next = LogoColorExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoColorExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.PrecisionExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            PrecisionExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.LogoColorExplanationSearchRequest next = 2; */
        if (message.next)
            LogoColorExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.LogoColorExplanationSearchResponse
 */
export const LogoColorExplanationSearchResponse = new LogoColorExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FontDetectionExplanationSearchRequest$Type extends MessageType<FontDetectionExplanationSearchRequest> {
    constructor() {
        super("brandguard.FontDetectionExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<FontDetectionExplanationSearchRequest>): FontDetectionExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FontDetectionExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FontDetectionExplanationSearchRequest): FontDetectionExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FontDetectionExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.FontDetectionExplanationSearchRequest
 */
export const FontDetectionExplanationSearchRequest = new FontDetectionExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FontDetectionExplanationSearchResponse$Type extends MessageType<FontDetectionExplanationSearchResponse> {
    constructor() {
        super("brandguard.FontDetectionExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrecisionExplanation },
            { no: 2, name: "next", kind: "message", T: () => FontDetectionExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<FontDetectionExplanationSearchResponse>): FontDetectionExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FontDetectionExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FontDetectionExplanationSearchResponse): FontDetectionExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.PrecisionExplanation items */ 1:
                    message.items.push(PrecisionExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.FontDetectionExplanationSearchRequest next */ 2:
                    message.next = FontDetectionExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FontDetectionExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.PrecisionExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            PrecisionExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.FontDetectionExplanationSearchRequest next = 2; */
        if (message.next)
            FontDetectionExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.FontDetectionExplanationSearchResponse
 */
export const FontDetectionExplanationSearchResponse = new FontDetectionExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextReferenceRuleViolation$Type extends MessageType<TextReferenceRuleViolation> {
    constructor() {
        super("brandguard.TextReferenceRuleViolation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "rule", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "explanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TextReferenceRuleViolation>): TextReferenceRuleViolation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", description: "", rule: "", explanation: "", created_at: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextReferenceRuleViolation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextReferenceRuleViolation): TextReferenceRuleViolation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string rule */ 6:
                    message.rule = reader.string();
                    break;
                case /* string explanation */ 7:
                    message.explanation = reader.string();
                    break;
                case /* string created_at = 8 [json_name = "created_at"];*/ 8:
                    message.created_at = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextReferenceRuleViolation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string rule = 6; */
        if (message.rule !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.rule);
        /* string explanation = 7; */
        if (message.explanation !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.explanation);
        /* string created_at = 8 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.created_at);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.TextReferenceRuleViolation
 */
export const TextReferenceRuleViolation = new TextReferenceRuleViolation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextReferenceRuleViolationSearchRequest$Type extends MessageType<TextReferenceRuleViolationSearchRequest> {
    constructor() {
        super("brandguard.TextReferenceRuleViolationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<TextReferenceRuleViolationSearchRequest>): TextReferenceRuleViolationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextReferenceRuleViolationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextReferenceRuleViolationSearchRequest): TextReferenceRuleViolationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 5:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextReferenceRuleViolationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 5; */
        if (message.limit !== 0n)
            writer.tag(5, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.TextReferenceRuleViolationSearchRequest
 */
export const TextReferenceRuleViolationSearchRequest = new TextReferenceRuleViolationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextReferenceRuleViolationSearchResponse$Type extends MessageType<TextReferenceRuleViolationSearchResponse> {
    constructor() {
        super("brandguard.TextReferenceRuleViolationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TextReferenceRuleViolation },
            { no: 2, name: "next", kind: "message", T: () => TextReferenceRuleViolationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<TextReferenceRuleViolationSearchResponse>): TextReferenceRuleViolationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextReferenceRuleViolationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextReferenceRuleViolationSearchResponse): TextReferenceRuleViolationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.TextReferenceRuleViolation items */ 1:
                    message.items.push(TextReferenceRuleViolation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.TextReferenceRuleViolationSearchRequest next */ 2:
                    message.next = TextReferenceRuleViolationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextReferenceRuleViolationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.TextReferenceRuleViolation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            TextReferenceRuleViolation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.TextReferenceRuleViolationSearchRequest next = 2; */
        if (message.next)
            TextReferenceRuleViolationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.TextReferenceRuleViolationSearchResponse
 */
export const TextReferenceRuleViolationSearchResponse = new TextReferenceRuleViolationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextConsistencyExplanation$Type extends MessageType<TextConsistencyExplanation> {
    constructor() {
        super("brandguard.TextConsistencyExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TextConsistencyExplanation>): TextConsistencyExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextConsistencyExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextConsistencyExplanation): TextConsistencyExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextConsistencyExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.TextConsistencyExplanation
 */
export const TextConsistencyExplanation = new TextConsistencyExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextConsistencyExplanationSearchRequest$Type extends MessageType<TextConsistencyExplanationSearchRequest> {
    constructor() {
        super("brandguard.TextConsistencyExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<TextConsistencyExplanationSearchRequest>): TextConsistencyExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextConsistencyExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextConsistencyExplanationSearchRequest): TextConsistencyExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextConsistencyExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.TextConsistencyExplanationSearchRequest
 */
export const TextConsistencyExplanationSearchRequest = new TextConsistencyExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextConsistencyExplanationSearchResponse$Type extends MessageType<TextConsistencyExplanationSearchResponse> {
    constructor() {
        super("brandguard.TextConsistencyExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TextConsistencyExplanation },
            { no: 2, name: "next", kind: "message", T: () => TextConsistencyExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<TextConsistencyExplanationSearchResponse>): TextConsistencyExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextConsistencyExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextConsistencyExplanationSearchResponse): TextConsistencyExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.TextConsistencyExplanation items */ 1:
                    message.items.push(TextConsistencyExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.TextConsistencyExplanationSearchRequest next */ 2:
                    message.next = TextConsistencyExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextConsistencyExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.TextConsistencyExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            TextConsistencyExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.TextConsistencyExplanationSearchRequest next = 2; */
        if (message.next)
            TextConsistencyExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.TextConsistencyExplanationSearchResponse
 */
export const TextConsistencyExplanationSearchResponse = new TextConsistencyExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToneNVoiceExplanation$Type extends MessageType<ToneNVoiceExplanation> {
    constructor() {
        super("brandguard.ToneNVoiceExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ToneNVoiceExplanation>): ToneNVoiceExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ToneNVoiceExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ToneNVoiceExplanation): ToneNVoiceExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ToneNVoiceExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ToneNVoiceExplanation
 */
export const ToneNVoiceExplanation = new ToneNVoiceExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToneNVoiceExplanationSearchRequest$Type extends MessageType<ToneNVoiceExplanationSearchRequest> {
    constructor() {
        super("brandguard.ToneNVoiceExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ToneNVoiceExplanationSearchRequest>): ToneNVoiceExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ToneNVoiceExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ToneNVoiceExplanationSearchRequest): ToneNVoiceExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ToneNVoiceExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ToneNVoiceExplanationSearchRequest
 */
export const ToneNVoiceExplanationSearchRequest = new ToneNVoiceExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToneNVoiceExplanationSearchResponse$Type extends MessageType<ToneNVoiceExplanationSearchResponse> {
    constructor() {
        super("brandguard.ToneNVoiceExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ToneNVoiceExplanation },
            { no: 2, name: "next", kind: "message", T: () => ToneNVoiceExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<ToneNVoiceExplanationSearchResponse>): ToneNVoiceExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ToneNVoiceExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ToneNVoiceExplanationSearchResponse): ToneNVoiceExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.ToneNVoiceExplanation items */ 1:
                    message.items.push(ToneNVoiceExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.ToneNVoiceExplanationSearchRequest next */ 2:
                    message.next = ToneNVoiceExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ToneNVoiceExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.ToneNVoiceExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ToneNVoiceExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.ToneNVoiceExplanationSearchRequest next = 2; */
        if (message.next)
            ToneNVoiceExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ToneNVoiceExplanationSearchResponse
 */
export const ToneNVoiceExplanationSearchResponse = new ToneNVoiceExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RacismExplanation$Type extends MessageType<RacismExplanation> {
    constructor() {
        super("brandguard.RacismExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RacismExplanation>): RacismExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RacismExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RacismExplanation): RacismExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RacismExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.RacismExplanation
 */
export const RacismExplanation = new RacismExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RacismExplanationSearchRequest$Type extends MessageType<RacismExplanationSearchRequest> {
    constructor() {
        super("brandguard.RacismExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<RacismExplanationSearchRequest>): RacismExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RacismExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RacismExplanationSearchRequest): RacismExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RacismExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.RacismExplanationSearchRequest
 */
export const RacismExplanationSearchRequest = new RacismExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RacismExplanationSearchResponse$Type extends MessageType<RacismExplanationSearchResponse> {
    constructor() {
        super("brandguard.RacismExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RacismExplanation },
            { no: 2, name: "next", kind: "message", T: () => RacismExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<RacismExplanationSearchResponse>): RacismExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RacismExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RacismExplanationSearchResponse): RacismExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.RacismExplanation items */ 1:
                    message.items.push(RacismExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.RacismExplanationSearchRequest next */ 2:
                    message.next = RacismExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RacismExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.RacismExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            RacismExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.RacismExplanationSearchRequest next = 2; */
        if (message.next)
            RacismExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.RacismExplanationSearchResponse
 */
export const RacismExplanationSearchResponse = new RacismExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfanityExplanation$Type extends MessageType<ProfanityExplanation> {
    constructor() {
        super("brandguard.ProfanityExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProfanityExplanation>): ProfanityExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfanityExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfanityExplanation): ProfanityExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfanityExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ProfanityExplanation
 */
export const ProfanityExplanation = new ProfanityExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfanityExplanationSearchRequest$Type extends MessageType<ProfanityExplanationSearchRequest> {
    constructor() {
        super("brandguard.ProfanityExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ProfanityExplanationSearchRequest>): ProfanityExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfanityExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfanityExplanationSearchRequest): ProfanityExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfanityExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ProfanityExplanationSearchRequest
 */
export const ProfanityExplanationSearchRequest = new ProfanityExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfanityExplanationSearchResponse$Type extends MessageType<ProfanityExplanationSearchResponse> {
    constructor() {
        super("brandguard.ProfanityExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProfanityExplanation },
            { no: 2, name: "next", kind: "message", T: () => ProfanityExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<ProfanityExplanationSearchResponse>): ProfanityExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfanityExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfanityExplanationSearchResponse): ProfanityExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.ProfanityExplanation items */ 1:
                    message.items.push(ProfanityExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.ProfanityExplanationSearchRequest next */ 2:
                    message.next = ProfanityExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfanityExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.ProfanityExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ProfanityExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.ProfanityExplanationSearchRequest next = 2; */
        if (message.next)
            ProfanityExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.ProfanityExplanationSearchResponse
 */
export const ProfanityExplanationSearchResponse = new ProfanityExplanationSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrammarExplanation$Type extends MessageType<GrammarExplanation> {
    constructor() {
        super("brandguard.GrammarExplanation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", localName: "event_id", jsonName: "event_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GrammarExplanation>): GrammarExplanation {
        const message = { id: "", account_id: "", brand_id: "", event_id: "", created_at: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GrammarExplanation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GrammarExplanation): GrammarExplanation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* string event_id = 4 [json_name = "event_id"];*/ 4:
                    message.event_id = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GrammarExplanation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* string event_id = 4 [json_name = "event_id"]; */
        if (message.event_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.event_id);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.GrammarExplanation
 */
export const GrammarExplanation = new GrammarExplanation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrammarExplanationSearchRequest$Type extends MessageType<GrammarExplanationSearchRequest> {
    constructor() {
        super("brandguard.GrammarExplanationSearchRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "event_ids", kind: "scalar", localName: "event_ids", jsonName: "event_ids", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GrammarExplanationSearchRequest>): GrammarExplanationSearchRequest {
        const message = { offset: 0n, event_ids: [], brand_id: "", limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GrammarExplanationSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GrammarExplanationSearchRequest): GrammarExplanationSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* repeated string event_ids = 2 [json_name = "event_ids"];*/ 2:
                    message.event_ids.push(reader.string());
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                case /* uint64 limit */ 4:
                    message.limit = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GrammarExplanationSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0n)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* repeated string event_ids = 2 [json_name = "event_ids"]; */
        for (let i = 0; i < message.event_ids.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.event_ids[i]);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        /* uint64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).uint64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.GrammarExplanationSearchRequest
 */
export const GrammarExplanationSearchRequest = new GrammarExplanationSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrammarExplanationSearchResponse$Type extends MessageType<GrammarExplanationSearchResponse> {
    constructor() {
        super("brandguard.GrammarExplanationSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GrammarExplanation },
            { no: 2, name: "next", kind: "message", T: () => GrammarExplanationSearchRequest }
        ]);
    }
    create(value?: PartialMessage<GrammarExplanationSearchResponse>): GrammarExplanationSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GrammarExplanationSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GrammarExplanationSearchResponse): GrammarExplanationSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguard.GrammarExplanation items */ 1:
                    message.items.push(GrammarExplanation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandguard.GrammarExplanationSearchRequest next */ 2:
                    message.next = GrammarExplanationSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GrammarExplanationSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguard.GrammarExplanation items = 1; */
        for (let i = 0; i < message.items.length; i++)
            GrammarExplanation.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandguard.GrammarExplanationSearchRequest next = 2; */
        if (message.next)
            GrammarExplanationSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguard.GrammarExplanationSearchResponse
 */
export const GrammarExplanationSearchResponse = new GrammarExplanationSearchResponse$Type();

import React, { useState } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as imagery from "brandguide/build/imagery"

function mergeData(
	defaultArray: brandguide.api.ImageryFundamentalObject[],
	userArray: brandguide.api.ImageryFundamentalObject[],
): brandguide.api.ImageryFundamentalObject[] {
	return defaultArray.map((defaultItem) => {
		const userItem = userArray.find((userItem) => userItem.id === defaultItem.id)
		if (userItem) {
			return {
				...defaultItem,
				items: mergeItems(defaultItem.items, userItem.items),
			}
		} else {
			return defaultItem
		}
	})
}

function mergeItems(
	defaultItems: brandguide.api.ImageryFundamentalItem[],
	userItems: brandguide.api.ImageryFundamentalItem[],
): brandguide.api.ImageryFundamentalItem[] {
	const mergedItems = defaultItems.map((defaultItem) => {
		const userItem = userItems.find((userItem) => userItem.name === defaultItem.name)
		return userItem ? userItem : defaultItem
	})

	const newUserItems = userItems.filter(
		(userItem) => !defaultItems.some((defaultItem) => defaultItem.name === userItem.name),
	)
	return mergedItems.concat(newUserItems)
}

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideImagery | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const initialData = mergeData(
		brandguide.build.generators.initial.imagery.fundamentals(),
		_guide.guide?.imagery?.fundamentals || [],
	)
	const [data, setData] = useState(
		brandguide.api.zeros.guide.guideImageryZero({ ..._guide.guide?.imagery, fundamentals: initialData }),
	)

	const items = data.fundamentals || []

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Photography Fundamentals" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please select all that apply to your imagery fundamentals.">
							<imagery.Carousel my="25px" className={imagery.layouts.styledGap60}>
								{items.map((item) => (
									<imagery.layouts.FundamentalSectionItem
										key={item.id}
										item={item}
										onChange={(upd) => {
											setData({ ...data, fundamentals: items.map((i) => (i.id === upd.id ? upd : i)) })
										}}
									/>
								))}
							</imagery.Carousel>

							<imagery.Explanation
								val={data.descriptions?.fundamentals || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.imagery.descZero({ ...data.descriptions, fundamentals: text }),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(imagery.Steps.MISUSE, undefined)}
								onNext={() => {
									const upd = items.map((i) => {
										return brandguide.api.zeros.imagery.fundamentalObjectZero({
											...i,
											items: i.items.filter((f) => f.checked),
										})
									})
									onChange(imagery.Steps.RESULT, { ...data, fundamentals: upd })
								}}
								disabled={!brandguide.validations.imagery.valid.fundamentals(data)}
								isLastStep
							/>
							<imagery.Progress
								activeStep={imagery.layouts.actualSteps(data).findIndex((i) => i === "Fundamentals")}
								steps={imagery.layouts.actualSteps(data)}
							/>
						</brandguide.build.layouts.SectionStepContainer>
						<imagery.Guidelines activPage="imagery" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

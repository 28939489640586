import * as layouts from "layouts"
import * as typography from "typography"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as archetype from "brandguide/build/archetype"
import { Link } from "react-router-dom"
import * as brands from "brands"
import { results } from "brandguide/build/layouts"
import * as navigation from "brandguide/build/navigation"
import { breakdowns } from "./templates"
import { css } from "@emotion/css"
import * as authz from "authz"
import * as authzc from "authzcached"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideArchetype | undefined): void
}

export function SectionResult(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const guide = brandguide.cache.useCached().guide
	const data = brandguide.api.zeros.guide.guideArchetypeZero(guide.guide?.archetype)

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	const items = data.adjectives

	return (
		<layouts.Flex flex="1" {...rest}>
			<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
				<results.Title>Archetype</results.Title>
				<results.ResultDesc>
					A brand archetype is a symbolic representation of a brand&apos;s personality, values, and characteristics. It
					serves as a relatable character for the audience, guiding how the brand is perceived, expressed, and
					understood. Understanding the archetype informs the brand&apos;s voice, appearance, and behavior, as well as
					its values and worldview, enabling the creation of a cohesive brand personality and guiding future brand
					strategy.
					<br />
				</results.ResultDesc>

				<results.Explanation desc={data.descriptions?.selection} />

				{items.map((i) => (
					<BreakDownItem key={i.archetype} item={i} />
				))}

				<results.Explanation desc={data.descriptions?.breakdown} />

				<authz.Protected enabled={permission.brandguide_edit}>
					<layouts.Flex justifyContent="center" mt="100px" mb="20px" alignItems="center">
						<NextStageLink guide={guide} className="no-print" />
						<navigation.Section
							title="Archetype"
							steps={archetype.layouts.steps}
							onChange={(s) => onChange(s, undefined)}
						/>
					</layouts.Flex>
				</authz.Protected>
			</layouts.Flex>
			<colors.Guidelines activPage="archetype" className="no-print" />
		</layouts.Flex>
	)
}

interface breakdownProps extends layouts.containers.ContainerProps {
	item: brandguide.api.ArchetypeAdjectiveObject
}

const styledIcon = css`
	svg {
		path {
			stroke: ${layouts.theme.colors.white};
			fill: ${layouts.theme.colors.white};
			fill-opacity: 1;
			stroke-opacity: 1;
		}
	}
`

export function BreakDownItem(props: breakdownProps): JSX.Element {
	const { item, ...rest } = props
	const breakdownItem = breakdowns.find((i) => i.archetype === item.archetype)

	if (!breakdownItem) return <></>

	return (
		<layouts.Flex flex="1" flexDirection="column" my="35px" {...rest}>
			<results.Title>{breakdownItem.title}</results.Title>
			<layouts.Flex flex="1" gridGap="41px" mt="25px" color={layouts.theme.colors.grey.dark50}>
				<layouts.Flex>
					<layouts.Flex
						justifyContent="center"
						alignItems="center"
						height="160px"
						width="160px"
						borderRadius="5px"
						background={layouts.theme.colors.grey.dark50}
						className={styledIcon}
						position="relative"
					>
						<layouts.containers.absolute right="0" top="0">
							<layouts.Flex p="5px" alignItems="center" justifyContent="center">
								<typography.h5 color={layouts.theme.colors.white}>{item.percent.toFixed(1)}%</typography.h5>
							</layouts.Flex>
						</layouts.containers.absolute>
						<layouts.Flex>{breakdownItem.icon}</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
				<layouts.Flex flexDirection="column">
					<typography.h5 whiteSpace="unset" fontWeight="400" fontSize="14px" lineHeight="20px">
						{breakdownItem.description}
						<br />
						<br />
						<b style={{ fontWeight: "700" }}>Communication</b>
						<br />
						{breakdownItem.communication}
					</typography.h5>
					<layouts.Flex flex="1" mt="32px">
						<layouts.Flex
							flex="1"
							flexDirection="column"
							borderRight={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`}
							alignItems="center"
							justifyContent="center"
						>
							<archetype.layouts.ColumnTitle mb="12px" color={layouts.theme.colors.grey.dark50alpha80} fontSize="14px">
								What motivates you
							</archetype.layouts.ColumnTitle>
							{breakdownItem.motivates.map((i) => (
								<archetype.layouts.ColumnList key={i} fontSize="14px">
									{i}
								</archetype.layouts.ColumnList>
							))}
						</layouts.Flex>
						<layouts.Flex flex="1" flexDirection="column" alignItems="center" justifyContent="center">
							<archetype.layouts.ColumnTitle mb="12px" color={layouts.theme.colors.grey.dark50alpha80} fontSize="14px">
								What scares you
							</archetype.layouts.ColumnTitle>
							{breakdownItem.scares.map((i) => (
								<archetype.layouts.ColumnList key={i} fontSize="14px">
									{i}
								</archetype.layouts.ColumnList>
							))}
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<layouts.Flex justifyContent="right" mr="47px">
						<brandguide.build.SavePDF />
					</layouts.Flex>
					<SectionResult onChange={onChange} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

interface nextProps extends layouts.containers.FlexProps {
	guide: brandguide.api.Brandguide
}

function NextStageLink(props: nextProps): JSX.Element {
	const { guide, ...rest } = props
	const brand = brands.caching.useCached()

	const nextLink = (): string | undefined => {
		if (guide.guide?.compliance?.enabled) return brandguide.routing.build.compliance(brand.id)
		if (guide.guide?.application?.enabled) return brandguide.routing.build.application(brand.id)
		return undefined
	}

	if (!nextLink()) return <></>

	return (
		<layouts.Flex {...rest}>
			<Link to={nextLink()!}>
				<layouts.buttons.unstyled
					width="130px"
					height="34px"
					background={layouts.theme.colors.grey.dark50}
					borderRadius="5px"
					color={layouts.theme.colors.white}
					onClick={() => undefined}
				>
					Next Section
				</layouts.buttons.unstyled>
			</Link>
		</layouts.Flex>
	)
}

NextStageLink.defaultProps = {
	flex: "1",
	justifyContent: "end",
}

export default Display

import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as logo_compts from "./components"
import { css } from "@emotion/css"

const flexGrid = css`
	.responsive-grid {
		flex: 1;
	}
`

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = guide.guide?.logos!

	const usage = (data.usage || []).filter((el) => el.use)
	const misuse = (data.usage || []).filter((el) => !el.use)

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Correct Placement</result_components.NavTitle>
				<result_components.NavDescription>
					The placement of the logo can be anywhere that is most impactful within the composition of the piece you are
					creating..
				</result_components.NavDescription>
				<result_components.Explanation desc={guide.guide?.logos?.descriptions?.placement} />
				<result_components.Explanation desc={guide.guide?.logos?.descriptions?.usage} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer pr="2%" justifyContent="center">
					<layouts.Flex flexDirection="column">
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
								Correct Placement
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex flex="1" alignItems="center" alignContent="center">
							<logo_compts.PlacementEl data={data} />
						</layouts.Flex>
					</layouts.Flex>
					<layouts.Flex flexDirection="column">
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
								Misuse
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex flex="1" alignItems="center" alignContent="center" className={flexGrid}>
							<layouts.containers.ResponsiveGrid gap={15} breakpointCols={data.misuse.length} flex="1">
								{(data.misuse || []).map((item) => (
									<brandguide.build.logos.layouts.MisUseItem
										key={item.title}
										readonly
										item={item}
										onDelete={() => undefined}
									/>
								))}
							</layouts.containers.ResponsiveGrid>
						</layouts.Flex>
					</layouts.Flex>

					<layouts.Flex flexDirection="column">
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
								Logo: Things To Do
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex alignItems="center" alignContent="center" className={flexGrid}>
							<layouts.containers.ResponsiveGrid gap={15} breakpointCols={usage.length} flex="1">
								{usage.map((item) => (
									<brandguide.build.logos.layouts.UsageItem
										key={item.title}
										readonly
										svg={data.primary!.svg}
										item={item}
										onDelete={() => undefined}
										correct
									/>
								))}
							</layouts.containers.ResponsiveGrid>
						</layouts.Flex>
					</layouts.Flex>
					<layouts.Flex flexDirection="column">
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
								Logo: Things To Avoid
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex flex="1" alignItems="center" alignContent="center" className={flexGrid}>
							<layouts.containers.ResponsiveGrid gap={15} breakpointCols={misuse.length} flex="1">
								{misuse.map((item) => (
									<brandguide.build.logos.layouts.UsageItem
										key={item.title}
										readonly
										svg={data.primary!.svg}
										item={item}
										onDelete={() => undefined}
									/>
								))}
							</layouts.containers.ResponsiveGrid>
						</layouts.Flex>
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

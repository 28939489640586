import React, { useContext, useEffect, useState } from "react"
import * as httpx from "httpx"
import * as sessions from "sessions"
import * as authz from "authz"
import * as meta from "meta/authz"

export interface cached {
	meta: authz.tokens.Cached<meta.Token>
}

export function zero(session: sessions.Session = sessions.zero()): cached {
	return {
		meta: meta.useAuthz(session),
	}
}

export const Context = React.createContext(zero())
export const Provider = Context.Provider

export function useCache<T>(reduce: (cached: cached) => T): T {
	const available = useContext(Context)
	return reduce(available)
}

export function bearer<T extends authz.tokens.Token>(c: authz.tokens.Cached<T>): httpx.option {
	return httpx.options.maybeBearer(c.token().then((token) => token.bearer))
}

export function Storage(props: React.PropsWithChildren<unknown>): JSX.Element {
	const session = sessions.useSession()
	const [cached, setCached] = useState(zero(session))

	// reset the cache when the profile changes.
	useEffect(() => {
		const auth = zero(session)
		const pending = auth.meta
			.token()
			.then((t) => {
				setCached({ ...auth, meta: { ...auth.meta, current: t } })
			})
			.catch(httpx.errors.cancellation(console.warn))
		return pending.cancel
	}, [session.profile.id])

	return (
		<Provider key={cached.meta.current.id} value={cached}>
			{props.children}
		</Provider>
	)
}

import React from "react"
import * as layouts from "layouts"

interface props extends layouts.containers.FlexProps, layouts.containers.DefaultStyling {
	cause?: JSX.Element
}

export function overlay(props: React.PropsWithChildren<props>): JSX.Element {
	const { cause, children, styled, ...rest } = props

	if (cause) {
		return (
			<layouts.containers.flex position="relative" {...rest}>
				{props.children}
				<layouts.containers.absolute
					className="errors-abs"
					styled={styled}
					p="0px"
					display="flex"
					top="0px"
					left="0px"
					width="100%"
					height="100%"
				>
					{cause}
				</layouts.containers.absolute>
			</layouts.containers.flex>
		)
	}

	return <>{children}</>
}

import * as httpx from "httpx"
import * as events from "./events"
import { CancellablePromise } from "real-cancellable-promise"
import {
	ImageSearchRequest,
	ImageSearchResponse,
	ImagePatchRequest,
	ImagePatchResponse,
	ImageUploadResponse,
	ImportFromURLRequest,
	ImportFromURLResponse,
	StockImportRequest,
	StockImportResponse,
	GoogleImagesRequest,
	GoogleImagesResponse,
} from "brandguard/brandguard.image_pb"

export function upload(
	data: FormData,
	file: File,
	bid: string,
	...options: httpx.option[]
): CancellablePromise<ImageUploadResponse> {
	data.append("content", file)
	return httpx
		.upload<ImageUploadResponse>(`${httpx.urlstorage.host()}/brands/${bid}/bg/image/imports/file`, data, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export function search(
	bid: string,
	req: ImageSearchRequest,
	...options: httpx.option[]
): CancellablePromise<ImageSearchResponse> {
	return httpx.get<ImageSearchResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}`, req, ...options).then((resp) => ({
		...resp,
		items: (resp.items || []).map((item) => ({ media: item.media, event: events.zero(item.event) })),
	}))
}

export function publish(
	id: string,
	bid: string,
	req: ImagePatchRequest,
	...options: httpx.option[]
): Promise<ImagePatchResponse> {
	return httpx
		.post<ImagePatchResponse>(`${httpx.urlstorage.host()}/bg/image/${bid}/${id}/reviewed`, req, ...options)
		.then((resp) => ({
			media: resp.media,
			event: events.zero(resp.event),
		}))
}

export async function urlimport(
	bid: string,
	req: ImportFromURLRequest,
	...options: httpx.option[]
): Promise<ImportFromURLResponse> {
	return httpx.post<ImportFromURLRequest>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/image/imports/url`,
		req,
		...options,
	)
}

export async function stockimport(
	bid: string,
	req: StockImportRequest,
	...options: httpx.option[]
): Promise<StockImportResponse> {
	return httpx.post<StockImportRequest>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/image/imports/stockphotos`,
		req,
		...options,
	)
}

export async function googleimagesimport(
	bid: string,
	req: GoogleImagesRequest,
	...options: httpx.option[]
): Promise<GoogleImagesResponse> {
	return httpx.post<GoogleImagesRequest>(
		`${httpx.urlstorage.host()}/brands/${bid}/bg/image/imports/google`,
		req,
		...options,
	)
}

import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import styled from "@emotion/styled"

const Card = styled(layouts.Flex)``
Card.defaultProps = {
	flex: "1",
	height: "100%",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "20px",
	background: colors.blue.blue,
	border: `1px solid ${colors.grey.dark50alpha30}`,
}

const CardText = styled(typography.h5)``
CardText.defaultProps = {
	p: "5%",
	whiteSpace: "unset",
	fontSize: "2vh",
	fontWeight: "700",
	lineHeight: "normal",
}

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const data = brandguide.api.zeros.guide.guideToneOfVoiceZero(guide.guide?.tone_of_voice)
	const selected = data.tone_of_voice.find((i) => i.selected)
	const pos = data.positive_adjectives || []
	const neg = data.negative_adjectives || []

	const maxItems = Math.max(pos.length, neg.length)

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Tone of Voice</result_components.NavTitle>
				<result_components.NavDescription>
					Our brand voice is {selected?.description || ""}
					<br />
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.tone_of_voice} />
				<result_components.Explanation desc={data.descriptions?.adjectives} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer gridGap="2%">
					{selected && (
						<layouts.Flex flex="1" flexDirection="column">
							<layouts.Flex>
								<typography.h4 color={colors.grey.dark50} lineHeight="50px">
									Use language that feels:
								</typography.h4>
							</layouts.Flex>
							<layouts.Flex flex="1" maxHeight="45vh" maxWidth="45vw">
								<Card width="50%">
									<CardText color={colors.white}>{selected.description}</CardText>
								</Card>
							</layouts.Flex>
						</layouts.Flex>
					)}

					<layouts.Flex flex="1" flexDirection="column" gridGap="2%">
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px">
								We Are
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex flex="1" alignItems="center" justifyContent="left" width="100%" gridGap="1%">
							{pos.map((i) => (
								<Card key={i.name} maxWidth={`${100 / maxItems - 1}%`}>
									<CardText color={colors.white}>{i.name}</CardText>
								</Card>
							))}
						</layouts.Flex>
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px">
								We Are Not
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex flex="1" alignItems="center" justifyContent="left" width="100%" gridGap="1%">
							{neg.map((i) => (
								<Card key={i.name} maxWidth={`${100 / maxItems - 1}%`}>
									<CardText color={colors.white}>{i.name}</CardText>
								</Card>
							))}
						</layouts.Flex>
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

import React from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import { CancellablePromise } from "real-cancellable-promise"
import * as bglayouts from "brandguard/components/layouts"
import * as typography from "typography"
import classnames from "classnames"

interface dprops<T> {
	concurrent: number // number of concurrent upload
	onChange(item: T): void // invoked once isProcessing is false.
	isProcessing(item: T): boolean // check if the item is still being processed.
	resume(): CancellablePromise<inputs.uploads.api.uploaded<T>[]> // items to resume processing during component mount.
	refresh(data: T): CancellablePromise<T> // refresh pending data.
	upload(stats: inputs.uploads.api.statistics, content: File): CancellablePromise<T> // upload content
	DropWell: React.FunctionComponent<React.PropsWithChildren<inputs.uploads.api.Props>> // dropwell or another input component
}

export function UploadSimple<T>(
	props: React.PropsWithChildren<
		Omit<dprops<T>, "concurrent"> & { onClose(): void; title?: string } & layouts.containers.FlexProps
	>,
): JSX.Element {
	const { onChange, resume, refresh, upload, DropWell, isProcessing, onClose, title, ...rest } = props

	return (
		<layouts.Flex height="min-content" className={classnames(bglayouts.uploads.uploadsimple)}>
			<layouts.Flex
				flexDirection="column"
				background={layouts.theme.colors.white}
				borderRadius="5px"
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flexDirection="row" mb="20px">
					<layouts.Flex flex="9" justifyContent="flex-start" alignItems="center">
						<typography.h5 fontSize="18px" fontWeight="700" p="10px" pt="20px" color={layouts.theme.colors.grey.medium}>
							{title}
						</typography.h5>
					</layouts.Flex>
					<layouts.Flex flex="1" justifyContent="flex-end" alignItems="flex-start">
						<layouts.Flex onClick={onClose}>
							<icons.Close
								width="15px"
								height="15px"
								fill={layouts.theme.colors.grey.dark50}
								fillOpacity="0.6"
								p="5px"
							/>
						</layouts.Flex>
					</layouts.Flex>
				</layouts.Flex>
				<inputs.uploads.Upload
					concurrent={4}
					isProcessing={isProcessing}
					onChange={onChange}
					resume={resume}
					upload={upload}
					refresh={refresh}
					DropWell={DropWell}
					background="unset"
					minWidth="350px"
					{...rest}
				/>
			</layouts.Flex>
		</layouts.Flex>
	)
}

UploadSimple.defaultProps = {
	title: "Upload Assets",
}

import * as accounts from "./meta.account"
export type { Account } from "./meta.account"

export const missing = zero({})

export function zero(a: Partial<accounts.Account> = {}): accounts.Account {
	return {
		id: "00000000-0000-0000-0000-000000000000",
		description: "",
		email: "",
		phone: "",
		created_at: "",
		updated_at: "",
		disabled_at: "",
		domain: "",
		...a,
	}
}

export { v4, NIL } from "uuid"

export function* _genincremental(n: number): IterableIterator<string> {
	while (true) {
		n++
		yield `00000000-0000-0000-0000-${String(n).padStart(12, "0")}`
	}
}

export function incremental(n: number = 0): () => string {
	const gen = _genincremental(n)
	return () => gen.next().value
}

export const Max = "ffffffff-ffff-ffff-ffff-ffffffffffff"

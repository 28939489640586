import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"

export function Modal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Compliance Help
				</typography.h4>
				<layouts.Flex py="25px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>
							Regulatory compliance refers to the adherence to laws, regulations, guidelines, and specifications
							relevant to an organization&apos;s business operations.
						</b>{" "}
						It ensures that the company operates within legal boundaries and follows industry standards to avoid legal
						repercussions, fines, and penalties. Regulatory compliance encompasses various aspects such as financial
						reporting, data protection, environmental laws, and industry-specific regulations. Maintaining regulatory
						compliance is crucial for building trust with customers, stakeholders, and regulatory bodies, safeguarding
						the company&apos;s reputation, and ensuring sustainable and ethical business practices.
						<br />
						<br />
						Adding compliance regulations to your brand guidelines is crucial for several reasons:
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Legal Protection:</b> Ensures that your brand adheres to relevant laws and
						regulations, reducing the risk of legal issues and potential fines.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Consistency:</b> Maintains consistent application of brand elements across
						various mediums, ensuring that all communications are within legal and ethical boundaries.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Trust and Credibility:</b> Enhances trust and credibility with consumers,
						stakeholders, and partners by demonstrating a commitment to ethical practices.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Risk Management:</b> Helps identify and mitigate potential risks associated
						with non-compliance, protecting the brand&apos;s reputation.
						<br />
						<br />
						<b style={{ fontWeight: "600" }}>Global Standards:</b> Assists in navigating different compliance
						requirements across various regions, ensuring the brand meets international standards.
					</p>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Modal.defaultProps = {
	width: "815px",
	height: "600px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

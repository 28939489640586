import React, { useContext, useEffect, useState } from "react"
import * as brands from "brands"
import * as api from "brandguide/api"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as layouts from "layouts"

export const Context = React.createContext([] as api.GoogleFont[])
export const Provider = Context.Provider

export function useCached(): api.GoogleFont[] {
	return useContext(Context)
}

export function Storage(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [cached, setCached] = useState([] as api.GoogleFont[])
	const [loading, setLoading] = useState(true)
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const brand = brands.caching.useCached()

	useEffect(() => {
		const retry = httpx.autoretry()
		const pending = retry
			.wrap(() => api.getGoogleFonts(brand.id, authzc.bearer(metaauthz)))
			.then((r) => setCached(r.items))
			.catch(httpx.errors.cancellation(console.warn))
			.catch((e) => console.error(e))
			.finally(() => setLoading(false))
		return pending.cancel
	}, [brand.id])

	return (
		<layouts.loading.screen flex="1" loading={loading}>
			<Provider value={cached}>{props.children}</Provider>
		</layouts.loading.screen>
	)
}

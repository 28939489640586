export const storagekey = "nsuite.host.urls.api"
export const console_storagekey = "nsuite.host.urls.console"
export const sso_storagekey = `${storagekey}.sso`

export function host(): string {
	// TODO default to production host
	return load(storagekey, process.env.REACT_APP_NSUITE_API_HTTP_ENDPOINT || "https://localhost:3001")
}

export function ssohostname(): string {
	return load(sso_storagekey, process.env.REACT_APP_SSO_HOSTNAME || "sso.localhost:3000")
}

export function sso(): string {
	return `https://${ssohostname()}`
}

export function publicUrl(): string {
	return load(storagekey, process.env.PUBLIC_URL || "https://localhost:3000")
}

export function wshost(): string {
	return load(storagekey, process.env.REACT_APP_NSUITE_API_HTTP_ENDPOINT || "https://localhost:3001").replace(
		"https://",
		"wss://",
	)
}

export function console_host(): string {
	return load(console_storagekey, process.env.REACT_APP_NSUITE_CONSOLE_HTTP_ENDPOINT || "https://localhost:3000")
}

export function update(urlkey: string, v: string): string {
	window?.localStorage?.setItem(urlkey, v)
	return v
}

export function load(urlkey: string, fallback: string = ""): string {
	return window?.localStorage?.getItem(urlkey) || fallback
}

import * as httpx from "httpx"
import * as timex from "timex"
import { CancellablePromise } from "real-cancellable-promise"
import { KernelMetadata, Status } from "brandguard/brandguard.kernel_pb"

export * as images from "./images"
export * as text from "./texts"
export * as observations from "./observations"
export * as violations from "./violations"
export * as explanations from "./explanations"
export * as text_reference_rule_violations from "./text_reference_rule_violations"

export { Prediction } from "brandguard/brandguard.approval_pb"
export { FloatRange } from "brandguard/meta.search_pb"
export {
	ImageUploadResponse,
	ImageSearchRequest,
	ImageSearchResponse,
	ImageSearchResponseItem,
	ImagePatchRequest,
	ImagePatchResponse,
	ImageFindResponse,
	ImageTrainRequest,
	ImageRescoreRequest,
	ImportFromURLRequest,
	ImportFromURLResponse,
	StockImportRequest,
	StockImportResponse,
	GoogleImagesRequest,
	GoogleImagesResponse,
	ImageEvent,
	ImageKernelSettingsCreateRequest,
	ImageKernelSettingsCreateResponse,
} from "brandguard/brandguard.image_pb"
export {
	TextSearchRequest,
	TextSearchResponse,
	TextSearchResponseItem,
	TextUploadResponse,
	TextPatchRequest,
	TextPatchResponse,
	TextFindResponse,
	TextTrainRequest,
	TextRescoreRequest,
	TextEvent,
	TextKernelSettingsRequest,
	TextKernelSettingsResponse,
	TextGenerationRequest,
	TextGenerationResponse,
} from "brandguard/brandguard.text_pb"
export {
	TextKernelSetting,
	TextKernelReferenceRulesEnabled,
	ImageKernelSetting,
	ImageKernelLogo,
	ImageKernelFont,
	ImageKernelLogosEnabled,
	ImageKernelFontEnabled,
	ImageKernelObscureRule,
	ImageKernelObscureRulesEnabled,
	ImageKernelBackgroundColor,
	ImageKernelBackgroundColorEnabled,
	KernelMetadata,
	Status,
	ImageClearspace,
} from "brandguard/brandguard.kernel_pb"
export {
	BackgroundColorExplanation,
	BackgroundColorExplanationSearchRequest,
	BackgroundColorExplanationSearchResponse,
	BackgroundColorExplanationMaskURL,
	ViolenceExplanation,
	ViolenceExplanationSearchRequest,
	ViolenceExplanationSearchResponse,
	NudityExplanation,
	NudityExplanationSearchRequest,
	NudityExplanationSearchResponse,
	EventViolation,
	EventViolationSearchRequest,
	EventViolationSearchResponse,
	TextReferenceRuleViolation,
	TextReferenceRuleViolationSearchRequest,
	TextReferenceRuleViolationSearchResponse,
	LogoDistortionExplanation,
	LogoDistortionExplanationSearchRequest,
	LogoDistortionExplanationSearchResponse,
	LogoClearspaceExplanation,
	LogoClearspaceExplanationSearchRequest,
	LogoClearspaceExplanationSearchResponse,
	LogoColorExplanation,
	LogoColorExplanationSearchRequest,
	LogoColorExplanationSearchResponse,
	PrecisionExplanation,
	ImageConsistencyExplanation,
	ImageConsistencyExplanationSearchRequest,
	ImageConsistencyExplanationSearchResponse,
	TextConsistencyExplanation,
	TextConsistencyExplanationSearchRequest,
	TextConsistencyExplanationSearchResponse,
	ToneNVoiceExplanation,
	ToneNVoiceExplanationSearchRequest,
	ToneNVoiceExplanationSearchResponse,
	FontDetectionExplanationSearchRequest,
	FontDetectionExplanationSearchResponse,
	RacismExplanation,
	RacismExplanationSearchRequest,
	RacismExplanationSearchResponse,
	ProfanityExplanation,
	ProfanityExplanationSearchRequest,
	ProfanityExplanationSearchResponse,
	GrammarExplanation,
	GrammarExplanationSearchRequest,
	GrammarExplanationSearchResponse,
} from "brandguard/brandguard_pb"

export interface scored {
	brandguard: number
	profanity: number
	racism: number
	grammar: number
	onbrand: number
}

export interface Media {
	id: string
	account_id: string
	brand_id: string
	uploaded_by: string
	md5: string
	mimetype: string
	mimetype_md5: string
}

export function clamp(v: number): number {
	return Math.round((v || 0) * 10000) / 100
}

export function human(v: Omit<scored, "brandguard">): number {
	return 0.6 * ((v.profanity + v.grammar + v.racism) / 3) + 0.4 * v.onbrand
}

export function findMaxOffset(a: bigint | undefined, b: bigint | undefined): bigint | undefined {
	if (Number(a) === -1 && Number(b) === -1) return undefined
	if (Number(a) === -1) return b
	if (Number(b) === -1) return a
	return BigInt(Math.max(Number(a), Number(b)))
}

const REQUEUE_AFTER_DURATION: timex.Duration = timex.duration.hours(3)
type requeuer<T> = (id: string, bid: string, ...options: httpx.option[]) => Promise<T>
type eventable = { event?: { id: string; updated_at: string } }

export function requeue<T extends eventable>(
	requeuer: requeuer<T>,
	bid: string,
	auth: httpx.option,
): (resp: T) => Promise<T> {
	const maybeRequeue: (resp: T) => Promise<T> = (resp: T) => {
		if (timex.utc() <= timex.from.iso(resp.event?.updated_at!).plus(REQUEUE_AFTER_DURATION)) {
			return CancellablePromise.resolve(resp)
		}

		return requeuer(resp.event?.id!, bid, auth)
	}

	return maybeRequeue
}

export namespace kernels {
	export namespace metadata {
		export function zero(d: Partial<KernelMetadata>): KernelMetadata {
			return {
				id: "",
				version: "",
				account_id: "",
				brand_id: "",
				mimetype: "",
				created_at: "",
				updated_at: "",
				status: Status.PENDING,
				...d,
			}
		}
	}
}

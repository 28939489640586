import * as httpx from "httpx"
import * as images from "."
import { ImageEvent, ImageSearchResponseItem } from "brandguard/brandguard.image_pb"
import { Prediction } from "brandguard/brandguard.approval_pb"

export const agree = async (
	item: ImageSearchResponseItem & { event: ImageEvent },
	...options: httpx.option[]
): Promise<ImageSearchResponseItem> => {
	if (item.event.approved === Prediction.APPROVED) {
		return images.uploads.review(item.event.id, item.event.brand_id, ...options)
	}

	return images.uploads.publish(item.event.id, item.event.brand_id, ...options)
}

export const disagree = (
	item: ImageSearchResponseItem & { event: ImageEvent },
	...options: httpx.option[]
): Promise<ImageSearchResponseItem> => {
	return images.uploads.review(item.event.id, item.event.brand_id, ...options)
}

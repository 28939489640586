import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as logo_compts from "./components"
import { css } from "@emotion/css"

const flexGrid = css`
	.responsive-grid {
		flex: 1;
	}
`

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = guide.guide?.logos!

	const primary = data.primary!

	const items = [data.primary, data.secondary, data.logomark, data.wordmark, data.alternative].filter((l) => l?.present)
	const additionals = [data.secondary, data.logomark, data.wordmark, data.alternative].filter((l) => l?.present)

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Clearspace</result_components.NavTitle>
				<result_components.NavDescription>
					This clearspace applies to all of our logos. This ensures that the logo remains legible, particularly at
					smaller sizes or when viewed from a distance.
					<br />
					<br />
					Please do not put any text too close to this logo or place the logo too close to the edge of the page
				</result_components.NavDescription>
				<result_components.Explanation desc={guide.guide?.logos?.descriptions?.clearspace} />
				<result_components.Explanation desc={guide.guide?.logos?.descriptions?.proportions} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer gridGap="2%">
					<layouts.Flex flex="2" flexDirection="column" {...logo_compts.fullSizerProps}>
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px">
								Clearspace
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex {...logo_compts.centeredProps} gridGap="30px">
							<logo_compts.ClearspaceEl item={primary} imageSize={100} />
							{additionals.length > 0 && (
								<layouts.containers.ResponsiveGrid
									gap={20}
									breakpointCols={additionals.length === 1 ? 1 : 2}
									maxHeight="100%"
									alignItems="center"
									justifyContent="center"
									flex="1"
									className={flexGrid}
								>
									{additionals.map((i, index) => (
										<logo_compts.ClearspaceEl key={index} item={i!} imageSize={50} />
									))}
								</layouts.containers.ResponsiveGrid>
							)}
						</layouts.Flex>
					</layouts.Flex>
					<layouts.Flex flex="1" gridGap="5%" {...logo_compts.fullSizerProps}>
						<layouts.Flex flex="1" flexDirection="column">
							<layouts.Flex>
								<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
									Proportions
								</typography.h4>
							</layouts.Flex>
							<layouts.Flex flex="1" alignItems="end" gridGap="5%">
								{items.map((i, index) => (
									<logo_compts.ProportionEl key={index} item={i!} />
								))}
							</layouts.Flex>
						</layouts.Flex>
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

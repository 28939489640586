import React from "react"
import styled from "@emotion/styled"
import * as system from "styled-system"
import * as containers from "./containers"
import * as layouts from "layouts"

const Background = styled.div<system.LayoutProps>`
	position: asbolute;
	height: inherit;
	background-image: linear-gradient(54.62deg, #5c1aff 0.61%, #800080 99.38%);
	${system.layout}
`

export function Splash(
	props: React.PropsWithChildren<
		{ className?: string } & containers.ContainerProps & system.FlexProps & system.FlexDirectionProps
	>,
): JSX.Element {
	const { children, ...rest } = props
	return (
		<>
			<containers.flex {...rest}>{children}</containers.flex>
			<containers.absolute zIndex={-1} top="0px" left="0px" width="100vw" height="100vh">
				<Background height="50%"></Background>
			</containers.absolute>
		</>
	)
}

export function Grey(
	props: React.PropsWithChildren<containers.ContainerProps & system.FlexProps & system.FlexDirectionProps>,
): JSX.Element {
	const { children, ...rest } = props
	return (
		<>
			<containers.flex background={layouts.theme.colors.grey.bg} {...rest}>
				{children}
			</containers.flex>
			<containers.absolute zIndex={-1} top="0px" left="0px" height="100vh"></containers.absolute>
		</>
	)
}

import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import * as components from "./components"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"

interface section {
	title: string
	number: number
	pages: string[]
}

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const items = ActiveSections(guide)

	return (
		<layouts.Flex {...rest}>
			<components.LeftSideNav>
				<result_components.NavDescription>
					If you have questions about the content in this Brand Guidelines document, please contact:
					<br />
					<br />
					contact@brandguard.ai
					<br />
				</result_components.NavDescription>
			</components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer pl="10%" pr="10%" height="50%">
					<layouts.containers.grid gridTemplateColumns="1fr 1fr 1fr 1fr" flex="1">
						{items.map((i) => (
							<layouts.Flex key={i.title} flexDirection="column" p="25px">
								<typography.h5 color={colors.grey.dark50} fontSize="16px" lineHeight="32px">
									{i.number}. {i.title}
								</typography.h5>
								{i.pages.map((p) => (
									<typography.h6
										key={p}
										color={colors.grey.dark50alpha80}
										fontSize="12px"
										fontWeight="400"
										lineHeight="20px"
									>
										{p}
									</typography.h6>
								))}
							</layouts.Flex>
						))}
					</layouts.containers.grid>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

const ActiveSections = (guide: brandguide.api.Brandguide) => {
	const enabled = brandguide.validations.guide.sections_active(guide)
	const items = [] as section[]
	enabled.forEach((s) => {
		switch (true) {
			case s?.id === "colors":
				items.push({
					title: "Colors",
					number: items.length + 1,
					pages: ["Primary", "Secondary", "Usage"],
				} as section)
				break
			case s?.id === "logos":
				// eslint-disable-next-line no-case-declarations
				const c_pages = ["Primary Logo"]
				if (result_components.validations.logos.has_additional_logos(guide.guide?.logos!))
					c_pages.push("Alternate Marks")
				items.push({
					title: "Logos",
					number: items.length + 1,
					pages: [...c_pages, ...["Correct Usage", "Incorrect Usage"]],
				} as section)
				break
			case s?.id === "typography":
				// eslint-disable-next-line no-case-declarations
				const t_pages = ["Primary Font"]
				if (result_components.validations.typography.has_secondary_font(guide.guide?.typography!))
					t_pages.push("Secondary Font")
				if (result_components.validations.typography.has_alternative_fonts(guide.guide?.typography!))
					t_pages.push("Alternative Fonts")
				items.push({
					title: "Typography",
					number: items.length + 1,
					pages: [...t_pages, ...["Using Our Fonts"]],
				} as section)
				break
			case s?.id === "imagery":
				// eslint-disable-next-line no-case-declarations
				const i_pages = ["Fundamentals"]
				if (result_components.validations.imagery.has_lifestyle(guide.guide?.imagery!))
					i_pages.push("Lifestyle Photography")
				if (result_components.validations.imagery.has_product(guide.guide?.imagery!))
					i_pages.push("Product Photography")
				if (result_components.validations.imagery.has_illustration(guide.guide?.imagery!)) i_pages.push("Illustration")
				if ((guide.guide?.imagery?.misuse || []).length > 0) i_pages.push("Photography to Avoid")
				items.push({
					title: "Imagery",
					number: items.length + 1,
					pages: i_pages,
				} as section)
				break
			case s?.id === "tone_of_voice":
				// eslint-disable-next-line no-case-declarations
				const tov_pages = ["Tone of Voice"]
				if (result_components.validations.tov.has_writing_style(guide.guide?.tone_of_voice!))
					tov_pages.push("Writing Style")
				items.push({
					title: "Tone of Voice",
					number: items.length + 1,
					pages: tov_pages,
				} as section)
				break
			case s?.id === "archetype":
				items.push({
					title: "Archetype",
					number: items.length + 1,
					pages: ["Brand Archetypes"],
				} as section)
				break
			case s?.id === "compliance":
				items.push({
					title: "Compliance",
					number: items.length + 1,
					pages: ["Compliance Documentation"],
				} as section)
				break
			case s?.id === "application":
				items.push({
					title: "Application",
					number: items.length + 1,
					pages: ["Social Examples"],
				} as section)
				break

			default:
				break
		}
	})

	return items
}

import React, { useState, useEffect, useMemo } from "react"
import classnames from "classnames"
import JSZip from "jszip"
import * as uuid from "uuid"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as sessions from "sessions"
import * as brands from "brands"
import * as api from "brandguard/api"
import * as predictions from "brandguard/predictions"
import * as bgimages from "brandguard/display/brandguard/images"
import * as bglayouts from "brandguard/components/layouts"
import * as bgapprovals from "brandguard/display/approval"
import { UploadSimple } from "brandguard/components"
import * as authz from "authz"
import { useSettings } from "accounts/settings"
import { State } from "accounts/api"
import * as nav from "brandguard/components/navigation"
import * as scores from "brandguard/components/layouts/scores"
import { Scores, Debug } from "brandguard/display/brandguard/images"
import * as debugx from "x/debugx"
import * as icons from "icons"
import * as media from "media"
import * as bgscores from "brandguard/display/scores"
import * as typography from "typography"
import { CancellablePromise } from "real-cancellable-promise"

export default function Display(props: React.PropsWithChildren<unknown>): JSX.Element {
	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current
	const settings = useSettings()
	const bearertoken = sessions.useToken()
	const brand = brands.caching.useCached()
	const profile = sessions.useProfile()
	const mtoggle = layouts.modals.useToggle()

	const [loading, setLoading] = useState(true)
	const [selectedItem, setSelectedItem] = useState(undefined as api.ImageSearchResponseItem | undefined)
	const [checkedUploadItems, setCheckedUploadItems] = useState([] as api.ImageSearchResponseItem[])
	const [uploadFocused, setUploadFocused] = useState(false)
	const [filterFocused, setFilterFocused] = useState(false)

	const [undoItems, setUndoItems] = useState<api.ImageSearchResponseItem[]>([])

	const _searchReq = api.images.uploads.searches.zero({
		offset: 0n,
		limit: 20n,
		brand_id: brand.id,
		queues: [uuid.NIL, brand.id],
		approved: [api.Prediction.APPROVED, api.Prediction.INREVIEW, api.Prediction.REJECTED],
	})

	const [searchReq, setSearchReq] = useState(_searchReq)
	const [cursorKey, setCursorKey] = useState(uuid.v4())

	const [images, setImages] = useState({
		items: [],
		next: searchReq,
	} as api.ImageSearchResponse)

	useEffect(() => {
		if (searchReq.brand_id === uuid.NIL) return
		if (!loading) setLoading(true)
		const retry = httpx.autoretry()
		const p = retry
			.wrap(() => api.images.uploads.search(searchReq.brand_id, searchReq, bearertoken))
			.then(setImages)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((cause) => console.error("unable to search for brandguard approval images", cause))
			.finally(() => setLoading(false))
		return p.cancel
	}, [searchReq])

	useEffect(() => {
		if (!selectedItem) return
		mtoggle(
			<layouts.Flex styled flexDirection="column">
				<bgapprovals.ImagePreview
					item={selectedItem}
					flex="1"
					onClose={() => {
						mtoggle(undefined)
						setSelectedItem(undefined)
					}}
				>
					<typography.h3 fontWeight="700" fontSize="18px" lineHeight="30px" color="#4D5E80">
						Asset Scores
					</typography.h3>
					<Scores item={selectedItem} />
					<bgapprovals.status.default>
						<bgapprovals.status.Uploads
							item={selectedItem}
							previtemstate={undoItems.find((u) => u.media?.id === selectedItem.media?.id)}
							agree={agree}
							disagree={disagree}
							undo={undo}
							borderRadius="10px"
						/>
					</bgapprovals.status.default>
					<authz.Protected enabled={debugx.alpha.enabled()}>
						<bgscores.layouts.accordion.Container title="Debug Info" textAlign="initial">
							<Debug item={selectedItem} />
						</bgscores.layouts.accordion.Container>
					</authz.Protected>
					<layouts.containers.flex className="actions" flex="1" alignItems="flex-end">
						<authz.Protected enabled={permission.brandguard_upload}>
							<bgimages.MultiActions
								uploadItems={[selectedItem]}
								deleteAction={permission.brandguard_upload ? api.images.uploads.patch : undefined}
								onDelete={(results) => {
									setImages((prevState) => ({
										...prevState,
										items: prevState.items.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
									}))
									mtoggle(undefined)
									setSelectedItem(undefined)
								}}
							/>
						</authz.Protected>
					</layouts.containers.flex>
				</bgapprovals.ImagePreview>
			</layouts.Flex>,
		).then((e) => setSelectedItem(undefined))
	}, [selectedItem])

	const onImageUpdate = (item: api.ImageSearchResponseItem): void => {
		setImages((prevState) => ({
			...prevState,
			items: prevState.items.map((i) => (i.media?.id === item.media?.id ? item : i)),
		}))
	}

	const agree = async (
		item: api.ImageSearchResponseItem & { event: api.ImageEvent },
	): Promise<api.ImageSearchResponseItem> =>
		api.images.approval.disagree(item, authzc.bearer(authzaccount)).then((res_i) => {
			setUndoItems([...undoItems, ...[item]])
			setImages((prevState) => ({
				...prevState,
				items: prevState.items.map((i) => (i.media?.id === res_i.media?.id ? res_i : i)),
			}))
			if (selectedItem && selectedItem.media?.id === res_i.media?.id) setSelectedItem(res_i)
			return res_i
		})

	const disagree = (
		item: api.ImageSearchResponseItem & { event: api.ImageEvent },
	): Promise<api.ImageSearchResponseItem> =>
		api.images.uploads
			.patch(
				item.event.brand_id,
				item.event.id,
				{
					...item,
					media: { ...item.media!, tombstoned_at: new Date().toISOString() },
					event: { ...item.event, observation: api.Prediction.DISCARDED_BY_UPLOADER },
				},
				authzc.bearer(authzaccount),
			)
			.then((res_i) => {
				setUndoItems([...undoItems, ...[item]])
				setImages((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === res_i.media?.id ? res_i : i)),
				}))
				if (selectedItem && selectedItem.media?.id === res_i.media?.id) setSelectedItem(res_i)
				return res_i
			})

	const undo = (
		current: api.ImageSearchResponseItem & { event: api.ImageEvent },
		previously: api.ImageSearchResponseItem & { event: api.ImageEvent },
	): Promise<api.ImagePatchResponse> => {
		return api.images.uploads
			.patch(previously.event.brand_id, previously.event.id, previously, authzc.bearer(authzaccount))
			.then((resp_i) => {
				setUndoItems(undoItems.filter((i) => i.media?.id !== previously.media?.id))
				setImages((prevState) => ({
					...prevState,
					items: prevState.items.map((i) => (i.media?.id === previously.media?.id ? previously : i)),
				}))
				if (selectedItem && selectedItem.media?.id === previously.media?.id) setSelectedItem(previously)
				return resp_i
			})
			.then((upload) => {
				return api.images.approvallog
					.find(current.event.id, current.event.brand_id, bearertoken)
					.then((result) => {
						return api.images.approvallog
							.destroy(result.event!.brand_id, result.event!.id, authzc.bearer(authzaccount))
							.then(() => {
								return upload
							})
					})
					.catch(
						httpx.errors.notFound((cause) => {
							console.debug("unable to find approval images", cause)
							// ignore 404s
							return upload
						}),
					)
			})
	}

	const _allselected = images.items.length > 0 && checkedUploadItems.length === images.items.length

	const handleSelectAll = (): void => {
		if (_allselected) {
			setCheckedUploadItems([])
			return
		}
		setCheckedUploadItems(images.items)
	}

	const appchecked = searchReq.approved.length === 1 && searchReq.approved[0] === api.Prediction.APPROVED
	const rejchecked = searchReq.approved.length === 1 && searchReq.approved[0] === api.Prediction.REJECTED
	const fcnt = bgapprovals.CalculateFilterSCount(_searchReq, searchReq)

	const MemoUpload = useMemo(() => {
		return function MemodUpload(props: inputs.uploads.api.Props) {
			return (
				<authz.Protected
					enabled={permission.brandguard_upload}
					rejected={
						<layouts.containers.flex flex="1" className={classnames(bglayouts.uploads.navdenied)}>
							<authz.layouts.UploadDenied className="styled-area">
								You do not have permissions to upload new assets for scoring. Please contact an admin.
							</authz.layouts.UploadDenied>
						</layouts.containers.flex>
					}
				>
					<bgimages.uploads.default
						background={layouts.theme.colors.blue.blue}
						observation={api.Prediction.APPROVED}
						{...props}
					/>
				</authz.Protected>
			)
		}
	}, [])

	return (
		<layouts.containers.flex flexDirection="column-reverse" overflow="hidden">
			<layouts.pagination.Cursor
				key={cursorKey}
				my="auto"
				justifyContent="center"
				current={searchReq.offset}
				advance={Number(images.next!.offset) === -1 ? undefined : images.next?.offset}
				onChange={(next) => {
					setSearchReq({
						...searchReq,
						offset: next,
					})
				}}
			/>
			<layouts.loading.screen
				className="brandguard-working-area"
				loading={loading}
				flex="1"
				overflow="hidden"
				icon={<></>}
				flexDirection="row"
			>
				<layouts.containers.flex className="center-panel" flex="6" flexDirection="column">
					<layouts.containers.flex flexDirection="column" flex="1" minHeight="100px">
						<bglayouts.OverlaysContainer overflow="auto" background={layouts.theme.backgrounds.whitealpha80}>
							<layouts.containers.ResponsiveGrid gap={20} p="25px">
								{images.items.map((item) => {
									return (
										<bgapprovals.images.CardDisplay
											key={item.media?.id + undoItems.length.toString()}
											item={item}
											active={selectedItem && item?.media?.id === selectedItem.media?.id}
											onCardClick={() => {
												setSelectedItem(item)
											}}
											onChange={onImageUpdate}
											onChecked={(checked) =>
												setCheckedUploadItems((prevState) =>
													checked ? [...prevState, item] : prevState.filter((i) => i.media?.id !== item.media?.id),
												)
											}
											score={
												<authz.Protected enabled={settings.confidence !== State.DISABLED}>
													<layouts.containers.absolute>
														<scores.BGScoreImageSimple event={item.event!} />
													</layouts.containers.absolute>
												</authz.Protected>
											}
											panel={
												<bgapprovals.status.default>
													<bgapprovals.status.Uploads
														item={item}
														previtemstate={undoItems.find((u) => u.media?.id === item.media?.id)}
														agree={agree}
														disagree={disagree}
														undo={undo}
														borderRadius="0 0 10px 10px"
													/>
												</bgapprovals.status.default>
											}
											find={api.images.uploads.find}
											checked={checkedUploadItems.some((i) => i.media?.id === item.media?.id)}
										/>
									)
								})}
							</layouts.containers.ResponsiveGrid>
							<layouts.loading.pending loading={loading || images.next?.offset === searchReq.offset}>
								{images.items.length === 0 && <bgapprovals.layouts.ZeroState />}
							</layouts.loading.pending>
						</bglayouts.OverlaysContainer>
					</layouts.containers.flex>
				</layouts.containers.flex>
			</layouts.loading.screen>
			<layouts.containers.flex flexDirection="row" className={classnames(bgapprovals.layouts.styledactions)}>
				<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative" flex="1" mr="10px">
					<layouts.buttons.outline
						width="150px"
						height="40px"
						borderRadius="4px"
						onClick={() => {
							if (!uploadFocused && filterFocused) {
								setFilterFocused(false)
							}
							setUploadFocused(!uploadFocused)
						}}
					>
						{uploadFocused ? (
							<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
						) : (
							<icons.Plus height="20px" width="20px" stroke={layouts.theme.colors.blue.blue} />
						)}
						<layouts.Span px="10px">Upload Assets</layouts.Span>
					</layouts.buttons.outline>
					<layouts.containers.flex display={uploadFocused ? "flex" : "none"} position="absolute" top="100%" pt="5px">
						<UploadSimple
							className={classnames(nav.layouts.slideDownAnimation)}
							isProcessing={(i: api.ImageSearchResponseItem) => predictions.processing(i.event?.approved)}
							onChange={(img) => setImages((prevState) => ({ ...prevState, items: [...[img], ...prevState.items] }))}
							resume={() => {
								const searchReq = {
									offset: 0n,
									limit: 20n,
									uploaded_by: [profile.id],
									brand_id: brand.id,
									queues: [uuid.NIL, brand.id],
									approved: [api.Prediction.NONE],
								} as api.ImageSearchRequest

								return api.images.uploads.search(searchReq.brand_id, searchReq, bearertoken).then((imgs) => {
									return imgs.items.map(
										(i) =>
											({
												id: uuid.v4(),
												display: i.media?.description,
												background: layouts.theme.colors.blue.blue,
												data: i,
											} as inputs.uploads.api.uploaded<api.ImageSearchResponseItem>),
									)
								})
							}}
							refresh={(data) =>
								api.images.uploads.find(data.media?.id!, brand.id, bearertoken).then((resp) => {
									return resp.event?.approved === api.Prediction.ERROR
										? CancellablePromise.reject(resp)
										: CancellablePromise.resolve(resp)
								})
							}
							upload={(stats, content) => api.images.uploads.create(content, brand.id, authzc.bearer(authzaccount))}
							DropWell={MemoUpload}
							onClose={() => setUploadFocused(false)}
						/>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex flexDirection="column" mb="5px" zIndex="0" position="relative">
					<bgapprovals.layouts.FilterButton
						width="145px"
						height="40px"
						borderRadius="4px"
						onClick={() => {
							if (!filterFocused && uploadFocused) {
								setUploadFocused(false)
							}
							setFilterFocused(!filterFocused)
						}}
						className={fcnt > 0 ? "active" : ""}
					>
						<layouts.containers.flex flexDirection="row" justifyContent="center">
							<layouts.containers.box>
								<icons.bgapproval.filter />
							</layouts.containers.box>
							<layouts.containers.box px="10px">Filter{fcnt > 0 ? `/${fcnt}` : ""}</layouts.containers.box>
							<layouts.containers.box>
								{filterFocused ? <icons.bgapproval.arrowDown /> : <icons.bgapproval.arrowDown />}
							</layouts.containers.box>
						</layouts.containers.flex>
					</bgapprovals.layouts.FilterButton>
					<layouts.containers.flex display={filterFocused ? "flex" : "none"} position="absolute" top="100%" pt="5px">
						<bgapprovals.FilterDisplay
							onFilterClear={() => {
								setSearchReq(_searchReq)
								setCursorKey(uuid.v4())
							}}
							_searchReq={_searchReq}
							onFilterChange={(r) => {
								setSearchReq({ ...searchReq, ...r, ...{ offset: 0n } })
								setCursorKey(uuid.v4())
							}}
						>
							<bgapprovals.Checkbox
								description="Assets that were approved"
								checked={appchecked}
								onChange={() => {
									setSearchReq((prevState) => ({
										...prevState,
										approved: appchecked ? _searchReq.approved : [api.Prediction.APPROVED],
										offset: 0n,
									}))
									setCursorKey(uuid.v4())
								}}
							/>
							<bgapprovals.Checkbox
								description="Assets that were rejected"
								checked={rejchecked}
								onChange={() => {
									setSearchReq((prevState) => ({
										...prevState,
										approved: rejchecked ? _searchReq.approved : [api.Prediction.REJECTED],
										offset: 0n,
									}))
									setCursorKey(uuid.v4())
								}}
							/>
						</bgapprovals.FilterDisplay>
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex className="actions" justifyContent="flex-end" flex="10">
					<bgapprovals.layouts.SelectAll
						disabled={images.items.length === 0}
						cursor="pointer"
						className="select-all-action"
						onClick={handleSelectAll}
					>
						{_allselected ? "Unselect All" : "Select All"}
					</bgapprovals.layouts.SelectAll>
					<authz.Protected
						enabled={permission.brandguard_upload && checkedUploadItems.length > 0}
						rejected={
							<>
								<layouts.containers.flex className="download-action">
									<icons.brandguard.download />
								</layouts.containers.flex>
								<layouts.containers.flex className="remove-action">
									<icons.brandguard.remove />
								</layouts.containers.flex>
							</>
						}
					>
						<layouts.containers.flex
							className="download-action"
							cursor="pointer"
							onClick={() => {
								const zip = new JSZip()
								const promises: Promise<api.ImagePatchRequest | JSZip>[] = []
								checkedUploadItems.forEach((i) => {
									promises.push(
										media.cache.current(authzaccount.current.id, i.media!.md5, bearertoken).then((url) => {
											return fetch(url)
												.then((response) => {
													return response.arrayBuffer()
												})
												.then((imageBuffer) => {
													const imageName = media.downloads.filename(i.media!.md5, i.media!.mimetype)
													return zip.file(imageName, imageBuffer)
												})
										}),
									)
								})
								Promise.all(promises).then(() => {
									zip.generateAsync({ type: "blob" }).then((content) => {
										const downloadLink = document.createElement("a")
										downloadLink.href = URL.createObjectURL(content)
										downloadLink.download = "brandguard-assets.zip"
										downloadLink.click()
									})
								})
							}}
						>
							<icons.brandguard.download />
						</layouts.containers.flex>
						<layouts.containers.flex
							className="remove-action"
							cursor="pointer"
							onClick={() => {
								const promises: Promise<api.ImagePatchRequest>[] = []
								checkedUploadItems.forEach((i) => {
									i.media!.tombstoned_at = new Date().toISOString()
									promises.push(
										api.images.uploads.patch(i.media!.brand_id, i.media!.id, i, authzc.bearer(authzaccount)),
									)
								})
								Promise.all(promises)
									.then((results) => {
										setCheckedUploadItems((prevState) =>
											prevState.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
										)
										setImages((prevState) => ({
											...prevState,
											items: prevState.items.filter((i) => !results.map((el) => el.media!.id).includes(i.media!.id)),
										}))
									})
									.catch((cause) => console.error("unable to display brandguard approval images", cause))
									.finally(() => {
										setSearchReq((prevState) => ({ ...prevState, offset: 0n }))
										setCursorKey(uuid.v4())
									})
							}}
						>
							<icons.brandguard.remove />
						</layouts.containers.flex>
					</authz.Protected>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

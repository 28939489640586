import * as icons from "icons"
import * as containers from "../containers"
import * as theme from "../theme"

export interface Props extends containers.ContainerProps {
	loading: boolean
	icon?: JSX.Element
}

export const defaults = {
	opacity: "40%",
	icon: (
		<icons.loading.Ring1
			m="auto"
			height="50%"
			maxWidth="80px"
			maxHeight="80px"
			foreground={theme.colors.black.light}
			color={theme.colors.hinting.lightest}
			opacity="40%"
		/>
	),
}

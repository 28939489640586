import { useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"
import { css } from "@emotion/css"

const styledHover = css`
	:hover {
		background-color: ${layouts.theme.colors.grey.dark50alpha05};
	}
`

type title =
	| "Colors"
	| "Logos"
	| "Typography"
	| "Imagery"
	| "Tone of Voice"
	| "Application"
	| "Archetype"
	| "Compliance"

interface sectionProps extends layouts.containers.ContainerProps {
	title: title
	steps: string[]
	onChange(s: number): void
}

export function Section(props: sectionProps): JSX.Element {
	const { title, steps, onChange, ...rest } = props
	const [focused, setFocused] = useState<boolean>(false)
	return (
		<layouts.Flex onClick={() => setFocused(!focused)} {...rest}>
			<typography.h5 fontSize="16px" fontWeight="400" color={layouts.theme.colors.grey.dark50} className="no-print">
				Edit
			</typography.h5>
			{focused && (
				<layouts.Flex
					position="absolute"
					flexDirection="column"
					background={layouts.theme.colors.white}
					bottom="0"
					borderRadius="5px"
					border={`1px solid ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 14)}`}
					boxShadow={`0px 3px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 15)}`}
					p="5px"
				>
					<layouts.Flex justifyContent="end" onClick={() => setFocused(false)}>
						<icons.Close width="8pt" height="8pt" fill={layouts.theme.colors.grey.dark50alpha80} />
					</layouts.Flex>
					<layouts.Flex px="15px">
						<typography.h6 fontSize="12pt" fontWeight="600" color={layouts.theme.colors.grey.dark50alpha80}>
							{title}
						</typography.h6>
					</layouts.Flex>

					<layouts.Flex flexDirection="column" position="relative" p="15px">
						{steps.map((s, i) => {
							return (
								<layouts.Flex
									key={s}
									onClick={() => onChange(i)}
									zIndex={1}
									alignItems="center"
									minHeight="30px"
									className={styledHover}
								>
									<icons.brandguide.arrows.SmallRight />
									<typography.h6
										fontWeight="400"
										fontSize="11pt"
										pl="10px"
										lineHeight="12pt"
										color={layouts.theme.colors.grey.dark50alpha80}
									>
										{s}
									</typography.h6>
								</layouts.Flex>
							)
						})}
					</layouts.Flex>
				</layouts.Flex>
			)}
		</layouts.Flex>
	)
}

Section.defaultProps = {
	flex: "1",
	justifyContent: "end",
	position: "relative",
}

import React, { useContext, useEffect } from "react"
import classnames from "classnames"
import * as layouts from "layouts"
import * as context from "./context"

interface props extends layouts.containers.FlexProps {
	enabled: boolean
}

export default function Screen(props: React.PropsWithChildren<props>): JSX.Element {
	const { display, children, className, enabled, ...rest } = props

	const { increment } = useContext(context.Context)

	useEffect(() => {
		increment(enabled ? 1 : -1)
	}, [enabled, increment])

	return (
		<layouts.containers.absolute
			className={classnames("overlay screen", className)}
			width="100%"
			height="100%"
			top="0px"
			{...rest}
			display={enabled ? display : "none"}
		>
			{children}
		</layouts.containers.absolute>
	)
}

Screen.defaultProps = {
	className: "",
	styled: false,
	display: "flex",
}

import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import * as timex from "timex"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as icons from "icons"
import * as api from "./api"
import * as typography from "typography"
import * as routing from "./routing"
import { css } from "@emotion/css"
import * as debugx from "x/debugx"
import * as brandguard from "brandguard"
import * as metrics from "metrics"
import * as training from "brandguard/display/training"
import * as authz from "authz"
import * as metaAuthz from "meta/authz"
import classnames from "classnames"

const cardHover = css`
	&:hover {
		outline: 0.1rem solid;
		outline-color: ${layouts.theme.colors.blue.blue};
	}
`

const iconHover = css`
	&:hover {
		box-shadow: ${layouts.theme.boxshadow.default};
	}
`

const textWithOverflow = css`
	overflow: hidden;
	text-overflow: ellipsis;
`

const readOnly = css`
	pointer-events: none;
	opacity: 0.6;
	animation: ro_opacity 1.5s infinite;
	@keyframes ro_opacity {
		50% {
			opacity: 0.8;
			box-shadow: 0px 2px 2px ${layouts.theme.colors.success};
		}
	}
`

interface props extends layouts.containers.ContainerProps {
	brand: api.Brand
	permission: metaAuthz.Token
	editBrand(): void
	setBrand(brand: api.Brand): void
}

export default function BrandCard(props: props): JSX.Element {
	const { brand, permission, editBrand, setBrand, ...rest } = props
	const bearertoken = sessions.useToken()
	const cloning = timex.utc() < timex.from.iso(brand.cloned_at)

	useEffect(() => {
		if (!cloning) return

		let cancelled = false
		const tid = setTimeout(() => {
			api
				.get(brand.id, bearertoken)
				.then((result) => {
					cancelled || setBrand(result.brand)
				})
				.catch((cause) => {
					console.error("unable to refresh brandguard approval text display", cause)
					cancelled || setBrand({ ...brand })
				})
		}, timex.backoff.noise(timex.duration.seconds(5)).toMillis())

		return () => {
			clearTimeout(tid)
			cancelled = true
		}
	}, [brand])

	return (
		<layouts.Flex className={classnames(cardHover, cloning ? readOnly : null)} {...rest}>
			<layouts.Flex flexDirection="column">
				<Link
					to={
						permission.brandguide_view || permission.brandguide_edit
							? routing.brandguide(brand.id)
							: routing.brandguard(brand.id)
					}
					style={{ textDecoration: "none" }}
				>
					<typography.h2
						color={layouts.theme.colors.grey.dark50}
						textAlign="left"
						title={brand.description}
						fontSize="16px"
						fontWeight="500"
						className={textWithOverflow}
					>
						{brand.description}
					</typography.h2>
					<typography.h4
						color={layouts.theme.colors.blue.blue}
						textAlign="left"
						title={brand.domain}
						fontSize="12px"
						fontWeight="400"
						className={textWithOverflow}
					>
						<layouts.Flex justifyContent="space-between">
							{brand.domain}
							{cloning && (
								<layouts.Flex display="inline-block" color={layouts.theme.colors.grey.dark50}>
									[cloning]
								</layouts.Flex>
							)}
						</layouts.Flex>
					</typography.h4>
				</Link>
			</layouts.Flex>
			<layouts.Flex mt="5px" borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha20}`} />
			<layouts.Flex justifyContent="space-evenly" pt="5px" gridGap="10px">
				{permission.brandguard_train && (
					<Link to={training.routing.image.upload(brand.id)}>
						<layouts.Flex className={iconHover} p="5px" title="Asset Training">
							<icons.AssetsTraining width="14px" height="14px" />
						</layouts.Flex>
					</Link>
				)}
				<Link to={brandguard.routing.image.score(brand.id)}>
					<layouts.Flex className={iconHover} p="5px" title="BrandGuard">
						<icons.Brandguard width="14px" height="14px" />
					</layouts.Flex>
				</Link>
				<authz.Protected enabled={permission.brandgpt_use}>
					<Link to={routing.brandgpt(brand.id)}>
						<layouts.Flex className={iconHover} p="5px" title="BrandGPT">
							<icons.BrandGPT width="14px" height="14px" />
						</layouts.Flex>
					</Link>
				</authz.Protected>
				{debugx.alpha.enabled() && (
					<Link to={metrics.routing.metrics_approvals(brand.id)}>
						<layouts.Flex className={iconHover} p="5px" title="Metrics">
							<icons.Metrics width="14px" height="14px" fill={layouts.theme.colors.grey.dark50} />
						</layouts.Flex>
					</Link>
				)}
				<layouts.overlays.Inline enabled={permission.usermanagement}>
					<layouts.containers.flex className={iconHover} p="5px" title="Edit Brand" onClick={() => editBrand()}>
						<icons.Pencil width="17px" height="17px" />
					</layouts.containers.flex>
				</layouts.overlays.Inline>
			</layouts.Flex>
		</layouts.Flex>
	)
}

BrandCard.defaultProps = {
	px: "25px",
	pt: "20px",
	width: "226px",
	height: "100px",
	background: layouts.theme.colors.white,
	borderRadius: "10px",
	border: `0.5px solid ${layouts.theme.colors.grey.light50}`,
	boxShadow: layouts.theme.boxshadow.black.medium,
	flexDirection: "column",
}

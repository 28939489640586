import * as brandguide from "brandguide"
import { SectionTitle } from "brandguide/results/components"

import { default as Primary } from "./primary"
import { default as Secondary } from "./secondary"
import { default as Alternatives } from "./alternatives"
import { default as Usage } from "./usage"

export { default as Primary } from "./primary"
export { default as Secondary } from "./secondary"
export { default as Alternatives } from "./alternatives"
export { default as Usage } from "./usage"

export function Section(): JSX.Element {
	return (
		<>
			<SectionTitle title="Typography" className="section-print" />
			<Primary className="section-print" />
			<Secondary className="section-print" />
			<Alternatives className="section-print" />
			<Usage className="section-print" />
		</>
	)
}

export function getAlphabetArray(): string[] {
	let alphabetArray: string[] = []
	for (let i = 65; i <= 90; i++) {
		let letter = String.fromCharCode(i)
		alphabetArray.push(letter + letter.toLowerCase())
	}
	return alphabetArray
}

export const getNumberSeries = Array.from({ length: 10 }, (_, i) => i)

export const fontFamily = (font: brandguide.api.FontObject) => `${font?.font_family}_${font?.id}`

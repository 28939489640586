import React, { useContext, useState } from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import { css } from "@emotion/css"
import { DateTime } from "luxon"
import * as inputs from "inputs"
import * as profiles from "profiles"
import * as icons from "icons"
import * as sessions from "sessions"
import * as authzc from "authzcached"
import * as authz from "authz"
import * as meta from "meta"
import * as typography from "typography"

export const ModalContainer = styled(layouts.containers.flex)<{ outerBackground: string }>`
	width: 430px;
	flex-direction: column;
	background: ${layouts.theme.colors.white};
	border-radius: 10px;
	box-shadow: ${layouts.theme.boxshadow.black.wide};
`

export const StyledInput = styled.input`
	border: none;
	min-width: 100px;
	outline: none;
	width: 100%;
	min-width: 100px;
	border-radius: 23px;
	border: ${layouts.theme.borders.grey.dark50alphamedium1px};
	height: 38px;
	padding-right: 10px;
	padding-left: 10px;
`

const styledselect = css`
	border: none;
	min-width: 80px;
	outline: none;
	color: ${layouts.theme.colors.grey.dark50alpha80};
`

export const StyledDatePicker = css`
	.react-datepicker-wrapper {
		padding-left: 10px;
		.react-datepicker__input-container {
			height: 100%;

			input {
				outline: none;
				border: none;
				height: 100%;
			}
		}
	}
`

export function StatusSelect(props: { val: number; onSelect(s: number): void }): JSX.Element {
	const { val, onSelect } = props
	return (
		<layouts.Flex width="inherit" display="inline" pr="15px">
			<inputs.Select
				value={val}
				onChange={(evt) => onSelect(parseInt(evt.currentTarget.value))}
				className={styledselect}
			>
				<option value={profiles.api.StatusFilters.Enabled}>Enabled</option>
				<option value={profiles.api.StatusFilters.Disabled}>Disabled</option>
				<option value={profiles.api.StatusFilters.All}>All</option>
			</inputs.Select>
		</layouts.Flex>
	)
}

function AccordionProfileHead(
	props: React.PropsWithChildren<
		{ description: string; email: string; created: string; updated: string } & layouts.accordions.props &
			layouts.containers.FlexProps
	>,
): JSX.Element {
	const { open, toggle } = useContext(layouts.accordions.AccordionContext)
	const { description, email, updated, created, children, ...rest } = { ...props }
	const permission = authzc.useCache((cached) => cached.meta.current)

	return (
		<layouts.accordions.AccordionProvider value={{ open, toggle }}>
			<layouts.containers.flex
				flexDirection="row"
				fontSize="14px"
				lineHeight="20px"
				color={layouts.theme.colors.grey.dark50}
				{...rest}
			>
				<layouts.containers.flex flex="3" p="10px" flexDirection="column">
					{description}
					<authz.Protected enabled={permission.usermanagement}>
						<layouts.Flex color={layouts.theme.colors.grey.dark50alpha40}>{email}</layouts.Flex>
					</authz.Protected>
				</layouts.containers.flex>

				<layouts.containers.flex flex="1" p="10px" justifyContent="center" alignItems="center">
					{DateTime.fromISO(created).toLocaleString(DateTime.DATE_MED)}
				</layouts.containers.flex>

				<layouts.containers.flex flex="1" p="10px" justifyContent="center" alignItems="center">
					{DateTime.fromISO(updated).toLocaleString(DateTime.DATE_MED)}
				</layouts.containers.flex>
				<authz.Protected enabled={permission.usermanagement}>
					<layouts.containers.flex
						flex="1"
						p="10px"
						justifyContent="center"
						alignItems="center"
						onClick={() => toggle(!open)}
					>
						{open ? <icons.accordions.Opened /> : <icons.accordions.Closed />}
					</layouts.containers.flex>
				</authz.Protected>
			</layouts.containers.flex>
			{children}
		</layouts.accordions.AccordionProvider>
	)
}

export function AccordionProfileContainer(
	props: React.PropsWithChildren<{ profile: profiles.Profile } & layouts.containers.FlexProps>,
): JSX.Element {
	const { profile, children, ...rest } = props

	return (
		<layouts.accordions.Container
			minHeight="unset"
			padding="unset"
			flexDirection="column"
			borderBottom={layouts.theme.borders.grey.dark50alphamedium1px}
			m="unset"
			{...rest}
		>
			<layouts.containers.box
				tabIndex={0}
				className="accordion-containers-box"
				background={layouts.theme.colors.grey.bg}
			>
				<AccordionProfileHead
					description={profile.display}
					email={profile.email}
					created={profile.created_at}
					updated={profile.updated_at}
				>
					<layouts.accordions.Content>
						<layouts.containers.flex flexDirection="column" width="100%" flex="1">
							{children}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</AccordionProfileHead>
			</layouts.containers.box>
		</layouts.accordions.Container>
	)
}

export const Input = styled.input`
	outline: none;
	width: 100%;
	min-width: 100px;
	font-weight: 600;
	color: ${layouts.theme.colors.grey.dark50};
	background: ${layouts.theme.colors.white};
	border: ${layouts.theme.borders.grey.dark50alphamedium1px};
	padding-left: 10px;
	padding-right: 10px;
`

export function EditProfile(props: {
	readonly?: boolean
	current: profiles.Profile
	onRemove(): void
	onChange(upd: profiles.Profile): Promise<profiles.Profile>
}): JSX.Element {
	const { current, readonly: _readonly, onChange, onRemove } = props
	const session = sessions.useSession()
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const permission = metaauthz.current
	const readonly = _readonly || !permission.usermanagement
	const readonly_login = readonly || current.id === session.profile.id
	const grant = current.id === session.profile.id ? meta.authz.grant_self : meta.authz.grant
	const disabled_remove = current.id === session.profile.id || !profiles.enabled(current)

	const { open, toggle } = useContext(layouts.accordions.AccordionContext)
	const mtoggle = layouts.modals.useToggle()
	const [upd, setUpd] = useState(current)

	return (
		<>
			<layouts.Flex flexDirection="row" width="100%" py="5px">
				<layouts.Flex flex="1" mr="10px">
					<layouts.containers.flex pr="10px">Name:</layouts.containers.flex>
					<Input
						required
						value={upd.display}
						onChange={(evt) => setUpd((prevState) => ({ ...prevState, display: evt.target.value }))}
					/>
				</layouts.Flex>
				<layouts.Flex flex="1" ml="10px">
					<layouts.containers.flex pr="10px">Email:</layouts.containers.flex>
					<Input
						disabled
						type="email"
						required
						value={upd.email}
						onChange={(evt) => setUpd((prevState) => ({ ...prevState, email: evt.target.value }))}
					/>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex my="20px">Permissions:</layouts.Flex>
			<layouts.containers.grid
				width="100%"
				justifyContent="center"
				display="grid"
				gridGap="10px"
				gridTemplateColumns="repeat(3, 1fr);"
				gridTemplateRows="min-content"
				px="10px"
			>
				<profiles.DisableToggle current={current} readonly={readonly_login} onChange={onChange} />
				<meta.authz.Modify
					flex="1"
					readonly={readonly}
					current={current}
					onChange={async (upd: meta.authz.Token) => {
						return grant(
							{
								authorization: {
									brandguard_upload: upd.brandguard_upload,
									brandguard_review: upd.brandguard_review,
									brandguard_train: upd.brandguard_train,
									adpool_management: upd.adpool_management,
									usermanagement: upd.usermanagement,
									brandgpt_use: upd.brandgpt_use,
									brandguide_view: upd.brandguide_view,
									brandguide_edit: upd.brandguide_edit,
								},
								profile_id: current.id,
							},
							authzc.bearer(metaauthz),
						).then((r) => ({
							...upd,
							...meta.authz.tokens.authorizationFromGrant(r),
						}))
					}}
				/>
			</layouts.containers.grid>
			<layouts.containers.flex flexDirection="row" justifyContent="flex-end" width="100%" gridGap="10px" my="20px">
				<layouts.buttons.outline
					borderRadius="33px"
					disabled={disabled_remove}
					onClick={() =>
						mtoggle(
							<ModalContainer outerBackground={layouts.theme.backgrounds.blackalpha75}>
								<UserRemoveModal
									dismiss={() => mtoggle(undefined)}
									accept={() => {
										onRemove()
										mtoggle(undefined)
										toggle(!open)
									}}
								/>
								,
							</ModalContainer>,
						)
					}
					minWidth="85px"
				>
					Remove User
				</layouts.buttons.outline>
				<layouts.buttons.primary
					borderRadius="33px"
					onClick={() => {
						onChange(upd)
						toggle(!open)
					}}
					minWidth="85px"
				>
					Save
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</>
	)
}

export function PendingRevokeModal(props: { dismiss(): void; accept(): void }): JSX.Element {
	const { dismiss, accept } = props
	return (
		<>
			<layouts.containers.flex p="10px" justifyContent="flex-end" alignItems="flex-start">
				<layouts.containers.flex onClick={dismiss}>
					<icons.Close width="15px" height="15px" fill={layouts.theme.colors.grey.dark50} fillOpacity="0.6" p="5px" />
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="column" px="50px" justifyContent="flex-start" alignItems="flex-start">
				<typography.h3 fontSize="16px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
					Revoke User
				</typography.h3>
				<typography.h5
					fontSize="14px"
					lineHeight="20px"
					fontWeight="400"
					color={layouts.theme.colors.grey.dark50}
					whiteSpace="unset"
				>
					Revoking an invite will no longer allow this person to become a user inside this application. You can always
					invite them again in you change your mind.
				</typography.h5>
			</layouts.containers.flex>
			<layouts.containers.flex p="25px" justifyContent="flex-end" gridGap="10px">
				<layouts.buttons.outline color={layouts.theme.colors.blue.bluedarkness} borderRadius="39px" onClick={dismiss}>
					Keep Invite
				</layouts.buttons.outline>
				<layouts.buttons.danger borderRadius="39px" background={layouts.theme.colors.red.cinnabar} onClick={accept}>
					Revoke User
				</layouts.buttons.danger>
			</layouts.containers.flex>
		</>
	)
}

export function UserRemoveModal(props: { dismiss(): void; accept(): void }): JSX.Element {
	const { dismiss, accept } = props
	return (
		<>
			<layouts.containers.flex p="10px" justifyContent="flex-end" alignItems="flex-start">
				<layouts.containers.flex onClick={dismiss}>
					<icons.Close width="15px" height="15px" fill={layouts.theme.colors.grey.dark50} fillOpacity="0.6" p="5px" />
				</layouts.containers.flex>
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="column" px="50px" justifyContent="flex-start" alignItems="flex-start">
				<typography.h3 fontSize="16px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
					Remove User
				</typography.h3>
				<typography.h5
					fontSize="14px"
					lineHeight="20px"
					fontWeight="400"
					color={layouts.theme.colors.grey.dark50}
					whiteSpace="unset"
				>
					You are about to remove a user which will not allow this person to login into this application anymore.
				</typography.h5>
			</layouts.containers.flex>
			<layouts.containers.flex p="25px" justifyContent="flex-end" gridGap="10px">
				<layouts.buttons.outline color={layouts.theme.colors.blue.bluedarkness} borderRadius="39px" onClick={dismiss}>
					Keep User
				</layouts.buttons.outline>
				<layouts.buttons.danger borderRadius="39px" background={layouts.theme.colors.red.cinnabar} onClick={accept}>
					Remove User
				</layouts.buttons.danger>
			</layouts.containers.flex>
		</>
	)
}

import React from "react"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as icons from "icons"
import { css } from "@emotion/css"
import classnames from "classnames"
export * as uploads from "./uploads"

export namespace fileDetails {
	export const AccordionHeader = styled.span<layouts.containers.FlexProps>`
		font-weight: 500;
		font-size: 12pt;
		line-height: 20px;
		letter-spacing: 0.5px;
		color: ${layouts.theme.colors.grey.light40};
		padding-left: 20px;
	`

	export const InformationGroup = styled(layouts.containers.flex)`
		padding-top: 20px;
		padding-bottom: 30px;
		flex-direction: column;
		&:not(:last-of-type) {
			::after {
				content: "";
				width: 96%;
				border-bottom: 2px solid ${layouts.theme.colors.grey.light20};
				margin: 0 auto;
				padding: 15px 0px;
			}
		}
		&.extension {
			width: 100%;
		}
	`

	export const InformationGroupTitle = styled(layouts.containers.span)`
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 12pt;
		line-height: 20px;
		color: ${layouts.theme.colors.grey.light40};
	`

	export const ScoreBoxContainerStyle = styled(layouts.containers.flex)`
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
	`

	export const ScoreBoxStyle = styled(layouts.containers.flex)<{ backgroundColor?: string; large?: boolean }>`
		justify-content: center;
		align-items: center;
		min-width: ${(props) => (props.large ? "38px" : "25px")};
		padding: 0 5px;
		height: ${(props) => (props.large ? "27px" : "23px")};
		border-radius: 5px;
		font-size: ${(props) => (props.large ? "16px" : "13px")};
		font-weight: 800;
		line-height: ${(props) => (props.large ? "27px" : "23px")};
		color: ${layouts.theme.colors.white};
		background-color: ${(props) => props.backgroundColor || layouts.theme.colors.blue.blue};
	`

	export const ScoreBoxTitle = styled(layouts.containers.flex)<{ large?: boolean }>`
		font-weight: 600;
		font-size: ${(props) => (props.large ? "11pt" : "10pt")};
		line-height: 11px;
		color: ${(props) => (props.large ? layouts.theme.colors.grey.dark3 : layouts.theme.colors.grey.dark10)};
	`

	interface scoreBoxProps {
		originalScore?: number
		score?: string
		title?: string
		large?: boolean
		inverse?: boolean
	}

	function ScoreBoxGood(props: scoreBoxProps): JSX.Element {
		const { score, title, large } = props
		return (
			<ScoreBoxContainerStyle>
				{title && <ScoreBoxTitle large={large}>{title}</ScoreBoxTitle>}
				<ScoreBoxStyle large={large}>{score || <icons.marks.check />}</ScoreBoxStyle>
			</ScoreBoxContainerStyle>
		)
	}

	function ScoreBoxAverage(props: scoreBoxProps): JSX.Element {
		const { score, title, large } = props
		return (
			<ScoreBoxContainerStyle>
				{title && <ScoreBoxTitle large={large}>{title}</ScoreBoxTitle>}
				<ScoreBoxStyle backgroundColor={layouts.theme.colors.red.mandarin} large={large}>
					{score || <icons.marks.x />}
				</ScoreBoxStyle>
			</ScoreBoxContainerStyle>
		)
	}

	function ScoreBoxBad(props: scoreBoxProps): JSX.Element {
		const { score, title, large } = props
		return (
			<ScoreBoxContainerStyle>
				{title && <ScoreBoxTitle large={large}>{title}</ScoreBoxTitle>}
				<ScoreBoxStyle backgroundColor={layouts.theme.colors.red.cinnabar} large={large}>
					{score || <icons.marks.x />}
				</ScoreBoxStyle>
			</ScoreBoxContainerStyle>
		)
	}

	function ScoreBoxNA(props: scoreBoxProps): JSX.Element {
		const { score, title, large } = props
		return (
			<ScoreBoxContainerStyle>
				{title && <ScoreBoxTitle large={large}>{title}</ScoreBoxTitle>}
				<ScoreBoxStyle backgroundColor={layouts.theme.colors.grey.dark10} large={large}>
					{score || <icons.marks.x />}
				</ScoreBoxStyle>
			</ScoreBoxContainerStyle>
		)
	}

	// weights for score board
	// > 75% good
	// 35 - 74% average
	// < 35% is bad
	export function ScoreBox(props: scoreBoxProps): JSX.Element {
		const { originalScore, inverse, ...rest } = props
		const scorePercent = Math.round(originalScore! * 100)
		if (scorePercent === -100) return <ScoreBoxNA score={"N/A"} {...rest} />

		const weight = inverse ? Math.round((1 - originalScore!) * 100) : Math.round(originalScore! * 100)
		if (weight >= 75) return <ScoreBoxGood score={`${scorePercent}%`} {...rest} />
		if (weight >= 35) return <ScoreBoxAverage score={`${scorePercent}%`} {...rest} />
		return <ScoreBoxBad score={`${scorePercent}%`} {...rest} />
	}
}

export const RemoveBtn = styled(layouts.buttons.unstyled)`
	mx: 5px;
	width: 150pt;
	height: 34pt;
	border-radius: 37px;
	padding: 0px;
	cursor: pointer;
	font-size: 14px;
	background: ${layouts.theme.colors.grey.dark10};
	color: ${layouts.theme.colors.white};
`

export const Card = styled(layouts.containers.flex)`
	position: relative;
	box-shadow: "unset";
	font-weight: 400;
	font-size: 12pt;
	line-height: 16px;
	width: 100%;
	color: ${layouts.theme.colors.black.lightest};
	&.active {
		outline: ${`2px solid ${layouts.theme.colors.blue.blue}`};
		box-shadow: ${layouts.theme.boxshadow.black.wide};
	}
	&.extension {
		&:not(.recent-image) {
			background: ${layouts.theme.extension.colors.white.alpha13};
		}
		img {
			margin-top: unset;
		}
		&.active {
			outline: ${`2px solid ${layouts.theme.extension.colors.blue.text_secondary}`};
		}
	}
`

Card.defaultProps = {
	// align center by default
	marginBottom: "auto",
	marginTop: "auto",
}

export const CardTextContent = styled(layouts.containers.flex)`
	padding: 58px;
	font-weight: 400;
	font-size: 12pt;
	line-height: 16pt;
	text-align: left;
	color: ${layouts.theme.colors.black.lightest};
	word-break: break-word;
	&.extension {
		height: 62px;
		padding: 20px 10px 10px;
		background: ${layouts.theme.extension.colors.blue.text_background};
		color: ${layouts.theme.extension.colors.black.text};
		text-align: left;
		font-size: 11px;
		font-weight: 400;
		line-height: 160.687%; /* 17.676px */
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
		white-space: pre-wrap;
		&.current-asset {
			height: 155px;
			overflow-y: auto;
		}
		&.eligible {
			width: 100%;
			height: auto;
			min-height: 36px;
			max-height: 62px;
		}
	}
`

export const StyledBtn = styled(layouts.buttons.outline)`
	background: ${layouts.theme.colors.white};
	border: 1px solid ${layouts.theme.colors.grey.light20};
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
	min-height: 48px;
	border-radius: 48px;
	font-size: 0.8em;
	&:hover {
		border: 1px solid ${layouts.theme.colors.blue.blue};
		background: ${layouts.theme.colors.white};
		color: ${layouts.theme.colors.blue.blue};
		box-shadow: 2px 2px 4px ${layouts.theme.colors.blue.blue}7E;
	}
`

export function TrashIcon(props: React.PropsWithChildren<unknown>): JSX.Element {
	const { children } = props
	return (
		<layouts.containers.flex justifyContent="center" alignItems="center">
			<icons.Trash
				fill={layouts.theme.colors.grey.dark10}
				stroke={layouts.theme.colors.white}
				width="15pt"
				height="15pt"
				mr="6px"
			/>
			{children}
		</layouts.containers.flex>
	)
}

export const styledscroll = css`
	scrollbar-width: thin;
	scrollbar-gutter: stable;
	::-webkit-scrollbar {
		width: 8px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 11px;
		background: ${layouts.theme.colors.grey.light50};
	}
`

export function OverlaysContainer(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { children, ...rest } = props
	return (
		<layouts.overlays.Container
			height="100vh"
			background={layouts.theme.colors.white}
			borderRadius="10px"
			flexDirection="column"
			textAlign="center"
			overflowY="auto"
			className={classnames(styledscroll)}
			{...rest}
		>
			{children}
		</layouts.overlays.Container>
	)
}

export function DisplayActions(
	props: React.PropsWithChildren<{
		cnt: number
		canDelete: boolean
		handleDelete(): void
		handleDownload(): void
	}>,
): JSX.Element {
	const { cnt, canDelete, handleDelete, handleDownload, children } = props
	return (
		<layouts.containers.flex
			minHeight="100px"
			flexDirection="column"
			mx="10px"
			mt="-10px"
			borderRadius="10px"
			className="actions"
			alignItems="center"
			background={layouts.theme.colors.white}
			p="10px"
			flex="1"
		>
			<layouts.containers.flex
				className="selected-assets-description"
				flexDirection="row"
				color={layouts.theme.colors.grey.dark50}
				fontSize="10pt"
				p="10px"
			>
				{cnt} {`Item${cnt !== 1 ? "s" : ""}`} Selected
			</layouts.containers.flex>
			<layouts.containers.flex flexDirection="row" mt="10px" className="actions-container">
				<layouts.containers.flex mr="10px" title="Download" onClick={handleDownload} className="download-action">
					<icons.brandguard.download />
				</layouts.containers.flex>
				{canDelete && (
					<layouts.containers.flex title="Remove" onClick={handleDelete} className="remove-action">
						<icons.brandguard.remove />
					</layouts.containers.flex>
				)}
				{children}
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

import * as uuid from "uuid"
import { Prediction } from "brandguard/brandguard.approval_pb"
import { TextEvent } from "brandguard/brandguard.text_pb"

export * as uploads from "./uploads"
export * as training from "./training"
export * as approval from "./approval"
export * as approvallog from "./approvallog"
export * as reviewable from "./reviewable"
export * as kernel from "./kernel"

export function EventZero(te: Partial<TextEvent> = {}): TextEvent {
	return {
		id: uuid.NIL,
		account_id: uuid.NIL,
		brand_id: uuid.NIL,
		approved: Prediction.NONE,
		observation: Prediction.NONE,
		queue: "",
		confidence: 0.0,
		onbrand: 0.0,
		grammar: 0.0,
		profanity: 0.0,
		racism: 0.0,
		text_tone_and_voice: 0.0,
		text_admiration: 0.0,
		text_amusement: 0.0,
		text_anger: 0.0,
		text_annoyance: 0.0,
		text_approval: 0.0,
		text_caring: 0.0,
		text_confusion: 0.0,
		text_curiosity: 0.0,
		text_desire: 0.0,
		text_disappointment: 0.0,
		text_disapproval: 0.0,
		text_disgust: 0.0,
		text_embarrassment: 0.0,
		text_excitement: 0.0,
		text_fear: 0.0,
		text_gratitude: 0.0,
		text_grief: 0.0,
		text_joy: 0.0,
		text_love: 0.0,
		text_nervousness: 0.0,
		text_neutral: 0.0,
		text_optimism: 0.0,
		text_pride: 0.0,
		text_realization: 0.0,
		text_relief: 0.0,
		text_remorse: 0.0,
		text_sadness: 0.0,
		text_surprise: 0.0,
		text_reference_rules: 0.0,
		updated_at: "",
		...te,
	}
}

import * as svg from "./svg"
import * as theme from "layouts/theme"

export const Opened = (props: svg.SVGProps) => {
	const { fill, ...rest } = props
	return (
		<svg.SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<rect width="18" height="18" rx="2" fill={fill} fillOpacity="0.1" />
			<circle cx="12.3333" cy="9.33325" r="1" transform="rotate(90 12.3333 9.33325)" fill={fill} />
			<circle cx="5.66669" cy="9.33325" r="1" transform="rotate(90 5.66669 9.33325)" fill={fill} />
			<circle cx="9" cy="9.33325" r="1" transform="rotate(90 9 9.33325)" fill={fill} />
		</svg.SVG>
	)
}

Opened.defaultProps = {
	fill: theme.colors.blue.blue,
}

export const Closed = (props: svg.SVGProps) => {
	const { fill, ...rest } = props
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<rect width="18" height="18" rx="2" fill={fill} fillOpacity="0.1" />
			<circle cx="9" cy="6" r="1" fill={fill} />
			<circle cx="9" cy="12.6667" r="1" fill={fill} />
			<circle cx="9" cy="9.33325" r="1" fill={fill} />
		</svg>
	)
}

Closed.defaultProps = {
	fill: theme.colors.blue.blue,
}

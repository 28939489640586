/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "meta.account.score.settings.proto" (package "meta", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message meta.AccountSettings
 */
export interface AccountSettings {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: meta.State confidence = 3;
     */
    confidence: State;
    /**
     * @generated from protobuf field: meta.State verdict = 4;
     */
    verdict: State;
    /**
     * @generated from protobuf field: meta.State brand_voice = 5 [json_name = "brand_voice"];
     */
    brand_voice: State;
    /**
     * @generated from protobuf field: meta.State onbrand = 6;
     */
    onbrand: State;
    /**
     * @generated from protobuf field: meta.State image_obscure_rules = 7 [json_name = "image_obscure_rules"];
     */
    image_obscure_rules: State;
    /**
     * @generated from protobuf field: meta.State text_tone_and_voice = 8 [json_name = "text_tone_and_voice"];
     */
    text_tone_and_voice: State;
    /**
     * @generated from protobuf field: meta.State brand_uniqueness = 9 [json_name = "brand_uniqueness"];
     */
    brand_uniqueness: State;
    /**
     * @generated from protobuf field: meta.State copy_consist = 10 [json_name = "copy_consist"];
     */
    copy_consist: State;
    /**
     * @generated from protobuf field: meta.State copy_ui_uniq = 11 [json_name = "copy_ui_uniq"];
     */
    copy_ui_uniq: State;
    /**
     * @generated from protobuf field: meta.State image_logo_detection = 12 [json_name = "image_logo_detection"];
     */
    image_logo_detection: State;
    /**
     * @generated from protobuf field: meta.State image_logo_distortion = 13 [json_name = "image_logo_distortion"];
     */
    image_logo_distortion: State;
    /**
     * @generated from protobuf field: meta.State image_logo_colors = 14 [json_name = "image_logo_colors"];
     */
    image_logo_colors: State;
    /**
     * @generated from protobuf field: meta.State image_logo_clearspace = 15 [json_name = "image_logo_clearspace"];
     */
    image_logo_clearspace: State;
    /**
     * @generated from protobuf field: meta.State image_font_detection = 16 [json_name = "image_font_detection"];
     */
    image_font_detection: State;
    /**
     * @generated from protobuf field: meta.State image_background_colors = 17 [json_name = "image_background_colors"];
     */
    image_background_colors: State;
    /**
     * @generated from protobuf field: meta.State image_violence = 18 [json_name = "image_violence"];
     */
    image_violence: State;
    /**
     * @generated from protobuf field: meta.State sexually_explicit = 19 [json_name = "sexually_explicit"];
     */
    sexually_explicit: State;
    /**
     * @generated from protobuf field: meta.State image_quality_general = 20 [json_name = "image_quality_general"];
     */
    image_quality_general: State;
    /**
     * @generated from protobuf field: meta.State grammar = 21;
     */
    grammar: State;
    /**
     * @generated from protobuf field: meta.State racism = 22;
     */
    racism: State;
    /**
     * @generated from protobuf field: meta.State profanity = 23;
     */
    profanity: State;
    /**
     * @generated from protobuf field: meta.State image_compliance = 24 [json_name = "image_compliance"];
     */
    image_compliance: State;
    /**
     * @generated from protobuf field: meta.State copy_compliance = 25 [json_name = "copy_compliance"];
     */
    copy_compliance: State;
    /**
     * @generated from protobuf field: string created_at = 26 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 27 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: meta.State text_reference_rules = 28 [json_name = "text_reference_rules"];
     */
    text_reference_rules: State;
}
/**
 * @generated from protobuf message meta.UpdateAccountSettingsRequest
 */
export interface UpdateAccountSettingsRequest {
    /**
     * @generated from protobuf field: meta.AccountSettings account_settings = 1 [json_name = "account_settings"];
     */
    account_settings?: AccountSettings;
}
/**
 * @generated from protobuf message meta.UpdateAccountSettingsResponse
 */
export interface UpdateAccountSettingsResponse {
    /**
     * @generated from protobuf field: meta.AccountSettings account_settings = 1 [json_name = "account_settings"];
     */
    account_settings?: AccountSettings;
}
/**
 * @generated from protobuf message meta.FindAccountSettingsRequest
 */
export interface FindAccountSettingsRequest {
    /**
     * @generated from protobuf field: meta.AccountSettings account_settings = 1 [json_name = "account_settings"];
     */
    account_settings?: AccountSettings;
}
/**
 * @generated from protobuf message meta.FindAccountSettingsResponse
 */
export interface FindAccountSettingsResponse {
    /**
     * @generated from protobuf field: meta.AccountSettings account_settings = 1 [json_name = "account_settings"];
     */
    account_settings?: AccountSettings;
}
/**
 * @generated from protobuf enum meta.State
 */
export enum State {
    /**
     * @generated from protobuf enum value: DISABLED = 0;
     */
    DISABLED = 0,
    /**
     * @generated from protobuf enum value: ENABLED = 1;
     */
    ENABLED = 1,
    /**
     * @generated from protobuf enum value: NON_NEGOTIABLE = 2;
     */
    NON_NEGOTIABLE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class AccountSettings$Type extends MessageType<AccountSettings> {
    constructor() {
        super("meta.AccountSettings", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "confidence", kind: "enum", T: () => ["meta.State", State] },
            { no: 4, name: "verdict", kind: "enum", T: () => ["meta.State", State] },
            { no: 5, name: "brand_voice", kind: "enum", localName: "brand_voice", jsonName: "brand_voice", T: () => ["meta.State", State] },
            { no: 6, name: "onbrand", kind: "enum", T: () => ["meta.State", State] },
            { no: 7, name: "image_obscure_rules", kind: "enum", localName: "image_obscure_rules", jsonName: "image_obscure_rules", T: () => ["meta.State", State] },
            { no: 8, name: "text_tone_and_voice", kind: "enum", localName: "text_tone_and_voice", jsonName: "text_tone_and_voice", T: () => ["meta.State", State] },
            { no: 9, name: "brand_uniqueness", kind: "enum", localName: "brand_uniqueness", jsonName: "brand_uniqueness", T: () => ["meta.State", State] },
            { no: 10, name: "copy_consist", kind: "enum", localName: "copy_consist", jsonName: "copy_consist", T: () => ["meta.State", State] },
            { no: 11, name: "copy_ui_uniq", kind: "enum", localName: "copy_ui_uniq", jsonName: "copy_ui_uniq", T: () => ["meta.State", State] },
            { no: 12, name: "image_logo_detection", kind: "enum", localName: "image_logo_detection", jsonName: "image_logo_detection", T: () => ["meta.State", State] },
            { no: 13, name: "image_logo_distortion", kind: "enum", localName: "image_logo_distortion", jsonName: "image_logo_distortion", T: () => ["meta.State", State] },
            { no: 14, name: "image_logo_colors", kind: "enum", localName: "image_logo_colors", jsonName: "image_logo_colors", T: () => ["meta.State", State] },
            { no: 15, name: "image_logo_clearspace", kind: "enum", localName: "image_logo_clearspace", jsonName: "image_logo_clearspace", T: () => ["meta.State", State] },
            { no: 16, name: "image_font_detection", kind: "enum", localName: "image_font_detection", jsonName: "image_font_detection", T: () => ["meta.State", State] },
            { no: 17, name: "image_background_colors", kind: "enum", localName: "image_background_colors", jsonName: "image_background_colors", T: () => ["meta.State", State] },
            { no: 18, name: "image_violence", kind: "enum", localName: "image_violence", jsonName: "image_violence", T: () => ["meta.State", State] },
            { no: 19, name: "sexually_explicit", kind: "enum", localName: "sexually_explicit", jsonName: "sexually_explicit", T: () => ["meta.State", State] },
            { no: 20, name: "image_quality_general", kind: "enum", localName: "image_quality_general", jsonName: "image_quality_general", T: () => ["meta.State", State] },
            { no: 21, name: "grammar", kind: "enum", T: () => ["meta.State", State] },
            { no: 22, name: "racism", kind: "enum", T: () => ["meta.State", State] },
            { no: 23, name: "profanity", kind: "enum", T: () => ["meta.State", State] },
            { no: 24, name: "image_compliance", kind: "enum", localName: "image_compliance", jsonName: "image_compliance", T: () => ["meta.State", State] },
            { no: 25, name: "copy_compliance", kind: "enum", localName: "copy_compliance", jsonName: "copy_compliance", T: () => ["meta.State", State] },
            { no: 26, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "text_reference_rules", kind: "enum", localName: "text_reference_rules", jsonName: "text_reference_rules", T: () => ["meta.State", State] }
        ]);
    }
    create(value?: PartialMessage<AccountSettings>): AccountSettings {
        const message = { id: "", account_id: "", confidence: 0, verdict: 0, brand_voice: 0, onbrand: 0, image_obscure_rules: 0, text_tone_and_voice: 0, brand_uniqueness: 0, copy_consist: 0, copy_ui_uniq: 0, image_logo_detection: 0, image_logo_distortion: 0, image_logo_colors: 0, image_logo_clearspace: 0, image_font_detection: 0, image_background_colors: 0, image_violence: 0, sexually_explicit: 0, image_quality_general: 0, grammar: 0, racism: 0, profanity: 0, image_compliance: 0, copy_compliance: 0, created_at: "", updated_at: "", text_reference_rules: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccountSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountSettings): AccountSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* meta.State confidence */ 3:
                    message.confidence = reader.int32();
                    break;
                case /* meta.State verdict */ 4:
                    message.verdict = reader.int32();
                    break;
                case /* meta.State brand_voice = 5 [json_name = "brand_voice"];*/ 5:
                    message.brand_voice = reader.int32();
                    break;
                case /* meta.State onbrand */ 6:
                    message.onbrand = reader.int32();
                    break;
                case /* meta.State image_obscure_rules = 7 [json_name = "image_obscure_rules"];*/ 7:
                    message.image_obscure_rules = reader.int32();
                    break;
                case /* meta.State text_tone_and_voice = 8 [json_name = "text_tone_and_voice"];*/ 8:
                    message.text_tone_and_voice = reader.int32();
                    break;
                case /* meta.State brand_uniqueness = 9 [json_name = "brand_uniqueness"];*/ 9:
                    message.brand_uniqueness = reader.int32();
                    break;
                case /* meta.State copy_consist = 10 [json_name = "copy_consist"];*/ 10:
                    message.copy_consist = reader.int32();
                    break;
                case /* meta.State copy_ui_uniq = 11 [json_name = "copy_ui_uniq"];*/ 11:
                    message.copy_ui_uniq = reader.int32();
                    break;
                case /* meta.State image_logo_detection = 12 [json_name = "image_logo_detection"];*/ 12:
                    message.image_logo_detection = reader.int32();
                    break;
                case /* meta.State image_logo_distortion = 13 [json_name = "image_logo_distortion"];*/ 13:
                    message.image_logo_distortion = reader.int32();
                    break;
                case /* meta.State image_logo_colors = 14 [json_name = "image_logo_colors"];*/ 14:
                    message.image_logo_colors = reader.int32();
                    break;
                case /* meta.State image_logo_clearspace = 15 [json_name = "image_logo_clearspace"];*/ 15:
                    message.image_logo_clearspace = reader.int32();
                    break;
                case /* meta.State image_font_detection = 16 [json_name = "image_font_detection"];*/ 16:
                    message.image_font_detection = reader.int32();
                    break;
                case /* meta.State image_background_colors = 17 [json_name = "image_background_colors"];*/ 17:
                    message.image_background_colors = reader.int32();
                    break;
                case /* meta.State image_violence = 18 [json_name = "image_violence"];*/ 18:
                    message.image_violence = reader.int32();
                    break;
                case /* meta.State sexually_explicit = 19 [json_name = "sexually_explicit"];*/ 19:
                    message.sexually_explicit = reader.int32();
                    break;
                case /* meta.State image_quality_general = 20 [json_name = "image_quality_general"];*/ 20:
                    message.image_quality_general = reader.int32();
                    break;
                case /* meta.State grammar */ 21:
                    message.grammar = reader.int32();
                    break;
                case /* meta.State racism */ 22:
                    message.racism = reader.int32();
                    break;
                case /* meta.State profanity */ 23:
                    message.profanity = reader.int32();
                    break;
                case /* meta.State image_compliance = 24 [json_name = "image_compliance"];*/ 24:
                    message.image_compliance = reader.int32();
                    break;
                case /* meta.State copy_compliance = 25 [json_name = "copy_compliance"];*/ 25:
                    message.copy_compliance = reader.int32();
                    break;
                case /* string created_at = 26 [json_name = "created_at"];*/ 26:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 27 [json_name = "updated_at"];*/ 27:
                    message.updated_at = reader.string();
                    break;
                case /* meta.State text_reference_rules = 28 [json_name = "text_reference_rules"];*/ 28:
                    message.text_reference_rules = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccountSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* meta.State confidence = 3; */
        if (message.confidence !== 0)
            writer.tag(3, WireType.Varint).int32(message.confidence);
        /* meta.State verdict = 4; */
        if (message.verdict !== 0)
            writer.tag(4, WireType.Varint).int32(message.verdict);
        /* meta.State brand_voice = 5 [json_name = "brand_voice"]; */
        if (message.brand_voice !== 0)
            writer.tag(5, WireType.Varint).int32(message.brand_voice);
        /* meta.State onbrand = 6; */
        if (message.onbrand !== 0)
            writer.tag(6, WireType.Varint).int32(message.onbrand);
        /* meta.State image_obscure_rules = 7 [json_name = "image_obscure_rules"]; */
        if (message.image_obscure_rules !== 0)
            writer.tag(7, WireType.Varint).int32(message.image_obscure_rules);
        /* meta.State text_tone_and_voice = 8 [json_name = "text_tone_and_voice"]; */
        if (message.text_tone_and_voice !== 0)
            writer.tag(8, WireType.Varint).int32(message.text_tone_and_voice);
        /* meta.State brand_uniqueness = 9 [json_name = "brand_uniqueness"]; */
        if (message.brand_uniqueness !== 0)
            writer.tag(9, WireType.Varint).int32(message.brand_uniqueness);
        /* meta.State copy_consist = 10 [json_name = "copy_consist"]; */
        if (message.copy_consist !== 0)
            writer.tag(10, WireType.Varint).int32(message.copy_consist);
        /* meta.State copy_ui_uniq = 11 [json_name = "copy_ui_uniq"]; */
        if (message.copy_ui_uniq !== 0)
            writer.tag(11, WireType.Varint).int32(message.copy_ui_uniq);
        /* meta.State image_logo_detection = 12 [json_name = "image_logo_detection"]; */
        if (message.image_logo_detection !== 0)
            writer.tag(12, WireType.Varint).int32(message.image_logo_detection);
        /* meta.State image_logo_distortion = 13 [json_name = "image_logo_distortion"]; */
        if (message.image_logo_distortion !== 0)
            writer.tag(13, WireType.Varint).int32(message.image_logo_distortion);
        /* meta.State image_logo_colors = 14 [json_name = "image_logo_colors"]; */
        if (message.image_logo_colors !== 0)
            writer.tag(14, WireType.Varint).int32(message.image_logo_colors);
        /* meta.State image_logo_clearspace = 15 [json_name = "image_logo_clearspace"]; */
        if (message.image_logo_clearspace !== 0)
            writer.tag(15, WireType.Varint).int32(message.image_logo_clearspace);
        /* meta.State image_font_detection = 16 [json_name = "image_font_detection"]; */
        if (message.image_font_detection !== 0)
            writer.tag(16, WireType.Varint).int32(message.image_font_detection);
        /* meta.State image_background_colors = 17 [json_name = "image_background_colors"]; */
        if (message.image_background_colors !== 0)
            writer.tag(17, WireType.Varint).int32(message.image_background_colors);
        /* meta.State image_violence = 18 [json_name = "image_violence"]; */
        if (message.image_violence !== 0)
            writer.tag(18, WireType.Varint).int32(message.image_violence);
        /* meta.State sexually_explicit = 19 [json_name = "sexually_explicit"]; */
        if (message.sexually_explicit !== 0)
            writer.tag(19, WireType.Varint).int32(message.sexually_explicit);
        /* meta.State image_quality_general = 20 [json_name = "image_quality_general"]; */
        if (message.image_quality_general !== 0)
            writer.tag(20, WireType.Varint).int32(message.image_quality_general);
        /* meta.State grammar = 21; */
        if (message.grammar !== 0)
            writer.tag(21, WireType.Varint).int32(message.grammar);
        /* meta.State racism = 22; */
        if (message.racism !== 0)
            writer.tag(22, WireType.Varint).int32(message.racism);
        /* meta.State profanity = 23; */
        if (message.profanity !== 0)
            writer.tag(23, WireType.Varint).int32(message.profanity);
        /* meta.State image_compliance = 24 [json_name = "image_compliance"]; */
        if (message.image_compliance !== 0)
            writer.tag(24, WireType.Varint).int32(message.image_compliance);
        /* meta.State copy_compliance = 25 [json_name = "copy_compliance"]; */
        if (message.copy_compliance !== 0)
            writer.tag(25, WireType.Varint).int32(message.copy_compliance);
        /* string created_at = 26 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 27 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.updated_at);
        /* meta.State text_reference_rules = 28 [json_name = "text_reference_rules"]; */
        if (message.text_reference_rules !== 0)
            writer.tag(28, WireType.Varint).int32(message.text_reference_rules);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.AccountSettings
 */
export const AccountSettings = new AccountSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAccountSettingsRequest$Type extends MessageType<UpdateAccountSettingsRequest> {
    constructor() {
        super("meta.UpdateAccountSettingsRequest", [
            { no: 1, name: "account_settings", kind: "message", localName: "account_settings", jsonName: "account_settings", T: () => AccountSettings }
        ]);
    }
    create(value?: PartialMessage<UpdateAccountSettingsRequest>): UpdateAccountSettingsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateAccountSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAccountSettingsRequest): UpdateAccountSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.AccountSettings account_settings = 1 [json_name = "account_settings"];*/ 1:
                    message.account_settings = AccountSettings.internalBinaryRead(reader, reader.uint32(), options, message.account_settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAccountSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.AccountSettings account_settings = 1 [json_name = "account_settings"]; */
        if (message.account_settings)
            AccountSettings.internalBinaryWrite(message.account_settings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.UpdateAccountSettingsRequest
 */
export const UpdateAccountSettingsRequest = new UpdateAccountSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAccountSettingsResponse$Type extends MessageType<UpdateAccountSettingsResponse> {
    constructor() {
        super("meta.UpdateAccountSettingsResponse", [
            { no: 1, name: "account_settings", kind: "message", localName: "account_settings", jsonName: "account_settings", T: () => AccountSettings }
        ]);
    }
    create(value?: PartialMessage<UpdateAccountSettingsResponse>): UpdateAccountSettingsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateAccountSettingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAccountSettingsResponse): UpdateAccountSettingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.AccountSettings account_settings = 1 [json_name = "account_settings"];*/ 1:
                    message.account_settings = AccountSettings.internalBinaryRead(reader, reader.uint32(), options, message.account_settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAccountSettingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.AccountSettings account_settings = 1 [json_name = "account_settings"]; */
        if (message.account_settings)
            AccountSettings.internalBinaryWrite(message.account_settings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.UpdateAccountSettingsResponse
 */
export const UpdateAccountSettingsResponse = new UpdateAccountSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FindAccountSettingsRequest$Type extends MessageType<FindAccountSettingsRequest> {
    constructor() {
        super("meta.FindAccountSettingsRequest", [
            { no: 1, name: "account_settings", kind: "message", localName: "account_settings", jsonName: "account_settings", T: () => AccountSettings }
        ]);
    }
    create(value?: PartialMessage<FindAccountSettingsRequest>): FindAccountSettingsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FindAccountSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FindAccountSettingsRequest): FindAccountSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.AccountSettings account_settings = 1 [json_name = "account_settings"];*/ 1:
                    message.account_settings = AccountSettings.internalBinaryRead(reader, reader.uint32(), options, message.account_settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FindAccountSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.AccountSettings account_settings = 1 [json_name = "account_settings"]; */
        if (message.account_settings)
            AccountSettings.internalBinaryWrite(message.account_settings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.FindAccountSettingsRequest
 */
export const FindAccountSettingsRequest = new FindAccountSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FindAccountSettingsResponse$Type extends MessageType<FindAccountSettingsResponse> {
    constructor() {
        super("meta.FindAccountSettingsResponse", [
            { no: 1, name: "account_settings", kind: "message", localName: "account_settings", jsonName: "account_settings", T: () => AccountSettings }
        ]);
    }
    create(value?: PartialMessage<FindAccountSettingsResponse>): FindAccountSettingsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FindAccountSettingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FindAccountSettingsResponse): FindAccountSettingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.AccountSettings account_settings = 1 [json_name = "account_settings"];*/ 1:
                    message.account_settings = AccountSettings.internalBinaryRead(reader, reader.uint32(), options, message.account_settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FindAccountSettingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.AccountSettings account_settings = 1 [json_name = "account_settings"]; */
        if (message.account_settings)
            AccountSettings.internalBinaryWrite(message.account_settings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.FindAccountSettingsResponse
 */
export const FindAccountSettingsResponse = new FindAccountSettingsResponse$Type();

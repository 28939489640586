import * as authzc from "authzcached"
import * as api from "brandguard/api"
import * as httpx from "httpx"
import * as brandguide from "brandguide"
import * as md5x from "md5x"
import * as icons from "icons"

export const useDataToKernelsImg = (settings: api.ImageKernelSetting) => {
	const authzaccount = authzc.useCache((cached) => cached.meta)

	const guideObj = brandguide.cache.useGuide()
	const guide = guideObj.guide

	const passFonts = () => {
		if (!guide?.typography?.enabled) return
		if (!brandguide.validations.typography.valid.completed(guide.typography)) return
		const pending = guide?.typography.fonts.map((f) => {
			return {
				mimetype: `font/${f.font_type}`,
				description: f.font_family,
				md5: md5x.string(f.font),
				font: f.font,
			}
		})
		const union = [
			...pending.filter((l) => (settings.image_font_detection?.fonts || []).find((o) => o.md5 === l.md5) === undefined),
			...(settings.image_font_detection?.fonts || []),
		]
		settings.image_font_detection!.fonts = union
		settings.image_font_detection!.enabled = true
	}

	const passLogos = () => {
		if (!guide?.logos?.enabled) return
		if (!brandguide.validations.logos.valid.completed(guide.logos)) return
		const logosToPass = [
			guide.logos.primary,
			guide.logos.secondary,
			guide.logos.logomark,
			guide.logos.wordmark,
			guide.logos.alternative,
		].filter((i) => i?.present)
		const pending = logosToPass.map((l) => {
			const svgElement = icons.svg.encodeFromB64ToSVGElement(l?.svg!)
			const clearspace = l?.clearspace || 0
			return icons.svg.svgToPngB64String(svgElement, 300, clearspace).then((b) => {
				return api.images.kernel.logos.zero({
					mimetype: "image/png",
					description: `BrandGuide Builder logo ${l?.id}`,
					md5: md5x.string(l?.svg!),
					image: b.split(",")[1], //remove prefix
					clearspace: {
						horizontal: clearspace / 100,
						vertical: clearspace / 100,
					},
				})
			})
		})

		Promise.all(pending).then((upd) => {
			const union = [
				...upd.filter((l) => (settings.image_logo_detection!.logos || []).find((o) => o.md5 === l.md5) === undefined),
				...(settings.image_logo_detection!.logos || []),
			]
			settings.image_logo_detection!.logos = union
			settings.image_logo_detection!.enabled = true
		})
	}

	const passColors = () => {
		if (!guide?.colors?.enabled) return
		if (!brandguide.validations.colors.valid.completed(guide.colors)) return
		const primary = guide?.colors.primary_colors.map((c) => {
			const color = c.color?.hex_code || "#000000"
			const colorToSend = removeHashFromHexColor(color)
			return {
				id: md5x.string(colorToSend),
				color: colorToSend,
				description: `${c.color?.name} BrandGuide primary color`,
			} as api.ImageKernelBackgroundColor
		})

		const secondary = guide?.colors.secondary_colors.map((c) => {
			const color = c.color?.hex_code || "#000000"
			const colorToSend = removeHashFromHexColor(color)
			return {
				id: md5x.string(colorToSend),
				color: colorToSend,
				description: `${c.color?.name} BrandGuide secondary color`,
			} as api.ImageKernelBackgroundColor
		})

		settings.image_background_colors!.colors = [...primary, ...secondary]
		settings.image_background_colors!.enabled = true
	}

	const passDataToImgKernels = () => {
		passFonts()
		passLogos()
		passColors()
		const retry = httpx.autoretry()
		retry
			.wrap(() =>
				api.images.kernel.create_version(
					guideObj.brand_id,
					guideObj.brand_id,
					{ settings: settings },
					authzc.bearer(authzaccount),
				),
			)
			.catch((e) => console.error(e))
	}

	return { passDataToImgKernels }
}

export const useDataToKernelsText = (settings: api.TextKernelSetting) => {
	const authzaccount = authzc.useCache((cached) => cached.meta)

	const guideObj = brandguide.cache.useGuide()
	const guide = guideObj.guide

	const passToneOfVoice = () => {
		if (!guide?.tone_of_voice?.enabled) return
		if (!brandguide.validations.tone_of_voice.valid.completed(guide.tone_of_voice)) return

		const line_break = ""
		const header = `This is the brand voice that's meant to be ${
			guide.tone_of_voice.tone_of_voice[0]?.description || ""
		}.`
		const explanation = guide.tone_of_voice.descriptions?.tone_of_voice || ""
		const positive_adjectives = `We are ${guide.tone_of_voice.positive_adjectives.map((pa) => pa.name).join(", ")}.`
		const negative_adjectives = `We are not ${guide.tone_of_voice.negative_adjectives.map((na) => na.name).join(", ")}.`
		const header2 = "Examples of text that satisfy this tone of voice are:"
		const writing_styles = guide.tone_of_voice.writing_style.map((ws) => ws.text).join("\r\n")

		settings.tnv!.prompt = [
			header,
			explanation,
			line_break,
			positive_adjectives,
			negative_adjectives,
			line_break,
			header2,
			writing_styles,
		].join("\r\n")
		settings.tnv!.enabled = true
	}

	const passDataToTextKernels = () => {
		passToneOfVoice()
		const retry = httpx.autoretry()
		retry
			.wrap(() =>
				api.text.kernel.create_version(
					guideObj.brand_id,
					guideObj.brand_id,
					{ settings: settings },
					authzc.bearer(authzaccount),
				),
			)
			.catch((e) => console.error(e))
	}

	return { passDataToTextKernels }
}

function removeHashFromHexColor(hexColor: string): string {
	if (hexColor.startsWith("#")) {
		return hexColor.slice(1)
	}
	return hexColor
}

import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import { fontFamily } from "."
import { PropsWithChildren } from "react"

interface textLineProps extends typography.TypographyProps {
	item: brandguide.api.TypographyObject
	fonts: brandguide.api.FontObject[]
	is_default?: boolean
}

function TextLine(props: PropsWithChildren<textLineProps>): JSX.Element {
	const { item, fonts, is_default, children, ...rest } = props
	const font = fonts.find((f) => f.id === item.font_id)
	const fontSize = item.size * 0.1
	const lineHeight = fontSize + 0.5
	const text = is_default
		? `${item.title}: ${brandguide.build.typography.layouts.combinedTitle(item, font?.font_family || "")}`
		: children
	return (
		<typography.h4
			{...rest}
			fontSize={`${fontSize}vh`}
			lineHeight={`${lineHeight}vh`}
			fontFamily={font ? fontFamily(font) : "unset"}
			color={colors.grey.dark50alpha80}
		>
			{text}
		</typography.h4>
	)
}

TextLine.defaultProps = {
	fontWeight: "400",
	whiteSpace: "unset",
}

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = brandguide.api.zeros.guide.guideTypographyZero(guide.guide?.typography)

	const fonts = data.fonts || []

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Typography</result_components.NavTitle>
				<result_components.NavDescription>
					To keep communication materials consistent and polished, please refer to the basic typography guidelines shown
					here.
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.usage} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer>
					<layouts.Flex>
						<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
							Typography Usage
						</typography.h4>
					</layouts.Flex>
					<layouts.Flex flexDirection="column" flex="1" width="100%" alignItems="start">
						{(data.items || []).map((item) => (
							<layouts.Flex key={item.id} flex="1" alignItems="center">
								<TextLine item={item} fonts={fonts} is_default />
							</layouts.Flex>
						))}
					</layouts.Flex>
					<layouts.Flex pt="1%">
						<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
							Typography Sample
						</typography.h4>
					</layouts.Flex>
					<layouts.Flex flexDirection="column" flex="2" width="100%" alignItems="start">
						{(data.items || []).map((item) => (
							<layouts.Flex key={item.id} width="100%" flex="1" alignItems="center">
								<layouts.Flex flex="1">
									<TextLine item={item} fonts={fonts}>
										Lorem ipsum dolor sit amet, consectetuer adipiscing elit
									</TextLine>
								</layouts.Flex>
								<layouts.Flex flex="1" flexDirection="column" px="10px">
									<layouts.Flex flex="1" borderBottom={`2px dotted ${layouts.theme.colors.grey.dark50}`} />
									<layouts.Flex flex="1" />
								</layouts.Flex>
								<layouts.Flex flex="1">
									<typography.h5
										fontSize="32px"
										lineHeight="35px"
										color={layouts.theme.colors.grey.dark50}
										whiteSpace="unset"
									>
										{item.title}
									</typography.h5>
								</layouts.Flex>
							</layouts.Flex>
						))}
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

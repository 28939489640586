import React from "react"
import * as b64 from "js-base64"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"
import * as md5x from "md5x"
import * as api from "brandguard/api"

interface props extends layouts.containers.FlexProps {
	settings: api.ImageKernelFontEnabled
	onChange(upd: api.ImageKernelFontEnabled): void
}

const acceptedFonts = {
	"application/x-font-ttf": [".ttf"],
	"application/x-font-opentype": [".otf"],
	"application/font-woff": [".woff"],
	"application/font-woff2": [".woff2"],
	"application/vnd.ms-fontobject": [".eot"],
}

export default function FontDetection(props: React.PropsWithChildren<props>): JSX.Element {
	const { settings, onChange, ...rest } = props
	const fonts = (settings.fonts || []).map((l) => (
		<layouts.Flex key={l.md5} py="5px">
			<layouts.Span>{l.description}</layouts.Span>
			<layouts.Span
				ml="auto"
				onClick={(evt) => {
					evt.stopPropagation()
					onChange({ ...settings, fonts: settings.fonts.filter((o) => o.md5 !== l.md5) })
				}}
			>
				<icons.Trash width="14px" height="14px" />
			</layouts.Span>
		</layouts.Flex>
	))

	return (
		<layouts.Flex flexDirection="column" flex="1" {...rest}>
			<inputs.Dropwell
				multiple
				accept={acceptedFonts}
				onDrop={(accepted, rejected) => {
					if (accepted.length === 0) return
					const pending = Array.from(accepted).map((f) => {
						return f.arrayBuffer().then((b) => {
							const barray = new Uint8Array(b)
							return {
								mimetype: f.type,
								description: f.name,
								md5: md5x.string(barray),
								font: b64.fromUint8Array(barray),
							}
						})
					})

					Promise.all(pending).then((upd) => {
						const union = [
							...upd.filter((l) => (settings.fonts || []).find((o) => o.md5 === l.md5) === undefined),
							...(settings.fonts || []),
						]
						onChange({ ...settings, fonts: union })
					})
				}}
			>
				<layouts.dnd.Container>
					<icons.FileUpload />
					<layouts.dnd.Textual>Drag & drop your fonts here or click to browse files</layouts.dnd.Textual>
				</layouts.dnd.Container>
			</inputs.Dropwell>
			<layouts.Flex flexDirection="column" py="15px">
				{fonts}
			</layouts.Flex>
		</layouts.Flex>
	)
}

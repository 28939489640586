import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as logo_compts from "./components"

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const cache = brandguide.cache.useCached()
	const guide = cache.guide

	const primary = guide.guide?.logos?.primary!

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>The Logo</result_components.NavTitle>
				<result_components.NavDescription>
					The primary logo is the preferred and most recognizable version of our brand mark. Use the primary logo in
					most applications, including print, digital, and signage.
					<br />
					<br />
					Maintain the original color scheme for the primary logo. It can also be used on our secondary colored
					backgrounds and over imagery (but please ensure optimum legibility). Always maintain a clear space around the
					logo to preserve its visibility and impact. Do not alter the proportions of the logo. Ensure it is scaled
					proportionally to maintain its integrity.
				</result_components.NavDescription>
				<result_components.Explanation desc={guide.guide?.logos?.descriptions?.uploads} />
			</result_components.LeftSideNav>
			<result_components.MainContainer gridGap="2%">
				<result_components.ContentContainer>
					<layouts.Flex>
						<typography.h4 color={colors.grey.dark50} lineHeight="50px">
							Primary Logo
						</typography.h4>
					</layouts.Flex>
					<layouts.Flex
						flex="1"
						{...logo_compts.centeredProps}
						maxHeight="100%"
						className={logo_compts.ContrastBGColorClassName(primary.svg)}
					>
						<logo_compts.LogoImage src={primary.svg} alt="Main Logo" />
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

export function colors(): string {
	return "/colors"
}

export function logos(): string {
	return "/logos"
}

export function typography(): string {
	return "/typography"
}

export function imagery(): string {
	return "/imagery"
}

export function tone_of_voice(): string {
	return "/tov"
}

export function archetype(): string {
	return "/archetype"
}

export function application(): string {
	return "/application"
}

export function compliance(): string {
	return "/compliance"
}

import React, { useState, useEffect, useRef } from "react"
import { css } from "@emotion/css"
import * as layouts from "layouts"
import styled from "@emotion/styled"
import * as typography from "typography"

export const styledscroll = css`
	scrollbar-width: thin;
	scrollbar-gutter: stable;
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${layouts.theme.colors.grey.light50};
	}
`

export const styledcontainer = css`
	hr {
		display: block;
		height: 2px;
		border: 0;
		border-top: ${layouts.theme.borders.grey.light};
		margin: 1em 10px;
		padding: 0;
	}
`

export const styledInput = css`
	input {
		border: 1px solid ${layouts.theme.colors.grey.light50} !important;
		color: ${layouts.theme.colors.grey.dark50} !important;
		background: #f4f5f6 !important;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0px;
		text-align: left;

		::placeholder {
			color: ${layouts.theme.colors.grey.dark50};
			font-size: 12px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: 0px;
			text-align: left;
		}
	}
`

export const messageInput = css`
	input {
		border: 1px solid ${layouts.theme.colors.grey.light50} !important;
		color: ${layouts.theme.colors.grey.dark50} !important;
		font-size: 14px;
		font-weight: 400;
		line-height: 26px;
		text-align: left;
		background: ${layouts.theme.colors.white} !important;
	}
`

export const textsimple = css`
	padding-top: 0 !important;
	font-size: 11px !important;
	font-weight: 400 !important;
	color: ${layouts.theme.colors.grey.dark50} !important;
	&:before {
		content: "" !important;
	}
`

export const textssmall = css`
	font-size: 12px !important;
	font-weight: 400 !important;
	color: ${layouts.theme.colors.grey.dark50} !important;
`

export const textsmedium = css`
	font-size: 14px !important;
	font-weight: 600 !important;
	color: ${layouts.theme.colors.grey.dark3} !important;
`

export const Par = styled.p`
	white-space: pre-line;
	b {
		font-weight: 500;
	}
	i {
		font-style: italic;
	}
	h3 {
		font-size: 16px;
		font-weight: 500;
	}
	h2 {
		font-size: 18px;
		font-weight: 500;
	}
	h1 {
		font-size: 20px;
		font-weight: 500;
	}
`

export const Img = styled.img`
	width: 288px;
	height: 212px;
	margin: auto;
`

export const Text = styled(typography.h3)`
	padding-top: 50px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: ${layouts.theme.colors.grey.dark10};
`

export const Select = styled.select`
	height: 48px;
	background: ${layouts.theme.colors.white};
	color: ${layouts.theme.colors.grey.dark20};
	box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
	border-radius: 10px;
	&:hover {
		box-shadow: none;
	}
	&:focus {
		outline: 0;
	}
`

export const Page = styled.span`
	height: 13px;
	width: 22px;
	padding: 2px 2px 2px 2px;
	margin-right: 3px;
	background: rgba(62, 110, 228, 0.75);
	border-radius: 3px;
	color: white;
	font-weight: 600;
	&:hover {
		cursor: pointer;
	}
`

export const styleguideresize = css`
	position: relative;
	width: 10px !important;
	border: 0 !important;
	z-index: 0 !important;
	background: ${layouts.theme.colors.white} !important;
	&.active,
	&:hover {
		transition: none !important;
	}
	svg {
		position: relative;
		top: 50%;
	}
	&::after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 40%;
		border-left: 2px solid ${layouts.theme.colors.grey.dark20alpha};
	}
`

export function TypeWriter(props: { fullText: string; speed: number }): JSX.Element {
	const { fullText, speed } = props
	const [text, setText] = useState("")
	const index = useRef(0)

	useEffect(() => {
		if (index.current >= fullText.length) return
		const timer = setTimeout(() => {
			setText((prevText) => prevText + fullText.charAt(index.current))
			index.current++
		}, speed)

		return () => {
			clearTimeout(timer)
		}
	}, [text])

	return (
		<p>
			{text.split("\n").map((line, index) => (
				<React.Fragment key={index}>
					{line}
					<br />
				</React.Fragment>
			))}
		</p>
	)
}

TypeWriter.defaultProps = {
	speed: 30,
}

import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as compl from "brandguide/build/compliance"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideCompliance | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideComplianceZero(_guide.guide?.compliance))

	useEffect(() => {
		if ((data.items || []).length > 0) return
		setData({
			...data,
			items: brandguide.build.generators.initial.compliance.selecting(),
		})
	}, [])

	const items = data.items || []

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Compliance" Help={<compl.helps.Modal />} />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer desc="Please let us know what compliance(s) you need to adhere to for your business.">
							<compl.Carousel my="25px" className={compl.layouts.styledCarousel} autoscrollright>
								{items.map((item) => (
									<compl.layouts.Item
										key={item.id}
										item={item}
										onChange={(upd) => setData({ ...data, items: items.map((i) => (i.id === upd.id ? upd : i)) })}
									/>
								))}
							</compl.Carousel>

							<compl.Explanation
								val={data.descriptions?.selection || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.compliance.descZero({ ...data.descriptions, selection: text }),
									})
								}}
							/>
							<brandguide.build.layouts.NextStepsNav
								onNext={() => onChange(compl.Steps.RESULT, data)}
								disabled={!brandguide.validations.compliance.valid.selection(data)}
							/>
							<compl.Progress activeStep={compl.Steps.SELECTION} steps={compl.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<compl.Guidelines activPage="compliance" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

import * as layouts from "layouts"

export const Container = layouts.css`
	margin-bottom: 5px;
	border-radius: 4px;
	overflow: hidden;
	color: ${layouts.theme.colors.grey.light25};
	max-height: 26px;
	justify-content: start;
	align-items: center;
`

export const ContainerDefaultProps = {
	px: "5px",
	border: `1px solid ${layouts.theme.colors.grey.light25}`,
}

export const Description = layouts.css`
	font-size: 10px;
	font-weight: 400;
	line-height: 16.5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

export const DescriptionDefaultProps = {
	py: "5px",
}

import styled from "@emotion/styled"
import * as system from "styled-system"
import * as theme from "layouts/theme"

export interface TypographyProps
	extends system.LayoutProps,
		system.TypographyProps,
		system.SpaceProps,
		system.PositionProps,
		system.WidthProps,
		system.BackgroundProps,
		system.ColorProps {
	className?: string
	whiteSpace?: string
}

export const h1 = styled.h1<TypographyProps>`
	font-size: 36px;
	font-weight: 800;
	line-height: 48px;
	letter-spacing: -0.02em;
	white-space: ${(props) => props.whiteSpace};
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
h1.defaultProps = {
	whiteSpace: "nowrap",
}

export const h2 = styled.h2<TypographyProps & system.ColorProps>`
	font-size: 18px;
	font-weight: 700;
	line-height: 25.5px;
	white-space: ${(props) => props.whiteSpace};
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
h2.defaultProps = {
	whiteSpace: "nowrap",
}

export const h3 = styled.h3<TypographyProps & system.ColorProps>`
	font-size: 18px;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: -0.02em;
	white-space: ${(props) => props.whiteSpace};
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
h3.defaultProps = {
	whiteSpace: "nowrap",
}

export const h4 = styled.h4<TypographyProps & system.ColorProps>`
	font-size: 18px;
	font-weight: 400;
	line-height: 25.5px;
	white-space: ${(props) => props.whiteSpace};
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
h4.defaultProps = {
	whiteSpace: "nowrap",
}

export const h5 = styled.h5<TypographyProps & system.ColorProps>`
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	white-space: ${(props) => props.whiteSpace};
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
h5.defaultProps = {
	whiteSpace: "nowrap",
}

export const h6 = styled.h6<TypographyProps & system.ColorProps>`
	font-size: 12px;
	font-weight: 700;
	line-height: 20px;
	white-space: ${(props) => props.whiteSpace};
	${system.background}
	${system.color}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
h6.defaultProps = {
	whiteSpace: "nowrap",
}

export const danger = styled.h6<TypographyProps>`
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: ${theme.colors.danger};
	white-space: ${(props) => props.whiteSpace};
	${system.background}
	${system.width}
	${system.position}
	${system.layout}
	${system.typography}
	${system.space}
`
danger.defaultProps = {
	whiteSpace: "nowrap",
}

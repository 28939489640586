/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandgpt.channel.proto" (package "brandgpt.channel", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message brandgpt.channel.Channel
 */
export interface Channel {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string brand_id = 2 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string account_id = 3 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: string created_at = 5 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 6 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string tombstoned_at = 7 [json_name = "tombstoned_at"];
     */
    tombstoned_at: string;
    /**
     * @generated from protobuf field: string styleguide_id = 8 [json_name = "styleguide_id"];
     */
    styleguide_id: string;
}
/**
 * @generated from protobuf message brandgpt.channel.ChannelsSearchRequest
 */
export interface ChannelsSearchRequest {
    /**
     * @generated from protobuf field: string brand_id = 1 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string styleguide_id = 2 [json_name = "styleguide_id"];
     */
    styleguide_id: string;
    /**
     * @generated from protobuf field: int64 offset = 3;
     */
    offset: bigint;
    /**
     * @generated from protobuf field: int64 limit = 4;
     */
    limit: bigint;
}
/**
 * @generated from protobuf message brandgpt.channel.ChannelsSearchResponse
 */
export interface ChannelsSearchResponse {
    /**
     * @generated from protobuf field: repeated brandgpt.channel.Channel items = 1;
     */
    items: Channel[];
    /**
     * @generated from protobuf field: brandgpt.channel.ChannelsSearchRequest next = 2;
     */
    next?: ChannelsSearchRequest;
}
/**
 * @generated from protobuf message brandgpt.channel.ChannelCreateRequest
 */
export interface ChannelCreateRequest {
    /**
     * @generated from protobuf field: brandgpt.channel.Channel channel = 1;
     */
    channel?: Channel;
}
/**
 * @generated from protobuf message brandgpt.channel.ChannelCreateResponse
 */
export interface ChannelCreateResponse {
    /**
     * @generated from protobuf field: brandgpt.channel.Channel channel = 1;
     */
    channel?: Channel;
}
/**
 * @generated from protobuf message brandgpt.channel.ChannelUpdateRequest
 */
export interface ChannelUpdateRequest {
    /**
     * @generated from protobuf field: brandgpt.channel.Channel channel = 1;
     */
    channel?: Channel;
}
/**
 * @generated from protobuf message brandgpt.channel.ChannelUpdateResponse
 */
export interface ChannelUpdateResponse {
    /**
     * @generated from protobuf field: brandgpt.channel.Channel channel = 1;
     */
    channel?: Channel;
}
/**
 * @generated from protobuf message brandgpt.channel.ChannelDeleteRequest
 */
export interface ChannelDeleteRequest {
    /**
     * @generated from protobuf field: brandgpt.channel.Channel channel = 1;
     */
    channel?: Channel;
}
/**
 * @generated from protobuf message brandgpt.channel.ChannelDeleteResponse
 */
export interface ChannelDeleteResponse {
    /**
     * @generated from protobuf field: brandgpt.channel.Channel channel = 1;
     */
    channel?: Channel;
}
// @generated message type with reflection information, may provide speed optimized methods
class Channel$Type extends MessageType<Channel> {
    constructor() {
        super("brandgpt.channel.Channel", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "tombstoned_at", kind: "scalar", localName: "tombstoned_at", jsonName: "tombstoned_at", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "styleguide_id", kind: "scalar", localName: "styleguide_id", jsonName: "styleguide_id", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Channel>): Channel {
        const message = { id: "", brand_id: "", account_id: "", description: "", created_at: "", updated_at: "", tombstoned_at: "", styleguide_id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Channel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Channel): Channel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string brand_id = 2 [json_name = "brand_id"];*/ 2:
                    message.brand_id = reader.string();
                    break;
                case /* string account_id = 3 [json_name = "account_id"];*/ 3:
                    message.account_id = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string created_at = 5 [json_name = "created_at"];*/ 5:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 6 [json_name = "updated_at"];*/ 6:
                    message.updated_at = reader.string();
                    break;
                case /* string tombstoned_at = 7 [json_name = "tombstoned_at"];*/ 7:
                    message.tombstoned_at = reader.string();
                    break;
                case /* string styleguide_id = 8 [json_name = "styleguide_id"];*/ 8:
                    message.styleguide_id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Channel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string brand_id = 2 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.brand_id);
        /* string account_id = 3 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.account_id);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string created_at = 5 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 6 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.updated_at);
        /* string tombstoned_at = 7 [json_name = "tombstoned_at"]; */
        if (message.tombstoned_at !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.tombstoned_at);
        /* string styleguide_id = 8 [json_name = "styleguide_id"]; */
        if (message.styleguide_id !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.styleguide_id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.Channel
 */
export const Channel = new Channel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelsSearchRequest$Type extends MessageType<ChannelsSearchRequest> {
    constructor() {
        super("brandgpt.channel.ChannelsSearchRequest", [
            { no: 1, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "styleguide_id", kind: "scalar", localName: "styleguide_id", jsonName: "styleguide_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ChannelsSearchRequest>): ChannelsSearchRequest {
        const message = { brand_id: "", styleguide_id: "", offset: 0n, limit: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelsSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelsSearchRequest): ChannelsSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string brand_id = 1 [json_name = "brand_id"];*/ 1:
                    message.brand_id = reader.string();
                    break;
                case /* string styleguide_id = 2 [json_name = "styleguide_id"];*/ 2:
                    message.styleguide_id = reader.string();
                    break;
                case /* int64 offset */ 3:
                    message.offset = reader.int64().toBigInt();
                    break;
                case /* int64 limit */ 4:
                    message.limit = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelsSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string brand_id = 1 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.brand_id);
        /* string styleguide_id = 2 [json_name = "styleguide_id"]; */
        if (message.styleguide_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.styleguide_id);
        /* int64 offset = 3; */
        if (message.offset !== 0n)
            writer.tag(3, WireType.Varint).int64(message.offset);
        /* int64 limit = 4; */
        if (message.limit !== 0n)
            writer.tag(4, WireType.Varint).int64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.ChannelsSearchRequest
 */
export const ChannelsSearchRequest = new ChannelsSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelsSearchResponse$Type extends MessageType<ChannelsSearchResponse> {
    constructor() {
        super("brandgpt.channel.ChannelsSearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Channel },
            { no: 2, name: "next", kind: "message", T: () => ChannelsSearchRequest }
        ]);
    }
    create(value?: PartialMessage<ChannelsSearchResponse>): ChannelsSearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelsSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelsSearchResponse): ChannelsSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandgpt.channel.Channel items */ 1:
                    message.items.push(Channel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* brandgpt.channel.ChannelsSearchRequest next */ 2:
                    message.next = ChannelsSearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelsSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandgpt.channel.Channel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Channel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* brandgpt.channel.ChannelsSearchRequest next = 2; */
        if (message.next)
            ChannelsSearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.ChannelsSearchResponse
 */
export const ChannelsSearchResponse = new ChannelsSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelCreateRequest$Type extends MessageType<ChannelCreateRequest> {
    constructor() {
        super("brandgpt.channel.ChannelCreateRequest", [
            { no: 1, name: "channel", kind: "message", T: () => Channel }
        ]);
    }
    create(value?: PartialMessage<ChannelCreateRequest>): ChannelCreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelCreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelCreateRequest): ChannelCreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandgpt.channel.Channel channel */ 1:
                    message.channel = Channel.internalBinaryRead(reader, reader.uint32(), options, message.channel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelCreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandgpt.channel.Channel channel = 1; */
        if (message.channel)
            Channel.internalBinaryWrite(message.channel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.ChannelCreateRequest
 */
export const ChannelCreateRequest = new ChannelCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelCreateResponse$Type extends MessageType<ChannelCreateResponse> {
    constructor() {
        super("brandgpt.channel.ChannelCreateResponse", [
            { no: 1, name: "channel", kind: "message", T: () => Channel }
        ]);
    }
    create(value?: PartialMessage<ChannelCreateResponse>): ChannelCreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelCreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelCreateResponse): ChannelCreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandgpt.channel.Channel channel */ 1:
                    message.channel = Channel.internalBinaryRead(reader, reader.uint32(), options, message.channel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelCreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandgpt.channel.Channel channel = 1; */
        if (message.channel)
            Channel.internalBinaryWrite(message.channel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.ChannelCreateResponse
 */
export const ChannelCreateResponse = new ChannelCreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelUpdateRequest$Type extends MessageType<ChannelUpdateRequest> {
    constructor() {
        super("brandgpt.channel.ChannelUpdateRequest", [
            { no: 1, name: "channel", kind: "message", T: () => Channel }
        ]);
    }
    create(value?: PartialMessage<ChannelUpdateRequest>): ChannelUpdateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelUpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelUpdateRequest): ChannelUpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandgpt.channel.Channel channel */ 1:
                    message.channel = Channel.internalBinaryRead(reader, reader.uint32(), options, message.channel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelUpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandgpt.channel.Channel channel = 1; */
        if (message.channel)
            Channel.internalBinaryWrite(message.channel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.ChannelUpdateRequest
 */
export const ChannelUpdateRequest = new ChannelUpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelUpdateResponse$Type extends MessageType<ChannelUpdateResponse> {
    constructor() {
        super("brandgpt.channel.ChannelUpdateResponse", [
            { no: 1, name: "channel", kind: "message", T: () => Channel }
        ]);
    }
    create(value?: PartialMessage<ChannelUpdateResponse>): ChannelUpdateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelUpdateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelUpdateResponse): ChannelUpdateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandgpt.channel.Channel channel */ 1:
                    message.channel = Channel.internalBinaryRead(reader, reader.uint32(), options, message.channel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelUpdateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandgpt.channel.Channel channel = 1; */
        if (message.channel)
            Channel.internalBinaryWrite(message.channel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.ChannelUpdateResponse
 */
export const ChannelUpdateResponse = new ChannelUpdateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelDeleteRequest$Type extends MessageType<ChannelDeleteRequest> {
    constructor() {
        super("brandgpt.channel.ChannelDeleteRequest", [
            { no: 1, name: "channel", kind: "message", T: () => Channel }
        ]);
    }
    create(value?: PartialMessage<ChannelDeleteRequest>): ChannelDeleteRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelDeleteRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelDeleteRequest): ChannelDeleteRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandgpt.channel.Channel channel */ 1:
                    message.channel = Channel.internalBinaryRead(reader, reader.uint32(), options, message.channel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelDeleteRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandgpt.channel.Channel channel = 1; */
        if (message.channel)
            Channel.internalBinaryWrite(message.channel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.ChannelDeleteRequest
 */
export const ChannelDeleteRequest = new ChannelDeleteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelDeleteResponse$Type extends MessageType<ChannelDeleteResponse> {
    constructor() {
        super("brandgpt.channel.ChannelDeleteResponse", [
            { no: 1, name: "channel", kind: "message", T: () => Channel }
        ]);
    }
    create(value?: PartialMessage<ChannelDeleteResponse>): ChannelDeleteResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelDeleteResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelDeleteResponse): ChannelDeleteResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* brandgpt.channel.Channel channel */ 1:
                    message.channel = Channel.internalBinaryRead(reader, reader.uint32(), options, message.channel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelDeleteResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* brandgpt.channel.Channel channel = 1; */
        if (message.channel)
            Channel.internalBinaryWrite(message.channel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandgpt.channel.ChannelDeleteResponse
 */
export const ChannelDeleteResponse = new ChannelDeleteResponse$Type();

import * as layouts from "layouts"
import * as typography from "typography"
import * as colors from "./index"
import * as brandguide from "brandguide"
import * as tone_of_voices from "brandguide/build/tone_of_voice"
import { Link } from "react-router-dom"
import * as brands from "brands"
import { results } from "brandguide/build/layouts"
import * as navigation from "brandguide/build/navigation"
import * as authz from "authz"
import * as authzc from "authzcached"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, guide: brandguide.api.GuideToneOfVoice | undefined): void
}

export function SectionResult(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const guide = brandguide.cache.useCached().guide
	const data = brandguide.api.zeros.guide.guideToneOfVoiceZero(guide.guide?.tone_of_voice)

	const authzaccount = authzc.useCache((cached) => cached.meta)
	const permission = authzaccount.current

	return (
		<layouts.Flex flex="1" {...rest}>
			<layouts.Flex flexDirection="column" flex="8" className="left-panel" px="47px">
				<results.Title>Tone of Voice</results.Title>
				<results.ResultDesc>
					Our brand voice is {data.tone_of_voice[0]?.description || ""}
					<br />
				</results.ResultDesc>

				<results.Explanation desc={data.descriptions?.tone_of_voice} />

				{data.positive_adjectives.length + data.negative_adjectives.length > 0 && (
					<results.SectionContainer>
						<results.Explanation desc={data.descriptions?.adjectives} />
						<layouts.Flex alignItems="center" my="15px" width="100%">
							<AdjectiveSection title="We Are" items={data.positive_adjectives} />
							<layouts.Flex
								my="20px"
								height="100%"
								borderRight={`1px solid ${layouts.theme.colors.grey.dark50alpha30}`}
							/>
							<AdjectiveSection title="We Are Not" items={data.negative_adjectives} />
						</layouts.Flex>
					</results.SectionContainer>
				)}

				{(data.writing_style || []).length > 0 && (
					<results.SectionContainer>
						<results.Title>Writing Samples</results.Title>
						<results.Explanation desc={data.descriptions?.writing_style} />

						<layouts.Flex flexDirection="column" width="100%" alignItems="start">
							{(data.writing_style || []).map((ws) => (
								<layouts.Flex
									key={ws.id}
									p="20px"
									borderBottom={`1px solid ${layouts.theme.colors.grey.dark50alpha40}`}
								>
									<typography.h4
										fontSize="16px"
										fontWeight="400"
										whiteSpace="unset"
										lineHeight="25px"
										color={layouts.theme.colors.grey.dark50alpha80}
									>
										{ws.text}
									</typography.h4>
								</layouts.Flex>
							))}
						</layouts.Flex>
					</results.SectionContainer>
				)}

				<authz.Protected enabled={permission.brandguide_edit}>
					<layouts.Flex justifyContent="center" mt="100px" mb="20px" alignItems="center">
						<NextStageLink guide={guide} className="no-print" />
						<navigation.Section
							title="Tone of Voice"
							steps={tone_of_voices.layouts.steps}
							onChange={(s) => onChange(s, undefined)}
						/>
					</layouts.Flex>
				</authz.Protected>
			</layouts.Flex>
			<colors.Guidelines activPage="tone_of_voice" className="no-print" />
		</layouts.Flex>
	)
}

function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<layouts.Flex justifyContent="right" mr="47px">
						<brandguide.build.SavePDF />
					</layouts.Flex>
					<SectionResult onChange={onChange} />
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

function AdjectiveSection(
	props: { title: string; items: brandguide.api.ToneOfVoiceAdjective[] } & layouts.containers.FlexProps,
): JSX.Element {
	const { title, items, ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<typography.h5
				fontSize="16px"
				fontWeight="600"
				lineHeight="normal"
				mb="13px"
				color={layouts.theme.colors.grey.dark50alpha80}
			>
				{title}
			</typography.h5>
			{(items || []).map((i) => (
				<AdjectiveItem key={i.id} item={i} />
			))}
		</layouts.Flex>
	)
}

AdjectiveSection.defaultProps = {
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "start",
	height: "100%",
	mx: "10px",
	flex: "1",
}

function AdjectiveItem(
	props: { item: brandguide.api.ToneOfVoiceAdjective } & layouts.containers.FlexProps,
): JSX.Element {
	const { item, ...rest } = props
	return (
		<layouts.Flex {...rest}>
			<typography.h5
				fontSize="14px"
				fontWeight="400"
				lineHeight="normal"
				color={layouts.theme.colors.white}
				overflow="hidden"
				maxWidth="245px"
				style={{ textOverflow: "ellipsis" }}
			>
				{item.name}
			</typography.h5>
		</layouts.Flex>
	)
}

AdjectiveItem.defaultProps = {
	width: "259px",
	height: "37px",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "5px",
	border: `1px solid ${layouts.theme.colors.grey.dark50alpha30}`,
	boxShadow: `0px 2px 2px 0px ${layouts.theme.colors.grey.dark50alpha10}`,
	gridGap: "10px",
	background: layouts.theme.colors.blue.blue,
	mt: "5px",
}

interface nextProps extends layouts.containers.FlexProps {
	guide: brandguide.api.Brandguide
}

function NextStageLink(props: nextProps): JSX.Element {
	const { guide, ...rest } = props
	const brand = brands.caching.useCached()

	const nextLink = (): string | undefined => {
		if (guide.guide?.archetype?.enabled) return brandguide.routing.build.archetype(brand.id)
		if (guide.guide?.compliance?.enabled) return brandguide.routing.build.compliance(brand.id)
		if (guide.guide?.application?.enabled) return brandguide.routing.build.application(brand.id)
		return undefined
	}

	if (!nextLink()) return <></>

	return (
		<layouts.Flex {...rest}>
			<Link to={nextLink()!}>
				<layouts.buttons.unstyled
					width="130px"
					height="34px"
					background={layouts.theme.colors.grey.dark50}
					borderRadius="5px"
					color={layouts.theme.colors.white}
					onClick={() => undefined}
				>
					Next Section
				</layouts.buttons.unstyled>
			</Link>
		</layouts.Flex>
	)
}

NextStageLink.defaultProps = {
	flex: "1",
	justifyContent: "end",
}

export default Display

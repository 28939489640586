import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as i from "icons"
import * as bg_api from "brandguard/api"
import * as bgpredictions from "brandguard/predictions"
import PredictionIcon from "brandguard/components/approvals/icon"

export { ExtensionContainer } from "./extension.container"

export namespace icons {
	export const LoadingIcon: JSX.Element = (
		<i.loading.Ring1 m="auto" width="60px" height="60px" foreground={layouts.theme.extension.colors.blue.header} />
	)
	export const LoadingEligibleAssets = (): JSX.Element => (
		<PredictionIcon width="20px" height="20px" prediction={bg_api.Prediction.NONE} />
	)
}

export const Tab = styled(layouts.containers.flex)`
	color: ${layouts.theme.extension.colors.blue.text_secondary};
	font-size: 12px;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
	margin-right: 22px;
	&.active {
		font-weight: 600;
		text-decoration-line: underline;
	}
`

export const AccordionContainer = styled(layouts.accordions.Container)`
	background-color: ${layouts.theme.extension.colors.blue.background};
	border-top: 2px solid ${layouts.theme.extension.colors.blue.border};
	border-bottom: unset;
	padding: 0;
`
export const AccordionDescription = styled(layouts.accordions.Description)`
	align-items: center;
	height: 54px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	padding: 0 24px;
	width: auto;
	> span > svg > path {
		stroke: ${layouts.theme.extension.colors.blue.feather} !important;
	}
`
export const AccordionContent = styled(layouts.accordions.Content)`
	color: ${layouts.theme.extension.colors.blue.text};
	padding: 0 24px;
	&.asset-score {
		padding: 0;
	}
`

const RecentTextGrid = styled(layouts.containers.grid)`
	justify-content: center;
	display: grid;
	grid-template-columns: repeat(auto-fill, 175px);
	grid-template-rows: min-content;
	gap: 10px 2px;
	padding: 2px 0;
`

export const EligibleTextGrid = styled(RecentTextGrid)`
	max-height: 380px;
	overflow-y: auto;
`

export const RecentItemsGrid = styled(layouts.containers.flex)`
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 2px;
`

export const RecentImageWrapper = styled(layouts.containers.flex)`
	box-sizing: border-box;
	width: calc(50% - 6.5px);
	padding-bottom: 15px;
`

export const RecentTextWrapper = styled(layouts.containers.flex)`
	box-sizing: border-box;
	width: 100%;
	padding-bottom: 10px;
`

const RecentImagesGrid = styled(layouts.containers.grid)`
	justify-content: center;
	display: grid;
	grid-template-columns: repeat(auto-fill, 82px);
	grid-template-rows: min-content;
	gap: 15px 13px;
	padding: 2px 0;
`

export const EligibleImagesGrid = styled(RecentImagesGrid)`
	max-height: 380px;
	overflow-y: auto;
`

export const EligibleImage = styled(layouts.images.Base)`
	width: 100%;
	height: auto;
	max-width: 100%;
	// max-height: 100%;
	margin: auto;
	&.active {
		outline: ${`2px solid ${layouts.theme.extension.colors.blue.text_secondary}`};
	}
`

export const ImageAsset = styled(layouts.images.Base)`
	max-height: 175px;
	background: ${layouts.theme.extension.colors.white.alpha13};
`

export const ScanButton = styled(layouts.buttons.primary_box)`
	margin-bottom: 8px;
	height: 30px;
	border-radius: 5px;
	background: ${layouts.theme.extension.colors.blue.text_secondary};
	color: ${layouts.theme.extension.colors.blue.background};
	font-size: 14px;
	font-weight: 700;
	line-height: 30px;
	&:hover {
		background: ${layouts.theme.extension.colors.blue.text_secondary};
		color: ${layouts.theme.extension.colors.blue.background};
	}
`

export const AssetStatus = styled(layouts.containers.flex)`
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
	height: 30px;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 800;
	text-transform: capitalize;
`

AssetStatus.defaultProps = {
	color: layouts.theme.colors.white,
}

export const UploadingStatus = (): JSX.Element => (
	<AssetStatus background={bgpredictions.color(bg_api.Prediction.NONE)}>
		<PredictionIcon width="15px" height="15px" prediction={bg_api.Prediction.NONE} />
		<layouts.containers.span ml="6px">uploading</layouts.containers.span>
	</AssetStatus>
)

export namespace assetStatus {
	const { REJECTED, DISCARDED_BY_UPLOADER, REJECTED_BY_REVIEW, ERROR, NONE, APPROVED } = bg_api.Prediction
	export const statusColor = (prediction: bg_api.Prediction): string => {
		switch (prediction) {
			case NONE:
				return layouts.theme.colors.grey.dark10
			case REJECTED:
			case DISCARDED_BY_UPLOADER:
			case REJECTED_BY_REVIEW:
			case ERROR:
				return layouts.theme.colors.red.cinnabar
			default:
				return layouts.theme.colors.blue.blue
		}
	}

	export const iconStatus = (prediction: bg_api.Prediction): bg_api.Prediction => {
		if ([REJECTED, DISCARDED_BY_UPLOADER, REJECTED_BY_REVIEW, ERROR, NONE].includes(prediction)) return prediction

		return APPROVED
	}

	export const assetScored = (asset: bg_api.ImageSearchResponseItem | bg_api.TextSearchResponseItem): boolean => {
		let year = new Date(asset.media!.scored_at).getFullYear()
		return !isNaN(year) && year < 9999
	}
}

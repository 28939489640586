import React, { useState, useEffect } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as caching from "brandguide/cache"
import * as brandguide from "brandguide"
import * as logos from "brandguide/build/logos"

interface props extends layouts.containers.ContainerProps {
	onChange(step: number, data: brandguide.api.GuideLogos | undefined): void
}

export function Display(props: props): JSX.Element {
	const { onChange, ...rest } = props
	const _guide = caching.useGuide()

	const [data, setData] = useState(brandguide.api.zeros.guide.guideLogosZero(_guide.guide?.logos))

	const svg = data.primary!.svg

	useEffect(() => {
		if ((_guide.guide?.logos?.usage || []).length > 0) return
		setData({ ...data, usage: brandguide.build.generators.initial.logos.usage(svg) })
	}, [])

	const items = data.usage || []

	const usage = items.filter((el) => el.use)
	const misuse = items.filter((el) => !el.use)

	return (
		<layouts.Flex {...rest}>
			<layouts.Flex
				flex="1"
				flexDirection="row"
				background={layouts.theme.colors.white}
				border={layouts.theme.borders.grey.dark50alpha}
				boxShadow={layouts.theme.boxshadow.black.wide}
			>
				<layouts.Flex flex="1" flexDirection="column" py="40px" className="panel">
					<brandguide.build.layouts.SectionStepTitle title="Logo Usage Do's and Don'ts" />
					<layouts.Flex flex="1">
						<brandguide.build.layouts.SectionStepContainer
							desc="Please remove the usage you do not agree with for both sections. These rules will apply to all logo
							variations."
						>
							<layouts.overlays.Container flexDirection="column" overflow="auto" height="42vh" my="10px">
								<layouts.Flex justifyContent="center" my="10px">
									<typography.h5
										fontSize="16px"
										fontWeight="600"
										letterSpacing="unset"
										color={layouts.theme.colors.grey.dark50}
										whiteSpace="unset"
									>
										DO
									</typography.h5>
								</layouts.Flex>
								<layouts.containers.grid
									gap="15px"
									gridTemplateColumns="repeat(2, minmax(250px, 1fr))"
									gridTemplateRows="repeat(auto-fit, minmax(120px, 1fr))"
									style={{ alignContent: "start", alignItems: "start" }}
									minHeight="0px"
								>
									{usage.map((item) => (
										<logos.layouts.UsageItem
											key={item.title}
											svg={svg}
											item={item}
											onDelete={(upd) => {
												if (usage.length === 1) {
													console.error("can't delete last one")
													return
												}
												setData({ ...data, usage: items.filter((i) => i !== upd) })
											}}
											correct
										/>
									))}
								</layouts.containers.grid>
								<layouts.Flex justifyContent="center" my="10px">
									<typography.h5
										fontSize="16px"
										fontWeight="600"
										letterSpacing="unset"
										color={layouts.theme.colors.grey.dark50}
										whiteSpace="unset"
									>
										DON&apos;T
									</typography.h5>
								</layouts.Flex>
								<layouts.containers.grid
									gap="15px"
									gridTemplateColumns="repeat(2, minmax(250px, 1fr))"
									gridTemplateRows="repeat(auto-fit, minmax(120px, 1fr))"
									style={{ alignContent: "start", alignItems: "start" }}
									minHeight="0px"
								>
									{misuse.map((item) => (
										<logos.layouts.UsageItem
											key={item.title}
											svg={svg}
											item={item}
											onDelete={(upd) => {
												if (misuse.length === 1) {
													console.error("can't delete last one")
													return
												}
												setData({ ...data, usage: items.filter((i) => i !== upd) })
											}}
										/>
									))}
								</layouts.containers.grid>
							</layouts.overlays.Container>

							<logos.Explanation
								val={data.descriptions?.usage || ""}
								placeholder="Add further explanation of logo usage here."
								onChange={(text) => {
									setData({
										...data,
										descriptions: brandguide.api.zeros.logos.descZero({ ...data.descriptions, usage: text }),
									})
								}}
							/>
							<brandguide.build.layouts.TwoWayStepsNav
								onBack={() => onChange(logos.Steps.MISUSE, undefined)}
								onNext={() => onChange(logos.Steps.RESULT, data)}
								disabled={!brandguide.validations.logos.valid.usage(data)}
								isLastStep
							/>
							<logos.Progress activeStep={logos.Steps.USAGE} steps={logos.layouts.steps} />
						</brandguide.build.layouts.SectionStepContainer>
						<logos.Guidelines activPage="logos" />
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: "1",
}

export default Display

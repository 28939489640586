import { useTheme as euseTheme } from "@emotion/react"
import * as theme from "./theme"

export * as theme from "./theme" // export theme first to prevent cyclic dependency issues
export * as accordions from "./accordions"
export * as approval from "./approval"
export * as backgrounds from "./backgrounds"
export * as buttons from "./buttons"
export * as containers from "./containers"
export * as forms from "./forms"
export * as loading from "./loading"
export * as overlays from "./overlays"
export * as modals from "./modals"
export * as pagination from "./pagination"
export * as pills from "./pills"
export * as tables from "./tables"
export * as toggles from "./toggle"
export * as links from "./links"
export * as images from "./images"
export * as events from "./events"
export * as dnd from "./dnd"
export * as bars from "./bars"
export * as tooltips from "./tooltips"
export { css } from "@emotion/css"
export { flex as Flex, box as Box, span as Span } from "./containers"
export { default as Clipboard } from "./clipboard.button"

export function useTheme(): theme.config {
	return euseTheme() as theme.config
}

import React from "react"
import * as layouts from "layouts"

export default function Percentage(
	props: {
		indev: boolean
		score: number
		inverse: boolean
		nonnegotiable: boolean
	} & layouts.containers.ContainerProps,
): JSX.Element {
	const { indev, score, inverse, nonnegotiable, ...rest } = props
	const theme = layouts.useTheme()
	if (indev || score === -1)
		return (
			<layouts.containers.flex flexDirection="row" {...rest}>
				N/A
			</layouts.containers.flex>
		)
	const scorePercent = inverse ? Math.round((1 - score) * 100) : Math.round(score * 100)
	if (nonnegotiable)
		return (
			<layouts.Flex color={theme.color(layouts.theme.colors.red.cinnabar, layouts.theme.colors.red.bright)} {...rest}>
				{scorePercent}%
			</layouts.Flex>
		)
	return <layouts.containers.span {...rest}>{scorePercent}%</layouts.containers.span>
}

Percentage.defaultProps = {
	inverse: false,
	indev: false,
	fontWeight: "500",
	nonnegotiable: false,
}

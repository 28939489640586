import * as brandguide from "brandguide"
import * as layouts from "layouts"
import * as typography from "typography"
import { colors } from "layouts/theme"
import * as result_components from "brandguide/results/components"
import * as brands from "brands"
import { getAlphabetArray, getNumberSeries, fontFamily } from "."
import { H1MainText } from "./layouts"

export default function Display(props: layouts.containers.ContainerProps): JSX.Element {
	const { ...rest } = props
	const brand = brands.caching.useCached()
	const cache = brandguide.cache.useCached()
	const guide = cache.guide
	const data = guide.guide?.typography!

	if (!result_components.validations.typography.has_secondary_font(data)) return <></>

	const fonts = data.fonts || []
	const secondary = fonts.find((f) => f.id === "secondary")!

	return (
		<layouts.Flex {...rest}>
			<result_components.LeftSideNav>
				<result_components.NavTitle>Typography</result_components.NavTitle>
				<result_components.NavDescription>
					There are {result_components.pluralize(fonts.length, "font")} used for {brand.description} marketing
					materials.
				</result_components.NavDescription>
				<result_components.Explanation desc={data.descriptions?.uploads} />
			</result_components.LeftSideNav>
			<result_components.MainContainer>
				<result_components.ContentContainer>
					<layouts.Flex flexDirection="column" height="100%" flex="1">
						<layouts.Flex>
							<typography.h4 color={colors.grey.dark50} lineHeight="50px" fontSize="16px">
								Secondary Font
							</typography.h4>
						</layouts.Flex>
						<layouts.Flex flex="1" flexDirection="column">
							<layouts.Flex flex="1">
								<typography.h4
									color={layouts.theme.colors.grey.dark50alpha80}
									fontFamily={fontFamily(secondary)}
									lineHeight="60px"
									fontSize="36px"
								>
									{secondary?.font_family || "Error"}
								</typography.h4>
							</layouts.Flex>
							<layouts.Flex flex="3" flexWrap="wrap">
								{getAlphabetArray().map((i) => (
									<layouts.Flex key={i}>
										<H1MainText font={secondary}>{i}</H1MainText>
									</layouts.Flex>
								))}
							</layouts.Flex>
							<layouts.Flex flex="1" flexWrap="wrap">
								{getNumberSeries.map((i) => (
									<layouts.Flex key={i}>
										<H1MainText font={secondary}>{i}</H1MainText>
									</layouts.Flex>
								))}
							</layouts.Flex>
							<layouts.Flex flex="1" alignItems="end">
								<typography.h4
									color={layouts.theme.colors.grey.dark50alpha80}
									fontFamily={fontFamily(secondary)}
									lineHeight="60px"
									fontSize="36px"
								>
									The quick brown fox jumps over the lazy dog
								</typography.h4>
							</layouts.Flex>
						</layouts.Flex>
					</layouts.Flex>
				</result_components.ContentContainer>
			</result_components.MainContainer>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	flex: 1,
	height: "100vh",
	width: "100vw",
}

import * as layouts from "layouts"
import * as typography from "typography"
import * as brandguide from "brandguide"
import * as icons from "icons"

export function Modal(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.Flex {...rest}>
			<layouts.Flex flex="1" maxHeight="40px" justifyContent="flex-end">
				<layouts.Flex onClick={() => mtoggle(undefined)} padding="10px">
					<icons.Close height="20px" width="20px" fill={layouts.theme.colors.grey.dark50alpha30} />
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column" px="40px">
				<typography.h4 fontSize="24px" lineHeight="normal" color={layouts.theme.colors.grey.dark50}>
					Color Palette Help
				</typography.h4>
				<layouts.Flex pt="15px" pb="10px">
					<p style={{ fontSize: "14px", lineHeight: "20px", color: layouts.theme.colors.grey.dark50alpha80 }}>
						<b style={{ fontWeight: "600" }}>Color is a powerful tool</b> that communicates emotions, conveys messages,
						and shapes perceptions. When creating a brand identity, the color palette serves as the foundation upon
						which all visual elements are built. It is crucial to consider not only the aesthetic appeal of the colors
						but also their psychological impact and ability to resonate with the target audience. A well-chosen color
						palette can evoke specific feelings, establish brand recognition, and differentiate the brand from
						competitors. It provides consistency and cohesiveness across various brand touchpoints. <br />
						<br />
						If you are still stuck please use this sample below to help guide your color choices.
					</p>
				</layouts.Flex>
				<layouts.containers.grid gap="15px">
					{brandguide.build.generators.helps.colors.tiles.map((t) => (
						<layouts.Flex
							key={t.title}
							flexDirection="column"
							maxWidth="137px"
							maxHeight="87px"
							p="12px"
							background={t.background}
						>
							<typography.h4 fontSize="13px" fontWeight="600" lineHeight="16px" color={t.color} pb="10px">
								{t.title}
							</typography.h4>
							<typography.h4 fontSize="11px" lineHeight="16px" color={t.color} whiteSpace="unset">
								{t.text}
							</typography.h4>
						</layouts.Flex>
					))}
				</layouts.containers.grid>
				<layouts.Flex
					flexDirection="column"
					maxHeight="58px"
					p="12px"
					my="15px"
					background={layouts.theme.colors.white}
					border={`1px solid ${layouts.theme.colors.grey.dark50alpha30}`}
				>
					<typography.h4
						fontSize="13px"
						fontWeight="600"
						lineHeight="16px"
						color={layouts.theme.colors.grey.dark50}
						pb="10px"
					>
						White
					</typography.h4>
					<typography.h4 fontSize="11px" lineHeight="16px" color={layouts.theme.colors.grey.dark50} whiteSpace="unset">
						Perfection, transmits luminosity, innocence, goodness & purity
					</typography.h4>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Modal.defaultProps = {
	width: "815px",
	height: "575px",
	flexDirection: "column",
	background: layouts.theme.colors.white,
	boxShadow: `0px 4px 4px 0px ${layouts.theme.colorWithOpacity(layouts.theme.colors.grey.dark50, 12)}`,
}

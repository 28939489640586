import { default as md5 } from "md5"

export const string = md5

export * as rendezvous from "./rendezvous"

export function partition<T>(seed: string, ...set: T[]): T {
	const offset = parseInt(md5(seed), 16) % set.length
	return set[offset]
}

import * as httpx from "httpx"
import { CancellablePromise } from "real-cancellable-promise"

import {
	BackgroundColorExplanation,
	BackgroundColorExplanationSearchRequest,
	BackgroundColorExplanationSearchResponse,
	BackgroundColorExplanationMaskURL,
	LogoDistortionExplanation,
	LogoDistortionExplanationSearchRequest,
	LogoDistortionExplanationSearchResponse,
	LogoClearspaceExplanation,
	LogoClearspaceExplanationSearchRequest,
	LogoClearspaceExplanationSearchResponse,
	LogoColorExplanation,
	LogoColorExplanationSearchRequest,
	LogoColorExplanationSearchResponse,
	ViolenceExplanation,
	ViolenceExplanationSearchRequest,
	ViolenceExplanationSearchResponse,
	NudityExplanation,
	NudityExplanationSearchRequest,
	NudityExplanationSearchResponse,
	ImageConsistencyExplanation,
	ImageConsistencyExplanationSearchRequest,
	ImageConsistencyExplanationSearchResponse,
	TextConsistencyExplanation,
	TextConsistencyExplanationSearchRequest,
	TextConsistencyExplanationSearchResponse,
	ToneNVoiceExplanation,
	ToneNVoiceExplanationSearchRequest,
	ToneNVoiceExplanationSearchResponse,
	PrecisionExplanation,
	FontDetectionExplanationSearchRequest,
	FontDetectionExplanationSearchResponse,
	RacismExplanation,
	RacismExplanationSearchRequest,
	RacismExplanationSearchResponse,
	ProfanityExplanation,
	ProfanityExplanationSearchRequest,
	ProfanityExplanationSearchResponse,
	GrammarExplanation,
	GrammarExplanationSearchRequest,
	GrammarExplanationSearchResponse,
} from "brandguard/brandguard_pb"

export namespace background_color {
	export function zero(d: Partial<BackgroundColorExplanation>): BackgroundColorExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			explanation: "",
			...d,
		}
	}

	export namespace searches {
		export function request(
			d: Partial<BackgroundColorExplanationSearchRequest> = {},
		): BackgroundColorExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(
			d: Partial<BackgroundColorExplanationSearchResponse> = {},
		): BackgroundColorExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: BackgroundColorExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<BackgroundColorExplanationSearchResponse> {
		return httpx
			.get<BackgroundColorExplanationSearchResponse>(
				`${httpx.urlstorage.host()}/bg/image/explanations/background_color/`,
				req,
				...options,
			)
			.then((r) => ({
				...r,
				items: (r.items || []).map(background_color.zero),
			}))
	}

	export function url(
		event_id: string,
		...options: httpx.option[]
	): CancellablePromise<BackgroundColorExplanationMaskURL> {
		return httpx.get(
			`${httpx.urlstorage.host()}/bg/image/explanations/background_color_mask_image/${event_id}`,
			{},
			...options,
		)
	}
}

export namespace fontdetection {
	export function zero(d: Partial<PrecisionExplanation>): PrecisionExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			dimensions: [],
			...d,
		}
	}

	export namespace searches {
		export function request(
			d: Partial<FontDetectionExplanationSearchRequest> = {},
		): FontDetectionExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}
	}

	export function search(
		req: FontDetectionExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<FontDetectionExplanationSearchResponse> {
		return httpx
			.get<FontDetectionExplanationSearchResponse>(
				`${httpx.urlstorage.host()}/bg/image/explanations/fontdetection`,
				req,
				...options,
			)
			.then((r) => ({
				...r,
				items: (r.items || []).map(fontdetection.zero),
			}))
	}
}

export namespace logos {
	export namespace distortion {
		export function zero(l: Partial<LogoDistortionExplanation>): LogoDistortionExplanation {
			return {
				id: "",
				account_id: "",
				brand_id: "",
				event_id: "",
				created_at: "",
				description: "",
				dimensions: "",
				...l,
			}
		}

		export function searchZero(
			d: Partial<LogoDistortionExplanationSearchRequest> = {},
		): LogoDistortionExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function search(
			req: LogoDistortionExplanationSearchRequest,
			...options: httpx.option[]
		): CancellablePromise<LogoDistortionExplanationSearchResponse> {
			return httpx
				.get<LogoDistortionExplanationSearchResponse>(
					`${httpx.urlstorage.host()}/bg/image/explanations/logodistortion/`,
					req,
					...options,
				)
				.then((r) => ({
					...r,
					items: r.items || [],
				}))
		}
	}

	export namespace clearspace {
		export function zero(l: Partial<LogoClearspaceExplanation>): LogoClearspaceExplanation {
			return {
				id: "",
				account_id: "",
				brand_id: "",
				event_id: "",
				created_at: "",
				description: "",
				dimensions: "",
				...l,
			}
		}

		export function searchZero(
			d: Partial<LogoClearspaceExplanationSearchRequest> = {},
		): LogoClearspaceExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function search(
			req: LogoClearspaceExplanationSearchRequest,
			...options: httpx.option[]
		): CancellablePromise<LogoClearspaceExplanationSearchResponse> {
			return httpx
				.get<LogoClearspaceExplanationSearchResponse>(
					`${httpx.urlstorage.host()}/bg/image/explanations/logoclearspace/`,
					req,
					...options,
				)
				.then((r) => ({
					...r,
					items: r.items || [],
				}))
		}
	}

	export namespace color {
		export function zero(l: Partial<LogoColorExplanation>): LogoColorExplanation {
			return {
				id: "",
				account_id: "",
				brand_id: "",
				event_id: "",
				created_at: "",
				description: "",
				dimensions: "",
				...l,
			}
		}

		export function searchZero(d: Partial<LogoColorExplanationSearchRequest> = {}): LogoColorExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function search(
			req: LogoColorExplanationSearchRequest,
			...options: httpx.option[]
		): CancellablePromise<LogoColorExplanationSearchResponse> {
			return httpx
				.get<LogoClearspaceExplanationSearchResponse>(
					`${httpx.urlstorage.host()}/bg/image/explanations/logocolor/`,
					req,
					...options,
				)
				.then((r) => ({
					...r,
					items: r.items || [],
				}))
		}
	}
}

export namespace violence {
	export function zero(d: Partial<ViolenceExplanation>): ViolenceExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			...d,
		}
	}

	export namespace searches {
		export function request(d: Partial<ViolenceExplanationSearchRequest> = {}): ViolenceExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(d: Partial<ViolenceExplanationSearchResponse> = {}): ViolenceExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: ViolenceExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<ViolenceExplanationSearchResponse> {
		return httpx
			.get<ViolenceExplanationSearchResponse>(
				`${httpx.urlstorage.host()}/bg/image/explanations/violence/`,
				req,
				...options,
			)
			.then((r) => ({
				...r,
				items: (r.items || []).map(violence.zero),
			}))
	}
}

export namespace nudity {
	export function zero(d: Partial<NudityExplanation>): NudityExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			...d,
		}
	}

	export namespace searches {
		export function request(d: Partial<NudityExplanationSearchRequest> = {}): NudityExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(d: Partial<NudityExplanationSearchResponse> = {}): NudityExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: NudityExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<NudityExplanationSearchResponse> {
		return httpx
			.get<NudityExplanationSearchResponse>(`${httpx.urlstorage.host()}/bg/image/explanations/nudity/`, req, ...options)
			.then((r) => ({
				...r,
				items: (r.items || []).map(nudity.zero),
			}))
	}
}

export namespace image_consistency {
	export function zero(d: Partial<ImageConsistencyExplanation>): ImageConsistencyExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			...d,
		}
	}

	export namespace searches {
		export function request(
			d: Partial<ImageConsistencyExplanationSearchRequest> = {},
		): ImageConsistencyExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(
			d: Partial<ImageConsistencyExplanationSearchResponse> = {},
		): ImageConsistencyExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: ImageConsistencyExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<ImageConsistencyExplanationSearchResponse> {
		return httpx
			.get<ImageConsistencyExplanationSearchResponse>(
				`${httpx.urlstorage.host()}/bg/image/explanations/image_consistency/`,
				req,
				...options,
			)
			.then((r) => ({
				...r,
				items: (r.items || []).map(image_consistency.zero),
			}))
	}
}

export namespace text_consistency {
	export function zero(d: Partial<TextConsistencyExplanation>): TextConsistencyExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			...d,
		}
	}

	export namespace searches {
		export function request(
			d: Partial<TextConsistencyExplanationSearchRequest> = {},
		): TextConsistencyExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(
			d: Partial<TextConsistencyExplanationSearchResponse> = {},
		): TextConsistencyExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: TextConsistencyExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<TextConsistencyExplanationSearchResponse> {
		return httpx
			.get<TextConsistencyExplanationSearchResponse>(
				`${httpx.urlstorage.host()}/bg/text/explanations/text_consistency/`,
				req,
				...options,
			)
			.then((r) => ({
				...r,
				items: (r.items || []).map(text_consistency.zero),
			}))
	}
}

export namespace tnv {
	export function zero(d: Partial<ToneNVoiceExplanation>): ToneNVoiceExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			...d,
		}
	}

	export namespace searches {
		export function request(d: Partial<ToneNVoiceExplanationSearchRequest> = {}): ToneNVoiceExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(
			d: Partial<ToneNVoiceExplanationSearchResponse> = {},
		): ToneNVoiceExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: ToneNVoiceExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<ToneNVoiceExplanationSearchResponse> {
		return httpx
			.get<ToneNVoiceExplanationSearchResponse>(`${httpx.urlstorage.host()}/bg/text/explanations/tnv/`, req, ...options)
			.then((r) => ({
				...r,
				items: (r.items || []).map(text_consistency.zero),
			}))
	}
}

export namespace racism {
	export function zero(d: Partial<RacismExplanation>): RacismExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			...d,
		}
	}

	export namespace searches {
		export function request(d: Partial<RacismExplanationSearchRequest> = {}): RacismExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(d: Partial<RacismExplanationSearchResponse> = {}): RacismExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: RacismExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<RacismExplanationSearchResponse> {
		return httpx
			.get<RacismExplanationSearchResponse>(`${httpx.urlstorage.host()}/bg/text/explanations/racism/`, req, ...options)
			.then((r) => ({
				...r,
				items: (r.items || []).map(text_consistency.zero),
			}))
	}
}
export namespace profanity {
	export function zero(d: Partial<ProfanityExplanation>): ProfanityExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			...d,
		}
	}

	export namespace searches {
		export function request(d: Partial<ProfanityExplanationSearchRequest> = {}): ProfanityExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(d: Partial<ProfanityExplanationSearchResponse> = {}): ProfanityExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: ProfanityExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<ProfanityExplanationSearchResponse> {
		return httpx
			.get<ProfanityExplanationSearchResponse>(
				`${httpx.urlstorage.host()}/bg/text/explanations/profanity/`,
				req,
				...options,
			)
			.then((r) => ({
				...r,
				items: (r.items || []).map(text_consistency.zero),
			}))
	}
}

export namespace grammar {
	export function zero(d: Partial<GrammarExplanation>): GrammarExplanation {
		return {
			id: "",
			account_id: "",
			brand_id: "",
			event_id: "",
			created_at: "",
			description: "",
			...d,
		}
	}

	export namespace searches {
		export function request(d: Partial<GrammarExplanationSearchRequest> = {}): GrammarExplanationSearchRequest {
			return {
				offset: 0n,
				brand_id: "",
				limit: 100n,
				event_ids: [],
				...d,
			}
		}

		export function response(d: Partial<GrammarExplanationSearchResponse> = {}): GrammarExplanationSearchResponse {
			return {
				next: request(),
				items: [],
				...d,
			}
		}
	}

	export function search(
		req: GrammarExplanationSearchRequest,
		...options: httpx.option[]
	): CancellablePromise<GrammarExplanationSearchResponse> {
		return httpx
			.get<GrammarExplanationSearchResponse>(
				`${httpx.urlstorage.host()}/bg/text/explanations/grammar/`,
				req,
				...options,
			)
			.then((r) => ({
				...r,
				items: (r.items || []).map(text_consistency.zero),
			}))
	}
}

import styled from "@emotion/styled"
import * as layouts from "layouts"
import { css } from "@emotion/css"
import * as icons from "icons"

export const PDFPage = styled(layouts.containers.box)`
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
`

export const styledwrapperRef = css`
	.page-wrapper:not(:first-child) {
		margin: 10px 0;
	}
`

export const SpinnerIcon = styled(icons.loading.Ring3)`
	width: 30px;
	height: 30px;
	border: 0;
`

export const numericInput = css`
	border: 1px solid ${layouts.theme.colors.grey.light50};
	color: ${layouts.theme.colors.grey.dark50};
	input {
		text-align: center;
		background: ${layouts.theme.colors.grey.light50};
	}
	margin-right: 5px;
`

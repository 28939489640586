import { useEffect, useState } from "react"
import { useLocation, useNavigate, Location } from "react-router-dom"
import styled from "@emotion/styled"
import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"
import * as logos from "titlebars/logos"
import * as httpx from "httpx"
import * as errors from "errors"
import * as uuid from "uuid"
import * as api from "./api"
import * as cache from "./cache"
import Passwordless, { clearLoginAttemptInfo } from "supertokens-web-js/recipe/passwordless"
import Session from "supertokens-web-js/recipe/session"
import Multiaccount from "./multiaccount"
import Available from "./available"
import Signup from "./signup"
import { CancellablePromise } from "real-cancellable-promise"

interface props {
	authd(location: Location): CancellablePromise<api.Authed>
}

const SpinnerIcon = styled(icons.loading.Ring1)`
	margin: auto;
	width: 80px;
	height: 80px;
`

function findsuggested(
	authd: CancellablePromise<api.Authed>,
	req: api.AvailableRequest,
): CancellablePromise<api.AvailableResponse> {
	return authd.then((r) => api.accounts.available.list(req, httpx.options.bearer(r.signup_token)))
}

export function Auth(props: props): JSX.Element {
	const { authd: _authd } = props
	const location = useLocation()
	const navigate = useNavigate()

	const [cause, setCause] = useState(undefined as undefined | JSX.Element)
	const [loading, setLoading] = useState(true)
	const [authd, setAuthd] = useState({
		user: {
			id: "",
			display: "",
			email: "",
		},
		options: api.loginopts.zero(),
		signup_token: "",
		profiles: [],
	} as api.Authed)
	const sugReq = api.accounts.available.requests.zero()
	const [suggested, setSuggested] = useState({
		cursor: sugReq,
		items: [],
	} as api.AvailableResponse)
	const redirect = (authd: api.Authed, current: api.Authn) => {
		const uri = new URL(
			authd.options?.redirect ? `${window.location.protocol}${authd.options?.redirect}` : window.location.origin,
		)
		uri.search = httpx.qs.stringify({ ...uri.searchParams, lt: current.token })
		return uri.toString()
	}

	useEffect(() => {
		const retry = httpx.autoretry()
		const pauthd = retry.wrap(() => _authd(location))
		const suggestions = retry.wrap(() => findsuggested(pauthd, suggested.cursor!))
		Promise.all([pauthd, suggestions])
			.then(async ([authd, suggestions]) => {
				// if autologin is enabled and we only have a single profile available and no suggested accounts
				// then automatically log in.
				if (!(authd.options?.autologin && authd.profiles.length === 1 && suggestions.items.length === 0)) {
					setAuthd(authd)
					setSuggested(suggestions)
					setLoading(false)
					return
				}
				const p = authd.profiles[0]
				setImmediate(() => (window.location.href = redirect(authd, p)))
			})
			.catch((cause) => {
				console.error("unable to authenticate", cause)
				navigate("/") // TODO: notify the user.
			})
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<layouts.backgrounds.Grey>
			<layouts.containers.flex className="authenticated" flexDirection="column" width="100vw" height="100vh">
				<layouts.containers.flex className="navigation" p="1%">
					<logos.Logo />
				</layouts.containers.flex>
				<layouts.containers.flex
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					height="100vh"
					width="100vw"
					overflow="hidden"
				>
					<typography.h1 color={layouts.theme.colors.grey.medium} mb="20px">
						Select an account to sign in with
					</typography.h1>
					<errors.overlay styled cause={cause} width="100%">
						<layouts.containers.flex
							styled
							flexDirection="column"
							justifyContent="center"
							alignContent="space-around"
							width="608px"
							minHeight="240px"
							borderRadius="20px"
							padding="50px"
							mb="50px"
						>
							<layouts.loading.pending
								loading={loading}
								margin="auto"
								icon={<SpinnerIcon foreground={layouts.theme.colors.hinting.lightest} />}
							>
								<Multiaccount
									overflow="auto"
									flex="1"
									minHeight="100px"
									borderRadius="5px"
									border={`1px solid ${layouts.theme.colors.grey.dark20alpha}`}
									authn={authd.profiles || []}
									onChange={(pending) => {
										pending
											.then((current) => {
												setImmediate(() => (window.location.href = redirect(authd, current)))
											})
											.catch((cause) => {
												setCause(<errors.UnknownCause cause={cause} onClick={() => setCause(undefined)} />)
											})
									}}
								/>
								<Available
									overflow="auto"
									flex="1"
									minHeight="100px"
									borderRadius="5px"
									border={`1px solid ${layouts.theme.colors.grey.dark20alpha}`}
									accounts={suggested}
									onChange={(pending) => {
										pending
											.then((current) => {
												setImmediate(() => (window.location.href = redirect(authd, current)))
											})
											.catch((cause) => {
												setCause(
													<errors.UnknownCause
														cause={cause}
														onClick={() => {
															setCause(undefined)
															navigate("/")
														}}
													/>,
												)
											})
									}}
								/>
								<Signup
									authd={authd}
									suggested={suggested}
									onChange={(pending) => {
										pending
											.then((current) => {
												setImmediate(() => (window.location.href = redirect(authd, current)))
											})
											.catch(
												httpx.errors.authorization((cause) => {
													console.warn("unable to signup for sso domain, email domain isn't authorized.")
													setCause(
														<errors.UnauthorizedDomains
															cause={cause}
															onClick={() => {
																setCause(undefined)
																navigate("/")
															}}
														/>,
													)
												}),
											)
											.catch((cause) => {
												setCause(
													<errors.UnknownCause
														cause={cause}
														onClick={() => {
															setCause(undefined)
															navigate("/")
														}}
													/>,
												)
											})
									}}
								/>
							</layouts.loading.pending>
						</layouts.containers.flex>
					</errors.overlay>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.backgrounds.Grey>
	)
}

function oauth2(
	location: Location,
	platform: (code: string, state: string, scope: string) => CancellablePromise<api.Authed>,
): CancellablePromise<api.Authed> {
	const urlparams = httpx.qs.parse(location.search) as {
		code: string
		state: string
		scope: string
	}

	return platform(urlparams.code, urlparams.state, urlparams.scope).then((resp) => ({
		...resp,
		profiles: resp.profiles || [],
	}))
}

export function gsuite(location: Location): CancellablePromise<api.Authed> {
	return oauth2(location, api.authn.gsuite)
}

export function facebook(location: Location): CancellablePromise<api.Authed> {
	return oauth2(location, api.authn.facebook)
}

export function microsoft(location: Location): CancellablePromise<api.Authed> {
	return oauth2(location, api.authn.microsoft)
}

export function ephemeral(location: Location): CancellablePromise<api.Authed> {
	return api.loginopts.get(location.pathname, api.loginopts.zero()).then((lopts) => {
		return api.generate(lopts).then((opts) => {
			return cache.ephemerallink.state
				.cas(uuid.v4())
				.then((uid) => api.authn.ephemeral(uid, opts.options, ""))
				.then((resp) => ({
					...resp,
					profiles: resp.profiles || [],
				}))
		})
	})
}

export function emailotp(location: Location): CancellablePromise<api.Authed> {
	return Passwordless.consumeCode()
		.then((r) => {
			if (r.status !== "OK") return CancellablePromise.reject(r.status)
			return cache.magiclink.state.consume().then((opts) => {
				return Session.getAccessToken().then((token) => {
					if (token === undefined) return Promise.reject("unable to retrieve access token")
					return api.authn.supertokensemailotp(opts, httpx.options.bearer(token))
				})
			})
		})
		.finally(() => clearLoginAttemptInfo()) as CancellablePromise<api.Authed>
}

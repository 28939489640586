import React from "react"
import { FileRejection } from "react-dropzone"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as icons from "icons"

const acceptedTypes = {
	"image/png": [".png"],
	"image/jpeg": [".jpeg", ".jpg"],
	"image/gif": [".gif"],
	"image/webp": [".webp"],
}

interface props extends layouts.containers.ContainerProps {
	onChange(item: File[]): void
	disabled?: boolean
}

export default function Dropwell(props: React.PropsWithChildren<props>): JSX.Element {
	const { onChange, disabled, ...rest } = props
	const onDropHandle = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		if (acceptedFiles.length === 0) return
		onChange(Array.from(acceptedFiles))
	}

	return (
		<inputs.Dropwell
			className="images.dropwell"
			accept={acceptedTypes}
			onDrop={(accepted, rejected, evt) => onDropHandle(accepted, rejected)}
			flex="1"
			multiple
			disabled={disabled}
			{...rest}
		>
			<layouts.dnd.Container>
				<icons.FileUpload className="dropwell-icon" />
				<layouts.dnd.Textual>Drag & drop your files here or choose files</layouts.dnd.Textual>
			</layouts.dnd.Container>
		</inputs.Dropwell>
	)
}

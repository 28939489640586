import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as icons from "icons"
import * as errors from "errors"
import * as sessions from "sessions"
import Endpoints from "./login.endpoints"

interface props extends sessions.api.LoginOptions {
	tabbed: boolean
}

export default function Login(props: props & layouts.containers.FlexProps): JSX.Element {
	const { tabbed, ..._rest } = props

	const [loginopts, rest] = sessions.api.loginopts.from(_rest)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)
	const [login, setLogin] = useState({
		options: "",
		endpoints: [],
	} as sessions.api.Login)

	function request() {
		sessions.api
			.generate(loginopts)
			.then((l) => {
				setCause(undefined)
				setLogin(l)
			})
			.catch((c) => {
				console.error("failed to load login endpoints", c)
				setCause(
					<errors.Textual onClick={() => setCause(undefined)}>
						<icons.loading.Ring1 m="auto" width="80px" height="80px" foreground="black" />
					</errors.Textual>,
				)
				setTimeout(request, 3000)
			})
	}

	useEffect(request, [])

	return (
		<layouts.Flex
			flex="1"
			className="login"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			background={layouts.theme.colors.white}
			{...rest}
		>
			<layouts.Flex className="navigation" mb="50px">
				<icons.MainLogo />
			</layouts.Flex>
			<typography.h1
				color={layouts.theme.colors.grey.dark50alpha90}
				fontWeight="700"
				fontSize="24px"
				lineHeight="25px"
				mb="25px"
			>
				Welcome To BrandGuard
			</typography.h1>
			<typography.h2 color={layouts.theme.colors.grey.dark50alpha90} fontWeight="400" fontSize="16px" lineHeight="25px">
				The world&apos;s #1
			</typography.h2>
			<typography.h2
				color={layouts.theme.colors.grey.dark50alpha90}
				fontWeight="400"
				fontSize="16px"
				lineHeight="25px"
				mb="35px"
			>
				Brand Governance Platform.
			</typography.h2>
			<layouts.Flex flexDirection="column" justifyContent="center" alignItems="center" borderRadius="20px">
				<layouts.containers.box mb="20px">
					<typography.h3
						fontSize="14px"
						color={layouts.theme.colors.grey.dark50alpha80}
						lineHeight="41px"
						fontWeight="400"
					>
						Authenticate to login or create an account
					</typography.h3>
				</layouts.containers.box>
				<errors.OverlayV2 cause={cause}>
					<Endpoints tabbed={tabbed} endpoints={login.endpoints} options={login.options} />
				</errors.OverlayV2>
			</layouts.Flex>
		</layouts.Flex>
	)
}

Login.defaultProps = {
	tabbed: false,
	autologin: false,
	redirect: "",
	account_id: "",
}

import React, { useState } from "react"
import * as errors from "errors"
import * as layouts from "layouts"
import * as context from "./context"
import classNames from "classnames"

export default function Container(props: React.PropsWithChildren<layouts.containers.FlexProps>): JSX.Element {
	const { className, children, ...rest } = props

	const [screens, setScreens] = useState(0)

	const ctx = context.create({
		screens: screens,
		enabled: screens > 0,
		increment(d: number): number {
			const updated = screens + d
			setScreens(updated)
			return updated
		},
	})

	return (
		<context.Provider value={ctx}>
			<errors.Boundary>
				<layouts.containers.flex {...rest} className={classNames("overlays container", className)} position="relative">
					{children}
				</layouts.containers.flex>
			</errors.Boundary>
		</context.Provider>
	)
}
